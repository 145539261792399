import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'abbreviate' })
export class AbbreviatePipe implements PipeTransform {
  transform(value: string, count = 15, sign = '…'): string {
    return typeof value === 'string' &&
      value.substr(0, count).length !== value.length
      ? value.substr(0, count) + sign
      : value;
  }
}
