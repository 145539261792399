<ul class="legend">
  <li *ngFor="let icon of legendArray">
    <div *ngIf="!icon.svgName; else svg"
         [inlineSVG]="icon.path"
         [class]="getClass(icon)"></div>
    <ng-template #svg>
      <svg-icon [name]="icon.svgName"
                [opacity]="icon.opacity || 1"></svg-icon>
    </ng-template>
    <span class="legend-text">
      {{ icon.title | translate }}
    </span>
  </li>
</ul>
