import { Action } from '@ngrx/store';


export const SHOW_INFO = '[ALERT] SHOW_INFO';
export const SHOW_ERROR = '[ALERT] SHOW_ERROR';

export class ShowInfo implements Action {
  readonly type = SHOW_INFO;
  constructor(public message: string) { }
}

export class ShowError implements Action {
  readonly type = SHOW_ERROR;
  constructor(public errorMessage: string) { }
}


export type AlertAction = ShowInfo | ShowError;
