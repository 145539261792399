import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleAccessDirective } from './role-access.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [RoleAccessDirective],
  exports: [RoleAccessDirective],
})
export class RoleAccessModule {}
