import { Component, Input } from '@angular/core';
import { isFunction } from 'lodash-es';

import { DeviceService } from '@app/core/device.service';
import { DeliveryFile } from '@app/state/interfaces';
import { OpenDeliveryFileReq } from '@app/core/http/interfaces';
import { AppState, OpenDeliveryFile } from '@app/state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss'],
})
export class ClipboardComponent {
  public clickedItem = false;
  public showMore = false;
  public initShowFilesCount = 3;

  @Input('content') contentToCopy: any = '';
  @Input('display') contentToDisplay: any = '';
  @Input('time') notificationDisplayTime = 1000;
  @Input('mobile-action') mobileAction: Function;
  @Input('mail') mailAddress: string;
  @Input('phone') phoneNumberToCall: string;
  @Input('sms') phoneNumberToSendSMS: string;
  @Input('svg-icon-name') svgName: string;
  @Input('files') files: DeliveryFile[];

  constructor(
    private deviceService: DeviceService,
    private store: Store<AppState>,
    ) {}

  get filesToshow(): DeliveryFile[] {
    const sortedFiles = this.files.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    return this.showMore ? sortedFiles : sortedFiles.slice(0, this.initShowFilesCount);
  }

  onClick(event: MouseEvent) {
    event.preventDefault();

    if (this.mailAddress) {
      window.open(`mailto:${this.mailAddress}?subject=ProperGate`);
      return;
    }

    if (this.mobileAction && isFunction(this.mobileAction) && this.deviceService.isMobile) {
      this.mobileAction();
      return;
    }

    if (this.phoneNumberToCall && this.deviceService.isMobile) {
      window.open(`tel:${this.phoneNumberToCall}`);
      return;
    }

    if (this.phoneNumberToSendSMS && this.deviceService.isMobile) {
      window.open(`sms:${this.phoneNumberToSendSMS}`);
      return;
    }

    this.toggleClass();
  }

  openFile(file: DeliveryFile) {
    const req: OpenDeliveryFileReq = {
      deliveryId: file.deliveryId,
      fileId: file.id,
      openInNewTab: true,
    };

    this.store.dispatch(new OpenDeliveryFile(req));
  }

  toggleClass(firstLoop: boolean = true) {
    this.clickedItem = !this.clickedItem;

    if (!firstLoop) {
      return;
    }

    setTimeout(() => {
      this.toggleClass(false);
    }, this.notificationDisplayTime);
  }
}
