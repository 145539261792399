import { ActionReducer } from '@ngrx/store';

import * as effects from './permissions.effect';
import * as reducers from './permissions.reducer';

export const permissionsEffects: any[] = [effects.PermissionsEffects];

export const permissionsReducer: ActionReducer<reducers.PermissionsState> = reducers.permissionsReducer;

export * from './permissions.action';
export * from './permissions.effect';
export * from './permissions.reducer';
export * from './permissions.selector';
