import { ActionReducer } from '@ngrx/store';

import * as effects from './auth.effects';
import * as reducers from './auth.reducer';
import { TokenDecoded } from '@app/core/http/interfaces';

export const authEffects: any[] = [effects.UserAuthEffects];

export interface AuthState {
  token?: string;
  tokenDecoded: TokenDecoded;
  error: boolean;
  userId?: string;
  specialToken?: string;
  role?: string;
  redirectUrl?: string;
  queryParams?: any;
}

export const reducer: ActionReducer<AuthState> = reducers.authReducer;

export * from './auth.actions';
export * from './auth.effects';
export * from './auth.reducer';
export * from './auth.selectors';
