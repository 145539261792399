import {
  Component,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, take, map, takeUntil, startWith, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GenericForm } from '@interfaces';
import { deliveryTypes, activeNoLimit, activeNoLimitOrderByCompanyName } from './add-delivery.utils';
import {
  AppState,
  getUserData,
  GetBuildings,
  GetVehicleSizes,
  GetSubcontractors,
  getAllSubcontractors,
  getAllVehicleSizes,
  CreateDelivery,
  getAllBuildings,
  GetSubcontractorSettings,
  getAllSubcontractorSettings,
  getDeliveryFormFilled,
  getAllSettings,
  DeliveryFormed,
} from '@app/state';
import {
  User,
  Building,
  Subcontractor,
  VehicleSize,
  EquipmentDeliveriesDetails,
  AvailableDeliveryTypes,
  Delivery,
  TaskAssigmentPanelData,
} from '@app/state/interfaces';
import { DeviceService } from '@app/core/device.service';
import { CreateDeliveryReq } from '@app/core/http/interfaces';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { ConfirmService } from '@ppgt/web/shared/ui/confirm';
import { PermissionsEnum, UserRoles, DeliveryTypeDetail } from '@ppgt/web/shared/domain';
import { SchedulerEventCreate } from '@ppgt/web/scheduler/domain';
import { SchedulerPublicService } from '@ppgt/web/scheduler/public';
import { getMaterials } from '@ppgt/web/tasks/data-access';

@Component({
  selector: 'app-add-delivery',
  templateUrl: './add-delivery.component.html',
  styleUrls: ['./add-delivery.component.scss'],
})
export class AddDeliveryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public additionalConfig?: EquipmentDeliveriesDetails;
  @Input() public taskAssigmentPanel: boolean = false;
  @Input() public taskAssigmentPanelData: TaskAssigmentPanelData;
  @Output() public closeAside = new EventEmitter<void>();
  @Output() public addedDelivery = new EventEmitter<void>();

  public deliveryTypes = deliveryTypes;
  public deliveryTypeForm: GenericForm<{ deliveryType: DeliveryTypeDetail }>;
  public me: User;
  public subcontractors$: Observable<Subcontractor[]>;
  public hasSubcontractorInputPermission: boolean;
  public vehicleSizes$: Observable<VehicleSize[]>;
  public buildings$: Observable<Building[]>;
  public destroy$ = new Subject<void>();
  public unloadingTimes$: Observable<{ label: string; value: number }[]>;
  public typeChange$ = new Subject<DeliveryTypeDetail>();
  public collapsed = false;
  schedulerDeliveryData: SchedulerEventCreate;
  public isSubcontractorRole: boolean;
  public availableDeliveryTypes: AvailableDeliveryTypes = {
    onetime: true,
    fast: true,
    emergency: true,
  };
  deliveryData: Partial<Delivery> = null;

  get deliveryTypeControl() {
    return this.deliveryTypeForm.get('deliveryType');
  }

  get currentType() {
    return this.deliveryTypeControl.value;
  }

  get isEmergency() {
    return this.currentType === DeliveryTypeDetail.Emergency;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    public device: DeviceService,
    private deliveriesService: DeliveriesService,
    private confirmService: ConfirmService,
    private schedulerPublicService: SchedulerPublicService
  ) {}

  public ngOnInit() {
    this.deliveryTypeForm = this.formBuilder.group({ deliveryType: DeliveryTypeDetail.Normal });

    if (this.additionalConfig) {
      // @todo add in next step
      // console.log(this.additionalConfig);
    }

    this.initFormFilledStream();
    this.checkIsSubcontractor();
    this.listenToTypeChange();

    this.store.dispatch(new GetVehicleSizes());
    this.store.dispatch(new GetBuildings(activeNoLimit));

    this.vehicleSizes$ = this.store.select(getAllVehicleSizes).pipe(  filter(Boolean), tap(vehicles => {
      return vehicles.sort((vehicleA, vehicleB) => vehicleA.name.localeCompare(vehicleB.name))
    }));
    this.buildings$ = this.store.select(getAllBuildings);
    this.getSchedulerDeliveryData();

    if (this.isSubcontractorRole) {
      this.store
        .select(getAllSubcontractorSettings)
        .pipe(
          tap((subSettings) => !subSettings && this.store.dispatch(new GetSubcontractorSettings())),
          filter(Boolean),
          take(1)
        )
        .subscribe((subcontractorSettings) => {
          this.availableDeliveryTypes = subcontractorSettings.availableDeliveryTypes;
          const activeDeliveryType = deliveryTypes.find(
            (type) => this.availableDeliveryTypes[type.name] === true
          )?.name;

          if (this.currentType === activeDeliveryType) return
          this.deliveryTypeControl.setValue(activeDeliveryType as DeliveryTypeDetail);
        });
    }

    if (!this.isSubcontractorRole) {
      this.deliveryTypeForm = this.formBuilder.group({ deliveryType: DeliveryTypeDetail.Normal });
    }

    if (this.taskAssigmentPanel) {
      this.availableDeliveryTypes = {
        onetime: true,
        fast: false,
        emergency: false,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.taskAssigmentPanelData && this.taskAssigmentPanel) {
      this.store
        .select(getMaterials)
        .pipe(takeUntil(this.destroy$))
        .subscribe((materials) => {
          const { id, quantity, unit, suppliers } = this.taskAssigmentPanelData.data;

          const category = materials.find((materialCategory) => {
            return materialCategory.materials.some((material) => material.id === id);
          });

          const { subcontractorId } = this.taskAssigmentPanelData;

          const deliveryData = {
            subcontractorId,
            deliveryMaterials: [
              {
                materialCategoryId: category?.id,
                materialId: id,
                expectedAmount: quantity,
                unit,
                supplierId: suppliers[0]?.id,
              },
            ],
          } as Partial<Delivery>;

          this.deliveriesService.onetimeDeliveryData = deliveryData;
          this.deliveryData = deliveryData;
        });
    }
  }

  public ngOnDestroy(): void {
    this.deliveriesService.clearOnetimeDeliveryData();
    this.deliveriesService.isOnetimeDeliveryFormEmpty = true;
    this.store.dispatch(new DeliveryFormed(null));

    this.destroy$.next();
    this.destroy$.complete();
  }

  public onFormChange(data): void {
    this.deliveriesService.isOnetimeDeliveryFormEmpty = false;
  }

  public listenToTypeChange(): void {
    this.deliveryTypeForm
      .get('deliveryType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((type) => {
        this.typeChange$.next(type);
      });
    this.setUnloadingTimes();
  }

  private initFormFilledStream() {
    this.store
      .select(getDeliveryFormFilled)
      .pipe(
        filter((data) => !!data && !this.isEmergency),
        map((data) => data.type),
        takeUntil(this.destroy$)
      )
      .subscribe((type) => {
        this.deliveryTypeControl.setValue(type);
      });
  }

  private checkIsSubcontractor() {
    this.store
      .select(getUserData)
      .pipe(filter(Boolean), take(1))
      .subscribe((me) => {
        this.me = me;
        this.isSubcontractorRole = me.role.name === UserRoles.SUBCONTRACTOR;
        // user with this Permission is a Subcontractor so an input field for Subcontractors is displayed
        // without this Permission a select field for Subcontractors is displayed instead
        this.hasSubcontractorInputPermission = me.role.permits.includes(
          PermissionsEnum.DELIVERIES_ONETIME_ADD_SUBCONTRACTOR_INPUT_FIELD_DISPLAY
        );

        if (!this.hasSubcontractorInputPermission) {
          this.getSubcontractors();
        }
      });
  }

  private setUnloadingTimes() {
    this.unloadingTimes$ = combineLatest([
      this.store.select(getAllSettings),
      this.deliveryTypeControl.valueChanges.pipe(startWith(this.deliveryTypeControl.value)),
    ]).pipe(
      filter(([settings]) => !!settings),
      map(([{ unloadingTimes }, deliveryType]) =>
        deliveryType === DeliveryTypeDetail.Fast ? unloadingTimes.fast : unloadingTimes.other
      )
    );
  }

  public getSubcontractors() {
    this.store.dispatch(new GetSubcontractors(activeNoLimitOrderByCompanyName));
    this.subcontractors$ = this.store.select(getAllSubcontractors);
  }

  public createDelivery(data: CreateDeliveryReq) {
    const repeatedFromDeliveryId = this.deliveriesService.onetimeDeliveryData?.repeatedFromDeliveryId;

    if (repeatedFromDeliveryId) {
      data = {
        ...data,
        delivery: {
          ...data.delivery,
          repeatedFromDeliveryId,
        },
      };
    }

    if (!data.duplicateAfter) {
      this.deliveriesService.isOnetimeDeliveryFormEmpty = true;
      this.addedDelivery.next();
    }

    this.store.dispatch(new CreateDelivery(data));
  }

  public toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  @HostListener('document:keydown.escape')
  public close() {
    if (this.deliveriesService.isOnetimeDeliveryFormEmpty) {
      this.closeAside.emit();
      return;
    }

    this.confirmService
      .open('alert.close_new_delivery_confirmation_l')
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed) => {
        if (confirmed) {
          this.deliveriesService.isOnetimeDeliveryFormEmpty = true;
          this.deliveriesService.clearOnetimeDeliveryData();

          setTimeout(() => {
            this.closeAside.emit();
          }, 0);
        }
      });
  }

  private getSchedulerDeliveryData(): void {
    this.schedulerPublicService.eventChanged$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.schedulerDeliveryData = data;

      if (data) {
        const isHotspot = data.resource?.hotspot;
        isHotspot && this.deliveryTypeControl.setValue(DeliveryTypeDetail.Fast);
        this.availableDeliveryTypes = { ...this.availableDeliveryTypes, emergency: false };
      }
    });
  }
}
