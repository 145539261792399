<div *ngFor="let alert of alerts; let i = index"
     class="alert"
     [ngStyle]="{'transform': alert.startAnimation ? 'translateY(' + (alert.offset + (i + 1) * 50) + 'px)' : ''}"
     [class.animation]="alert.startAnimation"
     [ngClass]="alert.type"
     data-cy="alert">
  <div>
    <span>{{ 'general.warning_l' | translate }}!</span>
    <p (click)="checkElementAction(alert, $event)"
       [innerHTML]="(alert.message | translate)">
    </p>
  </div>
  <button class="close"
          (click)="removeAlert(alert)">
    <svg-icon name="icon-alert-close"
              size="16"></svg-icon>
  </button>
</div>
