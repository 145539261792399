import { HttpErrorResponse } from '@angular/common/http'
import { createReducer, on } from '@ngrx/store'
import { Material } from '@ppgt/web/tasks/domain'
import * as fromMaterialsActions from './materials.actions'

export const MATERIALS_FEATURE_KEY = 'taskMaterials'

export interface MaterialsState {
  materials: Material[]
  loading: boolean
  error: HttpErrorResponse | null
}

export interface MaterialsPartialState {
  readonly [MATERIALS_FEATURE_KEY]: MaterialsState
}

export const materialsInitialState: MaterialsState = {
  materials: [],
  loading: false,
  error: null
}

export const materialsReducer = createReducer(materialsInitialState,
  on(fromMaterialsActions.getMaterials, (state) => ({
    ...state,
    materials: [],
    loading: true,
    error: null
  })),
  on(fromMaterialsActions.getMaterialsFail, (state, { error }) => ({
    ...state,
    materials: [],
    loading: false,
    error
  })),
  on(fromMaterialsActions.getMaterialsSuccess, (state, { materials }) => ({
    ...state,
    materials,
    loading: false,
    error: null
  })),
  on(fromMaterialsActions.assignMaterials, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromMaterialsActions.assignMaterialsFail, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(fromMaterialsActions.assignMaterialsSuccess, (state) => {
    return {
      ...state,
      loading: false,
      error: null
    }
  }),
)
