import { MenuTab } from '@app/state/interfaces';
import { PermissionsEnum, UserRoles } from '@ppgt/web/shared/domain';
import { environment } from '@env/environment';

type NavKeys =
  | 'general'
  | 'objects'
  | 'subcontractors'
  | 'suppliers'
  | 'buildingProfile'
  | 'subcontractor'
  | 'supplier'
  | 'addUser'
  | 'deliveries'
  | 'reports'
  | 'constructionPlan';

export const nav: { [key in NavKeys]: MenuTab[] } = {
  general: [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: 'assets/img/nav-icon-dashboard.svg',
      withPermission: PermissionsEnum.NAVIGATION_DASHBOARD,
    },
    {
      title: 'config_nav.general.scheduler',
      path: '/scheduler',
      icon: 'assets/img/nav-icon-scheduler.svg',
      withPermission: PermissionsEnum.NAVIGATION_SCHEDULER,
    },
    {
      title: 'config_nav.general.deliveries',
      path: '/deliveries',
      icon: 'assets/img/nav-icon-deliveries.svg',
      withPermission: PermissionsEnum.NAVIGATION_DELIVERIES,
    },
    {
      title: 'config_materials.building_materials_l',
      path: '/materials',
      icon: 'assets/img/nav-icon-materials.svg',
      withPermission: PermissionsEnum.NAVIGATION_MATERIALS,
    },
    {
      title: 'config_nav.general.construction_plan',
      path: '/construction-plan',
      icon: 'assets/img/nav-icon-maps.svg',
      withPermission: PermissionsEnum.NAVIGATION_CONSTRUCTION_PLAN,
    },
    {
      title: 'config_nav.general.subcontractors',
      path: '/subcontractors',
      icon: 'assets/img/nav-icon-subcontractors.svg',
      ignoreFill: true,
      withPermission: PermissionsEnum.NAVIGATION_SUBCONTRACTORS,
    },
    {
      title: 'config_nav.general.suppliers',
      path: '/suppliers',
      icon: 'assets/img/nav-icon-suppliers.svg',
      withPermission: PermissionsEnum.NAVIGATION_SUPPLIERS,
    },
    {
      title: 'config_nav.general.objects',
      path: '/objects',
      icon: 'assets/img/nav-icon-objects.svg',
      withPermission: PermissionsEnum.NAVIGATION_OBJECTS,
    },
    {
      title: 'config_nav.general.tasks',
      path: '/tasks',
      icon: 'assets/img/task.svg',
      withPermission: PermissionsEnum.NAVIGATION_TASKS,
    },
    {
      title: 'config_nav.general.reports',
      path: '/reports',
      icon: 'assets/img/nav-icon-reports.svg',
      withPermission: PermissionsEnum.NAVIGATION_REPORTS,
    },
    {
      title: 'config_nav.general.settings',
      path: '/settings',
      icon: 'assets/img/nav-icon-settings.svg',
      withPermission: PermissionsEnum.NAVIGATION_SETTINGS,
    },
  ],
  objects: [
    {
      title: 'config_nav.objects.unloading_places',
      path: '/objects/unloading-places',
      withPermission: PermissionsEnum.OBJECTS_UNLOADING_PLACES_DISPLAY,
    },
    {
      title: 'config_nav.objects.gates',
      path: '/objects/gates',
      withPermission: PermissionsEnum.OBJECTS_GATES_DISPLAY,
    },
    {
      title: 'config_nav.objects.equipment',
      path: '/objects/equipment',
      withPermission: PermissionsEnum.OBJECTS_EQUIPMENT_DISPLAY,
    },
    {
      title: 'config_nav.objects.depots',
      path: '/objects/depots',
      withPermission: PermissionsEnum.OBJECTS_DEPOTS_DISPLAY,
    },
    {
      title: 'config_nav.general.scheduler',
      path: '/scheduler',
      withPermission: PermissionsEnum.OBJECTS_SCHEDULER_DISPLAY,
    },
  ],
  subcontractors: [
    {
      title: 'config_nav.subcontractors.all',
      path: '/subcontractors/all',
      withPermission: PermissionsEnum.SUBCONTRACTOR_ALL_DISPLAY,
    },
    {
      title: 'config_nav.subcontractors.contracts',
      path: '/subcontractors/contracts',
      withPermission: PermissionsEnum.SUBCONTRACTOR_CONTRACTS_DISPLAY,
    },
    {
      title: 'config_nav.subcontractors.single_use',
      path: '/subcontractors/one-time',
      withPermission: PermissionsEnum.SUBCONTRACTOR_ONE_TIME_DISPLAY,
    },
    {
      title: 'config_nav.subcontractors.long_term',
      path: '/subcontractors/long-term',
      withPermission: PermissionsEnum.SUBCONTRACTOR_LONG_TERM_DISPLAY,
    },
    {
      title: 'config_nav.subcontractors.archive',
      path: '/subcontractors/archive',
      withPermission: PermissionsEnum.SUBCONTRACTOR_ARCHIVE_DISPLAY,
    },
  ],
  suppliers: [
    {
      title: 'config_nav.subcontractors.all',
      path: '/suppliers/all',
      withPermission: PermissionsEnum.SUPPLIER_ALL_DISPLAY,
    },
  ],
  buildingProfile: [
    {
      title: 'config_nav.building_profile.general',
      path: '/settings/building-profile/general',
      withPermission: PermissionsEnum.BUILDING_PROFILE_GENERAL_DISPLAY,
    },
    {
      title: 'config_nav.building_profile.construction_plan',
      path: '/settings/building-profile/construction-plan',
      withPermission: PermissionsEnum.BUILDING_PROFILE_CONSTRUCTION_PLAN_DISPLAY,
      testId: 'oneTimeDeliveryLink',
    },
    {
      title: 'config_nav.building_profile.future_construction_plan',
      path: '/settings/building-profile/future-construction-plan',
      withPermission: PermissionsEnum.BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_DISPLAY,
    },
    {
      title: 'config_nav.building_profile.permissions',
      path: '/settings/building-profile/permissions',
      withPermission: PermissionsEnum.BUILDING_PROFILE_PERMISSIONS_DISPLAY,
    },
    {
      title: 'config_nav.building_profile.objects',
      path: '/settings/building-profile/objects',
      withPermission: PermissionsEnum.BUILDING_PROFILE_OBJECTS_DISPLAY,
    },
    {
      title: 'config_nav.building_profile.deliveries',
      path: '/settings/building-profile/deliveries',
      withPermission: PermissionsEnum.BUILDING_PROFILE_DELIVERIES_DISPLAY,
    },
    {
      title: 'config_nav.building_profile.subcontractors',
      path: '/settings/building-profile/subcontractors',
      withPermission: PermissionsEnum.BUILDING_PROFILE_SUBCONTRACTORS_DISPLAY,
    },
    {
      title: 'config_nav.building_profile.ecology',
      path: '/settings/building-profile/ecology',
      withPermission: PermissionsEnum.BUILDING_PROFILE_ECOLOGY_DISPLAY,
    },
    {
      title: 'config_nav.building_profile.integrations',
      withPermission: PermissionsEnum.BUILDING_PROFILE_INTEGRATIONS_DISPLAY,
      dropdown: [
        {
          title: 'Procore',
          data: { integration: true, client: 'procore', integrationUrl: environment.procoreIntegrationUrl },
        },
      ],
    },
    {
      title: 'config_nav.building_profile.users',
      path: '/settings/building-profile/users',
      withPermission: PermissionsEnum.BUILDING_PROFILE_MANAGE_USERS_DISPLAY,
      dropdown: [
        {
          title: UserRoles.LOGISTIC_COORDINATOR,
          params: { roleType: UserRoles.LOGISTIC_COORDINATOR },
        },
        {
          title: UserRoles.EQUIPMENT_OPERATOR,
          params: { roleType: UserRoles.EQUIPMENT_OPERATOR },
        },
        {
          title: UserRoles.TRAFFIC_MARSHALL,
          params: { roleType: UserRoles.TRAFFIC_MARSHALL },
        },
        {
          title: UserRoles.SECURITY_GUARD,
          params: { roleType: UserRoles.SECURITY_GUARD },
        },
        {
          title: UserRoles.SUBCONTRACTOR,
          params: { roleType: UserRoles.SUBCONTRACTOR },
        },
        {
          title: UserRoles.SUPPLIER,
          params: { roleType: UserRoles.SUPPLIER },
        },
      ],
    },
  ],
  subcontractor: [
    {
      title: 'config_nav.subcontractor.details',
      path: 'details',
      withPermission: PermissionsEnum.SUBCONTRACTOR_DETAILS_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.employees',
      path: 'employees',
      withPermission: PermissionsEnum.SUBCONTRACTOR_DETAILS_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.locations',
      path: 'locations',
      withPermission: PermissionsEnum.SUBCONTRACTOR_DETAILS_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.contracts',
      path: 'contracts',
      withPermission: PermissionsEnum.SUBCONTRACTOR_CONTRACTS_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.vehicles',
      path: 'vehicles',
      withPermission: PermissionsEnum.VEHICLE_ALL_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.crews',
      path: 'crews',
      withPermission: PermissionsEnum.SUBCONTRACTOR_CREWS_DISPLAY,
    },
  ],
  supplier: [
    {
      title: 'config_nav.subcontractor.details',
      path: 'details',
      withPermission: PermissionsEnum.SUPPLIER_ALL_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.employees',
      path: 'employees',
      withPermission: PermissionsEnum.SUPPLIER_EDIT,
    },
    {
      title: 'config_nav.subcontractor.locations',
      path: 'locations',
      withPermission: PermissionsEnum.SUPPLIER_EDIT,
    },
    {
      title: 'config_nav.suppliers.products',
      path: 'products',
      withPermission: PermissionsEnum.SUPPLIER_PRODUCTS_DISPLAY,
    },
    {
      title: 'config_nav.suppliers.clients',
      path: 'clients',
      withPermission: PermissionsEnum.SUPPLIER_CLIENTS_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.contracts',
      path: 'contracts',
      withPermission: PermissionsEnum.SUPPLIER_CONTRACTS_DISPLAY,
    },
    {
      title: 'config_nav.subcontractor.vehicles',
      path: 'vehicles',
      withPermission: PermissionsEnum.SUPPLIER_EDIT,
    },
  ],
  addUser: [
    {
      title: UserRoles.LOGISTIC_COORDINATOR,
      path: '/settings/building-profile/users/add/logistic-coordinator',
    },
    {
      title: UserRoles.TRAFFIC_MARSHALL,
      path: '/settings/building-profile/users/add/traffic-marshall',
    },
    {
      title: UserRoles.SUBCONTRACTOR,
      path: '/subcontractors/add',
    },
    {
      title: UserRoles.EQUIPMENT_OPERATOR,
      path: '/settings/building-profile/users/add/equipment-operator',
    },
    {
      title: UserRoles.SECURITY_GUARD,
      path: '/settings/building-profile/users/add/security-guard',
    },
  ],
  deliveries: [
    {
      title: 'config_nav.deliveries.currently',
      path: '/deliveries/currently',
      withPermission: PermissionsEnum.DELIVERIES_CURRENT_DISPLAY,
      dropdown: [
        {
          title: 'config_nav.deliveries.onetime',
          mobileTitle: 'config_nav.deliveries.currently_onetime',
          path: '/deliveries/currently/onetime',
          withPermission: PermissionsEnum.DELIVERIES_CURRENT_DISPLAY,
        },
        {
          title: 'config_nav.deliveries.longterm',
          mobileTitle: 'config_nav.deliveries.currently_longterm',
          path: '/deliveries/currently/longterm',
          withPermission: PermissionsEnum.DELIVERIES_CURRENT_DISPLAY,
        },
      ],
    },
    {
      title: 'general.today_l',
      path: '/deliveries/today',
      withPermission: PermissionsEnum.DELIVERIES_TODAY_DISPLAY,
      dropdown: [
        {
          title: 'config_nav.deliveries.onetime',
          mobileTitle: 'config_nav.deliveries.today_onetime',
          path: '/deliveries/today/onetime',
          withPermission: PermissionsEnum.DELIVERIES_TODAY_DISPLAY,
        },
        {
          title: 'config_nav.deliveries.longterm',
          mobileTitle: 'config_nav.deliveries.today_longterm',
          path: '/deliveries/today/longterm',
          withPermission: PermissionsEnum.DELIVERIES_TODAY_DISPLAY,
        },
      ],
    },
    {
      title: 'config_nav.deliveries.expected',
      path: '/deliveries/expected/onetime',
      withPermission: PermissionsEnum.DELIVERIES_EXPECTED_DISPLAY,
      dropdown: [
        {
          title: 'config_nav.deliveries.onetime',
          mobileTitle: 'config_nav.deliveries.expected_onetime',
          path: '/deliveries/expected/onetime',
          withPermission: PermissionsEnum.DELIVERIES_EXPECTED_DISPLAY,
        },
        {
          title: 'config_nav.deliveries.longterm',
          mobileTitle: 'config_nav.deliveries.expected_longterm',
          path: '/deliveries/expected/longterm',
          withPermission: PermissionsEnum.DELIVERIES_EXPECTED_DISPLAY,
        },
      ],
    },
    {
      title: 'config_nav.deliveries.to_accept',
      path: '/deliveries/toaccept',
      withPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_DISPLAY,
    },
    {
      title: 'config_nav.deliveries.to_complete',
      path: '/deliveries/tocomplete',
      desktopOnly: true,
      withPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_DISPLAY,
    },
    {
      title: 'config_nav.deliveries.rejected',
      path: '/deliveries/rejected',
      withPermission: PermissionsEnum.DELIVERIES_REJECTED_DISPLAY,
    },
    {
      title: 'config_nav.deliveries.finished',
      path: '/deliveries/completed',
      withPermission: PermissionsEnum.DELIVERIES_COMPLETED_DISPLAY,
      dropdown: [
        {
          title: 'config_nav.deliveries.onetime',
          mobileTitle: 'config_nav.deliveries.finished_onetime',
          path: '/deliveries/completed/onetime',
          withPermission: PermissionsEnum.DELIVERIES_COMPLETED_DISPLAY,
        },
        {
          title: 'config_nav.deliveries.longterm',
          mobileTitle: 'config_nav.deliveries.finished_longterm',
          path: '/deliveries/completed/longterm',
          withPermission: PermissionsEnum.DELIVERIES_COMPLETED_DISPLAY,
        },
      ],
    },
    {
      title: 'config_nav.deliveries.all',
      path: '/deliveries/all',
      withPermission: PermissionsEnum.DELIVERIES_ALL_DISPLAY,
    },
  ],
  reports: [
    {
      title: 'config_nav.reports.general',
      path: '/reports/general',
    },
    {
      title: 'config_nav.reports.storage',
      path: '/reports/storage',
    },
    {
      title: 'config_nav.reports.delays',
      path: '/reports/delays',
    },
    {
      title: 'config_nav.reports.breeam',
      path: '/reports/breeam',
      monitoringCo2Required: true,
      // withPermission: PermissionsEnum.REPORTS_BREEAM_DISPLAY,
    },
    {
      title: 'config_nav.reports.unrealized',
      path: '/reports/unrealized',
    },
    {
      title: 'config_nav.reports.concreting',
      path: '/reports/concreting',
    },
  ],
  constructionPlan: [
    {
      title: 'config_nav.construction_plan.current',
      path: '/construction-plan/current',
      withPermission: PermissionsEnum.CONSTRUCTION_PLAN_CURRENT_ROUTE_DISPLAY,
    },
    {
      title: 'config_nav.general.objects',
      path: '/objects',
      withPermission: PermissionsEnum.CONSTRUCTION_PLAN_OBJECTS_ROUTE_DISPLAY,
    },
    {
      title: 'config_nav.construction_plan.archive',
      path: '/construction-plan/archive',
      withPermission: PermissionsEnum.CONSTRUCTION_PLAN_ARCHIVE_ROUTE_DISPLAY,
    },
  ],
};

if (environment.enableFloorEditor) {
  nav.constructionPlan.splice(1, 0, {
    title: 'config_nav.construction_plan.floors',
    path: '/construction-plan/floors',
    withPermission: PermissionsEnum.CONSTRUCTION_PLAN_FLOORS_ROUTE_DISPLAY,
  });
}

export const buttonPermission: { [key: string]: PermissionsEnum } = {
  objects: PermissionsEnum.OBJECTS_ADD,
  subcontractors: PermissionsEnum.SUBCONTRACTOR_ADD,
  suppliers: PermissionsEnum.SUPPLIER_ADD,
  contracts: PermissionsEnum.CONTRACT_ADD,
  vehicles: PermissionsEnum.VEHICLE_ADD,
  deliveriesOnetime: PermissionsEnum.DELIVERIES_ONETIME_ADD,
  deliveriesLongtime: PermissionsEnum.DELIVERIES_LONGTIME_ADD,
  constructionPlan: PermissionsEnum.CONSTRUCTION_PLAN_ASIDE_ADD_DELIVERY,
  reschedule: PermissionsEnum.CONSTRUCTION_PLAN_RESCHEDULE_ACTION,
  editConstructionPlan: PermissionsEnum.CONSTRUCTION_PLAN_EDIT,
  reports: PermissionsEnum.REPORTS_GENERATE_REPORT_FILE,
  settings: PermissionsEnum.ADD_USER,
  myProfile: PermissionsEnum.DISPLAY_OWN_ACCOUNT,
};
