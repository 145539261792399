import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  SchedulerDelivery,
  SchedulerEquipment,
  SchedulerReservation,
  SchedulerValidation,
} from '@ppgt/web/scheduler/domain';

@Injectable({
  providedIn: 'root',
})
export class SchedulerPublicService {
  refreshData$ = new Subject<void>();
  deselectEvent$ = new Subject<void>();
  updateEvent$ = new Subject<Partial<SchedulerDelivery | SchedulerReservation>>();
  eventChanged$ = new Subject<{
    event: Partial<SchedulerDelivery | SchedulerReservation>;
    resource: SchedulerEquipment;
  }>();
  eventValidation: SchedulerValidation = { valid: true };

  constructor(private router: Router) {}

  navigateSelectedEquipment(equipmentId: string): void {
    this.router.navigate(['/scheduler'], { queryParams: { equipmentId } });
  }

  refreshData(): void {
    this.refreshData$.next();
  }

  deselectEvent(): void {
    this.deselectEvent$.next();
  }

  updateEvent(eventData: Partial<SchedulerDelivery | SchedulerReservation>): void {
    this.updateEvent$.next(eventData);
  }

  eventChanged(event: Partial<SchedulerDelivery | SchedulerReservation>, resource: SchedulerEquipment): void {
    this.eventChanged$.next({ event, resource });
  }
}
