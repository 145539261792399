import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { IMyDateModel } from 'mydatepicker/lib/interfaces';

@Pipe({ name: 'filterTimeSeriesByCurrentTime' })
export class FilterTimeSeriesByCurrentTimePipe implements PipeTransform {
  transform(timeSeries: string[], date: IMyDateModel): string[] {
    return date && moment(date.jsdate).isSame(moment(), 'day')
      ? timeSeries.filter((hour) => {
          const dateTime = `${moment().format('DD-MM-YYYY')} ${hour}`;
          return moment(dateTime, 'DD-MM-YYYY HH:mm').isAfter();
        })
      : timeSeries;
  }
}
