import { ActionReducer } from '@ngrx/store';
import * as effects from './materials.effect';
import * as reducers from './materials.reducer';
import {
  Material,
  MaterialCategory,
  MaterialSupplier,
  MaterialProducer,
  MaterialDestination,
  RejectReason,
  InspectionStatus,
  MaterialsComments,
  MaterialsDescriptions,
  MaterialPackingMethod,
} from '@app/state/interfaces';

export const materialsEffects = [effects.MaterialsEffects];

export interface MaterialsState {
  materials?: Material[];
  destinations: MaterialDestination[];
  categoriesList: MaterialCategory[];
  categoriesDetails: { [id: string]: MaterialCategory };
  suppliers: MaterialSupplier[];
  producers: MaterialProducer[];
  loading: boolean;
  rejectReasons: RejectReason[];
  inspectionStatuses: InspectionStatus[];
  totalObjectsCount: number;
  materialsComments: MaterialsComments;
  materialsDescriptions: MaterialsDescriptions;
  materialPackingMethods: MaterialPackingMethod[];
}

export const materialsReducer: ActionReducer<MaterialsState> = reducers.materialsReducer;

export * from './materials.action';
export * from './materials.effect';
export * from './materials.reducer';
export * from './materials.selector';
