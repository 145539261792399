<form class="form" [formGroup]="form">
  <div *ngIf="!hasSubcontractorInputPermission" class="form__input--100 form__select">
    <label>{{ 'deliveries_page.subcontractor_l' | translate }} *</label>
    <div>
      <ng-select
        [items]="subcontractors"
        [clearable]="false"
        test-id="subcontractorDropdown"
        bindLabel="companyName"
        bindValue="id"
        formControlName="subcontractorId"
      >
      </ng-select>
    </div>
    <div class="error-message relative" test-id="errorMessage" *ngIf="hasError('subcontractorId', 'required')">
      {{ 'validation.field_required_l' | translate }}
    </div>
  </div>

  <app-add-delivery-material
    *ngFor="let materialValues of addDeliveryMaterialValues; let i = index"
    [initValues]="materialValues"
    [subcontractorId]="subcontractorId"
    [categories]="materialCategories"
    [index]="i"
    (removeMaterial)="removeMaterial(i)"
    [hasSubcontractorInputPermission]="hasSubcontractorInputPermission"
    [createFromTask]="!!taskData"
  ></app-add-delivery-material>
  <button *ngIf="!taskData" (click)="addMaterial()" class="add-material">
    + {{ 'add_delivery.add_material_l' | translate }}
  </button>

  <div *ngIf="taskData" class="form__input-container">
    <div class="form__input--100">
      <label>{{ 'deliveries_page.task_name_l' | translate }}</label>
      <div class="task-name">{{ taskData.name }}</div>
    </div>
  </div>

  <div class="form__input-container">
    <div class="form__select form__input--100">
      <label>{{ 'general.size_vehicle_l' | translate }} *</label>
      <div>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          [items]="vehicleSizes"
          [class.error-input]="hasError('vehicleSizeId', 'required')"
          test-id="vehicleSizeDropdown"
          bindValue="id"
          bindLabel="name"
          formControlName="vehicleSizeId"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('vehicleSizeId', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <div class="form__input-container">
    <div class="form__input--50 form__select">
      <label>{{ 'deliveries_page.unloading_time_l' | translate }} *</label>
      <div>
        <ng-select
          [items]="unloadingTimes"
          [searchable]="false"
          [clearable]="false"
          test-id="unloadingTimeDropdown"
          bindValue="value"
          bindLabel="label"
          formControlName="unloadingTime"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('unloadingTime', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
    <div class="form__input--50 form__select">
      <label>{{ 'deliveries_page.building_l' | translate }} *</label>
      <div>
        <ng-select
          [items]="buildings"
          [clearable]="false"
          test-id="buildingDropdown"
          bindLabel="shortName"
          bindValue="id"
          formControlName="buildingId"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('buildingId', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <p class="preferences">{{ 'deliveries_page.equipment_required_for_unloading_l' | translate }}</p>
  <div class="form__input-container">
    <div test-id="craneCheckbox" class="form__input form__input--50 form__input--checkbox">
      <input type="checkbox" formControlName="crane" id="isNeeded" (change)="craneChanged()" />
      <label for="isNeeded">{{ 'deliveries_page.checkbox_crane_a' | translate }}</label>
    </div>
    <div *ngIf="form.get('crane').value" class="form__input--50 form__select">
      <div>
        <ng-select
          [items]="cranes"
          [clearable]="false"
          [searchable]="false"
          test-id="craneDropdown"
          bindLabel="shortName"
          bindValue="id"
          (change)="craneChanged()"
          formControlName="craneId"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form__input-container">
    <div class="form__input form__input--50 form__input--checkbox elevator">
      <input type="checkbox" formControlName="elevator" id="elevator" />
      <label test-id="elevatorCheckbox" for="elevator">
        {{ 'deliveries_page.checkbox_lift_a' | translate }}
      </label>
    </div>
    <div *ngIf="form.get('elevator').value" class="form__input--30 form__select">
      <div>
        <ng-select
          [items]="elevators"
          [clearable]="false"
          [searchable]="false"
          [class.error-input]="hasError('elevatorId', 'required')"
          bindLabel="shortName"
          bindValue="id"
          formControlName="elevatorId"
          test-id="elevatorDropdown"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('elevatorId', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
    <div *ngIf="form.get('elevator').value" class="form__input--20 form__select">
      <div>
        <ng-select
          [items]="elevatorFloors"
          [clearable]="false"
          [searchable]="false"
          [class.error-input]="hasError('elevatorFloor', 'required')"
          formControlName="elevatorFloor"
          test-id="elevatorFloorDropdown"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('elevatorFloor', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>
  <div class="form__input-container">
    <div test-id="excavatorCheckbox" class="form__input form__input--50 form__input--checkbox">
      <input type="checkbox" formControlName="excavator" id="excavator" />
      <label for="excavator" test-id="excavatorCheckbox">{{
        'deliveries_page.checkbox_excavator_a' | translate
      }}</label>
    </div>
    <div *ngIf="form.get('excavator').value" class="form__input--50 form__select">
      <div>
        <ng-select
          [items]="excavators"
          [clearable]="false"
          [searchable]="false"
          test-id="excavatorDropdown"
          bindLabel="shortName"
          bindValue="id"
          formControlName="excavatorId"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form__input-container">
    <div test-id="miniExcavatorCheckbox" class="form__input form__input--50 form__input--checkbox">
      <input type="checkbox" formControlName="miniExcavator" id="miniExcavator" />
      <label test-id="miniExcavatorCheckbox" for="miniExcavator">
        {{ 'deliveries_page.checkbox_small_excavator_a' | translate }}</label
      >
    </div>
    <div *ngIf="form.get('miniExcavator').value" class="form__input--50 form__select">
      <div>
        <ng-select
          [items]="miniExcavators"
          [clearable]="false"
          [searchable]="false"
          test-id="miniExcavatorDropdown"
          bindLabel="shortName"
          bindValue="id"
          formControlName="miniExcavatorId"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form__input-container">
    <div test-id="forkliftCheckbox" class="form__input form__input--50 form__input--checkbox">
      <input type="checkbox" formControlName="forklift" id="forklift2" />
      <label test-id="forkliftCheckbox" for="forklift2">{{ 'deliveries_page.checkbox_forklift_a' | translate }}</label>
    </div>
    <div *ngIf="form.get('forklift').value" class="form__input--50 form__select">
      <div>
        <ng-select
          [items]="forklifts"
          [clearable]="false"
          [searchable]="false"
          test-id="forkliftDropdown"
          bindLabel="shortName"
          bindValue="id"
          formControlName="forkliftId"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form__input-container">
    <div test-id="mobileCraneCheckbox" class="form__input form__input--50 form__input--checkbox">
      <input type="checkbox" formControlName="mobileCrane" id="mobileCrane" />
      <label test-id="mobileCraneCheckbox" for="mobileCrane">{{ 'general.mobile_crane_l' | translate }}</label>
    </div>
    <div *ngIf="form.get('mobileCrane').value" class="form__input--50 form__select">
      <div>
        <ng-select
          [items]="mobileCranes"
          [clearable]="false"
          [searchable]="false"
          test-id="mobileCraneDropdown"
          bindLabel="shortName"
          bindValue="id"
          formControlName="mobileCraneId"
        >
        </ng-select>
      </div>
    </div>
  </div>

  <p class="preferences">{{ 'deliveries_page.delivery_preffered_place_l' | translate }}</p>
  <div class="form__input--100 form__select">
    <div>
      <ng-select
        [searchable]="true"
        [clearable]="false"
        test-id="unloadingPlaceDropdown"
        formControlName="preferredPlace"
      >
        <ng-option value="">{{ 'deliveries_page.not_selected_l' | translate }} </ng-option>
        <ng-option *ngFor="let place of places | filterPlacesByEquipment: [craneEq]" [value]="place.id">{{
          place.shortName
        }}</ng-option>
      </ng-select>
    </div>
  </div>
  <p class="preferences">{{ 'deliveries_page.space_required_for_storage_l' | translate }}</p>
  <div class="form__input-container depot">
    <div class="form__input form__input--33 form__input--checkbox">
      <input type="checkbox" formControlName="depot" id="depotIsNeeded" />
      <label test-id="depotCheckbox" for="depotIsNeeded">{{ 'deliveries_page.checkbox_depot_a' | translate }}</label>
    </div>

    <div *ngIf="form.get('depot').value" class="form__select form__input--33">
      <label>{{ 'delivery_stages.floor' | translate }}</label>
      <div>
        <ng-select
          [searchable]="true"
          [clearable]="false"
          (change)="floorChanged($event)"
          test-id="floorsDropdown"
          formControlName="floor"
        >
          <ng-option value="">{{ 'deliveries_page.not_selected_l' | translate }} </ng-option>
          <ng-option *ngFor="let floor of floorsRange" [value]="floor">{{ floor }}</ng-option>
        </ng-select>
      </div>
    </div>

    <div *ngIf="form.get('depot').value" class="form__select form__input--33">
      <label>{{ 'config_header.depot_l' | translate }}</label>
      <div>
        <ng-select
          [items]="depots | sortDepotsByFloor"
          [searchable]="false"
          [clearable]="false"
          test-id="depotNameDropdown"
          bindValue="id"
          bindLabel="shortName"
          formControlName="depotId"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div *ngIf="form.get('depot').value" class="form__input-container depot">
    <div class="form__input--33"></div>
    <div class="form__input form__input--33">
      <label>{{ 'deliveries_page.days_l' | translate }}</label>
      <div>
        <input type="number" [positiveIntegersOnly]="true" test-id="depotDaysInput" formControlName="depotDays" />
        <div class="error-message" *ngIf="hasError('depotDays', 'min')">
          {{ 'validation.field_min_l' | translate }}
        </div>
      </div>
    </div>
    <div class="form__input form__input--33">
      <label>{{ 'deliveries_page.square_meter_l' | translate }}</label>
      <div>
        <input type="number" test-id="depotAreaInput" formControlName="depotAmount" />
        <div class="error-message" *ngIf="hasError('depotAmount', 'min')">
          {{ 'validation.field_min_l' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="form__input-container" *ngIf="!loading && !schedulerDeliveryData">
    <p class="preferences">{{ 'deliveries_page.delivery_time_preferences_l' | translate }}</p>
    <div class="form__input--50 form__input">
      <p>{{ 'config_header.date_l' | translate }} *</p>
      <my-date-picker
        class="datepicker"
        [locale]="lang"
        placeholder="DD/MM/YYYY"
        [options]="datePickerOptions"
        formControlName="preferredDate"
      ></my-date-picker>
      <div class="error-message relative" test-id="errorMessage" *ngIf="hasError('preferredDate', 'required')">
        {{ 'alert.incorrect_date_l' | translate }}
      </div>
    </div>
    <div class="form__input--50 form__select">
      <p>{{ 'config_header.hour_l' | translate }} *</p>
      <div>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          [items]="timeRanges"
          test-id="timeRangeDropdown"
          formControlName="timeRange"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('timeRange', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <div class="form__input-container" *ngIf="!loading">
    <div class="form__input--50 form__input">
      <label for="filesAttached">{{ 'deliveries_page.pdf_uploaded_l' | translate }}</label>
      <input type="text" id="filesAttached" [value]="files.length" disabled />
    </div>
    <div class="form__input--50 form__select">
      <input
        class="upload-input"
        id="ticket"
        type="file"
        hidden
        multiple
        accept="application/pdf"
        (change)="handleFile($any($event.target).files)"
      />
      <div class="upload">
        <label for="ticket" class="button button--accent">
          {{ 'deliveries_page.upload' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="form__input-container" *ngIf="!loading">
    <div class="file" *ngFor="let file of files; let i = index">
      <div class="file__document">
        <svg-icon name="icon-file" size="24"></svg-icon>
      </div>
      <label> {{ file.name }} </label>
      <svg-icon name="close" size="12" class="file__remove" (click)="removeFile(i)"> </svg-icon>
    </div>
  </div>

  <p *ngIf="loading" class="loading">
    <img src="assets/img/loading.png" alt="loading" />
    <span>{{ 'deliveries_page.checking_patience_l' | translate }}</span>
    <span>{{ 'deliveries_page.checking_delivery_term_l' | translate }}</span>
  </p>
  <div *ngIf="loaded || schedulerDeliveryData" class="loaded">
    <ng-container *ngIf="!schedulerDeliveryData">
      <p class="loaded__titles">
        <span>{{ 'deliveries_page.avaliable_delivery_terms_l' | translate }}</span>
      </p>
      <div class="form__input-container">
        <div class="form__select form__input--100">
          <p *ngIf="selectTimes.length === 0">
            {{ 'deliveries_page.empty_delivery_term_l' | translate }}
          </p>
          <div *ngIf="selectTimes.length !== 0" class="select__size">
            <ng-select
              [clearable]="false"
              [searchable]="false"
              test-id="chooseSlotDropdown"
              formControlName="dateUnloadingId"
              class="add-delivery__proposedTerm"
              (change)="onProposalSelection($event)"
              [placeholder]="'manual_mode.choose' | translate"
            >
              <ng-option *ngFor="let selectTime of selectTimes" [value]="selectTime.timeId"
                >{{ selectTime.label }}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="loaded__buttons">
      <button *ngIf="!deviceService.isMobile && !schedulerDeliveryData" (click)="saveFormAndRedirect()">
        {{ redirect.translation | translate }}
      </button>
      <button *ngIf="!schedulerDeliveryData" test-id="searchAgainButton" (click)="findTerm(true)">
        {{ 'deliveries_page.new_search_a' | translate }}
      </button>
    </div>
    <div class="buttons-container">
      <button (click)="reserveDelivery()" test-id="bookSlotButton" class="button button--accent">
        {{ 'deliveries_page.reserve_a' | translate }}
      </button>
      <button
        (click)="reserveAndDuplicateDelivery()"
        *ngIf="!schedulerDeliveryData && !taskData"
        test-id="bookAndCloneButton"
        class="button button--accent"
      >
        {{ 'general.save_and_duplicate_a' | translate }}
      </button>
      <button (click)="addDelivery()" test-id="addDeliveryButton" class="button button--accent">
        {{ 'general.add_a' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="!loading && !loaded && !schedulerDeliveryData" class="buttons-container">
    <button class="button button--accent" test-id="findSlotButton" (click)="findTerm(false)">
      {{ 'deliveries_page.find_term_a' | translate }}
    </button>
  </div>
  <button (click)="cancel()" class="form__cancel">{{ 'general.cancel_a' | translate }}</button>
</form>
