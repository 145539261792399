import { createSelector, createFeatureSelector } from '@ngrx/store';
import { MaterialsState } from '.';

const stateSelector = createFeatureSelector<MaterialsState>('materials');

export const getAllMaterials = createSelector(
  stateSelector,
  (state: MaterialsState) => state.materials
);

export const getMaterialCategoriesList = createSelector(
  stateSelector,
  (state: MaterialsState) => state.categoriesList
);

export const getMaterialCategoriesDetails = createSelector(
  stateSelector,
  (state: MaterialsState) => state.categoriesDetails
);

export const getSuppliers = createSelector(
  stateSelector,
  (state: MaterialsState) => state.suppliers
);

export const getProducers = createSelector(
  stateSelector,
  (state: MaterialsState) => state.producers
);

export const getInspectionRejectReasons = createSelector(
  stateSelector,
  (state: MaterialsState) => state.rejectReasons,
);

export const getInspectionStatuses = createSelector(
  stateSelector,
  (state: MaterialsState) => state.inspectionStatuses,
);

export const getMaterialsLoading = createSelector(
  stateSelector,
  (state: MaterialsState) => state.loading
);

export const getMaterialDestinations = createSelector(
  stateSelector,
  (state: MaterialsState) => state.destinations,
);

export const getMaterialsCount = createSelector(
  stateSelector,
  (state: MaterialsState) => state.totalObjectsCount,
);

export const getMaterialsComments = createSelector(
  stateSelector,
  (state: MaterialsState) => state.materialsComments,
);

export const getmaterialsDescriptions = createSelector(
  stateSelector,
  (state: MaterialsState) => state.materialsDescriptions,
);

export const selectMaterialPackingMethods = createSelector(
  stateSelector,
  (state: MaterialsState) => state.materialPackingMethods,
);
