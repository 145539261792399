import { MaterialDurability } from '@app/state/interfaces';

export const materialDurabilities: {
  label: string;
  value: MaterialDurability;
  marRequired: boolean;
  clickableTestId?: string;
  inputTestId?: string;
}[] = [
  {
    value: 'permanent',
    label: 'materials.permanent_l',
    marRequired: true,
    clickableTestId: 'permanentRadioButtonClickable',
    inputTestId: 'permanentRadioButton'
  },
  {
    value: 'consumables',
    label: 'materials.consumables_l',
    marRequired: false,
    clickableTestId: 'consumablesRadioButtonClickable',
    inputTestId: 'consumablesRadioButton',
  },
  {
    value: 'temporary',
    label: 'materials.temporary_l',
    marRequired: false,
    clickableTestId: 'temporaryRadioButtonClickable',
    inputTestId: 'temporaryRadioButton',
  },
];
