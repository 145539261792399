/**
 * Used for managment of delivery stage and runs
 */
export enum DELIVERY_STAGE_STATUS {

  /**
   * Status assigned after creation of the stage.
   * Stage is now waiting for further actions
   *
   * @see POSSIBLE_STAGE_ACTIONS.CONFIRMATION_ASK
   */
  WAITING_FOR_ACTION = 'WAITING_FOR_ACTION',

  /**
   * Stage has been confirmed by the person responsible for the delivery:
   *
   * @see POSSIBLE_STAGE_ACTIONS.CONFIRM
   *
   * Supplier Operator is able now to accept that runs for the stage should start:
   * @see POSSIBLE_STAGE_ACTIONS.ACCEPT
   */
  CONFIRMED = 'CONFIRMED',
  CONFIRMED_BY_SUB = 'CONFIRMED_BY_SUB',

  /**
   * Stage has been accepted by the supplier operator:
   *
   * @see POSSIBLE_STAGE_ACTIONS.ACCEPT
   *
   * SUPPOP is able to send truck:
   * @see POSSIBLE_STAGE_ACTIONS.SEND_TRUCK
   */
  APPROVED = 'APPROVED',

  /**
   * SUPPOP sent first truck.
   *
   * @see POSSIBLE_STAGE_ACTIONS.SEND_TRUCK
   *
   * Stage is now in progress.
   */
  IN_PROGRESS = 'IN_PROGRESS',

  /**
   * Stage has been rejected by the person responsible for the stage
   * Rejecting the stage is possible only BEFORE approving the stage by the SUPPOP
   * It's not possible to start sending the trucks
   *
   * @see POSSIBLE_STAGE_ACTIONS.REJECT
   */
  REJECTED = 'REJECTED',

  /**
   * Stage has been rejected on demand of the person responsible for the stage
   * Rejecting on demand is posible AFTER approve from suppop but BEFORE sending first truck.
   * It's not possible to start sending the trucks
   *
   * @see POSSIBLE_STAGE_ACTIONS.REJECT_ON_DEMAND
   */
  REJECTED_AFTER_APPROVAL = 'REJECTED_AFTER_APPROVAL',

  /**
   * Stage has been stopped on demand of the person responsible for the stage
   * Stopping on demand is posible AFTER approve and AFTER sending first trucks (in progress).
   * It's not possible to continue sending more trucks
   *
   * @see POSSIBLE_STAGE_ACTIONS.REJECT_ON_DEMAND
   */
  STOPPED_ON_DEMAND = 'STOPPED_ON_DEMAND',

  /**
   * All of the runs (courses) are finished and the stage is completed.
   */
  FINISHED = 'FINISHED',
}
