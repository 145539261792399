import { Pipe, PipeTransform } from '@angular/core';

import { TranslateItemsService } from '../@services/translate-items.service';

@Pipe({ name: 'translateFromObject' })
export class TranslateFromObject implements PipeTransform {
  constructor(private translateItems: TranslateItemsService) {}

  transform(obj: any, params?: {defaultKey?: string; specificLang?: string}): string {
    const defaultKey = params?.defaultKey || null;
    const specificLang = params?.specificLang || null;

    return this.translateItems.getTranslationFromObject(obj, defaultKey, specificLang);
  }
}
