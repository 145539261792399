import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  equipmentReservationOpened$ = new BehaviorSubject<{
    opened: boolean;
    isUserAction?: boolean;
    id?: string;
    equipment?: {
      type: string;
      id: string;
    };
  }>({ opened: false });
  clearTimeControls$ = new Subject<void>();

  openEquipmentReservationAside(id?: string, equipment?: { type: string; id: string }): void {
    this.equipmentReservationOpened$.next({ opened: true, id, equipment });
  }

  closeEquipmentReservationAside(isUserAction = false): void {
    this.equipmentReservationOpened$.next({ opened: false, id: null, equipment: null, isUserAction });
  }

  clearTimeControls(): void {
    this.clearTimeControls$.next();
  }
}
