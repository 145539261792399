export function debounce(time = 1000) {
  let _suspended = false;

  return function(t: any, k: PropertyKey, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function() {
      if (_suspended === false) {
        _suspended = true;
        setTimeout(() => {
          _suspended = false;
        }, time);

        original.apply(this, arguments);
      }
    };

    return descriptor;
  };
}
