import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { GenericForm } from '../@interfaces';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private formBuilder: UntypedFormBuilder) {}

  public initGroup(obj: any): UntypedFormGroup {
    return this.formBuilder.group(obj);
  }

  public addGroup(form: any, formArray: string, inputGroup: any): void {
    const control = form.controls[formArray] as UntypedFormArray;
    control.push(this.initGroup(inputGroup));
  }

  public removeGroup(form: any, arrayName: string, index: number): void {
    const control = form.controls[arrayName] as UntypedFormArray;
    control.removeAt(index);
  }

  public clearFormArray(array: UntypedFormArray): void {
    while (array.length !== 0) {
      array.removeAt(0);
    }
  }

  public fillFormArray(array: UntypedFormArray, items: any[], group: any = null): void {
    if (items.length === 0) {
      return array.push(this.formBuilder.group({ ...group }));
    }

    items.forEach((item: any) => {
      if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
        array.push(this.formBuilder.control(item));
      } else {
        array.push(this.formBuilder.group({ ...item }));
      }
    });
  }

  public alignFormArray(array: UntypedFormArray, targetLength: number, group: any): void {
    if (array.length === targetLength) {
      return;
    }

    if (array.length > targetLength) {
      array.removeAt(0);
    } else {
      array.push(this.formBuilder.group({ ...group }));
    }

    this.alignFormArray(array, targetLength, group);
  }

  public validateAllFormFields(item: UntypedFormGroup | UntypedFormArray | AbstractControl): void {
    if (item instanceof UntypedFormGroup) {
      return Object.values(item.controls).forEach(control => {
        item.markAsTouched();
        this.validateAllFormFields(control);
      });
    }

    if (item instanceof UntypedFormArray) {
      return item.controls.forEach(control => {
        item.markAsTouched();
        this.validateAllFormFields(control);
      });
    }

    item.markAsTouched();
  }

  // object without validators !
  public buildStructure(origin: any): UntypedFormControl | UntypedFormArray | UntypedFormGroup {
    if (typeof origin !== 'object' || origin === null) {
      return new UntypedFormControl(origin);
    }

    if (Array.isArray(origin)) {
      const arr = new UntypedFormArray([]);
      origin.forEach(item => arr.push(this.buildStructure(item)));
      return arr;
    }

    if (typeof origin === 'object' && origin !== null) {
      const group = new UntypedFormGroup({});
      Object.keys(origin).forEach(key =>  group.addControl(key, this.buildStructure(origin[key])));
      return group;
    }
  }

  public createForm<T>(object: T): GenericForm<T> {
    if (typeof object !== 'object') {
      throw new Error('Origin must be an object');
    }

    return this.buildStructure(object) as GenericForm<T>;
  }

  public toggleState(control: AbstractControl, condition: boolean): void {
    if (!control) {
      return;
    }

    if (condition) {
      return control.enable();
    }

    control.disable();
  }
}
