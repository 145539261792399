import { Injectable } from '@angular/core';
import { HttpService } from '@app/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from './regulations.actions';

@Injectable()
export class RegulationsEffect {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.RegulationsActions>,
  ) {}

  
  public getRegulation$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_REGULATION),
      map((action: actions.GetRegulation) => action.payload),
      switchMap(({ language }) => this.http.getRegulation(language)
          .pipe(
            map(response => new actions.GetRegulationSuccess(response.regulation)),
            catchError(() => [new actions.GetRegulationFail()]),
          )),
    ));
}
