import { ActionReducer } from '@ngrx/store';

import * as effects from './places.effect';
import * as reducers from './places.reducer';

import { UnloadingPlace } from '@app/state/interfaces';

export const placesEffects: any[] = [effects.PlacesEffects];

export interface PlacesListState {
  places: UnloadingPlace[];
  place: UnloadingPlace;
  totalObjectsCount: number;
  loaded: boolean;
  loading: boolean;
}

export const placesReducer: ActionReducer<PlacesListState> =
  reducers.placesReducer;

export * from './places.action';
export * from './places.effect';
export * from './places.reducer';
export * from './places.selector';
