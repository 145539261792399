import * as actions from './delivery-stages.action';

import {
  ReducerMap,
} from '@app/state/interfaces';
import { DeliveriesState } from './index';

export const stagesReducerMap: ReducerMap<DeliveriesState> = {
  [actions.DISPATCH_STAGE_ACTION]: state => ({ ...state, deliveriesLoading: true }),
  [actions.DISPATCH_STAGE_ACTION_FAIL]: state => ({ ...state, deliveriesLoading: false }),
  [actions.DISPATCH_STAGE_ACTION_SUCCESS]: (state, payload: actions.DispatchStageActionSuccess['payload']) => {
    const { updatedDeliveryStage } = payload;
    const updatedDeliveries = state.deliveriesList.map(delivery => {
      if (delivery.id !== updatedDeliveryStage.deliveryId) {
        return delivery;
      }
      const updatedStages = delivery.deliveryStages.map(stage =>
        stage.id === updatedDeliveryStage.id ? updatedDeliveryStage : stage
      );

      return {
        ...delivery,
        deliveryStages: updatedStages
      };
    });

    return {
      ...state,
      deliveriesList: updatedDeliveries,
      deliveriesLoading: false,
    };
  },

  [actions.SEND_TRUCK]: state => ({ ...state, deliveriesLoading: true }),
  [actions.SEND_TRUCK_FAIL]: state => ({ ...state, deliveriesLoading: false }),
  [actions.SEND_TRUCK_SUCCESS]: (state, payload: actions.SendTruckSuccess['payload']) => {
    const { deliveryId, id: stageId } = payload.updatedDeliveryStage;
    const updatedDeliveries = state.deliveriesList.map(delivery => {
      if (delivery.id !== deliveryId) {
        return delivery;
      }
      const updatedStages = delivery.deliveryStages.map(
        stage => stage.id === stageId ? payload.updatedDeliveryStage : stage
      );
      return { ...delivery, deliveryStages: updatedStages };
    });

    return {
      ...state,
      deliveriesList: updatedDeliveries,
      deliveriesLoading: false,
    };
  },
};
