import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { ShellService } from '../shell/shell.service';

@Injectable({
  providedIn: 'root',
})
export class FullScreenGuard implements CanActivate, CanDeactivate<any> {
  constructor(private shellService: ShellService) {}

  canActivate(): boolean {
    this.shellService.turnOnFullScreen();
    return true;
  }

  canDeactivate(): boolean {
    this.shellService.turnOffFullScreen();
    return true;
  }
}
