import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  GetProjectSettings,
  getAllProjectSettings,
  AppState,
  getUserData,
  UpdateUser,
} from '@app/state';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, filter, takeUntil } from 'rxjs/operators';
import { User } from '@app/state/interfaces';
import { ShellService } from '@app/core/shell/shell.service';
import { Theme } from '@app/config/interfaces';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input()
  public type: 'tv' | 'default' = 'default';

  private refreshDateInterval = 60000;
  private intervalId: any;

  public currentDate = new Date();
  public build: string;
  public versionApp: string;
  public destroy$ = new Subject<void>();
  public user: User;
  public isFullScreen$ = this.shellService.isFullScreen$;

  constructor(private store: Store<AppState>, private shellService: ShellService) {}

  ngOnInit() {
    this.store
      .pipe(
        select(getAllProjectSettings),
        tap(ps => !ps && this.store.dispatch(new GetProjectSettings())),
        filter(Boolean),
        takeUntil(this.destroy$),
      )
      .subscribe(projectSettings => {
        this.build = projectSettings.build;
        this.versionApp = projectSettings.versionApp;
      });

    this.store
      .select(getUserData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
      });

    this.intervalId = setInterval(() => {
      this.currentDate = new Date();
    }, this.refreshDateInterval);
  }

  public themeChange() {
    const theme = this.user.theme === Theme.White ? Theme.Dark : Theme.White;
    const user = { id: this.user.id, theme };

    this.store.dispatch(new UpdateUser(user));
  }

  public toggleFullScreen() {
    this.shellService.toggleFullScreen();
  }

  public ngOnDestroy() {
    clearInterval(this.intervalId);

    this.destroy$.next();
    this.destroy$.complete();
  }
}
