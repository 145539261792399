import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { POSSIBLE_STAGE_ACTIONS, DeliveryRun } from '@interfaces';
import { ErrorHandlerService } from './error-handler.service';
import { createApiUrl } from './http-utils';
import { DeliveryStage, SendTruckPayload } from '@app/state/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DeliveriesHttpService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  public getDeliveryStage(deliveryId: string, stageId: string): Observable<DeliveryStage> {
    const url = createApiUrl('deliveries');

    return this.http.get<DeliveryStage>(`${url}/${deliveryId}/stages/${stageId}`).pipe(
      catchError((err) => this.errorHandlerService.errorShowAndHandle(err))
    );
  }

  public sendStageAction(
    deliveryId: string,
    stageId: string,
    actionName: POSSIBLE_STAGE_ACTIONS
  ): Observable<DeliveryStage> {
    const url = `${createApiUrl('deliveries')}/${deliveryId}/stages/${stageId}/action`;

    return this.http.post<DeliveryStage>(url, { dispatchedAction: actionName }).pipe(
      catchError((err) => this.errorHandlerService.errorShowAndHandle(err))
    );
  }

  public sendTruck(sendTruckPayload: SendTruckPayload): Observable<DeliveryStage> {
    const { deliveryId, stageId } = sendTruckPayload;
    const url = `${createApiUrl('deliveries')}/${deliveryId}/stages/${stageId}/send-truck`;

    return this.http.post<DeliveryStage>(url, sendTruckPayload).pipe(
      catchError((err) => this.errorHandlerService.errorShowAndHandle(err))
    );
  }

}
