import { Action } from '@ngrx/store';

import { UserLoginReq, TokenDecoded } from '@app/core/http/interfaces';
import { Error } from '@app/state/interfaces';
import { Params } from '@angular/router';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const USER_SIGN_OUT_SUCCESS = 'USER_SIGN_OUT_SUCCESS';
export const USER_SIGN_OUT_FAIL = 'USER_SIGN_OUT_FAIL';

export class UsersLogin implements Action {
  readonly type = USER_LOGIN;

  constructor(public payload: UserLoginReq) {}
}

export class UsersLoginSuccess implements Action {
  readonly type = USER_LOGIN_SUCCESS;

  constructor(
    public payload: {
      token: string;
      tokenDecoded: TokenDecoded;
      redirectUrl?: string;
    },
  ) {}
}

export class UsersLoginFail implements Action {
  readonly type = USER_LOGIN_FAIL;

  constructor(public payload: Error) {}
}

export class UsersSignOut implements Action {
  readonly type = USER_SIGN_OUT;

  constructor(public payload = { redirect: true }) {}
}

export class UsersSignOutSuccess implements Action {
  readonly type = USER_SIGN_OUT_SUCCESS;

  constructor(public payload?: any) {}
}

export class UsersSignOutFail implements Action {
  readonly type = USER_SIGN_OUT_FAIL;

  constructor(public payload: Error) {}
}

export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_FAIL = 'CONFIRM_ACCOUNT_FAIL';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';

export class ConfirmAccount implements Action {
  readonly type = CONFIRM_ACCOUNT;

  constructor(
    public payload: {
      email: string;
      password: string;
      token: string;
      acceptRegulations: boolean;
    },
  ) {}
}

export class ConfirmAccountFail implements Action {
  readonly type = CONFIRM_ACCOUNT_FAIL;

  constructor(public payload: Error) {}
}

export class ConfirmAccountSuccess implements Action {
  readonly type = CONFIRM_ACCOUNT_SUCCESS;
}

export const SET_REDIRECTION_LINK = 'SET_REDIRECTION_LINK';

export class SetRedirectionLink implements Action {
  readonly type = SET_REDIRECTION_LINK;

  constructor(public payload: { redirectUrl: string; queryParams: Params }) {}
}

export const REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN';
export const REFRESH_AUTH_TOKEN_FAIL = 'REFRESH_AUTH_TOKEN_FAIL';
export const REFRESH_AUTH_TOKEN_SUCCESS = 'REFRESH_AUTH_TOKEN_SUCCESS';

export class RefreshAuthToken implements Action {
  readonly type = REFRESH_AUTH_TOKEN;
}

export class RefreshAuthTokenFail implements Action {
  readonly type = REFRESH_AUTH_TOKEN_FAIL;

  constructor(public payload: Error) {}
}

export class RefreshAuthTokenSuccess implements Action {
  readonly type = REFRESH_AUTH_TOKEN_SUCCESS;

  constructor(
    public payload: {
      token: string;
      tokenDecoded: TokenDecoded;
    },
  ) {}
}

export type AuthActions =
  | UsersLogin
  | UsersLoginFail
  | UsersLoginSuccess
  | UsersSignOut
  | UsersSignOutFail
  | UsersSignOutSuccess
  | ConfirmAccount
  | ConfirmAccountFail
  | ConfirmAccountSuccess
  | SetRedirectionLink
  | RefreshAuthToken
  | RefreshAuthTokenFail
  | RefreshAuthTokenSuccess;
