import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import polylabel from 'polylabel';
import * as SVG from 'svg.js';
import { isEqual } from 'lodash-es';

import { config } from '@app/app.config';
import '../../../assets/svg.draggable.js';
import '../../../assets/svg.draw.js';
import '../../../assets/svg.select.js';
import '../../../assets/svg.resize.js';
import { ConstructionObject, EquipmentDeliveriesDetails } from '@app/state/interfaces';
import { PlanObjectSettings } from '@app/config';
import { EquipmentTypes, MapObjectTypes } from '@app/core/http/interfaces';
import { ROUTE_TYPES } from '@app/shared/@constants/route-types';
import { MapTooltip } from '@app/shared';
import { MapObjectWithTooltip } from '@app/shared/@constants/map-objects-with-tooltip';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  config = {
    id: 'drawing',
    snapToGrid: 15,
    keyCodeToFinishDraw: 13, // enter
    size: {
      width: config.plan.width,
      height: config.plan.height,
    },
    editable: true,
    shapesPossibleData: {
      rect: ['width', 'height', 'x', 'y'],
      polygon: ['points'],
      line: ['x1', 'x2', 'y1', 'y2'],
      image: ['x', 'y'],
    },
    textPositionParameters: {
      rect: ['x', 'y', 'width', 'height'],
      polygon: ['points'],
      image: ['x', 'y', 'width', 'height'],
      line: ['x1', 'y1', 'x2', 'y2'],
    },
  };
  selectedObject: any;
  private selectedObjectSource = new BehaviorSubject<any>(null);
  selectedObject$ = this.selectedObjectSource.asObservable();
  private objectsUpdatedSource = new Subject<boolean>();
  objectsUpdated$ = this.objectsUpdatedSource.asObservable();
  private tooltipDataSource = new Subject<MapTooltip>();
  tooltipData$ = this.tooltipDataSource.asObservable();
  tooltipData: MapTooltip;
  objects: any[] = [];
  deletedObjects: any[] = [];
  tooltipDataForNewDelivery: EquipmentDeliveriesDetails;
  tooltipDataForNewDelivery$ = new Subject<EquipmentDeliveriesDetails>();
  private map: SVG.Doc;
  private selectedText: any;
  private selectedDirection: any;
  private currentDrawingObject: any;
  private objectRequiresText = ['building', 'crane', 'unloadingPlace', 'gate'];
  private objectWithoutId = ['construction', 'route'];

  get constraints() {
    return {
      minX: 0,
      minY: 0,
      maxX: this.config.size.width,
      maxY: this.config.size.height,
    };
  }

  get svgType() {
    return this.currentDrawingObject?.settings?.svgType;
  }

  initMap(mapConfig?: any) {
    if (mapConfig) {
      this.config = { ...this.config, ...mapConfig };
    }

    this.map = SVG(this.config.id).size(this.config.size.width, this.config.size.height);

    if (this.config.editable) {
      this.addMapListeners();
    }
  }

  updateDimensions(dimensions): void {
    this.map?.size(dimensions.width, dimensions.height);
  }

  isCreated(id: string) {
    return this.objects.some(({ objectId }) => objectId === id);
  }

  isDeleted(id: string) {
    return this.deletedObjects.some(({ objectId }) => objectId === id);
  }

  isType(obj: any, type: EquipmentTypes | 'unloadingPlace') {
    return obj && obj.settings && obj.settings.type === type;
  }

  isNew(obj: any) {
    return !!obj && !this.isCreated(obj.id) && !this.isDeleted(obj.id);
  }

  renderMap(mapObjects: ConstructionObject[], objectsSettings: PlanObjectSettings[], initSelectionId?: string) {
    if (!mapObjects) {
      return false;
    }

    this.objects = [];
    const objectSettings = objectsSettings;

    const zIndex = {
      construction: 1,
      building: 2,
      depot: 3,
      others: 4,
    };

    const objectsToRender = [...mapObjects].sort(
      (prev, curr) => (zIndex[prev.type] || zIndex.others) - (zIndex[curr.type] || zIndex.others)
    );

    objectsToRender.forEach((object) => {
      const settings = objectSettings.find((item) =>
        !item.orientation || !object.orientation
          ? item.type === object.type
          : item.type === object.type && item.orientation === object.orientation
      );

      if (object.svgType === 'image') {
        this.renderIcon(settings, object);
      } else {
        this.renderObject(settings, object);
      }
    });

    this.unselectObject(this.selectedObject);
    this.unhighlightObjects();

    if (initSelectionId) {
      const initObject = this.objects.find((obj) => obj.objectId === initSelectionId);

      this.selectObject(initObject);
    }
  }

  isTooltipAllowedForType(type: EquipmentTypes | MapObjectTypes) {
    return MapObjectWithTooltip.includes(type);
  }

  drawObject(objectSettings: any) {
    if (!this.config.editable) {
      return false;
    }

    if (this.currentDrawingObject) {
      this.currentDrawingObject.svg.draw('cancel');
    }

    this.objects.map((object) => {
      object.svg.style('pointer-events', 'none');
    });

    const objectSVG = this.map[objectSettings.svgType]();
    objectSVG.attr(objectSettings.style);

    this.currentDrawingObject = {
      svg: objectSVG,
      settings: { ...objectSettings },
    };
  }

  drawIcon(objectSettings: any, object?: any) {
    if (!this.config.editable) {
      return false;
    }

    if (this.selectedObject) {
      this.unselectObject(this.selectedObject);
    }

    const objectSVG = this.map
      .image(objectSettings.iconUrl, objectSettings.size.width, objectSettings.size.height)
      .move(0, 0);

    const iconObject = {
      svg: objectSVG,
      settings: { ...objectSettings },
    };

    if (object && object.text) {
      this.addText(iconObject, object.text);
    }

    this.addObject(iconObject);
  }

  drawDirection(object: any) {
    const position: { x: number; y: number; transform: string } = {
      x: 10,
      y: 20,
      transform: '',
    };

    if (object.settings.directionPosition) {
      Object.assign(position, object.settings.directionPosition);
    }

    const direction = this.getProperRouteDirection(object.settings.direction);
    const directionSVG = this.map.image(object.settings.directionIcon[direction]).move(position.x, position.y);

    object.directionSVG = directionSVG;

    if (position.transform) {
      object.directionSVG.attr('transform', position.transform);
    }

    if (this.config.editable) {
      (directionSVG as any).draggable(/* this.constraints */).addClass('map-move');

      object.directionSVG.click(() => {
        this.unselectObject(this.selectedObject);
        this.selectedDirection = object.directionSVG;

        object.directionSVG.selectize({ points: false }).resize();
        object.directionSVG.draggable(/* this.constraints */);
      });
    }
  }

  updateDirection(object: any, routeDirection: string) {
    const direction = this.getProperRouteDirection(routeDirection);

    if (object.settings.direction === routeDirection) {
      return;
    }

    if (direction === ROUTE_TYPES.NO_DIRECTION) {
      object.directionSVGCopy = { ...object.directionSVG };
      object.directionSVG?.remove();
      object.directionSVG = null;
      return;
    }

    if (!object.directionSVG) {
      if (object.directionSVGCopy) {
        object.directionSVG = object.directionSVGCopy;
      }

      this.drawDirection(object);
    }

    object.directionSVG.load(object.settings.directionIcon[direction]);
  }

  getParsedObjects() {
    return this.parseObjects(this.objects);
  }

  setTooltipDataForNewDelivery(data: EquipmentDeliveriesDetails): void {
    this.tooltipDataForNewDelivery = data;
    this.tooltipDataForNewDelivery$.next(data);
  }

  getTooltipDataForNewDelivery(): EquipmentDeliveriesDetails {
    return this.tooltipDataForNewDelivery;
  }

  clearTooltipDataForNewDelivery(): void {
    this.tooltipDataForNewDelivery = null;
    this.tooltipDataForNewDelivery$.next(null);
  }

  addText(object: any, text: string | number, textPosition?: any) {
    const textString = text.toString();
    object.svgText = this.map.text(textString);

    object.svgText.font(object.settings.styleText);
    object.settings.text = textString;
    this.moveText(object);

    if (object.settings.moveText) {
      if (textPosition) {
        object.svgText.move(textPosition.x, textPosition.y);
      }

      if (textPosition && textPosition.transform) {
        object.svgText.attr('transform', textPosition.transform);
      }

      if (!this.config.editable) {
        return false;
      }

      object.svgText.draggable(this.constraints);
      object.svgText.addClass('map-move');

      object.svgText.click(() => {
        this.unselectObject(this.selectedObject);
        this.selectedText = object.svgText;

        object.svgText.selectize({ points: false }).resize();
        object.svgText.draggable(this.constraints);
      });
    }

    if (!object.settings.moveText && this.config.editable) {
      object.svg.draggable(this.constraints).on('dragmove', () => {
        this.moveText(object);
      });
    }
  }

  editText(object: any, text: string) {
    object.svgText.text(text);
    object.settings.text = text;

    if (!object.settings.moveText) {
      this.moveText(object);
    }
  }

  updateObject(id: string, params: any = {}) {
    const currentObject = this.objects.find(({ objectId }) => objectId === id);

    this.unselectObject(currentObject);

    const nameChanged = currentObject.settings.text !== params.name;

    if (nameChanged) {
      this.editText(currentObject, params.name);
      this.objectsUpdatedSource.next(true);
    }
  }

  getTextParameters(svgType: string, object: any): any {
    const parameters = {};

    this.config.textPositionParameters[svgType].forEach((item: string) => {
      parameters[item] = Number(object.svg.node.attributes[item].value);
    });

    return parameters;
  }

  getPolygonTextCoordinate(svgPoints: string) {
    let polygonPoints: number[][] = [];

    if (svgPoints) {
      polygonPoints = svgPoints.split(' ').map((item) => item.split(',').map((item) => Number(item)));
    }

    return polylabel([polygonPoints]);
  }

  highlightObjectsById(ids: string[], unhighlightObjects = false) {
    if (unhighlightObjects) {
      this.unhighlightObjects();
    }

    ids.forEach((id) => {
      const object = this.objects.find((obj) => !!id && obj.objectId === id);

      if (object) {
        this.highlightObject(object);
      }
    });
  }

  highlightObject(object: any) {
    if (!object?.svg) {
      return;
    }
    object.svg.addClass('selected');

    if (object.settings.iconUrlSelected) {
      object.svg.load(
        object.settings.hotspot ? object.settings.hotspotIconUrlSelected : object.settings.iconUrlSelected
      );
    }
  }

  selectObject(object: any) {
    if (!this.config.editable) {
      this.unhighlightObjects();
      this.highlightObject(object);
      this.selectedObjectSource.next(object);
      return;
    }

    this.unselectText();
    this.unselectDirection();
    if (this.selectedObject) {
      this.unselectObject(this.selectedObject);
    }
    this.selectedObject = object;
    this.selectedObjectSource.next(this.selectedObject);

    this.highlightObject(object);

    object.svg.draggable(this.constraints);
    if (object.settings.svgType === 'image') {
      object.svg.addClass('map-move');
    }

    if (this.config.editable && object.settings.rotate) {
      object.svg.selectize({ points: false }).resize();
    }

    if (this.config.editable && object.settings.resize) {
      object.svg
        .selectize({ deepSelect: true })
        .resize()
        .on('resizing', () => {
          this.moveText(object);
        });
    }
  }

  unselectObject(object = this.selectedObject) {
    if (!this.config.editable) {
      this.unhighlightObjects();
      this.selectedObjectSource.next(null);
    }

    this.unselectText();
    this.unselectDirection();

    if (!object) {
      return false;
    }

    if (object.settings.iconUrlSelected) {
      object.svg.load(
        this.selectedObject && this.selectedObject.settings && this.selectedObject.settings.hotspot
          ? object.settings.hotspotIconUrl
          : object.settings.iconUrl
      );
    }

    if (!this.config.editable) {
      object.svg.removeClass('selected');
      return false;
    }

    object.svg.selectize(false, { deepSelect: true });
    object.svg.selectize(false);
    object.svg.removeClass('selected');

    this.selectedObject = null;
    this.selectedObjectSource.next(this.selectedObject);
  }

  togglePlaceType(object: any, hotspot: boolean) {
    object.settings.hotspot = hotspot;
    object.svg.load(hotspot ? object.settings.hotspotIconUrlSelected : object.settings.iconUrlSelected);
  }

  removeObject(objectToRemove: any) {
    if (!objectToRemove) {
      return false;
    }

    this.deletedObjects.push(objectToRemove);
    this.unselectObject(objectToRemove);
    objectToRemove.svg.remove();
    objectToRemove.svgText?.remove();
    objectToRemove.directionSVG?.remove();

    this.objects = this.objects.filter((obj) => obj.svg !== objectToRemove.svg);
  }

  private getProperRouteDirection(routeDirection: string): string {
    const baseDirection = ROUTE_TYPES.ONE_WAY;

    if (routeDirection === ROUTE_TYPES.TWO_WAY || routeDirection === ROUTE_TYPES.NO_DIRECTION) {
      return routeDirection;
    }

    return baseDirection;
  }

  private addMapListenerForPolygon() {
    document.addEventListener('keydown', (e: any) => {
      if (!this.currentDrawingObject || this.svgType !== 'polygon') {
        return false;
      }

      if (e.keyCode === this.config.keyCodeToFinishDraw) {
        this.currentDrawingObject.svg.draw('done');
        this.currentDrawingObject.svg.off('drawstart');
        this.addObject(this.currentDrawingObject);
      }
    });
  }

  private addMapListeners() {
    if (!this.config.editable) {
      return false;
    }

    this.map.on('mousedown', (e: any) => {
      if (!this.currentDrawingObject) {
        this.unselectText();
        this.unselectDirection();

        if (this.selectedObject) {
          this.unselectObject(this.selectedObject);
        }

        return false;
      }

      this.currentDrawingObject.svg.draw(e, {
        // snapToGrid: this.config.snapToGrid,
      });

      const pointsXY = this.currentDrawingObject.svg.array().value;

      if (this.svgType === 'line' && !isEqual(pointsXY[0], pointsXY[1])) {
        this.addObject(this.currentDrawingObject);
      }

      this.map.on('mouseup', (event: any) => {
        if (!this.currentDrawingObject || this.svgType === 'polygon' || this.svgType === 'line') {
          return false;
        }

        this.currentDrawingObject.svg.draw(event);
        this.addObject(this.currentDrawingObject);
      });
    });

    this.addMapListenerForPolygon();
  }

  private addObject(object: any, select: boolean = true) {
    this.currentDrawingObject = null;

    if (select) {
      this.selectObject(object);
    }

    object.svg.click(() => {
      this.selectObject(object);
    });

    const allowTooltip = this.isTooltipAllowedForType(object.settings.type);

    if (allowTooltip) {
      object.svg.on('mouseover', (event: MouseEvent) => {
        const mousePosition = {
          x: event.offsetX,
          y: event.offsetY,
        };

        this.highlightObject(object);
        this.tooltipDataSource.next({
          mousePosition,
          object,
        });
      });

      object.svg.on('mouseleave', () => {
        this.tooltipDataSource.next(null);
        this.unhighlightObjects();
      });
    }

    if (object.settings.type === 'route' && object.settings.direction !== ROUTE_TYPES.NO_DIRECTION) {
      this.drawDirection(object);
    }

    this.objects.push(object);
    this.objects.forEach((obj) => {
      obj.svg.style('pointer-events', 'auto');
    });
  }

  private renderObject(objectSettings: PlanObjectSettings, object: ConstructionObject) {
    let objectSVG;
    if (object.svgType === 'rect') {
      objectSVG = this.map.rect();
      objectSVG.size(object.width, object.height);
      objectSVG.move(object.x, object.y);
    }

    if (object.svgType === 'polygon') {
      objectSVG = this.map.polygon(object.points);
    }

    if (object.svgType === 'line') {
      objectSVG = this.map.line(object.x1, object.y1, object.x2, object.y2);
    }

    objectSVG.attr(objectSettings.style);

    this.currentDrawingObject = {
      objectId: object.objectId,
      svg: objectSVG,
      settings: {
        ...objectSettings,
        directionPosition: { ...object.directionPosition },
      },
    };

    if (object.direction) {
      this.currentDrawingObject.settings.direction = object.direction;
    }

    if (object.text) {
      this.addText(this.currentDrawingObject, object.text, object.textPosition);
    }

    this.addObject(this.currentDrawingObject, false);
  }

  private renderIcon(objectSettings: PlanObjectSettings, object: ConstructionObject) {
    if (!objectSettings) {
      return;
    }

    const objectSVG: SVG.Image = this.map
      .image(
        object.hotspot ? objectSettings.hotspotIconUrl : objectSettings.iconUrl,
        objectSettings.size.width,
        objectSettings.size.height
      )
      .move(object.x, object.y);

    if (object.transform) {
      objectSVG.attr('transform', object.transform);
    }

    const iconObject = {
      objectId: object.objectId,
      svg: objectSVG,
      settings: { ...objectSettings, hotspot: object.hotspot },
    };

    if (object && object.text) {
      this.addText(iconObject, object.text, object.textPosition);
    }

    this.addObject(iconObject, false);
  }

  private getTextPosition(object: any, parameters: any, textDetails: any) {
    let polygonCoordinate: number[] = [0, 0];
    if (object.settings.svgType === 'polygon') {
      const points = object.svg.node.getAttribute('points');
      polygonCoordinate = this.getPolygonTextCoordinate(points);
    }

    const positions = {
      building: {
        x: polygonCoordinate[0] - textDetails.width / 2,
        y: polygonCoordinate[1] - textDetails.height / 2,
      },
      depot: {
        x: polygonCoordinate[0] - textDetails.width / 2,
        y: polygonCoordinate[1] - textDetails.height / 2,
      },
      crane: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + textDetails.height + 11,
      },
      other: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + textDetails.height + 11,
      },
      elevator: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + textDetails.height + 11,
      },
      excavator: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + textDetails.height + 11,
      },
      forklift: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + textDetails.height + 11,
      },
      miniExcavator: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + textDetails.height + 11,
      },
      mobileCrane: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + textDetails.height + 11,
      },
      unloadingPlace: {
        x: parameters.x + parameters.width / 2 - textDetails.width / 2,
        y: parameters.y + parameters.height / 2 - textDetails.height / 2,
        vertical: {
          x: parameters.x + parameters.width / 2 - textDetails.width / 2,
          y: parameters.y + parameters.height / 2 - textDetails.height / 2,
          rotate: true,
          transformOrigin: `${parameters.x + parameters.width / 2}px ${parameters.y + parameters.height / 2}px`,
        },
      },
    };

    return positions[object.settings.type];
  }

  private moveText(object: any) {
    if (object.settings.type === 'construction' || !object.svgText) {
      return false;
    }

    if (object.settings.moveText) {
      object.svgText.move(10, 10);
      return false;
    }

    const textDetails = object.svgText.bbox();

    if (!object.settings.moveText) {
      object.svgText.style('pointer-events', 'none');
    }

    const parameters = this.getTextParameters(object.settings.svgType, object);
    const position = this.getTextPosition(object, parameters, textDetails);

    if (object.settings.type === 'route' && parameters.y1 !== parameters.y2) {
      object.settings.orientation = 'vertical';
    }

    if (object.settings.orientation === 'vertical' && position.vertical) {
      object.svgText.move(position.vertical.x, position.vertical.y);

      if (position.vertical.rotate) {
        object.svgText.style('transform', 'rotate(90deg)');
        object.svgText.style('transform-origin', position.vertical.transformOrigin);
      }

      return false;
    }

    object.svgText.move(position.x, position.y);
  }

  private unhighlightObjects() {
    this.objects.forEach((o) => {
      o.svg.removeClass('selected');
      if (o.settings.iconUrlSelected) {
        o.svg.load(o.settings && o.settings.hotspot ? o.settings.hotspotIconUrl : o.settings.iconUrl);
      }
    });
  }

  private unselectText() {
    if (this.selectedText) {
      this.selectedText.selectize(false);
      this.selectedText = null;
    }
  }

  private unselectDirection() {
    if (this.selectedDirection) {
      this.selectedDirection.selectize(false);
      this.selectedDirection = null;
    }
  }

  private parseObjects(objects: any[]) {
    let parsedObjects: any[] = [];
    const shapes = Object.keys(this.config.shapesPossibleData);

    objects.forEach((item) => {
      shapes.forEach((shape) => {
        const data: any = {};
        const svg = item.svg.node.attributes;

        if (item.settings.svgType !== shape) {
          return false;
        }

        if (item.settings.type === 'gate' && svg['transform']) {
          data.transform = svg['transform'].value;
        }

        const textPosition =
          item.settings.moveText && item.svgText
            ? {
                x: item.svgText.node.attributes.x.value,
                y: item.svgText.node.attributes.y.value,
                transform: item.svgText.node.attributes.transform ? item.svgText.node.attributes.transform.value : '',
              }
            : null;

        const directionPosition =
          item.settings.moveText && item.directionSVG
            ? {
                x: item.directionSVG.node.attributes.x.value,
                y: item.directionSVG.node.attributes.y.value,
                transform: item.directionSVG.node.attributes.transform
                  ? item.directionSVG.node.attributes.transform.value
                  : '',
              }
            : null;

        this.config.shapesPossibleData[shape].forEach((parameter: string) => {
          data[parameter] = svg[parameter].value;
        });

        const parsedObject = {
          objectId: item.objectId,
          type: item.settings.type,
          direction: item.settings.direction || '',
          text: item.settings.text || '',
          svgType: item.settings.svgType,
          hotspot: item.settings.hotspot,
          orientation: item.settings.orientation || '',
          ...data,
          textPosition: { ...textPosition },
          directionPosition: { ...directionPosition },
        };

        parsedObjects.push(parsedObject);
      });
    });

    parsedObjects = parsedObjects.filter((obj) => {
      const requiredText = this.objectRequiresText.includes(obj.type);
      const withoutId = this.objectWithoutId.includes(obj.type);
      return (withoutId || !!obj.objectId) && (!requiredText || !!obj.text);
    });

    return parsedObjects;
  }
}
