import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './contracts.action';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class ContractsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.ContractsAction>,
    private alertService: AlertService,
  ) {}

  
  getContracts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_CONTRACTS),
    map((action: actions.GetContracts) => action.payload),
    switchMap(filters =>
      this.http.getContracts(filters).pipe(
        map(res => new actions.GetContractsSuccess(res.data)),
        catchError(error => [new actions.GetContractsFail()]),
      ),
    ),
  ));

  
  getContract$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_CONTRACT),
    map((action: actions.GetContract) => action.payload),
    switchMap(data =>
      this.http.getContract(data).pipe(
        map(res => new actions.GetContractSuccess(res)),
        catchError(error => [new actions.GetContractFail()]),
      ),
    ),
  ));

  
  getSubcontractorContracts$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_SUBCONTRACTOR_CONTRACTS),
      map((action: actions.GetSubcontractorContracts) => action.payload),
      switchMap(payload =>
        this.http.getSubcontractorContracts(payload).pipe(
          map(res => new actions.GetSubcontractorContractsSuccess(res)),
          catchError(error => [new actions.GetSubcontractorContractsFail()]),
        ),
      ),
    ));

  
  createContract$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.CREATE_CONTRACT),
    map((action: actions.CreateContract) => action.payload),
    switchMap(contract =>
      this.http.createContract(contract).pipe(
        map(newContract => {
          this.alertService.showInfo('alert.contract_added_l');
          return new actions.CreateContractSuccess(newContract);
        }),
        catchError(() => [new actions.CreateContractFail()]),
      ),
    ),
  ));

  
  updateContract$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_CONTRACT),
    map((action: actions.UpdateContract) => action.payload),
    switchMap(contract =>
      this.http.updateContract(contract).pipe(
        map(updatedContract => {
          this.alertService.showInfo('alert.updated_l');
          return new actions.UpdateContractSuccess(updatedContract);
        }),
        catchError(() => [new actions.CreateContractFail()]),
      ),
    ),
  ));
}
