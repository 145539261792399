import { createSelector, createFeatureSelector } from '@ngrx/store';
import {roles, rolesLoaded, rolesLoading, rolesError } from './roles.reducer';
import { RolesState } from '.';

const stateSelector = createFeatureSelector<RolesState>('roles');

export const getRolesObjects = createSelector(stateSelector, roles);
export const getRolesLoaded = createSelector(stateSelector, rolesLoaded);
export const getRolesLoading = createSelector(stateSelector, rolesLoading);
export const getRolesError = createSelector(stateSelector, rolesError);
