import { Directive, Input, HostBinding } from '@angular/core';

@Directive({ selector: '[display]' })
export class DisplayDirective {
  @HostBinding('style.display')
  public styleDisplay: string;

  @Input()
  public set display(isDisplayed: boolean) {
    this.styleDisplay = isDisplayed ? 'initial' : 'none';
  }
}
