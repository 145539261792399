import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  constructor() {}

  transform(val: string): string {
    if (!val) {
      return '';
    }
    return val.match(/.{1,3}/g).join(' ');
  }
}
