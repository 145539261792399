import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Validators } from '@angular/forms';
import { GenericForm } from '@app/shared/@interfaces';
import { VehicleSize, Subcontractor, Building, Equipment, User } from '@app/state/interfaces';
import { IMyDateModel, IMyOptions } from 'mydatepicker';
import { config } from '@app/app.config';
import { DateTimeService } from '@app/core/date-time.service';
import { AlertService } from '@ppgt/web/shared/core';
import { DeliveryType } from '@ppgt/web/shared/domain';
import * as moment from 'moment-timezone';
import { DeliveryEmergencyCreation, CreateDeliveryReq } from '@app/core/http/interfaces';
import { FormService } from '@app/shared/@services/form.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface NewDeliveryEmergency {
  unloadingTime: number;
  buildingId: string;
  subcontractorId: string;
  vehicleSizeId: string;
  destination: string;
  destinationEquipmentId: string;
  date: IMyDateModel;
  time: string;
}

@Component({
  selector: 'app-add-delivery-emergency',
  templateUrl: './add-delivery-emergency.component.html',
})
export class AddDeliveryEmergencyComponent implements OnInit, OnDestroy {
  @Input() public lang: string;
  @Input() public hasSubcontractorInputPermission: boolean;
  @Input() public buildings: Building[] = [];
  @Input() public vehicleSizes: VehicleSize[];
  @Input() public subcontractors: Subcontractor[];
  @Input() public unloadingTimes: { label: string; value: number }[];
  @Input() public me: User;

  @Output() private createDelivery = new EventEmitter<CreateDeliveryReq>();
  @Output() public close = new EventEmitter<void>();
  @Output() private formChanged = new EventEmitter<NewDeliveryEmergency>();

  public form: GenericForm<NewDeliveryEmergency>;
  public emergencyPurposes: { key: string; value: string }[];
  public timeSeries: string[];
  public equipment: Equipment[];
  public datePickerOptions: IMyOptions;
  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private dateTime: DateTimeService,
    private alertService: AlertService
  ) {}

  public ngOnInit() {
    this.form = this.createForm();
    this.timeSeries = this.dateTime.createTimeSeries();
    this.datePickerOptions = this.dateTime.getDatePickerOptions();
    this.listenToFormChange();

    const purposesEntries = Object.entries(config.deliveryPurposes);
    this.emergencyPurposes = purposesEntries.map(([key, value]) => ({ key, value }));

    const date = this.dateTime.getDatePickerToday();
    this.form.get('date').patchValue(date);

    if (this.hasSubcontractorInputPermission) {
      this.form.get('subcontractorId').setValue(this.me.subcontractor.id);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private listenToFormChange(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.form.dirty)
      )
      .subscribe((data) => this.formChanged.emit(data));
  }

  public hasError(name: string, errorType: string): boolean {
    return this.form.get(name).hasError(errorType) && this.form.get(name).touched;
  }

  private createForm() {
    return this.formService.initGroup({
      unloadingTime: [null, Validators.required],
      buildingId: [null, Validators.required],
      subcontractorId: [null, Validators.required],
      vehicleSizeId: [null, Validators.required],
      destination: [null, Validators.required],
      destinationEquipmentId: null,
      date: [null, Validators.required],
      time: [null, Validators.required],
    });
  }

  public onBuildingChange(building: Building) {
    this.equipment = building.equipment.sort((eq1, eq2) => eq1.shortName.localeCompare(eq2.shortName, 'en-u-kn'));
  }

  @HostListener('document:keydown.enter')
  public addDelivery() {
    if (this.form.invalid) {
      this.formService.validateAllFormFields(this.form);
      this.alertService.showError('alert.invalid_data_l');
      return;
    }

    const { time, date, ...restData } = this.form.getRawValue();
    const dateUnloading = moment.tz(`${date.formatted} ${time}`, 'DD/MM/YYYY HH:mm', this.dateTime.timezone).valueOf();

    const delivery: DeliveryEmergencyCreation = {
      typeDelivery: DeliveryType.Onetime,
      emergency: true,
      passengers: 0,
      dateUnloading,
      ...restData,
    };

    this.createDelivery.emit({ delivery, openPanel: true });
  }
}
