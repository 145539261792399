import { createSelector, createFeatureSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { SettingsState } from './settings.reducer';

const stateSelector = createFeatureSelector<SettingsState>('settings');

export const getAllSettings = createSelector(stateSelector, state => state.settings);

export const getAllDeliverySettings = createSelector(
  stateSelector,
  state => state.deliverySettings,
);

export const getAllObjectSettings = createSelector(stateSelector, state => state.objectSettings);

export const getAllSubcontractorSettings = createSelector(
  stateSelector,
  state => state.subcontractorSettings,
);

export const getAllEcologySettings = createSelector(stateSelector, state => state.ecologySettings);

export const getBreeamRequirement = createSelector(
  stateSelector,
  state => get(state, ['ecologySettings', 'monitoringCo2'], null) as boolean,
);

export const getAllProjectSettings = createSelector(stateSelector, state => state.projectSettings);

export const getAllVehicleSizes = createSelector(stateSelector, state => state.vehicleSizes);

export const getSettingsLoading = createSelector(stateSelector, state => state.loading);

export const getWorkingHours = createSelector(stateSelector, state => ({
  from: get(state, 'settings.workFrom', null) as string,
  to: get(state, 'settings.workTo', null) as string,
}));

export const selectScheduleColorsSets = createSelector(stateSelector, state => state.scheduleColorsSets);
