import { isFunction } from 'lodash-es';

import {} from '@app/state/interfaces'; // add interfaces

import * as actions from './construction-objects.action';
import { ConstructionObjectsState } from './';

const initialState: ConstructionObjectsState = {
  objects: null,
  object: null,
  loaded: false,
};

const reducerMap = {
  [actions.GET_CONSTRUCTION_OBJECTS_SUCCESS]: (
    state: ConstructionObjectsState,
    objects: any
  ) => ({
    ...state,
    ...objects,
    loaded: true,
  }),
  [actions.CREATE_CONSTRUCTION_OBJECT_SUCCESS]: (
    state: ConstructionObjectsState,
    object: any
  ) => ({
    ...state,
    ...object,
  }),
  [actions.GET_CONSTRUCTION_OBJECT_SUCCESS]: (
    state: ConstructionObjectsState,
    object: any
  ) => ({
    ...state,
    ...object,
    loaded: true,
  }),
};

export function constructionObjectsReducer(
  state: ConstructionObjectsState = initialState,
  action: any
) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getConstructionObjects = (state: ConstructionObjectsState) =>
  state.objects;
export const getConstructionObject = (state: ConstructionObjectsState) =>
  state.object;
export const getConstructionObjectLoaded = (state: ConstructionObjectsState) =>
  state.loaded;
