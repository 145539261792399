import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { config } from '@app/app.config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SvgIconService } from './svg-icon.service';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() color: string;
  @Input() size: string | number = 20;
  @Input() height: string | number;
  @Input() width: string | number;
  @Input() opacity = 1;
  @Input() crossOut: string = null;
  @Input() iconsPath = 'assets/icons';

  public destroy$ = new Subject<void>();
  public appColors = config.colors;
  private theme: string;

  public default = this.appColors['dune-gray'];
  public dustyGray = this.appColors['dusty-gray'];

  private colorsConfig = {
    'icon-ok-blue': this.appColors.blue,
    'icon-done-red': this.appColors.red,
    'icon-delayed-red': this.appColors.red,
    'arrow-down-white': this.appColors.white,
    'arrow-up-white': this.appColors.white,
    'arrow-white': this.appColors.white,
    close: this.appColors.white,
    'claim-ok': this.dustyGray,
    'icon-add': this.appColors.blue,
    'icon-user': this.appColors.blue,
    'icon-alert-close': this.appColors.white,
    'icon-allow': this.appColors.blue,
    'icon-allow-white': this.appColors.white,
    'icon-deny': this.appColors.red,
    'icon-deny-white': this.appColors.white,
    'icon-arrow-black': this.default,
    'icon-arrow-blue': this.appColors.blue,
    'icon-arrow-white': this.appColors.white,
    'icon-arrow-white-double': this.appColors.white,
    'icon-inactive': this.dustyGray,
    'icon-remove': this.appColors.white,
    'icon-search-gray': this.dustyGray,
    'icon-clear-gray': this.dustyGray,
    'icon-direction-import-white': this.appColors.white,
    'icon-direction-export-white': this.appColors.white,
    'icon-direction-both-white': this.appColors.white,
    'icon-pallet': this.appColors.white,
    'icon-cards': this.appColors.blue,
    'icon-mail': this.appColors.blue,
    'icon-mail-white': this.appColors.white,
    'icon-phone': this.appColors.blue,
    'icon-phone-white': this.appColors.white,
    'icon-phone-red': this.appColors.red,
    'icon-message': this.appColors.blue,
    'icon-message-white': this.appColors.white,
    'status-new': this.appColors.white,
    'status-accepted': this.appColors.white,
    'status-completed': this.appColors.white,
    'status-currently': this.appColors.white,
    'status-expected': this.appColors.white,
    'status-rejected': this.appColors.white,
    'status-to-accept': this.appColors.white,
    'status-to-complete': this.appColors.white,
    'icon-delivery-combined': this.appColors.blue,
    'icon-delivery-combined-red': this.appColors.red,
    'icon-delivery-vehicle': this.appColors.blue,
    'icon-delivery-vehicle-red': this.appColors.red,
    'icon-delivery-distance': this.appColors.blue,
    'icon-delivery-distance-red': this.appColors.red,
    'icon-delivery-registration': this.appColors.blue,
    'icon-delivery-registration-red': this.appColors.red,
    'type-emergency-red': this.appColors.red,
    'inspection-negative': this.appColors.red,
    'inspection-not-required': this.dustyGray,
    'inspection-positive': this.appColors.blue,
    'icon-material-inspected-yes': this.appColors.blue,
    'icon-clock-blue': this.appColors.blue,
    'icon-clock-gray': this.appColors['alto-gray'],
    'icon-clock-alt-gray': this.dustyGray,
    'icon-clock-alt-red': this.appColors.red,
    'icon-plus': this.appColors.white,
    'icon-cancel-circle': this.appColors.red,
    'icon-ok-circle': this.appColors.blue,
    'icon-flammable-white': this.appColors.white,
    'icon-linked-white': this.appColors.white,
    'icon-edit-mode': this.appColors.white,
    'icon-building': this.appColors.white,
    'icon-qr-code': this.appColors.white,
    'icon-package': this.appColors.white,
    'icon-inspection-ready': this.appColors.white,
    'icon-order-phase-blue': this.appColors.blue,
    'icon-order-phase-gray': this.dustyGray,
    'icon-order-day-blue': this.appColors.blue,
    'icon-order-day-gray': this.dustyGray,
    'icon-order-week-blue': this.appColors.blue,
    'icon-order-week-gray': this.dustyGray,
    'icon-close-white': this.appColors.white,
    'icon-close-blue': this.appColors.blue,
    'ok-circle-empty-gray': this.dustyGray,
    'ok-circle-filled-gray': this.dustyGray,
    'ok-circle-empty-white': this.appColors.white,
    'ok-circle-filled-white': this.appColors.white,
    'ok-circle-empty-red': this.appColors.red,
    'arrow-right-circle-filled-gray': this.dustyGray,
    'arrow-right-circle-filled-white': this.appColors.white,
    'question-mark-circle-black-empty': this.dustyGray,
    'question-mark-circle-white-empty': this.appColors.white,
    'question-mark-circle-gray-empty': this.dustyGray,
    'x-circle-red': this.appColors.red,
    'stop-circle-red': this.appColors.red,
    'icon-local-parking': this.appColors.white,
    'icon-cs-checkin': this.appColors.white,
  };

  constructor(private svgIconService: SvgIconService, private chDetRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.svgIconService.theme$.pipe(takeUntil(this.destroy$)).subscribe((theme) => {
      this.theme = theme;
      this.chDetRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSVGLoaded = (svg: SVGElement): SVGElement => {
    svg.setAttribute('width', `${this.width || this.size}`);
    svg.setAttribute('height', `${this.height || this.size}`);
    return svg;
  };

  get style() {
    const style = {
      opacity: this.opacity,
      background: 'none',
    };

    if (this.crossOut) {
      style.background = `linear-gradient(45deg, transparent 0% 48%, ${this.crossOut} 48% 52%, transparent 52% 100%)`;
    }

    const color = this.color || this.colorsConfig[this.name];
    if (color) {
      style['color'] = color;
    }
    return style;
  }
}
