<div [class]="theme$ | async"
     [class.fullscreen]="isFullScreen$ | async">
  <ng-container *ngIf="userRole">
    <div class="page-content"
         [class.page-content--no-footer]="!showFooter">
      <app-menu *ngIf="(withMenu$ | async) && (isFullScreen$ | async) !== true"
                #menu></app-menu>
      <div [display]="!(menu?.openedMenu)">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer *ngIf="showFooter"
                [display]="!(menu?.openedMenu)">
    </app-footer>
  </ng-container>
</div>
