import { createSelector, createFeatureSelector } from '@ngrx/store';
import { PermissionsState } from './permissions.reducer';

const stateSelector = createFeatureSelector<PermissionsState>('permissions');

export const getPermissionsObjects = createSelector(
  stateSelector,
  (state: PermissionsState) => state.permissions,
);

export const getSectionsObjects = createSelector(
  stateSelector,
  (state: PermissionsState) => state.sections,
);

export const getPermissionsLoaded = createSelector(
  stateSelector,
  (state: PermissionsState) => state.isLoaded,
);

export const getPermissionsLoading = createSelector(
  stateSelector,
  (state: PermissionsState) => state.isLoading,
);
export const getPermissionsError = createSelector(
  stateSelector,
  (state: PermissionsState) => state.error,
);
