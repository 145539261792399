import { Component, HostListener } from '@angular/core';
import { SearchService } from '../search/search.service';

@Component({
  selector: 'app-reset-sorting',
  templateUrl: './reset-sorting.component.html',
  styleUrls: ['./reset-sorting.component.scss'],
})
export class ResetSortingComponent {
  @HostListener('click', ['$event'])
  onClick() {
    this.resetSorting();
  }

  constructor(private searchService: SearchService) {}

  resetSorting() {
    this.searchService.resetSorting();
  }
}
