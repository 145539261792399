import { Action } from '@ngrx/store';

import {
  UpdateConstructionReq,
  UpdateConstructionPlanReq,
  UpdateConstructionPlanSuccessRes,
} from '@app/core/http/interfaces';
import { Construction, ConstructionPlanDimensions } from '@app/state/interfaces';

// load construction plans
export const GET_CONSTRUCTIONS = 'GET_CONSTRUCTIONS';
export const GET_CONSTRUCTIONS_FAIL = 'GET_CONSTRUCTIONS_FAIL';
export const GET_CONSTRUCTIONS_SUCCESS = 'GET_CONSTRUCTIONS_SUCCESS';

export class GetConstructions implements Action {
  readonly type = GET_CONSTRUCTIONS;
}

export class GetConstructionsFail implements Action {
  readonly type = GET_CONSTRUCTIONS_FAIL;
}

export class GetConstructionsSuccess implements Action {
  readonly type = GET_CONSTRUCTIONS_SUCCESS;

  constructor(public payload: Construction[]) {}
}

// create construction plan
export const CREATE_CONSTRUCTION = 'CREATE_CONSTRUCTION';
export const CREATE_CONSTRUCTION_FAIL = 'CREATE_CONSTRUCTION_FAIL';
export const CREATE_CONSTRUCTION_SUCCESS = 'CREATE_CONSTRUCTION_SUCCESS';

export class CreateConstruction implements Action {
  readonly type = CREATE_CONSTRUCTION;

  constructor(public payload: any) {}
}

export class CreateConstructionFail implements Action {
  readonly type = CREATE_CONSTRUCTION_FAIL;
}

export class CreateConstructionSuccess implements Action {
  readonly type = CREATE_CONSTRUCTION_SUCCESS;

  constructor(public payload: any) {}
}

// load selected construction plan
export const GET_CONSTRUCTION = 'GET_CONSTRUCTION';
export const GET_CONSTRUCTION_FAIL = 'GET_CONSTRUCTION_FAIL';
export const GET_CONSTRUCTION_SUCCESS = 'GET_CONSTRUCTION_SUCCESS';

export class GetConstruction implements Action {
  readonly type = GET_CONSTRUCTION;

  constructor(public payload: { id: string }) {}
}

export class GetConstructionFail implements Action {
  readonly type = GET_CONSTRUCTION_FAIL;
}

export class GetConstructionSuccess implements Action {
  readonly type = GET_CONSTRUCTION_SUCCESS;

  constructor(public payload: Construction) {}
}

// load current construction plan
export const GET_CURRENT_CONSTRUCTION = 'GET_CURRENT_CONSTRUCTION';
export const GET_CURRENT_CONSTRUCTION_FAIL = 'GET_CURRENT_CONSTRUCTION_FAIL';
export const GET_CURRENT_CONSTRUCTION_SUCCESS =
  'GET_CURRENT_CONSTRUCTION_SUCCESS';

export class GetCurrentConstruction implements Action {
  readonly type = GET_CURRENT_CONSTRUCTION;
}

export class GetCurrentConstructionFail implements Action {
  readonly type = GET_CURRENT_CONSTRUCTION_FAIL;
}

export class GetCurrentConstructionSuccess implements Action {
  readonly type = GET_CURRENT_CONSTRUCTION_SUCCESS;

  constructor(public payload: Construction) {}
}

// load future construction plan
export const GET_FUTURE_CONSTRUCTION = 'GET_FUTURE_CONSTRUCTION';
export const GET_FUTURE_CONSTRUCTION_FAIL = 'GET_FUTURE_CONSTRUCTION_FAIL';
export const GET_FUTURE_CONSTRUCTION_SUCCESS =
  'GET_FUTURE_CONSTRUCTION_SUCCESS';

export class GetFutureConstruction implements Action {
  readonly type = GET_FUTURE_CONSTRUCTION;
}

export class GetFutureConstructionFail implements Action {
  readonly type = GET_FUTURE_CONSTRUCTION_FAIL;
}

export class GetFutureConstructionSuccess implements Action {
  readonly type = GET_FUTURE_CONSTRUCTION_SUCCESS;

  constructor(public payload: Construction) {}
}

// update selected construction plan
export const UPDATE_CONSTRUCTION = 'UPDATE_CONSTRUCTION';
export const UPDATE_CONSTRUCTION_FAIL = 'UPDATE_CONSTRUCTION_FAIL';
export const UPDATE_CONSTRUCTION_SUCCESS = 'UPDATE_CONSTRUCTION_SUCCESS';

export class UpdateConstruction implements Action {
  readonly type = UPDATE_CONSTRUCTION;

  constructor(public payload: UpdateConstructionReq) {}
}

export class UpdateConstructionFail implements Action {
  readonly type = UPDATE_CONSTRUCTION_FAIL;
}

export class UpdateConstructionSuccess implements Action {
  readonly type = UPDATE_CONSTRUCTION_SUCCESS;

  constructor(public payload: Construction) {}
}

export const UPDATE_CONSTRUCTION_PLAN = 'UPDATE_CONSTRUCTION_PLAN';
export const UPDATE_CONSTRUCTION_PLAN_FAIL = 'UPDATE_CONSTRUCTION_PLAN_FAIL';
export const UPDATE_CONSTRUCTION_PLAN_SUCCESS =
  'UPDATE_CONSTRUCTION_PLAN_SUCCESS';

export class UpdateConstructionPlan implements Action {
  readonly type = UPDATE_CONSTRUCTION_PLAN;

  constructor(public payload: UpdateConstructionPlanReq) {}
}

export class UpdateConstructionPlanFail implements Action {
  readonly type = UPDATE_CONSTRUCTION_PLAN_FAIL;
}

export class UpdateConstructionPlanSuccess implements Action {
  readonly type = UPDATE_CONSTRUCTION_PLAN_SUCCESS;

  constructor(public payload: UpdateConstructionPlanSuccessRes) {}
}

export const DELETE_CONSTRUCTION_PLAN = 'DELETE_CONSTRUCTION_PLAN';
export const DELETE_CONSTRUCTION_PLAN_FAIL = 'DELETE_CONSTRUCTION_PLAN_FAIL';
export const DELETE_CONSTRUCTION_PLAN_SUCCESS =
  'DELETE_CONSTRUCTION_PLAN_SUCCESS';

export class DeleteConstructionPlan implements Action {
  readonly type = DELETE_CONSTRUCTION_PLAN;

  constructor(public payload: { id: string }) {}
}

export class DeleteConstructionPlanFail implements Action {
  readonly type = DELETE_CONSTRUCTION_PLAN_FAIL;
}

export class DeleteConstructionPlanSuccess implements Action {
  readonly type = DELETE_CONSTRUCTION_PLAN_SUCCESS;
}

// remove selected construction plan
export const REMOVE_CONSTRUCTION = 'REMOVE_CONSTRUCTION';
export const REMOVE_CONSTRUCTION_FAIL = 'REMOVE_CONSTRUCTION_FAIL';
export const REMOVE_CONSTRUCTION_SUCCESS = 'REMOVE_CONSTRUCTION_SUCCESS';

export class RemoveConstruction implements Action {
  readonly type = REMOVE_CONSTRUCTION;

  constructor(public payload: { id: string }) {}
}

export class RemoveConstructionFail implements Action {
  readonly type = REMOVE_CONSTRUCTION_FAIL;
}

export class RemoveConstructionSuccess implements Action {
  readonly type = REMOVE_CONSTRUCTION_SUCCESS;

  constructor(public payload: any) {}
}

export const UPDATE_PLAN_DIMENSIONS = 'UPDATE_PLAN_DIMENSIONS';
export const UPDATE_PLAN_DIMENSIONS_FAIL = 'UPDATE_PLAN_DIMENSIONS_FAIL';
export const UPDATE_PLAN_DIMENSIONS_SUCCESS =
  'UPDATE_PLAN_DIMENSIONS_SUCCESS';

export class UpdatePlanDimensions implements Action {
  readonly type = UPDATE_PLAN_DIMENSIONS;

  constructor(public payload: ConstructionPlanDimensions) {}
}

export class UpdatePlanDimensionsFail implements Action {
  readonly type = UPDATE_PLAN_DIMENSIONS_FAIL;
}

export class UpdatePlanDimensionsSuccess implements Action {
  readonly type = UPDATE_PLAN_DIMENSIONS_SUCCESS;
}

export type ConstructionsAction =
  | GetConstructions
  | GetConstructionsFail
  | GetConstructionsSuccess
  | GetConstruction
  | GetConstructionFail
  | GetConstructionSuccess
  | GetCurrentConstruction
  | GetCurrentConstructionFail
  | GetCurrentConstructionSuccess
  | GetFutureConstruction
  | GetFutureConstructionFail
  | GetFutureConstructionSuccess
  | CreateConstruction
  | CreateConstructionFail
  | CreateConstructionSuccess
  | UpdateConstruction
  | UpdateConstructionFail
  | UpdateConstructionSuccess
  | UpdateConstructionPlan
  | UpdateConstructionPlanFail
  | UpdateConstructionPlanSuccess
  | DeleteConstructionPlan
  | DeleteConstructionPlanFail
  | DeleteConstructionPlanSuccess
  | RemoveConstruction
  | RemoveConstructionFail
  | RemoveConstructionSuccess
  | UpdatePlanDimensions
  | UpdatePlanDimensionsFail
  | UpdatePlanDimensionsSuccess;
