import { Action } from '@ngrx/store';

import { GetContractsRes, CreateContractReq, UpdateContractReq } from '@app/core/http/interfaces';
import { ConstructionCrew } from '@app/state/interfaces';

// load construction crews
export const GET_CONSTRUCTION_CREWS = 'GET_CONSTRUCTION_CREWS';
export const GET_CONSTRUCTION_CREWS_FAIL = 'GET_CONSTRUCTION_CREWS_FAIL';
export const GET_CONSTRUCTION_CREWS_SUCCESS = 'GET_CONSTRUCTION_CREWS_SUCCESS';

export class GetConstructionCrews implements Action {
  readonly type = GET_CONSTRUCTION_CREWS;

  constructor(public payload: { subcontractorId: string }) {}
}

export class GetConstructionCrewsFail implements Action {
  readonly type = GET_CONSTRUCTION_CREWS_FAIL;
}

export class GetConstructionCrewsSuccess implements Action {
  readonly type = GET_CONSTRUCTION_CREWS_SUCCESS;

  constructor(public payload: ConstructionCrew) {}
}

// load construction crew
export const GET_CONSTRUCTION_CREW = 'GET_CONSTRUCTION_CREW';
export const GET_CONSTRUCTION_CREW_FAIL = 'GET_CONSTRUCTION_CREW_FAIL';
export const GET_CONSTRUCTION_CREW_SUCCESS = 'GET_CONSTRUCTION_CREW_SUCCESS';

export class GetConstructionCrew implements Action {
  readonly type = GET_CONSTRUCTION_CREW;

  constructor(public payload: { subcontractorId: string; crewId: string }) {}
}

export class GetConstructionCrewFail implements Action {
  readonly type = GET_CONSTRUCTION_CREW_FAIL;
}

export class GetConstructionCrewSuccess implements Action {
  readonly type = GET_CONSTRUCTION_CREW_SUCCESS;

  constructor(public payload: ConstructionCrew) {}
}

// create construction crews
export const CREATE_CONSTRUCTION_CREW = 'CREATE_CONSTRUCTION_CREW';
export const CREATE_CONSTRUCTION_CREW_FAIL = 'CREATE_CONSTRUCTION_CREW_FAIL';
export const CREATE_CONSTRUCTION_CREW_SUCCESS = 'CREATE_CONSTRUCTION_CREW_SUCCESS';

export class CreateConstructionCrew implements Action {
  readonly type = CREATE_CONSTRUCTION_CREW;

  constructor(public payload: { subcontractorId: string; data: Partial<ConstructionCrew> }) {}
}

export class CreateConstructionCrewFail implements Action {
  readonly type = CREATE_CONSTRUCTION_CREW_FAIL;
}

export class CreateConstructionCrewSuccess implements Action {
  readonly type = CREATE_CONSTRUCTION_CREW_SUCCESS;

  constructor(public payload: ConstructionCrew) {}
}

// update construction crew
export const UPDATE_CONSTRUCTION_CREW = 'UPDATE_CONSTRUCTION_CREW';
export const UPDATE_CONSTRUCTION_CREW_FAIL = 'UPDATE_CONSTRUCTION_CREW_FAIL';
export const UPDATE_CONSTRUCTION_CREW_SUCCESS = 'UPDATE_CONSTRUCTION_CREW_SUCCESS';

export class UpdateConstructionCrew implements Action {
  readonly type = UPDATE_CONSTRUCTION_CREW;

  constructor(public payload: { subcontractorId: string; crewId: string; data: Partial<ConstructionCrew> }) {}
}

export class UpdateConstructionCrewFail implements Action {
  readonly type = UPDATE_CONSTRUCTION_CREW_FAIL;
}

export class UpdateConstructionCrewSuccess implements Action {
  readonly type = UPDATE_CONSTRUCTION_CREW_SUCCESS;

  constructor(public payload: ConstructionCrew) {}
}

export type ConstructionCrewsAction =
  | GetConstructionCrews
  | GetConstructionCrewsFail
  | GetConstructionCrewsSuccess
  | CreateConstructionCrew
  | CreateConstructionCrewFail
  | CreateConstructionCrewSuccess
  | GetConstructionCrew
  | GetConstructionCrewFail
  | GetConstructionCrewSuccess
  | UpdateConstructionCrew
  | UpdateConstructionCrewSuccess
  | UpdateConstructionCrewFail;
