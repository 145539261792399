import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './construction-plan.action';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class ConstructionEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.ConstructionsAction>,
    private alertService: AlertService
  ) {}

  
  getConstructions$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_CONSTRUCTIONS),
      switchMap(() =>
        this.http.getConstructions().pipe(
          map(res => new actions.GetConstructionsSuccess(res)),
          catchError(error => [new actions.GetConstructionsFail()])
        )
      )
    ));

  
  createConstruction$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_CONSTRUCTION),
      map((action: actions.CreateConstruction) => action.payload),
      switchMap(construction =>
        this.http.createConstruction(construction).pipe(
          map(res => {
            this.alertService.showInfo('alert.construction_plan_saved_l');
            return new actions.CreateConstructionSuccess(res);
          }),
          catchError(error => [new actions.CreateConstructionFail()])
        )
      )
    ));

  
  getConstruction$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_CONSTRUCTION),
      map((action: actions.GetConstruction) => action.payload),
      switchMap(construction =>
        this.http.getConstruction(construction).pipe(
          map(res => new actions.GetConstructionSuccess(res)),
          catchError(error => [new actions.GetConstructionFail()])
        )
      )
    ));

  
  getCurrentConstruction$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_CURRENT_CONSTRUCTION),
      switchMap(() =>
        this.http.getCurrentConstruction().pipe(
          map(res => new actions.GetCurrentConstructionSuccess(res)),
          catchError(error => [new actions.GetCurrentConstructionFail()])
        )
      )
    ));

  
  getFutureConstruction$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_FUTURE_CONSTRUCTION),
      switchMap(() =>
        this.http.getFutureConstruction().pipe(
          map(res => new actions.GetFutureConstructionSuccess(res)),
          catchError(error => [new actions.GetFutureConstructionFail()])
        )
      )
    ));

  
  updateConstruction$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_CONSTRUCTION),
      map((action: actions.UpdateConstruction) => action.payload),
      switchMap(construction =>
        this.http.updateConstruction(construction).pipe(
          map(res => {
            this.alertService.showInfo('alert.construction_plan_saved_l');
            return new actions.UpdateConstructionSuccess(res);
          }),
          catchError(error => [new actions.UpdateConstructionFail()])
        )
      )
    ));

  
  updateConstructionPlan$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_CONSTRUCTION_PLAN),
      map((action: actions.UpdateConstructionPlan) => action.payload),
      switchMap(data =>
        this.http.updateConstructionPlan(data).pipe(
          map(res => {
            this.alertService.showInfo('alert.construction_plan_saved_l');
            return new actions.UpdateConstructionPlanSuccess(res);
          }),
          catchError(() => [new actions.UpdateConstructionPlanFail()])
        )
      )
    ));

  
  deleteConstructionPlan$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.DELETE_CONSTRUCTION_PLAN),
      map((action: actions.DeleteConstructionPlan) => action.payload),
      switchMap(data =>
        this.http.deleteConstructionPlan(data.id).pipe(
          map(() => new actions.DeleteConstructionPlanSuccess()),
          catchError(() => [new actions.DeleteConstructionPlanFail()])
        )
      )
    ));

  
  removeConstruction$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.REMOVE_CONSTRUCTION),
      map((action: actions.RemoveConstruction) => action.payload),
      switchMap(construction =>
        this.http.removeConstruction(construction).pipe(
          map(res => new actions.RemoveConstructionSuccess(res)),
          catchError(error => [new actions.RemoveConstructionFail()])
        )
      )
    ));

    updatePlanDimensions$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_PLAN_DIMENSIONS),
      map((action: actions.UpdatePlanDimensions) => action.payload),
      switchMap(data =>
        this.http.updatePlanDimensions(data).pipe(
          map(() => {
            this.alertService.showInfo('alert.plan_dimensions_saved');
            return new actions.UpdatePlanDimensionsSuccess();
          }),
          catchError(() => [new actions.UpdateConstructionPlanFail()])
        )
      )
    ));
}
