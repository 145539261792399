import { Action } from '@ngrx/store';
import { QueryParamObject, CreateMaterialReq, GetMaterialsRes } from '@app/core/http/interfaces';
import {
  Material,
  MaterialCategory,
  MaterialSupplier,
  MaterialProducer,
  RejectReason,
  InspectionStatus,
  MaterialDestination,
  MaterialsComment,
  MaterialsDescription,
  MaterialPackingMethod,
} from '../interfaces';

// load materials
export const GET_MATERIALS = 'GET_MATERIALS';
export const GET_MATERIALS_FAIL = 'GET_MATERIALS_FAIL';
export const GET_MATERIALS_SUCCESS = 'GET_MATERIALS_SUCCESS';
export const RESET_MATERIALS = 'RESET_MATERIALS';

export class GetMaterials implements Action {
  readonly type = GET_MATERIALS;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetMaterialsFail implements Action {
  readonly type = GET_MATERIALS_FAIL;
}

export class GetMaterialsSuccess implements Action {
  readonly type = GET_MATERIALS_SUCCESS;

  constructor(public payload: GetMaterialsRes['data']) {}
}

export class ResetMaterials implements Action {
  readonly type = RESET_MATERIALS;
}

export const GET_MATERIAL_CATEGORIES = 'GET_MATERIAL_CATEGORIES';
export const GET_MATERIAL_CATEGORIES_FAIL = 'GET_MATERIAL_CATEGORIES_FAIL';
export const GET_MATERIAL_CATEGORIES_SUCCESS = 'GET_MATERIAL_CATEGORIES_SUCCESS';

export class GetMaterialCategories implements Action {
  readonly type = GET_MATERIAL_CATEGORIES;

  constructor(public payload?: QueryParamObject[]) {}
}

export class GetMaterialCategoriesFail implements Action {
  readonly type = GET_MATERIAL_CATEGORIES_FAIL;
}

export class GetMaterialCategoriesSuccess implements Action {
  readonly type = GET_MATERIAL_CATEGORIES_SUCCESS;

  constructor(public payload: MaterialCategory[]) {}
}

export const GET_MATERIAL_CATEGORY = 'GET_MATERIAL_CATEGORY';
export const GET_MATERIAL_CATEGORY_FAIL = 'GET_MATERIAL_CATEGORY_FAIL';
export const GET_MATERIAL_CATEGORY_SUCCESS = 'GET_MATERIAL_CATEGORY_SUCCESS';

export class GetMaterialCategory implements Action {
  readonly type = GET_MATERIAL_CATEGORY;

  constructor(public payload: { id: string; params?: QueryParamObject[] }) {}
}

export class GetMaterialCategoryFail implements Action {
  readonly type = GET_MATERIAL_CATEGORY_FAIL;
}

export class GetMaterialCategorySuccess implements Action {
  readonly type = GET_MATERIAL_CATEGORY_SUCCESS;

  constructor(public payload: { [id: string]: MaterialCategory }) {}
}

export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIERS_FAIL = 'GET_SUPPLIERS_FAIL';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';

export class GetSuppliers implements Action {
  readonly type = GET_SUPPLIERS;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetSuppliersFail implements Action {
  readonly type = GET_SUPPLIERS_FAIL;
}

export class GetSuppliersSuccess implements Action {
  readonly type = GET_SUPPLIERS_SUCCESS;

  constructor(public payload: MaterialSupplier[]) {}
}

export const GET_PRODUCERS = 'GET_PRODUCERS';
export const GET_PRODUCERS_FAIL = 'GET_PRODUCERS_FAIL';
export const GET_PRODUCERS_SUCCESS = 'GET_PRODUCERS_SUCCESS';

export class GetProducers implements Action {
  readonly type = GET_PRODUCERS;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetProducersFail implements Action {
  readonly type = GET_PRODUCERS_FAIL;
}

export class GetProducersSuccess implements Action {
  readonly type = GET_PRODUCERS_SUCCESS;

  constructor(public payload: MaterialProducer[]) {}
}

export const GET_INSPECTION_REJECT_REASONS = 'GET_INSPECTION_REJECT_REASONS';
export const GET_INSPECTION_REJECT_REASONS_SUCCESS = 'GET_INSPECTION_REJECT_REASONS_SUCCESS';
export const GET_INSPECTION_REJECT_REASONS_FAIL = 'GET_INSPECTION_REJECT_REASONS_FAIL';

export class GetInspectionRejectReasons implements Action {
  readonly type = GET_INSPECTION_REJECT_REASONS;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetInspectionRejectReasonsSuccess implements Action {
  readonly type = GET_INSPECTION_REJECT_REASONS_SUCCESS;

  constructor(public payload: RejectReason[]) {}
}

export class GetInspectionRejectReasonsFail implements Action {
  readonly type = GET_INSPECTION_REJECT_REASONS_FAIL;
}

export const GET_INSPECTION_STATUSES = 'GET_INSPECTION_STATUSES';
export const GET_INSPECTION_STATUSES_SUCCESS = 'GET_INSPECTION_STATUSES_SUCCESS';
export const GET_INSPECTION_STATUSES_FAIL = 'GET_INSPECTION_STATUSES_FAIL';

export class GetInspectionStatuses implements Action {
  readonly type = GET_INSPECTION_STATUSES;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetInspectionStatusesSuccess implements Action {
  readonly type = GET_INSPECTION_STATUSES_SUCCESS;

  constructor(public payload: InspectionStatus[]) {}
}

export class GetInspectionStatusesFail implements Action {
  readonly type = GET_INSPECTION_STATUSES_FAIL;
}

export const GET_MATERIAL_DESTINATIONS = 'GET_MATERIAL_DESTINATIONS';
export const GET_MATERIAL_DESTINATIONS_SUCCESS = 'GET_MATERIAL_DESTINATIONS_SUCCESS';
export const GET_MATERIAL_DESTINATIONS_FAIL = 'GET_MATERIAL_DESTINATIONS_FAIL';

export class GetMaterialDestinations implements Action {
  readonly type = GET_MATERIAL_DESTINATIONS;
}

export class GetMaterialDestinationsSuccess implements Action {
  readonly type = GET_MATERIAL_DESTINATIONS_SUCCESS;

  constructor(public payload: MaterialDestination[]) {}
}

export class GetMaterialDestinationsFail implements Action {
  readonly type = GET_MATERIAL_DESTINATIONS_FAIL;
}

export const CREATE_MATERIAL = 'CREATE_MATERIAL';
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';
export const CREATE_MATERIAL_FAIL = 'CREATE_MATERIAL_FAIL';

export class CreateMaterial implements Action {
  readonly type = CREATE_MATERIAL;

  constructor(public payload: CreateMaterialReq) {}
}

export class CreateMaterialSuccess implements Action {
  readonly type = CREATE_MATERIAL_SUCCESS;

  constructor(public payload: Material) {}
}

export class CreateMaterialFail implements Action {
  readonly type = CREATE_MATERIAL_FAIL;
}

export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_SUCCESS';
export const UPDATE_MATERIAL_FAIL = 'UPDATE_MATERIAL_FAIL';

export class UpdateMaterial implements Action {
  readonly type = UPDATE_MATERIAL;

  constructor(public payload: { data: CreateMaterialReq; id: string }) {}
}

export class UpdateMaterialSuccess implements Action {
  readonly type = UPDATE_MATERIAL_SUCCESS;

  constructor(public payload: Material) {}
}

export class UpdateMaterialFail implements Action {
  readonly type = UPDATE_MATERIAL_FAIL;
}

export const GET_MATERIALS_COMMENTS = 'GET_MATERIALS_COMMENTS';
export const GET_MATERIALS_COMMENTS_SUCCESS = 'GET_MATERIALS_COMMENTS_SUCCESS';
export const GET_MATERIALS_COMMENTS_FAIL = 'GET_MATERIALS_COMMENTS_FAIL';

export class GetMaterialsComments implements Action {
  readonly type = GET_MATERIALS_COMMENTS;

  constructor(public payload: { materialId: string }) {}
}

export class GetMaterialsCommentsSuccess implements Action {
  readonly type = GET_MATERIALS_COMMENTS_SUCCESS;

  constructor(public payload: { materialId: string; comments: MaterialsComment[] }) {}
}

export class GetMaterialsCommentsFail implements Action {
  readonly type = GET_MATERIALS_COMMENTS_FAIL;
}

export const GET_MATERIALS_DESCRIPTIONS = 'GET_MATERIALS_DESCRIPTIONS';
export const GET_MATERIALS_DESCRIPTIONS_SUCCESS = 'GET_MATERIALS_DESCRIPTIONS_SUCCESS';
export const GET_MATERIALS_DESCRIPTIONS_FAIL = 'GET_MATERIALS_DESCRIPTIONS_FAIL';

export class GetMaterialsDescriptions implements Action {
  readonly type = GET_MATERIALS_DESCRIPTIONS;

  constructor(public payload: { materialId: string }) {}
}

export class GetMaterialsDescriptionsSuccess implements Action {
  readonly type = GET_MATERIALS_DESCRIPTIONS_SUCCESS;

  constructor(public payload: { materialId: string; descriptions: MaterialsDescription[] }) {}
}

export class GetMaterialsDescriptionsFail implements Action {
  readonly type = GET_MATERIALS_DESCRIPTIONS_FAIL;
}

export const GET_MATERIAL_PACKING_METHODS = 'GET_MATERIAL_PACKING_METHODS';
export const GET_MATERIAL_PACKING_METHODS_SUCCESS = 'GET_MATERIAL_PACKING_METHODS_SUCCESS';
export const GET_MATERIAL_PACKING_METHODS_FAIL = 'GET_MATERIAL_PACKING_METHODS_FAIL';

export class GetMaterialPackingMethods implements Action {
  readonly type = GET_MATERIAL_PACKING_METHODS;
}

export class GetMaterialPackingMethodsSuccess implements Action {
  readonly type = GET_MATERIAL_PACKING_METHODS_SUCCESS;

  constructor(public payload: { methods: MaterialPackingMethod[] }) {}
}

export class GetMaterialPackingMethodsFail implements Action {
  readonly type = GET_MATERIAL_PACKING_METHODS_FAIL;
}

export type MaterialsAction =
  | GetMaterials
  | GetMaterialsFail
  | GetMaterialsSuccess
  | GetMaterialCategories
  | GetMaterialCategoriesFail
  | GetMaterialCategoriesSuccess
  | GetMaterialCategory
  | GetMaterialCategoryFail
  | GetMaterialCategorySuccess
  | GetSuppliers
  | GetSuppliersFail
  | GetSuppliersSuccess
  | GetProducers
  | GetProducersFail
  | GetProducersSuccess
  | GetInspectionRejectReasons
  | GetInspectionRejectReasonsSuccess
  | GetInspectionRejectReasonsFail
  | GetInspectionStatuses
  | GetInspectionStatusesSuccess
  | GetInspectionStatusesFail
  | GetMaterialDestinations
  | GetMaterialDestinationsSuccess
  | GetMaterialDestinationsFail
  | CreateMaterial
  | CreateMaterialSuccess
  | CreateMaterialFail
  | UpdateMaterial
  | UpdateMaterialSuccess
  | UpdateMaterialFail
  | GetMaterialsComments
  | GetMaterialsCommentsSuccess
  | GetMaterialsCommentsFail
  | GetMaterialsDescriptions
  | GetMaterialsDescriptionsSuccess
  | GetMaterialsDescriptionsFail
  | GetMaterialPackingMethods
  | GetMaterialPackingMethodsSuccess
  | GetMaterialPackingMethodsFail;
