import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as fromAppState from '@app/state';

import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './users.action';
import { AlertService } from '@ppgt/web/shared/core';
import * as authActions from '../auth/auth.actions';
import { getUserData } from './users.selector';
import { Role } from '@ppgt/web/shared/domain';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';

@Injectable()
export class UsersEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.UsersAction>,
    private store: Store<fromAppState.AppState>,
    private alertService: AlertService,
    private translateItems: TranslateItemsService,
    private translateService: TranslateService,
  ) { }

  
  getMe$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_USER_DATA),
    switchMap(() =>
      this.http.getMe().pipe(
        mergeMap(me => {
          const role: Role = { ...me.role, permits: [...me.role.permits, me.role.type as any] };

          if (me.language) {
            this.translateService.use(me.language);
            this.translateItems.loadCurrentLang();
          }

          const actionList: Action[] = [new actions.GetUserDataSuccess({
            ...me,
            role: this.translateItems.translateRole(role),
          })]

          if (me.avatar) {
            actionList.push(new actions.StoreAvatarBase64(me.avatar))
          }

          return actionList;
        }),
        catchError(error => [new actions.GetUserDataFail(), new authActions.UsersSignOut()]),
      ),
    ),
  ));

  
  getUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_USERS),
    map((action: actions.GetUsers) => action.payload),
    switchMap((filters: any) =>
      this.http.getUsers(filters).pipe(
        map(res => new actions.GetUsersSuccess({
          ...res,
          users: res.users.map(
            user => ({ ...user, role: this.translateItems.translateRole(user.role) })
          )
        })),
        catchError(error => [new actions.GetUsersFail()]),
      ),
    ),
  ));

  
  getUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_USER),
    map((action: actions.GetUser) => action.payload.id),
    switchMap(id =>
      this.http.getUser(id).pipe(
        map(user => new actions.GetUserSuccess(
          { ...user, role: this.translateItems.translateRole(user.role) }
        )),
        catchError(error => [new actions.GetUserFail(error)]),
      ),
    ),
  ));

  
  createUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.CREATE_USER),
    map((action: actions.CreateUser) => action.payload),
    switchMap(payload =>
      this.http.createUser(payload).pipe(
        mergeMap(newUser => [
            new actions.CreateUserSuccess({
              ...newUser, role: this.translateItems.translateRole(newUser.role),
            }),
            new fromAppState.Go({
              path: ['settings/building-profile/users'],
              alertInfo: 'alert.user_created_l',
            }),
          ]),
        catchError(error => [new actions.CreateUserFail(error)]),
      ),
    ),
  ));


  updateUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_USER),
    map((action: actions.UpdateUser) => action.user),
    switchMap(user =>
      this.http.updateUser(user).pipe(
        withLatestFrom(this.store.select(getUserData).pipe(take(1))),
        mergeMap(([updatedUser, me]) => {
          const isMe = me.id === updatedUser.id;
          updatedUser.role = this.translateItems.translateRole(updatedUser.role);
          const actionsMap: Action[] = [new actions.UpdateUserSuccess(updatedUser)];
          const themeChange = Object.keys(user).length === 2 && 'theme' in user;

          if (updatedUser.language) {
            this.translateService.use(updatedUser.language);
            this.translateItems.loadCurrentLang();
          }

          if (themeChange) {
            this.alertService.showInfo('alert.user_updated_l');
          } else {
            actionsMap.push(
              new fromAppState.Go({
                path: [`settings/building-profile/${isMe ? '' : 'users'}`],
                alertInfo: 'alert.user_updated_l',
              }),
            );
          }

          if (isMe) {
            actionsMap.push(new actions.GetUserDataSuccess(updatedUser));
          }

          if (user.avatar) {
            actionsMap.push(new actions.UpdateAvatar({file: user.avatar, userId: user.id}))
          }

          return actionsMap;
        }),
        catchError(error => [new actions.UpdateUserFail(error)]),
      ),
    ),
  ));

  
  updatePassword$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_PASSWORD),
    map((action: actions.UpdatePassword) => action.payload),
    switchMap(({ userId, email }): Action[] =>
      this.http.updatePassword({ userId, email }).pipe(
        map(res => {
          this.alertService.showInfo('alert.update_password_l');
          return new actions.UpdatePasswordSuccess(res);
        }),
        // catchError(error => [new actions.UpdatePasswordFail(error)]),
      ),
    ),
  ));

  
  newPassword$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.NEW_PASSWORD),
    map((action: actions.NewPassword) => action.payload),
    switchMap((payload): Action[]  =>
      this.http.newPassword(payload).pipe(
        mergeMap(res => [
          new fromAppState.Go({
            path: ['login'],
            alertInfo: 'alert.new_password_set_l',
          }),
          new actions.NewPasswordSuccess(res),
        ]),
        catchError(error => [new actions.NewPasswordFail(error)]),
      ),
    ),
  ));

  
  getLogs$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_LOGS),
    switchMap(() =>
      this.http.getLogs().pipe(
        map(() => new actions.GetLogsSuccess()),
        catchError(error => [new actions.GetLogsFail(error)]),
      ),
    ),
  ));

   getUserPin$: Observable<actions.GetUserPinSuccess | actions.GetUserPinFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_USER_PIN),
      map((action: actions.GetUserPin) => action.payload),
      switchMap((id) =>
        this.http.getUserPin(id).pipe(
          map((data) => new actions.GetUserPinSuccess(data)),
          catchError(error => [new actions.GetUserPinFail(error)]),
        )
      )
    ));

  updateAvatar$ = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_AVATAR),
    map((action: actions.UpdateAvatar) => action.payload),
    mergeMap((avatarFile) =>
      this.http.updateAvatar(avatarFile).pipe(
        mergeMap((avatarUrl) => {
          const actionList: Action[] = [
            new actions.UpdateAvatarSuccess(avatarUrl),
            new actions.StoreAvatarBase64(avatarUrl),
          ];

          return actionList;
        }),
        catchError((error) => {
          return [new actions.UpdateAvatarFail()]}
        ),
      ),
    ),
  ));

  storeAvatarBase64$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.STORE_AVATAR_BASE64),
      map((action: actions.StoreAvatarBase64) => action.payload),
      switchMap((avatarUrl) =>
        this.http.urlToBase64(avatarUrl).pipe(
          map((base64Avatar) => new actions.StoreAvatarBase64Success(base64Avatar)),
          catchError(error => [new actions.UpdateAvatarFail()]),
        )
      ),
    )
  );
}
