import { Action } from '@ngrx/store';

import {
  CheckDeliveryReq,
  CreateDeliveryReq,
  GetDeliveryRes,
  GetManualModeDeliveriesReq,
  ProposeDeliveryReq,
  QueryParamObject,
  SendManualModeDeliveriesReq,
  CheckInDeliveryReq,
  GetDeliveriesRes,
  CheckOutDeliveryReq,
  UpdateDeliveryMaterialReq,
  SendOrdersReq,
  UpdateDeliveryReq,
  ResponseMetadata,
  UpdateDeliveryFilesReq,
  OpenDeliveryFileReq,
  RemoveDeliveryFileReq,
  ManuallyUpdateDeliveryReq,
} from '@app/core/http/interfaces';

import {
  CheckedDeliveries,
  Delivery,
  DeliveryContacts,
  Propose,
  DeliveryUnloadingMethod,
  DeliveryComment,
  Distributor,
  DeliveryLongtime,
  CreateInspectionBody,
  DeliveryMaterial,
  DeliveryFormedFilled,
  GetDeliveryLogsSuccessPayload,
  Unit,
  CreateInspectionSuccessPayload,
  DeliverySetTimesBody,
  DeliveryRun,
  DeliveryLongterm,
  SendOnetimeDeliveryTicketImagesBody,
  DeliveryPourCard,
  CubeTestsPurpose,
  DeliveryFile,
  GetLongtermDeliveryTermsPayload,
  LongtermDeliveryTerm,
  DeliveryStage,
} from '@app/state/interfaces';
import { DeliveryStageActions } from '@app/state';

export const GET_DELIVERIES = 'GET_DELIVERIES';
export const GET_DELIVERIES_FAIL = 'GET_DELIVERIES_FAIL';
export const GET_DELIVERIES_SUCCESS = 'GET_DELIVERIES_SUCCESS';

export class GetDeliveries implements Action {
  readonly type = GET_DELIVERIES;

  constructor(public payload?: { params: QueryParamObject[]; showLoader?: boolean }) { }
}

export class GetDeliveriesFail implements Action {
  readonly type = GET_DELIVERIES_FAIL;
}

export class GetDeliveriesSuccess implements Action {
  readonly type = GET_DELIVERIES_SUCCESS;

  constructor(public payload: GetDeliveriesRes['data']) { }
}

export const GET_LONGTERM_DELIVERIES = 'GET_LONGTERM_DELIVERIES';
export const GET_LONGTERM_DELIVERIES_FAIL = 'GET_LONGTERM_DELIVERIES_FAIL';
export const GET_LONGTERM_DELIVERIES_SUCCESS = 'GET_LONGTERM_DELIVERIES_SUCCESS';

export class GetLongtermDeliveries implements Action {
  readonly type = GET_LONGTERM_DELIVERIES;

  constructor(public payload?: {
    params: QueryParamObject[];
    status?: string;
    showLoader?: boolean;
    toggleDeliveriesAndStages?: boolean;
  }) { }
}

export class GetLongtermDeliveriesFail implements Action {
  readonly type = GET_LONGTERM_DELIVERIES_FAIL;
}

export class GetLongtermDeliveriesSuccess implements Action {
  readonly type = GET_LONGTERM_DELIVERIES_SUCCESS;

  constructor(public payload: {
    deliveries: DeliveryLongterm[];
    meta: ResponseMetadata;
    toggleDeliveriesAndStages: boolean;
  }) { }
}

export const GET_DELIVERY = 'GET_DELIVERY';
export const GET_DELIVERY_FAIL = 'GET_DELIVERY_FAIL';
export const GET_DELIVERY_SUCCESS = 'GET_DELIVERY_SUCCESS';

export class GetDelivery implements Action {
  readonly type = GET_DELIVERY;

  constructor(public payload: { id: string }) { }
}

export class GetDeliveryFail implements Action {
  readonly type = GET_DELIVERY_FAIL;
}

export class GetDeliverySuccess implements Action {
  readonly type = GET_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

export const CREATE_DELIVERY = 'CREATE_DELIVERY';
export const CREATE_DELIVERY_FAIL = 'CREATE_DELIVERY_FAIL';
export const CREATE_DELIVERY_SUCCESS = 'CREATE_DELIVERY_SUCCESS';

export class CreateDelivery implements Action {
  readonly type = CREATE_DELIVERY;

  constructor(public payload: CreateDeliveryReq) { }
}

export class CreateDeliveryFail implements Action {
  readonly type = CREATE_DELIVERY_FAIL;
}

export class CreateDeliverySuccess implements Action {
  readonly type = CREATE_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

export const CREATE_LONGTIME_DELIVERY = 'CREATE_LONGTIME_DELIVERY';
export const CREATE_LONGTIME_DELIVERY_FAIL = 'CREATE_LONGTIME_DELIVERY_FAIL';
export const CREATE_LONGTIME_DELIVERY_SUCCESS = 'CREATE_LONGTIME_DELIVERY_SUCCESS';

export class CreateLongtimeDelivery implements Action {
  readonly type = CREATE_LONGTIME_DELIVERY;

  constructor(public payload: { delivery: DeliveryLongtime }) { }
}

export class CreateLongtimeDeliveryFail implements Action {
  readonly type = CREATE_LONGTIME_DELIVERY_FAIL;
}

export class CreateLongtimeDeliverySuccess implements Action {
  readonly type = CREATE_LONGTIME_DELIVERY_SUCCESS;

  constructor(public payload: DeliveryLongtime) { }
}

export const UPDATE_LONGTIME_DELIVERY = 'UPDATE_LONGTIME_DELIVERY';
export const UPDATE_LONGTIME_DELIVERY_FAIL = 'UPDATE_LONGTIME_DELIVERY_FAIL';
export const UPDATE_LONGTIME_DELIVERY_SUCCESS = 'UPDATE_LONGTIME_DELIVERY_SUCCESS';

export class UpdateLongtimeDelivery implements Action {
  readonly type = UPDATE_LONGTIME_DELIVERY;

  constructor(public payload: { delivery: DeliveryLongtime }) { }
}

export class UpdateLongtimeDeliveryFail implements Action {
  readonly type = UPDATE_LONGTIME_DELIVERY_FAIL;
}

export class UpdateLongtimeDeliverySuccess implements Action {
  readonly type = UPDATE_LONGTIME_DELIVERY_SUCCESS;

  constructor(public payload: DeliveryLongtime) { }
}

export const MOVE_DELIVERY_STAGES = 'MOVE_DELIVERY_STAGES';
export const MOVE_DELIVERY_STAGES_SUCCESS = 'MOVE_DELIVERY_STAGES_SUCCESS';
export const MOVE_DELIVERY_STAGES_FAIL = 'MOVE_DELIVERY_STAGES_FAIL';

export class MoveDeliveryStages implements Action {
  readonly type = MOVE_DELIVERY_STAGES;

  constructor(public payload: {
    deliveryStages: DeliveryStage[];
    oldDelivery: DeliveryLongtime;
    newDeliveryId: string;
  }) { }
}

export class MoveDeliveryStagesSuccess implements Action {
  readonly type = MOVE_DELIVERY_STAGES_SUCCESS;
}

export class MoveDeliveryStagesFail implements Action {
  readonly type = MOVE_DELIVERY_STAGES_FAIL;
}

// edit delivery
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';
export const UPDATE_DELIVERY_FAIL = 'UPDATE_DELIVERY_FAIL';
export const UPDATE_DELIVERY_SUCCESS = 'UPDATE_DELIVERY_SUCCESS';
export class UpdateDelivery implements Action {
  readonly type = UPDATE_DELIVERY;

  constructor(public payload: UpdateDeliveryReq) { }
}

export class UpdateDeliveryFail implements Action {
  readonly type = UPDATE_DELIVERY_FAIL;
}

export class UpdateDeliverySuccess implements Action {
  readonly type = UPDATE_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

// manually update delivery
export const MANUALLY_UPDATE_DELIVERY = 'MANUALLY_UPDATE_DELIVERY';
export const MANUALLY_UPDATE_DELIVERY_SUCCESS = 'MANUALLY_UPDATE_DELIVERY_SUCCESS';
export const MANUALLY_UPDATE_DELIVERY_FAIL = 'MANUALLY_UPDATE_DELIVERY_FAIL';
export class ManuallyUpdateDelivery implements Action {
  readonly type = MANUALLY_UPDATE_DELIVERY;

  constructor(public payload: ManuallyUpdateDeliveryReq) { }
}

export class ManuallyUpdateDeliveryFail implements Action {
  readonly type = MANUALLY_UPDATE_DELIVERY_FAIL;
}

export class ManuallyUpdateDeliverySuccess implements Action {
  readonly type = MANUALLY_UPDATE_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

// restore delivery
export const RESTORE_DELIVERY = 'RESTORE_DELIVERY';
export const RESTORE_DELIVERY_FAIL = 'RESTORE_DELIVERY_FAIL';
export const RESTORE_DELIVERY_SUCCESS = 'RESTORE_DELIVERY_SUCCESS';
export class RestoreDelivery implements Action {
  readonly type = RESTORE_DELIVERY;

  constructor(public payload: UpdateDeliveryReq) {}
}

export class RestoreDeliveryFail implements Action {
  readonly type = RESTORE_DELIVERY_FAIL;
}

export class RestoreDeliverySuccess implements Action {
  readonly type = RESTORE_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) {}
}

// update delivery files
export const UPDATE_DELIVERY_FILES = 'UPDATE_DELIVERY_FILES';
export const UPDATE_DELIVERY_FILES_FAIL = 'UPDATE_DELIVERY_FILES_FAIL';
export const UPDATE_DELIVERY_FILES_SUCCESS = 'UPDATE_DELIVERY_FILES_SUCCESS';

export class UpdateDeliveryFiles implements Action {
  readonly type = UPDATE_DELIVERY_FILES;

  constructor(public payload: UpdateDeliveryFilesReq) { }
}

export class UpdateDeliveryFilesFail implements Action {
  readonly type = UPDATE_DELIVERY_FILES_FAIL;
}

export class UpdateDeliveryFilesSuccess implements Action {
  readonly type = UPDATE_DELIVERY_FILES_SUCCESS;

  constructor(public payload: Delivery) { }
}

// open delivery file
export const OPEN_DELIVERY_FILE = 'OPEN_DELIVERY_FILE';
export const OPEN_DELIVERY_FILE_FAIL = 'OPEN_DELIVERY_FILE_FAIL';
export const OPEN_DELIVERY_FILE_SUCCESS = 'OPEN_DELIVERY_FILE_SUCCESS';

export class OpenDeliveryFile implements Action {
  readonly type = OPEN_DELIVERY_FILE;

  constructor(public payload: OpenDeliveryFileReq) { }
}

export class OpenDeliveryFileFail implements Action {
  readonly type = OPEN_DELIVERY_FILE_FAIL;
}

export class OpenDeliveryFileSuccess implements Action {
  readonly type = OPEN_DELIVERY_FILE_SUCCESS;

  constructor(public payload: DeliveryFile) { }
}

export const REMOVE_DELIVERY_FILE = 'REMOVE_DELIVERY_FILE';
export const REMOVE_DELIVERY_FILE_FAIL = 'REMOVE_DELIVERY_FILE_FAIL';
export const REMOVE_DELIVERY_FILE_SUCCESS = 'REMOVE_DELIVERY_FILE_SUCCESS';

export class RemoveDeliveryFile implements Action {
  readonly type = REMOVE_DELIVERY_FILE;

  constructor(public payload: RemoveDeliveryFileReq) { }
}

export class RemoveDeliveryFileFail implements Action {
  readonly type = REMOVE_DELIVERY_FILE_FAIL;
}

export class RemoveDeliveryFileSuccess implements Action {
  readonly type = REMOVE_DELIVERY_FILE_SUCCESS;

  constructor(public payload: DeliveryFile[]) { }
}


export const CHECK_IN_DELIVERY = 'CHECK_IN_DELIVERY';
export const CHECK_IN_DELIVERY_FAIL = 'CHECK_IN_DELIVERY_FAIL';
export const CHECK_IN_DELIVERY_SUCCESS = 'CHECK_IN_DELIVERY_SUCCESS';

export class CheckInDelivery implements Action {
  readonly type = CHECK_IN_DELIVERY;

  constructor(public payload: CheckInDeliveryReq) { }
}

export class CheckInDeliveryFail implements Action {
  readonly type = CHECK_IN_DELIVERY_FAIL;
}

export class CheckInDeliverySuccess implements Action {
  readonly type = CHECK_IN_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

export const CHECK_OUT_DELIVERY = 'CHECK_OUT_DELIVERY';
export const CHECK_OUT_DELIVERY_FAIL = 'CHECK_OUT_DELIVERY_FAIL';
export const CHECK_OUT_DELIVERY_SUCCESS = 'CHECK_OUT_DELIVERY_SUCCESS';

export class CheckOutDelivery implements Action {
  readonly type = CHECK_OUT_DELIVERY;

  constructor(public payload: CheckOutDeliveryReq) { }
}

export class CheckOutDeliveryFail implements Action {
  readonly type = CHECK_OUT_DELIVERY_FAIL;
}

export class CheckOutDeliverySuccess implements Action {
  readonly type = CHECK_OUT_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

// reject delivery

export const REJECT_DELIVERY = 'REJECT_DELIVERY';
export const REJECT_DELIVERY_FAIL = 'REJECT_DELIVERY_FAIL';
export const REJECT_DELIVERY_SUCCESS = 'REJECT_DELIVERY_SUCCESS';

export class RejectDelivery implements Action {
  readonly type = REJECT_DELIVERY;

  constructor(
    public payload: {
      delivery: {
        status: string;
        reasonReject: string;
        rejectedOnGate: boolean;
        id: string;
      };
      redirectAfter?: boolean;
    },
  ) { }
}

export class RejectDeliveryFail implements Action {
  readonly type = REJECT_DELIVERY_FAIL;
}

export class RejectDeliverySuccess implements Action {
  readonly type = REJECT_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

export const CANCEL_DELIVERY = 'CANCEL_DELIVERY';
export const CANCEL_DELIVERY_FAIL = 'CANCEL_DELIVERY_FAIL';
export const CANCEL_DELIVERY_SUCCESS = 'CANCEL_DELIVERY_SUCCESS';

export class CancelDelivery implements Action {
  readonly type = CANCEL_DELIVERY;

  constructor(public payload: { id: string }) { }
}

export class CancelDeliveryFail implements Action {
  readonly type = CANCEL_DELIVERY_FAIL;
}

export class CancelDeliverySuccess implements Action {
  readonly type = CANCEL_DELIVERY_SUCCESS;

  constructor(public payload: Delivery) { }
}

export const CALL_DRIVER = 'CALL_DRIVER';
export const CALL_DRIVER_FAIL = 'CALL_DRIVER_FAIL';
export const CALL_DRIVER_SUCCESS = 'CALL_DRIVER_SUCCESS';

export class CallDriver implements Action {
  readonly type = CALL_DRIVER;

  constructor(public payload: { deliveryId: string }) { }
}

export class CallDriverFail implements Action {
  readonly type = CALL_DRIVER_FAIL;
}

export class CallDriverSuccess implements Action {
  readonly type = CALL_DRIVER_SUCCESS;

  constructor(public payload: Record<string, unknown>) { }
}

export const REMIND_DELIVERY = 'REMIND_DELIVERY';
export const REMIND_DELIVERY_FAIL = 'REMIND_DELIVERY_FAIL';
export const REMIND_DELIVERY_SUCCESS = 'REMIND_DELIVERY_SUCCESS';

export class RemindDelivery implements Action {
  readonly type = REMIND_DELIVERY;

  constructor(public payload: { id: string }) { }
}

export class RemindDeliveryFail implements Action {
  readonly type = REMIND_DELIVERY_FAIL;
}

export class RemindDeliverySuccess implements Action {
  readonly type = REMIND_DELIVERY_SUCCESS;
}

export const REMOVE_DELIVERY = 'REMOVE_DELIVERY';
export const REMOVE_DELIVERY_FAIL = 'REMOVE_DELIVERY_FAIL';
export const REMOVE_DELIVERY_SUCCESS = 'REMOVE_DELIVERY_SUCCESS';

export class RemoveDelivery implements Action {
  readonly type = REMOVE_DELIVERY;

  constructor(public payload: { id: string }) { }
}

export class RemoveDeliveryFail implements Action {
  readonly type = REMOVE_DELIVERY_FAIL;
}

export class RemoveDeliverySuccess implements Action {
  readonly type = REMOVE_DELIVERY_SUCCESS;

  constructor(public payload: GetDeliveryRes) { }
}

export const CHECK_DELIVERY = 'CHECK_DELIVERY';
export const CHECK_DELIVERY_RESET = 'CHECK_DELIVERY_RESET';
export const CHECK_DELIVERY_FAIL = 'CHECK_DELIVERY_FAIL';
export const CHECK_DELIVERY_SUCCESS = 'CHECK_DELIVERY_SUCCESS';

export class CheckDelivery implements Action {
  readonly type = CHECK_DELIVERY;

  constructor(public payload: CheckDeliveryReq) { }
}

export class CheckDeliveryReset implements Action {
  readonly type = CHECK_DELIVERY_RESET;
}

export class CheckDeliveryFail implements Action {
  readonly type = CHECK_DELIVERY_FAIL;
}

export class CheckDeliverySuccess implements Action {
  readonly type = CHECK_DELIVERY_SUCCESS;

  constructor(public payload: CheckedDeliveries) { }
}

export const DELIVERY_FORMED = 'DELIVERY_FORMED';

export class DeliveryFormed implements Action {
  readonly type = DELIVERY_FORMED;

  constructor(public payload: DeliveryFormedFilled) { }
}

export const PROPOSE_DELIVERY = 'PROPOSE_DELIVERY';
export const PROPOSE_DELIVERY_FAIL = 'PROPOSE_DELIVERY_FAIL';
export const PROPOSE_DELIVERY_SUCCESS = 'PROPOSE_DELIVERY_SUCCESS';

export class ProposeDelivery implements Action {
  readonly type = PROPOSE_DELIVERY;

  constructor(public payload: ProposeDeliveryReq) { }
}

export class ProposeDeliveryFail implements Action {
  readonly type = PROPOSE_DELIVERY_FAIL;
}

export class ProposeDeliverySuccess implements Action {
  readonly type = PROPOSE_DELIVERY_SUCCESS;

  constructor(public payload: Propose[]) { }
}

export const GET_MANUAL_MODE_DELIVERIES = 'GET_MANUAL_MODE_DELIVERIES';
export const GET_MANUAL_MODE_DELIVERIES_FAIL = 'GET_MANUAL_MODE_DELIVERIES_FAIL';
export const GET_MANUAL_MODE_DELIVERIES_SUCCESS = 'GET_MANUAL_MODE_DELIVERIES_SUCCESS';

export class GetManualModeDeliveries implements Action {
  readonly type = GET_MANUAL_MODE_DELIVERIES;

  constructor(public payload: GetManualModeDeliveriesReq) { }
}

export class GetManualModeDeliveriesFail implements Action {
  readonly type = GET_MANUAL_MODE_DELIVERIES_FAIL;
}

export class GetManualModeDeliveriesSuccess implements Action {
  readonly type = GET_MANUAL_MODE_DELIVERIES_SUCCESS;

  constructor(public payload: Delivery[]) { }
}

export const SEND_MANUAL_MODE_DELIVERIES = 'SEND_MANUAL_MODE_DELIVERIES';
export const SEND_MANUAL_MODE_DELIVERIES_FAIL = 'SEND_MANUAL_MODE_DELIVERIES_FAIL';
export const SEND_MANUAL_MODE_DELIVERIES_SUCCESS = 'SEND_MANUAL_MODE_DELIVERIES_SUCCESS';

export class SendManualModeDeliveries implements Action {
  readonly type = SEND_MANUAL_MODE_DELIVERIES;

  constructor(public payload: SendManualModeDeliveriesReq) { }
}

export class SendManualModeDeliveriesFail implements Action {
  readonly type = SEND_MANUAL_MODE_DELIVERIES_FAIL;
}

export class SendManualModeDeliveriesSuccess implements Action {
  readonly type = SEND_MANUAL_MODE_DELIVERIES_SUCCESS;

  constructor(public payload: Delivery[]) { }
}

export const SET_MANUAL_MODE_DELIVERY = 'SET_MANUAL_MODE_DELIVERY';
export const SET_MANUAL_MODE_DELIVERY_FAIL = 'SET_MANUAL_MODE_DELIVERY_FAIL';
export const SET_MANUAL_MODE_DELIVERY_SUCCESS = 'SET_MANUAL_MODE_DELIVERY_SUCCESS';

export class SetManualModeDelivery implements Action {
  readonly type = SET_MANUAL_MODE_DELIVERY;

  constructor(public payload: { id: string; redirectAfter?: boolean }) { }
}

export class SetManualModeDeliveryFail implements Action {
  readonly type = SET_MANUAL_MODE_DELIVERY_FAIL;
}

export class SetManualModeDeliverySuccess implements Action {
  readonly type = SET_MANUAL_MODE_DELIVERY_SUCCESS;
}

export const SEND_DELIVERY_CARDS = 'SEND_DELIVERY_CARDS';
export const SEND_DELIVERY_CARDS_SUCCESS = 'SEND_DELIVERY_CARDS_SUCCESS';
export const SEND_DELIVERY_CARDS_FAIL = 'SEND_DELIVERY_CARDS_FAIL';

export class SendDeliveryCards implements Action {
  readonly type = SEND_DELIVERY_CARDS;

  constructor(public payload: { id: string }) { }
}

export class SendDeliveryCardsSuccess implements Action {
  readonly type = SEND_DELIVERY_CARDS_SUCCESS;
}

export class SendDeliveryCardsFail implements Action {
  readonly type = SEND_DELIVERY_CARDS_FAIL;
}

export const DOWNLOAD_DELIVERY_CARD = 'DOWNLOAD_DELIVERY_CARD';
export const DOWNLOAD_DELIVERY_CARD_SUCCESS = 'DOWNLOAD_DELIVERY_CARD_SUCCESS';
export const DOWNLOAD_DELIVERY_CARD_FAIL = 'DOWNLOAD_DELIVERY_CARD_FAIL';


export class DownloadDeliveryCard implements Action {
  readonly type = DOWNLOAD_DELIVERY_CARD;

  constructor(public payload: Delivery) { }
}

export class DownloadDeliveryCardSuccess implements Action {
  readonly type = DOWNLOAD_DELIVERY_CARD_SUCCESS;
}

export class DownloadDeliveryCardFail implements Action {
  readonly type = DOWNLOAD_DELIVERY_CARD_FAIL;
}

export const GET_DELIVERY_CONTACTS = 'GET_DELIVERY_CONTACTS';
export const GET_DELIVERY_CONTACTS_SUCCESS = 'GET_DELIVERY_CONTACTS_SUCCESS';
export const GET_DELIVERY_CONTACTS_FAIL = 'GET_DELIVERY_CONTACTS_FAIL';

export class GetDeliveryContacts implements Action {
  readonly type = GET_DELIVERY_CONTACTS;

  constructor(public payload: { id: string }) { }
}

export class GetDeliveryContactsSuccess implements Action {
  readonly type = GET_DELIVERY_CONTACTS_SUCCESS;

  constructor(public payload: DeliveryContacts) { }
}

export class GetDeliveryContactsFail implements Action {
  readonly type = GET_DELIVERY_CONTACTS_FAIL;
}

export const GET_DELIVERY_UNLOADING_METHODS = 'GET_DELIVERY_UNLOADING_METHODS';
export const GET_DELIVERY_UNLOADING_METHODS_SUCCESS = 'GET_DELIVERY_UNLOADING_METHODS_SUCCESS';
export const GET_DELIVERY_UNLOADING_METHODS_FAIL = 'GET_DELIVERY_UNLOADING_METHODS_FAIL';

export class GetDeliveryUnloadingMethods implements Action {
  readonly type = GET_DELIVERY_UNLOADING_METHODS;
}

export class GetDeliveryUnloadingMethodsSuccess implements Action {
  readonly type = GET_DELIVERY_UNLOADING_METHODS_SUCCESS;

  constructor(public payload: DeliveryUnloadingMethod[]) { }
}

export class GetDeliveryUnloadingMethodsFail implements Action {
  readonly type = GET_DELIVERY_UNLOADING_METHODS_FAIL;
}

export const GET_DELIVERY_COMMENTS = 'GET_DELIVERY_COMMENTS';
export const GET_DELIVERY_COMMENTS_SUCCESS = 'GET_DELIVERY_COMMENTS_SUCCESS';
export const GET_DELIVERY_COMMENTS_FAIL = 'GET_DELIVERY_COMMENTS_FAIL';

export class GetDeliveryComments implements Action {
  readonly type = GET_DELIVERY_COMMENTS;

  constructor(public payload: { deliveryId: string }) { }
}

export class GetDeliveryCommentsSuccess implements Action {
  readonly type = GET_DELIVERY_COMMENTS_SUCCESS;

  constructor(public payload: DeliveryComment[]) { }
}

export class GetDeliveryCommentsFail implements Action {
  readonly type = GET_DELIVERY_COMMENTS_FAIL;
}

export const ADD_DELIVERY_COMMENTS = 'ADD_DELIVERY_COMMENTS';
export const ADD_DELIVERY_COMMENTS_SUCCESS = 'ADD_DELIVERY_COMMENTS_SUCCESS';
export const ADD_DELIVERY_COMMENTS_FAIL = 'ADD_DELIVERY_COMMENTS_FAIL';

export class AddDeliveryComments implements Action {
  readonly type = ADD_DELIVERY_COMMENTS;

  constructor(
    public payload: {
      deliveryId: string;
      deliveryComments: DeliveryComment[];
    },
  ) { }
}

export class AddDeliveryCommentsSuccess implements Action {
  readonly type = ADD_DELIVERY_COMMENTS_SUCCESS;

  constructor(public payload: DeliveryComment[]) { }
}

export class AddDeliveryCommentsFail implements Action {
  readonly type = ADD_DELIVERY_COMMENTS_FAIL;
}

export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS';
export const GET_DISTRIBUTORS_SUCCESS = 'GET_DISTRIBUTORS_SUCCESS';
export const GET_DISTRIBUTORS_FAIL = 'GET_DISTRIBUTORS_FAIL';

export class GetDistributors implements Action {
  readonly type = GET_DISTRIBUTORS;
}

export class GetDistributorsSuccess implements Action {
  readonly type = GET_DISTRIBUTORS_SUCCESS;

  constructor(public payload: Distributor[]) { }
}

export class GetDistributorsFail implements Action {
  readonly type = GET_DISTRIBUTORS_FAIL;
}

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export class StartLoading implements Action {
  readonly type = START_LOADING;
}

export class StopLoading implements Action {
  readonly type = STOP_LOADING;
}
export const CREATE_MATERIAL_INSPECTION = 'CREATE_MATERIAL_INSPECTION';
export const CREATE_MATERIAL_INSPECTION_SUCCESS = 'CREATE_MATERIAL_INSPECTION_SUCCESS';
export const CREATE_MATERIAL_INSPECTION_FAIL = 'CREATE_MATERIAL_INSPECTION_FAIL';

export class CreateMaterialInspection implements Action {
  readonly type = CREATE_MATERIAL_INSPECTION;

  constructor(
    public payload: {
      body: CreateInspectionBody;
      materialId: string;
      deliveryId: string;
    },
  ) { }
}

export class CreateMaterialInspectionSuccess implements Action {
  readonly type = CREATE_MATERIAL_INSPECTION_SUCCESS;

  constructor(public payload: CreateInspectionSuccessPayload) { }
}

export class CreateMaterialInspectionFail implements Action {
  readonly type = CREATE_MATERIAL_INSPECTION_FAIL;
}

export const UPDATE_DELIVERY_MATERIAL = 'UPDATE_DELIVERY_MATERIAL';
export const UPDATE_DELIVERY_MATERIAL_SUCCESS = 'UPDATE_DELIVERY_MATERIAL_SUCCESS';
export const UPDATE_DELIVERY_MATERIAL_FAIL = 'UPDATE_DELIVERY_MATERIAL_FAIL';

export class UpdateDeliveryMaterial implements Action {
  readonly type = UPDATE_DELIVERY_MATERIAL;

  constructor(public payload: UpdateDeliveryMaterialReq) { }
}

export class UpdateDeliveryMaterialSuccess implements Action {
  readonly type = UPDATE_DELIVERY_MATERIAL_SUCCESS;

  constructor(public payload: DeliveryMaterial[]) { }
}

export class UpdateDeliveryMaterialFail implements Action {
  readonly type = UPDATE_DELIVERY_MATERIAL_FAIL;
}

export const GET_DELIVERY_LOGS = 'GET_DELIVERY_LOGS';
export const GET_DELIVERY_LOGS_SUCCESS = 'GET_DELIVERY_LOGS_SUCCESS';
export const GET_DELIVERY_LOGS_FAIL = 'GET_DELIVERY_LOGS_FAIL';

export class GetDeliveryLogs implements Action {
  readonly type = GET_DELIVERY_LOGS;

  constructor(public payload: string) { }
}

export class GetDeliveryLogsSuccess implements Action {
  readonly type = GET_DELIVERY_LOGS_SUCCESS;

  constructor(public payload: GetDeliveryLogsSuccessPayload) { }
}

export class GetDeliveryLogsFail implements Action {
  readonly type = GET_DELIVERY_LOGS_FAIL;
}

export const SEND_ORDERS = 'SEND_ORDERS';
export const SEND_ORDERS_SUCCESS = 'SEND_ORDERS_SUCCESS';
export const SEND_ORDERS_FAIL = 'SEND_ORDERS_FAIL';

export class SendOrders implements Action {
  readonly type = SEND_ORDERS;

  constructor(public payload: SendOrdersReq) { }
}

export class SendOrdersFail implements Action {
  readonly type = SEND_ORDERS_FAIL;
}

export class SendOrdersSuccess implements Action {
  readonly type = SEND_ORDERS_SUCCESS;
}

export const GET_UNITS = 'GET_UNITS';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAIL = 'GET_UNITS_FAIL';

export class GetUnits implements Action {
  readonly type = GET_UNITS;
}

export class GetUnitsSuccess implements Action {
  readonly type = GET_UNITS_SUCCESS;

  constructor(public payload: Unit[]) { }
}

export class GetUnitsFail implements Action {
  readonly type = GET_UNITS_FAIL;
}

export const ASSIGN_STAGE_TO_RUN = 'ASSIGN_STAGE_TO_RUN';
export const ASSIGN_STAGE_TO_RUN_SUCCESS = 'ASSIGN_STAGE_TO_RUN_SUCCESS';
export const ASSIGN_STAGE_TO_RUN_FAIL = 'ASSIGN_STAGE_TO_RUN_FAIL';

export class AssignStageToRun implements Action {
  readonly type = ASSIGN_STAGE_TO_RUN;

  constructor(public payload: { stageId: string; runId: string }) { }
}

export class AssignStageToRunSuccess implements Action {
  readonly type = ASSIGN_STAGE_TO_RUN_SUCCESS;

  constructor(public payload: DeliveryRun) { }
}

export class AssignStageToRunFail implements Action {
  readonly type = ASSIGN_STAGE_TO_RUN_FAIL;
}

export const SET_DELIVERY_TIMES = 'SET_DELIVERY_TIMES';
export const SET_DELIVERY_TIMES_SUCCESS = 'SET_DELIVERY_TIMES_SUCCESS';
export const SET_DELIVERY_TIMES_FAIL = 'SET_DELIVERY_TIMES_FAIL';

export class SetDeliveryTimes implements Action {
  readonly type = SET_DELIVERY_TIMES;

  constructor(public payload: {
    deliveryId: string;
    stageId: string;
    deliveryRunId: string;
    body: DeliverySetTimesBody;
  }) { }
}

export class SetDeliveryTimesSuccess implements Action {
  readonly type = SET_DELIVERY_TIMES_SUCCESS;

  constructor(public payload: { deliveryId: string; stageId: string; run: DeliveryRun }) { }
}

export class SetDeliveryTimesFail implements Action {
  readonly type = SET_DELIVERY_TIMES_FAIL;
}

export const SEND_TICKET_IMAGE = 'SEND_TICKET_IMAGE';
export const SEND_TICKET_IMAGE_SUCCESS = 'SEND_TICKET_IMAGE_SUCCESS';
export const SEND_TICKET_IMAGE_FAIL = 'SEND_TICKET_IMAGE_FAIL';

export class SendTicketImage implements Action {
  readonly type = SEND_TICKET_IMAGE;

  constructor(public payload: UpdateDeliveryFilesReq) { }
}

export class SendTicketImageSuccess implements Action {
  readonly type = SEND_TICKET_IMAGE_SUCCESS;

  constructor(public payload: DeliveryRun) { }
}

export class SendTicketImageFail implements Action {
  readonly type = SEND_TICKET_IMAGE_FAIL;
}

export const UPDATE_DELIVERY_RUN = 'UPDATE_DELIVERY_RUN';
export const UPDATE_DELIVERY_RUN_SUCCESS = 'UPDATE_DELIVERY_RUN_SUCCESS';
export const UPDATE_DELIVERY_RUN_FAIL = 'UPDATE_DELIVERY_RUN_FAIL';

export class UpdateDeliveryRun implements Action {
  readonly type = UPDATE_DELIVERY_RUN;

  constructor(public payload: { runId: string; data: Partial<DeliveryRun> }) { }
}

export class UpdateDeliveryRunSuccess implements Action {
  readonly type = UPDATE_DELIVERY_RUN_SUCCESS;

  constructor(public payload: { run: DeliveryRun }) { }
}

export class UpdateDeliveryRunFail implements Action {
  readonly type = UPDATE_DELIVERY_RUN_FAIL;
}

export const SEND_ONETIME_DELIVERY_TICKET_IMAGES = 'SEND_ONETIME_DELIVERY_TICKET_IMAGES';
export const SEND_ONETIME_DELIVERY_TICKET_IMAGES_SUCCESS = 'SEND_ONETIME_DELIVERY_TICKET_IMAGES_SUCCESS';
export const SEND_ONETIME_DELIVERY_TICKET_IMAGES_FAIL = 'SEND_ONETIME_DELIVERY_TICKET_IMAGES_FAIL';

export class SendOnetimeDeliveryTicketImages implements Action {
  readonly type = SEND_ONETIME_DELIVERY_TICKET_IMAGES;

  constructor(public payload: {
    deliveryId: string;
    data: SendOnetimeDeliveryTicketImagesBody;
  }) { }
}

export class SendOnetimeDeliveryTicketImagesSuccess implements Action {
  readonly type = SEND_ONETIME_DELIVERY_TICKET_IMAGES_SUCCESS;

  constructor(public payload: { data: DeliveryFile[]; deliveryId: string }) { }
}

export class SendOnetimeDeliveryTicketImagesFail implements Action {
  readonly type = SEND_ONETIME_DELIVERY_TICKET_IMAGES_FAIL;
}

export const GET_CURRENT_TICKET_IMAGE_URL = 'GET_CURRENT_TICKET_IMAGE_URL';
export const GET_CURRENT_TICKET_IMAGE_URL_SUCCESS = 'GET_CURRENT_TICKET_IMAGE_URL_SUCCESS';
export const GET_CURRENT_TICKET_IMAGE_URL_FAIL = 'GET_CURRENT_TICKET_IMAGE_URL_FAIL';

export class GetCurrentTicketImageUrl implements Action {
  readonly type = GET_CURRENT_TICKET_IMAGE_URL;

  constructor(public payload: { deliveryId: string; fileId: string }) { }
}

export class GetCurrentTicketImageUrlSuccess implements Action {
  readonly type = GET_CURRENT_TICKET_IMAGE_URL_SUCCESS;

  constructor(public payload: { deliveryId: string; file: DeliveryFile }) { }
}

export class GetCurrentTicketImageUrlFail implements Action {
  readonly type = GET_CURRENT_TICKET_IMAGE_URL_FAIL;
}

export const GET_POUR_CARD = 'GET_POUR_CARD';
export const GET_POUR_CARD_SUCCESS = 'GET_POUR_CARD_SUCCESS';
export const GET_POUR_CARD_FAIL = 'GET_POUR_CARD_FAIL';

export class GetPourCard implements Action {
  readonly type = GET_POUR_CARD;

  constructor(public payload: { deliveryRunId: string }) { }
}

export class GetPourCardSuccess implements Action {
  readonly type = GET_POUR_CARD_SUCCESS;

  constructor(public payload: DeliveryPourCard) { }
}

export class GetPourCardFail implements Action {
  readonly type = GET_POUR_CARD_FAIL;
}

export const GET_CUBE_TESTS_PURPOSES = 'GET_CUBE_TESTS_PURPOSES';
export const GET_CUBE_TESTS_PURPOSES_SUCCESS = 'GET_CUBE_TESTS_PURPOSES_SUCCESS';
export const GET_CUBE_TESTS_PURPOSES_FAIL = 'GET_CUBE_TESTS_PURPOSES_FAIL';

export class GetCubeTestsPurposes implements Action {
  readonly type = GET_CUBE_TESTS_PURPOSES;
}

export class GetCubeTestsPurposesSuccess implements Action {
  readonly type = GET_CUBE_TESTS_PURPOSES_SUCCESS;

  constructor(public payload: CubeTestsPurpose[]) { }
}

export class GetCubeTestsPurposesFail implements Action {
  readonly type = GET_CUBE_TESTS_PURPOSES_FAIL;
}

export const SEND_POUR_CARD = 'SEND_POUR_CARD';
export const SEND_POUR_CARD_SUCCESS = 'SEND_POUR_CARD_SUCCESS';
export const SEND_POUR_CARD_FAIL = 'SEND_POUR_CARD_FAIL';

export class SendPourCard implements Action {
  readonly type = SEND_POUR_CARD;

  constructor(public payload: { deliveryRunId: string; data: DeliveryPourCard }) { }
}

export class SendPourCardSuccess implements Action {
  readonly type = SEND_POUR_CARD_SUCCESS;

  constructor(public payload: DeliveryPourCard) { }
}

export class SendPourCardFail implements Action {
  readonly type = SEND_POUR_CARD_FAIL;
}

export const UPDATE_POUR_CARD = 'UPDATE_POUR_CARD';
export const UPDATE_POUR_CARD_SUCCESS = 'UPDATE_POUR_CARD_SUCCESS';
export const UPDATE_POUR_CARD_FAIL = 'UPDATE_POUR_CARD_FAIL';

export class UpdatePourCard implements Action {
  readonly type = UPDATE_POUR_CARD;

  constructor(public payload: { deliveryRunId: string; data: DeliveryPourCard }) { }
}

export class UpdatePourCardSuccess implements Action {
  readonly type = UPDATE_POUR_CARD_SUCCESS;

  constructor(public payload: DeliveryPourCard) { }
}

export class UpdatePourCardFail implements Action {
  readonly type = UPDATE_POUR_CARD_FAIL;
}

export const CLEAR_POUR_CARD_STATE = 'CLEAR_POUR_CARD_STATE';

export class ClearPourCardState implements Action {
  readonly type = CLEAR_POUR_CARD_STATE;
}

export const GET_LONGTERM_DELIVERY_TERMS = 'GET_LONGTERM_DELIVERY_TERMS';
export const GET_LONGTERM_DELIVERY_TERMS_SUCCESS = 'GET_LONGTERM_DELIVERY_TERMS_SUCCESS';
export const GET_LONGTERM_DELIVERY_TERMS_FAIL = 'GET_LONGTERM_DELIVERY_TERMS_FAIL';

export class GetLongtermDeliveryTerms implements Action {
  readonly type = GET_LONGTERM_DELIVERY_TERMS;

  constructor(public payload: GetLongtermDeliveryTermsPayload) { }
}

export class GetLongtermDeliveryTermsSuccess implements Action {
  readonly type = GET_LONGTERM_DELIVERY_TERMS_SUCCESS;

  constructor(public payload: LongtermDeliveryTerm[]) { }
}

export class GetLongtermDeliveryTermsFail implements Action {
  readonly type = GET_LONGTERM_DELIVERY_TERMS_FAIL;
}

export const BOOK_AGAIN_DELIVERY = 'BOOK_AGAIN_DELIVERY';

export class BookAgainDelivery implements Action {
  readonly type = BOOK_AGAIN_DELIVERY;

  constructor(public payload: { repeatedFromDeliveryId: string }) { }
}

export * from './delivery-stages.action';

export type DeliveriesAction =
  | GetDeliveries
  | GetDeliveriesFail
  | GetDeliveriesSuccess
  | GetDelivery
  | GetDeliveryFail
  | GetDeliverySuccess
  | CreateDelivery
  | CreateDeliveryFail
  | CreateDeliverySuccess
  | UpdateDelivery
  | UpdateDeliveryFail
  | UpdateDeliverySuccess
  | UpdateDeliveryFiles
  | UpdateDeliveryFilesFail
  | UpdateDeliveryFilesSuccess
  | OpenDeliveryFile
  | OpenDeliveryFileFail
  | OpenDeliveryFileSuccess
  | CheckInDelivery
  | CheckInDeliveryFail
  | CheckInDeliverySuccess
  | CheckOutDelivery
  | CheckOutDeliveryFail
  | CheckOutDeliverySuccess
  | RejectDelivery
  | RejectDeliveryFail
  | RejectDeliverySuccess
  | CancelDelivery
  | CancelDeliveryFail
  | CancelDeliverySuccess
  | CallDriver
  | CallDriverFail
  | CallDriverSuccess
  | RemindDelivery
  | RemindDeliveryFail
  | RemindDeliverySuccess
  | RemoveDelivery
  | RemoveDeliveryFail
  | RemoveDeliverySuccess
  | CheckDelivery
  | CheckDeliveryFail
  | CheckDeliverySuccess
  | ProposeDelivery
  | ProposeDeliveryFail
  | ProposeDeliverySuccess
  | GetManualModeDeliveries
  | GetManualModeDeliveriesFail
  | GetManualModeDeliveriesSuccess
  | SendManualModeDeliveries
  | SendManualModeDeliveriesFail
  | SendManualModeDeliveriesSuccess
  | SetManualModeDelivery
  | SetManualModeDeliveryFail
  | SetManualModeDeliverySuccess
  | SendDeliveryCards
  | SendDeliveryCardsSuccess
  | SendDeliveryCardsFail
  | DownloadDeliveryCard
  | DownloadDeliveryCardSuccess
  | DownloadDeliveryCardFail
  | GetDeliveryContacts
  | GetDeliveryContactsSuccess
  | GetDeliveryContactsFail
  | GetDeliveryUnloadingMethods
  | GetDeliveryUnloadingMethodsSuccess
  | GetDeliveryUnloadingMethodsFail
  | GetDeliveryComments
  | GetDeliveryCommentsSuccess
  | GetDeliveryCommentsFail
  | AddDeliveryComments
  | AddDeliveryCommentsSuccess
  | AddDeliveryCommentsFail
  | GetDistributors
  | GetDistributorsSuccess
  | GetDistributorsFail
  | CreateLongtimeDelivery
  | CreateLongtimeDeliveryFail
  | CreateLongtimeDeliverySuccess
  | UpdateLongtimeDelivery
  | UpdateLongtimeDeliveryFail
  | UpdateLongtimeDeliverySuccess
  | StartLoading
  | StopLoading
  | CreateMaterialInspection
  | CreateMaterialInspectionSuccess
  | CreateMaterialInspectionFail
  | UpdateDeliveryMaterial
  | UpdateDeliveryMaterialSuccess
  | UpdateDeliveryMaterialFail
  | SendOrders
  | SendOrdersFail
  | SendOrdersSuccess
  | GetDeliveryLogs
  | GetDeliveryLogsSuccess
  | GetDeliveryLogsFail
  | GetUnits
  | GetUnitsSuccess
  | GetUnitsFail
  | GetLongtermDeliveries
  | GetLongtermDeliveriesSuccess
  | GetLongtermDeliveriesFail
  | AssignStageToRun
  | AssignStageToRunSuccess
  | AssignStageToRunFail
  | DeliveryStageActions
  | SetDeliveryTimes
  | SetDeliveryTimesSuccess
  | SetDeliveryTimesFail
  | SendTicketImage
  | SendTicketImageSuccess
  | SendTicketImageFail
  | UpdateDeliveryRun
  | UpdateDeliveryRunSuccess
  | UpdateDeliveryRunFail
  | SendOnetimeDeliveryTicketImages
  | SendOnetimeDeliveryTicketImagesSuccess
  | SendOnetimeDeliveryTicketImagesFail
  | GetCurrentTicketImageUrl
  | GetCurrentTicketImageUrlSuccess
  | GetCurrentTicketImageUrlFail
  | RemoveDeliveryFile
  | RemoveDeliveryFileSuccess
  | RemoveDeliveryFileFail
  | GetPourCard
  | GetPourCardSuccess
  | GetPourCardFail
  | GetCubeTestsPurposes
  | GetCubeTestsPurposesSuccess
  | GetCubeTestsPurposesFail
  | SendPourCard
  | SendPourCardSuccess
  | SendPourCardFail
  | UpdatePourCard
  | UpdatePourCardSuccess
  | UpdatePourCardFail
  | ClearPourCardState
  | GetLongtermDeliveryTerms
  | GetLongtermDeliveryTermsSuccess
  | GetLongtermDeliveryTermsFail
  | MoveDeliveryStages
  | MoveDeliveryStagesSuccess
  | MoveDeliveryStagesFail
  | BookAgainDelivery;
