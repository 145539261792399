import { isFunction } from 'lodash-es';

import * as actions from './depots.action';
import { DepotsListState } from './index';
import { Depot, Action, MaterialDepot } from '@app/state/interfaces';

const initialState: DepotsListState = {
  depots: [],
  materialDepots: [],
  depot: null,
  totalObjectsCount: null,
  loaded: false,
  loading: false,
};

const reducerMap = {
  [actions.GET_DEPOTS]: (state: DepotsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_DEPOTS_FAIL]: (state: DepotsListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_DEPOTS_SUCCESS]: (state: DepotsListState, res: any) => ({
    ...state,
    depots: res.depots as Depot[],
    totalObjectsCount: res.meta.count,
    loaded: true,
    loading: false,
  }),
  [actions.GET_MATERIAL_DEPOTS]: (state: DepotsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_MATERIAL_DEPOTS_FAIL]: (state: DepotsListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_MATERIAL_DEPOTS_SUCCESS]: (
    state: DepotsListState,
    materialDepots: MaterialDepot[],
  ) => ({
    ...state,
    materialDepots,
    loaded: true,
    loading: false,
  }),
  [actions.GET_DEPOT]: (state: DepotsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_DEPOT_FAIL]: (state: DepotsListState, depot: Depot) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_DEPOT_SUCCESS]: (state: DepotsListState, depot: Depot) => ({
    ...state,
    depot,
    loaded: true,
    loading: false,
  }),
  [actions.CREATE_DEPOT]: (state: DepotsListState, depot: Depot) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_DEPOT_FAIL]: (state: DepotsListState, depot: Depot) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_DEPOT_SUCCESS]: (state: DepotsListState, depot: Depot) => ({
    ...state,
    depots: [depot, ...state.depots],
    depot,
    loading: false,
  }),
  [actions.UPDATE_DEPOT]: (state: DepotsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_DEPOT_FAIL]: (state: DepotsListState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_DEPOT_SUCCESS]: (state: DepotsListState, depot: Depot) => ({
    ...state,
    depot,
    loading: false,
  }),
  [actions.REMOVE_DEPOT]: (state: DepotsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.REMOVE_DEPOT_FAIL]: (state: DepotsListState) => ({
    ...state,
    loading: false,
  }),
  [actions.REMOVE_DEPOT_SUCCESS]: (state: DepotsListState, depot: Depot) => ({
    ...state,
    depots: state.depots.filter(item => item.id !== depot.id),
    loading: false,
  }),
  [actions.CLEAR_DEPOT]: (state: DepotsListState) => ({
    ...state,
    depot: null,
  }),
};

export function depotsReducer(state: DepotsListState = initialState, action: Action) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
