<form class="form" [formGroup]="form">
  <div class="form__input-container">
    <div class="form__input--50 form__select">
      <label>{{ 'deliveries_page.unloading_time_l' | translate }} *</label>
      <div>
        <ng-select
          [items]="unloadingTimes"
          [searchable]="false"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          formControlName="unloadingTime"
          test-id="emergencyDeliveryUnloadingTimeDropdown"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('unloadingTime', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
    <div class="form__input--50 form__select">
      <label>{{ 'deliveries_page.building_l' | translate }} *</label>
      <div>
        <ng-select
          [items]="buildings"
          [clearable]="false"
          bindLabel="shortName"
          bindValue="id"
          (change)="onBuildingChange($event)"
          formControlName="buildingId"
          test-id="emergencyDeliveryBuildingDropdown"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('buildingId', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="!hasSubcontractorInputPermission" class="form__input--100 form__select">
    <label>{{ 'deliveries_page.subcontractor_l' | translate }} *</label>
    <div>
      <div>
        <ng-select
          [items]="subcontractors"
          [clearable]="false"
          bindLabel="companyName"
          bindValue="id"
          formControlName="subcontractorId"
          test-id="emergencyDeliverySubcontractorDropdown"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('subcontractorId', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <div class="form__input-container">
    <div class="form__input--100 form__select">
      <label>{{ 'general.size_vehicle_l' | translate }} *</label>
      <div>
        <ng-select
          [items]="vehicleSizes"
          [searchable]="false"
          [clearable]="false"
          bindValue="id"
          bindLabel="name"
          formControlName="vehicleSizeId"
          test-id="emergencyDeliveryVehicleSizeDropdown"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('vehicleSizeId', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <div class="form__input--100 form__select">
    <label>{{ 'general.delivery_purpose_l' | translate }} *</label>
    <div>
      <ng-select
        [searchable]="false"
        [clearable]="false"
        test-id="deliveryPurposeDropdown"
        formControlName="destination"
      >
        <ng-option *ngFor="let purpose of emergencyPurposes" [value]="purpose.key">{{
          purpose.value | translate
        }}</ng-option>
      </ng-select>
    </div>
    <div class="error-message relative" test-id="errorMessage" *ngIf="hasError('destination', 'required')">
      {{ 'validation.field_required_l' | translate }}
    </div>
  </div>
  <div class="form__input--100 form__select">
    <label>{{ 'scheduler.equipment' | translate }}</label>
    <div>
      <ng-select
        [items]="equipment"
        [searchable]="false"
        [clearable]="false"
        [notFoundText]="'general.no_items_found_l' | translate"
        bindLabel="shortName"
        bindValue="id"
        formControlName="destinationEquipmentId"
      >
      </ng-select>
    </div>
  </div>

  <div class="form__input-container">
    <div class="form__input--50 form__input">
      <p>{{ 'config_header.date_l' | translate }} *</p>
      <my-date-picker
        class="datepicker"
        [locale]="lang"
        placeholder="DD/MM/YYYY"
        [options]="datePickerOptions"
        formControlName="date"
      ></my-date-picker>
      <div class="error-message relative" test-id="errorMessage" *ngIf="hasError('date', 'required')">
        {{ 'alert.incorrect_date_l' | translate }}
      </div>
    </div>
    <div class="form__input--50 form__select">
      <p>{{ 'config_header.hour_l' | translate }} *</p>
      <div>
        <ng-select
          [items]="timeSeries | filterTimeSeriesByCurrentTime: form.value.date"
          [searchable]="false"
          [clearable]="false"
          test-id="deliveryTimeDropdown"
          formControlName="time"
        >
        </ng-select>
      </div>
      <div class="error-message" test-id="errorMessage" *ngIf="hasError('time', 'required')">
        {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <div class="buttons-container" (click)="addDelivery()">
    <button class="button button--accent" test-id="addEmergencyDeliveryButton">
      {{ 'general.add_a' | translate }}
    </button>
  </div>
  <button (click)="close.emit()" class="form__cancel">{{ 'general.cancel_a' | translate }}</button>
</form>
