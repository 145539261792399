import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from '@app/core/http/error-handler.service';
import { createApiUrl, createQueryParams } from '@app/core/http/http-utils';
import { DeliveryRun } from '@app/shared';
import { Log } from '@app/state/interfaces';
import {
  GetDeliveryRunsResponse,
  QueryParamObject,
} from '@app/core/http/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DeliveriesRunsService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  public getDeliveryRun(deliveryRunId: string) {
    return this.http.get<DeliveryRun>(createApiUrl(`deliveries-runs/${deliveryRunId}`))
      .pipe(
        catchError((err) => this.errorHandlerService.errorShowAndHandle(err)),
      );
  }

  public getDeliveryRunLogs(deliveryRunId: string): Observable<Log[]> {
    return this.http.get<Log[]>(createApiUrl(`deliveries-runs/${deliveryRunId}/logs`))
      .pipe(
        catchError((err) => this.errorHandlerService.errorShowAndHandle(err)),
      );
  }

  public getTicket(deliveryId: string, fileId: string): Observable<{tempPubUrl: string}> {
    return this.http.get<{tempPubUrl: string}>(createApiUrl(`deliveries/${deliveryId}/delivery-files/${fileId}`))
      .pipe(
        catchError((err) => this.errorHandlerService.errorShowAndHandle(err)),
      );
  }

  public getDeliveryRuns(deliveryId: string, params: QueryParamObject[]): Observable<GetDeliveryRunsResponse> {
    return this.http.get<GetDeliveryRunsResponse>(
      createApiUrl(`deliveries/${deliveryId}/delivery-runs${createQueryParams(params)}`)
    );
  }
}

