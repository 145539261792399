import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '@app/shared/search/search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SvgModule } from '@app/shared/svg-icon/svg.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { InlineSVGModule } from 'ng-inline-svg';
import { MyDatePickerModule } from 'mydatepicker';
import { PipesModule } from '@app/shared/@pipes/pipes.module';

@NgModule({
  declarations: [SearchComponent],
  exports: [
    SearchComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgModule,
    NgSelectModule,
    InlineSVGModule,
    MyDatePickerModule,
    PipesModule
  ]
})
export class SearchModule { }
