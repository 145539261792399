import { createSelector, createFeatureSelector } from '@ngrx/store';

import { Equipment } from '@app/state/interfaces';
import { EquipmentListState } from '.';

const stateSelector = createFeatureSelector<EquipmentListState>('equipment');

export const getAllEquipment = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.equipments
);

export const getAllCranes = createSelector(
  getAllEquipment,
  (equipments: Equipment[]) =>
    equipments.filter(equipment => equipment.type === 'crane')
);

export const getSelectedEquipment = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.selectedEquipment
);

export const getEquipmentsLoading = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.loading
);

export const getEquipmentsCount = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.totalObjectsCount
);

export const selectEquipmentTypes = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.equipmentTypes,
);

export const selectUnloadingUtilities = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.unloadingUtilities,
);

export const selectEquipmentBrands = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.brands,
);

export const selectEquipmentModels = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.models,
);

export const selectEquipmentDeliveriesDetails = createSelector(
  stateSelector,
  (state: EquipmentListState) => state.equipmentDeliveriesDetails
);
