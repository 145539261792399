import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { HttpService } from '@app/core/http/http.service';
import { UpdateUserSuccess } from '../users';
import * as actions from './subcontractors.action';
import * as fromAppState from '@app/state';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';
import { AlertService } from '@ppgt/web/shared/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsNames } from '@app/shared/@errors/error-names';
import { CreateSubcontractorBody } from '@app/core/http/interfaces';

@Injectable()
export class SubcontractorsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.SubcontractorsAction>,
    private translateItems: TranslateItemsService,
    private alertService: AlertService
  ) {}

  getSubcontractors$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUBCONTRACTORS),
      map((action: actions.GetSubcontractors) => action.payload),
      switchMap((filters) =>
        this.http.getSubcontractors(filters).pipe(
          map((res) => new actions.GetSubcontractorsSuccess(res.data)),
          catchError((error) => [new actions.GetSubcontractorsFail()])
        )
      )
    )
  );

  createSubcontractor$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_SUBCONTRACTOR),
      map((action: actions.CreateSubcontractor) => action.payload),
      switchMap((payload: CreateSubcontractorBody) =>
        this.http.createSubcontractor(payload).pipe(
          mergeMap((res) => [
            new actions.CreateSubcontractorSuccess(),
            new fromAppState.Go({
              path: ['subcontractors'],
              alertInfo: 'alert.subcontractor_has_been_created_l',
            }),
          ]),
          catchError(() => [new actions.CreateSubcontractorFail()])
        )
      )
    )
  );

  getSubcontractor$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUBCONTRACTOR),
      map((action: actions.GetSubcontractor) => action.payload),
      switchMap((subcontractor) =>
        this.http.getSubcontractor(subcontractor).pipe(
          map((res) => new actions.GetSubcontractorSuccess(res.data.subcontractor)),
          catchError((error) => [new actions.GetSubcontractorFail()])
        )
      )
    )
  );

  getVehicles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_VEHICLES),
      switchMap(() =>
        this.http.getVehicles().pipe(
          map((vehicles) => {
            const vehiclesTranslated = vehicles.map((vehicle) => this.translateItems.vehicle(vehicle));
            return new actions.GetVehiclesSuccess(vehiclesTranslated);
          }),
          catchError((error) => [new actions.GetVehiclesFail()])
        )
      )
    )
  );

  getSubcontractorVehicles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUBCONTRACTOR_VEHICLES),
      map((action: actions.GetSubcontractorVehicles) => action.payload),
      switchMap((payload) =>
        this.http.getSubcontractorVehicles(payload).pipe(
          map((res) => new actions.GetSubcontractorVehiclesSuccess(res)),
          catchError((error) => [new actions.GetSubcontractorVehiclesFail()])
        )
      )
    )
  );

  createVehicles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_VEHICLES),
      map((action: actions.CreateVehicles) => action.payload),
      switchMap((payload) =>
        this.http.createVehicles(payload).pipe(
          map((res) => {
            this.alertService.showInfo('alert.vehicles_added_l');
            return new actions.CreateVehiclesSuccess(res);
          }),
          catchError((error) => [new actions.CreateVehiclesFail()])
        )
      )
    )
  );

  updateSubcontractor$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_SUBCONTRACTOR),
      map((action: actions.UpdateSubcontractor) => action.payload),
      switchMap(({ subcontractor }) =>
        this.http.updateSubcontractor({ subcontractor }).pipe(
          mergeMap(
            (response) =>
              [
                new actions.UpdateSubcontractorSuccess(subcontractor),
                new UpdateUserSuccess(response.subcontractorUser.user),
                new fromAppState.Go({
                  path: ['subcontractors'],
                  alertInfo: 'alert.user_updated_l',
                }),
              ] as Action[]
          ),
          catchError((error) => [new actions.UpdateSubcontractorFail(error)])
        )
      )
    )
  );

  getSubcontractorsEmployees$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUBCONTRACTORS_EMPLOYEES),
      map((action: actions.GetSubcontractorsEmployees) => action.payload.subcontractorId),
      switchMap((id) =>
        this.http.getSubcontractorsEmployees(id).pipe(
          map((employees) => new actions.GetSubcontractorsEmployeesSuccess(employees)),
          catchError(() => [new actions.GetSubcontractorsEmployeesFail()])
        )
      )
    )
  );

  getSubcontractorsEmployeeRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUBCONTRACTORS_EMPLOYEE_ROLES),
      switchMap(() =>
        this.http.getSubcontractorsEmployeeRoles().pipe(
          map((roles) => new actions.GetSubcontractorsEmployeeRolesSuccess(roles)),
          catchError(() => [new actions.GetSubcontractorsEmployeeRolesFail()])
        )
      )
    )
  );

  createSubcontractorsEmployee$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_SUBCONTRACTORS_EMPLOYEE),
      map((action: actions.CreateSubcontractorsEmployee) => action.payload),
      switchMap(({ employee, subcontractorId, redirectAfter }) => {
        const { defaultEmployee, subcontractorEmployeeRoleId, ...rest } = employee;

        return this.http.createSubcontractorsEmployee(rest).pipe(
          mergeMap((createdEmployee) => [
            new actions.CreateSubcontractorsEmployeeSuccess(),
            new actions.AssignSubcontractorsEmployee({
              subcontractorId,
              employeeId: createdEmployee.id,
              body: { defaultEmployee, subcontractorEmployeeRoleId },
              redirectAfter,
            }),
          ]),
          catchError((e: HttpErrorResponse) => {
            if (e.error.message === ErrorsNames.USER_SIMILAR_EMAIL) {
              this.alertService.showError('settings_page.user_email_exist_l');
            }
            return [new actions.CreateSubcontractorsEmployeeFail()];
          })
        );
      })
    )
  );

  assignEmployeeToSubcontractor$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ASSIGN_SUBCONTRACTORS_EMPLOYEE),
      map((action: actions.AssignSubcontractorsEmployee) => action.payload),
      switchMap(({ subcontractorId, employeeId, body, redirectAfter }) =>
        this.http.assignEmployeeToSubcontractor(subcontractorId, employeeId, body).pipe(
          mergeMap((employee) => {
            if (redirectAfter) {
              return [
                new actions.AssignSubcontractorsEmployeeSuccess(employee),
                new fromAppState.Go({
                  path: ['subcontractors'],
                  alertInfo: 'alert.subcontractor_has_been_created_l',
                }),
              ];
            }
            this.alertService.showInfo('general.changes_saved_l');

            return [new actions.AssignSubcontractorsEmployeeSuccess(employee)];
          }),
          catchError(() => [new actions.AssignSubcontractorsEmployeeFail()])
        )
      )
    )
  );

  updateSubcontractorsEmployee$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_SUBCONTRACTORS_EMPLOYEE),
      map((action: actions.UpdateSubcontractorsEmployee) => action.payload),
      switchMap(({ employee, subcontractorId }) =>
        this.http.updateSubcontractorsEmployee(employee, subcontractorId).pipe(
          mergeMap((updatedEmployee) => {
            this.alertService.showInfo('general.changes_saved_l');
            return [
              new actions.UpdateSubcontractorsEmployeeSuccess(updatedEmployee),
              // TODO get all employee's data from PUT response
              new actions.GetSubcontractorsEmployees({ subcontractorId }),
            ];
          }),
          catchError(() => [new actions.UpdateSubcontractorsEmployeeFail()])
        )
      )
    )
  );

  getSubcontractorsSuppliers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUBCONTRACTORS_SUPPLIERS),
      map((action: actions.GetSubcontractorsSuppliers) => action.payload),
      switchMap(({ subcontractorId }) =>
        this.http.getSubcontractorsSuppliers(subcontractorId).pipe(
          map((suppliers) => new actions.GetSubcontractorsSuppliersSuccess(suppliers)),
          catchError(() => [new actions.GetSubcontractorsSuppliersFail()])
        )
      )
    )
  );

  getSubcontractorsLocations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUBCONTRACTOR_LOCATIONS),
      map((action: actions.GetSubcontractorLocations) => action.payload),
      switchMap(({ subcontractorId }) =>
        this.http.getSubcontractorLocations(subcontractorId).pipe(
          map((locations) => new actions.GetSubcontractorLocationsSuccess(locations)),
          catchError(() => [new actions.GetSubcontractorLocationsFail()])
        )
      )
    )
  );
}
