import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpService } from '@app/core/http/http.service';
import * as actions from './notifications.action';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class NotificationsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.NotificationsAction>,
    private alertService: AlertService
  ) {}

  
  getNotifications$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_NOTIFICATIONS),
      switchMap(() =>
        this.http.getNotifications().pipe(
          map(res => new actions.GetNotificationsSuccess(res)),
          catchError(error => [new actions.GetNotificationsFail()])
        )
      )
    ));

  
  updateNotification$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_NOTIFICATION),
      map((action: actions.UpdateNotification) => action.payload),
      switchMap(id =>
        this.http.updateNotification(id).pipe(
          map(res => new actions.UpdateNotificationSuccess(res)),
          catchError(error => [new actions.UpdateNotificationFail(error)])
        )
      )
    ));

  
  updateNotifications$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_NOTIFICATIONS),
      switchMap(() =>
        this.http.updateNotifications().pipe(
          map(() => {
            this.alertService.showInfo(
              'alert.all_notifications_marked_as_seen_l'
            );
            return new actions.UpdateNotificationsSuccess();
          }),
          catchError(() => [new actions.UpdateNotificationsFail()])
        )
      )
    ));
}
