import { isFunction } from 'lodash-es';

import * as actions from './contracts.action';

import { ContractsListState } from './';
import { GetContractsRes } from '@app/core/http/interfaces';
import { Contract } from '../interfaces';

const initialState: ContractsListState = {
  contracts: [],
  contract: null,
  totalObjectsCount: null,
  loading: false,
};

const reducerMap = {
  [actions.GET_CONTRACTS]: (state: ContractsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_CONTRACTS_FAIL]: (state: ContractsListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_CONTRACTS_SUCCESS]: (
    state: ContractsListState,
    { contracts, meta }: GetContractsRes['data'],
  ) => ({
    ...state,
    contracts,
    totalObjectsCount: meta.count,
    loading: false,
  }),
  [actions.CREATE_CONTRACT]: (state: ContractsListState, data: any) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_CONTRACT_FAIL]: (state: ContractsListState, data: any) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_CONTRACT_SUCCESS]: (state: ContractsListState, contract: Contract) => ({
    ...state,
    contract,
    contracts: [contract, ...state.contracts],
    loading: false,
  }),
  [actions.UPDATE_CONTRACT]: (state: ContractsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_CONTRACT_FAIL]: (state: ContractsListState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_CONTRACT_SUCCESS]: (state: ContractsListState, contract: Contract) => {
    const contracts = state.contracts.map(
      contractFromState => contract.id === contractFromState.id ? contract : contractFromState
    );

    return {
      ...state,
      contract,
      contracts,
      loading: false,
    };
  },
  [actions.GET_SUBCONTRACTOR_CONTRACTS]: (state: ContractsListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTOR_CONTRACTS_FAIL]: (state: ContractsListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTOR_CONTRACTS_SUCCESS]: (
    state: ContractsListState,
    { contracts, meta }: GetContractsRes['data'],
  ) => ({
    ...state,
    contracts,
    totalObjectsCount: meta.count,
    loading: false,
  }),
};

export function contractsReducer(state: ContractsListState = initialState, action: any) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getContracts = (state: ContractsListState) => state.contracts;
export const getContract = (state: ContractsListState) => state.contract;
export const getContractCount = (state: ContractsListState) => state.totalObjectsCount;
export const getSubcontractorContractCount = (state: ContractsListState) => state.totalObjectsCount;
