import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardComponent } from './clipboard.component';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { SvgModule } from '../svg-icon/svg.module';

@NgModule({
  declarations: [ClipboardComponent],
  exports: [ClipboardComponent],
  imports: [CommonModule, ClipboardModule, TranslateModule, SvgModule],
})
export class ExtendedClipboardModule {}
