import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ReservationsState } from '.';

const stateSelector = createFeatureSelector<ReservationsState>('reservations');

export const selectEquipmentReservation = createSelector(
  stateSelector,
  (state: ReservationsState) => state.equipmentReservation,
);

