import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '@app/core/date-time.service';
import { utcToTimezone, DateType } from '@ppgt/web/shared/util';

@Pipe({ name: 'utcToTimezone' })
export class UtcToTimezonePipe implements PipeTransform {
  constructor(private dateTime: DateTimeService) {}

  transform(date: DateType, format = 'DD-MM-YYYY'): string | undefined {
    return date && utcToTimezone(date, this.dateTime.timezone).format(format);
  }
}
