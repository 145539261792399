import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpService } from '@app/core/http/http.service';
import * as actions from './materials.action';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class MaterialsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.MaterialsAction>,
    private translateItems: TranslateItemsService,
    private alertService: AlertService
  ) {}

  getMaterials$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MATERIALS),
      map((action: actions.GetMaterials) => action.payload),
      switchMap((params) =>
        this.http.getMaterials(params).pipe(
          map((data) => {
            const materials = this.translateItems.mapMaterials(data.materials);
            return new actions.GetMaterialsSuccess({ materials, meta: data.meta });
          }),
          catchError(() => [new actions.GetMaterialsFail()])
        )
      )
    )
  );

  getMaterialCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MATERIAL_CATEGORIES),
      map((action: actions.GetMaterialCategories) => action.payload),
      switchMap((params) =>
        this.http.getMaterialCategories(params).pipe(
          map((categories) => {
            const categoriesMapped = this.translateItems.mapMaterialCategories(categories);
            return new actions.GetMaterialCategoriesSuccess(categoriesMapped);
          }),
          catchError(() => [new actions.GetMaterialCategoriesFail()])
        )
      )
    )
  );

  getMaterialCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MATERIAL_CATEGORY),
      map((action: actions.GetMaterialCategory) => action.payload),
      switchMap(({ id, params }) =>
        this.http.getMaterialCategory(id, params).pipe(
          map((category) => {
            if (category === null) {
              return new actions.GetMaterialCategorySuccess({ [id]: null });
            }
            const categoryMapped = this.translateItems.mapMaterialCategory(category);
            return new actions.GetMaterialCategorySuccess({ [id]: categoryMapped });
          }),
          catchError(() => [new actions.GetMaterialCategoryFail()])
        )
      )
    )
  );

  getSuppliers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_SUPPLIERS),
      map((action: actions.GetSuppliers) => action.payload),
      switchMap((params) =>
        this.http.getSuppliers(params).pipe(
          map((suppliers) => new actions.GetSuppliersSuccess(suppliers)),
          catchError(() => [new actions.GetSuppliersFail()])
        )
      )
    )
  );

  getProducers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_PRODUCERS),
      map((action: actions.GetProducers) => action.payload),
      switchMap((params) =>
        this.http.getProducers(params).pipe(
          map((producers) => new actions.GetProducersSuccess(producers)),
          catchError(() => [new actions.GetProducersFail()])
        )
      )
    )
  );

  getRejectReasons$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_INSPECTION_REJECT_REASONS),
      map((action: actions.GetInspectionRejectReasons) => action.payload),
      switchMap(() =>
        this.http.getInspectionRejectReasons().pipe(
          map((rejectReasons) => new actions.GetInspectionRejectReasonsSuccess(rejectReasons)),
          catchError(() => [new actions.GetInspectionRejectReasonsFail()])
        )
      )
    )
  );

  getInspectionStatuses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_INSPECTION_STATUSES),
      switchMap(() =>
        this.http.getInspectionStatuses().pipe(
          map((inspectionStatuses) => new actions.GetInspectionStatusesSuccess(inspectionStatuses)),
          catchError(() => [new actions.GetInspectionStatusesFail()])
        )
      )
    )
  );

  public getMaterialDestinations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MATERIAL_DESTINATIONS),
      switchMap(() =>
        this.http.getMaterialDestinations().pipe(
          map((destinations) => new actions.GetMaterialDestinationsSuccess(destinations)),
          catchError(() => [new actions.GetMaterialDestinationsFail()])
        )
      )
    )
  );

  createMaterial$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_MATERIAL),
      map((action: actions.CreateMaterial) => action.payload),
      switchMap((payload) =>
        this.http.createMaterial(payload).pipe(
          map((material) => {
            this.alertService.showInfo('alert.material_added_l');
            return new actions.CreateMaterialSuccess(material);
          }),
          catchError(() => [new actions.CreateMaterialFail()])
        )
      )
    )
  );
  
  updateMaterial$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_MATERIAL),
    map((action: actions.UpdateMaterial) => action.payload),
    switchMap(({ data, id }) =>
      this.http.updateMaterial(data, id).pipe(
        map(material => {
          this.alertService.showInfo('alert.material_edited_l');
          return new actions.UpdateMaterialSuccess(material);
        }),
        catchError(() => [new actions.UpdateMaterialFail()]),
      ),
    ),
  ));

  public getMaterialsComments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MATERIALS_COMMENTS),
      map((action: actions.GetMaterialsComments) => action.payload),
      switchMap(({ materialId }) =>
        this.http.getMaterialComments(materialId).pipe(
          map((comments) => new actions.GetMaterialsCommentsSuccess({ materialId, comments })),
          catchError(() => [new actions.GetMaterialsCommentsFail()])
        )
      )
    )
  );

  public getMaterialsDescriptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MATERIALS_DESCRIPTIONS),
      map((action: actions.GetMaterialsDescriptions) => action.payload),
      switchMap(({ materialId }) =>
        this.http.getMaterialDescriptions(materialId).pipe(
          map((descriptions) => new actions.GetMaterialsDescriptionsSuccess({ materialId, descriptions })),
          catchError(() => [new actions.GetMaterialsDescriptionsFail()])
        )
      )
    )
  );

  public getMaterialPackingMethods$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MATERIAL_PACKING_METHODS),
      switchMap(() =>
        this.http.getMaterialPackingMethods().pipe(
          map((methods) => new actions.GetMaterialPackingMethodsSuccess({ methods })),
          catchError(() => [new actions.GetMaterialPackingMethodsFail()])
        )
      )
    )
  );
}
