import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { MyDatePickerModule } from 'mydatepicker';
import { AddDeliveryEmergencyComponent } from './add-delivery-emergency/add-delivery-emergency.component';
import { AddDeliveryOtherComponent } from './add-delivery-other/add-delivery-other.component';
import { AddDeliveryMaterialComponent } from './add-delivery-material/add-delivery-material.component';
import { AddDeliveryComponent } from './add-delivery.component';
import { FilterTimeSeriesByCurrentTimePipe } from './filter-time-series.pipe';
import { SvgModule } from '../../svg-icon/svg.module';
import { DirectivesModule } from '../../@directives/directives.module';
import { PipesModule } from '../../@pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        TranslateModule,
        MyDatePickerModule,
        SvgModule,
        DirectivesModule,
        PipesModule,
        CommonModule,
    ],
  declarations: [
    AddDeliveryEmergencyComponent,
    AddDeliveryOtherComponent,
    AddDeliveryMaterialComponent,
    AddDeliveryComponent,
    FilterTimeSeriesByCurrentTimePipe,
  ],
  exports: [AddDeliveryComponent],
})
export class AddDeliveryModule {}
