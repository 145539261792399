import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, getUserTheme } from '@app/state';
import { Theme } from '@app/config/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SvgIconService {
  public customTheme$: BehaviorSubject<Theme> = new BehaviorSubject(null);
  public theme$: Observable<Theme>;

  constructor(private store: Store<AppState>) {
    this.theme$ = combineLatest([this.store.select(getUserTheme), this.customTheme$.asObservable()]).pipe(
      map(([theme, customTheme]) => customTheme || theme)
    );
  }
}
