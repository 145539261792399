import { Action } from '@ngrx/store';

import {
  Dashboard,
  DashboardCanteen,
  DashboardTV,
  DashboardPreview,
  EODashboardDelivery,
} from '@app/state/interfaces';

// load deliveries
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';

export class GetDashboard implements Action {
  readonly type = GET_DASHBOARD;

  constructor(public payload: { loading: boolean }) {}
}

export class GetDashboardFail implements Action {
  readonly type = GET_DASHBOARD_FAIL;
}

export class GetDashboardSuccess implements Action {
  readonly type = GET_DASHBOARD_SUCCESS;

  constructor(public payload: Dashboard | EODashboardDelivery) {}
}

// load deliveries
export const GET_DASHBOARD_PREVIEW = 'GET_DASHBOARD_PREVIEW';
export const GET_DASHBOARD_PREVIEW_FAIL = 'GET_DASHBOARD_PREVIEW_FAIL';
export const GET_DASHBOARD_PREVIEW_SUCCESS = 'GET_DASHBOARD_PREVIEW_SUCCESS';

export class GetDashboardPreview implements Action {
  readonly type = GET_DASHBOARD_PREVIEW;
}

export class GetDashboardPreviewFail implements Action {
  readonly type = GET_DASHBOARD_PREVIEW_FAIL;
}

export class GetDashboardPreviewSuccess implements Action {
  readonly type = GET_DASHBOARD_PREVIEW_SUCCESS;

  constructor(public payload: DashboardPreview) {}
}

export const GET_CANTEEN_DASHBOARD = 'GET_CANTEEN_DASHBOARD';
export const GET_CANTEEN_DASHBOARD_FAIL = 'GET_CANTEEN_DASHBOARD_FAIL';
export const GET_CANTEEN_DASHBOARD_SUCCESS = 'GET_CANTEEN_DASHBOARD_SUCCESS';

export class GetCanteenDashboard implements Action {
  readonly type = GET_CANTEEN_DASHBOARD;
}

export class GetCanteenDashboardFail implements Action {
  readonly type = GET_CANTEEN_DASHBOARD_FAIL;
}

export class GetCanteenDashboardSuccess implements Action {
  readonly type = GET_CANTEEN_DASHBOARD_SUCCESS;

  constructor(public payload: DashboardCanteen) {}
}

export const GET_TV_DASHBOARD = 'GET_TV_DASHBOARD';
export const GET_TV_DASHBOARD_FAIL = 'GET_TV_DASHBOARD_FAIL';
export const GET_TV_DASHBOARD_SUCCESS = 'GET_TV_DASHBOARD_SUCCESS';

export class GetTVDashboard implements Action {
  readonly type = GET_TV_DASHBOARD;
}

export class GetTVDashboardFail implements Action {
  readonly type = GET_TV_DASHBOARD_FAIL;
}

export class GetTVDashboardSuccess implements Action {
  readonly type = GET_TV_DASHBOARD_SUCCESS;

  constructor(public payload: DashboardTV) {}
}

export const UPDATE_TV_DASHBOARD = 'UPDATE_TV_DASHBOARD';
export const UPDATE_TV_DASHBOARD_FAIL = 'UPDATE_TV_DASHBOARD_FAIL';
export const UPDATE_TV_DASHBOARD_SUCCESS = 'UPDATE_TV_DASHBOARD_SUCCESS';

export class UpdateTVDashboard implements Action {
  readonly type = UPDATE_TV_DASHBOARD;

  constructor(public payload: any) {}
}

export class UpdateTVDashboardFail implements Action {
  readonly type = UPDATE_TV_DASHBOARD_FAIL;
}

export class UpdateTVDashboardSuccess implements Action {
  readonly type = UPDATE_TV_DASHBOARD_SUCCESS;

  constructor(public payload: any) {}
}

export type DashboardAction =
  | GetDashboard
  | GetDashboardFail
  | GetDashboardSuccess
  | GetDashboardPreview
  | GetDashboardPreviewFail
  | GetDashboardPreviewSuccess
  | GetCanteenDashboard
  | GetCanteenDashboardFail
  | GetCanteenDashboardSuccess
  | GetTVDashboard
  | GetTVDashboardFail
  | GetTVDashboardSuccess
  | UpdateTVDashboard
  | UpdateTVDashboardFail
  | UpdateTVDashboardSuccess;
