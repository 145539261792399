import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './reports.action';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class ReportEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.ReportsAction>,
    private alertService: AlertService
  ) {}

  
  getReport$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_REPORT),
      map((action: actions.GetReport) => action.payload),
      switchMap(payload =>
        this.http.getReport(payload).pipe(
          map(res => new actions.GetReportSuccess(res)),
          catchError(error => [new actions.GetReportFail()])
        )
      )
    ));

  
  sendReport$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.SEND_REPORT),
      map((action: actions.SendReport) => action.payload),
      switchMap(reportData =>
        this.http.sendReport(reportData).pipe(
          map(res => {
            this.alertService.showInfo('alert.email_sent_l');
            return new actions.SendReportSuccess(res);
          }),
          catchError(error => [new actions.SendReportFail()])
        )
      )
    ));
}
