import { ActionReducer } from '@ngrx/store';

import * as effects from './notifications.effect';
import * as reducers from './notifications.reducer';

import { Notif } from '../interfaces';

export const notificationsEffects: any[] = [effects.NotificationsEffects];

export interface NotificationsState {
  notifications: Notif[];
  loading: boolean;
}

export const notificationsReducer: ActionReducer<NotificationsState> =
  reducers.notificationsReducer;

export * from './notifications.action';
export * from './notifications.effect';
export * from './notifications.reducer';
export * from './notifications.selector';
