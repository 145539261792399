import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as fromAppState from '@app/state';
import * as actions from './depots.action';
import { AlertService } from '@ppgt/web/shared/core';
import { MapService } from '@app/shared/map/map.service';

@Injectable()
export class DepotsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.DepotsAction>,
    private alertService: AlertService,
    private mapService: MapService,
  ) {}

  
  getDepots$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_DEPOTS),
      map((action: actions.GetDepots) => action.payload),
      switchMap((filters: any) =>
        this.http.getDepots(filters).pipe(
          map(res => new actions.GetDepotsSuccess(res)),
          catchError(error => [new actions.GetDepotsFail()])
        )
      )
    ));

  
    getMaterialDepots$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType(actions.GET_MATERIAL_DEPOTS),
        map((action: actions.GetMaterialDepots) => action.payload),
        switchMap(({ categoryId }) =>
          this.http.getMaterialDepots(categoryId).pipe(
            map(res => new actions.GetMaterialDepotsSuccess(res)),
            catchError(error => [new actions.GetMaterialDepotsFail()])
          )
        )
      ));

  
  createDepot$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_DEPOT),
      map((action: actions.CreateDepot) => action.payload),
      switchMap(depot =>
        this.http.createDepot(depot).pipe(
          map(res => {
            this.alertService.showInfo('alert.object_added_l');
            this.mapService.unselectObject();
            return new actions.CreateDepotSuccess(res);
          }),
          catchError(error => [new actions.CreateDepotFail()])
        )
      )
    ));

  
  getDepot$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_DEPOT),
    map((action: actions.GetDepot) => action.payload),
    switchMap(depot =>
      this.http.getDepot(depot).pipe(
        map(res => new actions.GetDepotSuccess(res)),
        catchError(error => [new actions.GetDepotFail()])
      )
    )
  ));

  
  updateDepot$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_DEPOT),
      map((action: actions.UpdateDepot) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...data } = payload;
        return this.http.updateDepot(data).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_edited_l');
            return redirectAfter
              ? [
                  new actions.UpdateDepotSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/depots'],
                    alertInfo: 'alert.object_edited_l',
                  }),
                ]
              : [new actions.UpdateDepotSuccess(res)];
          }),
          catchError(error => [new actions.UpdateDepotFail()])
        );
      })
    ));

  
  removeDepot$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.REMOVE_DEPOT),
      map((action: actions.RemoveDepot) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...depot } = payload;
        return this.http.removeDepot(depot).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_deleted_l');
            return redirectAfter
              ? [
                  new actions.RemoveDepotSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/depots'],
                    alertInfo: 'alert.object_deleted_l',
                  }),
                ]
              : [new actions.RemoveDepotSuccess(res)];
          }),
          catchError(error => [new actions.RemoveDepotFail()])
        );
      })
    ));
}
