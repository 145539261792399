import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromContracts from './contracts.reducer';
import { ContractsListState } from '.';

const stateSelector = createFeatureSelector<ContractsListState>('contracts');

export const getAllContracts = createSelector(
  stateSelector,
  fromContracts.getContracts
);

export const getLoadedContract = createSelector(
  stateSelector,
  fromContracts.getContract
);

export const getContractsCount = createSelector(
  stateSelector,
  fromContracts.getContractCount
);

export const getSubcontractorContractsCount = createSelector(
  stateSelector,
  fromContracts.getSubcontractorContractCount
);

export const getContractsLoading = createSelector(
  stateSelector,
  (state: ContractsListState) => state.loading
);
