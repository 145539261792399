import { isFunction } from 'lodash-es';

import * as actions from './construction-crews.action';

import { ConstructionCrewsState } from '.';
// import { GetContractsRes } from '@app/core/http/interfaces';
import { ConstructionCrew } from '../interfaces';

const initialState: ConstructionCrewsState = {
  constructionCrews: [],
  constructionCrew: null,
  loading: false,
};

const reducerMap = {
  [actions.GET_CONSTRUCTION_CREWS]: (state: ConstructionCrewsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_CONSTRUCTION_CREWS_FAIL]: (state: ConstructionCrewsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_CONSTRUCTION_CREWS_SUCCESS]: (state: ConstructionCrewsState, constructionCrews: ConstructionCrew) => ({
    ...state,
    constructionCrews,
    loading: false,
  }),
  [actions.CREATE_CONSTRUCTION_CREW]: (state: ConstructionCrewsState) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_CONSTRUCTION_CREW_FAIL]: (state: ConstructionCrewsState) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_CONSTRUCTION_CREW_SUCCESS]: (state: ConstructionCrewsState, constructionCrew: ConstructionCrew) => ({
    ...state,
    constructionCrew,
    constructionCrews: [constructionCrew, ...state.constructionCrews],
    loading: false,
  }),
  [actions.UPDATE_CONSTRUCTION_CREW]: (state: ConstructionCrewsState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_CONSTRUCTION_CREW_FAIL]: (state: ConstructionCrewsState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_CONSTRUCTION_CREW_SUCCESS]: (state: ConstructionCrewsState, constructionCrew: ConstructionCrew) => {
    const constructionCrews = state.constructionCrews.map((crewFromState) =>
      constructionCrew.id === crewFromState.id ? constructionCrew : crewFromState
    );

    return {
      ...state,
      constructionCrews,
      constructionCrew,
      loading: false,
    };
  },
  [actions.GET_CONSTRUCTION_CREW]: (state: ConstructionCrewsState) => ({
    ...state,
    constructionCrew: null,
    loading: true,
  }),
  [actions.GET_CONSTRUCTION_CREW_FAIL]: (state: ConstructionCrewsState) => ({
    ...state,
    constructionCrew: null,
    loading: false,
  }),
  [actions.GET_CONSTRUCTION_CREW_SUCCESS]: (state: ConstructionCrewsState, constructionCrew: ConstructionCrew) => ({
    ...state,
    constructionCrew,
    loading: false,
  }),
};

export function constructionCrewsReducer(state: ConstructionCrewsState = initialState, action: any) {
  return isFunction(reducerMap[action.type]) ? reducerMap[action.type](state, action.payload) : state;
}

export const getConstructionCrews = (state: ConstructionCrewsState) => state.constructionCrews;
export const getConstructionCrew = (state: ConstructionCrewsState) => state.constructionCrew;
