import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable ,  of ,  combineLatest } from 'rxjs';
import { map, take, filter, catchError } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { I18nService } from '../i18n.service';
import { Store } from '@ngrx/store';
import { AppState, getAllProjectSettings, GetProjectSettings } from '@app/state';
import { ProjectSettings, Translations } from '@app/state/interfaces';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationsGuard implements CanActivate {
  constructor(
    private http: HttpService,
    private i18nService: I18nService,
    private store: Store<AppState>,
    private alertService: AlertService,
  ) {}

  private getTranslationsAndSettings([translations, settings]: [Translations, ProjectSettings]) {
    if (!translations) {
      return false;
    }

    if (!settings) {
      this.store.dispatch(new GetProjectSettings());
      return false;
    }

    this.i18nService.init(translations, settings.defaultLanguageApp);
    return true;
  }

  canActivate(): Observable<boolean> {
    if (this.i18nService.translationsLoaded) {
      return of(true);
    }

    return combineLatest([
      this.http.getTranslations(),
      this.store.select(getAllProjectSettings),
    ]).pipe(
      filter(this.getTranslationsAndSettings.bind(this)),
      map(() => true),
      take(1),
      catchError(() => {
        this.alertService.showError('Get Translations failed!');
        this.i18nService.init({});

        return of(true);
      }),
    );
  }
}
