import { ActionReducer } from '@ngrx/store';

import * as effects from './settings.effect';
import * as reducers from './settings.reducer';

export const settingsEffects: any[] = [effects.SettingsEffects];

export const settingReducer: ActionReducer<reducers.SettingsState> =
  reducers.settingsReducer;

export * from './settings.action';
export * from './settings.effect';
export * from './settings.reducer';
export * from './settings.selector';
