<ng-container *ngIf="(shellService.isFullScreen$ | async) !== true">
  <header class="header"
          *ngIf="(grantedPermissions$ | async) as grantedPermissions">
    <div class="header__row">
      <div class="header__title">
        <h2 *ngIf="title">{{ title | translate }} <ng-content></ng-content></h2>
        <div class="header__buttons"
             [attr.test-id]="headerButtonsTestId || ''"
             *ngIf="button && hasPermission(requiredPermission, grantedPermissions) && buttonSpecialCondition">
          <app-clipboard [content]="buttonDescription">
            <a class="header__button"
               data-cy="header-btn-1"
               (click)="clickButton()">
              <div *ngIf="isImgPath(buttonIcon)"
                   [inlineSVG]="buttonIcon"></div>
              <svg-icon *ngIf="!isImgPath(buttonIcon)"
                        [size]="deviceService.isMobile? 30 : 20"
                        [name]="buttonIcon"></svg-icon>
              <span>
                {{ button | translate }}
              </span>
            </a>
          </app-clipboard>
          <app-clipboard *ngIf="button2"
                         [content]="buttonDescription2">
            <a class="header__button"
               data-cy="header-btn-2"
               (click)="clickButton2()">
              <div *ngIf="isImgPath(buttonIcon)"
                   [inlineSVG]="buttonIcon2"></div>
              <svg-icon *ngIf="!isImgPath(buttonIcon)"
                        [size]="deviceService.isMobile? 30 : 20"
                        [name]="buttonIcon2"></svg-icon>
              <span>
                {{ button2 | translate }}
              </span>
            </a>
          </app-clipboard>
        </div>
        <img *ngIf="logo"
             [src]="logo"
             alt="logo">
      </div>
    </div>

    <p *ngIf="description">
      {{ description | translate }}
    </p>
    <div class="header__row"
         *ngIf="tabs">
      <ul class="header__tabs">
        <li *ngFor="let link of tabs">
          <div *ngIf="link.dropdown && link.dropdown.length">
            <ng-container *ngIf="link.path">
              <a *permissionAccess="[link?.withPermission]"
                class="header__tabs__dropdown--button"
                [attr.test-id]="link.testId || ''"
                [routerLink]="relativePaths ? ['..', link.path] : link.path"
                routerLinkActive="active">{{ link.title | translate }}</a>
            </ng-container>
            <ng-container *ngIf="!link.path">
              <span *permissionAccess="[link?.withPermission]" (click)="clickLink(link.data)" class="link header__tabs__dropdown--button">{{ link.title | translate }}</span>
            </ng-container>
            <ul class="header__tabs__dropdown--list">
              <li *ngFor="let item of link.dropdown">
                <ng-container *ngIf="link.path">
                  <a [routerLink]="relativePaths ? ['..', link.path] : link.path"
                      [queryParams]="item.params">
                    {{ item.title | translate }}
                  </a>
                </ng-container>
                <ng-container *ngIf="!link.path">
                  <span *permissionAccess="[link?.withPermission]" (click)="clickLink(item.data)" class="link">{{ item.title | translate }}</span>
                </ng-container>
              </li>
            </ul>
          </div>
          <div *ngIf="!link.dropdown">
            <a *permissionAccess="[link?.withPermission]"
               [attr.test-id]="link.testId || ''"
               [routerLink]="relativePaths ? ['..', link.path] : link.path"
               routerLinkActive="active">
              {{ link.title | translate }}
            </a>
          </div>
        </li>
      </ul>
    </div>
  </header>
</ng-container>
