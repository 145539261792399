import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { MenuComponent } from './menu/menu.component';
import { FooterModule } from '@app/shared/footer/footer.module';
import { SvgModule } from '@app/shared/svg-icon/svg.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@app/shared/@directives/directives.module';

@NgModule({
  imports: [CommonModule, SvgModule, TranslateModule, FooterModule, InlineSVGModule, RouterModule, DirectivesModule],
  declarations: [ShellComponent, MenuComponent],
  exports: [ShellComponent, MenuComponent],
})
export class ShellModule {}
