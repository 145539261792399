import { SafeHtml } from '@angular/platform-browser';
import { ActionReducer } from '@ngrx/store';

import { RegulationsEffect } from '@app/state/regulations/regulations.effect';
import { regulationReducer } from '@app/state/regulations/regulations.reducer';

export interface RegulationsState {
  regulation: SafeHtml;
  isLoading: boolean;
}

export const regulationsEffect = [RegulationsEffect];
export const regulationsReducer: ActionReducer<RegulationsState> = regulationReducer;

export * from './regulations.actions';
export * from './regulations.effect';
export * from './regulations.reducer';
export * from './regulations.selector';
