import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromGates from './gates.reducer';
import { GatesListState } from '.';

const stateSelector = createFeatureSelector<GatesListState>('gates');

export const getAllGates = createSelector(stateSelector, fromGates.getGates);

export const getGatesCount = createSelector(
  stateSelector,
  fromGates.getGateCount
);

export const getGate = createSelector(stateSelector, fromGates.getSelectedGate);

export const getGatesLoaded = createSelector(
  stateSelector,
  fromGates.getLoaded
);

export const getGatesLoading = createSelector(
  stateSelector,
  (state: GatesListState) => state.loading
);
