import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as actions from './alert.actions';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class AlertEffects {
  constructor(
    private actions$: Actions<actions.AlertAction>,
    private alertService: AlertService,
  ) { }

  
  showInfo$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SHOW_INFO),
    tap((action: actions.ShowInfo) => this.alertService.showInfo(action.message)),
  ), { dispatch: false});

  
  showError$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SHOW_ERROR),
    tap((action: actions.ShowError) => this.alertService.showError(action.errorMessage)),
  ), { dispatch: false});
}
