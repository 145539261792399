import { Action } from '@ngrx/store';
import { RouterParams } from '@app/state/interfaces';

export const GO = 'ROUTER_GO';
export const BACK = 'ROUTER_BACK';
export const FORWARD = 'ROUTER_FORWARD';

export class Go implements Action {
  readonly type = GO;

  constructor(
    public payload: RouterParams
  ) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export type RouterActions = Go | Back | Forward;
