import { Component, Input } from '@angular/core';

interface Icon {
  title: string;
  path?: string;
  themeChange?: boolean;
  class?: string;
  svgName?: string;
  opacity?: number;
}

export interface Legend {
  [key: string]: Icon;
}

@Component({
  selector: 'app-table-legend',
  templateUrl: './table-legend.component.html',
  styleUrls: ['./table-legend.component.scss'],
})
export class TableLegendComponent {
  @Input() public legend: Legend;

  public get legendArray(): Icon[] {
    return this.legend ? Object.values(this.legend) : [];
  }

  public getClass(icon: Icon): string {
    const themeChange = icon.themeChange ? 'themeChange' : '';
    const className = icon.class || '';
    return [themeChange, className].filter(i => !!i).join(' ');
  }
}
