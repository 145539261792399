import { isFunction } from 'lodash-es';

import * as actions from './reports.action';
import { ReportsState } from './';
import { Report, ReducerMap } from '@app/state/interfaces';

const initialState: ReportsState = {
  report: null,
  loading: false,
  totalObjectsCount: 0,
};

const reducerMap: ReducerMap<ReportsState> = {
  [actions.GET_REPORT]: state => ({
    ...state,
    report: null,
    loading: true,
  }),
  [actions.GET_REPORT_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_REPORT_SUCCESS]: (state, report: Report) => ({
    ...state,
    report,
    totalObjectsCount: report.meta.count.count,
    loading: false,
  }),
  [actions.SEND_REPORT]: state => ({ ...state, loading: true }),
  [actions.SEND_REPORT_FAIL]: state => ({ ...state, loading: false }),
  [actions.SEND_REPORT_SUCCESS]: state => ({ ...state, loading: false }),
};

export function reportReducer(state: ReportsState = initialState, action: any) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
