import { jwtDecode } from 'jwt-decode';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { map, switchMap, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AlertService, CookieStorageService } from '@ppgt/web/shared/core';
import { HttpService } from '@app/core/http/http.service';
import { config } from '@app/app.config';
import * as AuthActions from './auth.actions';
import * as RouterActions from '@app/state/router/actions/router.actions';
import { ClearState } from '../clear-state';

import { TokenDecoded } from '@app/core/http/interfaces';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { SocketService } from '@app/core/socket/socket.service';
import { ConfirmService } from '@ppgt/web/shared/ui/confirm';
import { AppState } from '@app/state';

@Injectable()
export class UserAuthEffects {
  constructor(
    private authActions$: Actions<AuthActions.AuthActions>,
    private http: HttpService,
    private cookieStorage: CookieStorageService,
    private alertService: AlertService,
    private confirmService: ConfirmService,
    public router: Router,
    public deliveriesService: DeliveriesService,
    public socketService: SocketService,
    public store: Store<AppState>
  ) {}

  loginAction$ = createEffect(() =>
    this.authActions$.pipe(
      ofType(AuthActions.USER_LOGIN),
      map((action: AuthActions.UsersLogin) => action.payload),
      switchMap((userData) =>
        this.http.login(userData).pipe(
          map(({ token, isPasswordActive, email }) => {
            const tokenDecoded = jwtDecode(token) as TokenDecoded;
            return isPasswordActive
              ? new AuthActions.UsersLoginSuccess({ token, tokenDecoded })
              : new RouterActions.Go({
                  path: ['new-password'],
                  query: { token, email, id: tokenDecoded.id },
                  alertError: 'alert.your_password_expired_l',
                });
          }),
          catchError((error) => [new AuthActions.UsersLoginFail(error)])
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.authActions$.pipe(
      ofType(AuthActions.USER_LOGIN_SUCCESS),
      withLatestFrom(this.store),
      map(([action, { auth }]: [AuthActions.UsersLoginSuccess, AppState]) => {
        let goPayload: { path: string[]; query?: Record<string, unknown> } = {
          path: ['dashboard'],
        };

        if (auth.redirectUrl) {
          goPayload = {
            ...goPayload,
            path: [auth.redirectUrl.split('?')[0]],
            query: auth.queryParams,
          };
        }

        this.cookieStorage.set(config.storageTokenKey, action.payload.token);

        return new RouterActions.Go(goPayload);
      })
    )
  );

  logout$ = createEffect(() =>
    this.authActions$.pipe(
      ofType(AuthActions.USER_SIGN_OUT),
      map((action: AuthActions.UsersSignOut) => action.payload),
      mergeMap(({ redirect }) => {
        this.cookieStorage.remove(config.storageTokenKey);
        this.cookieStorage.remove('authMaterial');

        this.deliveriesService.closeDeliveryDetails();
        this.deliveriesService.toggleDeliveryDetails({ opened: false });

        this.confirmService.cancel();

        if (this.socketService.socket && this.socketService.socket.connected) {
          this.socketService.socket.disconnect();
        }

        const actions: Action[] = [new AuthActions.UsersSignOutSuccess(), new ClearState()];

        if (redirect) {
          actions.push(new RouterActions.Go({ path: ['logout'] }));
        }

        return actions;
      })
    )
  );

  confirmAccount$ = createEffect(() =>
    this.authActions$.pipe(
      ofType(AuthActions.CONFIRM_ACCOUNT),
      map((action: AuthActions.ConfirmAccount) => action.payload),
      switchMap(({ email, password, acceptRegulations, token }) =>
        this.http.confirmAccount({ email, password, acceptRegulations }, token).pipe(
          mergeMap(() => [new AuthActions.ConfirmAccountSuccess(), new RouterActions.Go({ path: ['login'] })]),
          catchError(() => this.alertService.showError('alert.user_creation_error_l'))
        )
      )
    )
  );

  refreshToken$ = createEffect(() =>
    this.authActions$.pipe(
      ofType(AuthActions.REFRESH_AUTH_TOKEN),
      withLatestFrom(this.store),
      switchMap(([action, { auth }]: [AuthActions.RefreshAuthToken, AppState]) =>
        this.http.refreshToken(auth.tokenDecoded.id).pipe(
          map((token) => {
            this.cookieStorage.set(config.storageTokenKey, token);
            const tokenDecoded = jwtDecode(token) as TokenDecoded;
            return new AuthActions.RefreshAuthTokenSuccess({ token, tokenDecoded });
          })
        )
      ),
      catchError((error) => [new AuthActions.RefreshAuthTokenFail(error)])
    )
  );
}
