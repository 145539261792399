import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtendedClipboardModule } from '@app/shared/clipboard/extended-clipboard.module';
import { SvgModule } from '@app/shared/svg-icon/svg.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@app/shared/header/header.component';
import { DirectivesModule } from '@app/shared/@directives/directives.module';

@NgModule({
  declarations: [HeaderComponent],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    ExtendedClipboardModule,
    SvgModule,
    InlineSVGModule,
    TranslateModule,
    RouterModule,
    DirectivesModule,
  ]
})
export class HeaderModule { }
