import { isFunction } from 'lodash-es';

import {
  Settings,
  DeliverySettings,
  ObjectSettings,
  SubcontractorSettings,
  EcologySettings,
  ProjectSettings,
  VehicleSize,
  ReducerMap, ScheduleColorsSet,
} from '@app/state/interfaces';
import * as actions from './settings.action';

export interface SettingsState {
  settings?: Settings;
  loaded?: boolean;
  deliverySettings?: DeliverySettings;
  objectSettings?: ObjectSettings;
  subcontractorSettings?: SubcontractorSettings;
  ecologySettings?: EcologySettings;
  projectSettings?: ProjectSettings;
  vehicleSizes?: VehicleSize[];
  scheduleColorsSets: ScheduleColorsSet[];
  loading: boolean;
}

const initialState: SettingsState = {
  settings: null,
  loaded: false,
  deliverySettings: null,
  objectSettings: null,
  subcontractorSettings: null,
  ecologySettings: null,
  projectSettings: null,
  vehicleSizes: null,
  scheduleColorsSets: [],
  loading: false,
};

const reducerMap: ReducerMap<SettingsState> = {
  [actions.GET_SETTINGS]: state => ({ ...state, loading: true }),
  [actions.GET_SETTINGS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_SETTINGS_SUCCESS]: (state, settings: Settings) => ({
    ...state,
    settings,
    loading: false,
  }),
  [actions.GET_DELIVERY_SETTINGS]: state => ({ ...state, loading: true }),
  [actions.GET_DELIVERY_SETTINGS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_DELIVERY_SETTINGS_SUCCESS]: (state, deliverySettings: DeliverySettings) => ({
    ...state,
    deliverySettings,
    loading: false,
  }),
  [actions.GET_OBJECT_SETTINGS]: state => ({ ...state, loading: true }),
  [actions.GET_OBJECT_SETTINGS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_OBJECT_SETTINGS_SUCCESS]: (state, objectSettings: ObjectSettings) => ({
    ...state,
    objectSettings,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTOR_SETTINGS]: state => ({ ...state, loading: true }),
  [actions.GET_SUBCONTRACTOR_SETTINGS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_SUBCONTRACTOR_SETTINGS_SUCCESS]: (
    state,
    subcontractorSettings: SubcontractorSettings,
  ) => ({
    ...state,
    subcontractorSettings,
    loading: false,
  }),
  [actions.GET_ECOLOGY_SETTINGS]: state => ({ ...state, loading: true }),
  [actions.GET_ECOLOGY_SETTINGS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_ECOLOGY_SETTINGS_SUCCESS]: (state, ecologySettings: EcologySettings) => ({
    ...state,
    ecologySettings,
    loading: false,
  }),
  [actions.GET_PROJECT_SETTINGS]: state => ({ ...state, loading: true }),
  [actions.GET_PROJECT_SETTINGS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_PROJECT_SETTINGS_SUCCESS]: (state, projectSettings: ProjectSettings) => ({
    ...state,
    projectSettings,
    loaded: true,
    loading: false,
  }),
  [actions.GET_VEHICLE_SIZES]: state => ({ ...state, loading: true }),
  [actions.GET_VEHICLE_SIZES_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_VEHICLE_SIZES_SUCCESS]: (state, vehicleSizes: VehicleSize[]) => ({
    ...state,
    vehicleSizes,
    loaded: true,
    loading: false,
  }),
  [actions.GET_SCHEDULE_COLORS]: state => ({ ...state, loading: true }),
  [actions.GET_SCHEDULE_COLORS_SUCCESS]: (state, payload: { colors: ScheduleColorsSet[] }) => ({
    ...state,
    loading: false,
    scheduleColorsSets: payload.colors,
  }),
  [actions.GET_SCHEDULE_COLORS_FAIL]: state => ({ ...state, loading: false }),
  [actions.CREATE_SCHEDULE_COLORS]: state => ({ ...state, loading: true }),
  [actions.CREATE_SCHEDULE_COLORS_SUCCESS]: (state, payload: { colorsSets: ScheduleColorsSet[] }) => ({
    ...state,
    loading: false,
    scheduleColorsSets: [ ...state.scheduleColorsSets, ...payload.colorsSets ],
  }),
  [actions.CREATE_SCHEDULE_COLORS_FAIL]: state => ({ ...state, loading: false }),
  [actions.UPDATE_SCHEDULE_COLORS]: state => ({ ...state, loading: true }),
  [actions.UPDATE_SCHEDULE_COLORS_SUCCESS]: (state, payload: { colorsSets: ScheduleColorsSet[] }) => {
    const scheduleColorsSets = state.scheduleColorsSets.map(set => {
      const updatedSet = payload.colorsSets.find(newSet => set.id === newSet.id);

      return updatedSet ? updatedSet : set;
    });

    return {
      ...state,
      loading: false,
      scheduleColorsSets,
    };
  },
  [actions.UPDATE_SCHEDULE_COLORS_FAIL]: state => ({ ...state, loading: false }),
};

export function settingsReducer(
  state: SettingsState = initialState,
  action: actions.SettingsAction,
) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getSettings = (state: SettingsState) => state.settings;
export const getDeliverySettings = (state: SettingsState) =>
  state.deliverySettings;
export const getObjectSettings = (state: SettingsState) => state.objectSettings;
export const getSubcontractorSettings = (state: SettingsState) =>
  state.subcontractorSettings;
export const getEcologySettings = (state: SettingsState) =>
  state.ecologySettings;
export const getProjectSettings = (state: SettingsState) =>
  state.projectSettings;
export const getVehicleSizes = (state: SettingsState) => state.vehicleSizes;
export const getSettingLoaded = (state: SettingsState) => state.loaded;
