import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './buildings.action';
import { MapService } from '@app/shared/map/map.service';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class BuildingsEffects {
  constructor(
    private http: HttpService,
    private mapSerivce: MapService,
    private actions$: Actions<actions.BuildingsAction>,
    private alertService: AlertService
  ) {}

  
  getBuildings$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_BUILDINGS),
      map((action: actions.GetBuildings) => action.payload),
      switchMap(filters =>
        this.http
          .getBuildings(filters)
          .pipe(
            map(res => new actions.GetBuildingsSuccess(res)),
            catchError(error => [new actions.GetBuildingsFail()])
          )
      )
    ));

  
  getBuilding$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_BUILDING),
      map((action: actions.GetBuilding) => action.payload),
      switchMap(building =>
        this.http
          .getBuilding(building)
          .pipe(
            map(res => new actions.GetBuildingSuccess(res)),
            catchError(error => [new actions.GetBuildingFail()])
          )
      )
    ));

  
  createBuilding$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_BUILDING),
      map((action: actions.CreateBuilding) => action.payload),
      switchMap(building =>
        this.http.createBuilding(building).pipe(
          map(res => {
            this.alertService.showInfo('alert.object_added_l');
            return new actions.CreateBuildingSuccess(res);
          }),
          catchError(error => [new actions.CreateBuildingFail()])
        )
      )
    ));

  
  updateBuilding$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_BUILDING),
      map((action: actions.UpdateBuilding) => action.payload),
      switchMap(building =>
        this.http.updateBuilding(building).pipe(
          map(res => {
            this.alertService.showInfo('alert.object_edited_l');
            return new actions.UpdateBuildingSuccess(res);
          }),
          catchError(error => [new actions.UpdateBuildingFail()])
        )
      )
    ));

  
  removeBuilding$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.REMOVE_BUILDING),
      map((action: actions.RemoveBuilding) => action.payload),
      switchMap(building =>
        this.http
          .removeBuilding(building)
          .pipe(
            map(res => new actions.RemoveBuildingSuccess(res)),
            catchError(error => [new actions.RemoveBuildingFail()])
          )
      )
    ));
}
