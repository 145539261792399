import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DepotsListState } from './index';
import { BuildingsState, ConstructionsState, PlacesListState, SubcontractorsState } from '@app/state';

const stateSelector = createFeatureSelector<DepotsListState>('depots');
const buildingsSelector = createFeatureSelector<BuildingsState>('buildings');
const placesSelector = createFeatureSelector<PlacesListState>('places');
const subcontractorsSelector = createFeatureSelector<SubcontractorsState>('subcontractors');
const constructionSelector = createFeatureSelector<ConstructionsState>('constructionPlan');

export const getAllDepots = createSelector(
  stateSelector,
  (state: DepotsListState) => state.depots
);

export const getMaterialDepots = createSelector(
  stateSelector,
  (state: DepotsListState) => state.materialDepots
);

export const getDepotsCount = createSelector(
  stateSelector,
  (state: DepotsListState) => state.totalObjectsCount
);

export const getDepotsLoading = createSelector(
  stateSelector,
  (state: DepotsListState) => state.loading
);

export const getSelectedDepot = createSelector(
  stateSelector,
  (state: DepotsListState) => state.depot
);

const getAllBuildings = createSelector(
  stateSelector,
  buildingsSelector,
  (depotState: DepotsListState, buildingsState: BuildingsState) =>
    [...buildingsState?.buildings, ...depotState.depot.buildings.filter((building) => !building.status)]
);

const getAllPlaces = createSelector(
  stateSelector,
  placesSelector,
  (depotState: DepotsListState, placesState: PlacesListState) =>
    [...placesState.places, ...depotState.depot.places.filter((places) => !places.status)]
);

const getSelectedSubcontractor = createSelector(
  stateSelector,
  subcontractorsSelector,
  (depotState: DepotsListState, subcontractorState: SubcontractorsState) =>
  subcontractorState.subcontractors.find(sub => sub.id === depotState.depot?.subcontractorId)
);

const getObjectOnConstruction = createSelector(
  stateSelector,
  constructionSelector,
  (depotState: DepotsListState, constructionState: ConstructionsState) =>
    constructionState.currentConstruction?.constructionSiteObjects.some(
      ({ objectId }) => objectId === depotState.depot.id,
    )
);

export const depotQuery = {
  getAllDepots,
  getMaterialDepots,
  getDepotsCount,
  getDepotsLoading,
  getSelectedDepot,
  getAllBuildings,
  getAllPlaces,
  getSelectedSubcontractor,
  getObjectOnConstruction
};
