import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UsersSignOut, AppState, UsersLogin, getTokenDecoded, RefreshAuthToken } from '@app/state';
import { filter, take } from 'rxjs/operators';
import { ConfirmService } from '@ppgt/web/shared/ui/confirm';
import { TokenDecoded } from './http/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  $authToken = this.store.pipe(select(getTokenDecoded));
  private refreshTokenTimeout: ReturnType<typeof setTimeout>;
  private logoutTimeout: ReturnType<typeof setTimeout>;

  constructor(private store: Store<AppState>, private confirmService: ConfirmService) {
    this.checkTokenExpire();
  }

  public login(userData): void {
    this.store.dispatch(new UsersLogin(userData));
  }

  public logout(): void {
    this.store.dispatch(new UsersSignOut());
  }

  private checkTokenExpire(): void {
    this.$authToken.subscribe((token) => {
      this.clearTimeouts();

      if (token) {
        this.startRefreshTokenTimer(token);
      }
    });
  }

  private startRefreshTokenTimer(jwtToken: TokenDecoded) {
    const expires = new Date(jwtToken.exp * 1000);
    const milisecondsToExpire = expires.getTime() - Date.now();
    const fiveMinutesMiliseconds = 5 * 60 * 1000;

    this.refreshTokenTimeout = setTimeout(
      () => this.openConfirm().subscribe(this.refreshToken),
      milisecondsToExpire - fiveMinutesMiliseconds
    );

    this.logoutTimeout = setTimeout(() => this.logout(), milisecondsToExpire);
  }

  private clearTimeouts() {
    clearTimeout(this.refreshTokenTimeout);
    clearTimeout(this.logoutTimeout);
  }

  private openConfirm() {
    return this.confirmService.open({ message: 'general.session_expire' }).pipe(take(1), filter(Boolean));
  }

  private refreshToken = (): void => {
    this.store.dispatch(new RefreshAuthToken());
  };
}
