import { trigger, transition, style, animate } from '@angular/animations';

export const confirmAnimation = trigger('confirm', [
  transition(':enter', [
    style({ top: '-50px', boxShadow: 'none' }),
    animate(
      '200ms ease-out',
      style({ top: '50px', boxShadow: '0 0 0 10000px rgba(0, 0, 0, 0.5)' }),
    ),
  ]),
  transition(':leave', [animate('200ms ease-in', style({ top: '-50px', boxShadow: 'none' }))]),
]);
