import { createSelector, createFeatureSelector } from '@ngrx/store';
import { NotificationsState } from '.';

const stateSelector = createFeatureSelector<NotificationsState>('notifications');

export const getNotifications = createSelector(
  stateSelector,
  (state: NotificationsState) => state.notifications
);

export const getNotificationsLoading = createSelector(
  stateSelector,
  (state: NotificationsState) => state.loading
);
