import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { map, take, filter, catchError } from 'rxjs/operators';
import { AppState, UsersSignOut } from '@app/state';
import { HttpService } from '../http/http.service';
import { I18nService } from '../i18n.service';
import { DateTimeService } from '../date-time.service';
import { SettingsService } from '../settings.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsGuard implements CanActivate {
  constructor(
    private http: HttpService,
    private settingsService: SettingsService,
    private i18nService: I18nService,
    private dateTimeService: DateTimeService,
    private store: Store<AppState>,
  ) {}

  canActivate(): Observable<boolean> {
    if (this.settingsService.settingsLoaded) {
      return of(true);
    }

    return this.http.getGeneralSettings().pipe(
      map(settings => {
        if (!settings) {
          this.store.dispatch(new UsersSignOut());

          return false;
        }

        this.settingsService.init(settings);
        this.i18nService.setLanguage(settings.language);
        const clockFormat = this.settingsService.getClockFormat();

        this.dateTimeService.init(settings, clockFormat.toString());

        return true;
      }),
      filter(loaded => loaded),
      take(1),
      catchError(() => {
        this.settingsService.init(null);
        this.dateTimeService.init(null);

        return of(true);
      }),
    );
  }
}
