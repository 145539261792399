import { EquipmentTypes, MapObjectTypes } from '@app/core/http/interfaces';

export const MapObjectWithTooltip: (EquipmentTypes | MapObjectTypes)[] = [
  EquipmentTypes.Crane,
  EquipmentTypes.Excavator,
  EquipmentTypes.MiniExcavator,
  EquipmentTypes.Forklift,
  EquipmentTypes.MobileCrane,
  EquipmentTypes.Elevator,
  EquipmentTypes.Other,
  MapObjectTypes.UnloadingPlace,
  MapObjectTypes.Depot,
];
