import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AuthState, getTokenDecoded } from '@app/state/auth';

@Injectable({
  providedIn: 'root',
})
export class RedirectionGuard implements CanActivate {
  constructor(private router: Router, private store: Store<AuthState>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(getTokenDecoded),
      map(tokenDecoded => {
        if (tokenDecoded) {
          this.router.navigate(['/dashboard'], { replaceUrl: true });
          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
