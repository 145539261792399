<aside test-id="oneTimeDeliveryForm" class="aside" [class.collapsed]="collapsed">
  <button class="aside__collapse" (click)="toggleCollapse()">
    <svg-icon name="icon-arrow-white" size="16"></svg-icon>
  </button>

  <button class="aside__close" (click)="close()">
    <svg-icon name="icon-remove" size="16"></svg-icon>
  </button>

  <h3>{{ 'deliveries_page.new_delivery_l' | translate }}</h3>

  <div *ngIf="schedulerDeliveryData?.event as schedulerEvent" class="delivery-time">
    <div class="form__input">
      <p>{{ 'config_header.date_l' | translate }}</p>
      <input type="text" [value]="schedulerEvent.startDate | date: 'dd/MM/yyyy'" disabled />
    </div>
    <div class="form__input">
      <p>{{ 'config_header.hour_l' | translate }}</p>
      <input
        type="text"
        [value]="(schedulerEvent.startDate | date: 'HH:mm') + ' - ' + (schedulerEvent.endDate | date: 'HH:mm')"
        disabled
      />
    </div>
  </div>

  <div class="tabs" [formGroup]="deliveryTypeForm">
    <div *ngFor="let type of deliveryTypes; let i = index" class="form__input form__input--radio">
      <ng-container *ngIf="availableDeliveryTypes && availableDeliveryTypes[type.name]">
        <input
          type="radio"
          formControlName="deliveryType"
          [attr.test-id]="type.testId"
          [value]="type.name"
          [id]="type.name"
        />
        <label [for]="type.name">{{ type.translation | translate }}</label>
      </ng-container>
    </div>
  </div>

  <app-add-delivery-emergency
    *ngIf="isEmergency"
    [lang]="me.language"
    [unloadingTimes]="unloadingTimes$ | async"
    [hasSubcontractorInputPermission]="hasSubcontractorInputPermission"
    [buildings]="buildings$ | async"
    [me]="me"
    [subcontractors]="subcontractors$ | async"
    [vehicleSizes]="vehicleSizes$ | async"
    (close)="close()"
    (createDelivery)="createDelivery($event)"
    (formChanged)="onFormChange($event)"
  ></app-add-delivery-emergency>

  <app-add-delivery-other
    *ngIf="!isEmergency"
    [currentType]="currentType"
    [unloadingTimes]="unloadingTimes$ | async"
    [lang]="me.language"
    [hasSubcontractorInputPermission]="hasSubcontractorInputPermission"
    [me]="me"
    [subcontractors]="subcontractors$ | async"
    [vehicleSizes]="vehicleSizes$ | async"
    [fromTooltipConfig]="additionalConfig"
    [typeChange$]="typeChange$"
    [schedulerDeliveryData]="schedulerDeliveryData"
    (close)="close()"
    (createDelivery)="createDelivery($event)"
    (formChanged)="onFormChange($event)"
    [taskData]="taskAssigmentPanelData"
    [deliveryData]="deliveryData"
  ></app-add-delivery-other>
</aside>
