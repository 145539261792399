import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ManualModeService {
  public places: string[] = [];
  public deliveries: string[] = [];

  public clear() {
    this.places = [];
    this.deliveries = [];
  }
}
