import { Directive, HostListener, Input } from '@angular/core';

import { Delivery, DeliveryStage } from '@app/state/interfaces';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { Router } from '@angular/router';

@Directive({ selector: '[openStagePanel]' })
export class OpenStagePanelDirective {

  private delivery: Delivery;

  @Input() public stage: DeliveryStage;
  @Input() public status: string;
  @Input() public stageId: string;
  @Input() public set openStagePanel(delivery: Delivery) {
    this.delivery = delivery;
  }

  constructor(
    private deliveriesService: DeliveriesService,
  ) { }

  @HostListener('click')
  public openPanel() {
    this.deliveriesService.openStagePanel(this.delivery, this.stage, this.status, this.stageId);
    return false;
  }
}
