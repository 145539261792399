import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromAppState from '@app/state';
import { ProjectSettings } from '@app/state/interfaces';
import { getAllProjectSettings } from '@app/state';
import { DateTimeService } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  public projectSettings$: Observable<ProjectSettings>;

  constructor(private store: Store<fromAppState.AppState>, private dateTimeService: DateTimeService) {}

  loadAppSettings() {
    return new Promise<boolean>((resolve) => {
      this.store.dispatch(new fromAppState.GetProjectSettings());
      this.store
        .select(getAllProjectSettings)
        .pipe(filter(Boolean), take(1))
        .subscribe(({ timezone }) => {
          this.dateTimeService.setTimezone(timezone);
          resolve(true);
        });
    });
  }
}
