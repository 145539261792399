import { environment } from '@env/environment';
import { QueryParamObject, QueryParams } from './interfaces';
import { isString, isDate } from 'lodash-es';

export function createApiUrl(path: string): string {
  return `${environment.apiUrl}/${path}`;
}

export function createQueryParams(paramObjects: QueryParamObject[]) {
  if (!paramObjects) {
    return '';
  }

  const validateValue = (value: string | number | boolean | Date) => {
    if (isString(value)) {
      return `"${encodeURI(value as string)}"`;
    }

    if (isDate(value)) {
      return value.valueOf();
    }

    return value;
  };

  const query = paramObjects
    .map((paramObject) => {
      switch (paramObject.type) {
        case QueryParams.Filter:
          return `${QueryParams.Filter}= {"field": "${paramObject.filterField}", "condition": "${
            paramObject.condition
          }", "value": ${validateValue(paramObject.value)}}`;

        case QueryParams.SingleValue:
          return `${paramObject.key}=${paramObject.value}`;

        case QueryParams.Order:
          return `${QueryParams.Order}[${paramObject.filterField}]=${paramObject.condition}`;

        case QueryParams.PageSize:
          return `${QueryParams.PageSize}=${paramObject.value}`;

        case QueryParams.Page:
          return `${QueryParams.Page}=${paramObject.value}`;

        case QueryParams.LimitedDataConstructionSite:
          return `${QueryParams.LimitedDataConstructionSite}=${paramObject.value}`;

        case QueryParams.DeliveriesTab:
          return `${QueryParams.DeliveriesTab}=${paramObject.value}`;

        case QueryParams.NoLimit:
          return 'noLimit=true';

        default:
          throw new Error('Not recognized query param type.');
      }
    })
    .join('&');

  return `?${query}`;
}
