import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  buildingsQuery,
  constructionQuery,
  GetBuildings,
  GetCurrentConstruction,
  GetPlaces,
  GetSubcontractors,
  Go,
  placesQuery,
  subcontractorQuery,
  userQuery
} from '@app/state';
import {
  CreateUpdateDepotPayload,
  DeleteDepotPayload, Depot,
  GetDepotPayload,
  GetMaterialDepotsPayload,
  UpdateDepotPayload
} from '@app/objects/depots/domain';
import { QueryParamObject } from '@app/core/http/interfaces';
import * as fromState from '@app/state';
import { RouterParams } from '@app/state/interfaces';
import {
  CreateDepot,
  depotQuery,
  GetDepot,
  GetDepots,
  GetMaterialDepots,
  RemoveDepot,
  UpdateDepot
} from '@app/objects/depots/application';
import { Observable } from 'rxjs';

@Injectable()
export class DepotFacade {
  depotCollection$ = this.store.pipe(select(depotQuery.getAllDepots));
  materialDepots$ = this.store.pipe(select(depotQuery.getMaterialDepots));
  depotCount$ = this.store.pipe(select(depotQuery.getDepotsCount));
  depotsLoading$ = this.store.pipe(select(depotQuery.getDepotsLoading));
  depot$ = this.store.pipe(select(depotQuery.getSelectedDepot));
  subcontractors$ = this.store.pipe(select(subcontractorQuery.getAllSubcontractors));
  currentConstruction$ = this.store.pipe(select(constructionQuery.getSelectedCurrentConstruction));
  buildings$ = this.store.pipe(select(buildingsQuery.getAllBuildings));
  places$ = this.store.pipe(select(placesQuery.getAllPlaces));
  theme$ = this.store.pipe(select(userQuery.getUserTheme));
  allBuildings$ = this.store.pipe(select(depotQuery.getAllBuildings));
  selectedSubcontractor$ = this.store.pipe(select(depotQuery.getSelectedSubcontractor));
  onConstruction$ = this.store.pipe(select(depotQuery.getObjectOnConstruction));

  constructor(private store: Store<fromState.AppState>) {}

  getDepot(getDepotPayload: GetDepotPayload): Observable<Depot> {
    this.store.dispatch(new GetDepot(getDepotPayload));
    return this.store.pipe(select(depotQuery.getSelectedDepot));
  }

  getDepotCollection(queryParams: QueryParamObject[]): void {
    this.store.dispatch(new GetDepots(queryParams));
  }

  getMaterialDepots(getMaterialDepotsPayload: GetMaterialDepotsPayload): void {
    this.store.dispatch(new GetMaterialDepots(getMaterialDepotsPayload ));
  }

  createDepot(depot: CreateUpdateDepotPayload): void {
    this.store.dispatch(new CreateDepot(depot));
  }

  removeDepot(deleteDepotPayload: DeleteDepotPayload): void {
    this.store.dispatch(new RemoveDepot(deleteDepotPayload));
  }

  updateDepot(depot: UpdateDepotPayload): void {
    this.store.dispatch(new UpdateDepot(depot));
  }

  getSubcontractors(queryParams: QueryParamObject[]): void {
    this.store.dispatch(new GetSubcontractors(queryParams));
  }

  getCurrentConstruction(): void {
    this.store.dispatch(new GetCurrentConstruction());
  }

  getBuildings(queryParams?: QueryParamObject[]): void {
    this.store.dispatch(new GetBuildings(queryParams));
  }

  getPlaces(queryParams?: QueryParamObject[]): void {
    this.store.dispatch(new GetPlaces(queryParams));
  }

  navigate(routerParams: RouterParams): void {
    this.store.dispatch(new Go(routerParams));
  }

}
