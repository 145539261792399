import { Pipe, PipeTransform } from '@angular/core';
import { UnloadingPlace, Equipment } from '@app/state/interfaces';
import { flatten, uniq } from 'lodash-es';

@Pipe({ name: 'filterPlacesByEquipment' })
export class FilterPlacesPipe implements PipeTransform {
  transform(places: UnloadingPlace[], equipments: Equipment[]) {
    if (equipments.every(eq => !eq)) {
      return places;
    }

    const equipmentPlaces = flatten(equipments.filter(equipment => !!equipment).map(eq => eq.places));
    const equipmentPlacesIds = uniq(equipmentPlaces.map(({ id }) => id));
    return places.filter(place => equipmentPlacesIds.includes(place.id));
  }
}
