import { Action } from '@ngrx/store';

import { CreateGateReq, QueryParamObject } from '@app/core/http/interfaces';
import { Gate } from '@app/state/interfaces';

// load gates
export const GET_GATES = 'GET_GATES';
export const GET_GATES_FAIL = 'GET_GATES_FAIL';
export const GET_GATES_SUCCESS = 'GET_GATES_SUCCESS';

export class GetGates implements Action {
  readonly type = GET_GATES;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetGatesFail implements Action {
  readonly type = GET_GATES_FAIL;
}

export class GetGatesSuccess implements Action {
  readonly type = GET_GATES_SUCCESS;

  constructor(public payload: Gate[]) {}
}

// create gate
export const CREATE_GATE = 'CREATE_GATE';
export const CREATE_GATE_FAIL = 'CREATE_GATE_FAIL';
export const CREATE_GATE_SUCCESS = 'CREATE_GATE_SUCCESS';

export class CreateGate implements Action {
  readonly type = CREATE_GATE;

  constructor(public payload: CreateGateReq) {}
}

export class CreateGateFail implements Action {
  readonly type = CREATE_GATE_FAIL;
}

export class CreateGateSuccess implements Action {
  readonly type = CREATE_GATE_SUCCESS;

  constructor(public payload: Gate) {}
}

// load selected gate
export const GET_GATE = 'GET_GATE';
export const GET_GATE_FAIL = 'GET_GATE_FAIL';
export const GET_GATE_SUCCESS = 'GET_GATE_SUCCESS';

export class GetGate implements Action {
  readonly type = GET_GATE;

  constructor(public payload: { id: string }) {}
}

export class GetGateFail implements Action {
  readonly type = GET_GATE_FAIL;
}

export class GetGateSuccess implements Action {
  readonly type = GET_GATE_SUCCESS;

  constructor(public payload: Gate) {}
}
// update selected gate
export const UPDATE_GATE = 'UPDATE_GATE';
export const UPDATE_GATE_FAIL = 'UPDATE_GATE_FAIL';
export const UPDATE_GATE_SUCCESS = 'UPDATE_GATE_SUCCESS';

export class UpdateGate implements Action {
  readonly type = UPDATE_GATE;

  constructor(
    public payload: {
      id: string;
      data: CreateGateReq;
      redirectAfter?: boolean;
    }
  ) {}
}

export class UpdateGateFail implements Action {
  readonly type = UPDATE_GATE_FAIL;
}

export class UpdateGateSuccess implements Action {
  readonly type = UPDATE_GATE_SUCCESS;

  constructor(public payload: Gate) {}
}

// remove selected gate
export const REMOVE_GATE = 'REMOVE_GATE';
export const REMOVE_GATE_FAIL = 'REMOVE_GATE_FAIL';
export const REMOVE_GATE_SUCCESS = 'REMOVE_GATE_SUCCESS';

export class RemoveGate implements Action {
  readonly type = REMOVE_GATE;

  constructor(
    public payload: {
      id: string;
      redirectAfter?: boolean;
    }
  ) {}
}

export class RemoveGateFail implements Action {
  readonly type = REMOVE_GATE_FAIL;
}

export class RemoveGateSuccess implements Action {
  readonly type = REMOVE_GATE_SUCCESS;

  constructor(public payload: any) {}
}

export type GatesAction =
  | GetGates
  | GetGatesFail
  | GetGatesSuccess
  | CreateGate
  | CreateGateFail
  | CreateGateSuccess
  | GetGate
  | GetGateFail
  | GetGateSuccess
  | UpdateGate
  | UpdateGateFail
  | UpdateGateSuccess
  | RemoveGate
  | RemoveGateFail
  | RemoveGateSuccess;
