import { Component, Input, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as dayjs from 'dayjs';
import { FormService } from '@app/shared/@services/form.service';
import {
  SearchService,
  SortingOrder,
  DateRanges,
  DateRangeItem,
  SortingGroup,
  FilterGroup,
  SearchOptions,
} from '@app/shared/search/search.service';
import { Subject, Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GenericFormArray, GenericFormGroup } from '@app/shared';
import { isEqual, isNil, isArray } from 'lodash-es';
import { AppState, GetMaterialCategories, getMaterialCategoriesList } from '@app/state';
import { Store } from '@ngrx/store';
import { MaterialCategory } from '@app/state/interfaces';
import { QueryParams, QueryParamObject } from '@app/core/http/interfaces';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { TranslateService } from '@ngx-translate/core';
import { GenericForm } from '../@interfaces';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() searchConfig: any;

  @Input() possibleDateRange = false;

  form: GenericForm<SearchOptions>;
  filters: FilterGroup[];
  sortings: any[];
  emitter$ = new BehaviorSubject<null>(null);
  openedSearch = false;
  filterGroup: FilterGroup = {
    text: '',
    property: '',
    range: { start: null, end: null },
    rangeKey: null,
    type: null,
  };
  sortingGroup: SortingGroup = {
    property: '',
    order: SortingOrder.ASC,
  };
  dateRanges = this.searchService.dateRanges;
  dateRangeCustom: DateRangeItem = {
    type: 'range',
    label: 'general.choose_date_l',
    range: { from: null, to: null },
  };
  dateRangesCustom: DateRanges = {
    ...this.searchService.dateRanges,
    custom: this.dateRangeCustom,
  };
  dateRangesFutureCustom: DateRanges = {
    ...this.searchService.dateRangesFuture,
    custom: this.dateRangeCustom,
  };
  rangeValues = Object.values(this.dateRanges);
  date: Date;
  materials$: Observable<MaterialCategory[]>;
  noLimit: QueryParamObject = { type: QueryParams.NoLimit };
  datePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    showClearDateBtn: false,
    showTodayBtn: false,
  };
  private destroy$ = new Subject<void>();

  get currentLang() {
    return this.translateService.currentLang;
  }

  getKeys(object: Record<string, unknown>) {
    return Object.keys(object);
  }

  get filtersControl() {
    return this.form.get('filters') as GenericFormArray<GenericFormGroup<FilterGroup>>;
  }

  get filtersGroups() {
    return this.filtersControl.controls;
  }

  get sortingsControl() {
    return this.form.get('sortings') as GenericFormArray<GenericFormGroup<SortingGroup>>;
  }

  get sortingsGroups() {
    return this.sortingsControl.controls;
  }

  get materialPropertyName() {
    const lang = this.translateService.currentLang;

    return `deliveryMaterials.material.translation.data.${lang}`;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formService: FormService,
    private searchService: SearchService,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetMaterialCategories([this.noLimit]));

    if (this.searchConfig) {
      this.filters = this.searchConfig.filters;
      this.sortings = this.searchConfig.sortings;
    }

    combineLatest([this.searchService.searchOptions$, this.emitter$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([data]) => {
        this.createForm();

        const filters: FilterGroup[] = [];
        const materialFilters: FilterGroup[] = [];

        if (!data) {
          return;
        }

        data.filters.forEach((item) => {
          if (item.property === 'material' || item.property.includes('materialCategory.translation.data')) {
            item.property += this.translateService.currentLang;
            materialFilters.push(item);
          } else {
            filters.push(item);
          }
        });

        if (materialFilters.length > 0) {
          filters.push(
            materialFilters.reduce(
              (a: any, b) => {
                a.text.push(b.text);
                return a;
              },
              { property: 'material', text: [] }
            )
          );
        }

        if (data.sortings.length === 0) {
          this.clearSortings();
        }

        this.form.patchValue({ ...data, filters });
      });

    this.materials$ = this.store.select(getMaterialCategoriesList);
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:keydown.escape')
  closePanel() {
    this.emitter$.next(null);
    this.openedSearch = false;
  }

  propertyChanged(filterGroupIndex: number, { property, type }: FilterGroup) {
    this.filtersGroups[filterGroupIndex].reset({ property, type });
  }

  getRange(type: 'date' | 'date-future') {
    return type === 'date' ? this.dateRangesCustom : this.dateRangesFutureCustom;
  }

  dateRangeChanged(filterGroupIndex: number, rangeKey: string, ranges: DateRanges) {
    const group = this.filtersGroups[filterGroupIndex];
    const rangeObj = ranges[rangeKey];

    group.get('rangeKey').patchValue(rangeKey);
    group.get('range').patchValue({ start: null, end: null });

    if (rangeKey !== 'custom') {
      const { from, to } = rangeObj.range;
      group.get('range').patchValue({ start: from, end: to });
      group.get('text').setValue(rangeObj.label);
    }

    group.markAsDirty();
  }

  dateRangeCustomChanged(filterGroupIndex: number, controlName: 'start' | 'end', event: IMyDateModel) {
    const filterGroup = this.form.get(['filters', filterGroupIndex]);
    const rangeGroup = filterGroup.get('range');
    const control = rangeGroup.get(controlName);

    const jsdate = event ? event.jsdate : null;
    const value = controlName === 'start' ? jsdate : dayjs(jsdate).add(1, 'd').subtract(1, 'ms').toDate();
    control.patchValue(value);
    control.markAsDirty();

    const { start, end }: { start: Date; end: Date } = rangeGroup.value;
    const label = [start, end].map((date) => (date ? dayjs(date).format('DD/MM/YYYY') : '∞')).join(' - ');
    filterGroup.get('text').setValue(label);
  }

  addFilterGroup() {
    const group = this.formBuilder.group({ ...this.filterGroup });
    this.filtersControl.push(group);
  }

  clearSortings() {
    this.formService.clearFormArray(this.sortingsControl);
    this.addSortingGroup();
  }

  addSortingGroup() {
    const group = this.formBuilder.group({ ...this.sortingGroup });
    this.sortingsControl.push(group);
  }

  removeFilterGroup(index: number, searchAfter = false) {
    this.filtersControl.removeAt(index);

    if (index === 0) {
      this.addFilterGroup();
    }

    if (searchAfter) {
      this.updateSearchOptions();
    }
  }

  removeSortingGroup(index: number) {
    this.sortingsControl.removeAt(index);

    if (index === 0) {
      this.addSortingGroup();
    }
  }

  resetSorting(addEmptyGroup: boolean = true) {
    this.form.setControl('sortings', this.formBuilder.array([]));

    if (addEmptyGroup) {
      this.addSortingGroup();
    }
  }

  updateSortings(searchOptions: any) {
    if (!searchOptions) {
      return false;
    }

    this.resetSorting(!searchOptions.sortings.length);

    searchOptions.sortings.forEach((item: any) => {
      const group = this.formBuilder.group(item);
      this.sortingsControl.push(group);
    });

    this.form.patchValue(searchOptions.dateRange);
  }

  getFilterDetail(index: number, detail: string) {
    const property = this.form.get(['filters', index, 'property']).value;
    const searchedFilter = this.filters.find((filter) => filter.property === property);

    return searchedFilter && searchedFilter[detail];
  }

  getFilterValueTranslation(index: number) {
    const text = this.form.get(['filters', index, 'text']).value;
    const options = this.getFilterDetail(index, 'values') as any[];
    const option = options.find(({ value }) => value === text);
    return option ? option.name : '';
  }

  getAsString(value: string | number | string[]) {
    if (!isArray(value)) {
      return value.toString();
    }

    return (value as any[]).join(', ');
  }

  checkSortingProperty(index: number) {
    return this.form.value.sortings[index].property;
  }

  private createForm() {
    this.form = this.formService.buildStructure({
      filters: [this.filterGroup],
      sortings: [this.sortingGroup],
      dateRange: '',
    }) as UntypedFormGroup;
  }

  private updateSearchOptions() {
    const filters = this.form.value.filters
      .filter((filterGroup) => !isNil(filterGroup.text))
      .map((filterGroup) =>
        filterGroup.property.includes('translation.data')
          ? [{ ...filterGroup, property: this.materialPropertyName }]
          : [filterGroup]
      )
      .reduce((a, b) => [...a, ...b], []);

    const sortings = this.form.value.sortings.filter((sortingGroup) => !isNil(sortingGroup.property));

    this.searchService.loadSearchOptions({ ...this.form.value, filters, sortings });
  }

  @HostListener('document:keydown.enter')
  search() {
    if (this.form.pristine || !this.openedSearch) {
      return;
    }

    this.updateSearchOptions();
    this.openedSearch = false;
  }
}
