import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './construction-objects.action';

@Injectable()
export class ConstructionObjectsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.ConstructionObjectsAction>
  ) {}

  
  getConstructionObjects$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_CONSTRUCTION_OBJECTS),
      map((action: actions.GetConstructionObjects) => action.payload),
      switchMap(objects =>
        this.http
          .getConstructionObjects(objects)
          .pipe(
            map(res => new actions.GetConstructionObjectsSuccess(res)),
            catchError(error => [new actions.GetConstructionObjectsFail()])
          )
      )
    ));

  
  createConstructionObject$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_CONSTRUCTION_OBJECT),
      map((action: actions.CreateConstructionObject) => action.payload),
      switchMap(construction =>
        this.http
          .createConstructionObject(construction)
          .pipe(
            map(res => new actions.CreateConstructionObjectSuccess(res)),
            catchError(error => [new actions.CreateConstructionObjectFail()])
          )
      )
    ));

  
  getConstructionObject$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_CONSTRUCTION_OBJECT),
      map((action: actions.GetConstructionObject) => action.payload),
      switchMap(construction =>
        this.http
          .getConstructionObject(construction)
          .pipe(
            map(res => new actions.GetConstructionObjectSuccess(res)),
            catchError(error => [new actions.GetConstructionObjectFail()])
          )
      )
    ));

  
  updateConstructionObject$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_CONSTRUCTION_OBJECT),
      map((action: actions.UpdateConstructionObject) => action.payload),
      switchMap(construction =>
        this.http
          .updateConstructionObject(construction)
          .pipe(
            map(res => new actions.UpdateConstructionObjectSuccess(res)),
            catchError(error => [new actions.UpdateConstructionObjectFail()])
          )
      )
    ));

  
  removeConstructionObject$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.REMOVE_CONSTRUCTION_OBJECT),
      map((action: actions.RemoveConstructionObject) => action.payload),
      switchMap(construction =>
        this.http
          .removeConstructionObject(construction)
          .pipe(
            map(res => new actions.RemoveConstructionObjectSuccess(res)),
            catchError(error => [new actions.RemoveConstructionObjectFail()])
          )
      )
    ));

  
  deactivateObjects$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.DEACTIVATE_OBJECTS),
      map((action: actions.DeactivateObjects) => action.payload),
      switchMap(objects => this.http.deactivateObjects(objects)
          .pipe(
            map(() => new actions.DeactivateObjectsSuccess()),
            catchError(() => [new actions.DeactivateObjectsFail()]),
          )),
    ));
}
