import { Directive, HostListener, Input } from '@angular/core';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { RunPanelConfig } from '@app/state/interfaces';

@Directive({ selector: '[openRunPanel]' })
export class OpenRunPanelDirective {

  runPanelConfig: RunPanelConfig;

  @Input() public set openRunPanel(runPanelConfig: RunPanelConfig) {
    this.runPanelConfig = runPanelConfig;
  }

  constructor(
    private deliveriesService: DeliveriesService,
  ) { }

  @HostListener('click')
  public openPanel() {
    this.runPanelConfig.opened = true;
    this.deliveriesService.openDeliveryRunPanel(this.runPanelConfig);
  }
}
