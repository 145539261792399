import { InjectionToken } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { clearState } from './clear-state';
import * as fromAuth from './auth';
import * as fromRouter from './router';
import * as fromBuildings from './buildings';
import * as fromConstructionObjects from './construction-objects';
import * as fromConstructions from './construction-plan';
import * as fromContracts from './contracts';
import * as fromDashboard from './dashboard';
import * as fromDeliveries from './deliveries';
import * as fromDeliveryRuns from './delivery-runs';
import * as fromDepots from '../objects/depots/application/state';
import * as fromEquipment from './equipment';
import * as fromGates from './gates';
import * as fromMaterials from './materials';
import * as fromUsers from './users';
import * as fromPlaces from './places';
import * as fromSubcontractors from './subcontractors';
import * as fromReservations from './reservations';
import * as fromSettings from './settings';
import * as fromReports from './reports';
import * as fromNotifications from './notifications';
import * as fromRoles from './roles';
import * as fromRegulations from './regulations';
import * as fromSuppliers from './suppliers';
import * as fromPermissions from './permissions';
import * as fromAlert from './alert';
import * as fromTasksImportSource from './tasksImportSource';
import * as fromConstructionCrews from './construction-crews';

export const effects: any[] = [
  fromRouter.RouterEffects,
  ...fromAuth.authEffects,
  ...fromBuildings.buildingsEffects,
  ...fromConstructionObjects.constructionObjectsEffects,
  ...fromConstructions.constructionEffects,
  ...fromContracts.contractsEffects,
  ...fromDashboard.dashboardsEffects,
  ...fromDeliveries.deliveriesEffects,
  ...fromDeliveryRuns.deliveryRunsEffects,
  ...fromDepots.depotsEffects,
  ...fromEquipment.equipmentEffects,
  ...fromGates.gatesEffects,
  ...fromMaterials.materialsEffects,
  ...fromUsers.usersEffects,
  ...fromPlaces.placesEffects,
  ...fromSubcontractors.subcontractorsEffects,
  ...fromSuppliers.suppliersEffects,
  ...fromReservations.reservationsEffects,
  ...fromSettings.settingsEffects,
  ...fromReports.reportsEffects,
  ...fromNotifications.notificationsEffects,
  ...fromRoles.rolesEffects,
  ...fromRegulations.regulationsEffect,
  ...fromPermissions.permissionsEffects,
  ...fromAlert.alertEffects,
  ...fromTasksImportSource.tasksImportSourceEffects,
  ...fromConstructionCrews.constructionCrewsEffects
];

export interface AppState {
  auth: fromAuth.AuthState;
  routerReducer?: any;
  buildings: fromBuildings.BuildingsState;
  constructionObjects: fromConstructionObjects.ConstructionObjectsState;
  constructionPlan: fromConstructions.ConstructionsState;
  contracts: fromContracts.ContractsListState;
  dashboard: fromDashboard.DashboardState;
  deliveries: fromDeliveries.DeliveriesState;
  deliveryRuns: fromDeliveryRuns.DeliveryRunsState;
  depots: fromDepots.DepotsListState;
  equipment: fromEquipment.EquipmentListState;
  gates: fromGates.GatesListState;
  materials: fromMaterials.MaterialsState;
  users: fromUsers.UsersState;
  places: fromPlaces.PlacesListState;
  subcontractors: fromSubcontractors.SubcontractorsState;
  suppliers: fromSuppliers.SuppliersState;
  reservations: fromReservations.ReservationsState;
  settings: fromSettings.SettingsState;
  reports: fromReports.ReportsState;
  notifications: fromNotifications.NotificationsState;
  roles: fromRoles.RolesState;
  regulations: fromRegulations.RegulationsState;
  permissions: fromPermissions.PermissionsState;
  tasksImportSource: fromTasksImportSource.TasksImportSourceState;
  constructionCrews: fromConstructionCrews.ConstructionCrewsState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  ...fromRouter.routerReducer,
  buildings: fromBuildings.buildingsReducer,
  constructionObjects: fromConstructionObjects.constructionObjectsReducer,
  constructionPlan: fromConstructions.constructionReducer,
  contracts: fromContracts.contractsReducer,
  dashboard: fromDashboard.dashboardReducer,
  deliveries: fromDeliveries.deliveriesReducer,
  deliveryRuns: fromDeliveryRuns.deliveryRunsReducer,
  depots: fromDepots.depotsReducer,
  equipment: fromEquipment.equipmentReducer,
  gates: fromGates.gatesReducer,
  materials: fromMaterials.materialsReducer,
  users: fromUsers.usersReducer,
  places: fromPlaces.placesReducer,
  subcontractors: fromSubcontractors.subcontractorsReducer,
  suppliers: fromSuppliers.suppliersReducer,
  reservations: fromReservations.reservationsReducer,
  settings: fromSettings.settingsReducer,
  reports: fromReports.reportReducer,
  notifications: fromNotifications.notificationsReducer,
  roles: fromRoles.rolesReducer,
  regulations: fromRegulations.regulationReducer,
  permissions: fromPermissions.permissionsReducer,
  tasksImportSource: fromTasksImportSource.tasksReducer,
  constructionCrews: fromConstructionCrews.constructionCrewsReducer,
};

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');

export const reducerProvider = [{ provide: reducerToken, useValue: reducers }];

export const metaReducers: MetaReducer<AppState>[] = [clearState];

export * from './auth';
export * from './router';
export * from './buildings';
export * from './construction-objects';
export * from './construction-plan';
export * from './contracts';
export * from './dashboard';
export * from './deliveries';
export * from './delivery-runs';
export * from '../objects/depots/application/state';
export * from './equipment';
export * from './gates';
export * from './materials';
export * from './users';
export * from './places';
export * from './subcontractors';
export * from './suppliers';
export * from './reservations';
export * from './settings';
export * from './reports';
export * from './notifications';
export * from './roles';
export * from './regulations';
export * from './suppliers';
export * from './permissions';
export * from './alert';
export * from './tasksImportSource';
export * from './construction-crews';
