import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as dayjs from 'dayjs';
import { QueryParams, Filter, Sorting } from '@app/core/http/interfaces';
import { isNil, cloneDeep } from 'lodash-es';
import { TableHeader, Filtering } from '@app/config/interfaces';
import { ReportQueries } from '@app/state/interfaces';
import { rangeFromInterval, getEndOfTime } from '@ppgt/web/shared/util';
import { DateRange } from '@ppgt/web/shared/domain';

export interface FilterGroup {
  text: string;
  label?: string;
  property: string;
  range?: { start: Date; end: Date };
  rangeKey?: string;
  type?: Filtering['type'];
}

export interface SortingGroup {
  order: SortingOrder;
  property: string;
}

export interface SearchOptions {
  filters?: FilterGroup[];
  sortings?: SortingGroup[];
  dateRange?: { type: ReportQueries['type']; label: string; range: DateRange };
}

export enum SortingOrder {
  ASC = 'ASC',
  DESC = 'DESC',
  NO = '',
}

export interface DateRangeItem {
  type: 'range';
  label: string;
  range: DateRange;
}
export interface DateRanges {
  [key: string]: DateRangeItem;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  orders: SortingOrder[] = [SortingOrder.ASC, SortingOrder.DESC, SortingOrder.NO];
  dateRanges: DateRanges = {
    today: {
      type: 'range',
      label: 'general.today_lower_letter_l',
      range: rangeFromInterval('day', -1, getEndOfTime()),
    },
    yesterday: {
      type: 'range',
      label: 'general.yesterday_l',
      range: rangeFromInterval('day', -1, getEndOfTime(dayjs().subtract(1, 'day'))),
    },
    last7Days: {
      type: 'range',
      label: 'general.last_7_days_l',
      range: rangeFromInterval('day', -7, dayjs()),
    },
    last14Days: {
      type: 'range',
      label: 'general.last_14_days_l',
      range: rangeFromInterval('day', -14, dayjs()),
    },
    lastMonth: {
      type: 'range',
      label: 'general.last_month_l',
      range: rangeFromInterval('month', -1, dayjs()),
    },
    last3Months: {
      type: 'range',
      label: 'general.last_3_months_l',
      range: rangeFromInterval('month', -3, dayjs()),
    },
    lastYear: {
      type: 'range',
      label: 'general.last_year_l',
      range: rangeFromInterval('year', -1, dayjs()),
    },
  };

  dateRangesFuture: DateRanges = {
    tomorrow: {
      type: 'range',
      label: 'general.tomorrow_l',
      range: rangeFromInterval('day', 1, getEndOfTime()),
    },
    next7Days: {
      type: 'range',
      label: 'general.next_7_days_l',
      range: rangeFromInterval('day', 7, dayjs()),
    },
    next14Days: {
      type: 'range',
      label: 'general.next_14_days_l',
      range: rangeFromInterval('day', 14, dayjs()),
    },
    nextMonth: {
      type: 'range',
      label: 'general.next_month_l',
      range: rangeFromInterval('month', 1, dayjs()),
    },
  };
  options: SearchOptions = {
    filters: [{ text: '', property: '' }],
    sortings: [{ property: '', order: SortingOrder.ASC }],
    dateRange: this.dateRanges['last14Days'],
  };

  private searchOptionsSource = new BehaviorSubject<SearchOptions>(null);
  public searchOptions$ = this.searchOptionsSource.asObservable();

  loadSearchOptions(data: SearchOptions) {
    this.options = { ...this.options, ...data };
    this.searchOptionsSource.next(this.options);
  }

  getNextOrder(order: SortingOrder) {
    const index = this.orders.indexOf(order);
    const nextIndex = this.orders.length <= index + 1 ? 0 : index + 1;

    return this.orders[nextIndex];
  }

  resetSorting(defaultSorting: SortingGroup[] = []) {
    this.options.sortings = cloneDeep(defaultSorting);
    this.searchOptionsSource.next(this.options);
  }

  clearOptionsFilters() {
    this.options.filters = [];
  }

  updateTableColumns(tableColumns: TableHeader[], sortingOptions: SortingGroup[]): TableHeader[] {
    tableColumns.forEach((tableColumn) => {
      tableColumn.order = SortingOrder.NO;
    });

    sortingOptions.forEach((sortingOption) => {
      tableColumns.forEach((tableColumn) => {
        if (tableColumn.header === sortingOption.property) {
          tableColumn.order = sortingOption.order;
        }
      });
    });
    
    return cloneDeep(tableColumns)
  }

  sort(property: string) {
    let addedOption = false;

    this.options.sortings.forEach((item) => {
      if (item.property === property) {
        item.order = this.getNextOrder(item.order);
        addedOption = true;
      }
    });

    this.options.sortings = this.options.sortings.filter((item) => item.property && item.order);

    if (!addedOption) {
      this.options.sortings.push({ property, order: this.orders[0] });
    }

    this.searchOptionsSource.next(this.options);
  }

  setDateRange(currentDateRange: string) {
    this.options.dateRange = this.dateRanges[currentDateRange];
    this.searchOptionsSource.next(this.options);
  }

  getClass(item: TableHeader) {
    return [item.order, item.class, item.sortPossible ? 'sortable' : ''].filter((c) => !!c).join(' ');
  }

  getClassArray(item: TableHeader) {
    return [item.order, item.class].filter((c) => !!c);
  }

  getRangeFilters(startDate: Date, endDate: Date): Filter[] {
    return [
      {
        type: QueryParams.Filter,
        filterField: 'dateUnloading',
        condition: 'gte',
        value: startDate,
      },
      {
        type: QueryParams.Filter,
        filterField: 'dateUnloading',
        condition: 'lte',
        value: endDate,
      },
    ];
  }

  parseSearchOptions(searchOptions: SearchOptions) {
    if (!searchOptions) {
      return { filters: [], sortings: [] };
    }

    const filters = searchOptions.filters
      .filter((item) => item.property && !isNil(item.text))
      .map(this.getFilterQueryParams)
      .reduce((pv, cv) => [...pv, ...cv], []);

    const sortings = searchOptions.sortings.filter((item) => item.property).map(this.mapSorting);

    return { filters, sortings };
  }

  getFilterQueryParams = (item: FilterGroup): Filter[] => {
    const filter: Filter[] = [];

    if (!item.rangeKey) {
      filter.push({
        type: QueryParams.Filter,
        filterField: item.property,
        condition: item.type === 'text' ? 'iLike' : 'eq',
        value: item.text,
      });
      return filter;
    }

    if (item.range.start) {
      filter.push({
        type: QueryParams.Filter,
        filterField: item.property,
        condition: 'gte',
        value: item.range.start,
      });
    }

    if (item.range.end) {
      filter.push({
        type: QueryParams.Filter,
        filterField: item.property,
        condition: 'lte',
        value: item.range.end,
      });
    }

    return filter;
  };

  mapSorting = (item: SortingGroup): Sorting => ({
    type: QueryParams.Order,
    filterField: item.property,
    condition: item.order,
  });
}
