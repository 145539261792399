import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  public transform(array: any[], paramName: string, sortParam?: string): any {
    if (sortParam === 'asc') {
      return array.sort((a, b) => {
        const a1 = this.removeColon(a.get([...paramName.split(',')]).value);
        const b1 = this.removeColon(b.get([...paramName.split(',')]).value);
        return this.convertToNumber(a1) > this.convertToNumber(b1) ? 1 : -1;
      });
    }

    if (sortParam === 'desc') {
      return array.sort((a, b) => {
        const a1 = this.removeColon(a.get([...paramName.split(',')]).value);
        const b1 = this.removeColon(b.get([...paramName.split(',')]).value);
        return this.convertToNumber(a1) < this.convertToNumber(b1) ? 1 : -1;
      });
    }

    return null;
  }

  private removeColon(timeValue: string): string {
    if (!timeValue) {
      return;
    }

    return timeValue.replace(':', '');
  }

  private convertToNumber(value: string): number {
    return parseInt(value, 10) || null;
  }
}
