import { ActionReducer} from '@ngrx/store';

import * as effects from './delivery-runs.effects';
import * as reducers from './delivery-runs.reducer';
import { DeliveryRun, Log } from '@app/state/interfaces';

export const deliveryRunsEffects = [effects.DeliveryRunsEffects];

export interface DeliveryRunsState {
  deliveryRunLoading: boolean;
  deliveryRunData: DeliveryRun;
  deliveryRunLogs: Log[];
  deliveryRuns: DeliveryRun[];
  deliveryRunsCount: number;
  ticket: string;
}

export const deliveryRunsReducer: ActionReducer<DeliveryRunsState> = reducers.deliveryRunsReducer;

export * from './delivery-runs.action';
export * from './delivery-runs.effects';
export * from './delivery-runs.reducer';
export * from './delivery-runs.selector';
