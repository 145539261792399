import { Action } from '@ngrx/store';

import { Permission, PermissionsSection } from '@interfaces';

export const GET_PERMISSIONS = '[PERMISSIONS] GET_PERMISSIONS';
export const GET_PERMISSIONS_FAIL = '[PERMISSIONS] GET_PERMISSIONS_FAIL';
export const GET_PERMISSIONS_SUCCESS = '[PERMISSIONS] GET_PERMISSIONS_SUCCESS';

export const GET_SECTIONS = '[PERMISSIONS] GET_SECTIONS';
export const GET_SECTIONS_FAIL = '[PERMISSIONS] GET_SECTIONS_FAIL';
export const GET_SECTIONS_SUCCESS = '[PERMISSIONS] GET_SECTIONS_SUCCESS';

export const UPDATE_PERMISSION = '[PERMISSIONS] UPDATE_PERMISSION';
export const UPDATE_PERMISSION_FAIL = '[PERMISSIONS] UPDATE_PERMISSION_FAIL';
export const UPDATE_PERMISSION_SUCCESS = '[PERMISSIONS] UPDATE_PERMISSION_SUCCESS';


export const UPDATE_SECTION = '[PERMISSIONS] UPDATE_SECTION';
export const UPDATE_SECTION_FAIL = '[PERMISSIONS] UPDATE_SECTION_FAIL';
export const UPDATE_SECTION_SUCCESS = '[PERMISSIONS] UPDATE_SECTION_SUCCESS';

export class GetPermissions implements Action {
  readonly type = GET_PERMISSIONS;
}
export class GetPermissionsFail implements Action {
  readonly type = GET_PERMISSIONS_FAIL;

  constructor(public payload: { error: any }) {}
}

export class GetPermissionsSuccess implements Action {
  readonly type = GET_PERMISSIONS_SUCCESS;

  constructor(public payload: { fetchedPermissions: Permission[] }) {}
}

export class GetSections implements Action {
  readonly type = GET_SECTIONS;
}
export class GetSectionsFail implements Action {
  readonly type = GET_SECTIONS_FAIL;

  constructor(public payload: { error: any }) {}
}
export class GetSectionsSuccess implements Action {
  readonly type = GET_SECTIONS_SUCCESS;

  constructor(public payload: {fetchedSections: PermissionsSection[]}) {}
}

export class UpdatePermission implements Action {
  readonly type = UPDATE_PERMISSION;

  constructor(public payload: { permissionToUpdate: Partial<Permission> }) {}
}

export class UpdatePermissionSuccess implements Action {
  readonly type = UPDATE_PERMISSION_SUCCESS;

  constructor(public payload: { updatedPermission: Permission }) {}
}

export class UpdatePermissionFail implements Action {
  readonly type = UPDATE_PERMISSION_FAIL;

  constructor(public payload: { error: any }) {}
}


export class UpdateSection implements Action {
  readonly type = UPDATE_SECTION;

  constructor(public payload: { sectionToUpdate: {[langKey: string]: Partial<PermissionsSection>} }) {}
}

export class UpdateSectionSuccess implements Action {
  readonly type = UPDATE_SECTION_SUCCESS;

  constructor(public payload: { updatedSection: PermissionsSection }) {}
}

export class UpdateSectionFail implements Action {
  readonly type = UPDATE_SECTION_FAIL;

  constructor(public payload: { error: any }) {}
}


export type PermissionsAction =
  | GetPermissions
  | GetPermissionsFail
  | GetPermissionsSuccess
  | GetSections
  | GetSectionsFail
  | GetSectionsSuccess
  | UpdatePermission
  | UpdatePermissionFail
  | UpdatePermissionSuccess
  | UpdateSection
  | UpdateSectionFail
  | UpdateSectionSuccess;
