import { Permission, PermissionsSection } from '@interfaces';
import * as actions from './permissions.action';

export interface PermissionsState {
  permissions: Permission[];
  sections: PermissionsSection[];
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
}

const initialState: PermissionsState = {
  permissions: null,
  sections: null,
  isLoaded: false,
  isLoading: false,
  error: null
};

export function permissionsReducer(
  state: PermissionsState = initialState,
  action: actions.PermissionsAction
): PermissionsState {
  switch (action.type) {
    case actions.UPDATE_PERMISSION:
    case actions.GET_PERMISSIONS:
    case actions.UPDATE_SECTION:
    case actions.GET_SECTIONS:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case actions.UPDATE_PERMISSION_SUCCESS: {
      const { updatedPermission } = action.payload;
      const updatedPermissions = [...state.permissions];
      updatedPermissions.splice(
        updatedPermissions.findIndex(permission => permission.id === updatedPermission.id),
        1,
        { ...updatedPermission }
      );

      return {
        ...state,
        isLoading: false,
        error: null,
        permissions: [...updatedPermissions],
      };
    }

    case actions.UPDATE_SECTION_SUCCESS: {
      const { updatedSection } = action.payload;
      const updatedSections = [...state.sections];
      updatedSections.splice(
        updatedSections.findIndex(section => section.id === updatedSection.id),
        1,
        { ...updatedSection }
      );

      return {
        ...state,
        isLoading: false,
        error: null,
        sections: [...updatedSections],
      };
    }

    case actions.UPDATE_PERMISSION_FAIL:
    case actions.UPDATE_SECTION_FAIL:
    case actions.GET_SECTIONS_FAIL:
    case actions.GET_PERMISSIONS_FAIL:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload.error
      };
    case actions.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload.fetchedPermissions,
        isLoaded: true,
        isLoading: false,
        error: null
      };

    case actions.GET_SECTIONS_SUCCESS:
      return {
        ...state,
        sections: action.payload.fetchedSections,
        isLoaded: true,
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
}
