import { Action, DeliveryStage, ReducerMap } from '@app/state/interfaces';
import * as actions from './delivery-runs.action';
import { DeliveryRunsState } from './index';
import { isFunction } from 'lodash-es';
import { DeliveryRun } from '@app/shared';
import { ResponseMetadata } from '@app/core/http/interfaces';

const initialState: DeliveryRunsState = {
  deliveryRunLoading: false,
  deliveryRunData: null,
  deliveryRunLogs: null,
  ticket: null,
  deliveryRuns: [],
  deliveryRunsCount: 0,
};

const reducerMap: ReducerMap<any> = {
  [actions.GET_DELIVERY_RUN]: state => ({ ...state, deliveriesLoading: true }),
  [actions.GET_DELIVERY_RUN_FAIL]: state => ({ ...state, deliveriesLoading: false }),
  [actions.GET_DELIVERY_RUN_SUCCESS]: (state, data) => ({
    ...state,
    deliveryRunData: data,
    deliveryRunLoading: false,
  }),
  [actions.GET_DELIVERY_RUN_LOGS]: state => ({ ...state, deliveriesLoading: true }),
  [actions.GET_DELIVERY_RUN_LOGS_FAIL]: state => ({ ...state, deliveriesLoading: false }),
  [actions.GET_DELIVERY_RUN_LOGS_SUCCESS]: (state, data) => ({
    ...state,
    deliveryRunLogs: data,
    deliveriesLoading: false,
  }),
  [actions.GET_DELIVERY_RUN_TICKET]: state => ({ ...state, deliveriesLoading: true }),
  [actions.GET_DELIVERY_RUN_TICKET_FAIL]: state => ({ ...state, deliveriesLoading: false }),
  [actions.GET_DELIVERY_RUN_TICKET_SUCCESS]: (state, data) => ({
    ...state,
    ticket: data,
    deliveriesLoading: false,
  }),
  [actions.GET_DELIVERY_RUNS]: state => ({ ...state, deliveryRunLoading: true }),
  [actions.GET_DELIVERY_RUNS_SUCCESS]: (state, payload: { deliveryRuns: DeliveryRun[]; meta: ResponseMetadata }) => ({
    ...state,
    deliveryRunLoading: false,
    deliveryRuns: payload.deliveryRuns,
    deliveryRunsCount: payload.meta.count,
  }),
  [actions.GET_DELIVERY_RUNS_FAIL]: state => ({ ...state, deliveryRunLoading: false }),
  [actions.UPDATE_STAGE_IN_DELIVERY_RUN]: (
    state: DeliveryRunsState,
    payload: {
      deliveryRunId: string;
      deliveryStage: DeliveryStage;
      deliveryTicketIdentifier: string;
      batchStart: Date;
    }) => {
    const deliveryRuns = state.deliveryRuns.map(run => {
      if (run.id === payload.deliveryRunId) {
        return {
          ...run,
          deliveryStage: payload.deliveryStage,
          deliveryStageId: payload.deliveryStage.id,
          deliveryTicketIdentifier: payload.deliveryTicketIdentifier,
          batchStart: payload.batchStart
        };
      }

      return run;
    });

    return {
      ...state,
      deliveryRuns,
    };
  },
};

export function deliveryRunsReducer(state: DeliveryRunsState = initialState, action: Action) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getDeliveryRun = (state: DeliveryRunsState) => state.deliveryRunData;
export const getDeliveryRunLogs = (state: DeliveryRunsState) => state.deliveryRunLogs;
export const getTicket = ({ticket}: DeliveryRunsState) => ticket;
