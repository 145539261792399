import { isFunction } from 'lodash-es';
import * as actions from './materials.action';
import { MaterialsState } from './';
import {
  MaterialCategory,
  Material,
  MaterialSupplier,
  MaterialProducer,
  RejectReason,
  InspectionStatus,
  MaterialDestination,
  ReducerMap,
  MaterialsComment,
  MaterialsDescription,
} from '../interfaces';
import { GetMaterialsRes } from '@app/core/http/interfaces';

const initialState: MaterialsState = {
  materials: null,
  destinations: null,
  categoriesList: null,
  categoriesDetails: null,
  suppliers: null,
  producers: null,
  loading: false,
  rejectReasons: null,
  inspectionStatuses: null,
  totalObjectsCount: null,
  materialsComments: null,
  materialsDescriptions: null,
  materialPackingMethods: [],
};

const reducerMap: ReducerMap<MaterialsState> = {
  [actions.GET_MATERIALS]: state => ({ ...state, loading: true }),
  [actions.GET_MATERIALS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_MATERIALS_SUCCESS]: (state, { materials, meta }: GetMaterialsRes['data']) => ({
    ...state,
    materials,
    totalObjectsCount: meta.count,
    loading: false,
  }),
  [actions.RESET_MATERIALS]: state => ({ ...state, materials: null, categoriesList: null }),
  [actions.GET_MATERIAL_CATEGORIES]: state => ({ ...state, loading: true }),
  [actions.GET_MATERIAL_CATEGORIES_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_MATERIAL_CATEGORIES_SUCCESS]: (state, categoriesList: MaterialCategory[]) => ({
    ...state,
    categoriesList,
    loading: false,
  }),
  [actions.GET_MATERIAL_CATEGORY]: state => ({ ...state, loading: true }),
  [actions.GET_MATERIAL_CATEGORY_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_MATERIAL_CATEGORY_SUCCESS]: (
    state,
    category: { [id: string]: MaterialCategory },
  ) => ({
    ...state,
    categoriesDetails: { ...state.categoriesDetails, ...category },
    loading: false,
  }),
  [actions.GET_SUPPLIERS]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIERS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_SUPPLIERS_SUCCESS]: (state, suppliers: MaterialSupplier[]) => ({
    ...state,
    suppliers,
    loading: false,
  }),
  [actions.GET_PRODUCERS]: state => ({ ...state, loading: true }),
  [actions.GET_PRODUCERS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_PRODUCERS_SUCCESS]: (state, producers: MaterialProducer[]) => ({
    ...state,
    producers,
    loading: false,
  }),
  [actions.GET_MATERIAL_DESTINATIONS]: state => ({ ...state, loading: true }),
  [actions.GET_MATERIAL_DESTINATIONS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_MATERIAL_DESTINATIONS_SUCCESS]: (state, destinations: MaterialDestination[]) => ({
    ...state,
    destinations,
    loaded: true,
    loading: false,
  }),
  [actions.GET_MATERIAL_DESTINATIONS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_INSPECTION_REJECT_REASONS]: state => ({ ...state, loading: true }),
  [actions.GET_INSPECTION_REJECT_REASONS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_INSPECTION_REJECT_REASONS_SUCCESS]: (state, rejectReasons: RejectReason[]) => ({
    ...state,
    loading: false,
    rejectReasons,
  }),
  [actions.GET_INSPECTION_REJECT_REASONS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_INSPECTION_STATUSES]: state => ({ ...state, loading: true }),
  [actions.GET_INSPECTION_STATUSES_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_INSPECTION_STATUSES_SUCCESS]: (state, inspectionStatuses: InspectionStatus[]) => ({
    ...state,
    loading: false,
    inspectionStatuses,
  }),
  [actions.GET_INSPECTION_STATUSES_FAIL]: state => ({ ...state, loading: false }),
  [actions.CREATE_MATERIAL]: state => ({ ...state, loading: true }),
  [actions.CREATE_MATERIAL_FAIL]: state => ({ ...state, loading: false }),
  [actions.CREATE_MATERIAL_SUCCESS]: (state, material: Material) => {
    const suppliers = [...state.suppliers];

    if (Array.isArray(material.suppliers) && material.suppliers.length > 0) {
      const isOnSupplierList = state.suppliers.find(supp => supp.id === material.suppliers[0].id);

      if (!isOnSupplierList) {
        suppliers.unshift(material.suppliers[0]);
      }
    }

    return {
    ...state,
      suppliers,
      materials: [material, ...state.materials],
      loading: false,
    };
  },
  [actions.UPDATE_MATERIAL]: state => ({ ...state, loading: true }),
  [actions.UPDATE_MATERIAL_FAIL]: state => ({ ...state, loading: false }),
  [actions.UPDATE_MATERIAL_SUCCESS]: (state, material: Material) => ({
    ...state,
    materials: state.materials?.map(stateMaterial =>
      stateMaterial.id === material.id ? material : stateMaterial,
    ),
    loading: false,
  }),
  [actions.GET_MATERIALS_COMMENTS]: state => ({ ...state, loading: true }),
  [actions.GET_MATERIALS_COMMENTS_SUCCESS]: (
    state,
    payload: { materialId: string; comments: MaterialsComment[] },
  ) => ({
    ...state,
    loading: false,
    materialsComments: {
      ...state.materialsComments,
      [payload.materialId]: payload.comments,
    },
  }),
  [actions.GET_MATERIALS_COMMENTS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_MATERIALS_DESCRIPTIONS]: state => ({ ...state, loading: true }),
  [actions.GET_MATERIALS_DESCRIPTIONS_SUCCESS]: (
    state,
    payload: { materialId: string; descriptions: MaterialsDescription[] },
  ) => ({
    ...state,
    loading: false,
    materialsDescriptions: {
      ...state.materialsDescriptions,
      [payload.materialId]: payload.descriptions,
    },
  }),
  [actions.GET_MATERIALS_DESCRIPTIONS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_MATERIAL_PACKING_METHODS]: state => ({ ...state, loading: true }),
  [actions.GET_MATERIAL_PACKING_METHODS_SUCCESS]: (state, payload: { methods: any }) => ({
    ...state,
    loading: false,
    materialPackingMethods: payload.methods,
  }),
  [actions.GET_MATERIAL_PACKING_METHODS_FAIL]: state => ({ ...state, loading: false }),
};

export function materialsReducer(state: MaterialsState = initialState, action: any) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
