import { Injectable } from '@angular/core'
import * as fromTasksActions from './tasks.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { TasksHttpService } from '../tasks-http.service'
import { catchError, map, mergeMap, of } from 'rxjs'

import { Task } from '@ppgt/web/tasks/domain'

@Injectable()
export class TasksEffects {
  constructor(private actions$: Actions, private tasksHttpService: TasksHttpService) {}

  getTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTasksActions.Types.GetTasks),
      mergeMap(() => this.tasksHttpService.getTasks()),
      map((tasks: Task[]) => fromTasksActions.getTasksSuccess({ tasks })),
      catchError((error) => of(fromTasksActions.getTasksFail(error)))
    )
  )

  updateTaskMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTasksActions.Types.UpdateTaskMaterials),
      map(({taskChildId, materials, subtaskId}) => fromTasksActions.updateTaskMaterialsSuccess({ taskChildId, materials, subtaskId })),
    )
  )

  getSubtasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTasksActions.Types.GetSubtasks),
      mergeMap((action: { parentId: string, childId: string}) =>  this.tasksHttpService.getSubtasks(action.parentId, action.childId)
        .pipe(
          map((tasks) =>  ({ tasks, childId: action.childId }) )
        )
      ),
      map(({tasks, childId}) => fromTasksActions.getSubtasksSuccess({ tasks, childId })),
      catchError((error) => of(fromTasksActions.getSubtasksFail(error)))
    )
  )

  getTasksImportSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTasksActions.Types.GetTasksImportSource),
      mergeMap(() => this.tasksHttpService.getTasksImportSource()),
      map((source: string | null) => fromTasksActions.getTasksImportSourceSuccess({ source })),
      catchError((error) => of(fromTasksActions.getTasksImportSourceFail(error)))
    )
  )

  resetTasksData$ = createEffect(() =>
    {
      console.log('1241212')
      return this.actions$.pipe(
      ofType(fromTasksActions.Types.ResetTasksData),
      mergeMap(() => this.tasksHttpService.resetTasksData()),
      map(() => fromTasksActions.resetTasksDataSuccess()),
      catchError((error) => of(fromTasksActions.resetTasksDataFail(error)))
    )}
  )
}
