import { Routes } from '@angular/router';

import { ShellComponent } from './shell/shell.component';

import {
  AuthenticationGuard,
  TranslationsGuard,
  SettingsGuard,
  UserDataGuard,
} from '@app/core';
import { EcologyDataGuard } from './guards';

export class Route {
  static withShell(routes: Routes): Routes {
    return [
      {
        path: '',
        component: ShellComponent,
        children: routes,
        canActivate: [
          TranslationsGuard,
          AuthenticationGuard,
          SettingsGuard,
          UserDataGuard,
          EcologyDataGuard,
        ],
        // Reuse ShellComponent instance when navigating between child views
        data: {
          reuse: true,
        },
      },
    ];
  }
}
