import * as Model from '@app/state/interfaces';
import { Supplier, SupplierLocation, SuppliersEmployee } from '@interfaces';
import { SortingOrder } from '@app/shared';
import { AddDeliveryMaterialForm } from '@app/shared/deliveries/add-delivery/add-delivery-material/add-delivery-material.component';
import {
  DeliveryRun,
  Equipment,
  Material,
  Subcontractor,
  SubcontractorsEmployee,
  SubcontractorMaterial,
  VehicleObj,
} from '@app/state/interfaces';
import { UserRoles, DeliveryType } from '@ppgt/web/shared/domain';

export enum QueryParams {
  Filter = 'filter',
  SingleValue = 'singleValue',
  PageSize = 'pageSize',
  Page = 'page',
  Order = 'order',
  LimitedDataConstructionSite = 'limitedDataConstructionSite',
  NoLimit = 'noLimit',
  DeliveriesTab = 'deliveriesTab',
}

export enum DeliveriesTab {
  Currently = 'currently',
  TodayOneTime = 'today-one-time',
  TodayLongTerm = 'today-long-term',
  ExpectedOneTime = 'expected-one-time',
  ExpectedLongTerm = 'expected-long-term',
  ForAcceptanceAndMissingData = 'for-acceptance-and-missing-data'
}

export type FilterCondition = 'eq' | 'gt' | 'gte' | 'lt' | 'lte' | 'ne' | 'like' | 'notLike' | 'iLike' | 'in' | 'or';

export interface Filter {
  type: QueryParams.Filter;
  filterField: string;
  condition: FilterCondition;
  value: string | number | boolean | Date;
}

export interface Sorting {
  type: QueryParams.Order;
  filterField: string;
  condition: SortingOrder;
}

export type QueryParamObject =
  | Filter
  | { type: QueryParams.SingleValue; key: string; value: string }
  | Sorting
  | { type: QueryParams.DeliveriesTab; value: DeliveriesTab }
  | { type: QueryParams.LimitedDataConstructionSite; value: boolean }
  | { type: QueryParams.Page; value: number }
  | { type: QueryParams.PageSize; value: number }
  | { type: QueryParams.NoLimit };

export interface ResponseMetadata {
  page: number;
  pageSize: string;
  count: number;
}

export interface Response<T> {
  data: T;
}

export interface ListResponse<T> {
  data: T;
  meta: ResponseMetadata;
}

// ------------------------------------------------------------------------------------
// USERS
// ------------------------------------------------------------------------------------

// POST /users/login - implemented

export interface TokenDecoded {
  id: string;
  typeRole: UserRoles;
  type: string;
  iat: number;
  exp: number;
  language?: string;
  email?: string;
}

export interface GetUsersRes {
  users: Model.User[];
  meta?: ResponseMetadata;
}

export type GetUserRes = Response<{ user: Model.User }>;

export interface UserLoginReq {
  email: string;
  password: string;
  remember?: boolean;
}

export type UserLoginRes = Response<{
  token: string;
  isPasswordActive: boolean;
  email: string;
}>;

// GET translations

export type TranslationsRes = Response<Model.Translations>;

// PUT /users/password - confirm account (token in Authorization header)

export interface ConfirmAccountReq {
  password: string;
  email: string;
  acceptRegulations: boolean;
}

export interface ConfirmAccountRes {
  sth: string;
}

export interface CreateUserReq {
  email: string;
  fullName: string;
  phone: string;
  allowSMSNotifications: boolean;
  roleId?: string;
  equipmentId?: string;
  active?: boolean;
  language?: string;
  avatar?: File;
}

export interface UpdateUserReq extends Partial<CreateUserReq> {
  id: string;
  theme?: string;
}

export interface NewPasswordReq {
  id: string;
  token: string;
  password: string;
  email: string;
}

// ------------------------------------------------------------------------------------
// OBJECTS
// ------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------
// BUILDINGS

// GET /buildings

export type GetBuildingsRes = Response<{ buildings: Model.Building[] }>;

// GET /buildings/:id

export type GetBuildingRes = Response<{ building: Model.Building }>;

// POST /buildings
// PUT /buildings/:id

export interface CreateBuildingReq {
  minFloor: number;
  maxFloor: number;
  shortName?: string;
  id?: string;
  status?: boolean;
}

export type CreateBuildingRes = Response<{ building: Model.Building }>;

// ------------------------------------------------------------------------------------
// DEPOTS

// GET /depots

export type GetDepotsRes = Response<{ depots: Model.Depot[] }>;

// GET /materials/categories/:id/depots

export type GetMaterialDepotsRes = Response<{ depots: Model.MaterialDepot[] }>;

// GET /depot/:id

export type GetDepotRes = Response<{ depot: Model.Depot }>;

// POST /depots
// PUT /depots/:id

export type CreateDepotsReq = UpdateDepotsReq;
export type CreateDepotsRes = Response<{ depot: Model.Depot }>;

export interface UpdateDepotsReq {
  number?: number;
  shortName: string;
  buildings: string[];
  length: number;
  places: string[];
  subcontractorId: string;
  width: number;
  depotTimes: { from: string; to: string }[];
  status: boolean;
  archived: boolean;
}

export type DeleteDepotRes = Response<{ depot: Model.Depot }>;

// ------------------------------------------------------------------------------------
// EQUIPMENT

// GET /equipment

export type GetEquipmentRes = Response<{ equipment: Model.Equipment }>;

// POST /equipment

export enum EquipmentTypes {
  Crane = 'crane',
  Excavator = 'excavator',
  MiniExcavator = 'miniExcavator',
  Forklift = 'forklift',
  MobileCrane = 'mobileCrane',
  Elevator = 'elevator',
  Other = 'other',
}

export enum MapObjectTypes {
  UnloadingPlace = 'unloadingPlace',
  Depot = 'depot',
  Building = 'building',
  Place = 'place',
  Gate = 'gate',
  Crane = 'crane',
  Elevator = 'elevator',
  Excavator = 'excavator',
  MobileCrane = 'mobileCrane',
  Forklift = 'forklift',
}

export interface CreateEquipmentReq {
  shortName?: string;
  brand?: string;
  identifier?: string;
  model?: string;
  number?: number;
  subcontractorId?: string;
  userId?: string;
  type: EquipmentTypes | string;
  buildings?: string[];
  floorFrom?: number;
  floorTo?: number;
  floor?: {
    from: number;
    to: number;
  };
  maxCapacity?: number;
  maxLiftingCapacity?: number;
  places?: string[];
  fork?: number;
  lift?: number;
  equipmentTimes?: { from: string; to: string; dayNumber?: number }[];
  weight?: number;
  diggingDepth?: number;
  width?: number;
  length?: number;
  status?: boolean;
  name?: string;
  visibilityOnSchedule?: boolean;
  active?: boolean;
  equipmentBrandId?: string | number;
  equipmentModelId?: string | number;
  archived?: boolean;
  widthEnter?: number;
  heightEnter?: number;
}

export type CreateEquipmentRes = Response<{ equipment: Model.Equipment }>;

export type DeleteEquipmentRes = Response<{ equipment: Model.Equipment }>;

// ------------------------------------------------------------------------------------
// GATES

// GET /gates

export type GetGatesRes = Response<{ gates: Model.Gate[] }>;

// GET /gates/:id

export type GetGateRes = Response<{ gate: Model.Gate }>;

// POST /gates
// PUT /gates/:id

export interface CreateGateReq {
  shortName: string;
  buildings: string[];
  userId: string;
  width: number;
  street: string;
  accessControl: string;
  enter: boolean;
  leave: boolean;
  gateTimes: { from: string; to: string }[];
  status: boolean;
  defaultGateIn: boolean;
  defaultGateOut: boolean;
}

export type CreateGateRes = Response<{ gate: Model.Gate }>;

export type DeleteGateRes = Response<{ gate: Model.Gate }>;

// ------------------------------------------------------------------------------------
// PLACES

// GET /places

export interface GetPlacesRes {
  data: {
    meta: ResponseMetadata;
    places: Model.UnloadingPlace[];
  };
}

// GET /places/:id

export type GetPlaceRes = Response<{ place: Model.UnloadingPlace }>;

// POST /places

export interface CreatePlaceReq {
  shortName?: string;
  buildings: string[];
  cranes: string[];
  depotId: string;
  destination: string;
  details: string;
  elevators: string[];
  excavator: boolean;
  forklift: boolean;
  miniExcavator: boolean;
  status: boolean;
  archived: boolean;
  hotspot: boolean;
  placeDeliveryTimes: { from: string; to: string }[];
  gates: { id: string; leave: boolean; enter: boolean }[];
  height?: number;
  width?: number;
  maxUnloadingTimeInWork?: number;
  maxUnloadingTimeNotWork?: number;
  vehicleSizes?: string[];
  mobileCrane?: string | boolean;
}

export type CreatePlaceRes = Response<{ place: Model.UnloadingPlace }>;
export type DeletePlaceRes = Response<{ place: Model.UnloadingPlace }>;

// ------------------------------------------------------------------------------------
// SETTINGS
// ------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------
// CONTRACTS

// GET /contracts
// GET /subcontractors/:id/contracts

export interface GetContractsRes {
  data: {
    meta: ResponseMetadata;
    contracts: Model.Contract[];
  };
}

export type GetContractRes = Response<{ contract: Model.Contract }>;

// POST /contracts

export interface CreateContractReq {
  startTime?: number;
  endTime?: number;
  longContract?: boolean;
  amountCargo?: number;
  unit?: string;
  limitVehicles?: number;
  subcontractorId?: string;
  materialCategoryId?: string;
  contractNumber?: string;
  sendSmsToSubRequestBeforeConfirmationDelivery?: number;
  deadlineRequestUpdateBeforeDeliverySent?: number;
  deadlineRequestUpdateBeforeDeliverySentWithPump?: number;
  avgArrivalTimePlantToSite?: number;
  deadlineTimesContract?: {
    dayNumber: number;
    deadlineDate: string;
    limitOnSpecificDay: number;
    dayName: string;
    isActive: boolean;
  }[];
  dischargeTimeRequirements?: number;
}

export interface UpdateContractReq {
  id: string;
  startTime?: number;
  endTime?: number;
  longContract?: boolean;
  amountCargo?: number;
  unit?: string;
  limitVehicles?: number;
  subcontractorId?: string;
  supplierId?: string;
  materialCategoryId?: string;
  contractNumber?: string;
  sendSmsToSubRequestBeforeConfirmationDelivery?: number;
  deadlineRequestUpdateBeforeDeliverySent?: number;
  deadlineRequestUpdateBeforeDeliverySentWithPump?: number;
  avgArrivalTimePlantToSite?: number;
  deadlineTimesContract?: {
    dayNumber: number;
    deadlineDate: string;
    limitOnSpecificDay: number;
    dayName: string;
    isActive: boolean;
  }[];
  dischargeTimeRequirements?: number;
}

export type CreateContractRes = Response<{ contract: Model.Contract }>;

// ------------------------------------------------------------------------------------
// SUBCONTRACTORS

// GET /subcontractors

export interface GetSubcontractorsRes {
  data: {
    meta: ResponseMetadata;
    subcontractors: Model.Subcontractor[];
  };
}

export interface GetSubcontractorVehiclesRes {
  meta: ResponseMetadata;
  vehicles: Model.VehicleObj[];
}

export interface ReportData {
  type: Model.ReportQueries['type'];
  reportType: Model.ReportQueries['reportType'];
  startDate?: Date | number;
  endDate?: Date | number;
  token?: string;
  page?: number;
  pageSize?: number;
  filter?: { property: string; text: string }[];
  noLimit?: boolean;
}

export interface Order {
  type: 'order';
  field: string;
  condition: 'acs' | 'desc' | 'ASC' | 'DESC';
}

export type NoLimit = 'noLimit';

// POST /subcontractors

export interface CreateSubcontractorBody {
  subcontractor: CreateSubcontractorReq;
  user: Model.User;
  employee: SubcontractorsEmployee;
}

export interface CreateSubcontractorReq {
  companyName: string;
  web: string;
  status: boolean;
  longDeliveries: boolean;
  shortDeliveries?: boolean;
}

export interface CreateSubcontractorRes {
  data: {
    subcontractor: Model.Subcontractor;
    user: Model.User;
  };
}

// PUT /subcontractors

export interface UpdateSubcontractorReq {
  subcontractor: Model.Subcontractor;
}

export interface UpdateSubcontractorPlacesReq {
  subcontractor: Model.Subcontractor;
}

export interface CreateSubcontractorPlacesReq {
  subcontractorId: string;
  placesToCreate: {
    address: string;
    distance: number;
  }[];
}

export interface DeleteSubcontractorPlacesReq {
  subcontractorId: string;
  placesToDelete: Model.SubcontractorLocation[];
}

// GET /subcontractors/:id

export type GetSubcontractorRes = Response<{ subcontractor: Model.Subcontractor }>;

// ------------------------------------------------------------------------------------
// SUPPLIER

// GET /suppliers

export interface GetAllSuppliersRes {
  data: {
    meta: ResponseMetadata;
    suppliers: Supplier[];
  };
}

export interface GetSupplierVehiclesRes {
  data: {
    meta: ResponseMetadata;
    vehicles: Model.VehicleObj[];
  };
}

export interface GetSuppliersClientsRes {
  meta: ResponseMetadata;
  subcontractors: Subcontractor[];
}

export interface GetSuppliersProductsRes {
  meta: ResponseMetadata;
  materials: Material[];
}

// POST /suppliers

export interface CreateSupplierReq {
  supplier: Supplier;
  supplierEmployee: SuppliersEmployee;
  location: SupplierLocation;
}

// PUT /suppliers

export interface UpdateSupplierReq {
  name: string;
  web: string;
  taxIdentifier: string;
  regon: string;
  status: boolean;
  streetAddress: string;
  postCodeAddress: string;
  materialCategoryId: string;
}

// GET /suppliers/:id

export interface GetSupplierRes {
  data: {
    supplier: Supplier;
  };
}

// ------------------------------------------------------------------------------------
// VEHICLES

// GET /vehicles
// GET /subcontractors/:id/vehicles

export type GetVehiclesRes = Response<{ vehicles: Model.Vehicle[] }>;

// POST /subcontractors/:id/vehicles

export interface CreateVehicleObject {
  vehicleId: string;
  materialCategoryId: string;
  longTime: boolean;
  cargoVolume: string;
  unit: string;
  inflammable: boolean;
  registrationNumber: string;
  vehicleIdentifier: string;
  supplierId?: string;
}

export interface CreateVehiclesReq {
  supplierId: string;
  vehicles: CreateVehicleObject[];
}

export interface CreateSupplierVehiclesReq {
  supplierId: string;
  vehicles: CreateVehicleObject[];
}

export type CreateVehiclesRes = Response<{ vehicle: Model.VehicleObj[] }>;

// ------------------------------------------------------------------------------------
// PLACES

// GET /subcontractors/:id/places

export type GetSubcontractorPlacesRes = Response<{ places: Model.SubcontractorLocation[] }>;

// ------------------------------------------------------------------------------------
// DELIVERIES
// ------------------------------------------------------------------------------------

// GET /deliveries

export type GetDeliveriesRes = Response<{ deliveries: Model.Delivery[]; meta: ResponseMetadata }>;

export interface GetDeliveryRunsResponse {
  deliveryRuns: DeliveryRun[];
  meta: ResponseMetadata;
  tempPubUrl: string;
}

// POST /deliveries

export interface DeliveryEmergencyCreation {
  typeDelivery: DeliveryType.Onetime;
  emergency: true;
  dateUnloading: number;
  unloadingTime: number;
  buildingId: string;
  subcontractorId: string;
  vehicleSizeId: string;
  destination: string;
  destinationEquipmentId: string;
  passengers?: 0;
  repeatedFromDeliveryId?: string;
}
export interface DeliveryOtherCreation {
  unloadingTime: number;
  buildingId: string;
  vehicleSizeId: string;
  crane: Equipment;
  craneId: string;
  elevatorId: string;
  elevatorFloor: number;
  excavator: boolean;
  miniExcavator: boolean;
  forklift: boolean;
  mobileCrane: boolean;
  mobileCraneId: string;
  depot: boolean;
  depotAmount: number;
  depotDays: number;
  combined: boolean;
  entry24: boolean;
  preferredPlace: string;
  cargos: Partial<AddDeliveryMaterialForm>[];
  dateUnloading: number;
  typeDelivery: DeliveryType.Onetime;
  status: 'UPDATE_REQUIRED';
  hotspot: boolean;
  excavatorId: string;
  miniExcavatorId: string;
  forkliftId: string;
  depotId: string;
  placeId: string;
  subcontractorId?: string;
  passengers?: 0;
  emergency?: false;
  repeatedFromDeliveryId?: string;
  floor?: number;
}

export interface CreateDeliveryReq {
  delivery: DeliveryEmergencyCreation | DeliveryOtherCreation;
  openPanel?: boolean;
  redirect?: boolean;
  files?: File[];
  duplicateAfter?: boolean;
}

// GET /deliveries/:id

export type GetDeliveryRes = Response<{ delivery: Model.Delivery }>;

// PUT /deliveries/:id
export interface IUpdateDelivery
  extends Partial<
    Pick<
      DeliveryOtherCreation,
      | 'dateUnloading'
      | 'unloadingTime'
      | 'placeId'
      | 'craneId'
      | 'excavatorId'
      | 'miniExcavatorId'
      | 'elevatorId'
      | 'elevatorFloor'
      | 'forkliftId'
      | 'depotId'
      | 'depotAmount'
      | 'depotDays'
    >
  > {
  id: string;
  status?: string;
  forAccept?: boolean;
}

export interface UpdateDeliveryReq {
  delivery: IUpdateDelivery;
  redirect?: boolean;
  redirectPath?: string;
  closePanel?: boolean;
  alertInfo?: string;
  reschedule?: boolean;
}

export type UpdateDeliveryRes = Response<{ delivery: Model.Delivery }>;

export interface CheckInDeliveryReq {
  deliveryId: string;
  identificator: string;
  newRegistrationNumber?: string;
  bufferPark?: boolean;
  redirectAfter?: boolean;
  deliveryNoteFilesConfig?: UpdateDeliveryFilesReq;
  deliveryVehicleFilesConfig?: UpdateDeliveryFilesReq;
}

export interface UpdateDeliveryFilesReq {
  deliveryId: string;
  files: File[];
  action?: string;
  type?: string;
  registrationNumber?: string;
  deliveryRunId?: string;
  redirectConfig?: {
    path: string[];
  };
}

export interface OpenDeliveryFileReq {
  deliveryId: string;
  fileId: string;
  openInNewTab: boolean;
}

export interface RemoveDeliveryFileReq {
  deliveryId: string;
  fileId: string;
}

export type CheckOutDeliveryReq = CheckInDeliveryReq;

// PATCH /deliveries/:id/reject

export type RejectDeliveryRes = Response<{ delivery: Model.Delivery }>;

// PATCH /deliveries/:id/cancel

export type CancelDeliveryRes = Response<{ delivery: Model.Delivery }>;

// POST /deliveries/check

export interface CheckDeliveryReq {
  identificator?: string;
  type?: 'checkIn' | 'checkOut' | 'bufferParkCheckIn';
  checkoutAfter?: boolean;
  deliveryId?: string;
}

export interface ManuallyUpdateDeliveryReq {
  id: string;
}

export interface CheckDeliveryRes {
  data: Model.CheckedDeliveries;
}

// POST /deliveries/propose

export interface ProposeDeliveryReq {
  subcontractorId: string;
  unloadingTime?: number;
  excavatorId?: string;
  forkliftId?: string;
  miniExcavatorId?: string;
  mobileCraneId?: string;
  equipmentId?: string;
  elevatorId?: string;
  elevatorFloor?: number;
  buildingId: string;
  craneId: string;
  depot: boolean;
  depotId: string;
  depotAmount: number;
  depotDays: number;
  preferredPlace: string;
  hotspot?: boolean;
  reschedule?: boolean;
  firstTerm: number;
  lastTerm: number;
}

export type ProposeDeliveryRes = Response<{ delivery: Model.Propose[] }>;

// POST /deliveries/template

export interface TemplateDeliveryReq {
  subcontractorId: string;
  materialId: string;
}

export interface GetManualModeDeliveriesReq {
  places: string[];
  queries: QueryParamObject[];
}

export type GetManualModeDeliveriesRes = Response<{ deliveries: Model.Delivery[] }>;

export interface SendManualModeDeliveriesReq {
  deliveries: string[];
  message: string;
}

export type SendManualModeDeliveriesRes = Response<{ manualDeliveries: boolean }>;

// GET /dashboard

export interface GetDashboardRes {
  data: Model.Dashboard;
}

// ------------------------------------------------------------------------------------
// MATERIALS

// GET /materials

export type GetMaterialsRes = Response<{ materials: Model.Material[]; meta: ResponseMetadata }>;

export type GetMaterialCategoriesRes = Response<{ categories: Model.MaterialCategory[] }>;

export type GetMaterialCategoryRes = Response<{ category: Model.MaterialCategory[] }>;

export type GetSuppliersRes = Response<{ suppliers: Model.MaterialSupplier[] }>;

export type GetProducersRes = Response<{ producers: Model.MaterialProducer[] }>;

export interface GetInspectionStatusesResponse {
  statuses: Model.InspectionStatus[];
  reasonRejectionDefault: {
    type: string;
    name: string;
  }[];
}

export interface UpdateDeliveryMaterialReq {
  deliveryId: string;
  deliveryMaterialId: string;
  data: {
    deliveredAmount?: number;
    quantityAtTicket?: number;
    tempAtTest?: number;
    tempAtTicket?: number;
    slumpAtTest?: number;
    slumpAtTicket?: number;
    flowAtTest?: number;
    flowAtTicket?: number;
    calculatedQuantity?: number;
  };
}

export interface SendOrdersReq {
  deliveryId: string;
  data: { orderType: 'WEEKLY' | 'DAILY' | 'STAGE'; stageId?: string; date?: string | number };
}

export interface CreateMaterialReq {
  name: string;
  category: { name: string; id: string };
  description: string;
  marIdentifier: string;
  producer: { name: string; id: string };
  suppliers: { name: string; id: string }[];
  maxAmount: number;
  unit: string;
  translations?: { [key: string]: string };
  durability: Model.MaterialDurability;
  subcontractors: SubcontractorMaterial[];
}

// ------------------------------------------------------------------------------------
// SETTINGS

// GET /settings

export type GetSettingsRes = Response<{ generalSettings: Model.Settings }>;

export interface GetItemsSuccessRes<T> {
  data: T[];
}

export interface UpsertItemSuccessRes<T> {
  data: T;
}

export interface GetGeneralSettingsRes {
  generalSettings: Model.GeneralSettings;
}

export interface GetDeliverySettingsRes {
  deliverySettings: Model.DeliverySettings;
}

export interface GetObjectSettingsRes {
  objectSettings: Model.ObjectSettings;
}

export interface GetSubcontractorSettingsRes {
  subcontractorSettings: Model.SubcontractorSettings;
}

export interface GetEcologySettingsRes {
  ecologySettings: Model.EcologySettings;
}

export interface GetProjectSettingsRes {
  projectSettings: Model.ProjectSettings;
}

// ------------------------------------------------------------------------------------
// CONSTRUCTION-SITE

// GET /construction-site

export type GetConstructionsRes = Response<{ constructionSites: Model.Construction[] }>;

// GET /construction-site/current

export type GetCurrentConstructionRes = Response<{ currentConstructionSite: Model.Construction }>;

// GET /construction-site/future

export type GetFutureConstructionRes = Response<{ futureConstructionSite: Model.Construction }>;

// POST /construction-site

export interface CreateConstructionReq {
  constructionSites: Model.Construction[];
}

// PUT /construction-site/:d

export interface UpdateConstructionReq {
  id: string;
  constructionSiteObjects: Model.ConstructionObject[];
  possibleUpdate?: boolean;
}

export type UpdateConstructionRes = Response<{ constructionSite: Model.Construction }>;

export interface UpdateConstructionPlanReq {
  id: string;
  formData: FormData;
}

export type UpdateConstructionPlanRes = Response<{ constructionSite: string }>;

export type UpdateConstructionPlanSuccessRes = UpdateConstructionPlanRes['data'];

// GET /construction-site?:id

export type GetConstructionRes = Response<{ constructionSite: Model.Construction }>;

// ------------------------------------------------------------------------------------
// REPORTS

// GET /reports

export type GetReportsRes = Response<{ reports: Model.Report[] }>;

// GET /report

export type GetReportRes = Response<{ report: Model.Report }>;

// POST /report
export interface SendReportReq {
  reportType: Model.ReportQueries['reportType'];
  page?: number;
  pageSize?: number;
  startDate: Date;
  endDate: Date;
  noLimit?: boolean;
  filter?: {
    field: Filter['filterField'];
    condition: Filter['condition'];
    value: Filter['value'];
  }[];
}

export interface GetEmployeeRelatedData {
  assignedLocation: SupplierLocation;
  availableVehicles: VehicleObj[];
  availableDrivers: SuppliersEmployee[];
}

export interface UpdateAvatarFile {
  file: File;
  userId: string
}
