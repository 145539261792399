import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { config } from '@app/app.config';
import { CookieStorageService } from '@ppgt/web/shared/core';

import { UsersSignOut, AppState } from '@app/state';

const AUTH_ERROR_NAMES = [
  'MISSING_AUTHORIZATION_HEADER',
  'NO_JWT_SECRET_ENV_VARIABLE',
  'SESSION_EXPIRED',
  'MISSING_TOKEN_TYPE',
  'INVALID_TOKEN_TYPE',
  'JsonWebTokenError',
  'Authorization Error',
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public cookieStorage: CookieStorageService, private store: Store<AppState>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentURL = location.href;
    const url = new URL(currentURL);
    const queryParams = url.searchParams;

    const token = String(
      this.cookieStorage.get(config.storageTokenKey) || request.headers.get('Authorization') || queryParams.get('token')
    );

    const authReq = request.clone({
      headers: request.headers.set('Authorization', token),
      withCredentials: true,
    });

    return next.handle(authReq).pipe(
      tap(
        (event: HttpEvent<unknown>) => {},
        (err: unknown) => {
          if (
            err instanceof HttpErrorResponse &&
            [401, 403, 500].includes(err.status) &&
            AUTH_ERROR_NAMES.includes(err.error.name)
          ) {
            this.store.dispatch(new UsersSignOut());
          }
        }
      )
    );
  }
}
