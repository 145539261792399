import { Action } from '@ngrx/store';
import { Role } from '@ppgt/web/shared/domain';

export const GET_ROLES = '[ROLES] GET_ROLES';
export const GET_ROLES_FAIL = '[ROLES] GET_ROLES_FAIL';
export const GET_ROLES_SUCCESS = '[ROLES] GET_ROLES_SUCCESS';

export const CREATE_ROLE = '[ROLES] CREATE_ROLE';
export const CREATE_ROLE_FAIL = '[ROLES] CREATE_ROLE_FAIL';
export const CREATE_ROLE_SUCCESS = '[ROLES] CREATE_ROLE_SUCCESS';

export const UPDATE_ROLE = '[ROLES] UPDATE_ROLE';
export const UPDATE_ROLE_FAIL = '[ROLES] UPDATE_ROLE_FAIL';
export const UPDATE_ROLE_SUCCESS = '[ROLES] UPDATE_ROLE_SUCCESS';

export class GetRoles implements Action {
  readonly type = GET_ROLES;
}
export class GetRolesFail implements Action {
  readonly type = GET_ROLES_FAIL;

  constructor(public payload: { error: any }) {}
}

export class GetRolesSuccess implements Action {
  readonly type = GET_ROLES_SUCCESS;

  constructor(public payload: {fetchedRoles: Role[]}) {}
}

export class CreateRole implements Action {
  readonly type = CREATE_ROLE;

  constructor(public payload: { roleToCreate: Role }) {}
}

export class CreateRoleSuccess implements Action {
  readonly type = CREATE_ROLE_SUCCESS;

  constructor(public payload: { createdRole: Role }) {}
}

export class CreateRoleFail implements Action {
  readonly type = CREATE_ROLE_FAIL;

  constructor(public payload: { error: any }) {}
}

export class UpdateRole implements Action {
  readonly type = UPDATE_ROLE;

  constructor(public payload: { roleToUpdate: Role }) {}
}

export class UpdateRoleSuccess implements Action {
  readonly type = UPDATE_ROLE_SUCCESS;

  constructor(public payload: { updatedRole: Role }) {}
}

export class UpdateRoleFail implements Action {
  readonly type = UPDATE_ROLE_FAIL;

  constructor(public payload: { error: any }) {}
}


export type RolesAction =
  | GetRoles
  | GetRolesFail
  | GetRolesSuccess
  | CreateRole
  | CreateRoleFail
  | CreateRoleSuccess
  | UpdateRole
  | UpdateRoleFail
  | UpdateRoleSuccess;
