import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  Material,
  Vehicle,
  MaterialCategory,
  MaterialCategoryTags,
  DeliveryMaterial,
  VehicleObj,
  Translation,
  Unit,
} from '@app/state/interfaces';
import { get } from 'lodash-es';
import { Permission, PermissionsSection } from '@interfaces';
import { SettingsService } from '@app/core/settings.service';
import { AppState, getAllUnits, GetUnits } from '@app/state';
import { Role } from '@ppgt/web/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class TranslateItemsService {
  private currentLang: string;
  private materialUnits: Unit[] = [];

  constructor(
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private store: Store<AppState>,
  ) {}

  public loadCurrentLang(): void {
    this.currentLang = this.translateService.currentLang;
  }

  public getUnits(): void {
    this.store.dispatch(new GetUnits());
    this.store.select(getAllUnits).pipe(
      filter(Boolean),
      filter(data => Array.isArray(data)),
      take(1)
    ).subscribe((units) => {
      this.materialUnits = units;
    });
  }

  public getTranslationFromObject(
    object: {
      translation?: Translation;
      name?: string;
    } | Unit,
    defaultKey?: string,
    specificLang?: string,
  ): string {
    if (!object) {
      return '';
    }

    return get(object, ['translation', 'data', specificLang || this.currentLang], object[defaultKey] || object.name);
  }

  public mapMaterial(material: Material): Material {
    if (!material) {
      return;
    }

    const translatedName = this.getTranslationFromObject(material);
    const producerName: string = get(material, 'producer.name', null);
    const label = producerName ? `${translatedName} - ${producerName}` : translatedName;
    return { ...material, translatedName, label };
  }

  public mapMaterials(materials: Material[]): Material[] {
    return (materials || []).map(material => this.mapMaterial(material));
  }

  public mapMaterialCategory(category: MaterialCategory): MaterialCategory {
    if (!category) {
      return;
    }

    const translatedName = this.getTranslationFromObject(category);
    const label = category.pbc ? `${translatedName} (${category.pbc})` : translatedName;
    const tagsList = this.getTags(category.tags);
    const materials = this.mapMaterials(category.materials);
    return { ...category, translatedName, label, tagsList, materials };
  }

  public mapMaterialCategories(categories: MaterialCategory[]): MaterialCategory[] {
    return (categories || [])
      .map(category => this.mapMaterialCategory(category))
      .sort((catA, catB) => catA.translatedName.localeCompare(catB.translatedName));
  }

  public mapDeliveryMaterial(deliveryMaterial: DeliveryMaterial): DeliveryMaterial {
    if (!deliveryMaterial) {
      return;
    }

    return {
      ...deliveryMaterial,
      material: this.mapMaterial(deliveryMaterial.material),
      materialCategory: this.mapMaterialCategory(deliveryMaterial.materialCategory),
    };
  }

  mapVehicles(vehiclesObj: VehicleObj[]): VehicleObj[] {
    return vehiclesObj
      .map(vehicleObj => {
        const translatedName = this.vehicle(vehicleObj.vehicle).translation;
        return { ...vehicleObj, translatedName };
      })
      .sort((veh1, veh2) => veh1.translatedName.localeCompare(veh2.translatedName));
  }

  public vehicle(vehicle: Vehicle): Vehicle {
    if (!vehicle) {
      return null;
    }

    if (vehicle && vehicle.translation) {
      vehicle.translation = this.translateService.instant(vehicle.translation);
      return vehicle;
    }

    vehicle.translation = this.vehicleType(vehicle.generalType);
    return vehicle;
  }

  public unit(unitName: string): string {
    if (!unitName) {
      return '';
    }

    const foundUnit = this.materialUnits.find(unit => {
      const inTranslationObject = Object
        .values((unit.translation as Translation).data)
        .includes(unitName);

      const directlyTranslated = unit.translatedName === unitName;

      return !!(directlyTranslated || inTranslationObject);
    });

    if (foundUnit) {
      return this.getTranslationFromObject(foundUnit);
    }

    return unitName;
  }

  public vehicleType(generalType: string) {
    return generalType && typeof generalType === 'string'
      ? this.translateService.instant(
        `subcontractors_page.vehicle.${this.replacer(generalType)}.type_l`,
      )
      : '';
  }

  public getTags(tags: MaterialCategoryTags): string {
    return get(tags, ['data', this.currentLang], '');
  }

  public translateRoles(roles: Role[]): Role[] {
    return roles.map(role => this.translateRole(role));
  }

  public translateRole(role: Role): Role {
    const translatedName = get(role, ['translation', 'data', this.currentLang], role.name);

    return { ...role, translatedName };
  }

  public translateSections(sections: PermissionsSection[]): PermissionsSection[] {
    return sections.map(section => this.translateSection(section));
  }

  /**
   * Anna Wałkowska Comment in JIRA from 30.01.2020:
   * W całej aplikacji mamy pierwotną nazwę w domyślnym języku projektu, czy to polski czy angielski nvm,
   * pierwsze treści w pierwszym języku nazywam pierwotną treścią.
   * Tutaj nie mamy jeszcze pierwotnej treści bo trzeba przynajmniej jeden język (na DEV to polski) ustawić,
   * żeby była treść, którą można tłumaczyć na inne języki.
   * Chodzi mi o to, że tak jak w całej aplikacji nie ma ustawionego tłumaczenia
   * na inny język to pojawia się polski odpowiednik, tak i tutaj jak mam coś przetłumaczyć
   * to muszę wiedzieć co, czyli ma mi się wyświetlić polski,
   * na którym sobie będę pisać i tłumaczyć na wybrany wyżej język.
   * Jeśli problematyczne jest żeby się to wyświetlało podczas tłumaczenia w polu tekstowym,
   * które będę nadpisywać, to wyświetlmy to poniżej nazwy_systemowej,
   * a jeśli tego jeszcze nie ma, czyli nawet ten język domyślny interfejsu jest pusty to trzeba dać "Brak opisu".
   * A ja na wersji polskiej będę mogła ustawić wszystkie opisy
   * na podstawie read only nazwy_systemowej widocznej pod polem.
   *
   * @important It relates to 'translateSection' method and 'translatePermission' method.
   */
  public translateSection(section: PermissionsSection): PermissionsSection {
    const nameTranslations = get(section, ['nameTranslation', 'data'], null);
    const descriptionTranslations = get(section, ['descriptionTranslation', 'data'], null);
    const translatedName = this.getNotEmptyTranslation(nameTranslations);
    const translatedDescription = this.getNotEmptyTranslation(descriptionTranslations);
    const permissions = this.translatePermissions(section.permissions);
    return { ...section, permissions, translatedName, translatedDescription };
  }

  public getNotEmptyTranslation(translationsObj: any, langKey?: string): string {
    const currLangKey = langKey ? langKey : this.translateService.currentLang;
    const instanceLangKey = this.settingsService.getInstanceLanguageKey();
    let translation = this.translateService.instant('general.missing_description');

    if (translationsObj) {
      // We're not interested in empty strings!
      if (translationsObj[instanceLangKey]) {
        translation = translationsObj[instanceLangKey];
      }
      // We're not interested in empty strings!
      if (translationsObj[currLangKey]) {
        translation = translationsObj[currLangKey];
      }
    }
    return translation;
  }

  public translatePermissions(permissions: Permission[]): Permission[] {
    return permissions.map(permission => this.translatePermission(permission));
  }

  public translatePermission(permission: Permission): Permission {
    const permissionTranslations = get(permission, ['translation', 'data'], null);
    const translatedName = this.getNotEmptyTranslation(permissionTranslations);

    return {
      ...permission,
      translatedName,
    };
  }

  private replacer(text: string) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '_')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
}
