import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'unloadingHours' })
export class UnloadingHoursPipe implements PipeTransform {
  transform(unloadingDate: Date, unloadingTime: number): string {
    const startTime = moment(unloadingDate).format('HH:mm');
    const endTime = moment(unloadingDate).add(unloadingTime, 'm').format('HH:mm');
    return `${startTime} - ${endTime}`;
  }
}
