import { ActionReducer } from '@ngrx/store';

import * as effects from './suppliers.effect';
import * as reducers from './suppliers.reducer';

import {
  LocationType, Material,
  Subcontractor,
  VehicleObj,
} from '@app/state/interfaces';
import {
  Supplier,
  SuppliersEmployee,
  SuppliersEmployeeRole,
  SupplierLocation,
  WorkingDriver,
  NoDriverAppResponse,
} from '@interfaces';

export const suppliersEffects: any[] = [effects.SuppliersEffects];

export interface SuppliersState {
  suppliers?: Supplier[];
  selectedSupplier?: Supplier;
  supplierEmployees: SuppliersEmployee[];
  supplierEmployeesRoles: SuppliersEmployeeRole[];
  totalObjectsCount: number;
  suppliersLocations: SupplierLocation[];
  locationTypes: LocationType[];
  vehiclesCount: { value: number };
  supplierVehicles?: VehicleObj[];
  runAvailableDrivers: WorkingDriver[] | NoDriverAppResponse;
  suppliersClients: Subcontractor[];
  clientsCount: number;
  suppliersMaterials: Material[];
  materialsCount: number;
  loading: boolean;
  error: any;
}

export const suppliersReducer: ActionReducer<SuppliersState> =
  reducers.suppliersReducer;

export * from './suppliers.action';
export * from './suppliers.effect';
export * from './suppliers.reducer';
export * from './suppliers.selector';
