import { isFunction } from 'lodash-es';

import * as actions from './construction-plan.action';
import { ConstructionsState } from './';
import { Construction } from '../interfaces';
import { UpdateConstructionPlanSuccessRes } from '@app/core/http/interfaces';

const initialState: ConstructionsState = {
  constructionSites: [],
  construction: null,
  currentConstruction: null,
  futureConstruction: null,
  currentConstructionLoaded: false,
  futureConstructionLoaded: false,
  loaded: false,
  loading: false,
};

const reducerMap = {
  [actions.GET_CONSTRUCTIONS]: (state: ConstructionsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_CONSTRUCTIONS_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_CONSTRUCTIONS_SUCCESS]: (
    state: ConstructionsState,
    constructionSites: Construction[]
  ) => ({
    ...state,
    constructionSites,
    loaded: true,
    loading: false,
  }),
  [actions.CREATE_CONSTRUCTION]: (state: ConstructionsState) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_CONSTRUCTION_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_CONSTRUCTION_SUCCESS]: (
    state: ConstructionsState,
    construction: any
  ) => ({
    ...state,
    ...construction,
    loading: false,
  }),
  [actions.UPDATE_CONSTRUCTION]: (state: ConstructionsState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_CONSTRUCTION_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_CONSTRUCTION_SUCCESS]: (
    state: ConstructionsState,
    data: any
  ) => ({
    ...state,
    futureConstruction: data.constructionSite,
    loading: false,
  }),
  [actions.UPDATE_CONSTRUCTION_PLAN]: (state: ConstructionsState) => ({
    ...state,
    futureConstruction: {
      ...state.futureConstruction,
      backgroundUrl: null,
    },
    loading: true,
  }),
  [actions.UPDATE_CONSTRUCTION_PLAN_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_CONSTRUCTION_PLAN_SUCCESS]: (
    state: ConstructionsState,
    { constructionSite: backgroundUrl }: UpdateConstructionPlanSuccessRes
  ) => ({
    ...state,
    loading: false,
    futureConstruction: { ...state.futureConstruction, backgroundUrl },
  }),
  [actions.DELETE_CONSTRUCTION_PLAN]: (state: ConstructionsState) => ({
    ...state,
    loading: true,
  }),
  [actions.DELETE_CONSTRUCTION_PLAN_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.DELETE_CONSTRUCTION_PLAN_SUCCESS]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
    futureConstruction: {
      ...state.futureConstruction,
      backgroundUrl: null,
    },
  }),
  [actions.GET_CURRENT_CONSTRUCTION]: (state: ConstructionsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_CURRENT_CONSTRUCTION_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_CURRENT_CONSTRUCTION_SUCCESS]: (
    state: ConstructionsState,
    data: any
  ) => ({
    ...state,
    currentConstruction: data,
    currentConstructionLoaded: true,
    loading: false,
  }),
  [actions.GET_FUTURE_CONSTRUCTION]: (state: ConstructionsState) => ({
    ...state,
    futureConstruction: null,
    loading: true,
  }),
  [actions.GET_FUTURE_CONSTRUCTION_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_FUTURE_CONSTRUCTION_SUCCESS]: (
    state: ConstructionsState,
    data: any
  ) => ({
    ...state,
    futureConstruction: data,
    futureConstructionLoaded: true,
    loading: false,
  }),
  [actions.GET_CONSTRUCTION]: (state: ConstructionsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_CONSTRUCTION_FAIL]: (state: ConstructionsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_CONSTRUCTION_SUCCESS]: (
    state: ConstructionsState,
    construction: any
  ) => ({
    ...state,
    construction,
    loading: false,
    loaded: true,
  }),
};

export function constructionReducer(
  state: ConstructionsState = initialState,
  action: any
) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getConstructions = (state: ConstructionsState) =>
  state.constructionSites;
export const getConstruction = (state: ConstructionsState) =>
  state.construction;
export const getCurrentConstruction = (state: ConstructionsState) =>
  state.currentConstruction;
export const getFutureConstruction = (state: ConstructionsState) =>
  state.futureConstruction;
export const getConstructionLoaded = (state: ConstructionsState) =>
  state.loaded;
export const getCurrentConstructionLoaded = (state: ConstructionsState) =>
  state.currentConstructionLoaded;
export const getFutureConstructionLoaded = (state: ConstructionsState) =>
  state.futureConstructionLoaded;
