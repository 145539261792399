import { ActionReducer } from '@ngrx/store';

import * as effects from './construction-plan.effect';
import * as reducers from './construction-plan.reducer';
import { Construction } from '../interfaces';

export const constructionEffects: any[] = [effects.ConstructionEffects];

export interface ConstructionsState {
  constructionSites?: Construction[];
  construction?: Construction;
  currentConstruction?: Construction;
  futureConstruction?: Construction;
  loaded: boolean;
  currentConstructionLoaded?: boolean;
  futureConstructionLoaded?: boolean;
  loading: boolean;
}

export const constructionReducer: ActionReducer<ConstructionsState> =
  reducers.constructionReducer;

export * from './construction-plan.action';
export * from './construction-plan.effect';
export * from './construction-plan.reducer';
export * from './construction-plan.selector';
