import { ActionReducer } from '@ngrx/store';

import * as effects from './equipment.effect';
import * as reducers from './equipment.reducer';

import {
  Equipment,
  EquipmentBrand,
  EquipmentModel,
  EquipmentType,
  UnloadingUtility,
  EquipmentDeliveriesDetails,
} from '@app/state/interfaces';

export const equipmentEffects: any[] = [effects.EquipmentEffects];

export interface EquipmentListState {
  equipments: Equipment[];
  selectedEquipment: Equipment;
  equipmentTypes: EquipmentType[];
  unloadingUtilities: UnloadingUtility[];
  equipmentDeliveriesDetails: EquipmentDeliveriesDetails;
  brands: EquipmentBrand[];
  models: EquipmentModel[];
  totalObjectsCount: number;
  loaded: boolean;
  loading: boolean;
}

export const equipmentReducer: ActionReducer<EquipmentListState> =
  reducers.equipmentReducer;

export * from './equipment.action';
export * from './equipment.effect';
export * from './equipment.reducer';
export * from './equipment.selector';
