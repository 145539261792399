import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './construction-crews.action';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class ConstructionCrewsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.ConstructionCrewsAction>,
    private alertService: AlertService
  ) {}

  getConstructionCrews$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_CONSTRUCTION_CREWS),
      map((action: actions.GetConstructionCrews) => action.payload),
      switchMap(({ subcontractorId }) =>
        this.http.getConstructionCrews(subcontractorId).pipe(
          map((res) => new actions.GetConstructionCrewsSuccess(res)),
          catchError((error) => [new actions.GetConstructionCrewsFail()])
        )
      )
    )
  );

  getConstructionCrew$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_CONSTRUCTION_CREW),
      map((action: actions.GetConstructionCrew) => action.payload),
      switchMap(({ subcontractorId, crewId }) =>
        this.http.getConstructionCrew(subcontractorId, crewId).pipe(
          map((res) => new actions.GetConstructionCrewSuccess(res)),
          catchError((error) => [new actions.GetConstructionCrewFail()])
        )
      )
    )
  );

  createConstructionCrew$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_CONSTRUCTION_CREW),
      map((action: actions.CreateConstructionCrew) => action.payload),
      switchMap(({ subcontractorId, data }) =>
        this.http.createConstructionCrew(subcontractorId, data).pipe(
          map((newCrew) => {
            this.alertService.showInfo('alert.crew_added_l');
            return new actions.CreateConstructionCrewSuccess(newCrew);
          }),
          catchError(() => [new actions.CreateConstructionCrewFail()])
        )
      )
    )
  );

  updateConstructionCrew$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_CONSTRUCTION_CREW),
      map((action: actions.UpdateConstructionCrew) => action.payload),
      switchMap(({ subcontractorId, crewId, data }) =>
        this.http.updateConstructionCrew(subcontractorId, crewId, data).pipe(
          map((updatedCrew) => {
            this.alertService.showInfo('alert.updated_l');
            return new actions.UpdateConstructionCrewSuccess(updatedCrew);
          }),
          catchError(() => [new actions.CreateConstructionCrewFail()])
        )
      )
    )
  );
}
