import { Action } from '@ngrx/store';

import {
  CreateEquipmentReq,
  QueryParamObject,
} from '@app/core/http/interfaces';
import {
  Equipment,
  EquipmentBrand,
  EquipmentModel,
  EquipmentTimes,
  EquipmentType,
  UnloadingUtility,
  EquipmentDeliveriesDetails,
} from '@app/state/interfaces';

// load equipment
export const GET_EQUIPMENT = 'GET_EQUIPMENT';
export const GET_EQUIPMENT_FAIL = 'GET_EQUIPMENT_FAIL';
export const GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS';

export class GetEquipment implements Action {
  readonly type = GET_EQUIPMENT;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetEquipmentFail implements Action {
  readonly type = GET_EQUIPMENT_FAIL;
}

export class GetEquipmentSuccess implements Action {
  readonly type = GET_EQUIPMENT_SUCCESS;

  constructor(public payload: Equipment[]) {}
}

// create equipment
export const CREATE_EQUIPMENT = 'CREATE_EQUIPMENT';
export const CREATE_EQUIPMENT_FAIL = 'CREATE_EQUIPMENT_FAIL';
export const CREATE_EQUIPMENT_SUCCESS = 'CREATE_EQUIPMENT_SUCCESS';

export class CreateEquipment implements Action {
  readonly type = CREATE_EQUIPMENT;

  constructor(public payload: CreateEquipmentReq) {}
}

export class CreateEquipmentFail implements Action {
  readonly type = CREATE_EQUIPMENT_FAIL;
}

export class CreateEquipmentSuccess implements Action {
  readonly type = CREATE_EQUIPMENT_SUCCESS;

  constructor(public payload: Equipment) {}
}

// load selected equipment
export const GET_SELECTED_EQUIPMENT = 'GET_SELECTED_EQUIPMENT';
export const GET_SELECTED_EQUIPMENT_FAIL = 'GET_SELECTED_EQUIPMENT_FAIL';
export const GET_SELECTED_EQUIPMENT_SUCCESS = 'GET_SELECTED_EQUIPMENT_SUCCESS';

export class GetSelectedEquipment implements Action {
  readonly type = GET_SELECTED_EQUIPMENT;

  constructor(public payload: { id: string }) {}
}

export class GetSelectedEquipmentFail implements Action {
  readonly type = GET_SELECTED_EQUIPMENT_FAIL;
}

export class GetSelectedEquipmentSuccess implements Action {
  readonly type = GET_SELECTED_EQUIPMENT_SUCCESS;

  constructor(public payload: Equipment) {}
}

// update selected equipment
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const UPDATE_EQUIPMENT_FAIL = 'UPDATE_EQUIPMENT_FAIL';
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS';

export class UpdateEquipment implements Action {
  readonly type = UPDATE_EQUIPMENT;

  constructor(
    public payload: {
      id: string;
      data: CreateEquipmentReq;
      redirectAfter?: boolean;
    }
  ) {}
}

export class UpdateEquipmentFail implements Action {
  readonly type = UPDATE_EQUIPMENT_FAIL;
}

export class UpdateEquipmentSuccess implements Action {
  readonly type = UPDATE_EQUIPMENT_SUCCESS;

  constructor(public payload: Equipment) {}
}

// remove selected equipment
export const REMOVE_EQUIPMENT = 'REMOVE_EQUIPMENT';
export const REMOVE_EQUIPMENT_FAIL = 'REMOVE_EQUIPMENT_FAIL';
export const REMOVE_EQUIPMENT_SUCCESS = 'REMOVE_EQUIPMENT_SUCCESS';

export class RemoveEquipment implements Action {
  readonly type = REMOVE_EQUIPMENT;

  constructor(
    public payload: {
      id: string;
      redirectAfter?: boolean;
    }
  ) {}
}

export class RemoveEquipmentFail implements Action {
  readonly type = REMOVE_EQUIPMENT_FAIL;
}

export class RemoveEquipmentSuccess implements Action {
  readonly type = REMOVE_EQUIPMENT_SUCCESS;

  constructor(public payload: any) {}
}

export const CREATE_EQUIPMENT_TIME = 'CREATE_EQUIPMENT_TIME';
export const CREATE_EQUIPMENT_TIME_SUCCESS = 'CREATE_EQUIPMENT_TIME_SUCCESS';
export const CREATE_EQUIPMENT_TIME_FAIL = 'CREATE_EQUIPMENT_TIME_FAIL';

export class CreateEquipmentTime implements Action {
  readonly type = CREATE_EQUIPMENT_TIME;

  constructor(public payload: { equipmentId: string; times: Partial<EquipmentTimes[]> }) {}
}

export class CreateEquipmentTimeSuccess implements Action {
  readonly type = CREATE_EQUIPMENT_TIME_SUCCESS;

  constructor(public payload: EquipmentTimes) {}
}

export class CreateEquipmentTimeFail implements Action {
  readonly type = CREATE_EQUIPMENT_TIME_FAIL;
}

export const UPDATE_EQUIPMENT_TIME = 'UPDATE_EQUIPMENT_TIME';
export const UPDATE_EQUIPMENT_TIME_SUCCESS = 'UPDATE_EQUIPMENT_TIME_SUCCESS';
export const UPDATE_EQUIPMENT_TIME_FAIL = 'UPDATE_EQUIPMENT_TIME_FAIL';

export class UpdateEquipmentTime implements Action {
  readonly type = UPDATE_EQUIPMENT_TIME;

  constructor(public payload: { equipmentId: string; time: Partial<EquipmentTimes> }) {}
}

export class UpdateEquipmentTimeSuccess implements Action {
  readonly type = UPDATE_EQUIPMENT_TIME_SUCCESS;

  constructor(public payload: EquipmentTimes) {}
}

export class UpdateEquipmentTimeFail implements Action {
  readonly type = UPDATE_EQUIPMENT_TIME_FAIL;
}

export const DELETE_EQUIPMENT_TIME = 'DELETE_EQUIPMENT_TIME';
export const DELETE_EQUIPMENT_TIME_SUCCESS = 'DELETE_EQUIPMENT_TIME_SUCCESS';
export const DELETE_EQUIPMENT_TIME_FAIL = 'DELETE_EQUIPMENT_TIME_FAIL';

export class DeleteEquipmentTime implements Action {
  readonly type = DELETE_EQUIPMENT_TIME;

  constructor(public payload: { equipmentId: string; id: string }) {}
}

export class DeleteEquipmentTimeSuccess implements Action {
  readonly type = DELETE_EQUIPMENT_TIME_SUCCESS;

  constructor(public payload: EquipmentTimes) {}
}

export class DeleteEquipmentTimeFail implements Action {
  readonly type = DELETE_EQUIPMENT_TIME_FAIL;
}

export const GET_EQUIPMENT_TYPES = 'GET_EQUIPMENT_TYPES';
export const GET_EQUIPMENT_TYPES_SUCCESS = 'GET_EQUIPMENT_TYPES_SUCCESS';
export const GET_EQUIPMENT_TYPES_FAIL = 'GET_EQUIPMENT_TYPES_FAIL';

export class GetEquipmentTypes implements Action {
  readonly type = GET_EQUIPMENT_TYPES;
}

export class GetEquipmentTypesSuccess implements Action {
  readonly type = GET_EQUIPMENT_TYPES_SUCCESS;

  constructor(public payload: { types: EquipmentType[] }) {}
}

export class GetEquipmentTypesFail implements Action {
  readonly type = GET_EQUIPMENT_TYPES_FAIL;
}

export const GET_UNLOADING_UTILITIES = 'GET_UNLOADING_UTILITIES';
export const GET_UNLOADING_UTILITIES_SUCCESS = 'GET_UNLOADING_UTILITIES_SUCCESS';
export const GET_UNLOADING_UTILITIES_FAIL = 'GET_UNLOADING_UTILITIES_FAIL';

export class GetUnloadingUtilities implements Action {
  readonly type = GET_UNLOADING_UTILITIES;
}

export class GetUnloadingUtilitiesSuccess implements Action {
  readonly type = GET_UNLOADING_UTILITIES_SUCCESS;

  constructor(public payload: { utilities: UnloadingUtility[] }) {}
}

export class GetUnloadingUtilitiesFail implements Action {
  readonly type = GET_UNLOADING_UTILITIES_FAIL;
}

export const GET_EQUIPMENT_BRANDS = 'GET_EQUIPMENT_BRANDS';
export const GET_EQUIPMENT_BRANDS_SUCCESS = 'GET_EQUIPMENT_BRANDS_SUCCESS';
export const GET_EQUIPMENT_BRANDS_FAIL = 'GET_EQUIPMENT_BRANDS_FAIL';

export class GetEquipmentBrands {
  readonly type = GET_EQUIPMENT_BRANDS;
}

export class GetEquipmentBrandsSuccess implements Action {
  readonly type = GET_EQUIPMENT_BRANDS_SUCCESS;

  constructor(public payload: { brands: EquipmentBrand[] }) {}
}

export class GetEquipmentBrandsFail implements Action {
  readonly type = GET_EQUIPMENT_BRANDS_FAIL;
}


export const GET_EQUIPMENT_MODELS = 'GET_EQUIPMENT_MODELS';
export const GET_EQUIPMENT_MODELS_SUCCESS = 'GET_EQUIPMENT_MODELS_SUCCESS';
export const GET_EQUIPMENT_MODELS_FAIL = 'GET_EQUIPMENT_MODELS_FAIL';

export class GetEquipmentModels {
  readonly type = GET_EQUIPMENT_MODELS;
}

export class GetEquipmentModelsSuccess implements Action {
  readonly type = GET_EQUIPMENT_MODELS_SUCCESS;

  constructor(public payload: { models: EquipmentModel[] }) {}
}

export class GetEquipmentModelsFail implements Action {
  readonly type = GET_EQUIPMENT_MODELS_FAIL;
}

export const GET_EQUIPMENT_DELIVERIES_DETAILS = 'GET_EQUIPMENT_DELIVERIES_DETAILS';
export const GET_EQUIPMENT_DELIVERIES_DETAILS_FAIL = 'GET_EQUIPMENT_DELIVERIES_DETAILS_FAIL';
export const GET_EQUIPMENT_DELIVERIES_DETAILS_SUCCESS = 'GET_EQUIPMENT_DELIVERIES_DETAILS_SUCCESS';

export class GetEquipmentDeliveriesDetails implements Action {
  readonly type = GET_EQUIPMENT_DELIVERIES_DETAILS;

  constructor(public payload: { id: string; type: string }) {}
}

export class GetEquipmentDeliveriesDetailsFail implements Action {
  readonly type = GET_EQUIPMENT_DELIVERIES_DETAILS_FAIL;
}

export class GetEquipmentDeliveriesDetailsSuccess implements Action {
  readonly type = GET_EQUIPMENT_DELIVERIES_DETAILS_SUCCESS;

  constructor(public payload: EquipmentDeliveriesDetails) {}
}



export type EquipmentAction =
  | GetEquipment
  | GetEquipmentFail
  | GetEquipmentSuccess
  | GetSelectedEquipment
  | GetSelectedEquipmentFail
  | GetSelectedEquipmentSuccess
  | CreateEquipment
  | CreateEquipmentFail
  | CreateEquipmentSuccess
  | UpdateEquipment
  | UpdateEquipmentFail
  | UpdateEquipmentSuccess
  | RemoveEquipment
  | RemoveEquipmentFail
  | RemoveEquipmentSuccess
  | CreateEquipmentTime
  | CreateEquipmentTimeFail
  | CreateEquipmentTimeSuccess
  | UpdateEquipmentTime
  | UpdateEquipmentTimeFail
  | UpdateEquipmentTimeSuccess
  | DeleteEquipmentTime
  | DeleteEquipmentTimeFail
  | DeleteEquipmentTimeSuccess
  | GetEquipmentTypes
  | GetEquipmentTypesFail
  | GetEquipmentTypesSuccess
  | GetUnloadingUtilities
  | GetUnloadingUtilitiesSuccess
  | GetUnloadingUtilitiesFail
  | GetEquipmentBrands
  | GetEquipmentBrandsSuccess
  | GetEquipmentBrandsFail
  | GetEquipmentModels
  | GetEquipmentModelsSuccess
  | GetEquipmentModelsFail
  | GetEquipmentDeliveriesDetails
  | GetEquipmentDeliveriesDetailsSuccess
  | GetEquipmentDeliveriesDetailsFail
  ;
