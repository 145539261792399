import { Action } from '@ngrx/store';
import { DeliveryRun } from '@app/shared';
import { GetDeliveryRunsResponse, QueryParamObject } from '@app/core/http/interfaces';
import { DeliveryStage } from '@app/state/interfaces';

export const GET_DELIVERY_RUN = 'GET_DELIVERY_RUN';
export const GET_DELIVERY_RUN_FAIL = 'GET_DELIVERY_RUN_FAIL';
export const GET_DELIVERY_RUN_SUCCESS = 'GET_DELIVERY_RUN_SUCCESS';

export class GetDeliveryRun implements Action {
  readonly type = GET_DELIVERY_RUN;
  constructor(public payload: { deliveryRunId: string }) {}
}

export class GetDeliveryRunFail implements Action {
  readonly type = GET_DELIVERY_RUN_FAIL;
  constructor(public payload: { error: any }) {}
}

export class GetDeliveryRunSuccess implements Action {
  readonly type = GET_DELIVERY_RUN_SUCCESS;
  constructor(public payload: { deliveryRun: DeliveryRun }) {}
}

export const GET_DELIVERY_RUN_LOGS = 'GET_DELIVERY_RUN_LOGS';
export const GET_DELIVERY_RUN_LOGS_FAIL = 'GET_DELIVERY_RUN_LOGS';
export const GET_DELIVERY_RUN_LOGS_SUCCESS = 'GET_DELIVERY_RUN_LOGS_SUCCESS';

export class GetDeliveryRunLogs implements Action {
  readonly type = GET_DELIVERY_RUN_LOGS;
  constructor(public payload: { deliveryRunId: string }) {}
}

export class GetDeliveryRunLogsFail implements Action {
  readonly type = GET_DELIVERY_RUN_LOGS_FAIL;
  constructor(public payload: any) {}
}

export class GetDeliveryRunLogsSuccess implements Action {
  readonly type = GET_DELIVERY_RUN_LOGS_SUCCESS;
  constructor(public payload: any) {}
}

export const GET_DELIVERY_RUN_TICKET = 'GET_DELIVERY_RUN_TICKET';
export const GET_DELIVERY_RUN_TICKET_FAIL = 'GET_DELIVERY_RUN_TICKET_FAIL';
export const GET_DELIVERY_RUN_TICKET_SUCCESS = 'GET_DELIVERY_RUN_TICKET_SUCCESS';

export class GetDeliveryRunTicket implements Action {
  readonly type = GET_DELIVERY_RUN_TICKET;
  constructor(public payload: { deliveryId: string, fileId: string }) {}
}

export class GetDeliveryRunTicketFail implements Action {
  readonly type = GET_DELIVERY_RUN_TICKET_FAIL;
  constructor(public payload: { error: any }) { }
}

export class GetDeliveryRunTicketSuccess implements Action {
  readonly type = GET_DELIVERY_RUN_TICKET_SUCCESS;
  constructor(public payload: string) {}
}

export const GET_DELIVERY_RUNS = 'GET_DELIVERY_RUNS_';
export const GET_DELIVERY_RUNS_SUCCESS = 'GET_DELIVERY_RUNS__SUCCESS';
export const GET_DELIVERY_RUNS_FAIL = 'GET_DELIVERY_RUNS__FAIL';

export class GetDeliveryRuns implements Action {
  readonly type = GET_DELIVERY_RUNS;

  constructor(public payload: { deliveryId: string; params: QueryParamObject[] }) {}
}

export class GetDeliveryRunsSuccess implements Action {
  readonly type = GET_DELIVERY_RUNS_SUCCESS;

  constructor(public payload: GetDeliveryRunsResponse) {}
}

export class GetDeliveryRunsFail implements Action {
  readonly type = GET_DELIVERY_RUNS_FAIL;
}

export const UPDATE_STAGE_IN_DELIVERY_RUN = 'UPDATE_STAGE_IN_DELIVERY_RUN';

export class UpdateStageInDeliveryRun implements Action {
  readonly type = UPDATE_STAGE_IN_DELIVERY_RUN;

  constructor(
    public payload: {
      deliveryRunId: string;
      deliveryStage: DeliveryStage;
      deliveryTicketIdentifier: string;
      batchStart: Date;
    },
  ) {}
}

export type DeliveryRunsActions =
  | GetDeliveryRun
  | GetDeliveryRunFail
  | GetDeliveryRunSuccess
  | GetDeliveryRunLogs
  | GetDeliveryRunLogsFail
  | GetDeliveryRunLogsSuccess
  | GetDeliveryRunTicket
  | GetDeliveryRunTicketFail
  | GetDeliveryRunTicketSuccess
  | GetDeliveryRuns
  | GetDeliveryRunsSuccess
  | GetDeliveryRunsFail;
