export const objects = {
  shortNamePattern: /^[A-Za-z\u3040-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]{1,4}-\d{1,2}$/,  // includes japanese letters
};

export const objectTypeToRouteMap = {
  crane: 'equipment',
  mobileCrane: 'equipment',
  excavator: 'equipment',
  miniExcavator: 'equipment',
  elevator: 'equipment',
  forklift: 'equipment',
  place: 'unloading-places',
  unloadingPlace: 'unloading-places',
  gate: 'gates',
  depot: 'depots',
};
