import { Directive, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[positiveIntegersOnly]',
})
export class InputNumberDirective {
  @Input() positiveIntegersOnly = false;

  @HostListener('keydown', ['$event']) keyDownListener(event) {
    this.onKeyDown(event);
  }

  @HostListener('paste', ['$event']) pasteListener(event) {
    this.onPaste(event);
  }

  constructor(private control: NgControl) {}

  private isPositiveInteger(value: string) {
    return /^[0-9]+$/.test(value);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey) {
      return;
    }

    if (event.metaKey) {
      return;
    }

    const toPrevent = this.positiveIntegersOnly
      ? !this.isPositiveInteger(event.key) && (event.key && event.key.length === 1)
      : event.key === 'e';

    if (toPrevent) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    const pasteValue = event.clipboardData.getData('Text');
    const parsedValueArray = pasteValue.match(/[0-9]/g) || [];

    event.preventDefault();
    this.control.control.setValue(parsedValueArray.join(''));
  }
}
