<footer class="footer container"
        [class.tv]="type === 'tv'">
  <button *ngIf="user"
          (click)="toggleFullScreen()"
          class="compress-button"
          [title]="(isFullScreen$ | async) ? ('info.exit_fullscreen_mode' | translate) : ('info.enter_fullscreen_mode' | translate)">
    <svg-icon [name]="(isFullScreen$ | async) ? 'icon-compress' : 'icon-expand'"></svg-icon>
  </button>
  <p class="footer__copyright">
    <ng-container *ngIf="type !== 'tv'; else logo">
      {{ currentDate | date:'dd.MM HH:mm' }} &copy; Propergate
    </ng-container>
    <ng-template #logo>
      <img src="assets/img/@logo.png"
           alt="logo">
      <span>ProperGate</span>
    </ng-template>
    <app-user-info *ngIf="user"
                   [user]="user"
                   class="info"></app-user-info>
    <span *ifMobile="false"
          class="info">
      Release {{ versionApp }} | {{ build | date:'yyyy-MM-dd HH:mm' }}
    </span>
  </p>
  <button *ngIf="user"
          (click)="themeChange()"
          class="theme-change"
          [title]="'general.theme_change_l' | translate"></button>
</footer>
