<nav class="menu"
     [class.active]="openedMenu">
  <button class="menu__open"
          (click)="toggleMenu()">
    <svg-icon name="hamburger"
              width="23"
              height="14"
              class="menu--hamburger"></svg-icon>
  </button>
  <div test-id="sideMenu"
       class="menu__list">
    <button class="menu__close"
            (click)="toggleMenu()">
      <svg-icon name="close"
                size="16"></svg-icon>
    </button>
    <ul *ngIf="role">
      <li class="menu__list__item"
          *ngFor="let link of links">
        <div class="menu__list__item__container"
             *ngIf="hasPermission(link.withPermission)">
          <a [routerLink]="link.path"
             class="menu-icon"
             [class.nofill]="link.ignoreFill"
             routerLinkActive="active"
             (click)="openedMenu = false">
            <div class="icon"
                 [inlineSVG]="link.icon"></div>
            <span class="tooltip">{{ link.title | translate }}</span>
          </a>
        </div>
      </li>
      <li class="menu__list__item">
        <div class="menu__list__item__container">
          <a class="menu-icon nofill"
             test-id="logoutButton"
             (click)="logout()">
            <svg-icon name="nav-icon-exit"
                      size="25"></svg-icon>
            <span class="tooltip">{{ 'login_page.logout_a' | translate }}</span>
          </a>
        </div>
      </li>
      <li *ngIf="avatarDisplayed"
          class="menu__list__item menu__list__item--profile">
        <a routerLink="/notifications">
          <div>
            <span *ngIf="notificationsAmount.value"
                  class="circle">
              {{ notificationsAmount.value > 99 ? '99+' : notificationsAmount.value }}
            </span>
            <img [src]="avatar$ | async"
                 (error)="onAvatarError()"
                 alt="">
          </div>
          <span class="tooltip">
            <strong>{{ 'general.warning_l' | translate }}! </strong>
            {{ 'general.notifications_amount_l' | translate:notificationsAmount }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</nav>
