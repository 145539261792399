import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AlertService } from '@ppgt/web/shared/core';
import { Notif } from '@app/state/interfaces';
import { AppState, AddNotification } from '@app/state';

interface ISocket {
  connected: boolean;

  on(eventName: string, callback: Function): void;
  disconnect(): void;
}

const NOTIFICATION_EVENT = 'message';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public socket: ISocket;

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {}

  init(token: string) {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }

    const { socketUrl, socketPath } = environment;

    this.socket = io(socketUrl, {
      transports: ['websocket'],
      reconnectionAttempts: 10,
      path: socketPath,
      query: {
        token,
      },
    }) as ISocket;

    this.socket.on(NOTIFICATION_EVENT, (notification: Notif) => {
      this.store.dispatch(new AddNotification(notification));

      this.alertService.showInfo(notification.message || this.translateService.instant(notification.type), {
        ignoreAlertDuration: notification.ignoreAlertDuration,
      });
    });
  }
}
