import { SortingOrder } from '@app/shared';
import { PermissionsEnum, UserRoles } from '@ppgt/web/shared/domain';

export enum Theme {
  White = 'white',
  Dark = 'dark',
}

export interface TableHeader {
  header?: string;
  description: string;
  class?: 'id' | 'center' | 'align-right';
  sortPossible?: boolean;
  order?: SortingOrder;
  roles?: UserRoles[];
  monitoringCo2Required?: boolean;
  requiredPermission?: PermissionsEnum;
  params?: any[];
  testId?: string;
}

export interface Filtering {
  property: string;
  label: string;
  type: 'select' | 'text' | 'number' | 'text-eq' | 'date' | 'date-future' | 'select-material';
  class?: string;
  values?: { name: string; value: any }[];
  roles?: UserRoles[];
}

export interface CargoType {
  value: string;
  translation: string;
}

export enum DeliveryFrontStatus {
  New = 'new',
  Currently = 'currently',
  Expected = 'expected',
  Accepted = 'accepted',
  ToAccept = 'to-accept',
  ToComplete = 'to-complete',
  Completed = 'completed',
  Rejected = 'rejected',
}
