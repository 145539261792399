import { Pipe, PipeTransform } from '@angular/core';
import { Depot } from '@app/state/interfaces';

@Pipe({ name: 'sortDepotsByFloor' })
export class SortDepotsByFloorPipe implements PipeTransform {
  transform(depots: Depot[]): Depot[] {
    return depots.sort((a, b) => {
      a.buildingFloor = {
        floorNumber: a.buildingFloor && a.buildingFloor.floorNumber || 0,
      };
      b.buildingFloor = {
        floorNumber: b.buildingFloor && b.buildingFloor.floorNumber || 0,
      };

      return a.buildingFloor.floorNumber - b.buildingFloor.floorNumber;
    });
  }
}
