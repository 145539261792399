import { Action } from '@ngrx/store';

import {
  GeneralSettings,
  DeliverySettings,
  ObjectSettings,
  SubcontractorSettings,
  EcologySettings,
  ProjectSettings,
  VehicleSize, ScheduleColorsSet,
} from '@app/state/interfaces';

// load settings
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_DELIVERY_SETTINGS = 'GET_DELIVERY_SETTINGS';
export const GET_DELIVERY_SETTINGS_FAIL = 'GET_DELIVERY_SETTINGS_FAIL';
export const GET_DELIVERY_SETTINGS_SUCCESS = 'GET_DELIVERY_SETTINGS_SUCCESS';
export const GET_OBJECT_SETTINGS = 'GET_OBJECT_SETTINGS';
export const GET_OBJECT_SETTINGS_FAIL = 'GET_OBJECT_SETTINGS_FAIL';
export const GET_OBJECT_SETTINGS_SUCCESS = 'GET_OBJECT_SETTINGS_SUCCESS';
export const GET_SUBCONTRACTOR_SETTINGS = 'GET_SUBCONTRACTOR_SETTINGS';
export const GET_SUBCONTRACTOR_SETTINGS_FAIL = 'GET_SUBCONTRACTOR_SETTINGS_FAIL';
export const GET_SUBCONTRACTOR_SETTINGS_SUCCESS = 'GET_SUBCONTRACTOR_SETTINGS_SUCCESS';
export const GET_ECOLOGY_SETTINGS = 'GET_ECOLOGY_SETTINGS';
export const GET_ECOLOGY_SETTINGS_FAIL = 'GET_ECOLOGY_SETTINGS_FAIL';
export const GET_ECOLOGY_SETTINGS_SUCCESS = 'GET_ECOLOGY_SETTINGS_SUCCESS';
export const GET_PROJECT_SETTINGS = 'GET_PROJECT_SETTINGS';
export const GET_PROJECT_SETTINGS_FAIL = 'GET_PROJECT_SETTINGS_FAIL';
export const GET_PROJECT_SETTINGS_SUCCESS = 'GET_PROJECT_SETTINGS_SUCCESS';
export const GET_VEHICLE_SIZES = 'GET_VEHICLE_SIZES';
export const GET_VEHICLE_SIZES_FAIL = 'GET_VEHICLE_SIZES_FAIL';
export const GET_VEHICLE_SIZES_SUCCESS = 'GET_VEHICLE_SIZES_SUCCESS';

export const GET_SCHEDULE_COLORS = 'GET_SCHEDULE_COLORS';
export const GET_SCHEDULE_COLORS_SUCCESS = 'GET_SCHEDULE_COLORS_SUCCESS';
export const GET_SCHEDULE_COLORS_FAIL = 'GET_SCHEDULE_COLORS_FAIL';

export const CREATE_SCHEDULE_COLORS = 'CREATE_SCHEDULE_COLORS';
export const CREATE_SCHEDULE_COLORS_SUCCESS = 'CREATE_SCHEDULE_COLORS_SUCCESS';
export const CREATE_SCHEDULE_COLORS_FAIL = 'CREATE_SCHEDULE_COLORS_FAIL';

export const UPDATE_SCHEDULE_COLORS = 'UPDATE_SCHEDULE_COLORS';
export const UPDATE_SCHEDULE_COLORS_SUCCESS = 'UPDATE_SCHEDULE_COLORS_SUCCESS';
export const UPDATE_SCHEDULE_COLORS_FAIL = 'UPDATE_SCHEDULE_COLORS_FAIL';

export class GetSettings implements Action {
  readonly type = GET_SETTINGS;

  constructor(public payload?: null) {}
}

export class GetSettingsFail implements Action {
  readonly type = GET_SETTINGS_FAIL;

  constructor(public payload?: null) {}
}

export class GetSettingsSuccess implements Action {
  readonly type = GET_SETTINGS_SUCCESS;

  constructor(public payload: GeneralSettings) {}
}

export class GetDeliverySettings implements Action {
  readonly type = GET_DELIVERY_SETTINGS;

  constructor(public payload?: null) {}
}

export class GetDeliverySettingsFail implements Action {
  readonly type = GET_DELIVERY_SETTINGS_FAIL;

  constructor(public payload?: null) {}
}

export class GetDeliverySettingsSuccess implements Action {
  readonly type = GET_DELIVERY_SETTINGS_SUCCESS;

  constructor(public payload: DeliverySettings) {}
}

export class GetObjectSettings implements Action {
  readonly type = GET_OBJECT_SETTINGS;

  constructor(public payload?: null) {}
}

export class GetObjectSettingsFail implements Action {
  readonly type = GET_OBJECT_SETTINGS_FAIL;

  constructor(public payload?: null) {}
}

export class GetObjectSettingsSuccess implements Action {
  readonly type = GET_OBJECT_SETTINGS_SUCCESS;

  constructor(public payload: ObjectSettings) {}
}

export class GetSubcontractorSettings implements Action {
  readonly type = GET_SUBCONTRACTOR_SETTINGS;

  constructor(public payload?: null) {}
}

export class GetSubcontractorSettingsFail implements Action {
  readonly type = GET_SUBCONTRACTOR_SETTINGS_FAIL;

  constructor(public payload?: null) {}
}

export class GetSubcontractorSettingsSuccess implements Action {
  readonly type = GET_SUBCONTRACTOR_SETTINGS_SUCCESS;

  constructor(public payload: SubcontractorSettings) {}
}

export class GetEcologySettings implements Action {
  readonly type = GET_ECOLOGY_SETTINGS;

  constructor(public payload?: null) {}
}

export class GetEcologySettingsFail implements Action {
  readonly type = GET_ECOLOGY_SETTINGS_FAIL;

  constructor(public payload?: null) {}
}

export class GetEcologySettingsSuccess implements Action {
  readonly type = GET_ECOLOGY_SETTINGS_SUCCESS;

  constructor(public payload: EcologySettings) {}
}

export class GetProjectSettings implements Action {
  readonly type = GET_PROJECT_SETTINGS;

  constructor(public payload?: null) {}
}

export class GetProjectSettingsFail implements Action {
  readonly type = GET_PROJECT_SETTINGS_FAIL;

  constructor(public payload?: null) {}
}

export class GetProjectSettingsSuccess implements Action {
  readonly type = GET_PROJECT_SETTINGS_SUCCESS;

  constructor(public payload: ProjectSettings) {}
}

export class GetVehicleSizes implements Action {
  readonly type = GET_VEHICLE_SIZES;

  constructor(public payload?: null) {}
}

export class GetVehicleSizesFail implements Action {
  readonly type = GET_VEHICLE_SIZES_FAIL;
}

export class GetVehicleSizesSuccess implements Action {
  readonly type = GET_VEHICLE_SIZES_SUCCESS;

  constructor(public payload: VehicleSize[]) {}
}

export class GetScheduleColors implements Action {
  readonly type = GET_SCHEDULE_COLORS;

  constructor(public payload?: null) {}
}

export class GetScheduleColorsSuccess implements Action {
  readonly type = GET_SCHEDULE_COLORS_SUCCESS;

  constructor(public payload: { colors: ScheduleColorsSet[] }) {}
}

export class GetScheduleColorsFail implements Action {
  readonly type = GET_SCHEDULE_COLORS_FAIL;

  constructor(public payload?: null) {}
}

export class CreateScheduleColors implements Action {
  readonly type = CREATE_SCHEDULE_COLORS;

  constructor(public payload: { colorsSets: ScheduleColorsSet[] }) {}
}

export class CreateScheduleColorsSuccess implements Action {
  readonly type = CREATE_SCHEDULE_COLORS_SUCCESS;

  constructor(public payload: { colorsSets: ScheduleColorsSet[] }) {}
}

export class CreateScheduleColorsFail implements Action {
  readonly type = CREATE_SCHEDULE_COLORS_FAIL;

  constructor(public payload?: null) {}
}

export class UpdateScheduleColors implements Action {
  readonly type = UPDATE_SCHEDULE_COLORS;

  constructor(public payload: { colorsSets: ScheduleColorsSet[] }) {}
}

export class UpdateScheduleColorsSuccess implements Action {
  readonly type = UPDATE_SCHEDULE_COLORS_SUCCESS;

  constructor(public payload: { colorsSets: ScheduleColorsSet[] }) {}
}

export class UpdateScheduleColorsFail implements Action {
  readonly type = UPDATE_SCHEDULE_COLORS_FAIL;

  constructor(public payload?: null) {}
}

export type SettingsAction =
  | GetSettings
  | GetSettingsFail
  | GetSettingsSuccess
  | GetDeliverySettings
  | GetDeliverySettingsFail
  | GetDeliverySettingsSuccess
  | GetObjectSettings
  | GetObjectSettingsFail
  | GetObjectSettingsSuccess
  | GetSubcontractorSettings
  | GetSubcontractorSettingsFail
  | GetSubcontractorSettingsSuccess
  | GetEcologySettings
  | GetEcologySettingsFail
  | GetEcologySettingsSuccess
  | GetProjectSettings
  | GetProjectSettingsFail
  | GetProjectSettingsSuccess
  | GetVehicleSizes
  | GetScheduleColors
  | GetScheduleColorsSuccess
  | GetScheduleColorsFail
  | CreateScheduleColors
  | CreateScheduleColorsSuccess
  | CreateScheduleColorsFail
  | UpdateScheduleColors
  | UpdateScheduleColorsSuccess
  | UpdateScheduleColorsFail;
