import { Pipe, PipeTransform } from '@angular/core';
import { TranslateItemsService } from '../@services/translate-items.service';

@Pipe({ name: 'translateUnit' })
export class TranslateUnitPipe implements PipeTransform {
  constructor(private translateItems: TranslateItemsService) {}

  transform(unitName: string): string {
    return this.translateItems.unit(unitName);
  }
}
