import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_URL } from './config/api-url-config';
import { environment } from '@ppgt/web/shared/config';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
  ],
  providers: [
    { provide: API_URL, useValue: environment.apiUrl },
  ],
})
export class WebSharedCoreModule {}
