import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromDashboard from './dashboard.reducer';
import { DashboardState } from '.';

const stateSelector = createFeatureSelector<DashboardState>('dashboard');

export const getAllDashboard = createSelector(
  stateSelector,
  fromDashboard.getDashboard
);

export const getAllCanteenDashboard = createSelector(
  stateSelector,
  fromDashboard.getCanteenDashboard
);

export const getAllTVDashboard = createSelector(
  stateSelector,
  fromDashboard.getTVDashboard
);

export const getDashboardLoaded = createSelector(
  stateSelector,
  fromDashboard.getDashboardsLoaded
);

export const getDashboardLoading = createSelector(
  stateSelector,
  fromDashboard.getDashboardsLoading
);

export const getDashboardPreview = createSelector(
  stateSelector,
  (state: DashboardState) => state.preview
);
