import { isFunction } from 'lodash-es';

import * as actions from './dashboard.action';

import { DashboardState } from './';
import {
  DashboardPreview,
  Dashboard,
  DashboardTV,
  DashboardCanteen,
  Action,
} from '../interfaces';

const initialState: DashboardState = {
  dashboard: null,
  dashboardTV: null,
  canteenDashboard: null,
  preview: null,
  loading: false,
  loaded: false,
};

const reducerMap = {
  [actions.GET_DASHBOARD]: (
    state: DashboardState,
    payload: { loading: boolean }
  ) => ({ ...state, ...payload }),
  [actions.GET_DASHBOARD_FAIL]: (state: DashboardState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_DASHBOARD_SUCCESS]: (
    state: DashboardState,
    dashboard: Dashboard
  ) => ({ ...state, dashboard, loading: false }),

  [actions.GET_DASHBOARD_PREVIEW]: (state: DashboardState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_DASHBOARD_PREVIEW_FAIL]: (
    state: DashboardState,
    payload: any
  ) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_DASHBOARD_PREVIEW_SUCCESS]: (
    state: DashboardState,
    preview: DashboardPreview
  ) => ({ ...state, preview, loading: false }),

  [actions.GET_TV_DASHBOARD_SUCCESS]: (
    state: DashboardState,
    dashboardTV: DashboardTV
  ) => ({ ...state, dashboardTV }),
  [actions.UPDATE_TV_DASHBOARD_SUCCESS]: (
    state: DashboardState,
    dashboardTV: DashboardTV
  ) => ({ ...state, dashboardTV }),
  [actions.GET_CANTEEN_DASHBOARD_SUCCESS]: (
    state: DashboardState,
    canteenDashboard: DashboardCanteen[]
  ) => ({ ...state, canteenDashboard }),
};

export function dashboardsReducer(
  state: DashboardState = initialState,
  action: Action
) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getDashboard = (state: DashboardState) => state.dashboard;
export const getTVDashboard = (state: DashboardState) => state.dashboardTV;
export const getCanteenDashboard = (state: DashboardState) =>
  state.canteenDashboard;
export const getDashboardsLoaded = (state: DashboardState) => state.loaded;
export const getDashboardsLoading = (state: DashboardState) => state.loading;
