import { Directive, Input, HostBinding } from '@angular/core';
import * as moment from 'moment-timezone';

@Directive({ selector: '[now]' })
export class NowDirective {
  @HostBinding('innerText')
  public innerText: string;

  @Input()
  public set now(format: string) {
    this.innerText = moment().format(format);
  }
}
