export const errors = {
  ADDING_MATERIAL_CATEGORY_IS_DISABLED: 'alert.adding_material_category_disabled',
  USER_EMAIL_WRONG_LENGTH: 'alert.user_email_wrong_l',
  UNEXPECTED_ERROR: 'alert.unexpected_error',
  GATE_NOT_ACTIVE: 'alert.gate_is_not_active',
  NO_GATE_FOR_SECURITY_GUARD: 'alert.user_has_not_dashboard_sg_l',
  MANUAL_MODE_ALREADY_SET: 'deliveries_page.manual_mode_allow_delivery',
  MATERIAL_IS_NOT_GENERAL: 'subcontractor_contracts.long_term_not_general_cargo',
  CONTRACT_NOT_APPLY_FOR_THE_DAYS: 'deliveries_page.delivery_contract_days_l',
  MATERIAL_SAME_NAME_EXISTS: 'materials.exist_same_material_l',
  LIMIT_VEHICLE_ON_CONSTRUCTION_SITE: 'dashboard_page.limit_vehicles_l',
  USER_SIMILAR_EMAIL: 'settings_page.user_email_exist_l',
  SUBCONTRACTOR_ALREADY_EXISTS: 'settings_page.subcontractor_exist_l',
  SequelizeForeignKeyConstraintError: 'subcontractors_page.place_is_used_l',
  OBJECT_SAME_NUMBER_EXISTS: 'settings_page.construction_plan.object_is_used_l',
  ACCESS_DENIED: 'alert.access_denied_l',
  ACCOUNT_TEMPORARILY_BLOCKED: 'alert.account_temporarily_blocked_l',
  ALREADY_ACTIVE: 'alert.already_active_l',
  ALREADY_VERIFIED: 'alert.already_verified_l',
  CAN_NOT_DELETE_PLACE: 'alert.can_not_delete_place_l',
  COMPANY_NOT_AUTHORIZED: 'alert.company_not_authorized_l',
  COMPANY_NOT_REGISTERED: 'alert.company_not_registered_l',
  CONSTRUCTION_SITE_NOT_FOUND: 'alert.construction_site_not_found_l',
  CONSTRUCTION_SITE_OBJECT_NOT_FOUND: 'alert.construction_site_object_not_found_l',
  CONTRACT_ALREADY_EXISTS: 'alert.contract_already_exists_l',
  CONTRACT_IS_NOT_ACTIVE: 'alert.contract_is_not_active_l',
  DELIVERY_NOT_FOUND: 'alert.delivery_not_found_l',
  END_DATE_BEFORE_START_DATE: 'alert.end_date_before_start_date_l',
  ENTERING_OR_LEAVING_IMPOSSIBLE: 'alert.entering_or_leaving_impossible_l',
  FAILED_ADD_DELIVERY_SETTINGS: 'alert.failed_add_delivery_settings_l',
  FUTURE_CONSTRUCTION_SITE_DOESNT_EXIST: 'alert.future_construction_site_doesnt_exist_l',
  IMPOSSIBLE_ADD_SUCH_CONTRACT: 'alert.impossible_add_such_contract_l',
  INVALID_CREDENTIALS: 'alert.invalid_credentials_l',
  INVALID_FILE_TYPE: 'alert.invalid_file_type_l',
  INVALID_PARAMETERS: 'alert.invalid_parameters_l',
  INVALID_TOKEN_TYPE: 'alert.invalid_token_type_l',
  INVALID_URL: 'alert.invalid_url_l',
  METHOD_NOT_EXISTS: 'alert.method_not_exists_l',
  MISSING_AUTHORIZATION_HEADER: 'alert.missing_authorization_header_l',
  MISSING_DATA: 'alert.missing_data_l',
  MISSING_TOKEN_TYPE: 'alert.missing_token_type_l',
  NOTIFICATION_DOESNT_EXIST: 'alert.notification_doesnt_exist_l',
  NOT_FOUND: 'alert.not_found_l',
  NOT_POSSIBLE_ADD_DELIVERY_TODAY: 'alert.not_possible_add_delivery_today_l',
  NOT_POSSIBLE_CANCEL_THIS_DELIVERY: 'alert.not_possible_cancel_this_delivery_l',
  NO_ACCESS: 'alert.no_access_l',
  NO_HANDLER_FOR_DASHBOARD: 'alert.no_handler_for_dashboard_l',
  NO_HANDLER_FOR_REPORT: 'alert.no_handler_for_report_l',
  NO_JWT_SECRET_ENV_VARIABLE: 'alert.no_jwt_secret_env_variable_l',
  NO_PASSWORD_ASSIGNED: 'alert.no_password_assigned_l',
  NO_SUCH_SERVICE: 'alert.no_such_service_l',
  NO_VEHICLE_FOUND_WITH_REGISTRATION_NUMBER: 'alert.no_vehicle_found_with_registration_number_l',
  REGISTRATION_NUMBER_ALREADY_ASSIGNED: 'alert.reg_no_already_assigned',
  OBJECT_EXIST: 'alert.object_exist_l',
  OBJECT_HAS_DELIVERIES: 'alert.object_has_deliveries_l',
  ON_CURRENT_CONSTRUCTION_SITE: 'alert.on_current_construction_site_l',
  PASSWORD_EXPIRED: 'alert.password_expired_l',
  PAYLOAD_NOT_SPECIFIED: 'alert.payload_not_specified_l',
  PERMISSIONS_CHANGED: 'alert.permissions_changed_l',
  PERMISSION_DENIED: 'alert.permission_denied_l',
  SAME_PASSWORD: 'alert.identical_password_what_previous_one_l',
  SESSION_EXPIRED: 'alert.session_expired_l',
  SIMILAR_CONSTRUCTION_SITE_EXISTS: 'alert.similar_construction_site_exists_l',
  SIMILAR_DELIVERY_EXISTS: 'alert.similar_delivery_exist_l',
  SIMILAR_VEHICLE_ON_CONSTRUCTION_SITE: 'alert.similar_vehicle_on_construction_site_l',
  SUBCONTRACTOR_PLACE_NOT_FOUND: 'alert.subcontractor_place_not_found_l',
  SequelizeValidationError: 'alert.sequelizevalidationerror_l',
  UNKNOWN_DELIVERY: 'alert.unknown_delivery_l',
  USER_NOT_ACTIVE: 'alert.user_not_active_l',
  USER_NOT_EXISTS: 'alert.user_not_exists_l',
  USER_NOT_HAS_DASHBOARD: 'alert.user_not_has_dashboard_l',
  USER_PASSWORD_NOT_ACTIVE: 'alert.user_password_not_active_l',
  VEHICLE_EXIST: 'alert.vehicle_exist_l',
  APPROVAL_REQUEST_ID_EXISTS: 'alert.material_already_exists_l',
  NOT_POSSIBLE_TO_SEND_TRUCK: 'alert.invalid_load_amount_l',
  TOTAL_AMOUNT_SIZE_CAN_NOT_BE_LESS: 'alert.total_quantity_cannot_be_less_l',
  DELIVERY_ON_SITE_CANNOT_BE_CANCELED: 'alert.delivery_on_site_cannot_be_canceled_l',
  CANNOT_START_ALREADY_STARTED_UNLOADING: 'alert.unloading_already_started',
  CANNOT_PAUSE_NOT_STARTED_UNLOADING: 'alert.unloading_cannot_pause',
  UNLOADING_ALREADY_FINISHED: 'alert.unloading_already_finished',
  JsonWebTokenError: 'alert.session_expired_l',
  LONGTERM_DELIVERY_CARDS_DISABLED: 'alert.longterm_delivery_card_disabled',
  DELIVERY_STAGE_CONFIRMATION_DEADLINE_EXCEEDED: 'alert.delivery_stage_confirmation_deadline_exceeded_l',
  MATERIAL_DUPLICATE: 'alert.material_already_exists_l',
};
