import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, merge } from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators';
import { config } from '@app/app.config';
import { AppState, GetNotifications, getUserData, getNotifications, getUserRole } from '@app/state';
import { User } from '@app/state/interfaces';
import { PermissionsEnum, UserRoles, Role } from '@ppgt/web/shared/domain';
import { AuthService } from '@app/core/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  public destroy$ = new Subject<void>();
  public openedMenu = false;
  public links = config.nav.general;
  public role: Role;
  public avatar$: Observable<string>;
  public avatarError$ = new Subject<void>();
  public isSubcontractorUrl = false;
  public notificationsAmount$: Observable<number>;
  public screenWidthBreakpoint = config.breakpoints.screenM;
  public avatarDisplayed: boolean;
  public notificationsAmount = { value: 0 };
  private grantedPermissions: PermissionsEnum[];

  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth >= this.screenWidthBreakpoint) {
      this.openedMenu = false;
    }
  }

  constructor(private store: Store<AppState>, private authService: AuthService) {}

  public ngOnInit() {
    this.store
      .select(getUserRole)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((role) => {
        this.role = role;
        this.grantedPermissions = role.permits;
        this.avatarDisplayed = role.type !== UserRoles.EQUIPMENT_OPERATOR;

        if (this.avatarDisplayed) {
          this.getAvatarAndNotifications();
        }
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getAvatarAndNotifications() {
    this.store.dispatch(new GetNotifications());

    this.avatar$ = merge(
      this.store.select(getUserData).pipe(map(this.getAvatarImage)),
      this.avatarError$.asObservable().pipe(map(() => config.user.defaultAvatar))
    );

    this.store
      .select(getNotifications)
      .pipe(
        map((notifications) => (notifications || []).filter((notif) => !notif.seen).length),
        takeUntil(this.destroy$)
      )
      .subscribe((amount) => {
        this.notificationsAmount = { value: amount };
      });
  }

  public hasPermission(requiredPermission: PermissionsEnum) {
    return this.grantedPermissions.includes(requiredPermission);
  }

  public logout() {
    this.authService.logout();
  }

  public onAvatarError() {
    this.avatarError$.next();
  }

  private getAvatarImage(userData: User) {
    return userData?.avatar || config.user.defaultAvatar
  }

  public toggleMenu(): void {
    this.openedMenu = !this.openedMenu;
  }
}
