import { ActionReducer } from '@ngrx/store';

import * as reducers from './reservations.reducer';

import { ReservationsEffects } from './reservations.effects';

import { EquipmentReservation } from '@app/state/interfaces';

export const reservationsEffects: any[] = [ReservationsEffects];

export interface ReservationsState {
  equipmentReservation: EquipmentReservation;
  isLoading: boolean;
}

export const reservationsReducer: ActionReducer<ReservationsState> = reducers.reservationsReducer;

export * from './reservations.actions';
export * from './reservations.effects';
export * from './reservations.reducer';
export * from './reservations.selector';
