import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataComponent } from '@app/shared/no-data/no-data.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NoDataComponent],
  exports: [
    NoDataComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class NoDataModule { }
