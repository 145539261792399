import { ActionReducer } from '@ngrx/store';

import * as effects from './tasks.effect';
import * as reducers from './tasks.reducer';

export const tasksImportSourceEffects: any[] = [effects.TasksImportSourceEffects];

export interface TasksImportSourceState {
  source: string | null
  loaded: boolean
  error: any
}

export const tasksReducer: ActionReducer<TasksImportSourceState> = reducers.tasksReducer;

export * from './tasks.action';
export * from './tasks.effect';
export * from './tasks.reducer';
export * from './tasks.selector';
