import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromSubcontractors from './subcontractors.reducer';
import { SubcontractorsState } from '.';

const stateSelector = createFeatureSelector<SubcontractorsState>('subcontractors');

export const getAllSubcontractors = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractors
);

export const getSubcontractor = createSelector(
  stateSelector,
  fromSubcontractors.getSelectedSubcontractor
);

export const getVehicles = createSelector(
  stateSelector,
  fromSubcontractors.getAllVehicles
);

export const getSubcontractorVehicles = createSelector(
  stateSelector,
  fromSubcontractors.getAllSubcontractorVehicles
);

export const getAllSubcontractorPlaces = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractorPlaces
);

export const getSubcontractorsCount = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractorCount
);

export const getSubcontractorsLoading = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractorLoading
);

export const getSubcontractorsEmployees = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractorEmployees,
);

export const getSubcontractorsLocations = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractorLocations,
);

export const getSubcontractorsEmployeesRoles = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractorEmployeesRoles,
);

export const getSubcontractorsSuppliers = createSelector(
  stateSelector,
  fromSubcontractors.getSubcontractorSuppliers,
);

export const subcontractorQuery = {
  getAllSubcontractors,
};
