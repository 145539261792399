import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from './svg-icon.component';
import { SvgIconService } from './svg-icon.service';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  imports: [CommonModule, InlineSVGModule],
  declarations: [SvgIconComponent],
  exports: [SvgIconComponent],
  providers: [SvgIconService],
})
export class SvgModule {}
