import { Role } from '@ppgt/web/shared/domain';
import * as actions from './roles.action';

export interface RolesState {
  roles: Role[];
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
}

const initialState: RolesState = {
  roles: null,
  isLoaded: false,
  isLoading: false,
  error: null
};

/**
 * @TODO: DELETE_ROLE reducer
 */
export function rolesReducer(state: RolesState = initialState, action: actions.RolesAction): RolesState {
  switch (action.type) {
    case actions.UPDATE_ROLE:
    case actions.CREATE_ROLE:
    case actions.GET_ROLES:
      return {
        ...state,
        isLoading: true,
        error: null
    };

    case actions.UPDATE_ROLE_SUCCESS: {
      const { updatedRole } = action.payload;
      const updatedRoles = [ ...state.roles ];
      updatedRoles.splice(updatedRoles.findIndex(role => role.id === updatedRole.id), 1, { ...updatedRole });

      return {
        ...state,
        isLoading: false,
        error: null,
        roles: [ ...updatedRoles ],
      };
    }

    case actions.UPDATE_ROLE_FAIL:
    case actions.CREATE_ROLE_FAIL:
    case actions.GET_ROLES_FAIL:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: action.payload.error
      };
    case actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.fetchedRoles,
        isLoaded: true,
        isLoading: false,
        error: null
      };
    case actions.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: [...state.roles, { ...action.payload.createdRole }],
        isLoaded: true,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}

export const roles = (state: RolesState) => state.roles;
export const rolesLoaded = (state: RolesState) => state.isLoaded;
export const rolesLoading = (state: RolesState) => state.isLoading;
export const rolesError = (state: RolesState) => state.error;
