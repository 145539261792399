import { Component, OnInit, OnDestroy } from '@angular/core';
import { Alert, AlertService } from '@ppgt/web/shared/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

interface RedirectConfig {
  baseUrlPath: string;
  queryParams: { [key: string]: any };
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})

export class AlertComponent implements OnInit, OnDestroy {
  public destroy$ = new Subject<void>();
  alerts: Alert[] = [];

  constructor(
    private alertService: AlertService,
    private router: Router,
    ) {}

  ngOnInit() {
    this.alertService.alert$
      .pipe(takeUntil(this.destroy$))
      .subscribe((alert: Alert) => {
        if (!alert) {
          this.alerts = [];
          return false;
        }
        this.alerts.push(alert);
        setTimeout(() => (alert.startAnimation = true), 100);

        if (this.alerts.length > 2) {
          this.removeAlert(this.alerts[0]);
        }

        setTimeout(() => {
          if (!alert.ignoreAlertDuration) {
            this.removeAlert(alert);
          }
        }, alert.time);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(item => item !== alert);

    this.alertService.onCloseAlert(alert);
  }

  checkElementAction(alert: Alert, event: MouseEvent) {
    event.preventDefault();

    const message = `<div>${alert.message}</div>`;
    const parsed = new DOMParser().parseFromString(message, 'text/xml');

    const parsedData = parsed.querySelector('span');
    const navigationUrl = parsedData && parsedData.getAttribute('href');

    if (navigationUrl) {
      const {baseUrlPath, queryParams}: RedirectConfig = this.generateRedirectConfig(navigationUrl);
      this.router.navigate([baseUrlPath], {queryParams});
    }
  }

  private generateRedirectConfig(url: string): RedirectConfig {
    const [baseUrlPath, param] = url.split('?');
    const queryParams: { [key: string]: any } = null;
    const redirectConfig = {
      baseUrlPath,
      queryParams,
    };

    if (param) {
      const [paramKey, paramValue] = param.split('=');

      if (paramKey && paramValue) {
        redirectConfig.queryParams = {};
        redirectConfig.queryParams[paramKey] = paramValue;
      }
    }

    return redirectConfig;
  }
}
