import { Action } from '@ngrx/store';

import { CreateBuildingReq, QueryParamObject } from '@app/core/http/interfaces';
import { Building } from '@app/state/interfaces';

// load buildings
export const GET_BUILDINGS = 'GET_BUILDINGS';
export const GET_BUILDINGS_FAIL = 'GET_BUILDINGS_FAIL';
export const GET_BUILDINGS_SUCCESS = 'GET_BUILDINGS_SUCCESS';

export class GetBuildings implements Action {
  readonly type = GET_BUILDINGS;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetBuildingsFail implements Action {
  readonly type = GET_BUILDINGS_FAIL;
}

export class GetBuildingsSuccess implements Action {
  readonly type = GET_BUILDINGS_SUCCESS;

  constructor(public payload: Building[]) {}
}

// create buildings
export const CREATE_BUILDING = 'CREATE_BUILDING';
export const CREATE_BUILDING_FAIL = 'CREATE_BUILDING_FAIL';
export const CREATE_BUILDING_SUCCESS = 'CREATE_BUILDING_SUCCESS';

export class CreateBuilding implements Action {
  readonly type = CREATE_BUILDING;

  constructor(public payload: CreateBuildingReq) {}
}

export class CreateBuildingFail implements Action {
  readonly type = CREATE_BUILDING_FAIL;
}

export class CreateBuildingSuccess implements Action {
  readonly type = CREATE_BUILDING_SUCCESS;

  constructor(public payload: Building) {}
}

// load selected building
export const GET_BUILDING = 'GET_BUILDING';
export const GET_BUILDING_FAIL = 'GET_BUILDING_FAIL';
export const GET_BUILDING_SUCCESS = 'GET_BUILDING_SUCCESS';

export class GetBuilding implements Action {
  readonly type = GET_BUILDING;

  constructor(public payload: { id: string }) {}
}

export class GetBuildingFail implements Action {
  readonly type = GET_BUILDING_FAIL;
}

export class GetBuildingSuccess implements Action {
  readonly type = GET_BUILDING_SUCCESS;

  constructor(public payload: Building) {}
}

// update selected building
export const UPDATE_BUILDING = 'UPDATE_BUILDING';
export const UPDATE_BUILDING_FAIL = 'UPDATE_BUILDING_FAIL';
export const UPDATE_BUILDING_SUCCESS = 'UPDATE_BUILDING_SUCCESS';

export class UpdateBuilding implements Action {
  readonly type = UPDATE_BUILDING;

  constructor(
    public payload: {
      id: string;
      data: CreateBuildingReq;
    }
  ) {}
}

export class UpdateBuildingFail implements Action {
  readonly type = UPDATE_BUILDING_FAIL;
}

export class UpdateBuildingSuccess implements Action {
  readonly type = UPDATE_BUILDING_SUCCESS;

  constructor(public payload: Building) {} // not working on back - 404
}

// remove selected building
export const REMOVE_BUILDING = 'REMOVE_BUILDING';
export const REMOVE_BUILDING_FAIL = 'REMOVE_BUILDING_FAIL';
export const REMOVE_BUILDING_SUCCESS = 'REMOVE_BUILDING_SUCCESS';

export class RemoveBuilding implements Action {
  readonly type = REMOVE_BUILDING;

  constructor(public payload: { id: string }) {}
}

export class RemoveBuildingFail implements Action {
  readonly type = REMOVE_BUILDING_FAIL;
}

export class RemoveBuildingSuccess implements Action {
  readonly type = REMOVE_BUILDING_SUCCESS;

  constructor(public payload: any) {}
}

export const CLEAR_BUILDING = 'CLEAR_BUILDING';

export class ClearBuilding implements Action {
  readonly type = CLEAR_BUILDING;
}

export type BuildingsAction =
  | GetBuildings
  | GetBuildingsFail
  | GetBuildingsSuccess
  | CreateBuilding
  | CreateBuildingFail
  | CreateBuildingSuccess
  | GetBuilding
  | GetBuildingFail
  | GetBuildingSuccess
  | UpdateBuilding
  | UpdateBuildingFail
  | UpdateBuildingSuccess
  | RemoveBuilding
  | RemoveBuildingFail
  | RemoveBuildingSuccess
  | ClearBuilding;
