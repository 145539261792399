import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RolesHttpService } from '@app/core/http/roles.http.service';
import * as actions from './roles.action';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';

@Injectable()
export class RolesEffects {
  constructor(
    private http: RolesHttpService,
    private actions$: Actions<actions.RolesAction>,
    private translationService: TranslateItemsService,
  ) { }

  
  createRole: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_ROLE),
      switchMap((action: actions.CreateRole) => this.http.createRole(action.payload.roleToCreate).pipe(
          map(res => new actions.CreateRoleSuccess({
            createdRole: this.translationService.translateRole(res.data)
          })),
          catchError(error => [new actions.CreateRoleFail({ error })])
        ))
    ));

  
  updateRole: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_ROLE),
      switchMap((action: actions.UpdateRole) => {
        const { roleToUpdate } = action.payload;

        // Forbidden fields
        delete roleToUpdate.editable;
        delete roleToUpdate.type;

        return this.http.updateRole(roleToUpdate.id, roleToUpdate).pipe(
          map(res => new actions.UpdateRoleSuccess({
            updatedRole: this.translationService.translateRole(res.data)
          })),
          catchError(error => [new actions.UpdateRoleFail({ error })])
        );
      })
    ));

  
  getRoles: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_ROLES),
      switchMap(() => this.http.getRoles().pipe(
          map(res => new actions.GetRolesSuccess({
            fetchedRoles: this.translationService.translateRoles(res.data)
          })),
          catchError(error => [new actions.GetRolesFail({ error })])
        ))
    ));
}
