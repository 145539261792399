import { Pipe, PipeTransform } from '@angular/core';
import { GenericFormGroup } from '@app/shared';

@Pipe({
  name: 'hasError'
})
export class HasErrorPipe implements PipeTransform {
  transform(form: GenericFormGroup, path: string | (string | number)[], errorType: string): boolean {
    return form.get(path).hasError(errorType) && form.get(path).touched;
  }
}
