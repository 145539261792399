import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(list: any[], property: string, dir = 'asc'): any[] {
    if (!list || !property) {
      return list;
    }

    list.sort( (a, b) => {
      return ('' + a[property]).localeCompare( ('' + b[property]) ) * (dir === 'asc' ? 1 : -1);
    });

    return list;
  }

}