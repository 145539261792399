import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { SharedModule } from '@app/shared';
import { AuthInterceptor } from './http/http.interceptors';
import { AlertComponent } from './alert/alert.component';
import { WebSharedUiConfirmModule } from '@ppgt/web/shared/ui/confirm';
import { DeliveryGuard } from '@app/deliveries/deliveries.guard';
import { HammerConfig } from './hammer';
import {
  AuthenticationGuard,
  RedirectionGuard,
  TranslationsGuard,
  SettingsGuard,
  UserDataGuard,
  RoleGuard,
  DeactivationGuard,
  PanelGuard,
  FullScreenGuard,
  EcologyDataGuard,
  OnetimeFormOpenedGuard,
} from './guards';
import { API_URL } from '@app/core/api-url-config';
import { environment } from '@env/environment';

const guards = [
  AuthenticationGuard,
  RedirectionGuard,
  TranslationsGuard,
  SettingsGuard,
  UserDataGuard,
  RoleGuard,
  DeactivationGuard,
  PanelGuard,
  DeliveryGuard,
  FullScreenGuard,
  EcologyDataGuard,
  OnetimeFormOpenedGuard,
];
@NgModule({
  imports: [CommonModule, SharedModule, HttpClientModule, RouterModule, WebSharedUiConfirmModule],
  declarations: [AlertComponent],
  exports: [AlertComponent, WebSharedUiConfirmModule],
  providers: [
    ...guards,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: API_URL, useValue: environment.apiUrl },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
