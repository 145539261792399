import { Injectable } from '@angular/core';
import { workingHours } from '@app/config';
import * as moment from 'moment-timezone';
import * as dayjs from 'dayjs';
import 'moment-duration-format';
import { GeneralSettings } from '@app/state/interfaces';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { config } from '@app/app.config';
import { cloneDeep } from 'lodash-es';

type TimeRangeHours = 1 | 2 | 3 | 4 | 6 | 12;
export interface Duration extends moment.Duration {
  format: (template?: string, precision?: number, settings?: DurationSettings) => string;
}

interface DurationSettings {
  forceLength: boolean;
  precision: number;
  template: string;
  trim: string | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  readonly RANGE_STEP_IN_HOURS: TimeRangeHours = 6;
  ltTimeFormat = 'H:mm';
  ltsTimeFormat = 'H:mm:ss';
  settings: GeneralSettings;
  clockFormat = '24h';
  timezone: string;

  constructor(private translateService: TranslateService) {}

  init(settings: GeneralSettings, clockFormat = '24h') {
    this.settings = settings;
    this.clockFormat = clockFormat;
    const localeCode = settings ? settings.language : environment.defaultLanguage;

    this.updateLocale(localeCode);
  }

  setTimezone(timezone: string): void {
    this.timezone = timezone;
    moment.tz.setDefault(this.timezone);
    dayjs.tz.setDefault(this.timezone);
  }

  updateLocale(localeCode: string): void {
    moment.locale(localeCode);

    if (this.clockFormat === '12h') {
      this.ltTimeFormat = 'h:mm A';
      this.ltsTimeFormat = 'h:mm:ss A';
    }

    moment.updateLocale(localeCode, {
      week: {
        dow: this.settings?.weekDays.start || 1,
      },
      longDateFormat: {
        LT: this.ltTimeFormat,
        LTS: this.ltsTimeFormat,
        L: moment().localeData().longDateFormat('L'),
        LL: moment().localeData().longDateFormat('LL'),
        LLL: moment().localeData().longDateFormat('LLL'),
        LLLL: moment().localeData().longDateFormat('LLLL'),
      },
    });
  }

  createTimeSeries(
    from: string = this.settings.workFrom || workingHours.start,
    to: string = this.settings.workTo || workingHours.end,
    interval: number = this.settings.workInterval || workingHours.interval
  ): string[] {
    const timeSeries: string[] = [];
    const startTime = moment(from, this.ltTimeFormat);
    const endTime = moment(to, this.ltTimeFormat);
    const time = startTime;

    while (time <= endTime) {
      timeSeries.push(time.format(this.ltTimeFormat));
      time.add(interval, 'm');
    }

    return timeSeries;
  }

  createTimeRanges(
    from = this.settings.workFrom || workingHours.start,
    to = this.settings.workTo || workingHours.end,
    interval: TimeRangeHours = this.RANGE_STEP_IN_HOURS
  ) {
    const timeRanges: string[] = [];
    const startTime = moment(from, this.ltTimeFormat);
    const endTime = moment(to, this.ltTimeFormat);
    const time = startTime;

    while (time.isSameOrBefore(endTime)) {
      const range = `${time.format('H:mm')} - ${time.add(interval, 'h').format('k:mm')}`;
      timeRanges.push(range);
    }

    return timeRanges;
  }

  getMonthNames() {
    return moment().months;
  }

  getDayNames() {
    return moment().days;
  }

  getDelay(unloadingDate: string | Date, entryDate: string | Date) {
    const duration = moment.duration(moment(entryDate).diff(moment(unloadingDate))) as Duration;
    let diff = duration.format('h:mm');

    if (diff === '0') {
      return this.translateService.instant('dashboard_page.delivery_on_time_l');
    }

    if (diff.charAt(0) !== '-') {
      diff = `+${diff}`;
    }

    return diff + (diff.length > 3 ? ' h' : ' min');
  }

  getExitDate(unloadingDate: string, time: number) {
    return time ? moment(unloadingDate).add(time, 'm').format('H:mm') : '--:--';
  }

  getExitDifference(unloadingDate: string | Date, time: number, entryDate: string | Date) {
    const momentDiff = moment().diff(moment(entryDate || unloadingDate).add(time, 'm'));
    let diff = (moment.duration(momentDiff) as Duration).format('h:mm');

    if (diff.charAt(0) !== '-') {
      diff = `+${diff}`;
    }

    return diff + (diff.length > 3 ? ' h' : ' min');
  }

  getDatePickerOptions(): IMyOptions {
    return {
      ...cloneDeep(config.datePickerOptions),
      firstDayOfWeek: this.settings?.firstDayOfWeek,
    };
  }

  getDatePickerToday(): IMyDateModel {
    const now = moment();
    const date = { year: now.year(), month: now.month() + 1, day: now.date() };
    const jsdate = now.startOf('d').toDate();
    const formatted = now.format(config.datePickerMomentFormat);
    const epoc = now.unix();

    return { date, jsdate, formatted, epoc };
  }

  public getDatepickerDate(givenDate: string | number | Date): IMyDateModel {
    const momentDate = moment(givenDate);
    const date = { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() };
    const jsdate = momentDate.startOf('d').toDate();
    const formatted = momentDate.format(config.datePickerMomentFormat);
    const epoc = momentDate.unix();

    return { date, jsdate, formatted, epoc };
  }
}
