import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';
import { GetItemsSuccessRes, UpsertItemSuccessRes } from './interfaces';
import { Permission, PermissionsSection } from '@interfaces';
import { ErrorHandlerService } from './error-handler.service';
import { createApiUrl } from './http-utils';

@Injectable({
  providedIn: 'root',
})
export class PermissionsHttpService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    public store: Store<AppState>,
  ) { }

  getPermissions(): Observable<GetItemsSuccessRes<Permission>> {
    return this.http
      .get<GetItemsSuccessRes<Permission>>(createApiUrl('permissions'))
      .pipe(catchError((err) => this.errorHandlerService.errorShowAndHandle(err)));
  }

  getSections(): Observable<GetItemsSuccessRes<PermissionsSection>> {
    return this.http
      .get<GetItemsSuccessRes<PermissionsSection>>(
        createApiUrl('permissions/sections'),
        { params: { withPermissions: 'true' } },
      )
      .pipe(catchError((err) => this.errorHandlerService.errorShowAndHandle(err)));
  }

  updatePermission(
    id: string,
    updatedPermissionData: Partial<Permission>
  ): Observable<UpsertItemSuccessRes<Permission>> {
    return this.http
      .put<UpsertItemSuccessRes<Permission>>(createApiUrl(`permissions/one/${id}`), updatedPermissionData)
      .pipe(catchError((err) => this.errorHandlerService.errorShowAndHandle(err)));
  }

  updateSection(
    id: string,
    updatedSectionData: any
  ): Observable<UpsertItemSuccessRes<PermissionsSection>> {
    return this.http
      .put<UpsertItemSuccessRes<PermissionsSection>>(createApiUrl(`permissions/sections/${id}`), updatedSectionData)
      .pipe(catchError((err) => this.errorHandlerService.errorShowAndHandle(err)));
  }

}
