import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TranslationsGuard } from './core';

const routes: Routes = [
  { path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [],
})
export class WildcardRoutingModule {}
