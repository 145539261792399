import { isFunction } from 'lodash-es';

import { User, Action, ReducerMap } from '@app/state/interfaces';

import * as actions from './users.action';
import { UsersState } from './';
import { GetUsersRes } from '@app/core/http/interfaces';

const initialState: UsersState = {
  userData: null,
  userPin: null,
  users: [],
  loaded: false,
  loading: false,
  totalCount: 0,
  selectedUser: null,
};

const reducerMap: ReducerMap<UsersState> = {
  [actions.GET_USER_DATA]: state => ({ ...state, loading: true }),
  [actions.GET_USER_DATA_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_USER_DATA_SUCCESS]: (state, userData: User) => ({
    ...state,
    userData,
    loading: false,
  }),

  [actions.GET_USERS]: state => ({ ...state, users: [], loading: true }),
  [actions.GET_USERS_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_USERS_SUCCESS]: (state, data: GetUsersRes): UsersState => ({
    ...state,
    users: data.users,
    loaded: true,
    loading: false,
    totalCount: data.meta.count,
  }),

  [actions.GET_USER]: state => ({ ...state, selectedUser: null, loading: true }),
  [actions.GET_USER_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_USER_SUCCESS]: (state, user: User): UsersState => ({
    ...state,
    loading: false,
    selectedUser: user,
  }),

  [actions.CREATE_USER]: state => ({ ...state, loading: true }),
  [actions.CREATE_USER_FAIL]: state => ({ ...state, loading: false }),
  [actions.CREATE_USER_SUCCESS]: (state, newUser: User): UsersState => ({
    ...state,
    loading: false,
    users: [newUser, ...state.users],
  }),

  [actions.UPDATE_USER]: state => ({ ...state, loading: true }),
  [actions.UPDATE_USER_FAIL]: state => ({ ...state, loading: false }),
  [actions.UPDATE_USER_SUCCESS]: (state, updatedUser: User): UsersState => {
    const users = [updatedUser, ...state.users.filter(user => user.id !== updatedUser.id)];

    const userData =
      updatedUser.id === state.userData.id ? { ...state.userData, ...updatedUser } : state.userData;
    const selectedUser =
      updatedUser.id === (state.selectedUser && state.selectedUser.id)
        ? { ...state.selectedUser, ...updatedUser }
        : state.selectedUser;

    return {
      ...state,
      loading: false,
      users,
      selectedUser,
      userData,
    };
  },

  [actions.GET_LOGS]: state => ({ ...state, loading: true }),
  [actions.GET_LOGS_SUCCESS]: state => ({ ...state, loading: false }),
  [actions.GET_LOGS_FAIL]: state => ({ ...state, loading: false }),

  [actions.NEW_PASSWORD]: state => ({ ...state, loading: true }),
  [actions.NEW_PASSWORD_SUCCESS]: state => ({ ...state, loading: false }),
  [actions.NEW_PASSWORD_FAIL]: state => ({ ...state, loading: false }),

  [actions.UPDATE_PASSWORD]: state => ({ ...state, loading: true }),
  [actions.UPDATE_PASSWORD_SUCCESS]: state => ({ ...state, loading: false }),
  [actions.UPDATE_PASSWORD_FAIL]: state => ({ ...state, loading: false }),

  [actions.GET_USER_PIN]: state => ({ ...state, userPin: null }),
  [actions.GET_USER_PIN_FAIL]: state => ({ ...state }),
  [actions.GET_USER_PIN_SUCCESS]: (state, userPin): UsersState => ({
    ...state,
    userPin,
  }),

  [actions.UPDATE_AVATAR]: (state) => ({ ...state, loading: true }),
  [actions.UPDATE_AVATAR_FAIL]: (state) => ({ ...state, loading: false }),
  [actions.UPDATE_AVATAR_SUCCESS]: (state, avatar) => {
    const userData = { ...state.userData, avatar };

    return { ...state, userData, loading: false };
  },
  [actions.STORE_AVATAR_BASE64_SUCCESS]: (state, avatar) => {
    const userData = { ...state.userData, avatar };
    
    return { ...state, userData, loading: false };
  },
};  

export function usersReducer(state: UsersState = initialState, action: Action) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
