import { Action } from '@ngrx/store';

import {
  CreateDepotsReq,
  QueryParamObject,
  UpdateDepotsReq,
} from '@app/core/http/interfaces';
import { Depot, MaterialDepot } from '@app/state/interfaces';

// load depots
export const GET_DEPOTS = 'GET_DEPOTS';
export const GET_DEPOTS_FAIL = 'GET_DEPOTS_FAIL';
export const GET_DEPOTS_SUCCESS = 'GET_DEPOTS_SUCCESS';

/* eslint-disable max-classes-per-file */
export class GetDepots implements Action {
  readonly type = GET_DEPOTS;

  constructor(public payload?: QueryParamObject[]) {}
}

export class GetDepotsFail implements Action {
  readonly type = GET_DEPOTS_FAIL;
}

export class GetDepotsSuccess implements Action {
  readonly type = GET_DEPOTS_SUCCESS;

  constructor(public payload: Depot[]) {}
}

// load depots
export const GET_MATERIAL_DEPOTS = 'GET_MATERIAL_DEPOTS';
export const GET_MATERIAL_DEPOTS_FAIL = 'GET_MATERIAL_DEPOTS_FAIL';
export const GET_MATERIAL_DEPOTS_SUCCESS = 'GET_MATERIAL_DEPOTS_SUCCESS';

export class GetMaterialDepots implements Action {
  readonly type = GET_MATERIAL_DEPOTS;

  constructor(public payload: { categoryId: string }) {}
}

export class GetMaterialDepotsFail implements Action {
  readonly type = GET_MATERIAL_DEPOTS_FAIL;
}

export class GetMaterialDepotsSuccess implements Action {
  readonly type = GET_MATERIAL_DEPOTS_SUCCESS;

  constructor(public payload: MaterialDepot[]) {}
}

// create depot
export const CREATE_DEPOT = 'CREATE_DEPOT';
export const CREATE_DEPOT_FAIL = 'CREATE_DEPOT_FAIL';
export const CREATE_DEPOT_SUCCESS = 'CREATE_DEPOT_SUCCESS';

export class CreateDepot implements Action {
  readonly type = CREATE_DEPOT;

  constructor(public payload: CreateDepotsReq) {}
}

export class CreateDepotFail implements Action {
  readonly type = CREATE_DEPOT_FAIL;
}

export class CreateDepotSuccess implements Action {
  readonly type = CREATE_DEPOT_SUCCESS;

  constructor(public payload: Depot) {}
}

// load selected depot
export const GET_DEPOT = 'GET_DEPOT';
export const GET_DEPOT_FAIL = 'GET_DEPOT_FAIL';
export const GET_DEPOT_SUCCESS = 'GET_DEPOT_SUCCESS';

export class GetDepot implements Action {
  readonly type = GET_DEPOT;

  constructor(public payload: { id: string }) {}
}

export class GetDepotFail implements Action {
  readonly type = GET_DEPOT_FAIL;
}

export class GetDepotSuccess implements Action {
  readonly type = GET_DEPOT_SUCCESS;

  constructor(public payload: Depot) {}
}

// update selected depot
export const UPDATE_DEPOT = 'UPDATE_DEPOT';
export const UPDATE_DEPOT_FAIL = 'UPDATE_DEPOT_FAIL';
export const UPDATE_DEPOT_SUCCESS = 'UPDATE_DEPOT_SUCCESS';

export class UpdateDepot implements Action {
  readonly type = UPDATE_DEPOT;

  constructor(
    public payload: {
      id: string;
      data: UpdateDepotsReq;
      redirectAfter?: boolean;
    }
  ) {}
}

export class UpdateDepotFail implements Action {
  readonly type = UPDATE_DEPOT_FAIL;
}

export class UpdateDepotSuccess implements Action {
  readonly type = UPDATE_DEPOT_SUCCESS;

  constructor(public payload: Depot) {} // not working on back - 404
}

// remove selected depot
export const REMOVE_DEPOT = 'REMOVE_DEPOT';
export const REMOVE_DEPOT_FAIL = 'REMOVE_DEPOT_FAIL';
export const REMOVE_DEPOT_SUCCESS = 'REMOVE_DEPOT_SUCCESS';

export class RemoveDepot implements Action {
  readonly type = REMOVE_DEPOT;

  constructor(
    public payload: {
      id: string;
      redirectAfter?: boolean;
    }
  ) {}
}

export class RemoveDepotFail implements Action {
  readonly type = REMOVE_DEPOT_FAIL;
}

export class RemoveDepotSuccess implements Action {
  readonly type = REMOVE_DEPOT_SUCCESS;

  constructor(public payload: any) {}
}

export const CLEAR_DEPOT = 'CLEAR_DEPOT';

export class ClearDepot implements Action {
  readonly type = CLEAR_DEPOT;
}

export type DepotsAction =
  | GetDepots
  | GetDepotsFail
  | GetDepotsSuccess
  | GetMaterialDepots
  | GetMaterialDepotsFail
  | GetMaterialDepotsSuccess
  | CreateDepot
  | CreateDepotFail
  | CreateDepotSuccess
  | GetDepot
  | GetDepotFail
  | GetDepotSuccess
  | UpdateDepot
  | UpdateDepotFail
  | UpdateDepotSuccess
  | RemoveDepot
  | RemoveDepotFail
  | RemoveDepotSuccess
  | ClearDepot;
