<form [formGroup]="form"
      class="corner--box no-theme"
      *ngIf="!openedSearch">
  <ng-container formArrayName="filters"
                *ngFor="let filter of filtersGroups; let i = index">
    <div class="chip"
         [formGroupName]="i"
         *ngIf="filter.value.property">
      <span class="content">
        {{ getFilterDetail(i, 'label') | translate }}:
        {{ (getFilterDetail(i, 'type') === 'select' ? getFilterValueTranslation(i) : getAsString(filter.value.text)) | translate }}
      </span>
      <span (click)="removeFilterGroup(i, true)"
            class="close">✕</span>
    </div>
  </ng-container>
  <button class="search-button"
          test-id="searchButton"
          (click)="openedSearch = true">
    <svg-icon name="icon-search"
              size="24"></svg-icon>
  </button>
</form>

<div class="search__box"
     test-id="searchForm"
     *ngIf="openedSearch">
  <button class="close-button"
          (click)="closePanel()">
    <div inlineSVG="assets/img/icon-close.svg"></div>
  </button>
  <h3>{{ 'filter_title' | translate }}</h3>
  <form class="form"
        [formGroup]="form">
    <div class="filters"
         formArrayName="filters">
      <div *ngFor="let _ of filtersGroups; let i = index"
           [formGroupName]="i"
           class="row">
        <label>{{ 'filter_in_field' | translate }}</label>
        <div class="form__select form__input--100 form__input--remove no-theme">
          <div>
            <ng-select [searchable]="false"
                       [clearable]="false"
                       (change)="propertyChanged(i, $event)"
                       test-id="searchFormFiltersDropdown">
              <ng-option *ngFor="let item of filters"
                         [value]="item">{{ item.label | translate }}</ng-option>
            </ng-select>
          </div>
          <button class="remove"
                  (click)="removeFilterGroup(i)">
            <div [inlineSVG]="'assets/img/icon-close.svg'"></div>
          </button>
        </div>
        <div class="form__input form__input--100 no-theme"
             *ngIf="getFilterDetail(i,'type') === 'text' || getFilterDetail(i,'type') === 'text-eq'">
          <label>{{ 'filter_searched_text' | translate }}</label>
          <input type="text"
                 formControlName="text"
                 test-id="searchFormSearchInput">
        </div>
        <div class="form__input form__input--100 no-theme"
             *ngIf="getFilterDetail(i,'type') === 'number'">
          <label>{{ 'filter_searched_text' | translate }}</label>
          <input type="number"
                 formControlName="text"
                 test-id="searchFormSearchInput">
        </div>
        <ng-container *ngIf="getFilterDetail(i,'type') as type">
          <div class="form__select form__input--100 no-theme"
               *ngIf="type === 'date' || type === 'date-future'">
            <label>{{ 'filter_in_time' | translate }}</label>
            <div>
              <ng-select [searchable]="false"
                         [clearable]="false"
                         (change)="dateRangeChanged(i, $event, getRange(type))"
                         test-id="searchFormTimeDropdown">
                <ng-option *ngFor="let range of getKeys(getRange(type))"
                           [value]="range">
                  {{ getRange(type)[range].label | translate }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="form.get(['filters',i,'rangeKey'])?.value === 'custom'">
        <div class="form__input no-theme">
            <p>{{ 'general.date_from_l' | translate }}:</p>
            <my-date-picker class="datepicker"
                            locale="pl"
                            placeholder="DD/MM/YYYY"
                            test-id="searchFormDateFromInput"
                            [options]="datePickerOptions"
                            (dateChanged)="dateRangeCustomChanged(i, 'start', $event)">
            </my-date-picker>
          </div>
          <div class="form__input no-theme">
            <p>{{ 'general.date_to_l' | translate }}:</p>
            <my-date-picker class="datepicker"
                            locale="pl"
                            placeholder="DD/MM/YYYY"
                            test-id="searchFormDateToInput"
                            [options]="datePickerOptions"
                            (dateChanged)="dateRangeCustomChanged(i, 'end', $event)">
            </my-date-picker>
          </div>
        </ng-container>
        <div class="form__select form__input--100 no-theme"
             *ngIf="getFilterDetail(i,'type') === 'select' || getFilterDetail(i,'type') === 'select-material'">
          <label>{{ 'filter_searched_text' | translate }}</label>
          <div>
            <ng-select *ngIf="getFilterDetail(i,'type') === 'select'"
                       [clearable]="false"
                       [searchable]="true"
                       formControlName="text">
              <ng-option [value]="item.value"
                         *ngFor="let item of getFilterDetail(i, 'values')">{{ item.name | translate }}</ng-option>
            </ng-select>
            <ng-select *ngIf="getFilterDetail(i,'type') === 'select-material'"
                       class="multiple"
                       [clearable]="false"
                       [hideSelected]="true"
                       formControlName="text">
              <ng-option *ngFor="let material of materials$ | async"
                         [value]="material | translateFromObject">
                {{ material | translateFromObject }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <p class="and"
           *ngIf="this.filtersControl.length > i + 1">{{ 'filter_and' | translate }}</p>
      </div>
      <button class="add"
              (click)="addFilterGroup()">{{ 'filter_add_next_level_of_filtering' | translate }}</button>
    </div>

    <div class="sortings"
         formArrayName="sortings">
      <div *ngFor="let item of sortingsGroups; let i = index"
           [formGroupName]="i"
           class="row">
        <label>{{ 'filter_sort_by' | translate }}</label>
        <div class="form__select form__input--100 form__input--remove no-theme">
          <div>
            <ng-select [searchable]="false"
                       [clearable]="false"
                       formControlName="property">
              <ng-option *ngFor="let item of sortings"
                         [value]="item.header">{{ item.description | translate }}</ng-option>
            </ng-select>
          </div>
          <button class="remove"
                  (click)="removeSortingGroup(i)">
            <div [inlineSVG]="'assets/img/icon-close.svg'"></div>
          </button>
        </div>
        <div class="form__select form__input--100 no-theme"
             *ngIf="checkSortingProperty(i)">
          <div>
            <ng-select [searchable]="false"
                       [clearable]="false"
                       formControlName="order">
              <ng-option value="ASC">{{ 'filter_sorting_ascending' | translate }}</ng-option>
              <ng-option value="DESC">{{ 'filter_sorting_descending' | translate }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <button class="add"
              (click)="addSortingGroup()">{{ 'filter_add_next_level_of_sorting' | translate }}</button>
    </div>
    <div class="form__select form__input--100 no-theme"
         *ngIf="possibleDateRange">
      <label>{{ 'filter_in_time' | translate }}</label>
      <div>
        <ng-select [searchable]="false"
                   [clearable]="false"
                   formControlName="dateRange"
                   [items]="rangeValues" bindLabel="label">
          <ng-template  ng-option-tmp ng-label-tmp let-range="item">
            {{ range.label | translate }}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="button-container">
      <button class="button"
              test-id="searchFormConfirmSearchButton"
              (click)="search()">{{ 'filter_search' | translate }}</button>
    </div>
  </form>
</div>
