import { Action } from '@app/state/interfaces';
import { ActionReducer } from '@ngrx/store';
import { AppState } from '@app/state';

export const CLEAR_STATE = 'CLEAR_STATE';

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export function clearState(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state: AppState, action: Action): AppState =>
    reducer(action.type === CLEAR_STATE ? undefined : state, action);
}
