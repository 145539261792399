import { ActionReducer } from '@ngrx/store';

import * as effects from './buildings.effect';
import * as reducers from './buildings.reducer';

import { Building } from '@app/state/interfaces';

export const buildingsEffects: any[] = [effects.BuildingsEffects];

export interface BuildingsState {
  buildings: Building[];
  building: Building;
  loaded: boolean;
  loading: boolean;
}

export const buildingsReducer: ActionReducer<BuildingsState> =
  reducers.buildingReducer;

export * from './buildings.action';
export * from './buildings.effect';
export * from './buildings.reducer';
export * from './buildings.selector';
