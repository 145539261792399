import { Component, Input } from '@angular/core';
import { User } from '@app/state/interfaces';
import { PermissionsEnum } from '@ppgt/web/shared/domain';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
  @Input() public user: User;

  get hasAcces(): boolean {
    return this.user && this.user.role.permits.includes(PermissionsEnum.DISPLAY_OWN_ACCOUNT);
  }
}
