import { ErrorHandler } from '@angular/core';
import StackdriverErrorReporter from 'stackdriver-errors-js';

import { environment } from '@ppgt/web/shared/config';

export class GlobalErrorHandler implements ErrorHandler {
  errorHandler!: StackdriverErrorReporter;

  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.errorHandler = new StackdriverErrorReporter();

      this.errorHandler.start({
        key: environment.gcpApiKey,
        projectId: environment.gcpProjectId,
        service: 'front',
      });
    });
  }

  handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    this.errorHandler.report(error);
  }
}
