import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentTypes } from '@app/core/http/interfaces';

@Pipe({ name: 'translateObjectType' })
export class TranslateObjectTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(typeName: string): string {
    const types = {
      [EquipmentTypes.Crane]: 'general.crane_l',
      [EquipmentTypes.Forklift]: 'general.forklift_l',
      [EquipmentTypes.Excavator]: 'general.excavator_l',
      [EquipmentTypes.MiniExcavator]: 'general.mini_excavator_l',
      [EquipmentTypes.MobileCrane]: 'general.mobile_crane_l',
      boomLoader: 'general.boom_loader_l',
      skidSteerLoader: 'general.skid_steer_loader_l',
      tractor: 'general.tractor_l'  ,
      trailer: 'general.trailer_l',
      hoist: 'general.hoist_l',
      elevator: 'general.elevator_l',
      depot: 'general.depot_l',
    };

    return types[typeName] ? this.translate.instant(types[typeName]) : typeName;
  }
}
