import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState, getSelectedDelivery, GetDelivery } from '@app/state';
import { Store } from '@ngrx/store';
import { tap, filter, map } from 'rxjs/operators';
import { get } from 'lodash-es';
import { DeliveriesService } from './deliveries.service';
import { config } from '@app/app.config';

@Injectable()
export class DeliveryGuard implements CanActivate {
  constructor(private store: Store<AppState>, private deliveriesService: DeliveriesService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const { id, restoreMode } = route.queryParams;
    const isId = (id && id.length) === config.idLength;
    const deliveryStream = () =>
      this.store.select(getSelectedDelivery).pipe(
        tap((delivery) => {
          if (get(delivery, 'id') === id) {
            const { status } = this.deliveriesService.getStatus(delivery.status);
            const deliveryDetailsOpts = {
              status,
              id,
              opened: true,
              type: delivery.typeDelivery,
              restoreMode,
            };

            this.deliveriesService.toggleDeliveryDetails(deliveryDetailsOpts);
          } else {
            this.store.dispatch(new GetDelivery({ id }));
          }
        }),
        map((delivery) => delivery && delivery.id === id),
        filter((correct) => correct)
      );

    return !isId || deliveryStream();
  }
}
