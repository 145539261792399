import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event']) clickListener(event) {
    this.onDocumentClick(event);
  }

  @Output()
  public clickOutside = new EventEmitter();

  public onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);

    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
