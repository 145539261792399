import { Action } from '@ngrx/store';
import { EquipmentReservation } from '@app/state/interfaces';

export const CREATE_EQUIPMENT_RESERVATION = 'CREATE_EQUIPMENT_RESERVATION';
export const CREATE_EQUIPMENT_RESERVATION_SUCCESS = 'CREATE_EQUIPMENT_RESERVATION_SUCCESS';
export const CREATE_EQUIPMENT_RESERVATION_FAIL = 'CREATE_EQUIPMENT_RESERVATION_FAIL';

export class CreateEquipmentReservation implements Action {
  readonly type = CREATE_EQUIPMENT_RESERVATION;

  constructor(public payload: { reservation: EquipmentReservation; duplicateAfter: boolean }) {}
}

export class CreateEquipmentReservationSuccess implements Action {
  readonly type = CREATE_EQUIPMENT_RESERVATION_SUCCESS;

  constructor(public payload: { reservation: EquipmentReservation }) {}
}

export class CreateEquipmentReservationFail implements Action {
  readonly type = CREATE_EQUIPMENT_RESERVATION_FAIL;
}

export const UPDATE_EQUIPMENT_RESERVATION = 'UPDATE_EQUIPMENT_RESERVATION';
export const UPDATE_EQUIPMENT_RESERVATION_SUCCESS = 'UPDATE_EQUIPMENT_RESERVATION_SUCCESS';
export const UPDATE_EQUIPMENT_RESERVATION_FAIL = 'UPDATE_EQUIPMENT_RESERVATION_FAIL';

export class UpdateEquipmentReservation implements Action {
  readonly type = UPDATE_EQUIPMENT_RESERVATION;

  constructor(public payload: { id: string; reservation: EquipmentReservation; duplicateAfter: boolean }) {}
}

export class UpdateEquipmentReservationSuccess implements Action {
  readonly type = UPDATE_EQUIPMENT_RESERVATION_SUCCESS;

  constructor(public payload: { reservation: EquipmentReservation }) {}
}

export class UpdateEquipmentReservationFail implements Action {
  readonly type = UPDATE_EQUIPMENT_RESERVATION_FAIL;
}

export const GET_EQUIPMENT_RESERVATION = 'GET_EQUIPMENT_RESERVATION';
export const GET_EQUIPMENT_RESERVATION_SUCCESS = 'GET_EQUIPMENT_RESERVATION_SUCCESS';
export const GET_EQUIPMENT_RESERVATION_FAIL = 'GET_EQUIPMENT_RESERVATION_FAIL';

export class GetEquipmentReservation implements Action {
  readonly type = GET_EQUIPMENT_RESERVATION;

  constructor(public payload: { reservationId: string }) {}
}

export class GetEquipmentReservationSuccess implements Action {
  readonly type = GET_EQUIPMENT_RESERVATION_SUCCESS;

  constructor(public payload: { reservation: EquipmentReservation }) {}
}

export class GetEquipmentReservationFail implements Action {
  readonly type = GET_EQUIPMENT_RESERVATION_FAIL;
}

export const DELETE_EQUIPMENT_RESERVATION = 'DELETE_EQUIPMENT_RESERVATION';
export const DELETE_EQUIPMENT_RESERVATION_SUCCESS = 'DELETE_EQUIPMENT_RESERVATION_SUCCESS';
export const DELETE_EQUIPMENT_RESERVATION_FAIL = 'DELETE_EQUIPMENT_RESERVATION_FAIL';

export class DeleteEquipmentReservation implements Action {
  readonly type = DELETE_EQUIPMENT_RESERVATION;

  constructor(public payload: { reservationId: string }) {}
}

export class DeleteEquipmentReservationSuccess implements Action {
  readonly type = DELETE_EQUIPMENT_RESERVATION_SUCCESS;

  constructor(public payload: { reservationId: string }) {}
}

export class DeleteEquipmentReservationFail implements Action {
  readonly type = DELETE_EQUIPMENT_RESERVATION_FAIL;
}

export type ReservationsActions =
  | CreateEquipmentReservation
  | CreateEquipmentReservationSuccess
  | CreateEquipmentReservationFail
  | GetEquipmentReservation
  | GetEquipmentReservationSuccess
  | GetEquipmentReservationFail
  | UpdateEquipmentReservation
  | UpdateEquipmentReservationSuccess
  | UpdateEquipmentReservationFail
  | DeleteEquipmentReservation
  | DeleteEquipmentReservationSuccess
  | DeleteEquipmentReservationFail;
