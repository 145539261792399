import { TableHeader } from './interfaces';
import { PermissionsEnum, UserRoles } from '@ppgt/web/shared/domain';
import { DeliveriesHeader } from '@app/deliveries/deliveries.service';

type TableHeaderKeys =
  | 'unloadingPlaces'
  | 'materials'
  | 'gates'
  | 'equipment'
  | 'depots'
  | 'elevators'
  | 'allSubcontractors'
  | 'singleUseSubcontractors'
  | 'longTermSubcontractors'
  | 'subcontractorsContracts'
  | 'allSuppliers'
  | 'settingsNotifications'
  | 'settingsUsers'
  | 'subcontractorContracts'
  | 'subcontracorVehicles'
  | 'supplierClients'
  | 'supplierProducts'
  | 'deliveriesObject'
  | 'reportsGeneral'
  | 'reportsStorage'
  | 'reportsDelays'
  | 'reportsUnrealized'
  | 'reportsBreeam'
  | 'dashboardEquipmentOperator'
  | 'manualModeDeliveries'
  | 'notifications'
  | 'dashboardTV'
  | 'constructionCrews';

export const tables: { [key in TableHeaderKeys | DeliveriesHeader]: TableHeader[] } = {
  unloadingPlaces: [
    {
      header: 'lp',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'shortName',
      description: 'config_header.short_name_l',
      sortPossible: true,
    },
    {
      header: 'hotspot',
      description: 'config_header.type_l',
      sortPossible: true,
    },
    {
      header: 'gateEnter.shortName',
      description: 'config_header.enter_gate_l',
      class: 'center',
      sortPossible: false,
    },
    {
      header: 'leaveGates',
      description: 'config_header.leave_gate_l',
      class: 'center',
      sortPossible: false,
    },
    {
      header: 'buildings',
      description: 'config_header.building_l',
      class: 'center',
      sortPossible: false,
    },
    {
      header: 'availableObjects',
      description: 'config_header.available_objects_l',
      class: 'center',
      sortPossible: false,
    },
    {
      header: 'scheduler',
      description: 'config_header.scheduler_l',
      class: 'center',
      sortPossible: false,
    },
  ],
  deliveryRuns: [
    {
      header: 'lp',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'unloadingDate',
      description: 'config_header.date_l',
      sortPossible: true,
    },
    {
      header: 'truck',
      description: 'config_header.truck_l',
      sortPossible: false,
    },
    {
      header: 'arrivedOnSite',
      description: 'config_header.on_site_time_l',
      sortPossible: false,
    },
    {
      header: 'unloadingDate',
      description: 'config_header.unloading_time_l',
      sortPossible: false,
    },
    {
      header: 'quantity',
      description: 'config_header.quantity',
      sortPossible: false,
    },
    {
      header: 'stage',
      description: 'config_header.assigned_to_stage_l',
      sortPossible: false,
    },
    {
      header: 'deliveryNoteId',
      description: 'deliveries_page.delivery_note_l',
      sortPossible: false,
    },
    {
      header: 'batchStart',
      description: 'config_header.batch_time_l',
      sortPossible: true,
    },
    {
      header: 'deliveryNotePhoto',
      description: 'config_header.delivery_note_photo_l',
      sortPossible: false,
      class: 'center',
    },
    {
      header: 'vehiclePhoto',
      description: 'config_header.vehicle_photo_l',
      sortPossible: false,
      class: 'center',
    },
    {
      header: 'cubeTests',
      description: 'deliveries_page.cube_test_l',
      sortPossible: false,
      class: 'center',
    },
    {
      header: 'pourCard',
      description: 'deliveries_page.pour_card_l',
      sortPossible: false,
      class: 'center',
    },
  ],
  materials: [
    {
      header: 'lp',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'translation.data',
      description: 'general.name_l',
      sortPossible: true,
    },
    { description: 'config_header.description_l' },
    {
      header: 'materialCategory->translation.data',
      description: 'deliveries_page.category_l',
      sortPossible: true,
    },
    { header: 'maxAmount', description: 'config_header.delivered_scheduled_total_l', sortPossible: true },
    {
      header: 'producer.name',
      description: 'deliveries_page.material_producer_l',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'general.subcontractor_l',
      requiredPermission: PermissionsEnum.MATERIALS_LIST_COMPANY_NAME_COLUMN_DISPLAY,
    },
    {
      header: 'marIdentifier',
      description: 'MAR ID',
      sortPossible: true,
    },
    {
      header: 'durability',
      description: 'general.inspection_l',
      sortPossible: true,
    },
  ],
  gates: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'shortName',
      description: 'config_header.short_name_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'enter',
      description: 'config_header.enter_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'leave',
      description: 'config_header.leave_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'user.fullName',
      description: 'config_header.responsible_l',
      sortPossible: true,
    },
    {
      header: 'street',
      description: 'config_header.street_l',
      sortPossible: true,
    },
  ],
  equipment: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'shortName',
      description: 'config_header.short_name_l',
      sortPossible: true,
    },
    {
      header: 'brand',
      description: 'config_header.brand_l',
      sortPossible: true,
    },
    {
      header: 'model',
      description: 'config_header.model_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'identifier',
      description: 'config_header.identifier_l',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'type',
      description: 'config_header.type_l',
      sortPossible: true,
    },
    {
      header: 'place.shortName',
      description: 'config_header.unloading_place_l',
      sortPossible: false,
    },
    {
      header: 'scheduler',
      description: 'config_header.scheduler_l',
      class: 'center',
      sortPossible: false,
    },
  ],
  depots: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'shortName',
      description: 'config_header.short_name_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'buildings',
      description: 'config_header.building_l',
      class: 'center',
      sortPossible: false,
    },
    {
      header: 'area',
      description: 'config_header.area_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'length',
      description: 'config_header.length_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'width',
      description: 'config_header.width_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'actualFree',
      description: 'config_header.actual_free_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'place.shortName',
      description: 'config_header.unloading_place_l',
      sortPossible: false,
    },
    {
      header: 'scheduler',
      description: 'config_header.scheduler_l',
      class: 'center',
      sortPossible: false,
    },
  ],
  elevators: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'shortName',
      description: 'config_header.short_name_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'maxCapacity',
      description: 'config_header.max_capacity_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'length',
      description: 'config_header.cab_length_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'width',
      description: 'config_header.cab_width_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'heightEnter',
      description: 'config_header.enter_height_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'widthEnter',
      description: 'config_header.enter_width_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'unloadingPlace',
      description: 'config_header.unloading_place_l',
      sortPossible: false,
    },
    {
      header: 'scheduler',
      description: 'config_header.scheduler_l',
      class: 'center',
      sortPossible: false,
    },
  ],
  allSubcontractors: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'companyName',
      description: 'config_header.company_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'longDeliveries',
      description: 'config_header.type_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'courses',
      description: 'deliveries_page.runs_l',
      class: 'center',
    },
    {
      header: 'subcontractorUser.user.fullName',
      description: 'config_header.contact_person_l',
      sortPossible: true,
    },
    {
      header: 'contact',
      description: 'config_header.contact_l',
      class: 'center',
    },
  ],
  singleUseSubcontractors: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'companyName',
      description: 'config_header.company_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'subcontractorUser.user.fullName',
      description: 'config_header.contact_person_l',
      sortPossible: true,
    },
    {
      header: 'contact',
      description: 'config_header.contact_l',
      class: 'center',
    },
  ],
  longTermSubcontractors: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'companyName',
      description: 'config_header.company_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'courses',
      description: 'deliveries_page.runs_l',
      class: 'center',
    },
    {
      header: 'subcontractorUser.user.fullName',
      description: 'config_header.contact_person_l',
      sortPossible: true,
    },
    {
      header: 'contact',
      description: 'config_header.contact_l',
      class: 'center',
    },
  ],
  subcontractorsContracts: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'startTime',
      description: 'config_header.start_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'endTime',
      description: 'config_header.end_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'active',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'contractNumber',
      description: 'deliveries_page.contract_no_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'supplier.name',
      description: 'deliveries_page.material_supplier_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
      class: 'center',
    },
    {
      header: 'amount',
      description: 'config_header.amount_delivered_all_l',
      class: 'center',
    },
    {
      header: 'courses',
      description: 'deliveries_page.runs_l',
      class: 'center',
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.company_l',
      sortPossible: true,
    },
    {
      header: 'subcontractor.subcontractorUser.user.fullName',
      description: 'config_header.contact_person_l',
      sortPossible: false,
    },
  ],
  allSuppliers: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'name',
      description: 'config_header.company_l',
      sortPossible: true,
    },
    {
      header: 'createdAt',
      description: 'config_header.add_date_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'supplierUser.fullName',
      description: 'config_header.contact_person_l',
      sortPossible: false,
    },
    {
      header: 'contact',
      description: 'config_header.contact_l',
      class: 'center',
    },
  ],
  settingsNotifications: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'reason',
      description: 'config_header.reason_l',
    },
    {
      header: 'receiver',
      description: 'config_header.receiver_l',
    },
    {
      header: 'from',
      description: 'config_header.from_l',
    },
    {
      header: 'type',
      description: 'config_header.notif_type_l',
      class: 'center',
    },
  ],
  settingsUsers: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'fullName',
      description: 'config_header.name_l',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.company_l',
      sortPossible: false,
    },
    {
      header: 'role.name',
      description: 'config_header.role_l',
      sortPossible: true,
    },
    {
      header: 'ownEquipment.shortName',
      description: 'config_materials.equipment_l',
      sortPossible: false,
      params: [UserRoles.EQUIPMENT_OPERATOR],
    },
    {
      header: 'user.gate',
      description: 'general.gate_l',
      sortPossible: false,
      class: 'center',
      params: [UserRoles.SECURITY_GUARD],
    },
    {
      header: 'active',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'lastActivity',
      description: 'config_header.activity_l',
      sortPossible: true,
    },
    {
      header: 'contact',
      description: 'config_header.contact_l',
      class: 'center',
      sortPossible: false,
    },
  ],
  subcontractorContracts: [
    {
      header: 'createdAt',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'startTime',
      description: 'config_header.start_date_l',
      sortPossible: true,
    },
    {
      header: 'endTime',
      description: 'config_header.end_date_l',
      sortPossible: true,
    },
    {
      header: 'active',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'contractNumber',
      description: 'deliveries_page.contract_no_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'supplier.name',
      description: 'general.supplier_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
      class: 'center',
    },
    {
      header: 'amount',
      description: 'config_header.amount_load_l',
      class: 'center',
      sortPossible: false,
    },
    {
      header: 'courses',
      description: 'deliveries_page.runs_l',
      class: 'center',
      sortPossible: false,
    },
  ],
  subcontracorVehicles: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'registrationNumber',
      description: 'config_header.registration_number_l',
      sortPossible: true,
    },
    {
      header: 'createdAt',
      description: 'config_header.add_date_l',
      sortPossible: true,
    },
    {
      header: 'courses',
      description: 'deliveries_page.runs_l',
      class: 'center',
      sortPossible: false,
    },
    {
      header: 'vehicle.generalType',
      description: 'config_header.type_l',
      sortPossible: true,
    },
    {
      header: 'vehicle.detailTypeVehicle',
      description: 'config_header.capacity_l',
      sortPossible: false,
    },
    {
      header: 'vehicle.co2',
      description: 'CO2/km',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_type_l',
    },
    {
      header: 'inflammable',
      description: 'config_header.inflammable_l',
      class: 'center',
      sortPossible: true,
    },
  ],
  constructionCrews: [
    {
      header: 'number',
      description: 'config_header.count_l',
    },
    {
      header: 'name',
      description: 'general.name_l',
    },
    {
      header: 'leader',
      description: 'config_header.leader_l',
    },
    {
      header: 'preferredEquipment',
      description: 'config_header.preferred_equipment',
    },
  ],
  supplierClients: [
    {
      header: 'id',
      description: 'config_header.count_l',
      sortPossible: false,
    },
    {
      header: 'companyName',
      description: 'config_header.client_name_l',
      sortPossible: true,
    },
    {
      header: 'contracts.contractNumber',
      description: 'deliveries_page.contract_no_l',
      sortPossible: true,
    },
    {
      header: 'materials.length',
      description: 'suppliers_page.products_count_l',
      sortPossible: true,
    },
    {
      header: 'subcontractorUser.user.fullName',
      description: 'config_header.contact_person_l',
      sortPossible: true,
    },
    {
      header: 'contact',
      description: 'config_header.contact_l',
      class: 'center',
    },
  ],
  supplierProducts: [
    {
      header: 'id',
      description: 'config_header.count_l',
      sortPossible: false,
    },
    {
      header: 'material.name',
      description: 'add_delivery.product_l',
      sortPossible: true,
    },
    {
      header: 'materialCategory',
      description: 'deliveries_page.category_l',
      sortPossible: false,
    },
    {
      header: 'material.producer',
      description: 'deliveries_page.material_producer_l',
      sortPossible: true,
    },
    {
      header: 'subcontractors.length',
      description: 'config_nav.suppliers.clients',
      sortPossible: false,
    },
    {
      header: 'material.deliveredMaterial',
      description: 'general.delivered_l',
      sortPossible: true,
    },
    {
      header: 'material.maxAmount',
      description: 'config_nav.deliveries.expected',
      sortPossible: true,
    },
  ],
  deliveriesAll: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.date_l',
      class: 'center',
      sortPossible: true,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_SUBCONTRACTOR_COMPANY_NAME_DISPLAY,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
    },
    {
      header: 'quantity',
      description: 'config_header.delivered_scheduled_amount_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_DELIVERY_QUANTITY_DISPLAY,
    },
    {
      header: 'remarks',
      description: 'config_header.remarks',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_REMARKS_DISPLAY,
    },
    {
      header: 'code',
      description: 'config_header.delivery_code_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'code',
      description: 'config_header.files_l',
      class: 'center',
      requiredPermission: PermissionsEnum.FILES_VISIBLE,
    },
  ],
  deliveriesExpectedOneTime: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_NUMBER_DISPLAY,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_TYPE_DETAIL_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_date_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_PLANNED_DATE_DISPLAY,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'supplier.name',
      description: 'deliveries_page.material_supplier_l',
      requiredPermission: PermissionsEnum.DELIVERIES_EXPECTED_SUPPLIER_NAME,
    },
    {
      header: 'place.shortName',
      description: 'config_header.place_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_PLACE_DISPLAY,
    },
    {
      header: 'gateEnter.shortName',
      description: 'config_header.enter_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_GATE_ENTER_DISPLAY,
    },
    {
      header: 'deliveryMaterials.material.translation',
      description: 'config_header.load_l',
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_LOAD_DISPLAY,
    },
    {
      header: 'quantity',
      description: 'delivery_card_page.amount_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY,
    },
    {
      header: 'code',
      description: 'config_header.delivery_code_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_CODE_DISPLAY,
    },
    {
      description: 'delivery_card_page.delivery_card_l',
      class: 'center',
    },
    {
      header: 'cancel',
      description: 'config_header.cancel_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_REJECT_ACTION,
    },
  ],
  deliveriesExpectedLongTerm: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_ID_DISPLAY,
    },
    {
      header: 'projectAndDeliveryId',
      description: 'ProjDeliveryID',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_PROJECT_AND_DELIVERY_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_ID_DISPLAY,
    },
    {
      header: 'contract.materialCategory.translation',
      description: 'deliveries_page.category_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CATEGORY_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_date_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_PLANNED_DATE_DISPLAY,
    },
    {
      header: 'durationTime',
      description: 'config_header.duration_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DURATION_TIME_DISPLAY,
    },
    {
      header: 'quantity',
      description: 'delivery_card_page.amount_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY,
    },
    {
      header: 'runs',
      description: 'deliveries_page.runs_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_RUNS_DISPLAY,
    },
    {
      header: 'stages',
      description: 'deliveries_page.stages_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_STAGES_DISPLAY,
    },
    // {
    //   header: 'unloadingTime',
    //   description: 'config_header.unloading_time_l',
    //   requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_UNLOADING_TIME_DISPLAY,
    // },
    {
      header: 'distanceAndTime',
      description: 'config_header.distance_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DISTANCE_AND_TIME_DISPLAY,
    },
    {
      header: 'supplier.name',
      description: 'deliveries_page.material_supplier_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUPPLIER_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'general.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'contactPerson',
      description: 'config_header.contact_person_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY,
    },
    {
      description: 'config_header.orders_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_ORDERS_SEND,
      class: 'center',
    },
    {
      header: 'deliveryCard',
      description: 'config_header.delivery_cards_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DELIVERY_CARDS_DISPLAY,
    },
  ],
  deliveriesTodayOneTime: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_NUMBER_DISPLAY,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_TYPE_DETAIL_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_time_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_PLANNED_TIME_DISPLAY,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'delivery.deliveryMaterials[0].supplier.name',
      description: 'deliveries_page.material_supplier_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_TODAY_TABLE_SUPPLIER_NAME,
    },
    {
      header: 'place.shortName',
      description: 'config_header.place_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_PLACE_DISPLAY,
    },
    {
      header: 'gateEnter.shortName',
      description: 'config_header.enter_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_GATE_ENTER_DISPLAY,
    },
    {
      header: 'deliveryMaterials.material.translation',
      description: 'config_header.load_l',
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_LOAD_DISPLAY,
    },
    {
      header: 'quantity',
      description: 'delivery_card_page.amount_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY,
    },
    {
      header: 'code',
      description: 'config_header.delivery_code_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_CODE_DISPLAY,
    },
    {
      description: 'delivery_card_page.delivery_card_l',
      class: 'center',
    },
    {
      header: 'cancel',
      description: 'config_header.cancel_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_REJECT_ACTION,
    },
    {
      header: 'allow',
      description: 'config_header.allow_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_ONETIME_TABLE_SET_MANUAL_ACTION,
    },
  ],
  deliveriesTodayLongTerm: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_ID_DISPLAY,
    },
    {
      header: 'projectAndDeliveryId',
      description: 'ProjDeliveryID',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_PROJECT_AND_DELIVERY_ID_DISPLAY,
    },
    {
      header: 'contract.materialCategory.translation',
      description: 'deliveries_page.category_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CATEGORY_DISPLAY,
    },
    {
      header: 'durationTime',
      description: 'config_header.duration_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DURATION_TIME_DISPLAY,
    },
    {
      header: 'quantity',
      description: 'delivery_card_page.amount_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY,
    },
    {
      header: 'runs',
      description: 'deliveries_page.runs_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_RUNS_DISPLAY,
    },
    {
      header: 'stages',
      description: 'deliveries_page.stages_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_STAGES_DISPLAY,
    },
    {
      header: 'unloadingTime',
      description: 'config_header.unloading_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_UNLOADING_TIME_DISPLAY,
    },
    {
      header: 'distanceAndTime',
      description: 'config_header.distance_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DISTANCE_AND_TIME_DISPLAY,
    },
    {
      header: 'delivery.deliveryMaterials[0].supplier.name',
      description: 'deliveries_page.material_supplier_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUPPLIER_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'general.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'contactPerson',
      description: 'config_header.contact_person_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY,
    },
    {
      header: 'call',
      description: 'config_header.call_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY,
    },
    {
      header: 'deliveryCard',
      description: 'config_header.delivery_cards_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DELIVERY_CARDS_DISPLAY,
    },
  ],
  deliveriesCurrently: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_NUMBER_DISPLAY,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      sortPossible: true,
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_TYPE_DETAIL_DISPLAY,
    },
    {
      header: 'registrationNumber',
      description: 'config_header.registration_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_REGISTRATION_NUMBER_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'deliveryMaterials.material.translation',
      description: 'config_header.load_l',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_LOAD_DISPLAY,
    },
    {
      header: 'quantity',
      description: 'delivery_card_page.amount_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY,
    },
    {
      header: 'place.shortName',
      description: 'config_header.place_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_PLACE_DISPLAY,
    },
    {
      header: 'equipment.shortName',
      description: 'config_header.equipment_l',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_EQUIPMENT_DISPLAY,
    },
    {
      header: 'depot.shortName',
      description: 'config_header.depot_l',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_DEPOT_DISPLAY,
    },
    {
      header: 'gateLeave.shortName',
      description: 'config_header.leave_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_GATE_LEAVE_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.time_hour_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_DATE_UNLOADING_DISPLAY,
    },
    {
      header: 'supplier.name',
      description: 'deliveries_page.material_supplier_l',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_SUPPLIER_NAME,
    },
    {
      header: 'call',
      description: 'config_header.call_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_CALL_ACTION,
    },
  ],
  deliveriesCurrentlyLongterm: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_ID_DISPLAY,
    },
    {
      header: 'projectAndDeliveryId',
      description: 'ProjDeliveryID',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_PROJECT_AND_DELIVERY_ID_DISPLAY,
    },
    {
      header: 'contract.materialCategory.translation',
      description: 'deliveries_page.category_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CATEGORY_DISPLAY,
    },
    {
      header: 'durationTime',
      description: 'config_header.duration_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DURATION_TIME_DISPLAY,
    },
    {
      header: 'runs',
      description: 'deliveries_page.runs_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_RUNS_DISPLAY,
    },
    {
      header: 'stages',
      description: 'deliveries_page.stages_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_STAGES_DISPLAY,
    },
    {
      header: 'unloadingTime',
      description: 'config_header.unloading_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_UNLOADING_TIME_DISPLAY,
    },
    {
      header: 'distanceAndTime',
      description: 'config_header.distance_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DISTANCE_AND_TIME_DISPLAY,
    },
    {
      header: 'supplier.name',
      description: 'deliveries_page.material_supplier_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUPPLIER_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'general.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'contactPerson',
      description: 'config_header.contact_person_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY,
    },
    {
      header: 'call',
      description: 'config_header.call_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY,
    },
    {
      header: 'deliveryCard',
      description: 'config_header.delivery_cards_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DELIVERY_CARDS_DISPLAY,
    },
  ],
  deliveriesToAccept: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_NUMBER_DISPLAY,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_TYPE_DETAIL,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_date_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_PLANNED_DATE_DISPLAY,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_time_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_PLANNED_TIME_DISPLAY,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'deliveryMaterials.material.translation',
      description: 'config_header.load_l',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_LOAD_DISPLAY,
    },
    {
      header: 'direction',
      description: 'config_header.direction_l',
      sortPossible: true,
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_DIRECTION_DISPLAY,
    },
    {
      header: 'place.shortName',
      description: 'config_header.place_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_PLACE_DISPLAY,
    },
    {
      header: 'gateLeave.shortName',
      description: 'config_header.enter_leave_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_GATE_LEAVE_DISPLAY,
    },
    {
      header: 'crane.shortName',
      description: 'config_header.equipment_l',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_EQUIPMENT_DISPLAY,
    },
    {
      header: 'vehicleSize.shortName',
      description: 'config_header.vehicle_size_l',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_EQUIPMENT_SIZE_DISPLAY,
    },
    {
      header: 'depot.shortName',
      description: 'config_header.depot_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_DEPOT_DISPLAY,
    },
    {
      header: 'cancel',
      description: 'config_header.cancel_l',
      class: 'align-right',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_CANCEL_ACTION,
    },
    {
      header: 'reject',
      description: 'config_header.reject_l',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_REJECT_ACTION,
    },
    {
      header: 'accept',
      description: 'config_header.accept_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_ACCEPT_TABLE_ACCEPT_ACTION,
    },
  ],
  deliveriesToComplete: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_NUMBER_DISPLAY,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_TYPE_DETAIL_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_date_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_PLANNED_DATE_DISPLAY,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_time_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_PLANNED_TIME_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'deliveryMaterials.material.translation',
      description: 'config_header.load_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_LOAD_DISPLAY,
    },
    {
      header: 'place.shortName',
      description: 'config_header.place_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_PLACE_DISPLAY,
    },
    {
      header: 'vehicle.generalType',
      description: 'general.vehicle_l',
      class: 'center',
      monitoringCo2Required: true,
    },
    {
      header: 'distanceFromPlace',
      description: 'config_header.distance_l',
      class: 'center',
      monitoringCo2Required: true,
    },
    {
      header: 'phoneDriver',
      description: 'config_header.contact_phone_short_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_PHONE_DRIVER_DISPLAY,
    },
    {
      header: 'registrationNumber',
      description: 'config_header.reg_number_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_REGISTRATION_NUMBER_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.time_left_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_TIME_LEFT_DISPLAY,
    },
    {
      header: 'cancel',
      description: 'config_header.cancel_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_CANCEL_ACTION,
    },
    {
      header: 'ask',
      description: 'config_header.ask_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_TO_COMPLETE_TABLE_ASK_ACTION,
    },
  ],
  deliveriesRejected: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_NUMBER_DISPLAY,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_TYPE_DETAIL_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_date_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_PLANNED_DATE_DISPLAY,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_PLANNED_TIME_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'deliveryMaterials.material.translation',
      description: 'config_header.load_l',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_LOAD_DISPLAY,
    },
    {
      header: 'direction',
      description: 'config_header.direction_l',
      sortPossible: true,
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_DIRECTION_DISPLAY,
    },
    {
      header: 'crane',
      description: 'config_header.crane_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_OBJECT_DISPLAY,
    },
    {
      header: 'elevator',
      description: 'config_header.elevator_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_OBJECT_DISPLAY,
    },
    {
      header: 'place',
      description: 'config_header.spot_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_OBJECT_DISPLAY,
    },
    {
      header: 'depot',
      description: 'config_header.depot_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_DEPOT_DISPLAY,
    },
    {
      header: 'miniExcavator',
      description: 'config_header.mini_excavator_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_OBJECT_DISPLAY,
    },
    {
      header: 'noData',
      description: 'config_header.no_data_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_NO_DATA_DISPLAY,
    },
    {
      header: 'restore',
      description: 'config_header.restore_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_REJECTED_TABLE_RESTORE_DISPLAY,
    },
  ],
  deliveriesCompleted: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_NUMBER_DISPLAY,
    },
    {
      header: 'typeDetail',
      description: 'config_header.type_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_TYPE_DETAIL_DISPLAY,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_STATUS_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_SUBCONTRACTOR_DISPLAY,
      testId: 'plannedTimeSortButton',
    },
    {
      header: 'entryDate',
      description: 'config_header.realization_time_l',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_REALIZATION_TIME_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_time_l',
      class: 'center',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_PLANNED_TIME_DISPLAY,
    },
    {
      header: 'deliveryMaterials.material.translation',
      description: 'config_header.load_l',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_LOAD_DISPLAY,
    },
    {
      header: 'quantity',
      description: 'config_header.delivered_scheduled_amount_l',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_DELIVERY_QUANTITY_DISPLAY,
    },
    {
      header: 'depot.shortName',
      description: 'config_header.depot_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_SHORT_NAME_DISPLAY,
    },
    {
      header: 'remarks',
      description: 'config_header.remarks',
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_REMARKS_DISPLAY,
    },
    {
      header: 'deliveryInspectionStatus',
      description: 'config_header.material_inspection_onsite_l',
      sortPossible: true,
      class: 'center',
      requiredPermission: PermissionsEnum.DELIVERIES_COMPLETED_TABLE_INSPECTION_STATUS_DISPLAY,
    },
  ],
  deliveriesCompletedLongterm: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      requiredPermission: PermissionsEnum.DELIVERIES_CURRENTLY_TABLE_ID_DISPLAY,
    },
    {
      header: 'projectAndDeliveryId',
      description: 'ProjDeliveryID',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_PROJECT_AND_DELIVERY_ID_DISPLAY,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_ID_DISPLAY,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.realization_date_l',
      sortPossible: true,
    },
    {
      header: 'contract.materialCategory.translation',
      description: 'deliveries_page.category_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CATEGORY_DISPLAY,
    },
    {
      header: 'quantity',
      description: 'delivery_card_page.amount_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY,
    },
    {
      header: 'runs',
      description: 'deliveries_page.runs_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_RUNS_DISPLAY,
    },
    {
      header: 'stages',
      description: 'deliveries_page.stages_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_STAGES_DISPLAY,
    },
    {
      header: 'unloadingTime',
      description: 'config_header.unloading_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_UNLOADING_TIME_DISPLAY,
    },
    {
      header: 'distanceAndTime',
      description: 'config_header.distance_time_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DISTANCE_AND_TIME_DISPLAY,
    },
    {
      header: 'contract.supplier.name',
      description: 'deliveries_page.material_supplier_l',
      sortPossible: false,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUPPLIER_DISPLAY,
    },
    {
      header: 'subcontractor.companyName',
      description: 'general.subcontractor_l',
      sortPossible: true,
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DISPLAY,
    },
    {
      header: 'call',
      description: 'config_header.call_l',
      requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY,
    },
    // {
    //   header: 'deliveryCard',
    //   description: 'config_header.delivery_cards_l',
    //   requiredPermission: PermissionsEnum.DELIVERIES_LONGTERM_TABLE_DELIVERY_CARDS_DISPLAY,
    // },
  ],
  deliveriesObject: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'registrationNumber',
      description: 'config_header.registration_l',
    },
    {
      header: 'dateUnloading',
      description: 'config_header.realization_date_l',
      sortPossible: true,
    },
    {
      header: 'entryDate',
      description: 'config_header.realization_time_l',
      sortPossible: true,
    },
    {
      header: 'entryDate',
      description: 'config_header.planned_time_l',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
    },
    {
      header: 'direction',
      description: 'config_header.direction_l',
      sortPossible: true,
      class: 'center',
    },
    {
      header: 'code',
      description: 'config_header.delivery_code_l',
      class: 'align-right',
      sortPossible: true,
    },
  ],
  reportsGeneral: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'typeDelivery',
      description: 'config_header.type_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'status',
      description: 'config_header.status_l',
      sortPossible: true,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_time_l',
      sortPossible: true,
    },
    {
      header: 'entryDate',
      description: 'config_header.real_delivery_time_l',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
    },
    {
      header: 'amount',
      description: 'config_header.delivered_scheduled_amount_l',
      sortPossible: false,
    },
    {
      header: 'place.shortName',
      description: 'config_header.place_l',
      sortPossible: true,
    },
    {
      header: 'building.shortName',
      description: 'config_header.building_l',
      sortPossible: true,
    },
    {
      header: 'depot.shortName',
      description: 'config_header.depot_l',
      sortPossible: true,
    },
    {
      header: 'remarks',
      description: 'config_header.remarks',
      class: 'center',
    },
  ],
  reportsStorage: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.reservation_date_l',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
    },
    {
      header: 'hour',
      description: 'config_header.time_hour_l',
      sortPossible: false,
    },
    {
      header: 'depot.shortName',
      description: 'config_header.depot_l',
      sortPossible: true,
    },
  ],
  reportsDelays: [
    {
      header: 'lp',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'registrationNumber',
      description: 'config_header.registration_l',
      sortPossible: true,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.planned_day_l',
      sortPossible: true,
    },
    {
      header: 'plannedTime',
      description: 'config_header.planned_time_l',
      sortPossible: false,
    },
    {
      header: 'entryDate',
      description: 'config_header.real_time_l',
      sortPossible: false,
    },
    {
      header: 'enterTime',
      description: 'config_header.enter_time_l',
      sortPossible: false,
    },
    {
      header: 'unloadingTime',
      description: 'config_header.unloading_time_l',
      sortPossible: false,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
    },
  ],
  reportsUnrealized: [
    {
      header: 'lp',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'unloadingTime',
      description: 'config_header.planned_day_l',
      sortPossible: true,
    },
    {
      header: 'plannedTime',
      description: 'config_header.planned_time_l',
      sortPossible: false,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
    },
    {
      header: 'status',
      description: 'config_header.cause_l',
      sortPossible: true,
    },
  ],
  reportsBreeam: [
    {
      header: 'lp',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: false,
    },
    {
      header: 'number',
      description: 'ID',
      sortPossible: true,
    },
    {
      header: 'registrationNumber',
      description: 'config_header.registration_number_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'subcontractor.companyName',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'dateUnloading',
      description: 'config_header.date_l',
      sortPossible: true,
    },
    {
      header: 'vehicle.generalType',
      description: 'config_header.type_l',
      sortPossible: true,
    },
    {
      header: 'distanceFromPlace',
      description: 'config_header.distance_l',
      sortPossible: true,
    },
    {
      header: 'co2',
      description: 'config_header.CO2_emission_l',
      class: 'align-right',
      sortPossible: true,
    },
  ],
  dashboardEquipmentOperator: [
    {
      header: 'id',
      description: 'ID',
    },
    {
      header: 'status',
      description: 'config_header.status_l',
    },
    {
      header: 'place',
      description: 'config_header.place_l',
    },
    {
      header: 'time',
      description: 'config_header.time_hour_l',
    },
    {
      header: 'depot.shortName',
      description: 'config_header.depot_l',
    },
  ],
  manualModeDeliveries: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
      sortPossible: true,
    },
    {
      header: 'number',
      description: 'config_header.number_l',
      sortPossible: true,
    },
    {
      header: 'plannedDate',
      description: 'config_header.planned_date_l',
    },
    {
      header: 'plannedTime',
      description: 'config_header.planned_time_l',
    },
    {
      header: 'subcontractor',
      description: 'config_header.subcontractor_l',
      sortPossible: true,
    },
    {
      header: 'material.translation',
      description: 'config_header.load_l',
    },
    {
      header: 'direction',
      description: 'config_header.direction_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'place',
      description: 'config_header.place_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'enter',
      description: 'config_header.enter_l',
      class: 'center',
    },
    {
      header: 'exit',
      description: 'config_header.leave_l',
      class: 'center',
      sortPossible: true,
    },
    {
      header: 'equipment',
      description: 'config_header.equipment_l',
      sortPossible: true,
    },
    {
      header: 'depot',
      description: 'config_header.depot_l',
      sortPossible: true,
    },
    {
      header: 'phone',
      description: 'config_header.phone_l',
      sortPossible: true,
    },
    {
      header: 'manualMode',
      description: 'config_header.manual_mode_l',
      class: 'align-right',
    },
  ],
  notifications: [
    {
      header: 'id',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'message',
      description: 'config_header.message_l',
    },
    {
      header: 'seen',
      description: 'config_header.seen_l',
      class: 'align-right',
    },
  ],
  dashboardTV: [
    {
      header: 'lp',
      description: 'config_header.count_l',
      class: 'id',
    },
    {
      header: 'updateDate',
      description: 'config_header.update_date_l',
    },
    {
      header: 'building',
      description: 'config_header.building_l',
    },
    {
      header: 'floor',
      description: 'deliveries_page.floor_l',
    },
    {
      header: 'spoil',
      description: 'config_header.spoil_l',
    },
    {
      header: 'spoilPercentage',
      description: 'config_header.of_spoil_l',
    },
    {
      header: 'concrete',
      description: 'config_header.concrete_l',
    },
    {
      header: 'concretePercentage',
      description: 'config_header.of_concrete_l',
    },
    {
      header: 'steel',
      description: 'config_header.steel_l',
    },
    {
      header: 'steelPercentage',
      description: 'config_header.of_steel_l',
    },
  ],
};
