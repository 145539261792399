import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { SearchService, SortingOrder } from '../search/search.service';
import { TableHeader } from '@app/config/interfaces';

@Directive({ selector: '[sort]' })
export class SortDirective {
  private classList: DOMTokenList;
  private item: TableHeader;

  @Input() public set sort(item: TableHeader) {
    this.item = item;

    if (item.class) {
      this.classList.add(item.class);
    }

    if (item.sortPossible) {
      this.classList.add('sortable');
    }

    if (this.item.order) {
      this.classList.add(this.item.order);
    }
  }

  constructor(private elementRef: ElementRef, private searchService: SearchService) {
    this.classList = this.elementRef.nativeElement.classList as DOMTokenList;
  }

  @HostListener('click')
  public sortAndAddClass() {
    if (!this.item.sortPossible) {
      return;
    }

    this.searchService.sort(this.item.header);

    this.classList.remove(SortingOrder.ASC);
    this.classList.remove(SortingOrder.DESC);

    if (this.item.order) {
      this.classList.add(this.item.order);
    }
  }
}
