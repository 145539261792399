import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDeliveries from './deliveries.reducer';
import { DeliveriesState } from '@app/state';

const stateSelector = createFeatureSelector<DeliveriesState>('deliveries');

export const getAllDeliveries = createSelector(
  stateSelector,
  fromDeliveries.getDeliveries,
);

export const getSelectedDelivery = createSelector(
  stateSelector,
  fromDeliveries.getDelivery,
);

export const getCheckDelivery = createSelector(
  stateSelector,
  fromDeliveries.getCheck,
);

export const getProposeDeliveries = createSelector(
  stateSelector,
  fromDeliveries.getPropose,
);

export const getDeliveriesCount = createSelector(
  stateSelector,
  fromDeliveries.getDeliveryCount,
);

export const getDeliveryLoadingSelector = createSelector(
  stateSelector,
  fromDeliveries.getDeliveryLoading,
);

export const getDeliveriesLoadingSelector = createSelector(
  stateSelector,
  fromDeliveries.getDeliveriesLoading,
);

export const getDeliveryFormFilled = createSelector(
  stateSelector,
  fromDeliveries.getDeliveryForm,
);

export const getDeliveryContacts = createSelector(
  stateSelector,
  fromDeliveries.getContacts,
);

export const getDeliveryUnloadingMethods = createSelector(
  stateSelector,
  fromDeliveries.getUnloadingMethods,
);

export const getDeliveryComments = createSelector(
  stateSelector,
  fromDeliveries.getComments,
);

export const getAllDistributors = createSelector(
  stateSelector,
  fromDeliveries.getDistributors,
);

export const getAllUnits = createSelector(
  stateSelector,
  fromDeliveries.getUnits,
);

export const selectLongtermDeliveries = createSelector(
  stateSelector,
  fromDeliveries.getLongtermDeliveries,
);

export const selectPourCard = createSelector(
  stateSelector,
  fromDeliveries.getPourCard,
);

export const selectCubeTestsPurposes = createSelector(
  stateSelector,
  fromDeliveries.getCubeTestsPurposes,
);

export const selectLongtermDeliveryTerms = createSelector(
  stateSelector,
  fromDeliveries.getLongtermDeliveryTerms,
);

export const selectDeliveryFile = createSelector(
  stateSelector,
  (state: DeliveriesState) => state.deliveryFile,
);
