import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ReportsState } from '.';

const stateSelector = createFeatureSelector<ReportsState>('reports');

export const getSelectedReport = createSelector(
  stateSelector,
  (state: ReportsState) => state.report,
);

export const getReportsCount = createSelector(
  stateSelector,
  (state: ReportsState) => state.totalObjectsCount,
);

export const getReportsLoading = createSelector(
  stateSelector,
  (state: ReportsState) => state.loading,
);
