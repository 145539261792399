import { Action } from '@ngrx/store';

export const GET_TASKS_IMPORT_SOURCE = 'GET_TASKS_IMPORT_SOURCE'
export const GET_TASKS_IMPORT_SOURCE_FAIL = 'GET_TASKS_IMPORT_SOURCE_FAIL'
export const GET_TASKS_IMPORT_SOURCE_SUCCESS = 'GET_TASKS_IMPORT_SOURCE_SUCCESS'

export const SET_TASKS_IMPORT_SOURCE = 'SET_TASKS_IMPORT_SOURCE'
export const SET_TASKS_IMPORT_SOURCE_FAIL = 'SET_TASKS_IMPORT_SOURCE_FAIL'
export const SET_TASKS_IMPORT_SOURCE_SUCCESS = 'SET_TASKS_IMPORT_SOURCE_SUCCESS'

export class GetTasksImportSource implements Action {
  readonly type = GET_TASKS_IMPORT_SOURCE;

  constructor(public payload?: null) {}
}

export class GetTasksImportSourceFail implements Action {
  readonly type = GET_TASKS_IMPORT_SOURCE_FAIL;

  constructor(public payload?: null) {}
}

export class GetTasksImportSourceSuccess implements Action {
  readonly type = GET_TASKS_IMPORT_SOURCE_SUCCESS;

  constructor(public payload: string) { }
}

export class SetTasksImportSource implements Action {
  readonly type = SET_TASKS_IMPORT_SOURCE;

  constructor(public payload?: string) {}
}

export class SetTasksImportSourceFail implements Action {
  readonly type = SET_TASKS_IMPORT_SOURCE_FAIL;

  constructor(public payload?: null) {}
}

export class SetTasksImportSourceSuccess implements Action {
  readonly type = SET_TASKS_IMPORT_SOURCE_SUCCESS;

  constructor(public payload: string) { }
}
