import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';
import * as fromAppState from '@app/state';

import * as actions from './equipment.action';
import { AlertService } from '@ppgt/web/shared/core';
import { MapService } from '@app/shared/map/map.service';

@Injectable()
export class EquipmentEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.EquipmentAction>,
    private alertService: AlertService,
    private mapService: MapService
  ) {}

  
  getEquipment$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_EQUIPMENT),
      map((action: actions.GetEquipment) => action.payload),
      switchMap(filters =>
        this.http.getEquipment(filters).pipe(
          map(res => new actions.GetEquipmentSuccess(res)),
          catchError(error => [new actions.GetEquipmentFail()])
        )
      )
    ));

  
  getSelectedEquipment$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_SELECTED_EQUIPMENT),
      map((action: actions.GetSelectedEquipment) => action.payload),
      switchMap(equipment =>
        this.http.getSelectedEquipment(equipment).pipe(
          map(res => new actions.GetSelectedEquipmentSuccess(res)),
          catchError(error => [new actions.GetSelectedEquipmentFail()])
        )
      )
    ));

  
  createEquipment$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_EQUIPMENT),
      map((action: actions.CreateEquipment) => action.payload),
      switchMap(equipment =>
        this.http.createEquipment(equipment).pipe(
          map(res => {
            this.alertService.showInfo('alert.object_added_l');
            this.mapService.unselectObject();
            return new actions.CreateEquipmentSuccess(res);
          }),
          catchError(error => [new actions.CreateEquipmentFail()])
        )
      )
    ));

  
  updateEquipment$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_EQUIPMENT),
      map((action: actions.UpdateEquipment) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...data } = payload;
        return this.http.updateEquipment(data).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_edited_l');
            return redirectAfter
              ? [
                  new actions.UpdateEquipmentSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/equipment'],
                    alertInfo: 'alert.object_edited_l',
                  }),
                ]
              : [new actions.UpdateEquipmentSuccess(res)];
          }),
          catchError(error => [new actions.UpdateEquipmentFail()])
        );
      })
    ));

  
  removeEquipment$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.REMOVE_EQUIPMENT),
      map((action: actions.RemoveEquipment) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...equipment } = payload;
        return this.http.removeEquipment(equipment).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_deleted_l');
            return redirectAfter
              ? [
                  new actions.RemoveEquipmentSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/equipment'],
                    alertInfo: 'alert.object_deleted_l',
                  }),
                ]
              : [new actions.RemoveEquipmentSuccess(res)];
          }),
          catchError(error => [new actions.RemoveEquipmentFail()])
        );
      })
    ));

  
  createEquipmentTime$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_EQUIPMENT_TIME),
      map((action: actions.CreateEquipmentTime) => action.payload),
      mergeMap(payload => this.http.createEquipmentTimes(payload.equipmentId, payload.times)
          .pipe(
            map(response => new actions.CreateEquipmentTimeSuccess(response)),
            catchError(() => [new actions.CreateEquipmentTimeFail()]),
          ))
    ));

  
  updateEquipmentTime$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_EQUIPMENT_TIME),
      map((action: actions.UpdateEquipmentTime) => action.payload),
      mergeMap(payload => this.http.updateEquipmentTimes(payload.equipmentId, payload.time)
          .pipe(
            map(response => new actions.UpdateEquipmentTimeSuccess(response)),
            catchError(() => [new actions.UpdateEquipmentTimeFail()]),
          ))
    ));

  
  deleteEquipmentTime$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.DELETE_EQUIPMENT_TIME),
      map((action: actions.DeleteEquipmentTime) => action.payload),
      mergeMap(payload => this.http.deleteEquipmentTime(payload.equipmentId, payload.id)
          .pipe(
            map((response) => new actions.DeleteEquipmentTimeSuccess(response)),
            catchError(() => [new actions.DeleteEquipmentTimeFail()]),
          ))
    ));

  
  getEquipmentTypes$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_EQUIPMENT_TYPES),
      switchMap(() => this.http.getEquipmentTypes()
          .pipe(
            map(types => new actions.GetEquipmentTypesSuccess({ types })),
            catchError(() => [new actions.GetEquipmentTypesFail()]),
          )),
    ));

  
  public getUnloadingUtilities$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_UNLOADING_UTILITIES),
      switchMap(() => this.http.getUnloadingUtilities()
          .pipe(
            map(utilities => new actions.GetUnloadingUtilitiesSuccess({ utilities })),
            catchError(() => [new actions.GetUnloadingUtilitiesFail()]),
          )),
    ));

  
  public getEquipmentBrands$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_EQUIPMENT_BRANDS),
      switchMap(() => this.http.getEquipmentBrands()
          .pipe(
            map(brands => new actions.GetEquipmentBrandsSuccess({ brands })),
            catchError(() => [new actions.GetEquipmentBrandsFail()]),
          )),
    ));

  
  public getEquipmentModels$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_EQUIPMENT_BRANDS),
      switchMap(() => this.http.getEquipmentModels()
          .pipe(
            map(models => new actions.GetEquipmentModelsSuccess({ models })),
            catchError(() => [new actions.GetEquipmentModelsFail()]),
          )),
    ));

  
  getEqupmentDeliveryDetails: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_EQUIPMENT_DELIVERIES_DETAILS),
      map((action: actions.GetEquipmentDeliveriesDetails) => action.payload),
      switchMap(equipment =>
        this.http.getEquipmentDeliveriesDetails(equipment).pipe(
          map(res => new actions.GetEquipmentDeliveriesDetailsSuccess(res)),
          catchError(error => [new actions.GetEquipmentDeliveriesDetailsFail()])
        )
      )
    ));
}
