import { isFunction } from 'lodash-es';
import { Notif } from '../interfaces';
import { NotificationsState } from './';
import * as actions from './notifications.action';

type ReducerFn = (
  state: NotificationsState,
  payload: any
) => NotificationsState;

const initialState: NotificationsState = {
  notifications: [],
  loading: false,
};

const reducerMap = {
  [actions.GET_NOTIFICATIONS]: (state: NotificationsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_NOTIFICATIONS_SUCCESS]: (
    state: NotificationsState,
    notifications: Notif[]
  ) => ({
    ...state,
    notifications,
    loading: false,
  }),
  [actions.ADD_NOTIFICATION]: (
    state: NotificationsState,
    notification: Notif
  ) => ({
    ...state,
    notifications: [notification, ...state.notifications],
  }),
  [actions.UPDATE_NOTIFICATION_SUCCESS]: (
    state: NotificationsState,
    notification: Notif
  ) => ({
    ...state,
    notifications: [
      ...state.notifications.filter(n => n.id !== notification.id),
    ],
  }),
  [actions.UPDATE_NOTIFICATIONS]: (state: NotificationsState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_NOTIFICATIONS_SUCCESS]: (state: NotificationsState) => ({
    ...state,
    notifications: [],
    loading: false,
  }),
};

export function notificationsReducer(
  state: NotificationsState = initialState,
  action: any
) {
  return isFunction(reducerMap[action.type])
    ? (reducerMap[action.type] as ReducerFn)(state, action.payload)
    : state;
}
