import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MapService } from '@app/shared/map/map.service';
import { config } from '@app/app.config';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as moment from 'moment-timezone';
import { environment } from '@env/environment';
import {
  ConstructionObject,
  EquipmentDeliveriesDetails,
  EquipmentType,
  ConstructionPlanDimensions,
} from '@app/state/interfaces';
import { PlanObjectSettings, objectTypeToRouteMap } from '@app/config';
import { Theme } from '@app/config/interfaces';
import {
  AppState,
  GetEquipmentDeliveriesDetails,
  GetEquipmentTypes,
  selectEquipmentDeliveriesDetails,
  selectEquipmentTypes,
} from '@app/state';
import { Store } from '@ngrx/store';
import { SchedulerService } from '@app/scheduler/scheduler.service';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { EquipmentTypes, MapObjectTypes } from '@app/core/http/interfaces';
import { Router } from '@angular/router';
import { sortBy } from 'lodash-es';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() objects: ConstructionObject[];
  @Input() editable = true;
  @Input() selectedObjectId: string;
  @Input() showLegend = true;
  @Input() id = 'drawing';
  @Input() theme: Theme | string;
  @Input() backgroundUrl: string;
  @Input() showTooltips = true;
  @Input() planDimensions: ConstructionPlanDimensions;

  @Output()
  openOnetimeDelivery: EventEmitter<EquipmentDeliveriesDetails> = new EventEmitter();

  defaultBackgroundUrl = '../../../assets/img/map.png';
  mapObjects: Record<string, unknown>[];
  isRendered = false;
  selectedObject: any = null;
  tooltipObject: any;
  tooltipPosition: { x: number; y: number };
  isTooltipHovered: boolean;
  // prettier-ignore
  letters: string[] = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
  ];
  xAxis = [...this.letters];
  yAxis: number[] = Array.from(Array(30).keys());
  objectsSettings: PlanObjectSettings[];
  mapObjectForTooltip: EquipmentDeliveriesDetails;
  EquipmentTypes = EquipmentTypes;
  MapObjectTypes = MapObjectTypes;
  tooltipTypesWithDeliveryButton: (EquipmentTypes | MapObjectTypes)[] = [
    EquipmentTypes.Crane,
    EquipmentTypes.Excavator,
    EquipmentTypes.MiniExcavator,
    EquipmentTypes.Forklift,
    EquipmentTypes.MobileCrane,
    EquipmentTypes.Elevator,
    EquipmentTypes.Other,
    MapObjectTypes.Place,
    MapObjectTypes.Depot,
  ];
  tooltipTypesWithoutAllocateButton: (EquipmentTypes | MapObjectTypes)[] = [MapObjectTypes.Place];
  types: EquipmentType[];
  backgroundStyle: { [property: string]: any } = {};
  private dimensionsFactorPx = 30;
  private destroy$ = new Subject<void>();

  constructor(
    private mapService: MapService,
    private store: Store<AppState>,
    private schedulerService: SchedulerService,
    private deliveriesService: DeliveriesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.objectsSettings = config.plan.objects[this.theme];
    this.getEquipmentTypes();
    this.setBackgroundStyle();
    this.setXYAxis();
  }

  ngOnChanges({ theme, planDimensions, backgroundUrl }: SimpleChanges) {
    if (theme && !theme.firstChange) {
      this.objectsSettings = config.plan.objects[this.theme];
      this.mapService.renderMap(this.objects, this.objectsSettings, this.selectedObjectId);
    }

    if (planDimensions?.currentValue) {
      this.mapService.updateDimensions(this.planDimensions);
      this.setXYAxis();
    }

    if (backgroundUrl) {
      this.setBackgroundStyle();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    if (!this.objects && this.isRendered) {
      return false;
    }

    const { id, editable } = this;
    this.mapService.initMap({ id, editable, size: { ...this.planDimensions } });
    this.mapService.renderMap(this.objects, this.objectsSettings, this.selectedObjectId);
    this.isRendered = true;

    this.mapService.selectedObject$.pipe(takeUntil(this.destroy$)).subscribe((item) => {
      this.selectedObject = item;
    });

    this.mapService.tooltipData$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        filter((tooltipData) => tooltipData && !this.isTooltipHovered)
      )
      .subscribe(({ mousePosition, object }) => {
        if (!this.showTooltips) {
          return;
        }

        if (this.tooltipObject && object.objectId === this.tooltipObject.objectId && this.mapObjectForTooltip) {
          return;
        }

        this.tooltipPosition =
          mousePosition && object.settings.size
            ? {
                x: mousePosition.x - object.settings.size.width / 2,
                y: mousePosition.y + object.settings.size.height,
              }
            : null;

        if (object.settings.type === MapObjectTypes.Depot) {
          const points = object.svg.node.getAttribute('points');
          const polygonCoordinate = this.mapService.getPolygonTextCoordinate(points);
          this.tooltipPosition = { x: polygonCoordinate[0] - 15, y: polygonCoordinate[1] + 30 };
        }

        this.tooltipObject = object;
        const allowTooltip = this.mapService.isTooltipAllowedForType(object.settings.type);

        if (allowTooltip) {
          let type = 'equipment';

          if ([MapObjectTypes.Depot, MapObjectTypes.Building].includes(object.settings.type)) {
            type = object.settings.type;
          }

          if (object.settings.type === MapObjectTypes.UnloadingPlace) {
            type = 'place';
          }

          this.store.dispatch(new GetEquipmentDeliveriesDetails({ id: this.tooltipObject.objectId, type }));
          this.mapObjectForTooltip = null;
        }
      });

    this.store
      .select(selectEquipmentDeliveriesDetails)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((object) => {
        this.mapObjectForTooltip = object;
        this.mapObjectForTooltip.combinedTerms = [
          ...this.mapObjectForTooltip.busyTerms,
          ...this.mapObjectForTooltip.freeTerms,
        ].sort((a, b) => new Date(a.expectedFrom).getTime() - new Date(b.expectedFrom).getTime());

        this.mapObjectForTooltip.combinedTerms.forEach((term) => {
          term.inCurrentTime = moment().isBetween(moment(term.expectedFrom), moment(term.expectedTo));
        });
      });
  }

  get dimensions() {
    return this.planDimensions || config.plan;
  }

  private getEquipmentTypes(): void {
    this.store.dispatch(new GetEquipmentTypes());
    this.store
      .select(selectEquipmentTypes)
      .pipe(
        takeUntil(this.destroy$),
        map((types) => sortBy(types, ['typeName'])),
        map((types) => types.filter((type) => !type.defaultEquipment))
      )
      .subscribe((types) => {
        this.types = types;
      });

    this.store.dispatch(new GetEquipmentTypes());
  }

  private setBackgroundStyle(): void {
    const backgroundUrl = this.backgroundUrl && environment.apiUrl + this.backgroundUrl;
    const defaultBackground = `url(${this.defaultBackgroundUrl})`;
    const backgroundFromUrl = `url(${backgroundUrl})`;

    const background = this.backgroundUrl ? `${defaultBackground}, ${backgroundFromUrl}` : defaultBackground;
    this.backgroundStyle = { background };
  }

  private setXYAxis(): void {
    const { width, height } = this.dimensions;
    const xAxisLength = Math.ceil(width / this.dimensionsFactorPx);
    const yAxisLength = Math.ceil(height / this.dimensionsFactorPx);

    this.xAxis = this.generateAxisLetters(xAxisLength);
    this.yAxis = Array.from(Array(yAxisLength).keys());
  }

  public selectObject() {
    const objectId = this.tooltipObject.objectId;
    const objectType = objectTypeToRouteMap[this.tooltipObject.settings.type];

    this.router.navigate([`/objects/${objectType}/${objectId}`]);
    this.mapService.selectObject(this.tooltipObject);
  }

  public draw(object: any) {
    if (this.selectedObject) {
      this.mapService.unselectObject(this.selectedObject);
    }

    if (object.svgType === 'image') {
      this.mapService.drawIcon(object);
      return false;
    }

    this.mapService.drawObject(object);
  }

  public isActive(object: any) {
    return (
      this.selectedObject &&
      object.type === this.selectedObject.settings.type &&
      (!object.orientation || object.orientation === this.selectedObject.settings.orientation)
    );
  }

  public setTooltipHover(isHovered: boolean) {
    this.isTooltipHovered = isHovered;

    if (!isHovered) {
      this.mapObjectForTooltip = null;
    }
  }

  public openEquipmentAside(equipmentId: string, equipmentType: string): void {
    this.schedulerService.openEquipmentReservationAside(null, { id: equipmentId, type: equipmentType });
  }

  public emitOpenOnetimeDelivery() {
    this.openOnetimeDelivery.emit(this.mapObjectForTooltip);
  }

  public openRejectAside(data: { deliveryId: string; deliveryNumber: number }): void {
    this.schedulerService.closeEquipmentReservationAside();

    this.deliveriesService.openRejectDeliveryAside({
      id: data.deliveryNumber,
      deliveryId: data.deliveryId,
      redirectAfter: false,
    });
  }

  public openOnetimeDetails(id: string): void {
    this.router.navigate(['deliveries', 'today', 'onetime'], { queryParams: { id } });
  }

  public openSubcontractorDetails(id: string): void {
    // @todo adjust after backend data
    this.router.navigate(['settings', 'subcontractor', id]);
  }

  private generateAxisLetters(length: number): string[] {
    const letters = [];
    let index = 0;
    let number = 0;

    while (letters.length <= length) {
      if (index >= this.letters.length) {
        index = 0;
        number++;
      }
      letters.push(`${this.letters[index]}${number || ''}`);
      index++;
    }

    return letters;
  }
}
