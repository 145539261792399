import { Action } from '@ngrx/store';
import { DeliveryStage, DispatchStageActionPayload, SendTruckPayload } from '../interfaces';
import { DeliveryRun } from '@interfaces';


export const GET_DELIVERY_STAGE = 'GET_DELIVERY_STAGE';
export const GET_DELIVERY_STAGE_FAIL = 'GET_DELIVERY_STAGE_FAIL';
export const GET_DELIVERY_STAGE_SUCCESS = 'GET_DELIVERY_STAGE_SUCCESS';

export class GetDeliveryStage implements Action {
  readonly type = GET_DELIVERY_STAGE;
  constructor(public payload: { stageId: string }) { }
}

export class GetDeliveryStageFail implements Action {
  readonly type = GET_DELIVERY_STAGE_FAIL;
  constructor(public payload: { error: any }) { }
}

export class GetDeliveryStageSuccess implements Action {
  readonly type = GET_DELIVERY_STAGE_SUCCESS;
  constructor(public payload: { deliveryStage: DeliveryStage }) { }
}


export const DISPATCH_STAGE_ACTION = 'DISPATCH_STAGE_ACTION';
export const DISPATCH_STAGE_ACTION_FAIL = 'DISPATCH_STAGE_ACTION_FAIL';
export const DISPATCH_STAGE_ACTION_SUCCESS = 'DISPATCH_STAGE_ACTION_SUCCESS';

export class DispatchStageAction implements Action {
  readonly type = DISPATCH_STAGE_ACTION;
  constructor(public payload: { dispatchPayload: DispatchStageActionPayload }) {}
}

export class DispatchStageActionFail implements Action {
  readonly type = DISPATCH_STAGE_ACTION_FAIL;
  constructor(public payload: { error: any }) { }
}

export class DispatchStageActionSuccess implements Action {
  readonly type = DISPATCH_STAGE_ACTION_SUCCESS;
  constructor(public payload: { updatedDeliveryStage: DeliveryStage }) { }
}

export const SEND_TRUCK = 'SEND_TRUCK';
export const SEND_TRUCK_FAIL = 'SEND_TRUCK_FAIL';
export const SEND_TRUCK_SUCCESS = 'SEND_TRUCK_SUCCESS';

export class SendTruck implements Action {
  readonly type = SEND_TRUCK;
  constructor(public payload: { sendTruckPayload: SendTruckPayload }) {}
}

export class SendTruckFail implements Action {
  readonly type = SEND_TRUCK_FAIL;
  constructor(public payload: { error: any }) {}
}

export class SendTruckSuccess implements Action {
  readonly type = SEND_TRUCK_SUCCESS;
  constructor(public payload: { updatedDeliveryStage: DeliveryStage }) {}
}

export type DeliveryStageActions = GetDeliveryStage
  | GetDeliveryStageFail
  | GetDeliveryStageSuccess
  | DispatchStageAction
  | DispatchStageActionFail
  | DispatchStageActionSuccess
  | SendTruck
  | SendTruckFail
  | SendTruckSuccess;
