import { isFunction } from 'lodash-es';

import * as actions from './places.action';
import { PlacesListState } from './';
import { UnloadingPlace, Action } from '@app/state/interfaces';

const initialState: PlacesListState = {
  places: [],
  totalObjectsCount: null,
  place: null,
  loaded: false,
  loading: false,
};

const reducerMap = {
  [actions.GET_PLACES]: (state: PlacesListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_PLACES_FAIL]: (state: PlacesListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_PLACES_SUCCESS]: (state: PlacesListState, res: any) => ({
    ...state,
    places: res.places,
    totalObjectsCount: res.meta.count,
    loading: false,
  }),
  [actions.GET_PLACE]: (state: PlacesListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_PLACE_FAIL]: (state: PlacesListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_PLACE_SUCCESS]: (state: PlacesListState, place: any) => ({
    ...state,
    place,
    loading: false,
  }),
  [actions.CREATE_PLACE]: (state: PlacesListState) => ({
    ...state,
    place: null,
    loading: true,
  }),
  [actions.CREATE_PLACE_FAIL]: (state: PlacesListState) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_PLACE_SUCCESS]: (state: PlacesListState, place: any) => ({
    ...state,
    places: [place, ...state.places],
    place,
    loading: false,
  }),
  [actions.UPDATE_PLACE]: (state: PlacesListState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_PLACE_FAIL]: (state: PlacesListState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_PLACE_SUCCESS]: (
    state: PlacesListState,
    place: UnloadingPlace
  ) => ({
    ...state,
    place,
    loading: false,
  }),
  [actions.REMOVE_PLACE]: (state: PlacesListState) => ({
    ...state,
    loading: true,
  }),
  [actions.REMOVE_PLACE_FAIL]: (state: PlacesListState) => ({
    ...state,
    loading: false,
  }),
  [actions.REMOVE_PLACE_SUCCESS]: (
    state: PlacesListState,
    place: UnloadingPlace
  ) => ({
    ...state,
    place: null,
    places: state.places.filter(item => item.id !== place.id),
    loading: false,
  }),
};

export function placesReducer(
  state: PlacesListState = initialState,
  action: Action
) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
