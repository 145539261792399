import { Action } from '@ngrx/store';

import {
  GetSubcontractorsRes,
  UpdateSubcontractorReq,
  QueryParamObject,
  CreateVehiclesReq,
  GetSubcontractorVehiclesRes,
  CreateSubcontractorBody,
} from '@app/core/http/interfaces';

import {
  Subcontractor,
  VehicleObj,
  Vehicle,
  SubcontractorsEmployee,
  SubcontractorsEmployeeRole,
  AssignEmployeeBody,
  User,
  MaterialSupplier
} from '../interfaces';
import { SupplierLocation } from '@interfaces';

// load all subcontractors
export const GET_SUBCONTRACTORS = 'GET_SUBCONTRACTORS';
export const GET_SUBCONTRACTORS_FAIL = 'GET_SUBCONTRACTORS_FAIL';
export const GET_SUBCONTRACTORS_SUCCESS = 'GET_SUBCONTRACTORS_SUCCESS';

export class GetSubcontractors implements Action {
  readonly type = GET_SUBCONTRACTORS;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetSubcontractorsFail implements Action {
  readonly type = GET_SUBCONTRACTORS_FAIL;
}

export class GetSubcontractorsSuccess implements Action {
  readonly type = GET_SUBCONTRACTORS_SUCCESS;

  constructor(public payload: GetSubcontractorsRes['data']) {}
}

// create subcontractor
export const CREATE_SUBCONTRACTOR = 'CREATE_SUBCONTRACTOR';
export const CREATE_SUBCONTRACTOR_FAIL = 'CREATE_SUBCONTRACTOR_FAIL';
export const CREATE_SUBCONTRACTOR_SUCCESS = 'CREATE_SUBCONTRACTOR_SUCCESS';

export class CreateSubcontractor implements Action {
  readonly type = CREATE_SUBCONTRACTOR;

  constructor(public payload: CreateSubcontractorBody) {}
}

export class CreateSubcontractorFail implements Action {
  readonly type = CREATE_SUBCONTRACTOR_FAIL;
}

export class CreateSubcontractorSuccess implements Action {
  readonly type = CREATE_SUBCONTRACTOR_SUCCESS;
}

// load selected subcontractor
export const GET_SUBCONTRACTOR = 'GET_SUBCONTRACTOR';
export const GET_SUBCONTRACTOR_FAIL = 'GET_SUBCONTRACTOR_FAIL';
export const GET_SUBCONTRACTOR_SUCCESS = 'GET_SUBCONTRACTOR_SUCCESS';

export class GetSubcontractor implements Action {
  readonly type = GET_SUBCONTRACTOR;

  constructor(public payload: { id: string }) {}
}

export class GetSubcontractorFail implements Action {
  readonly type = GET_SUBCONTRACTOR_FAIL;
}

export class GetSubcontractorSuccess implements Action {
  readonly type = GET_SUBCONTRACTOR_SUCCESS;

  constructor(public payload: Subcontractor) {}
}

// load all vehicles
export const GET_VEHICLES = 'GET_VEHICLES';
export const GET_VEHICLES_FAIL = 'GET_VEHICLES_FAIL';
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';

export class GetVehicles implements Action {
  readonly type = GET_VEHICLES;
}

export class GetVehiclesFail implements Action {
  readonly type = GET_VEHICLES_FAIL;
}

export class GetVehiclesSuccess implements Action {
  readonly type = GET_VEHICLES_SUCCESS;

  constructor(public payload: Vehicle[]) {}
}

// load subcontractor's vehicles
export const GET_SUBCONTRACTOR_VEHICLES = 'GET_SUBCONTRACTOR_VEHICLES';
export const GET_SUBCONTRACTOR_VEHICLES_FAIL = 'GET_SUBCONTRACTOR_VEHICLES_FAIL';
export const GET_SUBCONTRACTOR_VEHICLES_SUCCESS = 'GET_SUBCONTRACTOR_VEHICLES_SUCCESS';

export class GetSubcontractorVehicles implements Action {
  readonly type = GET_SUBCONTRACTOR_VEHICLES;

  constructor(public payload: { id: string; queries?: QueryParamObject[] }) {}
}

export class GetSubcontractorVehiclesFail implements Action {
  readonly type = GET_SUBCONTRACTOR_VEHICLES_FAIL;
}

export class GetSubcontractorVehiclesSuccess implements Action {
  readonly type = GET_SUBCONTRACTOR_VEHICLES_SUCCESS;

  constructor(public payload: GetSubcontractorVehiclesRes) {}
}

// create vehicle
export const CREATE_VEHICLES = 'CREATE_VEHICLES';
export const CREATE_VEHICLES_FAIL = 'CREATE_VEHICLES_FAIL';
export const CREATE_VEHICLES_SUCCESS = 'CREATE_VEHICLES_SUCCESS';

export class CreateVehicles implements Action {
  readonly type = CREATE_VEHICLES;

  constructor(public payload: CreateVehiclesReq) {}
}

export class CreateVehiclesFail implements Action {
  readonly type = CREATE_VEHICLES_FAIL;
}

export class CreateVehiclesSuccess implements Action {
  readonly type = CREATE_VEHICLES_SUCCESS;

  constructor(public payload: VehicleObj[]) {}
}

export const UPDATE_SUBCONTRACTOR = 'UPDATE_SUBCONTRACTOR';
export const UPDATE_SUBCONTRACTOR_FAIL = 'UPDATE_SUBCONTRACTOR_FAIL';
export const UPDATE_SUBCONTRACTOR_SUCCESS = 'UPDATE_SUBCONTRACTOR_SUCCESS';

export class UpdateSubcontractor implements Action {
  readonly type = UPDATE_SUBCONTRACTOR;

  constructor(public payload: UpdateSubcontractorReq) {}
}

export class UpdateSubcontractorFail implements Action {
  readonly type = UPDATE_SUBCONTRACTOR_FAIL;

  constructor(public error: any) {}
}

export class UpdateSubcontractorSuccess implements Action {
  readonly type = UPDATE_SUBCONTRACTOR_SUCCESS;

  constructor(public payload: Subcontractor) {}
}

export const GET_SUBCONTRACTOR_LOCATIONS = 'GET_SUBCONTRACTOR_LOCATIONS';
export const GET_SUBCONTRACTOR_LOCATIONS_FAIL = 'GET_SUBCONTRACTOR_LOCATIONS_FAIL';
export const GET_SUBCONTRACTOR_LOCATIONS_SUCCESS = 'GET_SUBCONTRACTOR_LOCATIONS_SUCCESS';

export class GetSubcontractorLocations implements Action {
  readonly type = GET_SUBCONTRACTOR_LOCATIONS;

  constructor(public payload: { subcontractorId: string }) {}
}

export class GetSubcontractorLocationsFail implements Action {
  readonly type = GET_SUBCONTRACTOR_LOCATIONS_FAIL;
}

export class GetSubcontractorLocationsSuccess implements Action {
  readonly type = GET_SUBCONTRACTOR_LOCATIONS_SUCCESS;

  constructor(public payload: SupplierLocation[]) {}
}

export const GET_SUBCONTRACTORS_EMPLOYEES = 'GET_SUBCONTRACTOR_EMPLOYEES';
export const GET_SUBCONTRACTORS_EMPLOYEES_SUCCESS = 'GET_SUBCONTRACTOR_EMPLOYEES_SUCCESS';
export const GET_SUBCONTRACTORS_EMPLOYEES_FAIL = 'GET_SUBCONTRACTOR_EMPLOYEES_FAIL';

export class GetSubcontractorsEmployees implements Action {
  readonly type = GET_SUBCONTRACTORS_EMPLOYEES;

  constructor(public payload: { subcontractorId: string }) {}
}

export class GetSubcontractorsEmployeesSuccess implements Action {
  readonly type = GET_SUBCONTRACTORS_EMPLOYEES_SUCCESS;

  constructor(public payload: SubcontractorsEmployee[]) {}
}

export class GetSubcontractorsEmployeesFail implements Action {
  readonly type = GET_SUBCONTRACTORS_EMPLOYEES_FAIL;
}

export const CREATE_SUBCONTRACTORS_EMPLOYEE = 'CREATE_SUBCONTRACTORS_EMPLOYEE';
export const CREATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS = 'CREATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS';
export const CREATE_SUBCONTRACTORS_EMPLOYEE_FAIL = 'CREATE_SUBCONTRACTORS_EMPLOYEE_FAIL';

export class CreateSubcontractorsEmployee implements Action {
  readonly type = CREATE_SUBCONTRACTORS_EMPLOYEE;

  constructor(public payload: {
    employee: SubcontractorsEmployee;
    subcontractorId: string;
    redirectAfter?: boolean;
  }) {}
}

export class CreateSubcontractorsEmployeeSuccess implements Action {
  readonly type = CREATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS;
}

export class CreateSubcontractorsEmployeeFail implements Action {
  readonly type = CREATE_SUBCONTRACTORS_EMPLOYEE_FAIL;
}

export const UPDATE_SUBCONTRACTORS_EMPLOYEE = 'UPDATE_SUBCONTRACTORS_EMPLOYEE';
export const UPDATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS = 'UPDATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS';
export const UPDATE_SUBCONTRACTORS_EMPLOYEE_FAIL = 'UPDATE_SUBCONTRACTORS_EMPLOYEE_FAIL';

export class UpdateSubcontractorsEmployee implements Action {
  readonly type = UPDATE_SUBCONTRACTORS_EMPLOYEE;

  constructor(public payload: { employee: SubcontractorsEmployee; subcontractorId: string }) {}
}

export class UpdateSubcontractorsEmployeeSuccess implements Action {
  readonly type = UPDATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS;

  constructor(public payload: User) {}
}

export class UpdateSubcontractorsEmployeeFail implements Action {
  readonly type = UPDATE_SUBCONTRACTORS_EMPLOYEE_FAIL;
}

export const ASSIGN_SUBCONTRACTORS_EMPLOYEE = 'ASSIGN_SUBCONTRACTORS_EMPLOYEE';
export const ASSIGN_SUBCONTRACTORS_EMPLOYEE_SUCCESS = 'ASSIGN_SUBCONTRACTORS_EMPLOYEE_SUCCESS';
export const ASSIGN_SUBCONTRACTORS_EMPLOYEE_FAIL = 'ASSIGN_SUBCONTRACTORS_EMPLOYEE_FAIL';

export class AssignSubcontractorsEmployee implements Action {
  readonly type = ASSIGN_SUBCONTRACTORS_EMPLOYEE;

  constructor(public payload: {
    subcontractorId: string;
    employeeId: string;
    body: AssignEmployeeBody;
    redirectAfter?: boolean;
  }) {}
}

export class AssignSubcontractorsEmployeeSuccess implements Action {
  readonly type = ASSIGN_SUBCONTRACTORS_EMPLOYEE_SUCCESS;

  constructor(public payload: SubcontractorsEmployee) {}
}

export class AssignSubcontractorsEmployeeFail implements Action {
  readonly type = ASSIGN_SUBCONTRACTORS_EMPLOYEE_FAIL;
}

export const GET_SUBCONTRACTORS_EMPLOYEE_ROLES = 'GET_SUBCONTRACTORS_EMPLOYEE_ROLES';
export const GET_SUBCONTRACTORS_EMPLOYEE_ROLES_SUCCESS = 'GET_SUBCONTRACTORS_EMPLOYEE_ROLES_SUCCESS';
export const GET_SUBCONTRACTORS_EMPLOYEE_ROLES_FAIL = 'GET_SUBCONTRACTORS_EMPLOYEE_ROLES_FAIL';

export class GetSubcontractorsEmployeeRoles implements Action {
  readonly type = GET_SUBCONTRACTORS_EMPLOYEE_ROLES;
}

export class GetSubcontractorsEmployeeRolesSuccess implements Action {
  readonly type = GET_SUBCONTRACTORS_EMPLOYEE_ROLES_SUCCESS;

  constructor(public payload: SubcontractorsEmployeeRole[]) {}
}

export class GetSubcontractorsEmployeeRolesFail implements Action {
  readonly type = GET_SUBCONTRACTORS_EMPLOYEE_ROLES_FAIL;
}

export const GET_SUBCONTRACTORS_SUPPLIERS = 'GET_SUBCONTRACTORS_SUPPLIERS';
export const GET_SUBCONTRACTORS_SUPPLIERS_SUCCESS = 'GET_SUBCONTRACTORS_SUPPLIERS_SUCCESS';
export const GET_SUBCONTRACTORS_SUPPLIERS_FAIL = 'GET_SUBCONTRACTORS_SUPPLIERS_FAIL';

export class GetSubcontractorsSuppliers implements Action {
  readonly type = GET_SUBCONTRACTORS_SUPPLIERS;

  constructor(public payload: { subcontractorId: string }) {}
}

export class GetSubcontractorsSuppliersSuccess implements Action {
  readonly type = GET_SUBCONTRACTORS_SUPPLIERS_SUCCESS;

  constructor(public payload: MaterialSupplier[]) {}
}

export class GetSubcontractorsSuppliersFail implements Action {
  readonly type = GET_SUBCONTRACTORS_SUPPLIERS_FAIL;
}

export type SubcontractorsAction =
  | GetSubcontractors
  | GetSubcontractorsFail
  | GetSubcontractorsSuccess
  | CreateSubcontractor
  | CreateSubcontractorFail
  | CreateSubcontractorSuccess
  | GetSubcontractor
  | GetSubcontractorFail
  | GetSubcontractorSuccess
  | GetVehicles
  | GetVehiclesFail
  | GetVehiclesSuccess
  | CreateVehicles
  | CreateVehiclesFail
  | CreateVehiclesSuccess
  | GetSubcontractorVehicles
  | GetSubcontractorVehiclesFail
  | GetSubcontractorVehiclesSuccess
  | UpdateSubcontractor
  | UpdateSubcontractorFail
  | UpdateSubcontractorSuccess
  | GetSubcontractorLocations
  | GetSubcontractorLocationsFail
  | GetSubcontractorLocationsSuccess
  | GetSubcontractorsEmployees
  | GetSubcontractorsEmployeesSuccess
  | GetSubcontractorsEmployeesFail
  | GetSubcontractorsEmployeeRoles
  | GetSubcontractorsEmployeeRolesSuccess
  | GetSubcontractorsEmployeeRolesFail
  | CreateSubcontractorsEmployee
  | CreateSubcontractorsEmployeeSuccess
  | CreateSubcontractorsEmployeeFail
  | AssignSubcontractorsEmployee
  | AssignSubcontractorsEmployeeSuccess
  | AssignSubcontractorsEmployeeFail
  | UpdateSubcontractorsEmployee
  | UpdateSubcontractorsEmployeeSuccess
  | UpdateSubcontractorsEmployeeFail
  | GetSubcontractorsSuppliers
  | GetSubcontractorsSuppliersSuccess
  | GetSubcontractorsSuppliersFail;
