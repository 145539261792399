import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  MATERIALS_FEATURE_KEY,
  MaterialsState
} from './materials.reducer'

const getMaterialsState = createFeatureSelector<MaterialsState>(MATERIALS_FEATURE_KEY)

export const getMaterials = createSelector(getMaterialsState, (state) => state.materials)

export const materialsQuery = {
  getMaterials,
}
