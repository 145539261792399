import { UserRoles } from '@ppgt/web/shared/domain';
import { Filtering } from './interfaces';
import { DeliveriesHeader } from '@app/deliveries/deliveries.service';

type FilteringKeys =
  | 'unloadingPlaces'
  | 'gates'
  | 'equipment'
  | 'depots'
  | 'materials'
  | 'manualModeDeliveries'
  | 'allSubcontractors'
  | 'singleUseSubcontractor'
  | 'longUseSubcontractor'
  | 'archiveSubcontractors'
  | 'subcontractorsContracts'
  | 'subcontractorContracts'
  | 'supplierClients'
  | 'supplierProducts'
  | 'subcontractorVehicles'
  | 'reportsGeneral'
  | 'reportsStorage'
  | 'reportsDelays'
  | 'reportsBREEAM'
  | 'reportsUnrealized'
  | 'users';

export const filtering: { [key in FilteringKeys]: Filtering[] } = {
  unloadingPlaces: [
    {
      property: 'shortName',
      label: 'objects_page.short_name_l',
      type: 'text',
    },
    {
      property: 'hotspot',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'objects_page.place_for_fast_deliveries_l', value: true },
        { name: 'objects_page.place_for_normal_deliveries_l', value: false },
      ],
    },
    {
      property: 'gateEnter.shortName',
      label: 'config_header.enter_gate_l',
      type: 'text',
    },
    {
      property: 'gateLeave.shortName',
      label: 'config_header.leave_gate_l',
      type: 'text',
    },
    {
      property: 'building.shortName',
      label: 'config_header.building_l',
      type: 'text',
    },
  ],
  gates: [
    {
      property: 'shortName',
      label: 'objects_page.short_name_l',
      type: 'text',
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        { name: 'general.notifications_active_l', value: true },
        { name: 'general.notifications_inactive_l', value: false },
      ],
    },
    {
      property: 'enter',
      label: 'general.entry_l',
      type: 'select',
      values: [
        { name: 'general.checkbox_yes_upper_letter_a', value: true },
        { name: 'general.checkbox_no_upper_letter_a', value: false },
      ],
    },
    {
      property: 'leave',
      label: 'general.departure_l',
      type: 'select',
      values: [
        { name: 'general.checkbox_yes_upper_letter_a', value: true },
        { name: 'general.checkbox_no_upper_letter_a', value: false },
      ],
    },
    {
      property: 'user.fullName',
      label: 'config_header.responsible_l',
      type: 'text',
    },
    {
      property: 'street',
      label: 'settings_page.construction_plan.street_name_l',
      type: 'text',
    },
  ],
  equipment: [
    {
      property: 'shortName',
      label: 'objects_page.short_name_l',
      type: 'text',
    },
    {
      property: 'brand',
      label: 'config_header.brand_l',
      type: 'text',
    },
    {
      property: 'model',
      label: 'config_header.model_l',
      type: 'text',
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        { name: 'general.notifications_active_l', value: true },
        { name: 'general.notifications_inactive_l', value: false },
      ],
    },
    {
      property: 'identifier',
      label: 'config_header.identifier_l',
      type: 'text',
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
    },
    {
      property: 'type',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.crane_l', value: 'crane' },
        { name: 'general.elevator_l', value: 'elevator' },
        { name: 'general.miniExcavator_l', value: 'miniExcavator' },
        { name: 'general.excavator_l', value: 'excavator' },
        { name: 'general.forklift_l', value: 'forklift' },
        { name: 'general.boom_loader_l', value: 'boomLoader' },
        { name: 'general.skid_steer_loader_l', value: 'skidSteerLoader' },
        { name: 'general.tractor_l', value: 'tractor' },
        { name: 'general.trailer_l', value: 'trailer' },
        { name: 'general.hoist_l', value: 'hoist' },
        { name: 'general.mobile_crane_l', value: 'mobileCrane' },
      ],
    },
    {
      property: 'place.shortName',
      label: 'general.unloading_place_l',
      type: 'text',
    },
  ],
  depots: [
    {
      property: 'shortName',
      label: 'objects_page.short_name_l',
      type: 'text',
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        { name: 'general.notifications_active_l', value: true },
        { name: 'general.notifications_inactive_l', value: false },
      ],
    },
    {
      property: 'building.shortName',
      label: 'config_header.building_l',
      type: 'text',
    },
    {
      property: 'area',
      label: 'config_header.area_l',
      type: 'text-eq',
    },
    {
      property: 'length',
      label: 'config_header.length_l',
      type: 'text-eq',
    },
    {
      property: 'width',
      label: 'config_header.width_l',
      type: 'text-eq',
    },
    {
      property: 'actualFree',
      label: 'config_header.actual_free_l',
      type: 'text-eq',
    },
    {
      property: 'place.shortName',
      label: 'general.unloading_place_l',
      type: 'text',
    },
  ],
  materials: [
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text-eq',
    },
  ],
  manualModeDeliveries: [
    {
      property: 'number',
      label: 'ID',
      type: 'text',
    },
    {
      property: 'dateUnloading',
      label: 'config_header.planned_date_l',
      type: 'text',
    },
    {
      property: 'subcontractor',
      label: 'config_header.subcontractor_l',
      type: 'text',
    },
    // {
    //   property: 'load',
    //   label: 'config_header.load_l',
    //   type: 'text',
    // },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
    },
    {
      property: 'place',
      label: 'config_header.place_l',
      type: 'text',
    },
    {
      property: 'equipment',
      label: 'config_header.equipment_l',
      type: 'text',
    },
    {
      property: 'depot',
      label: 'config_header.depot_l',
      type: 'text',
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
    },
  ],
  allSubcontractors: [
    {
      property: 'companyName',
      label: 'config_header.company_l',
      type: 'text',
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        { name: 'subcontractors_page.legend_active_l', value: true },
        { name: 'subcontractors_page.legend_archived_l', value: false },
      ],
    },
    {
      property: 'longDeliveries',
      label: 'config_header.longterm_l',
      type: 'select',
      values: [
        { name: 'deliveries_page.checkbox_yes_a', value: true },
        { name: 'deliveries_page.checkbox_no_a', value: false },
      ],
    },
    {
      property: 'user.fullName',
      label: 'config_header.contact_person_l',
      type: 'text',
    },
  ],
  singleUseSubcontractor: [
    {
      property: 'companyName',
      label: 'config_header.company_l',
      type: 'text',
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        { name: 'subcontractors_page.legend_active_l', value: true },
        { name: 'subcontractors_page.legend_archived_l', value: false },
      ],
    },
    {
      property: 'user.fullName',
      label: 'config_header.contact_person_l',
      type: 'text',
    },
  ],
  longUseSubcontractor: [
    {
      property: 'companyName',
      label: 'config_header.company_l',
      type: 'text',
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        { name: 'subcontractors_page.legend_active_l', value: true },
        { name: 'subcontractors_page.legend_archived_l', value: false },
      ],
    },
    {
      property: 'user.fullName',
      label: 'config_header.contact_person_l',
      type: 'text',
    },
  ],
  archiveSubcontractors: [
    {
      property: 'companyName',
      label: 'config_header.company_l',
      type: 'text',
    },
    {
      property: 'user.fullName',
      label: 'config_header.contact_person_l',
      type: 'text',
    },
    {
      property: 'longDeliveries',
      label: 'config_header.longterm_l',
      type: 'select',
      values: [
        { name: 'deliveries_page.checkbox_yes_a', value: true },
        { name: 'deliveries_page.checkbox_no_a', value: false },
      ],
    },
  ],
  subcontractorsContracts: [
    {
      property: 'startTime',
      label: 'config_header.start_l',
      type: 'date',
    },
    {
      property: 'endTime',
      label: 'config_header.end_l',
      type: 'date',
    },
    {
      property: 'active',
      label: 'config_header.status_l',
      type: 'select',
      values: [
        { name: 'general.notifications_active_l', value: true },
        { name: 'general.notifications_inactive_l', value: false },
      ],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.company_l',
      type: 'text',
    },
  ],
  subcontractorContracts: [
    {
      property: 'startTime',
      label: 'config_header.start_date_l',
      type: 'date',
    },
    {
      property: 'endTime',
      label: 'config_header.end_date_l',
      type: 'date',
    },
    {
      property: 'active',
      label: 'config_header.status_l',
      type: 'select',
      values: [
        { name: 'general.notifications_active_l', value: true },
        { name: 'general.notifications_inactive_l', value: false },
      ],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
    },
  ],
  subcontractorVehicles: [
    {
      property: 'registrationNumber',
      label: 'config_header.registration_number_l',
      type: 'text',
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
    },
  ],
  supplierClients: [
    {
      property: 'companyName',
      label: 'general.company_name_l',
      type: 'text',
    },
  ],
  supplierProducts: [
    {
      property: 'material.name',
      label: 'add_delivery.product_l',
      type: 'text',
    },
  ],
  reportsGeneral: [
    {
      property: 'number',
      label: 'ID',
      type: 'text-eq',
    },
    {
      property: 'typeDelivery',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'subcontractors_page.one_time_deliveries_l', value: 'onetime' },
        { name: 'subcontractors_page.long_time_deliveries_l', value: 'part' },
      ],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SECURITY_GUARD],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
    },
    {
      property: 'place.shortName',
      label: 'general.place_l',
      type: 'text',
    },
    {
      property: 'building.shortName',
      label: 'general.building_l',
      type: 'text',
    },
    {
      property: 'depot.shortName',
      label: 'config_header.depot_l',
      type: 'text',
    },
  ],
  reportsStorage: [
    {
      property: 'number',
      label: 'ID',
      type: 'text-eq',
    },
    {
      property: 'dateUnloading',
      label: 'config_header.reservation_date_l',
      type: 'date',
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SECURITY_GUARD],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
    },
    {
      property: 'depot.shortName',
      label: 'config_header.depot_l',
      type: 'text',
    },
  ],
  reportsDelays: [
    {
      property: 'number',
      label: 'ID',
      type: 'text-eq',
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_number_l',
      type: 'text',
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SECURITY_GUARD],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
    },
  ],
  reportsBREEAM: [
    {
      property: 'number',
      label: 'ID',
      type: 'text-eq',
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_number_l',
      type: 'text',
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SECURITY_GUARD],
    },
    {
      property: 'dateUnloading',
      label: 'config_header.date_l',
      type: 'date',
    },
    {
      property: 'distanceFromPlace',
      label: 'config_header.distance_l',
      type: 'text-eq',
    },
  ],
  reportsUnrealized: [
    {
      property: 'number',
      label: 'ID',
      type: 'text-eq',
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SECURITY_GUARD],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
    },
  ],
  users: [
    {
      property: 'fullName',
      label: 'config_header.name_l',
      type: 'text',
    },
    {
      property: 'active',
      label: 'Status',
      type: 'select',
      values: [
        { name: 'general.notifications_active_l', value: true },
        { name: 'general.notifications_inactive_l', value: false },
      ],
    },
  ],
};

export const filteringDeliveries: { [key in DeliveriesHeader]: Filtering[] } = {
  deliveriesCompleted: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_longtime', value: 'longtime' },
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        {
          name: 'deliveries_page.delivery_type_delivered_earlier_l',
          value: 'DONE_EARLIER',
        },
        {
          name: 'deliveries_page.delivery_type_delivered_l',
          value: 'DONE',
        },
        {
          name: 'deliveries_page.delivery_type_delivered_later_l',
          value: 'DONE_LATER',
        },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'dateUnloading',
      label: 'config_header.realization_date_l',
      type: 'date',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'depot.shortName',
      label: 'config_header.depot_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'deliveryInspectionStatus',
      label: 'config_header.material_inspection_onsite_l',
      values: [
        {
          name: 'inspection_material.delivery_material_status_for_inspection',
          value: 'FOR_INSPECTION',
        },
        { name: 'inspection_material.delivery_material_status_accepted', value: 'READY_TO_USE' },
        { name: 'inspection_material.delivery_material_status_rejected', value: 'FAILED' },
        { name: 'general.not_required', value: 'NOT_REQUIRED' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'code',
      label: 'config_header.delivery_code_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
  ],
  deliveriesCompletedLongterm: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_longtime', value: 'longtime' },
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'status',
      label: 'Status',
      type: 'select',
      values: [
        {
          name: 'deliveries_page.delivery_type_delivered_earlier_l',
          value: 'DONE_EARLIER',
        },
        {
          name: 'deliveries_page.delivery_type_delivered_l',
          value: 'DONE',
        },
        {
          name: 'deliveries_page.delivery_type_delivered_later_l',
          value: 'DONE_LATER',
        },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'dateUnloading',
      label: 'config_header.realization_date_l',
      type: 'date',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'depot.shortName',
      label: 'config_header.depot_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'deliveryInspectionStatus',
      label: 'config_header.material_inspection_onsite_l',
      values: [
        {
          name: 'inspection_material.delivery_material_status_for_inspection',
          value: 'FOR_INSPECTION',
        },
        { name: 'inspection_material.delivery_material_status_accepted', value: 'READY_TO_USE' },
        { name: 'inspection_material.delivery_material_status_rejected', value: 'FAILED' },
        { name: 'general.not_required', value: 'NOT_REQUIRED' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'code',
      label: 'config_header.delivery_code_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.SUBCONTRACTOR, UserRoles.TRAFFIC_MARSHALL],
    },
  ],
  deliveriesRejected: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_longtime', value: 'longtime' },
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'dateUnloading',
      label: 'config_header.planned_date_l',
      type: 'date',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveriesToComplete: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_longtime', value: 'longtime' },
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'dateUnloading',
      label: 'config_header.planned_date_l',
      type: 'date-future',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'place.shortName',
      label: 'config_header.place_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'phoneDriver',
      label: 'config_header.contact_phone_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveriesToAccept: [
    {
      property: 'number',
      label: 'config_header.number_l',
      type: 'text-eq',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      values: [
        { name: 'general.type_longtime', value: 'longtime' },
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'dateUnloading',
      label: 'config_header.planned_date_l',
      type: 'date-future',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'place.shortName',
      label: 'config_header.place_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'gateEnter.shortName',
      label: 'config_header.enter_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'gateLeave.shortName',
      label: 'config_header.leave_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveriesCurrently: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
        { name: 'general.type_longtime', value: 'longtime' },
      ],
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SECURITY_GUARD],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'place.shortName',
      label: 'config_header.place_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'equipment.shortName',
      label: 'config_header.equipment_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'depot.shortName',
      label: 'config_header.depot_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'gateLeave.shortName',
      label: 'config_header.leave_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'phoneDriver',
      label: 'config_header.driver_phone_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
  ],
  deliveriesCurrentlyLongterm: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
        { name: 'general.type_longtime', value: 'longtime' },
      ],
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SECURITY_GUARD],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'place.shortName',
      label: 'config_header.place_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'equipment.shortName',
      label: 'config_header.equipment_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'depot.shortName',
      label: 'config_header.depot_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'gateLeave.shortName',
      label: 'config_header.leave_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
    {
      property: 'phoneDriver',
      label: 'config_header.driver_phone_l',
      type: 'text',
      roles: [
        UserRoles.LOGISTIC_COORDINATOR,
        UserRoles.TRAFFIC_MARSHALL,
        UserRoles.SUBCONTRACTOR,
        UserRoles.SECURITY_GUARD,
      ],
    },
  ],
  deliveriesExpectedOneTime: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'place.shortName',
      label: 'config_header.place_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'gateEnter.shortName',
      label: 'config_header.enter_l',
      class: 'center',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'code',
      label: 'config_header.delivery_code_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveriesExpectedLongTerm: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveriesTodayOneTime: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'registrationNumber',
      label: 'config_header.registration_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'place.shortName',
      label: 'config_header.place_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'gateEnter.shortName',
      label: 'config_header.enter_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'materialCategory.translation.data',
      label: 'config_header.load_l',
      type: 'select-material',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'code',
      label: 'config_header.delivery_code_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveriesTodayLongTerm: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'direction',
      label: 'config_header.direction_l',
      values: [
        { name: 'general.entry_l', value: 'import' },
        { name: 'general.departure_l', value: 'export' },
        { name: 'general.both_l', value: 'both' },
      ],
      type: 'select',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveriesAll: [
    {
      property: 'number',
      label: 'ID',
      type: 'number',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'typeDetail',
      label: 'config_header.type_l',
      type: 'select',
      values: [
        { name: 'general.type_longtime', value: 'longtime' },
        { name: 'general.type_normal', value: 'onetime' },
        { name: 'general.type_emergency', value: 'emergency' },
        { name: 'general.type_fast', value: 'fast' },
      ],
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'dateUnloading',
      label: 'config_header.planned_date_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
    {
      property: 'subcontractor.companyName',
      label: 'config_header.subcontractor_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL],
    },
    {
      property: 'code',
      label: 'config_header.delivery_code_l',
      type: 'text',
      roles: [UserRoles.LOGISTIC_COORDINATOR, UserRoles.TRAFFIC_MARSHALL, UserRoles.SUBCONTRACTOR],
    },
  ],
  deliveryRuns: [],
};
