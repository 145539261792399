import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DasherizePipe } from './dasherize.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DasherizePipe],
  exports: [DasherizePipe],
})
export class DasherizeModule {}
