import { ActionReducer } from '@ngrx/store';

import * as effects from './roles.effect';
import * as reducers from './roles.reducer';

export const rolesEffects: any[] = [effects.RolesEffects];

export const rolesReducer: ActionReducer<reducers.RolesState> = reducers.rolesReducer;

export * from './roles.action';
export * from './roles.effect';
export * from './roles.reducer';
export * from './roles.selector';
