import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  TASKS_FEATURE_KEY,
  TasksState,
  SUBTASKS_FEATURE_KEY,
  SubtasksState,
} from './tasks.reducer'

const getTasksState = createFeatureSelector<TasksState>(TASKS_FEATURE_KEY)

export const getTasks = createSelector(getTasksState, (state) => state.tasks)

export const tasksQuery = {
  getTasks,
}

const getSubtasksState = createFeatureSelector<SubtasksState>(SUBTASKS_FEATURE_KEY)

export const getSubtasks = createSelector(getSubtasksState, (state) => state.subtasks)

export const getSubtasksById = (id) => createSelector(getSubtasksState, (state) => state[id])

export const subtasksQuery = {
  getSubtasksById,
  getSubtasks
}
