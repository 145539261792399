import { ActionReducer } from '@ngrx/store';

import * as effects from './subcontractors.effect';
import * as reducers from './subcontractors.reducer';

import {
  MaterialSupplier,
  Subcontractor,
  SubcontractorsEmployee, SubcontractorsEmployeeRole,
  Vehicle,
  VehicleObj
} from '@app/state/interfaces';
import { SupplierLocation } from '@interfaces';

export const subcontractorsEffects: any[] = [effects.SubcontractorsEffects];

export interface SubcontractorsState {
  subcontractors?: Subcontractor[];
  selectedSubcontractor?: Subcontractor;
  vehicles?: Vehicle[];
  subcontractorVehicles?: VehicleObj[];
  subcontractorPlaces?: SupplierLocation[];
  subcontractorEmployees: SubcontractorsEmployee[];
  subcontractorEmployeesRoles: SubcontractorsEmployeeRole[];
  subcontractorsSuppliers: MaterialSupplier[];
  subcontractorsLocations: SupplierLocation[];
  totalObjectsCount: number;
  loading: boolean;
}

export const subcontractorsReducer: ActionReducer<SubcontractorsState> =
  reducers.subcontractorsReducer;

export * from './subcontractors.action';
export * from './subcontractors.effect';
export * from './subcontractors.reducer';
export * from './subcontractors.selector';
