import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthState, getTokenDecoded, SetRedirectionLink } from '@app/state/auth';
import { ClearState } from '@app/state/clear-state';
import { Go } from '@app/state';
import { TokenDecoded } from '@app/core/http/interfaces';
import { config } from '@app/app.config';
import { CookieStorageService } from '@ppgt/web/shared/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    public cookieStorage: CookieStorageService,
    private router: Router,
    private store: Store<AuthState>
  ) {}

  isTokenValid(tokenDecoded: TokenDecoded) {
    return tokenDecoded.exp * 1000 > Date.now();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(getTokenDecoded).pipe(
      take(1),
      map((tokenDecoded) => {
        const queryParams = state.root.queryParams;
        const { redirectAfterLogin } = queryParams;
        const redirectUrl = state.url;

        if (tokenDecoded && this.isTokenValid(tokenDecoded)) {
          return true;
        }

        if (redirectAfterLogin) {
          this.store.dispatch(new SetRedirectionLink({ redirectUrl, queryParams }));
        }

        if (tokenDecoded && !this.isTokenValid(tokenDecoded)) {
          this.cookieStorage.remove(config.storageTokenKey);
          this.store.dispatch(new ClearState());
          this.store.dispatch(
            new Go({
              path: ['/login'],
              extras: { replaceUrl: true },
              alertError: 'alert.session_expired_l',
            }),
          );
          return false;
        }

        this.router.navigate(['/login'], { replaceUrl: true });
        return false;
      }),
    );
  }
}
