import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { directives } from '.';
import { PermissionAccessModule, RoleAccessModule } from '@ppgt/web/shared/directives';

@NgModule({
  imports: [CommonModule, PermissionAccessModule, RoleAccessModule],
  declarations: [...directives],
  exports: [...directives, PermissionAccessModule, RoleAccessModule],
})
export class DirectivesModule {}
