import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableLegendComponent } from './table-legend.component';
import { SvgModule } from '../svg-icon/svg.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TableLegendComponent],
  exports: [TableLegendComponent],
  imports: [CommonModule, SvgModule, InlineSVGModule, TranslateModule],
})
export class TableLegendModule {}
