import { createAction, props } from "@ngrx/store"
import { HttpErrorResponse } from "@angular/common/http"

import { Material } from "@ppgt/web/tasks/domain"

export enum Types {
  GetMaterials = '[Materials] Get Materials',
  GetMaterialsFail = '[Materials] Get Materials Fail',
  GetMaterialsSuccess = '[Materials] Get Materials Success',
  AssignMaterials = '[Materials] Assign Materials',
  AssignMaterialsFail = '[Materials] Assign Materials Fail',
  AssignMaterialsSuccess = '[Materials] Assign Materials Success',
}

export const getMaterials = createAction(Types.GetMaterials)

export const getMaterialsFail = createAction(
  Types.GetMaterialsFail,
  props<{ error: HttpErrorResponse }>()
)

export const getMaterialsSuccess = createAction(
  Types.GetMaterialsSuccess,
  props<{materials: Material[]}>()
)

export const assignMaterials = createAction(Types.AssignMaterials, props<{materials: { materialId: string; quantity: number; }[], taskParentId: string, taskChildId: string, subtaskId: string}>() )

export const assignMaterialsFail = createAction(
  Types.AssignMaterialsFail,
  props<{ error: HttpErrorResponse }>()
)

export const assignMaterialsSuccess = createAction(
  Types.AssignMaterialsSuccess,
  props<{ materials: Material[]; taskParentId: string; taskChildId: string, subtaskId: string }>()
);
