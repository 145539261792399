import { Action } from '@ngrx/store';

import {
  GetContractsRes,
  CreateContractReq,
  QueryParamObject,
  UpdateContractReq,
} from '@app/core/http/interfaces';
import { Contract } from '@app/state/interfaces';

// load contracts
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACTS_FAIL = 'GET_CONTRACTS_FAIL';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';

export class GetContracts implements Action {
  readonly type = GET_CONTRACTS;

  constructor(public payload: QueryParamObject[] = null) {}
}

export class GetContractsFail implements Action {
  readonly type = GET_CONTRACTS_FAIL;
}

export class GetContractsSuccess implements Action {
  readonly type = GET_CONTRACTS_SUCCESS;

  constructor(public payload: GetContractsRes['data']) {}
}

// load contracts
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACT_FAIL = 'GET_CONTRACT_FAIL';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';

export class GetContract implements Action {
  readonly type = GET_CONTRACT;

  constructor(public payload: { id: string }) {}
}

export class GetContractFail implements Action {
  readonly type = GET_CONTRACT_FAIL;
}

export class GetContractSuccess implements Action {
  readonly type = GET_CONTRACT_SUCCESS;

  constructor(public payload: Contract) {}
}

// load subcontractor's contracts
export const GET_SUBCONTRACTOR_CONTRACTS = 'GET_SUBCONTRACTOR_CONTRACTS';
export const GET_SUBCONTRACTOR_CONTRACTS_FAIL = 'GET_SUBCONTRACTOR_CONTRACTS_FAIL';
export const GET_SUBCONTRACTOR_CONTRACTS_SUCCESS = 'GET_SUBCONTRACTOR_CONTRACTS_SUCCESS';

export class GetSubcontractorContracts implements Action {
  readonly type = GET_SUBCONTRACTOR_CONTRACTS;

  constructor(public payload: { id: string; queries: QueryParamObject[] }) {}
}

export class GetSubcontractorContractsFail implements Action {
  readonly type = GET_SUBCONTRACTOR_CONTRACTS_FAIL;
}

export class GetSubcontractorContractsSuccess implements Action {
  readonly type = GET_SUBCONTRACTOR_CONTRACTS_SUCCESS;

  constructor(public payload: GetContractsRes['data']) {}
}

// create contracts
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const CREATE_CONTRACT_FAIL = 'CREATE_CONTRACT_FAIL';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';

export class CreateContract implements Action {
  readonly type = CREATE_CONTRACT;

  constructor(public payload: CreateContractReq) {}
}

export class CreateContractFail implements Action {
  readonly type = CREATE_CONTRACT_FAIL;
}

export class CreateContractSuccess implements Action {
  readonly type = CREATE_CONTRACT_SUCCESS;

  constructor(public payload: Contract) {}
}

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_FAIL = 'UPDATE_CONTRACT_FAIL';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';

export class UpdateContract implements Action {
  readonly type = UPDATE_CONTRACT;

  constructor(public payload: UpdateContractReq) {}
}

export class UpdateContractFail implements Action {
  readonly type = UPDATE_CONTRACT_FAIL;
}

export class UpdateContractSuccess implements Action {
  readonly type = UPDATE_CONTRACT_SUCCESS;

  constructor(public payload: GetContractsRes) {}
}

export type ContractsAction =
  | GetContracts
  | GetContractsFail
  | GetContractsSuccess
  | GetSubcontractorContracts
  | GetSubcontractorContractsFail
  | GetSubcontractorContractsSuccess
  | CreateContract
  | CreateContractFail
  | CreateContractSuccess
  | GetContract
  | GetContractFail
  | GetContractSuccess
  | UpdateContract
  | UpdateContractSuccess
  | UpdateContractFail;
