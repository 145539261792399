export const cargoType: {
  value: string;
  translation: string;
  capacity?: number;
  unit?: string;
  import?: boolean;
  export?: boolean;
}[] = [
  {
    value: 'Reinforcement accessories',
    translation: 'config_materials.reinforcement_accessories_l',
  },
  {
    value: 'Concrete',
    translation: 'config_materials.concrete_l',
    capacity: 8,
    unit: 'm3',
    import: true,
    export: false,
  },
  { value: 'H&S', translation: 'config_materials.h&s_l' },
  { value: 'Office', translation: 'config_materials.office_l' },
  { value: 'Cement', translation: 'config_materials.cement_l' },
  { value: 'Door', translation: 'config_materials.door_l' },
  { value: 'Crew', translation: 'config_materials.crew_l' },
  {
    value: 'Concrete elements',
    translation: 'config_materials.concrete_elements_l',
  },
  {
    value: 'Steel elements',
    translation: 'config_materials.steel_elements_l',
  },
  { value: 'H&S elements', translation: 'config_materials.h&s_elements_l' },
  {
    value: 'Technical gases',
    translation: 'config_materials.technical_gases_l',
  },
  { value: 'Containers', translation: 'config_materials.containers_l' },
  {
    value: 'Building materials',
    translation: 'config_materials.building_materials_l',
  },
  {
    value: 'Elektrotechnical materials',
    translation: 'config_materials.elektrotechnical_materials_l',
  },
  {
    value: 'Insulation materials',
    translation: 'config_materials.insulation_materials_l',
  },
  {
    value: 'Gardening materials',
    translation: 'config_materials.gardening_materials_l',
  },
  {
    value: 'Glass materials',
    translation: 'config_materials.glass_materials_l',
  },
  {
    value: 'Water & sewage materials',
    translation: 'config_materials.water_&_sewage_materials_l',
  },
  {
    value: 'Waste',
    translation: 'config_materials.waste_l',
    import: false,
    export: true,
  },
  { value: 'Windows', translation: 'config_materials.windows_l' },
  { value: 'Devices', translation: 'config_materials.devices_l' },
  { value: 'Fuel', translation: 'config_materials.fuel_l' },
  { value: 'MON plates', translation: 'config_materials.mon_plates_l' },
  { value: 'Concrete pump', translation: 'config_materials.concrete_pump_l' },
  { value: 'Prefabs', translation: 'config_materials.prefabs_l' },
  { value: 'Scaffolding', translation: 'config_materials.scaffolding_l' },
  { value: 'Service', translation: 'config_materials.service_l' },
  {
    value: 'Reinforcing steel',
    translation: 'config_materials.reinforcing_steel_l',
  },
  { value: 'Formwork', translation: 'config_materials.formwork_l' },
  { value: 'Plank', translation: 'config_materials.plank_l' },
  { value: 'Toilets', translation: 'config_materials.toilets_l' },
  {
    value: 'Dredging spoils',
    translation: 'config_materials.dredging_spoils_l',
  },
  { value: 'Equipment', translation: 'config_materials.equipment_l' },
  {
    value: 'Office equipment',
    translation: 'config_materials.office_equipment_l',
  },
  {
    value: 'Office supplies',
    translation: 'config_materials.office_supplies_l',
  },
  {
    value: 'Magazine supplies',
    translation: 'config_materials.magazine_supplies_l',
  },
  { value: 'Betonite', translation: 'config_materials.betonite_l' },
  { value: 'Bricks', translation: 'config_materials.bricks_l' },
  { value: 'Cables copper', translation: 'config_materials.cables_cooper_l' },
  {
    value: 'Cables fiber-optics',
    translation: 'config_materials.cables_fiber_optics_l',
  },
  { value: 'Gravel', translation: 'config_materials.gravel_l' },
  {
    value: 'Net insulation',
    translation: 'config_materials.net_insulation_l',
  },
  { value: 'Oil', translation: 'config_materials.oil_l' },
  { value: 'Sand', translation: 'config_materials.sand_l' },
  { value: 'Pipes GI', translation: 'config_materials.pipes_gi_l' },
  { value: 'Pipes iron', translation: 'config_materials.pipes_iron_l' },
  { value: 'Pipes PVC', translation: 'config_materials.pipes_pvc_l' },
  {
    value: 'Power generator',
    translation: 'config_materials.power_generator_l',
  },
  {
    value: 'Prefabricated walls',
    translation: 'config_materials.prefabricated_walls_l',
  },
  { value: 'Rubble', translation: 'config_materials.rubble_l' },
  { value: 'Spare parts', translation: 'config_materials.spare_parts_l' },
  {
    value: 'Steel rods',
    translation: 'config_materials.steel_rods_l',
    capacity: 20,
    unit: 'ton',
    import: true,
    export: false,
  },
  { value: 'Water', translation: 'config_materials.water_l' },
  { value: 'Cargo', translation: 'config_materials.cargo_l' },
  { value: 'Cables copper', translation: 'config_materials.cables_cooper_l' },
  { value: 'Furniture', translation: 'config_materials.furniture_l' },
  { value: 'Airbrick', translation: 'config_materials.airbrick_l' },
  { value: 'Bolt', translation: 'config_materials.bolt_l' },
  { value: 'Bracket', translation: 'config_materials.bracket_l' },
  { value: 'Cantilever', translation: 'config_materials.cantilever_l' },
  { value: 'Caulking', translation: 'config_materials.caulking_l' },
  {
    value: 'Cement & Aggregates',
    translation: 'config_materials.cement_aggregates_l',
  },
  { value: 'Clay', translation: 'config_materials.clay_l' },
  { value: 'Clinker', translation: 'config_materials.clinker_l' },
  {
    value: 'Concrete Lintels',
    translation: 'config_materials.concrete_lintels_l',
  },
  { value: 'Console', translation: 'config_materials.console_l' },
  { value: 'Crew', translation: 'config_materials.crew_l' },
  { value: 'Dry mortar', translation: 'config_materials.dry_mortar_l' },
  {
    value: 'Electrical outlet',
    translation: 'config_materials.electrical_outlet_l',
  },
  { value: 'Ferrule', translation: 'config_materials.ferrule_l' },
  { value: 'Fiber', translation: 'config_materials.fiber_l' },
  {
    value: 'Finishing strip',
    translation: 'config_materials.finishing_strip_l',
  },
  { value: 'Floor heating', translation: 'config_materials.floor_heating_l' },
  {
    value: 'Galvanised Steel Stud Wall',
    translation: 'config_materials.galvanised_steel_stud_wall_l',
  },
  { value: 'Gasper', translation: 'config_materials.gasper_l' },
  { value: 'Geomembrane', translation: 'config_materials.geomembrane_l' },
  { value: 'Glaze', translation: 'config_materials.glaze_l' },
  {
    value: 'Glaze adhesive',
    translation: 'config_materials.glaze_adhesive_l',
  },
  { value: 'Gravel roofs', translation: 'config_materials.gravel_roofs_l' },
  { value: 'Gypsum', translation: 'config_materials.gypsum_l' },
  { value: 'H&S', translation: 'config_materials.h_s_l' },
  { value: 'H&S elements', translation: 'config_materials.h_s_elements_l' },
  { value: 'Insulation', translation: 'config_materials.insulation_l' },
  { value: 'Jamb', translation: 'config_materials.jamb_l' },
  { value: 'Joint', translation: 'config_materials.joint_l' },
  { value: 'Mineral wool', translation: 'config_materials.mineral_wool_l' },
  { value: 'Paint', translation: 'config_materials.paint_l' },
  { value: 'Peg', translation: 'config_materials.peg_l' },
  { value: 'Pin', translation: 'config_materials.pin_l' },
  { value: 'Plank', translation: 'config_materials.plank_l' },
  { value: 'Plaster', translation: 'config_materials.plaster_l' },
  { value: 'Plasterboard', translation: 'config_materials.plasterboard_l' },
  {
    value: 'Plastering Tools',
    translation: 'config_materials.plastering_tools_l',
  },
  { value: 'Plastics', translation: 'config_materials.plastics_l' },
  {
    value: 'Reinforcement accessories',
    translation: 'config_materials.reinforcement_accessories_l',
  },
  { value: 'Rod', translation: 'config_materials.rod_l' },
  { value: 'Sandpaper', translation: 'config_materials.sandpaper_l' },
  { value: 'Screw', translation: 'config_materials.screw_l' },
  { value: 'Screwdriver', translation: 'config_materials.screwdriver_l' },
  {
    value: 'Sheet Materials',
    translation: 'config_materials.sheet_materials_l',
  },
  { value: 'Spade', translation: 'config_materials.spade_l' },
  { value: 'Spatula', translation: 'config_materials.spatula_l' },
  { value: 'Spike', translation: 'config_materials.spike_l' },
  { value: 'Spoil', translation: 'config_materials.spoil_l' },
  { value: 'Sprit', translation: 'config_materials.sprit_l' },
  { value: 'Stairs', translation: 'config_materials.stairs_l' },
  { value: 'Stave', translation: 'config_materials.stave_l' },
  { value: 'Switch', translation: 'config_materials.switch_l' },
  { value: 'Tar paper', translation: 'config_materials.tar_paper_l' },
  { value: 'Tiles', translation: 'config_materials.tiles_l' },
  { value: 'Timber', translation: 'config_materials.timber_l' },
  { value: 'Wallpaper', translation: 'config_materials.wallpaper_l' },
  {
    value: 'Waterproofing membrane',
    translation: 'config_materials.waterproofing_membrane_l',
  },
  { value: 'Windowsill', translation: 'config_materials.windowsill_l' },
  { value: 'Wire', translation: 'config_materials.wire_l' },
  { value: 'Gasoline', translation: 'config_materials.gasoline_l' },
];
