import { Component } from '@angular/core';
import { ConfirmService } from './confirm.service';
import { confirmAnimation } from '@app/shared/@animations/confirm';
import { ConfirmNotification } from '@interfaces';

@Component({
  selector: 'ppgt-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  animations: [confirmAnimation],
})
export class ConfirmComponent {
  private defaultRejectText = 'general.cancel_a';
  private defaultConfirmText = 'general.ok_a';

  constructor(public confirmService: ConfirmService) {}

  messageText(notification: ConfirmNotification | string): string {
    return this.formatNotification(notification).message;
  }

  confirmText(notification: ConfirmNotification | string): string {
    return this.formatNotification(notification).confirmText || this.defaultConfirmText;
  }

  rejectText(notification: ConfirmNotification | string): string {
    return this.formatNotification(notification).rejectText || this.defaultRejectText;
  }

  cancel(): void {
    this.confirmService.cancel();
  }

  approve(): void {
    this.confirmService.approve();
  }

  private formatNotification(notification: ConfirmNotification | string): ConfirmNotification {
    return (typeof notification === 'object'
      ? notification
      : { message: notification }) as ConfirmNotification;
  }
}
