import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SuppliersState } from './index';

const stateSelector = createFeatureSelector<SuppliersState>('suppliers');


export const getAllSuppliers = createSelector(
  stateSelector,
  (state: SuppliersState) => state.suppliers,
);

export const getSupplier = createSelector(
  stateSelector,
  (state: SuppliersState) => state.selectedSupplier,
);

export const getAllSuppliersCount = createSelector(
  stateSelector,
  (state: SuppliersState) => state.totalObjectsCount,
);

export const getSuppliersEmployees = createSelector(
  stateSelector,
  (state: SuppliersState) => state.supplierEmployees,
);

export const getSuppliersEmployeesRoles = createSelector(
  stateSelector,
  (state: SuppliersState) => state.supplierEmployeesRoles,
);

export const getSupplierLocations = createSelector(
  stateSelector,
  (state: SuppliersState) => state.suppliersLocations,
);

export const getLocationTypesSelector = createSelector(
  stateSelector,
  (state: SuppliersState) => state.locationTypes,
);

export const getSuppliersLoading = createSelector(
  stateSelector,
  (state: SuppliersState) => state.loading,
);

export const selectSuppliersVehiclesCount = createSelector(
  stateSelector,
  (state: SuppliersState) => state.vehiclesCount,
);

export const getSupplierVehicles = createSelector(
  stateSelector,
  (state: SuppliersState) => state.supplierVehicles,
);

export const getRunAvailableDrivers = createSelector(
  stateSelector,
  (state: SuppliersState) => state.runAvailableDrivers,
);

export const selectSuppliersClients = createSelector(
  stateSelector,
  (state: SuppliersState) => state.suppliersClients,
);

export const selectSuppliersClientsCount = createSelector(
  stateSelector,
  (state: SuppliersState) => state.clientsCount,
);

export const selectSuppliersProducts = createSelector(
  stateSelector,
  (state: SuppliersState) => state.suppliersMaterials,
);

export const selectSuppliersProductsCount = createSelector(
  stateSelector,
  (state: SuppliersState) => state.materialsCount,
);
