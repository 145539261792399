import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MenuComponent } from './menu/menu.component';
import { ShellService } from './shell.service';
import { DeviceService } from '@app/core/device.service';
import { AppState, getUserTheme, getUserRole } from '@app/state';
import { UserRoles, Role } from '@ppgt/web/shared/domain';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('menu')
  public menu: MenuComponent;

  public destroy$ = new Subject<void>();
  public userRole: Role;
  public isMobile: boolean;
  public showFooter: boolean;
  public theme$: Observable<string>;
  public isFullScreen$ = this.shellService.isFullScreen$;
  public withMenu$ = this.shellService.withMenu$;

  constructor(
    private store$: Store<AppState>,
    private shellService: ShellService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile;
    this.theme$ = this.store$.select(getUserTheme);
    this.store$
      .select(getUserRole)
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe((userRole) => {
        this.userRole = userRole;
        const rolesHideFooter = [UserRoles.SECURITY_GUARD, UserRoles.EQUIPMENT_OPERATOR];
        this.showFooter = !this.isMobile || (this.isMobile && !rolesHideFooter.includes(userRole.type));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleFullScreen() {
    this.shellService.toggleFullScreen();
  }
}
