import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';
import * as fromAppState from '@app/state';

import * as actions from './places.action';
import { AlertService } from '@ppgt/web/shared/core';
import { MapService } from '@app/shared/map/map.service';

@Injectable()
export class PlacesEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.PlacesAction>,
    private alertService: AlertService,
    private mapService: MapService,
  ) {}

  
  getPlaces$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_PLACES),
      map((action: actions.GetPlaces) => action.payload),
      switchMap(filters =>
        this.http.getPlaces(filters).pipe(
          map(res => new actions.GetPlacesSuccess(res)),
          catchError(error => [new actions.GetPlacesFail()])
        )
      )
    ));

  
  getPlace$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_PLACE),
    map((action: actions.GetPlace) => action.payload),
    switchMap(place =>
      this.http.getPlace(place).pipe(
        map(res => new actions.GetPlaceSuccess(res)),
        catchError(error => [new actions.GetPlaceFail()])
      )
    )
  ));

  
  createPlace$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_PLACE),
      map((action: actions.CreatePlace) => action.payload),
      switchMap(place =>
        this.http.createPlace(place).pipe(
          map(res => {
            this.alertService.showInfo('alert.object_added_l');
            this.mapService.unselectObject();
            return new actions.CreatePlaceSuccess(res);
          }),
          catchError(error => [new actions.CreatePlaceFail()])
        )
      )
    ));

  
  updatePlace$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_PLACE),
      map((action: actions.UpdatePlace) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...data } = payload;
        return this.http.updatePlace(data).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_edited_l');
            return redirectAfter
              ? [
                  new actions.UpdatePlaceSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/unloading-places'],
                    alertInfo: 'alert.object_edited_l',
                  }),
                ]
              : [new actions.UpdatePlaceSuccess(res)];
          }),

          catchError(error => [new actions.UpdatePlaceFail()])
        );
      })
    ));

  
  removePlace: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.REMOVE_PLACE),
      map((action: actions.RemovePlace) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...place } = payload;

        return this.http.removePlace(place).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_deleted_l');
            return redirectAfter
              ? [
                  new actions.RemovePlaceSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/unloading-places'],
                    alertInfo: 'alert.object_deleted_l',
                  }),
                ]
              : [new actions.RemovePlaceSuccess(res)];
          }),
          catchError(error => [new actions.RemovePlaceFail()])
        );
      })
    ));
}
