import { Injectable } from '@angular/core';
import { config } from '@app/app.config';
import { BehaviorSubject } from 'rxjs';
import * as MobileDetect from 'mobile-detect';

export interface AppScreen {
  width: number;
  height: number;
  isLandscape: boolean;
  isPortrait: boolean;
  isMobileLandscape: boolean;
  isMobilePortrait: boolean;
  isIOS: boolean;
  isAndroidOS: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  public mobileWidthBreakpoint = config.breakpoints.screenM;
  public isMobile = !!new MobileDetect(navigator.userAgent, this.mobileWidthBreakpoint).mobile();
  public screen$ = new BehaviorSubject<AppScreen>(this.extendedScreen);
  private operatingSystem = new MobileDetect(navigator.userAgent).os();

  private get extendedScreen() {
    const { width, height } = screen;
    const isLandscape = width > height;
    const isPortrait = width < height;
    const isMobileLandscape = isLandscape && this.isMobile;
    const isMobilePortrait = isPortrait && this.isMobile;
    const isIOS = this.operatingSystem === 'iOS';
    const isAndroidOS = this.operatingSystem === 'AndroidOS';

    return { width, height, isLandscape, isPortrait, isMobileLandscape, isMobilePortrait, isIOS, isAndroidOS };
  }

  constructor() {
    this.update();
    window.addEventListener('resize', this.update.bind(this));
  }

  private update() {
    this.screen$.next(this.extendedScreen);
  }
}
