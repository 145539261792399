import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Task } from '@ppgt/web/tasks/domain'
import { map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class TasksHttpService {
  readonly endpoints = {
    getTasks: `${environment.tasksUrl}/tasks`,
    getSubtasks: `${environment.tasksUrl}/subtasks`,
    apiUrl: environment.apiUrl
  }

  constructor(private http: HttpClient) {}

  getTasks(): Observable<Task[]> {
    return this.http.get<Task[]>(this.endpoints.getTasks, { headers: {"project-short-name": environment.clientShortname } })
      .pipe(map((response: Task[]) => response))
  }

  getSubtasks(parentId: string, childId: string): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.endpoints.getSubtasks}/${parentId}/subtask/${childId}`, { headers: {"project-short-name": environment.clientShortname } })
      .pipe(map((response: Task[]) => response))
  }

  
  getTasksImportSource(): Observable<string | null> {
    return this.http.get<{source: string}>(`${this.endpoints.apiUrl}/tasks-integration`).pipe(
      map((response) => response.source)
    )
  }

  resetTasksData(): Observable<object> {
    return this.http.delete(`${this.endpoints.getTasks}`, { headers: {"project-short-name": environment.clientShortname } }).pipe(
      map((response) => response)
    )
  }
}
