import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, getUserRole } from '@app/state';
import { DeviceService } from '../device.service';
import { UserRoles } from '@ppgt/web/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class ShellService {
  private withMenuSource$ = new BehaviorSubject<boolean>(true);
  private isFullScreenSource$ = new BehaviorSubject<boolean>(false);

  public withMenu$ = this.withMenuSource$.asObservable();
  public isFullScreen$ = this.isFullScreenSource$.asObservable();

  constructor(private store$: Store<AppState>, private router: Router, private device: DeviceService) {
    this.store$
      .select(getUserRole)
      .pipe(filter(Boolean))
      .subscribe((role) => {
        const isMobileDashboardEO =
          role.type === UserRoles.EQUIPMENT_OPERATOR && screen.width < this.device.mobileWidthBreakpoint;

        this.withMenuSource$.next(!isMobileDashboardEO);
      });
  }

  public toggleFullScreen() {
    const lastValue = this.isFullScreenSource$.getValue();
    this.isFullScreenSource$.next(!lastValue);
  }

  public turnOnFullScreen() {
    this.isFullScreenSource$.next(true);
  }

  public turnOffFullScreen() {
    this.isFullScreenSource$.next(false);
  }
}
