import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TasksEffects } from './tasks/+state/tasks.effects'
import { TasksFacade } from './tasks/+state/tasks.facade'
import {
  initialState as tasksInitialState,
  TASKS_FEATURE_KEY,
  tasksReducer,
  SUBTASKS_FEATURE_KEY,
  subtasksReducer,
  subtasksInitialState,
  TASKS_IMPORT_SOURCE_KEY,
  RESET_TASKS_DATA,
  tasksImportSourceReducer,
  tasksImportSourceInitState,
  resetTasksDataReducer
} from './tasks/+state/tasks.reducer'

import {
  MATERIALS_FEATURE_KEY,
  materialsReducer,
  materialsInitialState
} from "./materials/+state/materials.reducer";
import { MaterialsFacade } from './materials/+state/materials.facade'
import { MaterialsEffects } from './materials/+state/materials.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(TASKS_FEATURE_KEY, tasksReducer, { initialState: tasksInitialState }),
    StoreModule.forFeature(SUBTASKS_FEATURE_KEY, subtasksReducer, { initialState: subtasksInitialState }),
    StoreModule.forFeature(MATERIALS_FEATURE_KEY, materialsReducer, { initialState: materialsInitialState }),
    StoreModule.forFeature(TASKS_IMPORT_SOURCE_KEY, tasksImportSourceReducer, { initialState: tasksImportSourceInitState }),
    StoreModule.forFeature(RESET_TASKS_DATA, resetTasksDataReducer),
    EffectsModule.forFeature([TasksEffects, MaterialsEffects]),
  ],
  providers: [TasksFacade, MaterialsFacade],
})
export class WebTasksDataAccessModule {}
