import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, Go } from '@app/state';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { errors } from './errors';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  private errors: {[key: string]: string} = errors;

  constructor(
    public alertService: AlertService,
    public store: Store<AppState>,
  ) {}

  public errorShowAndHandle = (httpError: HttpErrorResponse): Observable<any> => {
    const error = httpError.error;
    const expired = error.message === 'SESSION_EXPIRED';
    if (expired) {
      this.store.dispatch(
        new Go({
          path: ['/login'],
          alertError: this.errors[error.message],
        }),
      );
    }
    const errorOptions = error.options;

    if (errorOptions && errorOptions.directErrorMessage) {
      const directErrorMessageOpts = {
        time: 500000,
        closeAllErrors: true,
      };

      this.alertService.showError(errorOptions.errorMessage, directErrorMessageOpts);
    }

    if (error && !(errorOptions && errorOptions.directErrorMessage)) {
      const { message, name } = error;
      if (message || name) {
        const errorTranlationKey = this.errors[message] || this.errors[name];
        this.alertService.showError(errorTranlationKey);
      }
    }

    const errorMessage = new Error(error.message) as any;
    return throwError(errorMessage);
  };
}
