import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { AppState, getAllSettings, GetSettings, isLoggedIn } from '@app/state';
import { filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Settings, Translations } from '@app/state/interfaces';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private supportedLanguages: string[] = [];
  public translationsLoaded = false;
  public defaultLanguage = environment.defaultLanguage;

  constructor(
    private translateService: TranslateService,
    private translocoService: TranslocoService,
    private store: Store<AppState>,
    private translateItemsService: TranslateItemsService
  ) {
    combineLatest([this.store.select(getAllSettings), this.store.select(isLoggedIn)])
      .pipe(filter(this.getSettings.bind(this)))
      .subscribe(([settings]) => {
        this.supportedLanguages = settings.availableLanguages.map((lang) => lang.key);
      });
  }

  public getSettings([settings, loggedIn]: [Settings, boolean]) {
    if (!loggedIn) {
      return false;
    }

    if (!settings) {
      this.store.dispatch(new GetSettings());
      return false;
    }

    return true;
  }

  init(translations: Translations, defaultLanguage?: string) {
    if (defaultLanguage) {
      this.defaultLanguage = defaultLanguage;
    }

    Object.keys(translations).forEach((langCode) => {
      this.translateService.setTranslation(langCode, translations[langCode]);
    });

    if (!this.getCurrentLanguage()) {
      this.setLanguage(this.defaultLanguage);
    }

    this.translationsLoaded = true;
  }

  setLanguage(langCode: string, isCreatingAccount?: boolean) {
    if (isCreatingAccount) {
      this.saveLanguage(langCode);
      return;
    }

    langCode = this.supportedLanguages.includes(langCode)
      ? langCode
      : this.defaultLanguage || this.translateService.getBrowserLang();

    this.saveLanguage(langCode);
  }

  private saveLanguage(lang: string): void {
    moment.locale(lang);
    this.translateService.use(lang);
    this.translocoService.setActiveLang(lang);
    this.translateItemsService.loadCurrentLang();
  }

  getCurrentLanguage() {
    return this.translateService.currentLang;
  }

  areTanslationsLoaded() {
    return this.translationsLoaded;
  }
}
