import { isFunction } from 'lodash-es';

import {
  GetAllSuppliersRes,
  GetSupplierRes,
  GetSuppliersProductsRes,
  GetSupplierVehiclesRes,
} from '@app/core/http/interfaces';

import * as actions from './suppliers.action';
import { SuppliersState } from '.';
import {
  ReducerMap,
  LocationType,
  VehicleObj,
} from '../interfaces';
import {
  SuppliersEmployeeRole,
  SuppliersEmployee,
  SupplierLocation,
} from '@interfaces';

const initialState: SuppliersState = {
  suppliers: [],
  selectedSupplier: null,
  supplierEmployees: [],
  supplierEmployeesRoles: [],
  suppliersLocations: [],
  locationTypes: [],
  vehiclesCount: { value: null },
  supplierVehicles: [],
  totalObjectsCount: null,
  runAvailableDrivers: [],
  suppliersClients: [],
  clientsCount: null,
  suppliersMaterials: [],
  materialsCount: null,
  loading: false,
  error: null,
};

const reducerMap: ReducerMap<SuppliersState> = {
  [actions.GET_ALL_SUPPLIERS]: state => ({ ...state, loading: true }),
  [actions.GET_ALL_SUPPLIERS_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),
  [actions.GET_ALL_SUPPLIERS_SUCCESS]: (state, { suppliers, meta }: GetAllSuppliersRes['data'] ) => ({
    ...state,
    suppliers,
    totalObjectsCount: meta.count,
    loading: false,
  }),

  [actions.GET_SUPPLIER]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIER_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),
  [actions.GET_SUPPLIER_SUCCESS]: (state, { supplier }: GetSupplierRes['data']) => ({
    ...state,
    selectedSupplier: supplier,
    loading: false,
  }),

  [actions.CREATE_SUPPLIER]: state => ({ ...state, loading: true }),
  [actions.CREATE_SUPPLIER_FAIL]: state => ({ ...state, loading: false }),
  [actions.CREATE_SUPPLIER_SUCCESS]: state => ({ ...state, loading: false }),

  [actions.UPDATE_SUPPLIER]: state => ({ ...state, loading: true }),
  [actions.UPDATE_SUPPLIER_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),
  [actions.UPDATE_SUPPLIER_SUCCESS]: state => ({ ...state, loading: false }),

  [actions.GET_SUPPLIERS_EMPLOYEE_ROLES]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIERS_EMPLOYEE_ROLES_SUCCESS]: (state, roles: SuppliersEmployeeRole[]) => ({
    ...state,
    loading: false,
    supplierEmployeesRoles: roles,
  }),
  [actions.GET_SUPPLIERS_EMPLOYEE_ROLES_FAIL]: state => ({ ...state, loading: false }),

  [actions.GET_SUPPLIERS_EMPLOYEES]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIERS_EMPLOYEES_SUCCESS]: (state, employees: SuppliersEmployee[]) => ({
    ...state,
    loading: false,
    supplierEmployees: employees,
  }),
  [actions.GET_SUPPLIERS_EMPLOYEES_FAIL]: state => ({ ...state, loading: false }),

  [actions.CREATE_SUPPLIERS_EMPLOYEE]: state => ({ ...state, loading: true }),
  [actions.CREATE_SUPPLIERS_EMPLOYEE_SUCCESS]: state => ({ ...state, loading: false }),
  [actions.CREATE_SUPPLIERS_EMPLOYEE_FAIL]: state => ({ ...state, loading: false }),

  [actions.UPDATE_SUPPLIER_EMPLOYEE]: state => ({ ...state, loading: true }),
  [actions.UPDATE_SUPPLIER_EMPLOYEE_SUCCESS]: state => ({ ...state, loading: false }),
  [actions.UPDATE_SUPPLIER_EMPLOYEE_FAIL]: state => ({ ...state, loading: false }),

  [actions.ASSIGN_SUPPLIERS_EMPLOYEE]: state => ({ ...state, loading: true }),
  [actions.ASSIGN_SUPPLIERS_EMPLOYEE_SUCCESS]: (state, employee: SuppliersEmployee) => ({
    ...state,
    loading: false,
    supplierEmployees: [
    ...state.supplierEmployees,
      employee,
    ],
  }),
  [actions.ASSIGN_SUPPLIERS_EMPLOYEE_FAIL]: state => ({ ...state, loading: false }),

  [actions.GET_SUPPLIER_LOCATIONS]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIER_LOCATIONS_SUCCESS]: (state, locations: SupplierLocation[]) => ({
    ...state,
    loading: false,
    suppliersLocations: locations,
  }),
  [actions.GET_SUPPLIER_LOCATIONS_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),

  [actions.CREATE_SUPPLIER_LOCATION]: state => ({ ...state, loading: true }),
  [actions.CREATE_SUPPLIER_LOCATION_SUCCESS]: (state, location: SupplierLocation) => ({
    ...state,
    loading: false,
    suppliersLocations: [
      ...state.suppliersLocations,
      location,
    ],
  }),
  [actions.CREATE_SUPPLIER_LOCATION_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),

  [actions.UPDATE_SUPPLIER_LOCATION]: state => ({ ...state, loading: true }),
  [actions.UPDATE_SUPPLIER_LOCATION_SUCCESS]: (state, updatedLocation: SupplierLocation) => {
    const suppliersLocations = state.suppliersLocations.map(
      location => location.id === updatedLocation.id ? updatedLocation : location
    );

    return {
      ...state,
      loading: false,
      suppliersLocations,
    };
  },
  [actions.UPDATE_SUPPLIER_LOCATION_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),

  [actions.GET_LOCATION_TYPES]: state => ({ ...state, loading: true }),
  [actions.GET_LOCATION_TYPES_SUCCESS]: (state, locationTypes: LocationType[]) => ({
    ...state,
    loading: false,
    locationTypes,
  }),
  [actions.GET_LOCATION_TYPES_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),

  [actions.SET_DEFAULT_LOCATION]: state  => ({ ...state, loading: true }),
  [actions.SET_DEFAULT_LOCATION_SUCCESS]: (state, updatedLocation: SupplierLocation) => {
    const suppliersLocations = state.suppliersLocations.map(location => {
      location.defaultLocation = false;
      return location.id === updatedLocation.id ? updatedLocation : location;
    });

    return {
      ...state,
      loading: false,
      suppliersLocations,
    };
  },
  [actions.SET_DEFAULT_LOCATION_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),

  [actions.GET_SUPPLIERS_VEHICLES_COUNT]: state => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUPPLIERS_VEHICLES_COUNT_SUCCESS]: (state, vehiclesCount: number) => ({
    ...state,
    loading: false,
    vehiclesCount: { value: vehiclesCount },
  }),
  [actions.GET_SUPPLIERS_VEHICLES_COUNT_FAIL]: state => ({
    ...state,
    loading: false,
  }),

  [actions.GET_SUPPLIER_VEHICLES]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIER_VEHICLES_SUCCESS]: (state, { vehicles, meta }: GetSupplierVehiclesRes['data'] ) => ({
    ...state,
    supplierVehicles: vehicles,
    totalObjectsCount: meta.count,
    loading: false,
  }),
  [actions.GET_SUPPLIER_VEHICLES_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),

  [actions.CREATE_SUPPLIER_VEHICLES]: state  => ({ ...state, loading: true }),
  [actions.CREATE_SUPPLIER_VEHICLES_SUCCESS]: (state, vehicles: VehicleObj[]) => ({
    ...state,
    supplierVehicles: [...vehicles, ...state.supplierVehicles],
    loading: false,
  }),
  [actions.CREATE_SUPPLIER_VEHICLES_FAIL]: (state, error: any) => ({ ...state, loading: false, error }),

  [actions.GET_RUN_AVAILABLE_DRIVERS]: (state) => ({ ...state, loading: true, error: null }),
  [actions.GET_RUN_AVAILABLE_DRIVERS_FAIL]: (state, payload) => ({ ...state, loading: false, error: payload.error }),
  [actions.GET_RUN_AVAILABLE_DRIVERS_SUCCESS]: (state, fetchedData) => {
    const { availableDrivers  } = fetchedData;
    return {
      ...state,
      runAvailableDrivers: availableDrivers,
      loading: false,
      error: null,
    };
  },

  [actions.GET_SUPPLIERS_CLIENTS]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIERS_CLIENTS_SUCCESS]: (state, payload ) => ({
    ...state,
    suppliersClients: payload.suppliersClients,
    clientsCount: payload.clientsCount,
    loading: false,
  }),
  [actions.GET_SUPPLIERS_CLIENTS_FAIL]: state => ({ ...state, loading: false }),

  [actions.GET_SUPPLIERS_PRODUCTS]: state => ({ ...state, loading: true }),
  [actions.GET_SUPPLIERS_PRODUCTS_SUCCESS]: (state, payload: GetSuppliersProductsRes) => ({
    ...state,
    suppliersMaterials: payload.materials,
    materialsCount: payload.meta.count,
    loading: false,
  }),
  [actions.GET_SUPPLIERS_PRODUCTS_FAIL]: state => ({ ...state, loading: false }),
};

export function suppliersReducer(state: SuppliersState = initialState, action: any) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
