import { isFunction } from 'lodash-es';

import * as actions from './gates.action';

import { GatesListState } from './';
import { Gate, Action } from '@app/state/interfaces';

const initialState: GatesListState = {
  gates: [],
  gate: null,
  totalObjectsCount: null,
  loaded: false,
  loading: false,
};

const reducerMap = {
  [actions.GET_GATES]: (state: GatesListState, res: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_GATES_FAIL]: (state: GatesListState, res: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_GATES_SUCCESS]: (state: GatesListState, res: any) => ({
    ...state,
    gates: res.gates as Gate[],
    totalObjectsCount: res.meta.count,
    loaded: true,
    loading: false,
  }),
  [actions.GET_GATE]: (state: GatesListState, res: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_GATE_FAIL]: (state: GatesListState, res: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_GATE_SUCCESS]: (state: GatesListState, gate: Gate) => ({
    ...state,
    gate,
    loaded: true,
    loading: false,
  }),
  [actions.CREATE_GATE]: (state: GatesListState, gate: Gate) => ({
    ...state,
    gate: null,
    loading: true,
  }),
  [actions.CREATE_GATE_FAIL]: (state: GatesListState, gate: Gate) => ({
    ...state,
    gates: state.gates.filter(g => !state.gate || g.id !== state.gate.id),
    loading: false,
  }),
  [actions.CREATE_GATE_SUCCESS]: (state: GatesListState, gate: Gate) => ({
    ...state,
    gates: [gate, ...state.gates],
    gate,
    loading: false,
  }),
  [actions.UPDATE_GATE]: (state: GatesListState, gate: any) => ({
    ...state,
    gate: null,
    loading: true,
  }),
  [actions.UPDATE_GATE_FAIL]: (state: GatesListState, gate: any) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_GATE_SUCCESS]: (state: GatesListState, gate: Gate) => ({
    ...state,
    gate,
    loading: false,
  }),
  [actions.REMOVE_GATE]: (state: GatesListState, gate: any) => ({
    ...state,
    loading: true,
  }),
  [actions.REMOVE_GATE_FAIL]: (state: GatesListState, gate: any) => ({
    ...state,
    loading: false,
  }),
  [actions.REMOVE_GATE_SUCCESS]: (state: GatesListState, gate: Gate) => ({
    ...state,
    gates: state.gates.filter(g => g.id !== gate.id),
    loading: false,
  }),
};

export function gatesReducer(state = initialState, action: Action) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getGates = (state: GatesListState) => state.gates;
export const getSelectedGate = (state: GatesListState) => state.gate;
export const getLoaded = (state: GatesListState) => state.loaded;
export const getGateCount = (state: GatesListState) => state.totalObjectsCount;
