import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { SocketService } from '@app/core/socket/socket.service';
import { Store, select } from '@ngrx/store';
import { Subject, fromEvent } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { config } from './app.config';
import { UsersSignOut, getToken, getUserData, AppState } from '@app/state';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { LoaderService } from '@app/core/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public destroy$ = new Subject<void>();
  public loading = false;
  public showNewDeliveryAside = false;

  constructor(
    private socket: SocketService,
    private store: Store<AppState>,
    private router: Router,
    private titleService: Title,
    private translateService: TranslateService,
    private translateItemsService: TranslateItemsService,
    private deliveriesService: DeliveriesService,
    private cdRef: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    // if (environment.production) {
    //   this.configureSmartlook();
    // }

    this.initLoader();
    this.storageObserveInit();

    this.store.pipe(select(getToken), filter(Boolean), takeUntil(this.destroy$)).subscribe((token) => {
      this.socket.init(token);
      this.translateItemsService.getUnits();
    });

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      window.scrollTo(0, 0);
      this.setTitle(event.url);
    });

    this.getDeliveryFormOpened();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initLoader(): void {
    this.loaderService.onLoading$.pipe(takeUntil(this.destroy$)).subscribe((isloading) => (this.loading = isloading));

    this.loaderService.loadingObserveInit();
  }

  private getDeliveryFormOpened(): void {
    this.deliveriesService.onetimeDeliveryFormOpened$.pipe(takeUntil(this.destroy$)).subscribe(({ opened }) => {
      this.showNewDeliveryAside = opened;
      this.cdRef.detectChanges();
    });
  }

  public closeNewDeliveryAside(): void {
    this.deliveriesService.closeOnetimeDeliveryForm(true);
  }

  // private configureSmartlook(): void {
  //   smartlookClient.init('380adb8b472d770f6a333b85094ee75fea89bafe');

  //   this.store
  //     .select(getUserData)
  //     .pipe(filter(Boolean), take(1))
  //     .subscribe(({ id, fullName: name }) => {
  //       smartlookClient.identify(id, {
  //         name,
  //       });
  //     });
  // }

  public setTitle(linkUrl: string) {
    const section = linkUrl.match(/^(.+?)(\/|$)/) && linkUrl.match(/^(.+?)(\/|$)/)[0];
    const link = config.nav.general.find((link) => link.path === section);

    const title = link ? this.translateService.instant(link.title) : config.title;

    this.titleService.setTitle(title);
  }

  storageObserveInit() {
    fromEvent<StorageEvent>(window, 'storage')
      .pipe(filter(({ key, newValue }) => key === config.storageTokenKey && !newValue))
      .subscribe(() => {
        this.store.dispatch(new UsersSignOut());
      });
  }
}
