import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ConfirmService } from '@ppgt/web/shared/ui/confirm';
import { DeliveriesService } from '@app/deliveries/deliveries.service';

@Injectable({
  providedIn: 'root',
})
export class OnetimeFormOpenedGuard implements CanDeactivate<any> {
  constructor(private confirmService: ConfirmService, private deliveriesService: DeliveriesService) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> {
    const routesWithFormAvailable = ['/construction-plan/current', '/scheduler'];

    return this.deliveriesService.onetimeDeliveryFormOpened$.pipe(
      switchMap(({ opened }) => {
        const urlWithoutParams = nextState.url.split('?')[0];
        if (opened && !routesWithFormAvailable.includes(urlWithoutParams)) {
          if (this.deliveriesService.isOnetimeDeliveryFormEmpty) {
            this.deliveriesService.closeOnetimeDeliveryForm();
            return observableOf(true);
          }

          return this.confirmService.open('alert.close_new_delivery_confirmation_l').pipe(
            tap((response) => {
              if (response) {
                this.deliveriesService.closeOnetimeDeliveryForm();
              }
            })
          );
        }

        return observableOf(true);
      })
    );
  }
}
