import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';
import { AlertService } from '@ppgt/web/shared/core';

import * as actions from './reservations.actions';
import { SchedulerService } from '@app/scheduler/scheduler.service';

@Injectable()
export class ReservationsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.ReservationsActions>,
    private alertService: AlertService,
    private schedulerService: SchedulerService,
  ) {}
    
  public createEquipmentReservation$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_EQUIPMENT_RESERVATION),
      map((action: actions.CreateEquipmentReservation) => action.payload),
      switchMap(({ reservation, duplicateAfter }) => this.http.createEquipmentReservation(reservation)
          .pipe(
            map(newReservation => {
              this.alertService.showInfo('scheduler.reservation_success_l');

              if (!duplicateAfter) {
                this.schedulerService.closeEquipmentReservationAside();
              }

              if (duplicateAfter) {
                this.schedulerService.clearTimeControls();
              }

              return new actions.CreateEquipmentReservationSuccess({ reservation: newReservation });
            }),
            catchError(() => [new actions.CreateEquipmentReservationFail()]),
          )),
    ));

  
  public updateEquipmentReservation$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_EQUIPMENT_RESERVATION),
      map((action: actions.UpdateEquipmentReservation) => action.payload),
      switchMap(({ id, reservation, duplicateAfter }) => this.http.updateEquipmentReservation(id, reservation)
          .pipe(
            map(updatedReservation => {
              this.alertService.showInfo('scheduler.reservation_update_success_l');

              if (!duplicateAfter) {
                this.schedulerService.closeEquipmentReservationAside();
              }

              if (duplicateAfter) {
                this.schedulerService.clearTimeControls();
              }

              return new actions.UpdateEquipmentReservationSuccess({ reservation: updatedReservation });
            }),
            catchError(() => [new actions.UpdateEquipmentReservationFail()]),
          )),
    ));

  
  public getEquipmentReservation$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_EQUIPMENT_RESERVATION),
      map((action: actions.GetEquipmentReservation) => action.payload),
      switchMap(({ reservationId }) => this.http.getEquipmentReservation(reservationId)
          .pipe(
            map(reservation => new actions.GetEquipmentReservationSuccess({ reservation })),
            catchError(() => [new actions.GetEquipmentReservationFail()]),
          )),
    ));

  
  public deleteEquipmentReservation$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.DELETE_EQUIPMENT_RESERVATION),
      map((action: actions.DeleteEquipmentReservation) => action.payload),
      switchMap(({ reservationId }) => this.http.deleteEquipmentReservation(reservationId)
          .pipe(
            map(() => {
              this.alertService.showInfo('scheduler.reservation_delete_success_l');
              this.schedulerService.closeEquipmentReservationAside();

              return new actions.DeleteEquipmentReservationSuccess({ reservationId });
            }),
            catchError(() => [new actions.DeleteEquipmentReservationFail()]),
          )),
    ));
}
