import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import { FooterComponent } from './footer.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../@directives/directives.module'
import { SvgModule } from '../svg-icon/svg.module';

@NgModule({
  declarations: [FooterComponent, UserInfoComponent],
  exports: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DirectivesModule,
    SvgModule,
    RouterModule
  ]
})
export class FooterModule { }