import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';
import * as actions from './settings.action';
import { TranslateService } from '@ngx-translate/core';
import { VehicleSize } from '../interfaces';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class SettingsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.SettingsAction>,
    private translateService: TranslateService,
    private alertService: AlertService,
  ) {}

  
  getSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SETTINGS),
    switchMap(() =>
      this.http.getGeneralSettings().pipe(
        map(res => new actions.GetSettingsSuccess(res)),
        catchError(error => [new actions.GetSettingsFail()]),
      ),
    ),
  ));

  
  getDeliverySettings$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_DELIVERY_SETTINGS),
      switchMap(() =>
        this.http.getDeliverySettings().pipe(
          map(res => new actions.GetDeliverySettingsSuccess(res)),
          catchError(error => [new actions.GetDeliverySettingsFail()]),
        ),
      ),
    ));

  
  getObjectSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_OBJECT_SETTINGS),
    switchMap(() =>
      this.http.getObjectSettings().pipe(
        map(res => new actions.GetObjectSettingsSuccess(res)),
        catchError(error => [new actions.GetObjectSettingsFail()]),
      ),
    ),
  ));

  
  getSubcontractorSettings$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_SUBCONTRACTOR_SETTINGS),
      switchMap(() =>
        this.http.getSubcontractorSettings().pipe(
          map(res => new actions.GetSubcontractorSettingsSuccess(res)),
          catchError(error => [new actions.GetSubcontractorSettingsFail()]),
        ),
      ),
    ));

  
  getEcologySettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_ECOLOGY_SETTINGS),
    switchMap(() =>
      this.http.getEcologySettings().pipe(
        map(res => new actions.GetEcologySettingsSuccess(res)),
        catchError(error => [new actions.GetEcologySettingsFail()]),
      ),
    ),
  ));

  
  getProjectSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_PROJECT_SETTINGS),
    switchMap(() =>
      this.http.getProjectSettings().pipe(
        map(res => new actions.GetProjectSettingsSuccess(res)),
        catchError(error => [new actions.GetProjectSettingsFail()]),
      ),
    ),
  ));

  
  getVehicleSizes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_VEHICLE_SIZES),
    switchMap(() =>
      this.http.getVehicleSizes().pipe(
        map(vehicleSizes => {
          const vehicleSizesMapped: VehicleSize[] = vehicleSizes.map(vehicleSize => {
            const name = vehicleSize.translation
              ? this.translateService.instant(vehicleSize.translation)
              : vehicleSize.name;
            return { ...vehicleSize, name };
          });
          return new actions.GetVehicleSizesSuccess(vehicleSizesMapped);
        }),
        catchError(error => [new actions.GetVehicleSizesFail()]),
      ),
    ),
  ));

  
  public getScheduleColors$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_SCHEDULE_COLORS),
      switchMap(() => this.http.getScheduleColors()
          .pipe(
            map(colors => new actions.GetScheduleColorsSuccess({ colors })),
            catchError(() => [new actions.GetScheduleColorsFail()]),
          )),
    ));

  
  public createScheduleColors$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_SCHEDULE_COLORS),
      map((action: actions.CreateScheduleColors) => action.payload),
      switchMap(({ colorsSets }) => this.http.createScheduleColors(colorsSets)
          .pipe(
            map(res => new actions.CreateScheduleColorsSuccess({ colorsSets: res })),
            catchError(() => [new actions.CreateScheduleColorsFail()]),
          )),
    ));

  
  public updateScheduleColors$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_SCHEDULE_COLORS),
      map((action: actions.UpdateScheduleColors) => action.payload),
      switchMap(({ colorsSets }) => this.http.updateScheduleColors(colorsSets)
          .pipe(
            map(res => {
              this.alertService.showInfo('general.changes_saved_l');
              return new actions.UpdateScheduleColorsSuccess({ colorsSets: res });
            }),
            catchError(() => [new actions.UpdateScheduleColorsFail()]),
          )),
    ));
}
