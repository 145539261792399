import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthState } from './';

const stateSelector = createFeatureSelector<AuthState>('auth');

export const getToken = createSelector(stateSelector, state => state.token);
export const getTokenDecoded = createSelector(stateSelector, state => state.tokenDecoded);
export const getRedirectionLink = createSelector(stateSelector, state => ({
  redirectionLink: state.redirectUrl,
  queryParams: state.queryParams,
}));
export const isLoggedIn = createSelector(
  stateSelector,
  state => !!state.tokenDecoded && !!state.tokenDecoded.id,
);
