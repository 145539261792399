import { createAction, props } from "@ngrx/store"
import { HttpErrorResponse } from "@angular/common/http"

import { Task, Materials } from "@ppgt/web/tasks/domain"

export enum Types {
  GetTasks = '[Tasks] Get Tasks',
  GetTasksFail = '[Tasks] Get Tasks Fail',
  GetTasksSuccess = '[Tasks] Get Tasks Success',
  UpdateTaskMaterials = '[Task] Update Task Materials',
  UpdateTaskMaterialsSuccess = '[Task] Update Task Materials Success',
  GetSubtasks = '[Subtasks] Get Subtasks',
  GetSubtasksFail = '[Subtasks] Get SubtasksFail',
  GetSubtasksSuccess = '[Subtasks] Get Subtasks Success',
  GetTasksImportSource = '[Tasks] Get Tasks Import Source',
  GetTasksImportSourceFail = '[Tasks] Get Tasks Import Source Fail',
  GetTasksImportSourceSuccess = '[Tasks] Get Tasks Import Source Success',
  ResetTasksData = '[Tasks] Reset tasks data',
  ResetTasksDataSuccess = '[Tasks] Reset tasks data success',  
  ResetTasksDataFail = '[Tasks] Reset tasks data fail',
}

export const getTasks = createAction(Types.GetTasks)

export const getTasksFail = createAction(
  Types.GetTasksFail,
  props<{ error: HttpErrorResponse }>()
)

export const getTasksSuccess = createAction(
  Types.GetTasksSuccess,
  props<{tasks: Task[]}>()
)

export const updateTaskMaterials = createAction(
  Types.UpdateTaskMaterials,
  props<{taskId: string, taskChildId:string, materials: Materials[], subtaskId: string}>()
)

export const updateTaskMaterialsSuccess = createAction(
  Types.UpdateTaskMaterialsSuccess,
  props<{taskChildId:string, materials: Materials[], subtaskId: string}>()
)

export const getSubtasks = createAction(Types.GetSubtasks, props<{parentId: string, childId: string}>())

export const getSubtasksFail = createAction(
  Types.GetSubtasksFail,
  props<{error: HttpErrorResponse}>()
)

export const getSubtasksSuccess = createAction(
  Types.GetTasksImportSource,
  props<{tasks: Task[], childId: string}>()
)

export const getTasksImportSource = createAction(Types.GetSubtasks)

export const getTasksImportSourceFail = createAction(
  Types.GetTasksImportSourceFail,
  props<{error: HttpErrorResponse}>()
)

export const getTasksImportSourceSuccess = createAction(
  Types.GetTasksImportSourceSuccess,
  props<{source: string | null}>()
)

export const resetTasksData = createAction(Types.ResetTasksData)

export const resetTasksDataFail = createAction(
  Types.ResetTasksDataFail,
  props<{error: HttpErrorResponse}>()
)

export const resetTasksDataSuccess = createAction(
  Types.ResetTasksDataSuccess,
)
