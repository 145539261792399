import { Action } from '@ngrx/store';

import { CreatePlaceReq, QueryParamObject } from '@app/core/http/interfaces';
import { UnloadingPlace } from '@app/state/interfaces';

// load places
export const GET_PLACES = 'GET_PLACES';
export const GET_PLACES_FAIL = 'GET_PLACES_FAIL';
export const GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS';

export class GetPlaces implements Action {
  readonly type = GET_PLACES;

  constructor(public payload?: QueryParamObject[]) {}
}

export class GetPlacesFail implements Action {
  readonly type = GET_PLACES_FAIL;
}

export class GetPlacesSuccess implements Action {
  readonly type = GET_PLACES_SUCCESS;

  constructor(public payload: UnloadingPlace[]) {}
}

// load place
export const GET_PLACE = 'GET_PLACE';
export const GET_PLACE_FAIL = 'GET_PLACE_FAIL';
export const GET_PLACE_SUCCESS = 'GET_PLACE_SUCCESS';

export class GetPlace implements Action {
  readonly type = GET_PLACE;

  constructor(public payload: { id: string }) {}
}

export class GetPlaceFail implements Action {
  readonly type = GET_PLACE_FAIL;
}

export class GetPlaceSuccess implements Action {
  readonly type = GET_PLACE_SUCCESS;

  constructor(public payload: UnloadingPlace) {}
}

// create place
export const CREATE_PLACE = 'CREATE_PLACE';
export const CREATE_PLACE_FAIL = 'CREATE_PLACE_FAIL';
export const CREATE_PLACE_SUCCESS = 'CREATE_PLACE_SUCCESS';

export class CreatePlace implements Action {
  readonly type = CREATE_PLACE;

  constructor(public payload: CreatePlaceReq) {}
}

export class CreatePlaceFail implements Action {
  readonly type = CREATE_PLACE_FAIL;
}

export class CreatePlaceSuccess implements Action {
  readonly type = CREATE_PLACE_SUCCESS;

  constructor(public payload: UnloadingPlace) {}
}

// update place

export const UPDATE_PLACE = 'UPDATE_PLACE';
export const UPDATE_PLACE_FAIL = 'UPDATE_PLACE_FAIL';
export const UPDATE_PLACE_SUCCESS = 'UPDATE_PLACE_SUCCESS';

export class UpdatePlace implements Action {
  readonly type = UPDATE_PLACE;

  constructor(
    public payload: {
      id: string;
      data: CreatePlaceReq;
      redirectAfter?: boolean;
    }
  ) {}
}

export class UpdatePlaceFail implements Action {
  readonly type = UPDATE_PLACE_FAIL;
}

export class UpdatePlaceSuccess implements Action {
  readonly type = UPDATE_PLACE_SUCCESS;

  constructor(public payload: UnloadingPlace) {}
}

// remove selected place
export const REMOVE_PLACE = 'REMOVE_PLACE';
export const REMOVE_PLACE_FAIL = 'REMOVE_PLACE_FAIL';
export const REMOVE_PLACE_SUCCESS = 'REMOVE_PLACE_SUCCESS';

export class RemovePlace implements Action {
  readonly type = REMOVE_PLACE;

  constructor(
    public payload: {
      id: string;
      redirectAfter?: boolean;
    }
  ) {}
}

export class RemovePlaceFail implements Action {
  readonly type = REMOVE_PLACE_FAIL;
}

export class RemovePlaceSuccess implements Action {
  readonly type = REMOVE_PLACE_SUCCESS;

  constructor(public payload: any) {}
}

export type PlacesAction =
  | GetPlaces
  | GetPlacesFail
  | GetPlacesSuccess
  | GetPlace
  | GetPlaceFail
  | GetPlaceSuccess
  | CreatePlace
  | CreatePlaceFail
  | CreatePlaceSuccess
  | UpdatePlace
  | UpdatePlaceFail
  | UpdatePlaceSuccess
  | RemovePlace
  | RemovePlaceFail
  | RemovePlaceSuccess;
