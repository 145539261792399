import { ActionReducer } from '@ngrx/store';

import * as effects from './gates.effect';
import * as reducers from './gates.reducer';

import { Gate } from '@app/state/interfaces';

export const gatesEffects: any[] = [effects.GatesEffects];

export interface GatesListState {
  gates: Gate[];
  gate: Gate;
  totalObjectsCount: number;
  loaded: boolean;
  loading: boolean;
}

export const gatesReducer: ActionReducer<GatesListState> =
  reducers.gatesReducer;

export * from './gates.action';
export * from './gates.effect';
export * from './gates.reducer';
export * from './gates.selector';
