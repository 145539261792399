import { ActionReducer } from '@ngrx/store';

import * as effects from './users.effect';
import * as reducers from './users.reducer';

import { User } from '@app/state/interfaces';

export const usersEffects: any[] = [effects.UsersEffects];

export interface UsersState {
  userData: User;
  users?: User[];
  userPin: string;
  loaded: boolean;
  loading: boolean;
  totalCount?: number;
  selectedUser?: User;
  logs?: any;
}

export const usersReducer: ActionReducer<UsersState> = reducers.usersReducer;

export * from './users.action';
export * from './users.effect';
export * from './users.reducer';
export * from './users.selector';
