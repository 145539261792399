import { Action } from '@ngrx/store';
import { SafeHtml } from '@angular/platform-browser';

export const GET_REGULATION = 'GET_REGULATION';
export const GET_REGULATION_SUCCESS = 'GET_REGULATION_SUCCESS';
export const GET_REGULATION_FAIL = 'GET_REGULATION_FAIL';

export class GetRegulation implements Action {
  readonly type = GET_REGULATION;

  constructor(public payload: { language: string }) {}
}

export class GetRegulationSuccess implements Action {
  readonly type = GET_REGULATION_SUCCESS;

  constructor(public payload: SafeHtml) {}
}

export class GetRegulationFail {
  readonly type = GET_REGULATION_FAIL;
}

export type RegulationsActions =
  | GetRegulation
  | GetRegulationSuccess
  | GetRegulationFail;
