import { createSelector, createFeatureSelector } from '@ngrx/store';

import { PlacesListState } from '.';

const stateSelector = createFeatureSelector<PlacesListState>('places');

export const getAllPlaces = createSelector(
  stateSelector,
  (state: PlacesListState) => state.places
);

export const getPlacesCount = createSelector(
  stateSelector,
  (state: PlacesListState) => state.totalObjectsCount
);

export const getSelectedPlace = createSelector(
  stateSelector,
  (state: PlacesListState) => state.place
);

export const getPlacesLoading = createSelector(
  stateSelector,
  (state: PlacesListState) => state.loading
);

export const placesQuery = {
  getAllPlaces,
};
