import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegulationsState } from '.';

const stateSelector = createFeatureSelector<RegulationsState>('regulations');

const getRegulation = (state: RegulationsState) => state.regulation;
const isLoadingRegulation = (state: RegulationsState) => state.isLoading;

export const selectRegulation = createSelector(
  stateSelector,
  getRegulation,
);

export const selectIsLoadingRegulation = createSelector(
  stateSelector,
  isLoadingRegulation,
);
