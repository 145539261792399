import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmNotification } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  private confirmationSource = new Subject<string | ConfirmNotification | null>();
  public confirmation$ = this.confirmationSource.asObservable();

  private confirmedSource = new Subject<boolean>();
  public confirmed$ = this.confirmedSource.asObservable();

  public open(message: string | ConfirmNotification) {
    this.confirmationSource.next(message);
    return this.confirmed$;
  }

  public approve() {
    this.confirmedSource.next(true);
    this.confirmationSource.next(null);
  }

  public cancel() {
    this.confirmedSource.next(false);
    this.confirmationSource.next(null);
  }
}
