import { ActionReducer } from '@ngrx/store';

import * as effects from './deliveries.effect';
import { DeliveryStagesEffects } from './delivery-stages.effect';
import * as reducers from './deliveries.reducer';

import {
  Delivery,
  Propose,
  CheckedDeliveries,
  DeliveryContacts,
  DeliveryUnloadingMethod,
  DeliveryComment,
  Distributor,
  DeliveryFormedFilled,
  Unit,
  DeliveryLongterm,
  DeliveryPourCard,
  CubeTestsPurpose,
  LongtermDeliveryTerm,
  DeliveryFile,
} from '@app/state/interfaces';

export const deliveriesEffects: any[] = [effects.DeliveriesEffects, DeliveryStagesEffects];

export interface DeliveriesState {
  deliveriesList?: Delivery[];
  longtermDeliveries?: DeliveryLongterm[];
  delivery?: Delivery;
  contacts?: DeliveryContacts;
  check?: CheckedDeliveries;
  propose?: Propose[];
  totalObjectsCount: number;
  deliveryLoading: boolean;
  deliveriesLoading: boolean;
  deliveryForm: DeliveryFormedFilled;
  unloadingMethods: DeliveryUnloadingMethod[];
  comments: DeliveryComment[];
  distributors: Distributor[];
  units: Unit[];
  pourCard: DeliveryPourCard;
  cubeTestsPurposes: CubeTestsPurpose[];
  longtermDeliveryTerms: LongtermDeliveryTerm[];
  deliveryFile: DeliveryFile;
}

export const deliveriesReducer: ActionReducer<DeliveriesState> = reducers.deliveriesReducer;

export * from './deliveries.action';
export * from './deliveries.effect';
export * from './deliveries.reducer';
export * from './deliveries.selector';
