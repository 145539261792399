import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, getUserPermissions } from '@app/state';
import { DeviceService } from '@app/core/device.service';
import { ShellService } from '@app/core/shell/shell.service';
import { PermissionsEnum } from '@ppgt/web/shared/domain';
import { IntegrationLinkData } from '@app/state/interfaces';
import { HttpService } from '@app/core/http/http.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public grantedPermissions$: Observable<PermissionsEnum[]>;

  @Input() public title: string;
  @Input() public description: string;
  @Input() public logo: string;
  @Input() public tabs: any[];
  @Input() public relativePaths = false;
  @Input() public buttonPermission: PermissionsEnum;
  @Input() public headerButtonsTestId: string;

  @Input() public requiredPermission: PermissionsEnum;

  @Input() public button: string;
  @Input() public buttonIcon: string;
  @Input() public buttonDescription: string;
  @Input() public button2: string;
  @Input() public buttonIcon2: string;
  @Input() public buttonDescription2: string;
  @Input() public buttonSpecialCondition = true;

  @Output() public buttonAction = new EventEmitter<any>();
  @Output() public buttonAction2 = new EventEmitter<any>();

  public constructor(
    private store: Store<AppState>,
    public deviceService: DeviceService,
    public shellService: ShellService,
    public httpService: HttpService
  ) {}

  public ngOnInit() {
    this.grantedPermissions$ = this.store.select(getUserPermissions);
  }

  public hasPermission(
    requiredPermission: PermissionsEnum = PermissionsEnum.NONE,
    grantedPermissions: PermissionsEnum[]
  ): boolean {
    if (requiredPermission === PermissionsEnum.NONE) {
      return true;
    }

    return grantedPermissions.includes(requiredPermission);
  }

  public clickButton() {
    this.buttonAction.emit();
  }

  public clickButton2() {
    this.buttonAction2.emit();
  }

  public clickLink(data?: IntegrationLinkData) {
    if (data && data.integration) {
      this.httpService
        .getIntegrationToken(data.client)
        .subscribe((token) => (location.href = `${data.integrationUrl}?integrationToken=${token}`));
    }
  }

  public isImgPath(buttonIcon: string) {
    return buttonIcon.includes('/');
  }
}
