import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AlertService } from '@ppgt/web/shared/core';
import * as actions from './delivery-runs.action';
import { DeliveriesRunsService } from '@app/core/http/deliveries-runs.service';
import { Action } from '@ngrx/store';

@Injectable()
export class DeliveryRunsEffects {
  constructor(
    private actions$: Actions<actions.DeliveryRunsActions>,
    private alertService: AlertService,
    private translateService: TranslateService,
    private deliveriesRunsService: DeliveriesRunsService,
  ) {
  }

  
  getDeliveryRun: Observable<actions.GetDeliveryRunSuccess | actions.GetDeliveryRunFail> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_DELIVERY_RUN),
      map((action: actions.GetDeliveryRun) => action.payload),
      switchMap(({ deliveryRunId }) => this.deliveriesRunsService.getDeliveryRun(deliveryRunId)
          .pipe(
            map(res => new actions.GetDeliveryRunSuccess(res)),
            catchError(error => [new actions.GetDeliveryRunFail({ error })]),
          )),
    ));

  
  getDeliveryRunLogs: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_DELIVERY_RUN_LOGS),
      map((action: actions.GetDeliveryRunLogs) => action.payload),
      switchMap(({ deliveryRunId }) => this.deliveriesRunsService.getDeliveryRunLogs(deliveryRunId)
          .pipe(
            map(res => new actions.GetDeliveryRunLogsSuccess(res)),
            catchError(error => [new actions.GetDeliveryRunLogsFail({ error })]),
          )),
    ));

  
  getDeliveryRunTicket: Observable<actions.GetDeliveryRunTicketSuccess | actions.GetDeliveryRunTicketFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DELIVERY_RUN_TICKET),
      map(({payload}: actions.GetDeliveryRunTicket) => payload),
      switchMap(({ deliveryId, fileId }) => this.deliveriesRunsService.getTicket(deliveryId, fileId)
          .pipe(
            map(({tempPubUrl}) => new actions.GetDeliveryRunTicketSuccess(tempPubUrl)),
            catchError(error => [new actions.GetDeliveryRunTicketFail({ error })]),
          ))
    ));

  
  getDeliveryRuns: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_DELIVERY_RUNS),
      map((action: actions.GetDeliveryRuns) => action.payload),
      switchMap(({ deliveryId, params }) => this.deliveriesRunsService.getDeliveryRuns(deliveryId, params)
          .pipe(
            map(res => new actions.GetDeliveryRunsSuccess(res)),
            catchError(() => [ new actions.GetDeliveryRunsFail() ]),
          )),
    ));
}
