import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { assign } from 'lodash-es';

import { UpdateUserSuccess } from '../users';
import * as actions from './suppliers.action';
import * as fromAppState from '@app/state';
import { AlertService } from '@ppgt/web/shared/core';
import { SuppliersHttpService } from '@app/core/http/suppliers.http.service';
import { ErrorsNames } from '@app/shared/@errors/error-names';

@Injectable()
export class SuppliersEffects {
  constructor(
    private actions$: Actions<actions.SuppliersAction>,
    private alertService: AlertService,
    private suppliersHttpService: SuppliersHttpService,
  ) {}

  
  getSuppliers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_ALL_SUPPLIERS),
    map((action: actions.GetAllSuppliers) => action.payload),
    switchMap((filters) =>
      this.suppliersHttpService.getAllSuppliers(filters).pipe(
        map((res) => new actions.GetAllSuppliersSuccess(res.data)),
        catchError((err) => [new actions.GetAllSuppliersFail(err)]),
      ),
    ),
  ));

  
  createSupplier$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.CREATE_SUPPLIER),
    map((action: actions.CreateSupplier) => action.payload),
    switchMap((newSupplierData) =>
      this.suppliersHttpService.createSupplier(newSupplierData).pipe(
        mergeMap(res => [
          new actions.CreateSupplierSuccess(),
          new fromAppState.Go({
            path: ['suppliers'],
            alertInfo: 'alert.supplier_has_been_created_l',
          }),
        ]),
        catchError((e: HttpErrorResponse) => {
          if (e.error && e.error.name === ErrorsNames.USER_SIMILAR_EMAIL) {
            this.alertService.showError('settings_page.user_email_exist_l');
          }
          return [new actions.CreateSupplierFail()];
        }),
      ),
    ),
  ));

  
  getSupplier$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIER),
    map((action: actions.GetSupplier) => action.payload),
    switchMap((supplierId) =>
      this.suppliersHttpService.getSupplier(supplierId).pipe(
        map((res) => new actions.GetSupplierSuccess(res.data)),
        catchError((err) => [new actions.GetSupplierFail(err)]),
      ),
    ),
  ));

  
  updateSupplier$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_SUPPLIER),
    map((action: actions.UpdateSupplier) => action.payload),
    switchMap(({ updatedSupplier, supplierId }) =>
      this.suppliersHttpService.updateSupplier(updatedSupplier, supplierId).pipe(
        mergeMap(
          (response) =>
            [
              new actions.UpdateSupplierSuccess(response),
              new UpdateUserSuccess(response.supplierUser.user),
              new fromAppState.Go({
                path: ['suppliers'],
                alertInfo: 'alert.user_updated_l',
              }),
            ] as Action[],
        ),
        catchError((error) => [new actions.UpdateSupplierFail(error)]),
      ),
    ),
  ));

  
  getSuppliersEmployeeRoles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIERS_EMPLOYEE_ROLES),
    switchMap(() =>
      this.suppliersHttpService.getSuppliersEmployeeRoles().pipe(
        map((roles) => new actions.GetSuppliersEmployeeRolesSuccess(roles)),
        catchError(() => [new actions.GetSuppliersEmployeeRolesFail()]),
      ),
    ),
  ));

  
  getSuppliersEmployees$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIERS_EMPLOYEES),
    map((action: actions.GetSuppliersEmployees) => action.payload.supplierId),
    switchMap((id) =>
      this.suppliersHttpService.getSuppliersEmployees(id).pipe(
        map((employees) => new actions.GetSuppliersEmployeesSuccess(employees)),
        catchError(() => [new actions.GetSuppliersEmployeesFail()]),
      ),
    ),
  ));

  
  createSuppliersEmployee$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.CREATE_SUPPLIERS_EMPLOYEE),
    map((action: actions.CreateSuppliersEmployee) => action.payload),
    switchMap(({ employee, supplierId, redirectAfter }) => {
      const { defaultEmployee, supplierEmployeeRoleId, supplierLocationId, ...rest } = employee;

      return this.suppliersHttpService.createSuppliersEmployee(rest).pipe(
        mergeMap((createdEmployee) => [
          new actions.CreateSuppliersEmployeeSuccess(),
          new actions.AssignSuppliersEmployee({
            supplierId,
            employeeId: createdEmployee.id,
            body: { defaultEmployee, supplierEmployeeRoleId, supplierLocationId },
            redirectAfter,
          }),
        ]),
        catchError((e: HttpErrorResponse) => {
          if (e.error && e.error.name === ErrorsNames.USER_SIMILAR_EMAIL) {
            this.alertService.showError('settings_page.user_email_exist_l');
          }
          return [new actions.CreateSuppliersEmployeeFail()];
        }),
      );
    }),
  ));

  
  assignEmployeeToSupplier$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.ASSIGN_SUPPLIERS_EMPLOYEE),
    map((action: actions.AssignSuppliersEmployee) => action.payload),
    switchMap(({ supplierId, employeeId, body, redirectAfter }) =>
      this.suppliersHttpService.assignEmployeeToSupplier(supplierId, employeeId, body).pipe(
        mergeMap((employee) => {
          if (redirectAfter) {
            return [
              new actions.AssignSuppliersEmployeeSuccess(employee),
              new fromAppState.Go({
                path: ['suppliers'],
                alertInfo: 'alert.supplier_has_been_created_l',
              }),
            ];
          }
          this.alertService.showInfo('general.changes_saved_l');

          return [new actions.AssignSuppliersEmployeeSuccess(employee)];
        }),
        catchError(() => [new actions.AssignSuppliersEmployeeFail()]),
      ),
    ),
  ));

  
  updateSuppliersEmployee$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_SUPPLIER_EMPLOYEE),
    map((action: actions.UpdateSuppliersEmployee) => action.payload),
    switchMap(({ employee, supplierId, employeeId }) =>
      this.suppliersHttpService.updateSuppliersEmployee(employee, supplierId, employeeId).pipe(
        mergeMap((updatedEmployee) => {
          this.alertService.showInfo('general.changes_saved_l');
          return [
            new actions.UpdateSuppliersEmployeeSuccess(updatedEmployee),
            new actions.GetSuppliersEmployees({ supplierId }),
          ];
        }),
        catchError(() => [new actions.UpdateSuppliersEmployeeFail()]),
      ),
    ),
  ));

  
  getSuppliersLocations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIER_LOCATIONS),
    map((action: actions.GetSupplierLocations) => action.payload),
    switchMap(({ supplierId }) =>
      this.suppliersHttpService.getSuppliersLocations(supplierId).pipe(
        map((locations) => new actions.GetSupplierLocationsSuccess(locations)),
        catchError((err) => [new actions.GetSupplierLocationsFail(err)]),
      ),
    ),
  ));

  
  createSuppliersLocation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.CREATE_SUPPLIER_LOCATION),
    map((action: actions.CreateSupplierLocation) => action.payload),
    switchMap(({ supplierId, location, employee }) =>
      this.suppliersHttpService.createSupplierLocation(supplierId, location).pipe(
        mergeMap((createdLocation) => {
          this.alertService.showInfo('general.changes_saved_l');
          if (employee) {
            const newEmployee = assign(employee, { supplierLocationId: createdLocation.id });
            return [
              new actions.CreateSupplierLocationSuccess(createdLocation),
              new actions.SetDefaultLocation({ supplierId, locationId: createdLocation.id }),
              new actions.CreateSuppliersEmployee({
                employee: newEmployee,
                supplierId,
                redirectAfter: true,
              }),
            ];
          }

          return [new actions.CreateSupplierLocationSuccess(createdLocation)];
        }),
        catchError((err) => [new actions.CreateSupplierLocationFail(err)]),
      ),
    ),
  ));

  
  updateSupplierLocation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_SUPPLIER_LOCATION),
    map((action: actions.UpdateSupplierLocation) => action.payload),
    switchMap(({ supplierId, locationId, location }) =>
      this.suppliersHttpService.updateSupplierLocation(supplierId, locationId, location).pipe(
        map((updatedLocation) => {
          this.alertService.showInfo('general.changes_saved_l');
          return new actions.UpdateSupplierLocationSuccess(updatedLocation);
        }),
        catchError((err) => [new actions.UpdateSupplierLocationFail(err)]),
      ),
    ),
  ));

  
  getLocationTypes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_LOCATION_TYPES),
    switchMap(() =>
      this.suppliersHttpService.getLocationsTypes().pipe(
        map((locationTypes) => new actions.GetLocationTypesSuccess(locationTypes)),
        catchError((err) => [new actions.GetLocationTypesFail(err)]),
      ),
    ),
  ));

  
  setDefaultLocation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SET_DEFAULT_LOCATION),
    map((action: actions.SetDefaultLocation) => action.payload),
    switchMap(({ supplierId, locationId }) =>
      this.suppliersHttpService.setDefaultLocation(supplierId, locationId).pipe(
        map((updatedLocation) => new actions.SetDefaultLocationSuccess(updatedLocation)),
        catchError((err) => [new actions.SetDefaultLocationFail(err)]),
      ),
    ),
  ));

  
  getSuppliersVehiclesCount: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIERS_VEHICLES_COUNT),
    map((action: actions.GetSuppliersVehiclesCount) => action.payload),
    switchMap(({ supplierId, materialCategoryId }) =>
      this.suppliersHttpService.getSuppliersVehiclesCount(supplierId, materialCategoryId).pipe(
        map((vehiclesCount) => new actions.GetSuppliersVehiclesCountSuccess(vehiclesCount)),
        catchError(() => [new actions.GetSuppliersVehiclesCountFail()]),
      ),
    ),
  ));

  
  getSupplierVehicles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIER_VEHICLES),
    map((action: actions.GetSupplierVehicles) => action.payload),
    switchMap((payload) =>
      this.suppliersHttpService.getSupplierVehicles(payload).pipe(
        map((res) => new actions.GetSupplierVehiclesSuccess(res)),
        catchError((error) => [new actions.GetSupplierVehiclesFail(error)]),
      ),
    ),
  ));

  
  createSupplierVehicle$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.CREATE_SUPPLIER_VEHICLES),
    map((action: actions.CreateSupplierVehicles) => action.payload),
    switchMap((vehicles) =>
      this.suppliersHttpService.createVehicles(vehicles).pipe(
        map((res) => {
          this.alertService.showInfo('alert.vehicles_added_l');
          return new actions.CreateSupplierVehiclesSuccess(res);
        }),
        catchError((error) => [new actions.CreateSupplierVehiclesFail(error)]),
      ),
    ),
  ));

  
  getRunAvailableDrivers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_RUN_AVAILABLE_DRIVERS),
    map((action: actions.GetRunAvailableDrivers) => action.payload),
    switchMap(({ materialCategoryId }) =>
      this.suppliersHttpService.getRunAvailableDrivers(materialCategoryId).pipe(
        map((res) => new actions.GetRunAvailableDriversSuccess({ availableDrivers: res })),
        catchError((error) => [new actions.GetRunAvailableDriversFail({ error })]),
      ),
    ),
  ));

  
  getSuppliersClients$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIERS_CLIENTS),
    map((action: actions.GetSuppliersClients) => action.payload),
    switchMap(({ supplierId, params }) =>
      this.suppliersHttpService.getSuppliersClients(supplierId, params).pipe(
        map(
          (res) =>
            new actions.GetSuppliersClientsSuccess({
              suppliersClients: res.subcontractors,
              clientsCount: res.meta.count,
            }),
        ),
        catchError(() => [new actions.GetSuppliersClientsFail()]),
      ),
    ),
  ));

  
  getSuppliersProducts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_SUPPLIERS_PRODUCTS),
    map((action: actions.GetSuppliersProducts) => action.payload),
    switchMap(({ supplierId, params }) =>
      this.suppliersHttpService.getSuppliersProducts(supplierId, params).pipe(
        map((res) => new actions.GetSuppliersProductsSuccess(res)),
        catchError(() => [new actions.GetSuppliersProductsFail()]),
      ),
    ),
  ));
}
