import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import {Material, MaterialToAssign} from '@ppgt/web/tasks/domain'
import { map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class MaterialsHttpService {
  readonly endpoints = {
    getMaterials: `${environment.tasksUrl}/materials`,
    assignMaterialsToTask: `${environment.tasksUrl}`,
  }

  constructor(private http: HttpClient) {}

  getMaterials(): Observable<Material[]> {
    return this.http.get<Material[]>(this.endpoints.getMaterials, { headers: {"project-short-name": environment.clientShortname } })
      .pipe(map((response: Material[]) => response))
  }

  assignMaterials(taskParentId: string, taskChildId: string, body: MaterialToAssign[], subtaskId: string ): Observable<{ materials: Material[], taskParentId: string, taskChildId: string, subtaskId: string}> {
    return this.http.put<Material[]>(`${this.endpoints.assignMaterialsToTask}/subtasks/${taskParentId}/subtask/${taskChildId}/assign-materials`,{
      materials: body,
    }, {headers: {"project-short-name": environment.clientShortname }}).pipe(map((response: Material[]) => ({ materials: response, taskParentId, taskChildId, subtaskId })))
  }
}
