import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppState, getUserData } from '@app/state';
import { Store, select } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { PermissionsEnum } from '@ppgt/web/shared/domain';

@Directive({ selector: '[permissionAccess]' })
export class PermissionAccessDirective {
  private grantedPermissions!: string[];
  private hasAccess!: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>
  ) {
    this.store.pipe(select(getUserData), filter(Boolean), take(1)).subscribe((userData) => {
      this.grantedPermissions = userData.role.permits;
    });
  }

  @Input()
  set permissionAccess(items: any[]) {
    const isPermission = (itemToCheck: any) => Object.keys(PermissionsEnum).includes(itemToCheck);

    const hasRequiredPermission = items
      .filter((item) => isPermission(item))
      .some((requiredPermission: PermissionsEnum) => this.grantedPermissions.includes(requiredPermission));

    const extraConditions = items.filter((item) => !isPermission(item)).every((item) => !!item);

    this.hasAccess = hasRequiredPermission && extraConditions;

    if (!this.hasAccess) {
      return;
    }

    this.clearAndInsert(this.templateRef);
  }

  @Input('permissionAccessElseRender')
  set permissionAccessElseRender(template: TemplateRef<any>) {
    if (this.hasAccess) {
      return;
    }
    this.clearAndInsert(template as TemplateRef<any>);
  }

  private clearAndInsert(templateRef: TemplateRef<any>) {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(templateRef);
  }
}
