import { ActionReducer } from '@ngrx/store';

import * as effects from './reports.effect';
import * as reducers from './reports.reducer';

import { Report } from '@app/state/interfaces';

export const reportsEffects: any[] = [effects.ReportEffects];

export interface ReportsState {
  report: Report;
  loading: boolean;
  totalObjectsCount: number;
}

export const reportsReducer: ActionReducer<ReportsState> = reducers.reportReducer;

export * from './reports.action';
export * from './reports.effect';
export * from './reports.reducer';
export * from './reports.selector';
