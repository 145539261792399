import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AbbreviatePipe,
  CapitalizePipe,
  DateAddPipe,
  PhonePipe,
  TranslateFromObject,
  TranslateObjectTypePipe,
  TimeDifferencePipe,
  UnloadingHoursPipe,
  TranslateUnitPipe,
  EncodeUriPipe,
  ListPipe,
  OrderByPipe,
  DeliveryIdPipe,
  FilterUnitsByMaterialPipe,
  FilterTabsPipe,
  FilterDepotsPipe,
  FilterPlacesPipe,
  SortByDateKeyPipe,
  SortDepotsByFloorPipe,
  HasErrorPipe,
  SortByPipe,
  UniqueByPropertyPipe,
  UtcToTimezonePipe,
} from '.';

import { DasherizeModule, EmptyModule } from '@ppgt/web/shared/pipes';

const pipes = [
  PhonePipe,
  CapitalizePipe,
  AbbreviatePipe,
  TranslateFromObject,
  TranslateObjectTypePipe,
  DateAddPipe,
  ListPipe,
  TimeDifferencePipe,
  UnloadingHoursPipe,
  TranslateUnitPipe,
  EncodeUriPipe,
  ListPipe,
  OrderByPipe,
  DeliveryIdPipe,
  FilterUnitsByMaterialPipe,
  FilterTabsPipe,
  FilterDepotsPipe,
  FilterPlacesPipe,
  SortByDateKeyPipe,
  SortByPipe,
  SortDepotsByFloorPipe,
  HasErrorPipe,
  UniqueByPropertyPipe,
  UtcToTimezonePipe,
];

@NgModule({
  imports: [CommonModule, DasherizeModule, EmptyModule],
  declarations: [...pipes],
  exports: [...pipes, DasherizeModule, EmptyModule],
  providers: [...pipes],
})
export class PipesModule {}
