import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromConstructions from './construction-plan.reducer';
import { ConstructionsState } from '.';

const stateSelector = createFeatureSelector<ConstructionsState>('constructionPlan');

export const getAllConstructions = createSelector(
  stateSelector,
  fromConstructions.getConstructions
);

export const getSelectedConstruction = createSelector(
  stateSelector,
  fromConstructions.getConstruction
);

export const getSelectedCurrentConstruction = createSelector(
  stateSelector,
  fromConstructions.getCurrentConstruction
);

export const getSelectedFutureConstruction = createSelector(
  stateSelector,
  fromConstructions.getFutureConstruction
);

export const getConstructionsLoaded = createSelector(
  stateSelector,
  fromConstructions.getConstructionLoaded
);

export const getFutureConstructionsLoaded = createSelector(
  stateSelector,
  fromConstructions.getFutureConstructionLoaded
);

export const getCurrentConstructionsLoaded = createSelector(
  stateSelector,
  fromConstructions.getCurrentConstructionLoaded
);

export const getConstructionLoading = createSelector(
  stateSelector,
  (state: ConstructionsState) => state.loading
);

export const constructionQuery = {
  getSelectedCurrentConstruction,
};
