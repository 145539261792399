import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromReducers from './';
import * as fromConstructionObjects from './construction-objects.reducer';

const stateSelector = createFeatureSelector('constructionObjects');

// export const getConstructionObjectsState = createSelector(
//   stateSelector,
//   (state: fromReducers.ConstructionObjectsState) => state.
// );

export const getAllConstructionObjects = createSelector(
  stateSelector,
  fromConstructionObjects.getConstructionObjects
);

export const getSelectedConstructionObject = createSelector(
  stateSelector,
  fromConstructionObjects.getConstructionObject
);

export const getConstructionObjectsLoaded = createSelector(
  stateSelector,
  fromConstructionObjects.getConstructionObjectLoaded
);
