import { ActionReducer } from '@ngrx/store';

import * as effects from './depots.effect';
import * as reducers from './depots.reducer';

import { Depot, MaterialDepot } from '@app/state/interfaces';

export const depotsEffects: any[] = [effects.DepotsEffects];

export interface DepotsListState {
  depots: Depot[];
  materialDepots: MaterialDepot[];
  depot: Depot;
  totalObjectsCount: number;
  loaded: boolean;
  loading: boolean;
}

export const depotsReducer: ActionReducer<DepotsListState> =
  reducers.depotsReducer;
