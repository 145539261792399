import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { TasksPartialState } from './tasks.reducer'
import { subtasksQuery, tasksQuery } from './tasks.selectors'
import * as fromTasksActions from './tasks.actions'

@Injectable()
export class TasksFacade {
  tasks$ = this.store.pipe(select(tasksQuery.getTasks))
  subtasks$ = this.store.pipe(select(subtasksQuery.getSubtasks))

  constructor(private store: Store<TasksPartialState>) {}

  getTasks(): void {
    this.store.dispatch(fromTasksActions.getTasks())
  }

  getSubtasks(parentId: string, childId: string): void {
    this.store.dispatch(fromTasksActions.getSubtasks({parentId, childId}))
  }

  getTasksImportSource(): void {
    this.store.dispatch(fromTasksActions.getTasksImportSource())
  }

  resetTasksData(): void {
    this.store.dispatch(fromTasksActions.resetTasksData())
  }
}
