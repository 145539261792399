import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[type=number]',
})
export class ReplaceCommaWithDotDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) replaceComma(): void {
    const nativeEl = this.el.nativeElement as HTMLInputElement;
    nativeEl.value.replace(/,/g, '.');
  }
}
