import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as RouterActions from '../actions/router.actions';

import { tap, map } from 'rxjs/operators';
import { AlertService } from '@ppgt/web/shared/core';
import { DeliveriesService } from '@app/deliveries/deliveries.service';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private router: Router,
    private location: Location,
    private deliveriesService: DeliveriesService
  ) {}

  
  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.GO),
    map((action: RouterActions.Go) => action.payload),
    tap(
      ({
        path,
        query: queryParams,
        extras,
        alertInfo,
        alertError,
        openDetailsPanel,
      }) => {
        this.router.navigate(path, { queryParams, ...extras }).then(() => {
          if (alertInfo) {
            this.alertService.showInfo(alertInfo);
          }

          if (alertError) {
            this.alertService.showError(alertError);
          }

          if (openDetailsPanel) {
            const { opened, type, status, id } = openDetailsPanel;
            this.deliveriesService.toggleDeliveryDetails({ opened, type, status, id });
          }
        });
      }
    )
  ), { dispatch: false });

  
  navigateBack$ = createEffect(() => this.actions$
    .pipe(
      ofType(RouterActions.BACK),
      tap(() => this.location.back())
    ), { dispatch: false });

  
  navigateForward$ = createEffect(() => this.actions$
    .pipe(
      ofType(RouterActions.FORWARD),
      tap(() => this.location.forward())
    ), { dispatch: false });
}
