import { CanDeactivate } from '@angular/router';
import { ConfirmService } from '@ppgt/web/shared/ui/confirm';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

class CanDeactivateCmp {
  areChangesSaved: boolean;
  confirmMessage: string;
}
@Injectable({
  providedIn: 'root',
})
export class DeactivationGuard implements CanDeactivate<CanDeactivateCmp> {
  constructor(private confirmService: ConfirmService) {}

  canDeactivate(target: CanDeactivateCmp): boolean | Observable<boolean> {
    return !target || target.areChangesSaved || this.confirmService.open(target.confirmMessage);
  }
}
