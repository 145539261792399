import { ActionReducer } from '@ngrx/store';

import * as effects from './construction-objects.effect';
import * as reducers from './construction-objects.reducer';

export const constructionObjectsEffects: any[] = [
  effects.ConstructionObjectsEffects,
];

export interface ConstructionObjectsState {
  objects?: any;
  object?: any;
  loaded: boolean;
}

export const constructionObjectsReducer: ActionReducer<
  ConstructionObjectsState
> =
  reducers.constructionObjectsReducer;

export * from './construction-objects.action';
export * from './construction-objects.effect';
export * from './construction-objects.reducer';
export * from './construction-objects.selector';
