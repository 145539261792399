import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  AddDeliveryMaterialValues,
  Building,
  Construction,
  DeliveryFormedFilled,
  Depot,
  Equipment,
  EquipmentDeliveriesDetails,
  MaterialCategory,
  Propose,
  Subcontractor,
  TimeProposal,
  UnloadingPlace,
  User,
  VehicleSize,
  Delivery,
} from '@app/state/interfaces';
import { GenericForm } from '@app/shared/@interfaces';
import { IMyDateModel, IMyOptions } from 'mydatepicker';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DateTimeService } from '@app/core/date-time.service';
import { AlertService } from '@ppgt/web/shared/core';
import * as moment from 'moment-timezone';
import { Observable, Subject, merge } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, skip, startWith, take, takeUntil, throttleTime } from 'rxjs/operators';
import { flatten, get, pick, range } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import { FormService } from '@app/shared/@services/form.service';
import * as appState from '@app/state';
import { getAllProjectSettings, GetMaterialCategories, getMaterialCategoriesList, getUserData } from '@app/state';
import {
  CreateDeliveryReq,
  DeliveryOtherCreation,
  EquipmentTypes,
  MapObjectTypes,
  ProposeDeliveryReq,
  QueryParamObject,
  QueryParams,
} from '@app/core/http/interfaces';
import { DeviceService } from '@app/core/device.service';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { AddDeliveryMaterialComponent } from '../add-delivery-material/add-delivery-material.component';
import { NewDeliveryOther, redirectObj } from '../add-delivery.utils';
import { MapService } from '@app/shared/map/map.service';
import { DeliveryDirections } from '@app/shared/@constants/delivery-directions';
import { SchedulerEventCreate, SchedulerDelivery, SchedulerMaterial } from '@ppgt/web/scheduler/domain';
import { DeliveryTypeDetail, DeliveryType } from '@ppgt/web/shared/domain';
import { SchedulerPublicService } from '@ppgt/web/scheduler/public';

@Component({
  selector: 'app-add-delivery-other',
  templateUrl: './add-delivery-other.component.html',
  styleUrls: ['./add-delivery-other.component.scss'],
})
export class AddDeliveryOtherComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChildren(AddDeliveryMaterialComponent)
  private materialComponents: QueryList<AddDeliveryMaterialComponent>;

  @Input() lang: string;
  @Input() hasSubcontractorInputPermission: boolean;
  @Input() vehicleSizes: VehicleSize[];
  @Input() subcontractors: Subcontractor[] = [];
  @Input() currentType: DeliveryTypeDetail;
  @Input() me: User;
  @Input() unloadingTimes: { label: string; value: number }[];
  @Input() isClosed: boolean;
  @Input() fromTooltipConfig?: EquipmentDeliveriesDetails;
  @Input() typeChange$: Subject<DeliveryTypeDetail>;
  @Input() schedulerDeliveryData: SchedulerEventCreate | null;
  @Input() deliveryData: Partial<Delivery> = null;
  @Input() taskData = null;

  @Output() private createDelivery = new EventEmitter<CreateDeliveryReq>();
  @Output() public close = new EventEmitter<void>();
  @Output() private formChanged = new EventEmitter<NewDeliveryOther>();

  public form: GenericForm<NewDeliveryOther>;
  public datePickerOptions: IMyOptions;
  public equipment: Equipment[] = [];
  public propose$: Observable<Propose[]>;
  public places: UnloadingPlace[] = [];
  public propose: Propose[] = [];
  public proposeForm: ProposeDeliveryReq;
  public selectedItem: string;
  public loading = false;
  public loaded = false;
  public craneEq: Equipment;
  public cranes: Equipment[] = [];
  public excavators: Equipment[] = [];
  public elevators: Equipment[] = [];
  public forklifts: Equipment[] = [];
  public miniExcavators: Equipment[] = [];
  public mobileCranes: Equipment[] = [];
  public elevatorFloors: number[] = [];
  public depots: Depot[] = [];
  public allDepotsForBuilding: Depot[] = [];
  public place: UnloadingPlace;
  public selectTimes: { timeId: string; label: string }[] = [];
  public currentPage: string;
  public redirectObj = redirectObj;
  public timeRanges: string[];
  public noLimit: QueryParamObject = { type: QueryParams.NoLimit };
  public materialCategories: MaterialCategory[];
  public addDeliveryMaterialValues: AddDeliveryMaterialValues[] = [null];
  public buildings: Building[] = [];
  public projectLanguage: string;
  public usersLanguage: string;
  public files: File[] = [];
  public floorsRange: number[] = [];
  public elevatorId: string;
  public elevatorFirstChange = true;
  private _times: TimeProposal[] = [];
  private destroy$ = new Subject<void>();
  private allowSchedulerUpdate = true;

  private equipmentsWithCheckboxAndSelect: EquipmentTypes[] = [
    EquipmentTypes.Crane,
    EquipmentTypes.Elevator,
    EquipmentTypes.MobileCrane,
    EquipmentTypes.MiniExcavator,
    EquipmentTypes.Forklift,
    EquipmentTypes.Excavator,
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateTime: DateTimeService,
    private alertService: AlertService,
    private formService: FormService,
    private router: Router,
    private location: Location,
    private store: Store<appState.AppState>,
    private route: ActivatedRoute,
    private deliveriesService: DeliveriesService,
    private mapService: MapService,
    private translateService: TranslateService,
    public deviceService: DeviceService,
    private schedulerPublicService: SchedulerPublicService
  ) {}

  ngOnChanges({ unloadingTimes, isClosed, fromTooltipConfig, schedulerDeliveryData, deliveryData }: SimpleChanges) {
    if (deliveryData) {
      const deliveryMaterials = deliveryData.currentValue?.deliveryMaterials;
      deliveryMaterials.forEach((material) => {
        this.addMaterial({ formValue: material });
      });
    }

    if (isClosed?.currentValue) {
      this.clearFilledForm();
      return;
    }

    if (fromTooltipConfig) {
      this.fromTooltipConfig = fromTooltipConfig.currentValue;
      this.handleTooltipConfig();
    }

    if (schedulerDeliveryData) {
      this.patchFromSchedulerData();
    }

    if (
      !unloadingTimes ||
      !unloadingTimes.currentValue ||
      !this.form ||
      this.unloadingTimes.map((item) => item.value).includes(this.form.value.unloadingTime)
    ) {
      return;
    }

    this.form.get('unloadingTime').setValue(null);
  }

  ngOnInit() {
    this.form = this.createForm();
    this.datePickerOptions = this.dateTime.getDatePickerOptions();
    this.timeRanges = this.dateTime.createTimeRanges();
    this.listenToFormChange();
    this.listenToUrlChange();
    this.listenToTypeChange();

    this.store
      .select(getAllProjectSettings)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((settings) => {
        this.projectLanguage = settings.defaultLanguageApp;
      });

    this.store
      .select(getUserData)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((settings) => {
        this.usersLanguage = settings.language;
      });

    this.startMaterialCategoriesStream();
    this.initValueChangesStreams();
    this.setTodayAsDefault();
    this.handleOpeningFromTooltip();

    if (this.hasSubcontractorInputPermission) {
      this.form.get('subcontractorId').setValue(this.me.subcontractor.id);
    }

    if (this.taskData) {
      this.form.get('subcontractorId').disable();
    }
  }

  private listenToTypeChange(): void {
    this.typeChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.get('buildingId').setValue(null);
      this.form.get('preferredPlace').setValue(null);
      this.loaded = false;
      this.times = [];
      this.places = [];
    });
  }

  private listenToUrlChange(): void {
    this.currentPage = this.router.url;

    this.router.events
      .pipe(
        filter((event) => event instanceof RouterEvent),
        takeUntil(this.destroy$)
      )
      .subscribe((event: RouterEvent) => {
        this.currentPage = event.url;
      });
  }

  private patchForm(): void {
    const deliveryData = this.deliveriesService.onetimeDeliveryData;

    if (deliveryData) {
      this.addDeliveryMaterialValues = deliveryData.deliveryMaterials.map((material) => ({
        formValue: {
          ...material,
          import: material.direction === DeliveryDirections.Import || material.direction === DeliveryDirections.Both,
          export: material.direction === DeliveryDirections.Export || material.direction === DeliveryDirections.Both,
        },
      }));

      this.form.patchValue({
        ...deliveryData,
        unloadingTime: Number(deliveryData.unloadingTime),
      });
    }
  }

  private handleOpeningFromTooltip(): void {
    this.fromTooltipConfig = this.mapService.getTooltipDataForNewDelivery();
    if (this.fromTooltipConfig) {
      this.handleTooltipConfig();
    }

    this.mapService.tooltipDataForNewDelivery$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.fromTooltipConfig = data;
      this.handleTooltipConfig();
    });
  }

  private initValueChangesStreams() {
    this.store
      .select(appState.getAllBuildings)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((buildings) => {
        this.buildings = [...buildings].sort((p, c) => p.shortName.localeCompare(c.shortName, 'en-u-kn'));
        this.form.get('buildingId').setValue(null);

        if (buildings.length === 1) {
          this.form.get('buildingId').setValue(buildings[0].id);
        }

        if (buildings.length !== 0) {
          this.patchForm();
        }

        this.patchFromSchedulerData();
      });

    this.valueChanges('buildingId')
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((buildingId) => {
        const building = this.buildings.find(({ id }) => id === buildingId);

        if (building) {
          this.buildingChanged(building);
        }
      });

    this.valueChanges('preferredPlace')
      .pipe(takeUntil(this.destroy$))
      .subscribe((placeId) => {
        this.filterTimes();
        this.place = this.places.find((place) => place.id === placeId);
      });

    this.valueChanges('craneId')
      .pipe(takeUntil(this.destroy$))
      .subscribe((craneId) => {
        if (!craneId) {
          this.craneEq = null;
          return;
        }

        this.craneEq = this.cranes.find(({ id }) => id === craneId);
      });

    this.highlightObjectOnChange();

    this.equipmentsWithCheckboxAndSelect.forEach((controlName) =>
      this.valueChanges(controlName as keyof NewDeliveryOther).subscribe((value) => {
        if (!value) {
          this.form.get(`${controlName}Id`).patchValue(null);
        }
      })
    );

    this.valueChanges('crane').subscribe((crane) => {
      let craneId = null;

      if (crane && this.cranes.length > 0) {
        craneId = crane.id;
      }

      this.form.patchValue({ craneId });
    });

    this.valueChanges('elevator').subscribe((elevator) => {
      if (elevator && this.elevators.length === 1) {
        this.form.get('elevatorId').setValue(this.elevators[0].id);
      }

      this.form.get('elevatorId').clearValidators();
      this.form.get('elevatorFloor').clearValidators();

      if (elevator) {
        this.form.get('elevatorId').setValidators(Validators.required);
        this.form.get('elevatorFloor').setValidators(Validators.required);
      }

      if (elevator === false) {
        this.form.get('elevatorId').reset();
        this.form.get('elevatorFloor').reset();
      }

      this.form.get('elevatorId').updateValueAndValidity();
      this.form.get('elevatorFloor').updateValueAndValidity();
    });

    this.valueChanges('elevatorId')
      .pipe(skip(1))
      .subscribe((elevatorId) => {
        this.elevatorId = elevatorId;

        if (!this.elevatorFirstChange) {
          this.form.get('preferredPlace').patchValue(null);
        }

        this.elevatorFirstChange = false;

        if (!elevatorId) {
          this.elevatorFloors = [];
          return;
        }

        const elevator = this.elevators.find(({ id }) => id === elevatorId);

        if (elevator) {
          const floorsLength = elevator.floorTo - elevator.floorFrom + 1;
          this.elevatorFloors = Array.from(Array(floorsLength)).map((_, i) => i + elevator.floorFrom);
        }
      });

    this.store
      .pipe(select(appState.getDeliveryFormFilled), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((formfilled) => {
        this.form.patchValue(formfilled.formValue);
        this.times = formfilled.times;
        this.propose = formfilled.propose;

        if (formfilled.formValue.dateUnloadingId) {
          this.onProposalSelection(formfilled.formValue.dateUnloadingId);
          this.loaded = true;
        }

        this.addDeliveryMaterialValues = formfilled.addDeliveryMaterialValues;
      });

    this.deliveriesService.proposalDate$
      .pipe(
        filter((date) => date && date !== get(this.form.get('proposal').value, 'time')),
        takeUntil(this.destroy$)
      )
      .subscribe((date) => {
        this.onProposalSelection(date, true);
      });
  }

  private getConstruction(isConstruction: Construction) {
    if (!isConstruction) {
      this.store.dispatch(new appState.GetCurrentConstruction());
    }
  }

  public handleFile(files: FileList): void {
    for (let i = 0; i <= files.length; i++) {
      const file = files.item(i);

      if (file) {
        const isAlready = this.files.some((actualFile) => actualFile.name === file.name);
        let fileIsValid = true;

        if (file.type !== 'application/pdf') {
          this.alertService.showError('deliveries_page.has_to_be_pdf');
          fileIsValid = false;
        }

        if (isAlready) {
          this.alertService.showError('deliveries_page.same_file_name');
          fileIsValid = false;
        }

        if (fileIsValid) {
          this.files.push(file);
        }
      }
    }
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
  }

  private handleTooltipConfig(): void {
    if (!this.fromTooltipConfig) {
      return;
    }

    const { buildings, type, id } = this.fromTooltipConfig;
    this.fromTooltipConfig = null;

    if (!(buildings && buildings.length)) {
      return;
    }

    this.form.get('buildingId').patchValue(buildings[0].id);

    if (this.equipmentsWithCheckboxAndSelect.includes(type as EquipmentTypes)) {
      this.form.get(type).patchValue(true);
      this.form.get(`${type}Id`).patchValue(id);
    }

    if (type === MapObjectTypes.Place) {
      this.form.get('preferredPlace').patchValue(id);
    }
  }

  private createForm() {
    const form = this.formBuilder.group({
      unloadingTime: [null, Validators.required],
      buildingId: [null, Validators.required],
      subcontractorId: [null, Validators.required],
      vehicleSizeId: [null, Validators.required],
      crane: false,
      craneId: null,
      elevator: false,
      elevatorId: null,
      elevatorFloor: null,
      excavator: false,
      excavatorId: null,
      miniExcavator: false,
      miniExcavatorId: null,
      forklift: false,
      forkliftId: null,
      mobileCrane: false,
      mobileCraneId: null,
      depot: false,
      depotId: null,
      depotAmount: [null, Validators.min(0)],
      depotDays: [null, Validators.min(0)],
      combined: false,
      entry24: false,
      proposal: null,
      dateUnloadingId: null,
      preferredPlace: null,
      preferredDate: [null, Validators.required],
      timeRange: [null, Validators.required],
      floor: null,
    });
    return form;
  }

  public set times(value: TimeProposal[]) {
    this._times = value;
    this.selectTimes = value.map((timeProposal) => ({
      timeId: timeProposal.timeId,
      label: `${moment(timeProposal.time).format('DD-MM-YYYY HH:mm')} ${timeProposal.place}`,
    }));
  }

  public get times() {
    return this._times;
  }

  get subcontractorId() {
    return this.form.value.subcontractorId;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.isClosed || !this.me) {
      return;
    }

    this.mapService.clearTooltipDataForNewDelivery();
    this.saveForm();
  }

  private setTodayAsDefault() {
    const date = this.dateTime.getDatePickerToday();
    this.form.get('preferredDate').patchValue(date);
  }

  private startMaterialCategoriesStream() {
    this.valueChanges('subcontractorId').subscribe((subcontractorId) => {
      const payload: QueryParamObject[] = [this.noLimit];

      if (subcontractorId) {
        payload.push({
          type: QueryParams.SingleValue,
          key: 'subcontractorId',
          value: subcontractorId,
        });
      }

      this.store.dispatch(new GetMaterialCategories(payload));
    });

    this.store
      .select(getMaterialCategoriesList)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((categories) => {
        this.materialCategories = categories;
      });
  }

  public addNewMaterialCategory(name: string) {
    const newItem = { id: null as string, name, translatedName: name, label: name };
    this.materialCategories = [newItem, ...this.materialCategories];
  }

  public addMaterial(material = null) {
    this.addDeliveryMaterialValues.push(material);
  }

  public removeMaterial(i: number) {
    if (this.addDeliveryMaterialValues.length === 1) {
      this.materialComponents.first.form.reset();
      this.materialComponents.first.form.enable();
    } else {
      this.addDeliveryMaterialValues.splice(i, 1);
    }
  }

  private valueChanges<K extends keyof NewDeliveryOther>(controlName: K): Observable<NewDeliveryOther[K]> {
    return this.form
      .get(controlName)
      .valueChanges.pipe(startWith(null), distinctUntilChanged(), takeUntil(this.destroy$));
  }

  private buildingChanged(building: Building) {
    this.loaded = false;
    this.times = [];

    this.patchFromSchedulerData();

    this.resetBuildingDependentControls();

    const { craneId } = this.form.value;

    const { equipment, places, depots, minFloor, maxFloor } = building;

    this.floorsRange = range(minFloor, maxFloor + 1);

    this.equipment = equipment;

    this.elevators = this.getEquipment(EquipmentTypes.Elevator);
    this.cranes = this.getEquipment(EquipmentTypes.Crane);
    this.excavators = this.getEquipment(EquipmentTypes.Excavator);
    this.forklifts = this.getEquipment(EquipmentTypes.Forklift);
    this.miniExcavators = this.getEquipment(EquipmentTypes.MiniExcavator);
    this.mobileCranes = this.getEquipment(EquipmentTypes.MobileCrane);

    this.places = places
      .filter((place) => (this.currentType === DeliveryTypeDetail.Fast ? place.hotspot : !place.hotspot))
      .filter(({ equipment }) => !craneId || (equipment && equipment.some(({ id }) => id === craneId)));

    this.depots = depots;
    this.allDepotsForBuilding = depots;

    this.initEquipmentInputs();
  }

  public craneChanged() {
    this.times = [];
    this.loaded = false;
  }

  public floorChanged(floor: number | string): void {
    this.form.get('depotId').patchValue(null);

    if (floor === '') {
      this.depots = this.allDepotsForBuilding;
      return;
    }

    this.depots = this.allDepotsForBuilding.filter(
      ({ buildingFloor }) => (buildingFloor && buildingFloor.floorNumber === floor) || (!buildingFloor && floor === 0)
    );
  }

  private highlightObjectOnChange(): void {
    merge(
      this.valueChanges('buildingId'),
      this.valueChanges('preferredPlace'),
      this.valueChanges('craneId'),
      this.valueChanges('elevatorId'),
      this.valueChanges('excavatorId'),
      this.valueChanges('forkliftId'),
      this.valueChanges('mobileCraneId'),
      this.valueChanges('miniExcavatorId')
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((objectId: string) => {
        this.mapService.highlightObjectsById([objectId], true);
      });
  }

  private initEquipmentInputs() {
    this.setInputProps('crane', Boolean(this.cranes.length));
    this.setInputProps('elevator', Boolean(this.elevators.length));
    this.setInputProps('excavator', Boolean(this.excavators.length));
    this.setInputProps('miniExcavator', Boolean(this.miniExcavators.length));
    this.setInputProps('forklift', Boolean(this.forklifts.length));
    this.setInputProps('mobileCrane', Boolean(this.mobileCranes.length));
  }

  private resetBuildingDependentControls() {
    const controls = [
      'preferredPlace',
      'craneId',
      'elevatorId',
      'excavatorId',
      'forkliftId',
      'mobileCraneId',
      'miniExcavatorId',
    ];
    for (const controlName of controls) {
      this.form.get(controlName).setValue(null);
    }
  }

  private getEquipment(type: EquipmentTypes): Equipment[] {
    const isActive = (eq: Equipment) => eq.active && !eq.archived;
    const hasStatus = (eq: Equipment) => eq.status;
    return this.equipment
      .filter(isActive)
      .filter(hasStatus)
      .filter((eq) => eq.type === type);
  }

  private setInputProps(input: string, status = true, value: any = null): void {
    const element = this.form.get(input);

    if (element) {
      if (status) {
        element.enable();
      } else {
        element.disable();
      }
      element.setValue(value);
    }
  }

  private filterTimes() {
    this.times = [];
    const { preferredPlace } = this.form.value;

    const times = this.propose
      .filter(({ placeId }) => !preferredPlace || placeId === preferredPlace)
      .map((propose) =>
        (propose.times || []).map((time) => {
          const timeId = `${propose.placeNumber}-${Date.parse(time)}`;
          return { ...propose, time, timeId };
        })
      );

    this.times = flatten(times).sort((t1, t2) => Date.parse(t1.time) - Date.parse(t2.time));
  }

  public get redirect() {
    return this.currentPage.includes(this.redirectObj.constructionPlan.path)
      ? this.redirectObj.schedules
      : this.redirectObj.constructionPlan;
  }

  public saveFormAndRedirect() {
    let queryParams = {};

    if (this.redirect.path === this.redirectObj.schedules.path) {
      const date = this.form.get('preferredDate').value?.jsdate;
      queryParams = { date: moment(date).format('YYYY-MM-DD') };
    }

    this.router.navigate([this.redirect.path], { queryParams });
  }

  public checkGates(placeId: string) {
    const place = this.places.find(({ id }) => id === placeId);
    return place && place.gates.some((gate) => gate.leave) && place.gates.some((gate) => gate.enter);
  }

  public onProposalSelection(timeId: string, fromSource = false) {
    const proposal = this.times.find((time) => time.timeId === timeId || time.time === timeId);
    this.form.get('proposal').setValue(proposal || null);

    if (fromSource && proposal?.timeId) {
      this.form.get('dateUnloadingId').setValue(proposal.timeId);
      return;
    }

    this.deliveriesService.proposalDateSelected(proposal && proposal.time);
  }

  public findTerm(again: boolean) {
    this.formService.validateAllFormFields(this.form);

    const materialFormInvalid = this.materialComponents.some((materialComponent) => !materialComponent.checkValid());

    if (this.form.invalid || materialFormInvalid) {
      this.alertService.showError('alert.invalid_data_l');
      return false;
    }

    this.form.get('dateUnloadingId').setValue(null);
    this.times = [];

    this.loaded = false;
    this.loading = true;

    this.createProposeForm();

    if (again) {
      this.form.patchValue({ proposal: null, dateUnloadingId: null });
    }

    this.store.dispatch(new appState.ProposeDelivery(this.proposeForm));

    // @TODO Change handling of data-streams reserved only for this form
    // (data once loaded remains in global state even after closing/submitting the form)
    this.initProposalsStream();
  }

  private initProposalsStream() {
    this.store
      .pipe(select(appState.getProposeDeliveries), takeUntil(this.destroy$), filter(Boolean), take(1))
      .subscribe((propose) => {
        this.propose = propose;
        this.filterTimes();

        this.loaded = true;
        this.loading = false;
      });
  }

  public createProposeForm() {
    const { preferredDate, timeRange } = this.form.value;
    const { firstTerm, lastTerm } = this.generateDateRange(preferredDate, timeRange);

    this.proposeForm = {
      ...pick(this.form.getRawValue(), [
        'unloadingTime',
        'buildingId',
        'excavatorId',
        'forkliftId',
        'miniExcavatorId',
        'mobileCraneId',
        'craneId',
        'elevatorId',
        'elevatorFloor',
        'depot',
        'depotId',
        'depotAmount',
        'depotDays',
        'preferredPlace',
        'subcontractorId',
      ]),
      hotspot: this.currentType === DeliveryTypeDetail.Fast,
      firstTerm,
      lastTerm,
    };
  }

  public generateDateRange({ formatted }: IMyDateModel, timeRange: string) {
    const [startHour, endHour] = timeRange.split(/\s-\s/);
    const addTime = (hour: string) => moment(`${formatted} ${hour}`, 'DD/MM/YYYY HH:mm').valueOf();
    const firstTerm = addTime(startHour);
    const lastTerm = addTime(endHour);
    return { firstTerm, lastTerm };
  }

  public parseData() {
    const { proposal, elevator, ...formValues } = this.form.getRawValue();

    const dateUnloading = this.schedulerDeliveryData ? formValues.preferredDate : proposal?.time;
    const placeId = this.schedulerDeliveryData ? formValues.preferredPlace : proposal?.placeId;

    if (!dateUnloading) {
      this.alertService.showError('alert.choose_term_l');
      return;
    }

    this.translateService.use(this.projectLanguage);

    const cargos = this.materialComponents.map((materialComponent) => {
      const cargo = materialComponent.getData();
      const unit = cargo ? this.translateService.instant(cargo.unit) : null;
      if (!cargo.producerId) {
        delete cargo.producerId;
      }

      const parsedData = {
        ...cargo,
        unit,
      };

      if (this.taskData) {
        return {
          ...parsedData,
          taskChildId: this.taskData.lastChildID,
          taskParentId: this.taskData.parentID,
          fullTaskName: this.taskData.name,
        };
      }

      return parsedData;
    });

    this.translateService.use(this.usersLanguage);

    if (cargos.includes(null)) {
      this.alertService.showError('alert.invalid_data_l');
      return;
    }

    const data: DeliveryOtherCreation = {
      ...formValues,
      cargos,
      dateUnloading: Date.parse(dateUnloading as string),
      typeDelivery: DeliveryType.Onetime,
      status: 'UPDATE_REQUIRED',
      hotspot: this.currentType === DeliveryTypeDetail.Fast,
      elevatorFloor: elevator ? formValues.elevatorFloor : null,
      depotId: formValues.depot ? formValues.depotId : null,
      depotAmount: formValues.depot ? formValues.depotAmount : null,
      depotDays: formValues.depot ? formValues.depotDays : null,
      placeId,
    };

    if (this.hasSubcontractorInputPermission) {
      data.subcontractorId = this.me.subcontractor.id;
    }

    return data;
  }

  @HostListener('document:keydown.enter', ['$event'])
  public addDelivery(event?: any): void {
    if (event && event.target.type === 'textarea') return;

    if (!this.validateForm()) return;

    this.dispatchDelivery({ openPanel: true });
  }

  public reserveDelivery() {
    if (!this.validateForm()) return;

    this.dispatchDelivery({ openPanel: false, duplicateAfter: true });
  }

  public reserveAndDuplicateDelivery(): void {
    if (!this.validateForm()) return;

    this.dispatchDelivery({ duplicateAfter: true, openPanel: true });

    this.form.get('timeRange').patchValue(null);
    this.form.get('dateUnloadingId').patchValue(null);
    this.files = [];
    this.loaded = false;
    this.selectTimes = [];
  }

  public dispatchDelivery(payload: { openPanel?: boolean; duplicateAfter?: boolean }) {
    this.isClosed = !payload.duplicateAfter;
    const delivery = this.parseData();

    if (delivery) {
      this.createDelivery.emit({
        delivery,
        ...payload,
        files: this.files,
      });
    }
  }

  public back() {
    this.location.back();
  }

  public cancel() {
    this.isClosed = true;
    this.close.emit();
  }

  public hasError(name: string, errorType: string): boolean {
    return this.form.get(name).hasError(errorType) && this.form.get(name).touched;
  }

  private validateForm(): boolean {
    if (this.form.invalid) {
      this.formService.validateAllFormFields(this.form);
      this.alertService.showError('alert.invalid_data_l');
      return false;
    }

    const invalidMaterialComponent = this.materialComponents.find(({ form }) => form.invalid);
    if (invalidMaterialComponent) {
      this.formService.validateAllFormFields(invalidMaterialComponent.form);
      this.alertService.showError('alert.invalid_data_l');
      return false;
    }

    const { eventValidation } = this.schedulerPublicService;
    if (this.schedulerDeliveryData && eventValidation.valid === false) {
      this.alertService.showError(eventValidation.message);
      return false;
    }

    return true;
  }

  private saveForm() {
    const addDeliveryMaterialValues: AddDeliveryMaterialValues[] = this.materialComponents.map((materialComponent) => ({
      formValue: materialComponent.form.getRawValue(),
    }));

    const payload: DeliveryFormedFilled = {
      formValue: this.form.getRawValue(),
      propose: this.propose,
      times: this.times,
      type: this.currentType,
      addDeliveryMaterialValues,
    };

    this.store.dispatch(new appState.DeliveryFormed(payload));
  }

  private clearFilledForm(): void {
    this.store.dispatch(new appState.DeliveryFormed(null));
  }

  private listenToFormChange(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.form.dirty),
        throttleTime(500)
      )
      .subscribe((data) => {
        if (this.schedulerDeliveryData && this.allowSchedulerUpdate) {
          const schedulerDelivery = this.convertToSchedulerDelivery(data);
          this.schedulerPublicService.updateEvent(schedulerDelivery);
        }
        this.formChanged.emit(data);
      });
  }

  private patchFromSchedulerData(data = this.schedulerDeliveryData): void {
    if (!data || !this.form) return;

    this.allowSchedulerUpdate = false;

    const { event, resource } = data;

    this.updateAllEquipmentControlsValues(event as SchedulerDelivery);

    if (resource) {
      const buildingControl = this.form.get('buildingId');

      if (!buildingControl.value && this.buildings.length) buildingControl.patchValue(resource.buildings[0]?.id);

      this.setEquipmentControlsValue(resource.type, resource.id);
    }

    const place = event.resourceAssignments.find(({ type }) => type === 'place');

    const unloadingTime = moment.duration(moment(event.endDate).diff(event.startDate)).asMinutes();
    this.form.patchValue({
      preferredPlace: place?.id,
      preferredDate: moment(event.startDate).toISOString(),
      unloadingTime,
    });

    this.updateSchedulerDependentValidators();
    this.allowSchedulerUpdate = true;
  }

  private updateAllEquipmentControlsValues(delivery: SchedulerDelivery): void {
    this.equipmentsWithCheckboxAndSelect.forEach((controlName) => {
      this.setEquipmentControlsValue(controlName);
    });

    delivery.resourceAssignments.forEach((resource) => {
      this.setEquipmentControlsValue(resource.type, resource.id);
    });
  }

  private setEquipmentControlsValue(type: string, value: string | null = null): void {
    if (type === 'place') {
      this.form.get('preferredPlace').patchValue(value);
      return;
    }
    this.form.get(type).patchValue(!!value);
    this.form.get(`${type}Id`).patchValue(value);
  }

  private updateSchedulerDependentValidators(): void {
    this.form.get('preferredDate').removeValidators(Validators.required);
    this.form.get('preferredDate').updateValueAndValidity();

    this.form.get('timeRange').removeValidators(Validators.required);
    this.form.get('timeRange').updateValueAndValidity();

    this.form.get('preferredPlace').addValidators(Validators.required);
    this.form.get('preferredPlace').updateValueAndValidity();
  }

  private convertToSchedulerDelivery = (data): Partial<SchedulerDelivery> => {
    const resourceAssignments = this.equipmentsWithCheckboxAndSelect.reduce((assignments, equipmentType) => {
      const equipmentId = data[`${equipmentType}Id`];
      return equipmentId ? [...assignments, { id: equipmentId, type: equipmentType }] : assignments;
    }, []);

    if (data.preferredPlace) {
      resourceAssignments.push({ id: data.preferredPlace, type: 'place' });
    }

    const subcontractor = this.subcontractors?.find(({ id }) => id === data.subcontractorId);
    const schedulerEvent: Partial<SchedulerDelivery> = {
      subcontractorName: subcontractor?.companyName,
      subcontractorCode: subcontractor?.companyCode,
      subcontractorId: subcontractor?.id,
      resourceAssignments,
      buildingId: data.buildingId,
      materials: this.convertToSchedulerMaterials(),
    };

    if (data.unloadingTime) {
      const startDate = this.schedulerDeliveryData?.event?.startDate;
      schedulerEvent.endDate = moment(startDate).add(data.unloadingTime, 'minutes').toDate();
    }

    return schedulerEvent;
  };

  private convertToSchedulerMaterials(): SchedulerMaterial[] {
    const materialComponent = this.materialComponents.first;
    const materialData = materialComponent.form.getRawValue();
    const { materialId } = materialData;
    const allMaterials = materialComponent.allMaterials;
    return allMaterials.filter(({ id }) => id === materialId) as SchedulerMaterial[];
  }
}
