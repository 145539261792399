import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromConstructionCrews from './construction-crews.reducer';
import { ConstructionCrewsState } from '.';

const stateSelector = createFeatureSelector<ConstructionCrewsState>('constructionCrews');

export const getAllConstructionCrews = createSelector(stateSelector, fromConstructionCrews.getConstructionCrews);

export const getSelectedConstructionCrew = createSelector(stateSelector, fromConstructionCrews.getConstructionCrew);

export const getConstructionCrewsLoading = createSelector(
  stateSelector,
  (state: ConstructionCrewsState) => state.loading
);
