import {
  tables,
  nav,
  buttonPermission,
  planObjects,
  filtering,
  filteringDeliveries,
  cargoType,
  materialDurabilities,
  objects,
} from './config';
import { IMyOptions } from 'mydatepicker';
import { DeliveryFrontStatus } from './config/interfaces';

export const config = {
  title: 'ProperGate',
  idLength: 36,
  cannyAppId: '5d8e57c71fe466346f4f7ada',
  storageTokenKey: 'token',
  tables,
  nav,
  buttonPermission,
  filtering,
  filteringDeliveries,
  objects,
  passwordPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!-\/:-@[-`{-~]).*$/,
  databaseDateMomentFormat: 'YYYY-MM-DDTHH:mm:ssZ',
  timeFormat: { eng: '12h', standard: '24h' },
  days: [
    'deliveries_page.week_days_sunday_l',
    'deliveries_page.week_days_monday_l',
    'deliveries_page.week_days_tuesday_l',
    'deliveries_page.week_days_wednesday_l',
    'deliveries_page.week_days_thursday_l',
    'deliveries_page.week_days_friday_l',
    'deliveries_page.week_days_saturday_l',
  ],
  cargoType,
  materialDurabilities,
  passengers: [0, 1, 2, 3, 4, 5, 6],
  passengersAvailable: false,
  plan: {
    width: 1190,
    height: 900,
    objects: planObjects,
  },
  accessControls: ['auto', 'manual'],
  slotDuration: {
    fast: 15,
    normal: 30,
  },
  unloadingTime: [
    { label: '30 MIN', value: 30 },
    { label: '1H 00 MIN', value: 60 },
    { label: '1H 30 MIN', value: 90 },
    { label: '2H 00 MIN', value: 120 },
  ],
  unloadingTimeFast: [
    { label: '15 MIN', value: 15 },
    { label: '30 MIN', value: 30 },
    { label: '45 MIN', value: 45 },
    { label: '1H 00 MIN', value: 60 },
  ],
  paginationPageSizes: [10, 25, 50, 100],
  rejectCauses: {
    REJECTED_NO_DATA: 'deliveries_page.rejection_no_data_l',
    REJECTED_NO_VEHICLE: 'deliveries_page.rejection_no_vehicle_l',
    REJECTED_NO_RESOURCES: 'deliveries_page.rejection_no_resources_l',
    REJECTED_ANOTHER_REASON: 'deliveries_page.rejection_another_reason_l',
    REJECTED_DELAYED: 'deliveries_page.delayed_l',
    REJECTED_CANCELED: 'deliveries_page.rejection_canceled_l',
    REJECTED_NOT_DELIVERED: 'deliveries_page.rejection_not_delivered_l',
    REJECTED_BOOKED_AGAIN: 'deliveries_page.rejection_booked_again_l',
  },
  deliveryStatuses: {
    NEW: {
      translation: 'deliveries_page.delivery_type_new_l',
      match: /^NEW/,
      status: DeliveryFrontStatus.ToAccept,
    },
    EXPECTED: {
      translation: 'deliveries_page.delivery_type_edit_l',
      match: /^EXPECTED/,
      status: DeliveryFrontStatus.Expected,
    },
    DONE: {
      translation: 'deliveries_page.delivery_type_completed_l',
      match: /^DONE/,
      status: DeliveryFrontStatus.Completed,
    },
    REJECTED: {
      translation: 'general.status_rejected',
      match: /^REJECTED|DELAYED/,
      status: DeliveryFrontStatus.Rejected,
    },
    ACCEPTED: {
      translation: 'deliveries_page.delivery_type_edit_l',
      match: /^ACCEPTED/,
      status: DeliveryFrontStatus.Expected,
    },
    DURING_UNLOADING: {
      translation: 'general.status_during',
      match: /^DURING_UNLOADING/,
      status: DeliveryFrontStatus.Currently,
    },
    UPDATE_REQUIRED: {
      translation: 'config_nav.deliveries.to_complete',
      match: /^UPDATE_REQUIRED/,
      status: DeliveryFrontStatus.ToComplete,
    },
  },
  deliveryCardStatuses: {
    ACCEPTED: 'general.status_accepted',
    NEW: 'deliveries_page.delivery_type_new_l',
    UPDATE_REQUIRED: 'general.status_update_required',
    EXPECTED: 'general.status_expected',
    DURING_UNLOADING: 'general.status_during',
    DURING_UNLOADING_DANGEROUS_CARGO: 'general.status_during',
    UNLOADING_DELAY: 'general.status_delayed',
    REJECTED_NO_DATA: 'general.status_rejected',
    REJECTED_NO_VEHICLE: 'general.status_rejected',
    REJECTED_NO_RESOURCES: 'general.status_rejected',
    REJECTED_ANOTHER_REASON: 'general.status_rejected',
    REJECTED_CANCELED: 'general.status_rejected',
    REJECTED_NOT_DELIVERED: 'general.status_rejected',
    REJECTED_BOOKED_AGAIN: 'general.status_rejected',
    DONE: 'deliveries_page.delivery_type_completed_l',
    DONE_EARLIER: 'deliveries_page.delivery_type_completed_l',
    DONE_LATER: 'deliveries_page.delivery_type_completed_l',
  },
  user: {
    defaultAvatar: '/assets/img/default-avatar.png',
  },
  typeDelivery: {
    longtime: 'longtime',
    onetime: 'onetime',
    part: 'part',
  },
  datePickerOptions: {
    dateFormat: 'dd/mm/yyyy',
    showClearDateBtn: false,
    showTodayBtn: false,
    disableUntil: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() - 1,
    },
  } as IMyOptions,
  datePickerMomentFormat: 'DD/MM/YYYY',
  deliveryPurposes: {
    INSTALLATION_SERVICE: 'deliveries_page.installation_service_l',
    VEHICLE_SERVICE: 'deliveries_page.vehicle_service_l',
    EQUIPMENT_SERVICE: 'deliveries_page.equipment_service_l',
    RAN_OUT_OF_FUEL: 'deliveries_page.ran_out_of_fuel_l',
    SPARE_PARTS: 'deliveries_page.spare_parts_l',
    COURIER: 'deliveries_page.courier_l',
  },
  breakpoints: {
    screenS: 320,
    screenSM: 480,
    screenSMM: 640,
    screenM: 768, // mobileWidthBreakpoint in DeviceService
    screenL: 1024,
    screenLXL: 1280,
    screenXL: 1440,
  }, // the same as in css
  colors: {
    'tundora-gray': '#4a4a4a',
    'dusty-gray': '#9b9b9b',
    'dune-gray': '#3b3735',
    'alto-gray': '#d8d8d8',
    'dove-gray': '#6f6f6f',
    white: '#ffffff',
    blue: '#4a90e2',
    red: '#d0021b',
    orange: '#f8a524',
  }, // the same as in css
  steelName: 'Steel elements',
  wasteName: 'Waste',
  towerCraneSlug: 'towerCrane',
  mobilePumpSlug: 'mobilePump',
  inspectionStatuses: {
    FOR_INSPECTION: {
      svgName: 'inspection-awaiting',
      title: 'inspection_material.delivery_material_status_for_inspection',
    },
    READY_TO_USE: {
      svgName: 'inspection-positive',
      title: 'inspection_material.delivery_material_status_accepted',
    },
    FAILED: {
      svgName: 'inspection-negative',
      title: 'inspection_material.delivery_material_status_rejected',
    },
    NOT_REQUIRED: {
      svgName: 'inspection-not-required',
      title: 'general.not_required',
    },
  },
  splitFloorsRange: /^(-?\d+)\s*?[-\/\.,]\s*?(-?\d+)$/,
  allDayAvailabilityTimeFrom: '00:00',
  allDayAvailabilityTimeTo: '23:59',
  fullWeekAvailability: [
    { from: '00:00', to: '23:59', dayNumber: 0 },
    { from: '00:00', to: '23:59', dayNumber: 1 },
    { from: '00:00', to: '23:59', dayNumber: 2 },
    { from: '00:00', to: '23:59', dayNumber: 3 },
    { from: '00:00', to: '23:59', dayNumber: 4 },
    { from: '00:00', to: '23:59', dayNumber: 5 },
    { from: '00:00', to: '23:59', dayNumber: 6 },
  ]
};
