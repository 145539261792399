import { isFunction } from 'lodash-es';

import * as actions from './buildings.action';
import { BuildingsState } from './';
import { Building } from '@app/state/interfaces';

const initialState: BuildingsState = {
  buildings: [],
  building: null,
  loaded: false,
  loading: false,
};

const reducerMap = {
  [actions.GET_BUILDINGS]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_BUILDINGS_FAIL]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_BUILDINGS_SUCCESS]: (
    state: BuildingsState,
    buildings: Building[]
  ) => ({
    ...state,
    buildings,
    loaded: true,
    loading: false,
  }),
  [actions.GET_BUILDING]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_BUILDING_FAIL]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_BUILDING_SUCCESS]: (
    state: BuildingsState,
    building: Building
  ) => ({
    ...state,
    building,
    loaded: true,
    loading: false,
  }),
  [actions.CREATE_BUILDING]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_BUILDING_FAIL]: (state: BuildingsState, payload: any) => ({
    ...state,
    buildings: state.buildings.filter(
      b => !state.building || b.id !== state.building.id
    ),
    building: null,
    loading: false,
  }),
  [actions.CREATE_BUILDING_SUCCESS]: (
    state: BuildingsState,
    building: Building
  ) => ({
    ...state,
    buildings: [building, ...state.buildings],
    building,
    loading: false,
  }),
  [actions.UPDATE_BUILDING]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_BUILDING_FAIL]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_BUILDING_SUCCESS]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.REMOVE_BUILDING]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.REMOVE_BUILDING_FAIL]: (state: BuildingsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.REMOVE_BUILDING_SUCCESS]: (
    state: BuildingsState,
    building: Building
  ) => ({
    ...state,
    building,
    loading: false,
  }),
  [actions.CLEAR_BUILDING]: (state: BuildingsState) => ({
    ...state,
    building: null,
  }),
};

export function buildingReducer(state = initialState, action: any) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getBuildings = (state: BuildingsState) => state.buildings;
export const getBuilding = (state: BuildingsState) => state.building;
export const getBuildingLoaded = (state: BuildingsState) => state.loaded;
