import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const getPath = createSelector(
  fromFeature.getRouterState,
  state => state.state
);

export const getCurrentPath = createSelector(getPath, fromFeature.getUrl);
