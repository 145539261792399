import { SafeHtml } from '@angular/platform-browser';
import { isFunction } from 'lodash-es';

import { RegulationsState } from '@app/state/regulations/index';
import { ReducerMap } from '@app/state/interfaces';
import * as actions from './regulations.actions';

const initialState: RegulationsState = {
  regulation: null,
  isLoading: false,
};

const reducerMap: ReducerMap<RegulationsState> = {
  [actions.GET_REGULATION]: state => ({
    ...state,
    isLoading: true,
  }),
  [actions.GET_REGULATION_SUCCESS]: (state, regulation: SafeHtml) => ({
    ...state,
    regulation,
    isLoading: false,
  }),
  [actions.GET_REGULATION_FAIL]: state => ({
    ...state,
    isLoading: false,
  }),
};

export function regulationReducer(state: RegulationsState = initialState, action: any) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
