import { Directive, Input, HostBinding } from '@angular/core';

@Directive({ selector: '[listIndex]' })
export class ListIndexDirective {
  @HostBinding('innerText')
  public innerText: number;

  @Input() public set listIndex([idx, page, pageSize]: number[]) {
    this.innerText = idx + 1 + (page - 1) * pageSize;
  }
}
