import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromBuildings from './buildings.reducer';
import { BuildingsState } from '.';

const stateSelector = createFeatureSelector<BuildingsState>('buildings');

export const getAllBuildings = createSelector(
  stateSelector,
  fromBuildings.getBuildings
);

export const getSelectedBuilding = createSelector(
  stateSelector,
  fromBuildings.getBuilding
);

export const getBuildingsLoaded = createSelector(
  stateSelector,
  fromBuildings.getBuildingLoaded
);

export const getBuildingsLoading = createSelector(
  stateSelector,
  (state: BuildingsState) => state.loading
);


export const buildingsQuery = {
  getAllBuildings
};
