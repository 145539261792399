import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

import { createApiUrl, createQueryParams } from './http-utils';

import { LocationType, VehicleObj, User } from '@app/state/interfaces';
import {
  Supplier,
  SuppliersEmployee,
  SupplierLocation,
  SuppliersEmployeeRole,
  AssignSupplierEmployeeBody,
  WorkingDriver,
  NoDriverAppResponse,
} from '@interfaces';
import {
  Response,
  QueryParamObject,
  GetAllSuppliersRes,
  CreateSupplierReq,
  GetSupplierRes,
  GetVehiclesRes,
  CreateVehiclesRes,
  UpdateSupplierReq,
  CreateSupplierVehiclesReq,
  GetSupplierVehiclesRes,
  GetSuppliersClientsRes,
  GetSuppliersProductsRes,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class SuppliersHttpService {
  private createApiUrl = createApiUrl;
  private createQueryParams = createQueryParams;

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {}

  public errorShowAndHandle = (httpError: HttpErrorResponse): Observable<any> =>
    this.errorHandlerService.errorShowAndHandle(httpError);

  public getAllSuppliers(params: QueryParamObject[] = null): Observable<GetAllSuppliersRes> {
    const getAllSuppliersUrl = this.createApiUrl(`suppliers${this.createQueryParams(params)}`);

    return this.http.get<GetAllSuppliersRes>(getAllSuppliersUrl).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public createSupplier(supplier: CreateSupplierReq): Observable<Supplier> {
    return this.http.post<Supplier>(this.createApiUrl('suppliers'), supplier).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public updateSupplier(supplier: UpdateSupplierReq, supplierId: string): Observable<Supplier> {
    return this.http.put<Supplier>(this.createApiUrl(`suppliers/${supplierId}`), supplier).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public getSupplier(supplier: { id: string }): Observable<GetSupplierRes> {
    return this.http.get<GetSupplierRes>(this.createApiUrl(`suppliers/${supplier.id}`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public createSupplierLocation(id: string, location: SupplierLocation): Observable<SupplierLocation> {
    return this.http.post<SupplierLocation>(this.createApiUrl(`suppliers/${id}/locations`), location).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public getSuppliersLocations(id: string): Observable<SupplierLocation[]> {
    return this.http.get<SupplierLocation[]>(this.createApiUrl(`suppliers/${id}/locations`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public updateSupplierLocation(
    supplierId: string,
    locationId: string,
    location: SupplierLocation
  ): Observable<SupplierLocation> {
    const updateLocationUrl = this.createApiUrl(`suppliers/${supplierId}/locations/${locationId}`);

    return this.http.put<SupplierLocation>(updateLocationUrl, location).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public getLocationsTypes(): Observable<LocationType[]> {
    return this.http.get<LocationType[]>(this.createApiUrl('suppliers/locations-types')).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public setDefaultLocation(supplierId: string, locationId: string): Observable<SupplierLocation> {
    const setDefaultLocationUrl = this.createApiUrl(`suppliers/${supplierId}/locations/${locationId}/default`);

    return this.http.post<SupplierLocation>(setDefaultLocationUrl, {}).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public getSuppliersEmployees(supplierId: string): Observable<SuppliersEmployee[]> {
    return this.http.get<SuppliersEmployee[]>(this.createApiUrl(`suppliers/${supplierId}/employees`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public createSuppliersEmployee(employee: SuppliersEmployee): Observable<User> {
    return this.http.post<Response<{ user: User }>>(this.createApiUrl('users'), employee).pipe(
      map((response) => response.data.user),
      catchError(this.errorShowAndHandle)
    );
  }

  public updateSuppliersEmployee(
    employee: SuppliersEmployee,
    supplierId: string,
    employeeId: string
  ): Observable<User> {
    const updateEmployeeUrl = this.createApiUrl(`suppliers/${supplierId}/employees/${employeeId}`);

    return this.http.put<User>(updateEmployeeUrl, employee).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public assignEmployeeToSupplier(
    supplierId: string,
    employeeId: string,
    body: AssignSupplierEmployeeBody
  ): Observable<SuppliersEmployee> {
    const assignEmployeeUrl = this.createApiUrl(`suppliers/${supplierId}/employee/${employeeId}/assign`);

    return this.http.post<SuppliersEmployee>(assignEmployeeUrl, body).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public getSuppliersEmployeeRoles(): Observable<SuppliersEmployeeRole[]> {
    return this.http.get<SuppliersEmployeeRole[]>(this.createApiUrl('suppliers-roles'));
  }

  public getSuppliersVehiclesCount(supplierId: string, materialCategoryId: string) {
    return this.http.get<number>(this.createApiUrl(`suppliers/${supplierId}/vehicles/${materialCategoryId}/count`));
  }

  public createVehicles({ supplierId, vehicles }: CreateSupplierVehiclesReq): Observable<VehicleObj> {
    const createSuppliersVehicleUrl = this.createApiUrl(`suppliers/${supplierId}/vehicles`);

    return this.http.post<CreateVehiclesRes>(createSuppliersVehicleUrl, { vehicles }).pipe(
      map((response: CreateVehiclesRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public getSupplierVehicles(payload: {
    id: string;
    queries?: QueryParamObject[];
  }): Observable<GetSupplierVehiclesRes> {
    const queryParams = this.createQueryParams(payload.queries);
    const getSuppliersVehiclesUrl = this.createApiUrl(`suppliers/${payload.id}/vehicles${queryParams}`);

    return this.http.get<GetVehiclesRes>(getSuppliersVehiclesUrl).pipe(
      map((response: GetVehiclesRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  public getRunAvailableDrivers(materialCategoryId: string): Observable<WorkingDriver[] | NoDriverAppResponse> {
    const url = this.createApiUrl(`suppliers/run-available-drivers?materialCategoryId=${materialCategoryId}`);
    return this.http.get<WorkingDriver[] | NoDriverAppResponse>(url);
  }

  public getSuppliersClients(supplierId: string, queries?: QueryParamObject[]): Observable<GetSuppliersClientsRes> {
    const queryParams = this.createQueryParams(queries);

    return this.http.get<GetSuppliersClientsRes>(
      this.createApiUrl(`suppliers/${supplierId}/subcontractors${queryParams}`)
    );
  }

  public getSuppliersProducts(supplierId: string, queries?: QueryParamObject[]): Observable<GetSuppliersProductsRes> {
    const queryParams = this.createQueryParams(queries);
    const params = new HttpParams().set('tableData', 'true');

    return this.http.get<GetSuppliersProductsRes>(
      this.createApiUrl(`suppliers/${supplierId}/materials${queryParams}`),
      { params }
    );
  }
}
