import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanDeactivate,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DeliveriesService } from '@app/deliveries/deliveries.service';

@Injectable({
  providedIn: 'root',
})
export class PanelGuard implements CanActivate, CanDeactivate<any> {
  constructor(private deliveriesService: DeliveriesService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (currentState.url.split('?')[0] !== nextState.url.split('?')[0]) {
      this.deliveriesService.closeDeliveryDetails();
    }

    return true;
  }
}
