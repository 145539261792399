import { Directive, Input, HostBinding } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Directive({ selector: '[trustHTML]' })
export class TrustHtmlDirective {
  @HostBinding('innerHtml')
  public innerHtml?: SafeHtml;

  @Input()
  public set trustHTML(html: string) {
    const trustHtml = this.decode(html);

    if (this.html !== trustHtml) {
      this.html = trustHtml;
      this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(trustHtml);
    }
  }

  private html: string;

  constructor(private sanitizer: DomSanitizer) {}

  private decode(str: string) {
    const el = document.createElement('div');
    el.innerHTML = str;
    str = el.textContent || el.innerText;
    el.remove();
    return str;
  }
}
