import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';
import * as fromAppState from '@app/state';

import * as actions from './gates.action';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class GatesEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.GatesAction>,
    private alertService: AlertService
  ) {}

  
  getGates$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_GATES),
    map((action: actions.GetGates) => action.payload),
    switchMap(filters =>
      this.http.getGates(filters).pipe(
        map(res => new actions.GetGatesSuccess(res)),
        catchError(error => [new actions.GetGatesFail()])
      )
    )
  ));

  
  getGate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_GATE),
    map((action: actions.GetGate) => action.payload),
    switchMap(gate =>
      this.http.getGate(gate).pipe(
        map(res => new actions.GetGateSuccess(res)),
        catchError(error => [new actions.GetGateFail()])
      )
    )
  ));

  
  createGate$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.CREATE_GATE),
      map((action: actions.CreateGate) => action.payload),
      switchMap(gate =>
        this.http.createGate(gate).pipe(
          map(res => {
            this.alertService.showInfo('alert.object_added_l');
            return new actions.CreateGateSuccess(res);
          }),
          catchError(error => [new actions.CreateGateFail()])
        )
      )
    ));

  
  updateGate$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_GATE),
      map((action: actions.UpdateGate) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...data } = payload;
        return this.http.updateGate(data).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_edited_l');
            return redirectAfter
              ? [
                  new actions.UpdateGateSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/gates'],
                    alertInfo: 'alert.object_edited_l',
                  }),
                ]
              : [new actions.UpdateGateSuccess(res)];
          }),
          catchError(error => [new actions.UpdateGateFail()])
        );
      })
    ));

  
  removeGate: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.REMOVE_GATE),
      map((action: actions.RemoveGate) => action.payload),
      switchMap(payload => {
        const { redirectAfter, ...gate } = payload;
        return this.http.removeGate(gate).pipe(
          mergeMap(res => {
            this.alertService.showInfo('alert.object_deleted_l');
            return redirectAfter
              ? [
                  new actions.RemoveGateSuccess(res),
                  new fromAppState.Go({
                    path: ['objects/gates'],
                    alertInfo: 'alert.object_deleted_l',
                  }),
                ]
              : [new actions.RemoveGateSuccess(res)];
          }),
          catchError(error => [new actions.RemoveGateFail()])
        );
      })
    ));
}
