/* eslint-disable max-len */

export enum PermissionsEnum {
  NONE = '*',

  // ---------------------- NAVIGATION (MAIN MENU) ITEMS DISPLAY
  NAVIGATION_DASHBOARD = 'NAVIGATION_DASHBOARD',
  NAVIGATION_SCHEDULER = 'NAVIGATION_SCHEDULER',
  NAVIGATION_DELIVERIES = 'NAVIGATION_DELIVERIES',
  NAVIGATION_MATERIALS = 'NAVIGATION_MATERIALS',
  NAVIGATION_CONSTRUCTION_PLAN = 'NAVIGATION_CONSTRUCTION_PLAN',
  NAVIGATION_SUBCONTRACTORS = 'NAVIGATION_SUBCONTRACTORS',
  NAVIGATION_OBJECTS = 'NAVIGATION_OBJECTS',
  NAVIGATION_TASKS = 'NAVIGATION_TASKS',
  NAVIGATION_REPORTS = 'NAVIGATION_REPORTS',
  NAVIGATION_SETTINGS = 'NAVIGATION_SETTINGS',
  NAVIGATION_SUPPLIERS = 'NAVIGATION_SUPPLIERS',

  // ---------------------- SETTINGS (BUILDING PROFILE)
  BUILDING_PROFILE_GENERAL_DISPLAY = 'BUILDING_PROFILE_GENERAL_DISPLAY',
  BUILDING_PROFILE_CONSTRUCTION_PLAN_DISPLAY = 'BUILDING_PROFILE_CONSTRUCTION_PLAN_DISPLAY',
  BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_DISPLAY = 'BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_DISPLAY',
  BUILDING_PROFILE_PERMISSIONS_DISPLAY = 'BUILDING_PROFILE_PERMISSIONS_DISPLAY',
  BUILDING_PROFILE_OBJECTS_DISPLAY = 'BUILDING_PROFILE_OBJECTS_DISPLAY',
  BUILDING_PROFILE_DELIVERIES_DISPLAY = 'BUILDING_PROFILE_DELIVERIES_DISPLAY',
  BUILDING_PROFILE_SUBCONTRACTORS_DISPLAY = 'BUILDING_PROFILE_SUBCONTRACTORS_DISPLAY',
  BUILDING_PROFILE_ECOLOGY_DISPLAY = 'BUILDING_PROFILE_ECOLOGY_DISPLAY',
  BUILDING_PROFILE_INTEGRATIONS_DISPLAY = 'BUILDING_PROFILE_INTEGRATIONS_DISPLAY',
  BUILDING_PROFILE_MANAGE_USERS_DISPLAY = 'BUILDING_PROFILE_MANAGE_USERS_DISPLAY',
  BUILDING_PROFILE_EDIT_COLORS = 'BUILDING_PROFILE_EDIT_COLORS',

  // Currently not used but listed in PermissionsEnum Sheet (documentation)
  // BUILDING_PROFILE_GENERAL_EDIT = 'BUILDING_PROFILE_GENERAL_EDIT',
  // BUILDING_PROFILE_GENERAL_ADD = 'BUILDING_PROFILE_GENERAL_ADD',
  // BUILDING_PROFILE_GENERAL_DELETE = 'BUILDING_PROFILE_GENERAL_DELETE',
  // BUILDING_PROFILE_CONSTRUCTION_PLAN_EDIT = 'BUILDING_PROFILE_CONSTRUCTION_PLAN_EDIT',
  // BUILDING_PROFILE_CONSTRUCTION_PLAN_ADD = 'BUILDING_PROFILE_CONSTRUCTION_PLAN_ADD',
  // BUILDING_PROFILE_CONSTRUCTION_PLAN_DELETE = 'BUILDING_PROFILE_CONSTRUCTION_PLAN_DELETE',
  // BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_EDIT = 'BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_EDIT',
  // BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_ADD = 'BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_ADD',
  // BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_DELETE = 'BUILDING_PROFILE_FUTURE_CONSTRUCTION_PLAN_DELETE',
  // BUILDING_PROFILE_PERMISSIONS_EDIT = 'BUILDING_PROFILE_PERMISSIONS_EDIT',
  // BUILDING_PROFILE_PERMISSIONS_ADD = 'BUILDING_PROFILE_PERMISSIONS_ADD',
  // BUILDING_PROFILE_PERMISSIONS_DELETE = 'BUILDING_PROFILE_PERMISSIONS_DELETE',
  // BUILDING_PROFILE_OBJECTS_EDIT = 'BUILDING_PROFILE_OBJECTS_EDIT',
  // BUILDING_PROFILE_OBJECTS_ADD = 'BUILDING_PROFILE_OBJECTS_ADD',
  // BUILDING_PROFILE_OBJECTS_DELETE = 'BUILDING_PROFILE_OBJECTS_DELETE',
  // BUILDING_PROFILE_DELIVERIES_EDIT = 'BUILDING_PROFILE_DELIVERIES_EDIT',
  // BUILDING_PROFILE_DELIVERIES_ADD = 'BUILDING_PROFILE_DELIVERIES_ADD',
  // BUILDING_PROFILE_DELIVERIES_DELETE = 'BUILDING_PROFILE_DELIVERIES_DELETE',
  // BUILDING_PROFILE_SUBCONTRACTORS_EDIT = 'BUILDING_PROFILE_SUBCONTRACTORS_EDIT',
  // BUILDING_PROFILE_SUBCONTRACTORS_ADD = 'BUILDING_PROFILE_SUBCONTRACTORS_ADD',
  // BUILDING_PROFILE_SUBCONTRACTORS_DELETE = 'BUILDING_PROFILE_SUBCONTRACTORS_DELETE',
  // BUILDING_PROFILE_ECOLOGY_EDIT = 'BUILDING_PROFILE_ECOLOGY_EDIT',
  // BUILDING_PROFILE_ECOLOGY_ADD = 'BUILDING_PROFILE_ECOLOGY_ADD',
  // BUILDING_PROFILE_ECOLOGY_DELETE = 'BUILDING_PROFILE_ECOLOGY_DELETE',
  // BUILDING_PROFILE_MANAGE_USERS_EDIT = 'BUILDING_PROFILE_MANAGE_USERS_EDIT',
  // BUILDING_PROFILE_MANAGE_USERS_ADD = 'BUILDING_PROFILE_MANAGE_USERS_ADD',
  // BUILDING_PROFILE_MANAGE_USERS_DELETE = 'BUILDING_PROFILE_MANAGE_USERS_DELETE',

  // ---------------------- USER MANAGEMENT
  ADD_USER = 'ADD_USER',
  DISPLAY_USER = 'DISPLAY_USER',
  EDIT_USER = 'EDIT_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  DISPLAY_OWN_ACCOUNT = 'DISPLAY_OWN_ACCOUNT',
  EDIT_OWN_ACCOUNT = 'EDIT_OWN_ACCOUNT',
  RESET_PASSWORD = 'RESET_PASSWORD',

  // Should currently edited user fill phone number. User with that Role!
  USER_EDIT_PHONE_REQUIREMENT = 'USER_EDIT_PHONE_REQUIREMENT',

  // Is it allowed to assign equipment to the user (it is if this user has this permission)
  USER_EDIT_EQUIPMENT_SELECTION_DISPLAY = 'USER_EDIT_EQUIPMENT_SELECTION_DISPLAY',

  // Is it allowed to assign gate to currently edited user (it is if this user has this permission)
  USER_EDIT_GATE_SELECTION_DISPLAY = 'USER_EDIT_GATE_SELECTION_DISPLAY',

  // Is freshly assigned role 'Subcontractor'? If so - go to the specific view for adding subcontractors
  USER_EDIT_ADD_NEW_SUBCONTRACTOR = 'USER_EDIT_ADD_NEW_SUBCONTRACTOR',

  // ---------------------- LOG ACTIVITY HISTORY
  GET_LOG_ACTIVITY_HISTORY = 'GET_LOG_ACTIVITY_HISTORY',

  // ---------------------- SUBCONTRACTORS
  SUBCONTRACTOR_DETAILS_DISPLAY = 'SUBCONTRACTOR_DETAILS_DISPLAY',
  SUBCONTRACTOR_ALL_DISPLAY = 'SUBCONTRACTOR_ALL_DISPLAY',
  SUBCONTRACTOR_ARCHIVE_DISPLAY = 'SUBCONTRACTOR_ARCHIVE_DISPLAY',
  SUBCONTRACTOR_CONTRACTS_DISPLAY = 'SUBCONTRACTOR_CONTRACTS_DISPLAY',
  SUBCONTRACTOR_LONG_TERM_DISPLAY = 'SUBCONTRACTOR_LONG_TERM_DISPLAY',
  SUBCONTRACTOR_ONE_TIME_DISPLAY = 'SUBCONTRACTOR_ONE_TIME_DISPLAY',
  SUBCONTRACTOR_ADD = 'SUBCONTRACTOR_ADD',
  SUBCONTRACTOR_EDIT = 'SUBCONTRACTOR_EDIT',
  SUBCONTRACTOR_EMPLOYEES_ADD = 'SUBCONTRACTOR_EMPLOYEES_ADD',
  SUBCONTRACTOR_LOCATIONS_ADD = 'SUBCONTRACTOR_LOCATIONS_ADD',
  SUBCONTRACTOR_EMPLOYEE_ALWAYS_SHOW_PIN = 'SUBCONTRACTOR_EMPLOYEE_ALWAYS_SHOW_PIN',
  SUBCONTRACTOR_EMPLOYEE_SHOW_PIN_IF_MATCHED_USER = 'SUBCONTRACTOR_EMPLOYEE_SHOW_PIN_IF_MATCHED_USER',
  SUBCONTRACTOR_CREWS_DISPLAY = 'SUBCONTRACTOR_CREWS_DISPLAY',

  // Permission for changing subcontractor status
  SUBCONTRACTOR_EDIT_STATUS_CHANGE = 'SUBCONTRACTOR_EDIT_STATUS_CHANGE',

  // Permission for delivery type change for given subcontractor
  SUBCONTRACTOR_EDIT_DELIVERIES_SHORT_OR_LONG_CHANGE = 'SUBCONTRACTOR_EDIT_DELIVERIES_SHORT_OR_LONG_CHANGE',

  // Currently not used but listed in PermissionsEnum Sheet (documentation)
  // SUBCONTRACTOR_REMOVE = 'SUBCONTRACTOR_REMOVE',
  // SUBCONTRACTOR_ACTIVATE = 'SUBCONTRACTOR_ACTIVATE',
  // SUBCONTRACTOR_DEACTIVATE = 'SUBCONTRACTOR_DEACTIVATE',

  // ---------------------- SUPPLIERS
  SUPPLIER_ALL_DISPLAY = 'SUPPLIER_ALL_DISPLAY',
  SUPPLIER_CONTRACTS_DISPLAY = 'SUPPLIER_CONTRACTS_DISPLAY',
  SUPPLIER_ADD = 'SUPPLIER_ADD',
  SUPPLIER_EDIT = 'SUPPLIER_EDIT',
  SUPPLIER_DETAILS_DISPLAY = 'SUPPLIER_DETAILS_DISPLAY',
  SUPPLIER_EMPLOYEES_DISPLAY = 'SUPPLIER_EMPLOYEES_DISPLAY',
  SUPPLIER_LOCATIONS_DISPLAY = 'SUPPLIER_LOCATIONS_DISPLAY',
  SUPPLIER_PRODUCTS_DISPLAY = 'SUPPLIER_PRODUCTS_DISPLAY',
  SUPPLIER_CLIENTS_DISPLAY = 'SUPPLIER_CLIENTS_DISPLAY',
  SUPPLIER_VEHICLES_DISPLAY = 'SUPPLIER_VEHICLES_DISPLAY',
  SUPPLIER_EMPLOYEES_ADD = 'SUPPLIER_EMPLOYEES_ADD',
  SUPPLIER_CLIENT_ADD = 'SUPPLIER_CLIENT_ADD',
  SUPPLIER_LOCATION_ADD = 'SUPPLIER_LOCATION_ADD',
  SUPPLIER_IMPROVED_EDIT = 'SUPPLIER_IMPROVED_EDIT',
  SUPPLIER_EMPLOYEE_ALWAYS_SHOW_PIN = 'SUPPLIER_EMPLOYEE_ALWAYS_SHOW_PIN',

  // ---------------------- CONTRACTS
  CONTRACT_ADD = 'CONTRACT_ADD',
  CONTRACT_EDIT = 'CONTRACT_EDIT',

  // Currently not used but listed in PermissionsEnum Sheet (documentation)
  // CONTRACT_DISPLAY = 'CONTRACT_DISPLAY',
  // CONTRACT_REMOVE = 'CONTRACT_REMOVE',
  // CONTRACT_OWN_DISPLAY = 'CONTRACT_OWN_DISPLAY',

  // ---------------------- VEHICLES
  VEHICLE_DISPLAY = 'VEHICLE_DISPLAY',
  VEHICLE_ALL_DISPLAY = 'VEHICLE_ALL_DISPLAY',
  VEHICLE_ADD = 'VEHICLE_ADD',

  // Currently not used but listed in PermissionsEnum Sheet (documentation)
  // VEHICLE_EDIT = 'VEHICLE_EDIT',
  // VEHICLE_REMOVE = 'VEHICLE_REMOVE',
  // VEHICLE_OWN_DISPLAY = 'VEHICLE_OWN_DISPLAY',

  // ---------------------- OBJECTS
  OBJECTS_UNLOADING_PLACES_DISPLAY = 'OBJECTS_UNLOADING_PLACES_DISPLAY',
  OBJECTS_GATES_DISPLAY = 'OBJECTS_GATES_DISPLAY',
  OBJECTS_EQUIPMENT_DISPLAY = 'OBJECTS_EQUIPMENT_DISPLAY',
  OBJECTS_DEPOTS_DISPLAY = 'OBJECTS_DEPOTS_DISPLAY',
  OBJECTS_SCHEDULER_DISPLAY = 'OBJECTS_SCHEDULER_DISPLAY',

  OBJECTS_UNLOADING_PLACE_DETAIL_DISPLAY = 'OBJECTS_UNLOADING_PLACE_DETAIL_DISPLAY',
  OBJECTS_GATE_DETAIL_DISPLAY = 'OBJECTS_GATE_DETAIL_DISPLAY',
  OBJECTS_EQUIPMENT_DETAIL_DISPLAY = 'OBJECTS_EQUIPMENT_DETAIL_DISPLAY',
  OBJECTS_DEPOT_DETAIL_DISPLAY = 'OBJECTS_DEPOT_DETAIL_DISPLAY',

  OBJECTS_ADD = 'OBJECTS_ADD',
  OBJECTS_EDIT = 'OBJECTS_EDIT',
  // Currently not used but listed in PermissionsEnum Sheet (documentation)
  // OBJECTS_REMOVE = 'OBJECTS_REMOVE',

  // ---------------------- REPORTS
  REPORTS_GENERAL_DISPLAY = 'REPORTS_GENERAL_DISPLAY',
  REPORTS_GENERATE_REPORT_FILE = 'REPORTS_GENERATE_REPORT_FILE',
  REPORTS_BREEAM_DISPLAY = 'REPORTS_BREEAM_DISPLAY',

  // ---------------------- SCHEDULER
  SCHEDULER_GENERAL_DISPLAY = 'SCHEDULER_GENERAL_DISPLAY',
  SCHEDULER_LEGEND_GENERAL_DELIVERIES_DISPLAY = 'SCHEDULER_LEGEND_GENERAL_DELIVERIES_DISPLAY',
  SCHEDULER_LEGEND_SUBCONTRACTORS_DELIVERIES_DISPLAY = 'SCHEDULER_LEGEND_SUBCONTRACTORS_DELIVERIES_DISPLAY',
  SCHEDULER_OBJECTS_LINKS_DISPLAY = 'SCHEDULER_OBJECTS_LINKS_DISPLAY',
  SCHEDULER_DELIVERY_ACCESS = 'SCHEDULER_DELIVERY_ACCESS',
  SCHEDULER_UPLOAD_XLS = 'SCHEDULER_UPLOAD_XLS',
  SCHEDULER_CHECKOUT_DELIVERY = 'SCHEDULER_CHECKOUT_DELIVERY',

  // ---------------------- DELIVERIES
  DELIVERIES_GENERAL_DISPLAY = 'DELIVERIES_GENERAL_DISPLAY',
  DELIVERIES_CURRENT_DISPLAY = 'DELIVERIES_CURRENT_DISPLAY',
  DELIVERIES_TODAY_DISPLAY = 'DELIVERIES_TODAY_DISPLAY',
  DELIVERIES_EXPECTED_DISPLAY = 'DELIVERIES_EXPECTED_DISPLAY',
  DELIVERIES_TO_ACCEPT_DISPLAY = 'DELIVERIES_TO_ACCEPT_DISPLAY',
  DELIVERIES_TO_COMPLETE_DISPLAY = 'DELIVERIES_TO_COMPLETE_DISPLAY',
  DELIVERIES_REJECTED_DISPLAY = 'DELIVERIES_REJECTED_DISPLAY',
  DELIVERIES_COMPLETED_DISPLAY = 'DELIVERIES_COMPLETED_DISPLAY',
  DELIVERIES_OBJECT_DISPLAY = 'DELIVERIES_OBJECT_DISPLAY',
  DELIVERIES_ALL_DISPLAY = 'DELIVERIES_ALL_DISPLAY',
  DELIVERIES_DETAILS_DISPLAY = 'DELIVERIES_DETAILS_DISPLAY',
  DELIVERIES_EXPECTED_SUPPLIER_NAME = 'DELIVERIES_EXPECTED_SUPPLIER_NAME',

  DELIVERIES_ONETIME_ADD = 'DELIVERIES_ONETIME_ADD',
  DELIVERIES_ONETIME_ADD_CURRENT_DAY_DELIVERY_ADD = 'DELIVERIES_ONETIME_ADD_CURRENT_DAY_DELIVERY_ADD',
  // ONLY SUBCONTRACTORS SHOULD HAVE THIS PERMISSION
  DELIVERIES_ONETIME_ADD_SUBCONTRACTOR_INPUT_FIELD_DISPLAY = 'DELIVERIES_ONETIME_ADD_SUBCONTRACTOR_INPUT_FIELD_DISPLAY',
  DELIVERIES_LONGTIME_ADD = 'DELIVERIES_LONGTIME_ADD',
  DELIVERIES_SUBCONTRACTOR_COMPANY_NAME_DISPLAY = 'DELIVERIES_SUBCONTRACTOR_COMPANY_NAME_DISPLAY',
  DELIVERIES_SUBCONTRACTOR_DETAILS_DISPLAY = 'DELIVERIES_SUBCONTRACTOR_DETAILS_DISPLAY',

  DELIVERIES_ONETIME_ID_SHOW_ONLY = 'DELIVERIES_ONETIME_ID_SHOW_ONLY',
  DELIVERIES_ONETIME_ID_SHOW_AND_REDIRECT_TO_DETAILS = 'DELIVERIES_ONETIME_ID_SHOW_AND_REDIRECT_TO_DETAILS',
  DELIVERIES_ALL_ID_SHOW_ONLY = 'DELIVERIES_ALL_ID_SHOW_ONLY',
  DELIVERIES_ALL_ID_SHOW_AND_REDIRECT_TO_DETAILS = 'DELIVERIES_ALL_ID_SHOW_AND_REDIRECT_TO_DETAILS',

  DELIVERIES_TODAY_TABLE_SUPPLIER_NAME = 'DELIVERIES_TODAY_TABLE_SUPPLIER_NAME',

  DELIVERIES_COMPLETED_TABLE_ID_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_ID_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_NUMBER_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_NUMBER_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_TYPE_DETAIL_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_TYPE_DETAIL_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_STATUS_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_STATUS_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_SUBCONTRACTOR_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_SUBCONTRACTOR_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_REALIZATION_DATE_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_REALIZATION_DATE_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_ENTRY_DATE_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_ENTRY_DATE_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_REALIZATION_TIME_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_REALIZATION_TIME_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_PLANNED_TIME_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_PLANNED_TIME_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_LOAD_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_LOAD_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_SHORT_NAME_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_SHORT_NAME_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_INSPECTION_STATUS_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_INSPECTION_STATUS_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_DIRECTION_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_DIRECTION_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_CODE_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_CODE_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_REMARKS_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_REMARKS_DISPLAY',
  DELIVERIES_COMPLETED_TABLE_SUPPLIER_NAME = 'DELIVERIES_COMPLETED_TABLE_SUPPLIER_NAME',
  DELIVERIES_COMPLETED_TABLE_DELIVERY_QUANTITY_DISPLAY = 'DELIVERIES_COMPLETED_TABLE_DELIVERY_QUANTITY_DISPLAY',

  DELIVERIES_CURRENTLY_TABLE_ID_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_ID_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_NUMBER_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_NUMBER_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_TYPE_DETAIL_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_TYPE_DETAIL_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_REGISTRATION_NUMBER_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_REGISTRATION_NUMBER_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_SUBCONTRACTOR_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_SUBCONTRACTOR_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_LOAD_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_LOAD_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_DIRECTION_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_DIRECTION_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_PLACE_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_PLACE_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_EQUIPMENT_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_EQUIPMENT_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_DEPOT_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_DEPOT_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_GATE_LEAVE_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_GATE_LEAVE_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_DATE_UNLOADING_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_DATE_UNLOADING_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_PHONE_DRIVER_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_PHONE_DRIVER_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_CALL_ACTION = 'DELIVERIES_CURRENTLY_TABLE_CALL_ACTION',
  DELIVERIES_CURRENTLY_TABLE_OBJECT_DETAILS_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_OBJECT_DETAILS_DISPLAY',
  DELIVERIES_CURRENTLY_TABLE_SUPPLIER_NAME = 'DELIVERIES_CURRENTLY_TABLE_SUPPLIER_NAME',
  DELIVERIES_CURRENTLY_TABLE_STAGES_DISPLAY = 'DELIVERIES_CURRENTLY_TABLE_STAGES_DISPLAY',

  // longterm tables
  DELIVERIES_LONGTERM_TABLE_ID_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_ID_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_ID_DISPLAY_NO_REDIRECT = 'DELIVERIES_LONGTERM_TABLE_ID_DISPLAY_NO_REDIRECT',
  DELIVERIES_LONGTERM_TABLE_NUMBER_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_NUMBER_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_ORDERS_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_ORDERS_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_ORDERS_SEND = 'DELIVERIES_LONGTERM_TABLE_ORDERS_SEND',
  DELIVERIES_LONGTERM_TABLE_CATEGORY_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_CATEGORY_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_SUPPLIER_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_SUPPLIER_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_PROJECT_AND_DELIVERY_ID_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_PROJECT_AND_DELIVERY_ID_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_CONSTRUCTION_SITE_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_CONSTRUCTION_SITE_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_UNLOADING_TIME_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_UNLOADING_TIME_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_DURATION_TIME_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_DURATION_TIME_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_QUANTITY_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_RUNS_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_RUNS_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_STAGES_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_STAGES_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_DISTANCE_AND_TIME_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_DISTANCE_AND_TIME_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_CONTACT_PERSON_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_DELIVERY_CARDS_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_DELIVERY_CARDS_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_RETURN_TIME_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_RETURN_TIME_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_PLANNED_DATE_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_PLANNED_DATE_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_RUN_FREQUENCY_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_RUN_FREQUENCY_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_UNLOADING_MINUTES_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_UNLOADING_MINUTES_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_RETURN_TO_SITE_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_RETURN_TO_SITE_DISPLAY',
  DELIVERIES_LONGTERM_TABLE_WZ_DISPLAY = 'DELIVERIES_LONGTERM_TABLE_WZ_DISPLAY',

  // Expected Onetime Table + Today Onetime Table
  DELIVERIES_ONETIME_TABLE_ID_DISPLAY = 'DELIVERIES_ONETIME_TABLE_ID_DISPLAY',
  DELIVERIES_ONETIME_TABLE_NUMBER_DISPLAY = 'DELIVERIES_ONETIME_TABLE_NUMBER_DISPLAY',
  DELIVERIES_ONETIME_TABLE_TYPE_DETAIL_DISPLAY = 'DELIVERIES_ONETIME_TABLE_TYPE_DETAIL_DISPLAY',
  DELIVERIES_ONETIME_TABLE_PLANNED_DATE_DISPLAY = 'DELIVERIES_ONETIME_TABLE_PLANNED_DATE_DISPLAY',
  DELIVERIES_ONETIME_TABLE_PLANNED_TIME_DISPLAY = 'DELIVERIES_ONETIME_TABLE_PLANNED_TIME_DISPLAY',
  DELIVERIES_ONETIME_TABLE_REGISTRATION_NUMBER_DISPLAY = 'DELIVERIES_ONETIME_TABLE_REGISTRATION_NUMBER_DISPLAY',
  DELIVERIES_ONETIME_TABLE_PLACE_DISPLAY = 'DELIVERIES_ONETIME_TABLE_PLACE_DISPLAY',
  DELIVERIES_ONETIME_TABLE_GATE_ENTER_DISPLAY = 'DELIVERIES_ONETIME_TABLE_GATE_ENTER_DISPLAY',
  DELIVERIES_ONETIME_TABLE_LOAD_DISPLAY = 'DELIVERIES_ONETIME_TABLE_LOAD_DISPLAY',
  DELIVERIES_ONETIME_TABLE_CALL_ACTION_DISPLAY = 'DELIVERIES_ONETIME_TABLE_CALL_ACTION_DISPLAY',
  DELIVERIES_ONETIME_TABLE_CODE_DISPLAY = 'DELIVERIES_ONETIME_TABLE_CODE_DISPLAY',
  DELIVERIES_ONETIME_TABLE_SUBCONTRACTOR_DISPLAY = 'DELIVERIES_ONETIME_TABLE_SUBCONTRACTOR_DISPLAY',
  DELIVERIES_ONETIME_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY = 'DELIVERIES_ONETIME_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY',
  DELIVERIES_ONETIME_TABLE_OBJECT_DETAILS_DISPLAY = 'DELIVERIES_ONETIME_TABLE_OBJECT_DETAILS_DISPLAY',
  DELIVERIES_ONETIME_TABLE_REJECT_ACTION = 'DELIVERIES_ONETIME_TABLE_REJECT_ACTION',
  DELIVERIES_ONETIME_TABLE_SET_MANUAL_ACTION = 'DELIVERIES_ONETIME_TABLE_SET_MANUAL_ACTION',

  DELIVERIES_REJECTED_TABLE_ID_DISPLAY = 'DELIVERIES_REJECTED_TABLE_ID_DISPLAY',
  DELIVERIES_REJECTED_TABLE_NUMBER_DISPLAY = 'DELIVERIES_REJECTED_TABLE_NUMBER_DISPLAY',
  DELIVERIES_REJECTED_TABLE_TYPE_DETAIL_DISPLAY = 'DELIVERIES_REJECTED_TABLE_TYPE_DETAIL_DISPLAY',
  DELIVERIES_REJECTED_TABLE_PLANNED_DATE_DISPLAY = 'DELIVERIES_REJECTED_TABLE_PLANNED_DATE_DISPLAY',
  DELIVERIES_REJECTED_TABLE_PLANNED_TIME_DISPLAY = 'DELIVERIES_REJECTED_TABLE_PLANNED_TIME_DISPLAY',
  DELIVERIES_REJECTED_TABLE_LOAD_DISPLAY = 'DELIVERIES_REJECTED_TABLE_LOAD_DISPLAY',
  DELIVERIES_REJECTED_TABLE_DIRECTION_DISPLAY = 'DELIVERIES_REJECTED_TABLE_DIRECTION_DISPLAY',
  DELIVERIES_REJECTED_TABLE_OBJECT_DISPLAY = 'DELIVERIES_REJECTED_TABLE_OBJECT_DISPLAY',
  DELIVERIES_REJECTED_TABLE_DEPOT_DISPLAY = 'DELIVERIES_REJECTED_TABLE_DEPOT_DISPLAY',
  DELIVERIES_REJECTED_TABLE_NO_DATA_DISPLAY = 'DELIVERIES_REJECTED_TABLE_NO_DATA_DISPLAY',
  DELIVERIES_REJECTED_TABLE_SUBCONTRACTOR_DISPLAY = 'DELIVERIES_REJECTED_TABLE_SUBCONTRACTOR_DISPLAY',
  DELIVERIES_REJECTED_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY = 'DELIVERIES_REJECTED_TABLE_SUBCONTRACTOR_DETAILS_DISPLAY',
  DELIVERIES_REJECTED_TABLE_RESTORE_DISPLAY = 'DELIVERIES_REJECTED_TABLE_RESTORE_DISPLAY',

  DELIVERIES_TO_ACCEPT_TABLE_ID_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_ID_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_NUMBER_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_NUMBER_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_TYPE_DETAIL = 'DELIVERIES_TO_ACCEPT_TABLE_TYPE_DETAIL',
  DELIVERIES_TO_ACCEPT_TABLE_PLANNED_DATE_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_PLANNED_DATE_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_PLANNED_TIME_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_PLANNED_TIME_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_SUBCONTRACTOR_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_SUBCONTRACTOR_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_LOAD_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_LOAD_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_DIRECTION_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_DIRECTION_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_PLACE_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_PLACE_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_GATE_ENTER_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_GATE_ENTER_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_GATE_LEAVE_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_GATE_LEAVE_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_EQUIPMENT_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_EQUIPMENT_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_EQUIPMENT_SIZE_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_EQUIPMENT_SIZE_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_DEPOT_DISPLAY = 'DELIVERIES_TO_ACCEPT_TABLE_DEPOT_DISPLAY',
  DELIVERIES_TO_ACCEPT_TABLE_CANCEL_ACTION = 'DELIVERIES_TO_ACCEPT_TABLE_CANCEL_ACTION',
  DELIVERIES_TO_ACCEPT_TABLE_REJECT_ACTION = 'DELIVERIES_TO_ACCEPT_TABLE_REJECT_ACTION',
  DELIVERIES_TO_ACCEPT_TABLE_ACCEPT_ACTION = 'DELIVERIES_TO_ACCEPT_TABLE_ACCEPT_ACTION',

  DELIVERIES_TO_COMPLETE_TABLE_ID_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_ID_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_NUMBER_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_NUMBER_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_TYPE_DETAIL_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_TYPE_DETAIL_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_PLANNED_DATE_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_PLANNED_DATE_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_PLANNED_TIME_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_PLANNED_TIME_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_SUBCONTRACTOR_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_SUBCONTRACTOR_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_LOAD_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_LOAD_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_PLACE_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_PLACE_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_VEHICLE_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_VEHICLE_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_DISTANCE_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_DISTANCE_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_PHONE_DRIVER_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_PHONE_DRIVER_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_REGISTRATION_NUMBER_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_REGISTRATION_NUMBER_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_TIME_LEFT_DISPLAY = 'DELIVERIES_TO_COMPLETE_TABLE_TIME_LEFT_DISPLAY',
  DELIVERIES_TO_COMPLETE_TABLE_CANCEL_ACTION = 'DELIVERIES_TO_COMPLETE_TABLE_CANCEL_ACTION',
  DELIVERIES_TO_COMPLETE_TABLE_ASK_ACTION = 'DELIVERIES_TO_COMPLETE_TABLE_ASK_ACTION',
  DELIVERIES_TO_COMPLETE_TABLE_REJECT_ACTION = 'DELIVERIES_TO_COMPLETE_TABLE_REJECT_ACTION',
  DELIVERIES_TO_COMPLETE_TABLE_REMIND_ACTION = 'DELIVERIES_TO_COMPLETE_TABLE_REMIND_ACTION',

  // ---------------------- CONSTRUCTION PLAN
  CONSTRUCTION_PLAN_GENERAL_ROUTE_DISPLAY = 'CONSTRUCTION_PLAN_GENERAL_ROUTE_DISPLAY',
  CONSTRUCTION_PLAN_CURRENT_ROUTE_DISPLAY = 'CONSTRUCTION_PLAN_CURRENT_ROUTE_DISPLAY',
  CONSTRUCTION_PLAN_ARCHIVE_ROUTE_DISPLAY = 'CONSTRUCTION_PLAN_ARCHIVE_ROUTE_DISPLAY',
  CONSTRUCTION_PLAN_CONFIRM_ROUTE_DISPLAY = 'CONSTRUCTION_PLAN_CONFIRM_ROUTE_DISPLAY',
  CONSTRUCTION_PLAN_DETAILS_ROUTE_DISPLAY = 'CONSTRUCTION_PLAN_DETAILS_ROUTE_DISPLAY',
  CONSTRUCTION_PLAN_LEGEND_MY_DELIVERIES = 'CONSTRUCTION_PLAN_LEGEND_MY_DELIVERIES',
  CONSTRUCTION_PLAN_LEGEND_ALL_DELIVERIES = 'CONSTRUCTION_PLAN_LEGEND_ALL_DELIVERIES',
  CONSTRUCTION_PLAN_RESCHEDULE_ACTION = 'CONSTRUCTION_PLAN_RESCHEDULE_ACTION',
  CONSTRUCTION_PLAN_NEW_DELIVERY_ACTION = 'CONSTRUCTION_PLAN_NEW_DELIVERY_ACTION',
  CONSTRUCTION_PLAN_ASIDE_ADD_DELIVERY = 'CONSTRUCTION_PLAN_ASIDE_ADD_DELIVERY',
  CONSTRUCTION_PLAN_EDIT = 'CONSTRUCTION_PLAN_EDIT',
  CONSTRUCTION_PLAN_OBJECTS_ROUTE_DISPLAY = 'CONSTRUCTION_PLAN_OBJECTS_ROUTE_DISPLAY',
  CONSTRUCTION_PLAN_FLOORS_ROUTE_DISPLAY = 'CONSTRUCTION_PLAN_FLOORS_ROUTE_DISPLAY',

  // ---------------------- MOBILE DELIVERY DETAILS
  DELIVERY_QUALITY_INSPECTION = 'DELIVERY_QUALITY_INSPECTION',
  DELIVERY_QUANTITY_UPDATE = 'DELIVERY_QUANTITY_UPDATE',

  // ---------------------- ONETIME DELIVERY DETAILS
  ONETIME_DELIVERY_EDIT = 'ONETIME_DELIVERY_EDIT',

  // ---------------------- MATERIALS
  MATERIAL_ADD = 'MATERIAL_ADD',
  MATERIALS_LIST_COMPANY_NAME_COLUMN_DISPLAY = 'MATERIALS_LIST_COMPANY_NAME_COLUMN_DISPLAY',
  MATERIALS_LIST_COMPANY_NAME_LINK_DISPLAY = 'MATERIALS_LIST_COMPANY_NAME_LINK_DISPLAY',

  // ---------------------- DASHBOARD CHECK-IN CHECK-OUT ACCESS
  DASHBOARD_CHECKIN_DISPLAY = 'DASHBOARD_CHECKIN_DISPLAY',
  DASHBOARD_CHECKOUT_DISPLAY = 'DASHBOARD_CHECKOUT_DISPLAY',

  // ---------------------- DELIVERY STAGES (SUPPOP & SUB/LC)
  SUPPLIER_ORDER_MANAGEMENT = 'SUPPLIER_ORDER_MANAGEMENT',
  DELIVERY_STAGE_MANAGEMENT = 'DELIVERY_STAGE_MANAGEMENT',

  // ---------------------- DELIVERY RUN DETAILS
  DELIVERY_RUN_DETAILS_SHOW_EACH_STATUS = 'DELIVERY_RUN_DETAILS_SHOW_EACH_STATUS',
  DELIVERY_RUN_STATUSES_WITHOUT_TRUCK_RETURN = 'DELIVERY_RUN_STATUSES_WITHOUT_TRUCK_RETURN',
  DELIVERY_RUN_DETAILS_SHOW_WHEN_SECTION = 'DELIVERY_RUN_DETAILS_SHOW_WHEN_SECTION',

  // ---------------------- FILES
  FILES_DELETING = 'FILES_DELETING',
  FILES_VISIBLE = 'FILES_VISIBLE',

  // ----------------------- RESERVATIONS
  RESERVATION_CHOOSE_LOGISTICS = 'RESERVATION_CHOOSE_LOGISTICS',
}
