import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { MaterialsPartialState } from './materials.reducer'
import { materialsQuery } from './materials.selectors'
import * as fromMaterialsActions from './materials.actions'

@Injectable()
export class MaterialsFacade {
  materials$ = this.store.pipe(select(materialsQuery.getMaterials))

  constructor(private store: Store<MaterialsPartialState>) {}

  getMaterials(): void {
    this.store.dispatch(fromMaterialsActions.getMaterials())
  }

  assignMaterials(materials: { materialId: string; quantity: number; }[], taskParentId:string, taskChildId:string, subtaskId:string): void {
    this.store.dispatch(fromMaterialsActions.assignMaterials({ materials, taskParentId, taskChildId, subtaskId }))
  }
}
