import { IMyDateModel } from 'mydatepicker';
import { DeliveryLoad, Equipment, Propose } from '../../../state/interfaces';
import { DeliveryTypeDetail } from '@ppgt/web/shared/domain';
import { QueryParams, QueryParamObject } from '@app/core/http/interfaces';
import { SortingOrder } from '../../search/search.service';

export const deliveryTypes = [
  {
    name: DeliveryTypeDetail.Normal,
    translation: 'general.normal_l',
    testId: 'normalDeliveryCheckbox',
  },
  {
    name: DeliveryTypeDetail.Fast,
    translation: 'general.fast_l',
    testId: '',
  },
  {
    name: DeliveryTypeDetail.Emergency,
    translation: 'general.emergency_l',
    testId: '',
  },
];

export const redirectObj = {
  schedules: {
    path: '/scheduler',
    translation: 'general.schedule_a',
  },
  constructionPlan: {
    path: '/construction-plan/current',
    translation: 'config_nav.general.construction_plan',
  },
};

export const limitedData: QueryParamObject = {
  type: QueryParams.LimitedDataConstructionSite,
  value: true,
};

export const activeNoLimit: QueryParamObject[] = [
  {
    type: QueryParams.Filter,
    filterField: 'status',
    condition: 'eq',
    value: 'true',
  },
  { type: QueryParams.NoLimit },
  limitedData,
];

export const activeNoLimitOrderByCompanyName: QueryParamObject[] = [
  ...activeNoLimit,
  {
    type: QueryParams.Order,
    filterField: 'companyName',
    condition: SortingOrder.ASC,
  },
];

export const depotParams: QueryParamObject[] = [
  ...activeNoLimit,
  {
    type: QueryParams.Filter,
    filterField: 'archived',
    condition: 'eq',
    value: false,
  },
  {
    type: QueryParams.Order,
    filterField: 'shortName',
    condition: SortingOrder.ASC,
  },
];

export interface ProposalsData {
  deliveryTime: number;
  proposals: Propose[];
  reschedule: boolean;
}

export interface NewDeliveryOther {
  unloadingTime: number;
  buildingId: string;
  subcontractorId: string;
  cargos: DeliveryLoad[];
  vehicleSizeId: string;
  crane: Equipment;
  craneId: string;
  elevator: boolean;
  elevatorId: string;
  elevatorFloor: number;
  excavator: boolean;
  excavatorId: string;
  miniExcavator: boolean;
  miniExcavatorId: string;
  forklift: boolean;
  forkliftId: string;
  mobileCrane: boolean;
  mobileCraneId: string;
  depot: boolean;
  depotId: string;
  depotAmount: number;
  depotDays: number;
  combined: boolean;
  entry24: boolean;
  proposal: {
    depotId?: string;
    elevatorId?: string;
    excavatorId?: string;
    miniExcavatorId?: string;
    mobileCraneId?: string;
    forkliftId?: string;
    place: string;
    placeId: string;
    placeNumber: number;
    time: string;
    timeId: string;
    times: string[];
  };
  dateUnloadingId: string;
  preferredPlace: string;
  preferredDate: IMyDateModel;
  timeRange: string;
  floor?: number;
}
