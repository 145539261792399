import { Injectable } from '@angular/core';
import * as fromState from '@app/state';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { getDepotsLoading } from '@app/objects/depots/application';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public onLoading$ = new Subject<boolean>();

  private loadingsFromState = [
    fromState.getDeliveriesLoadingSelector,
    fromState.getDeliveryLoadingSelector,
    fromState.getSubcontractorsLoading,
    fromState.getConstructionLoading,
    fromState.getContractsLoading,
    fromState.getSettingsLoading,
    fromState.getDashboardLoading,
    fromState.getBuildingsLoading,
    getDepotsLoading,
    fromState.getEquipmentsLoading,
    fromState.getGatesLoading,
    fromState.getPlacesLoading,
    fromState.getReportsLoading,
    fromState.getNotificationsLoading,
    fromState.getMaterialsLoading,
    fromState.getUserLoading,
    fromState.getRolesLoading,
    fromState.selectIsLoadingRegulation,
    fromState.getSuppliersLoading,
    fromState.getPermissionsLoading,
    fromState.selectDeliveryRunLoading,
  ];

  constructor(private store: Store<fromState.AppState>) {}

  public setLoading(isLoading: boolean): void {
    this.onLoading$.next(isLoading);
  }

  public loadingObserveInit() {
    const streams: Observable<boolean>[] = [];
    this.loadingsFromState.forEach((selector) => {
      streams.push(this.store.select(selector));
    });

    combineLatest(streams).subscribe(async (arr) => {
      const isLoading = await arr.includes(true);
      this.setLoading(isLoading);
    });
  }
}
