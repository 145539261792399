import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state';
import { GetItemsSuccessRes, UpsertItemSuccessRes } from './interfaces';
import { Role } from '@ppgt/web/shared/domain';
import { ErrorHandlerService } from './error-handler.service';
import { createApiUrl } from './http-utils';

@Injectable({
  providedIn: 'root',
})
export class RolesHttpService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    public store: Store<AppState>,
  ) {}

  getRoles(): Observable<GetItemsSuccessRes<Role>> {
    return this.http
      .get<GetItemsSuccessRes<Role>>(createApiUrl('roles'))
      .pipe(catchError((err) => this.errorHandlerService.errorShowAndHandle(err)));
  }

  createRole(newRoleData: Role): Observable<UpsertItemSuccessRes<Role>> {
    return this.http
      .post<UpsertItemSuccessRes<Role>>(createApiUrl('roles'), newRoleData)
      .pipe(catchError((err) => this.errorHandlerService.errorShowAndHandle(err)));
  }

  updateRole(id: string, updatedRoleData: Role): Observable<UpsertItemSuccessRes<Role>> {
    return this.http
      .put<UpsertItemSuccessRes<Role>>(createApiUrl(`roles/${id}`), updatedRoleData)
      .pipe(catchError((err) => this.errorHandlerService.errorShowAndHandle(err)));
  }

}
