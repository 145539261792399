<div
  [class]="(contentToCopy || '') && (clickedItem ? 'notification' : 'text')"
  ngxClipboard
  [cbContent]="contentToCopy"
  [attr.box]="contentToDisplay || contentToCopy"
  [attr.clicked]="'general.clipboard_l' | translate"
  (click)="onClick($event)"
>
  <svg-icon *ngIf="svgName" [name]="svgName"></svg-icon>
  <ng-content></ng-content>
</div>
<div class="files" *ngIf="files?.length">
  <div class="file" *ngFor="let file of filesToshow" (click)="openFile(file)">
    {{ file.originalName }}
  </div>
  <svg-icon
    *ngIf="files?.length > initShowFilesCount"
    class="more-btn"
    [ngClass]="{ 'show-more': showMore }"
    (click)="showMore = !showMore"
    [size]="15"
    name="icon-arrow-black"
  ></svg-icon>
</div>
