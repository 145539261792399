import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListPaginationComponent } from '@app/shared/list-pagination/list-pagination.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ListPaginationComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    ListPaginationComponent,
  ]
})
export class ListPaginationModule { }
