import { Action } from '@ngrx/store';

import { Notif } from '../interfaces';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export class GetNotifications implements Action {
  readonly type = GET_NOTIFICATIONS;
}

export class GetNotificationsFail implements Action {
  readonly type = GET_NOTIFICATIONS_FAIL;
}

export class GetNotificationsSuccess implements Action {
  readonly type = GET_NOTIFICATIONS_SUCCESS;

  constructor(public payload: Notif[]) {}
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export class AddNotification implements Action {
  readonly type = ADD_NOTIFICATION;

  constructor(public payload: Notif) {}
}

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';

export class UpdateNotification implements Action {
  readonly type = UPDATE_NOTIFICATION;

  constructor(public payload: string) {}
}

export class UpdateNotificationFail implements Action {
  readonly type = UPDATE_NOTIFICATION_FAIL;

  constructor(public payload: Error) {}
}

export class UpdateNotificationSuccess implements Action {
  readonly type = UPDATE_NOTIFICATION_SUCCESS;

  constructor(public payload: Notif) {}
}

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_FAIL = 'UPDATE_NOTIFICATIONS_FAIL';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';

export class UpdateNotifications implements Action {
  readonly type = UPDATE_NOTIFICATIONS;
}

export class UpdateNotificationsFail implements Action {
  readonly type = UPDATE_NOTIFICATIONS_FAIL;
}

export class UpdateNotificationsSuccess implements Action {
  readonly type = UPDATE_NOTIFICATIONS_SUCCESS;
}

export type NotificationsAction =
  | GetNotifications
  | GetNotificationsFail
  | GetNotificationsSuccess
  | AddNotification
  | UpdateNotification
  | UpdateNotificationFail
  | UpdateNotificationSuccess
  | UpdateNotifications
  | UpdateNotificationsFail
  | UpdateNotificationsSuccess;
