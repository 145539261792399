<div class="map">
  <ul class="xAxis">
    <li *ngFor="let letter of xAxis">{{ letter }}</li>
  </ul>
  <ul class="yAxis">
    <li *ngFor="let number of yAxis">{{ number + 1 }}</li>
  </ul>
  <div
    class="drawing"
    [ngStyle]="backgroundStyle"
    [style.width.px]="dimensions.width"
    [style.height.px]="dimensions.height"
    [id]="id"
  >
    <div
      class="map__tooltip"
      *ngIf="tooltipPosition && tooltipObject && mapObjectForTooltip"
      [class.visible]="tooltipPosition && tooltipObject && mapObjectForTooltip"
      (mouseenter)="setTooltipHover(true)"
      (mouseleave)="setTooltipHover(false)"
      [style.left.px]="tooltipPosition?.x"
      [style.top.px]="tooltipPosition?.y"
    >
      <div class="arrow-up"></div>
      <div class="tooltip__header">
        <ng-container *ngFor="let type of types">
          <span *ngIf="type.typeName === mapObjectForTooltip.type">
            {{ type | translateFromObject : { defaultKey: 'typeName' } }}
          </span>
        </ng-container>
        <span [ngSwitch]="mapObjectForTooltip.type">
          <ng-container *ngSwitchCase="EquipmentTypes.Crane"> {{ 'general.crane_l' | translate }} </ng-container>
          <ng-container *ngSwitchCase="MapObjectTypes.Place">
            {{ 'general.unloading_place_l' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="MapObjectTypes.Depot"> {{ 'general.depot_l' | translate }} </ng-container>
          <ng-container *ngSwitchCase="EquipmentTypes.Elevator"> {{ 'general.elevator_l' | translate }} </ng-container>
          <ng-container *ngSwitchCase="EquipmentTypes.Excavator">
            {{ 'general.excavator_l' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="EquipmentTypes.Forklift"> {{ 'general.forklift_l' | translate }} </ng-container>
          <ng-container *ngSwitchCase="EquipmentTypes.MiniExcavator">
            {{ 'general.miniExcavator_l' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="EquipmentTypes.MobileCrane">
            {{ 'general.mobile_crane_l' | translate }}
          </ng-container>
        </span>
        <span class="name with-redirection" (click)="selectObject()"> {{ mapObjectForTooltip.shortName }} </span>
      </div>
      <ng-container *ngIf="mapObjectForTooltip.type !== MapObjectTypes.Depot; else tooltipDepotTemplate">
        <div class="tooltip__utilization">
          <span> {{ 'tooltip.utilization_l' | translate }}</span>
          <svg-icon name="icon-sunny-24px" [size]="13"></svg-icon>
          <span> {{ mapObjectForTooltip.calculate.dayshift }} %</span>
          <svg-icon name="icon-brightness-24px" [size]="13"></svg-icon>
          <span> {{ mapObjectForTooltip.calculate.nightshift }} %</span>
        </div>
        <div class="tooltip__times">
          <div
            class="time-row"
            [class.time-row--selected]="term.inCurrentTime"
            *ngFor="let term of mapObjectForTooltip.combinedTerms"
          >
            <div class="period">{{ term.expectedFrom | date : 'HH:mm' }} - {{ term.expectedTo | date : 'HH:mm' }}</div>
            <span *ngIf="!term.slotType; else showDeliveryDetails" class="text text--available">
              {{ 'general.available_a' | translate }}
            </span>
            <ng-template #showDeliveryDetails>
              <span
                class="text text--delivery with-redirection"
                *ngIf="term.delivery?.deliveryNumber; else showDeliverySubcontractor"
                (click)="openOnetimeDetails(term.delivery.id)"
              >
                {{ term.delivery.deliveryNumber | empty }}
              </span>
              <ng-template #showDeliverySubcontractor>
                <span
                  [style.color]="'#' + term.delivery.subcontractor.scheduleColor.textColor"
                  class="text text--company"
                  [class.with-redirection]="term.delivery.subcontractor.companyCode"
                >
                  {{ term.delivery.subcontractor.companyCode | empty }}
                </span>
              </ng-template>
            </ng-template>
          </div>
        </div>
        <div class="tooltip__buttons">
          <button
            class="button button--animated"
            *ngIf="!tooltipTypesWithoutAllocateButton.includes(mapObjectForTooltip.type)"
            routerLink="/scheduler"
            (click)="openEquipmentAside(mapObjectForTooltip.id, mapObjectForTooltip.type)"
          >
            {{ 'general.allocate_l' | translate }}
          </button>
          <button
            class="button button--primary"
            *ngIf="tooltipTypesWithDeliveryButton.includes(mapObjectForTooltip.type)"
            (click)="emitOpenOnetimeDelivery()"
          >
            {{ 'general.delivery_l' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <ul class="map__legend" *ngIf="showLegend" [class.editable]="editable">
    <li *ngFor="let object of objectsSettings" [attr.test-id]="'new' + object.type + 'Button'">
      <button (click)="draw(object)" [class.active]="editable && isActive(object)">
        <span>
          <div [inlineSVG]="editable && isActive(object) ? object.legendIconActive : object.legendIcon"></div>
        </span>
        {{ object.name | translate }}
      </button>
    </li>
  </ul>
</div>

<ng-template #tooltipDepotTemplate>
  <app-depot-tooltip [mapObjectForTooltip]="mapObjectForTooltip"></app-depot-tooltip>
</ng-template>
