import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDeliveryRuns from './delivery-runs.reducer';
import { DeliveryRunsState } from '.';

const stateSelector = createFeatureSelector<DeliveryRunsState>('deliveryRuns');

export const selectDeliveryRun = createSelector(
  stateSelector,
  fromDeliveryRuns.getDeliveryRun,
);

export const selectDeliveryRunLogs = createSelector(
  stateSelector,
  fromDeliveryRuns.getDeliveryRunLogs,
);

export const selectDeliveryRunTicket = createSelector(
  stateSelector,
  fromDeliveryRuns.getTicket,
);

export const selectDeliveryRuns = createSelector(
  stateSelector,
  (state: DeliveryRunsState) => state.deliveryRuns,
);

export const selectDeliveryRunsCount = createSelector(
  stateSelector,
  (state: DeliveryRunsState) => state.deliveryRunsCount,
);

export const selectDeliveryRunLoading = createSelector(
  stateSelector,
  (state: DeliveryRunsState) => state.deliveryRunLoading,
);
