import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { DeliveriesHttpService } from '@app/core/http/deliveries.http.service';
import { AlertService } from '@ppgt/web/shared/core';
import * as actions from './delivery-stages.action';

@Injectable()
export class DeliveryStagesEffects {
  constructor(
    private http: DeliveriesHttpService,
    private actions$: Actions<actions.DeliveryStageActions>,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) { }

  
  dispatchStageAction$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.DISPATCH_STAGE_ACTION),
    map((action: actions.DispatchStageAction) => action.payload),
    switchMap(actionPayload => {
      const { deliveryId, dispatchedAction, stageId } = actionPayload.dispatchPayload;
      return this.http.sendStageAction(deliveryId, stageId, dispatchedAction).pipe(
        map(res => new actions.DispatchStageActionSuccess({ updatedDeliveryStage: res })),
        catchError(error => [new actions.DispatchStageActionFail({ error })]),
      );
    }),
  ));

  
  dispatchStageActionSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.DISPATCH_STAGE_ACTION_SUCCESS),
    tap(() => {
      const message = this.translateService.instant('delivery_stages.stage_action_send_successfully');
      this.alertService.showInfo(message);
    })
  ), { dispatch: false });

  
  dispatchStageActionFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.DISPATCH_STAGE_ACTION_FAIL),
    tap(() => {
      const message = this.translateService.instant('delivery_stages.stage_action_failed');
      this.alertService.showError(message);
    })
  ), { dispatch: false });

  
  sendTruck$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SEND_TRUCK),
    map((action: actions.SendTruck) => action.payload.sendTruckPayload),
    switchMap(sendTruckPayload => this.http.sendTruck(sendTruckPayload).pipe(
        map(res => new actions.SendTruckSuccess({ updatedDeliveryStage: res })),
        catchError(error => [new actions.SendTruckFail({ error })]),
      )),
  ));

  
  sendTruckSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SEND_TRUCK_SUCCESS),
    tap(() => {
      const message = this.translateService.instant('delivery_stages.run_created_successfully');
      this.alertService.showInfo(message);
    })
  ), { dispatch: false });

  
  sendTruckFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.SEND_TRUCK_FAIL),
    tap(() => {
      const message = this.translateService.instant('delivery_stages.run_creation_fail');
      this.alertService.showError(message);
    })
  ), { dispatch: false });
}
