import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()
export class ExternalUrlRedirectResolver implements Resolve<boolean> {
  resolve(route: ActivatedRouteSnapshot): boolean {
    window.location.href = route.data.externalUrl;
    return false;
  }
}
