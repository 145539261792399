import { HttpErrorResponse } from '@angular/common/http'
import { createReducer, on } from '@ngrx/store'
import { Task } from '@ppgt/web/tasks/domain'
import * as fromTasksActions from './tasks.actions'

export const TASKS_FEATURE_KEY = 'tasks'
export const SUBTASKS_FEATURE_KEY = 'subtasks'
export const TASKS_IMPORT_SOURCE_KEY = 'taskImportSource'
export const RESET_TASKS_DATA = 'resetTasksData'

export interface TasksState {
  tasks: Task[]
  loading: boolean
  error: HttpErrorResponse | null
}

export interface SubtasksState {
  subtasks: Subtasks
  loading: boolean
  error: HttpErrorResponse | null
}

export interface TasksImportSourceState {
  source: string | null
  loading: boolean
  error: HttpErrorResponse | null
}

export interface TasksPartialState {
  readonly [TASKS_FEATURE_KEY]: TasksState
}

export interface SubtasksPartialState {
  readonly [SUBTASKS_FEATURE_KEY]: SubtasksState
}

export interface Subtasks {
  [key: string]: Task[]
}

export const initialState: TasksState = {
  tasks: [],
  loading: false,
  error: null,
}

export const subtasksInitialState: SubtasksState = {
  subtasks: {},
  loading: false,
  error: null
}

export const tasksImportSourceInitState: TasksImportSourceState = {
  source: null,
  loading: false,
  error: null
}

export const tasksReducer = createReducer(initialState,
  on(fromTasksActions.getTasks, (state) => ({
    ...state,
    tasks: [],
    loading: true,
    error: null
  })),
  on(fromTasksActions.getTasksFail, (state, { error }) => ({
    ...state,
    tasks: [],
    loading: false,
    error: error
  })),
  on(fromTasksActions.getTasksSuccess, (state, { tasks }) => ({
    ...state,
    tasks: tasks,
    loading: false,
    error: null
  })),
  on(fromTasksActions.updateTaskMaterialsSuccess, (state) => {
    return {
      ...state,
      loading: false,
      error: null
    }
  }),
  )

export const subtasksReducer = createReducer(subtasksInitialState,
  on(fromTasksActions.getSubtasks, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromTasksActions.getSubtasksFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(fromTasksActions.getSubtasksSuccess, (state, { tasks, childId }) => ({
    ...state,
    [childId]: tasks,
    loading: false,
    error: null
  })),
  on(fromTasksActions.updateTaskMaterialsSuccess, ( state, { taskChildId, materials, subtaskId }) => {
    let assignedMaterials;
    const subtaskIndex = state[subtaskId].findIndex( subtask => subtask.externalId === taskChildId);
    const getLastMaterialsNode = (materials, childId) => {
      if (materials.children) {
        for (let i = 0; i < materials.children?.length; i++) {
          if (materials.children[i].externalId === childId) {
            assignedMaterials = materials.children[i].materials
          }

          getLastMaterialsNode(materials.children[i], childId)
        }
      }

      return;
    }

    getLastMaterialsNode(materials, taskChildId)
    state[subtaskId][subtaskIndex].materials = assignedMaterials

    return {
      ...state,
      loading: false,
      error: null
    }
  }),
)

export const tasksImportSourceReducer = createReducer(tasksImportSourceInitState,
  on(fromTasksActions.getTasksImportSource, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromTasksActions.getTasksImportSourceFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(fromTasksActions.getTasksImportSourceSuccess, (state) => {
    return({
    ...state,
    loading: false,
    error: null
  })}),
)

export const resetTasksDataReducer = createReducer(tasksImportSourceInitState,
  on(fromTasksActions.resetTasksData, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(fromTasksActions.resetTasksDataFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(fromTasksActions.resetTasksDataSuccess, (state) => {
    return({
    ...state,
    source: null,
    loading: false,
    error: null
  })}),
)
