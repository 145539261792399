import { isFunction, get } from 'lodash-es';

import {
  Action,
  CheckedDeliveries,
  Delivery,
  DeliveryContacts,
  Propose,
  VerificationStatus,
  DeliveryUnloadingMethod,
  DeliveryComment,
  Distributor,
  DeliveryMaterial,
  DeliveryFormedFilled,
  ReducerMap,
  GetDeliveryLogsSuccessPayload,
  Unit,
  CreateInspectionSuccessPayload,
  DeliveryRun,
  DeliveryLongterm,
  DeliveryFile,
  DeliveryPourCard,
  LongtermDeliveryTerm,
} from '@app/state/interfaces';
import { GetDeliveriesRes, ResponseMetadata } from '@app/core/http/interfaces';

import { DeliveriesState } from './index';
import * as actions from './deliveries.action';
import { stagesReducerMap } from './delivery-stages.reducer';

const initialState: DeliveriesState = {
  deliveriesList: [],
  longtermDeliveries: [],
  delivery: null,
  check: null,
  propose: null,
  totalObjectsCount: null,
  deliveryLoading: false,
  deliveriesLoading: false,
  deliveryForm: null,
  contacts: null,
  unloadingMethods: null,
  comments: null,
  distributors: null,
  units: null,
  pourCard: null,
  cubeTestsPurposes: [],
  longtermDeliveryTerms: [],
  deliveryFile: null,
};

const reducerMap: ReducerMap<DeliveriesState> = {
  [actions.GET_DELIVERIES]: (state, payload) => ({ ...state, deliveriesLoading: payload.showLoader }),
  [actions.GET_DELIVERIES_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_DELIVERIES_SUCCESS]: (state, data: GetDeliveriesRes['data']) => ({
    ...state,
    deliveriesList: data.deliveries,
    totalObjectsCount: data.meta.count,
    deliveriesLoading: false,
  }),
  [actions.GET_LONGTERM_DELIVERIES]: (state, payload: { showLoader: boolean }) => ({
    ...state,
    deliveriesLoading: payload.showLoader,
  }),
  [actions.GET_LONGTERM_DELIVERIES_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_LONGTERM_DELIVERIES_SUCCESS]: (
    state,
    payload: {
      deliveries: DeliveryLongterm[];
      meta: ResponseMetadata;
      toggleDeliveriesAndStages: boolean;
    },
  ) => {
    let longtermDeliveries = payload.deliveries;

    if (payload.toggleDeliveriesAndStages) {
      longtermDeliveries = longtermDeliveries.map((delivery) => {
        const deliveryStages = delivery.deliveryStages.map((stage) => ({ ...stage, toggled: true }));

        return { ...delivery, deliveryStages, toggled: true };
      });
    }

    return {
      ...state,
      longtermDeliveries,
      totalObjectsCount: payload.meta.count,
      deliveriesLoading: false,
    };
  },
  [actions.CREATE_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.CREATE_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CREATE_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    delivery,
    deliveriesList: [delivery, ...state.deliveriesList],
    deliveryLoading: false,
    deliveryForm: null,
    propose: [],
  }),
  [actions.CREATE_LONGTIME_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.CREATE_LONGTIME_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CREATE_LONGTIME_DELIVERY_SUCCESS]: (state, newDelivery: DeliveryLongterm) => {
    const updatedDeliveriesList = [newDelivery, ...state.longtermDeliveries];

    return {
      ...state,
      deliveryLoading: false,
      longtermDeliveries: updatedDeliveriesList,
    };
  },
  [actions.UPDATE_LONGTIME_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.UPDATE_LONGTIME_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.UPDATE_LONGTIME_DELIVERY_SUCCESS]: (state, updatedDelivery: DeliveryLongterm) => {
    const updatedDeliveriesList = state.longtermDeliveries.map((delivery: DeliveryLongterm) => {
      if (delivery.id !== updatedDelivery.id) {
        return delivery;
      }

      return updatedDelivery;
    });

    return {
      ...state,
      delivery: updatedDelivery,
      deliveryLoading: false,
      longtermDeliveries: updatedDeliveriesList,
    };
  },
  [actions.GET_DELIVERY]: (state) => ({ ...state, delivery: null, deliveryLoading: true }),
  [actions.GET_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.GET_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    delivery,
    deliveryLoading: false,
  }),
  [actions.UPDATE_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.UPDATE_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.UPDATE_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    deliveriesList: state.deliveriesList.map((item) => (item.id === delivery.id ? { ...item, ...delivery } : item)),
    delivery,
    deliveryLoading: false,
    propose: [],
  }),
  [actions.RESTORE_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.RESTORE_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.RESTORE_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    deliveriesList: state.deliveriesList.map((item) => (item.id === delivery.id ? { ...item, ...delivery } : item)),
    delivery,
    deliveryLoading: false,
  }),
  [actions.UPDATE_DELIVERY_FILES]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.UPDATE_DELIVERY_FILES_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.UPDATE_DELIVERY_FILES_SUCCESS]: (state, deliveryFiles: DeliveryFile[]) => {
    if (!state.delivery) {
      return {
        ...state,
      };
    }

    const combinedDeliveryFiles = state.delivery.deliveryFiles || [];
    combinedDeliveryFiles.push(...deliveryFiles);

    const delivery = {
      ...state.delivery,
      deliveryFiles: combinedDeliveryFiles,
    };

    return {
      ...state,
      delivery,
      deliveriesList: state.deliveriesList.map((item) =>
        deliveryFiles[0] && item.id === deliveryFiles[0].deliveryId ? delivery : item,
      ),
      deliveryLoading: false,
    };
  },
  [actions.OPEN_DELIVERY_FILE]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.OPEN_DELIVERY_FILE_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.OPEN_DELIVERY_FILE_SUCCESS]: (state, deliveryFile: DeliveryFile) => ({
    ...state,
    deliveryFile,
    deliveryLoading: false,
  }),
  [actions.CHECK_IN_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.CHECK_IN_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CHECK_IN_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    deliveriesList: state.deliveriesList.map((item) => (item.id === delivery.id ? { ...item, ...delivery } : item)),
    delivery,
    deliveryLoading: false,
  }),
  [actions.CHECK_OUT_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.CHECK_OUT_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CHECK_OUT_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    deliveriesList: state.deliveriesList.map((item) =>
      delivery && item.id === delivery.id ? { ...item, ...delivery } : item,
    ),
    delivery,
    deliveryLoading: false,
  }),
  [actions.REJECT_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.REJECT_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.REJECT_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    deliveriesList: state.deliveriesList.filter((item) => item.id !== delivery.id),
    deliveryLoading: false,
  }),
  [actions.CANCEL_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.CANCEL_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CANCEL_DELIVERY_SUCCESS]: (state, delivery: Delivery) => ({
    ...state,
    deliveriesList: state.deliveriesList.filter((item) => item.id !== delivery.id),
    deliveryLoading: false,
  }),
  [actions.CALL_DRIVER]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.CALL_DRIVER_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CALL_DRIVER_SUCCESS]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CHECK_DELIVERY]: (state) => ({
    ...state,
    check: { verificationStatus: VerificationStatus.Loading },
    deliveryLoading: true,
  }),
  [actions.CHECK_DELIVERY_RESET]: (state) => ({
    ...state,
    check: { verificationStatus: VerificationStatus.Loading },
  }),
  [actions.CHECK_DELIVERY_SUCCESS]: (state, check: CheckedDeliveries) => {
    const verificationStatus =
      check.deliveries.length > 1 ? VerificationStatus.Deliveries : VerificationStatus.OneDelivery;
    return {
      ...state,
      check: { ...check, verificationStatus },
      deliveryLoading: false,
    };
  },
  [actions.CHECK_DELIVERY_FAIL]: (state) => ({
    ...state,
    check: { verificationStatus: VerificationStatus.Error },
    deliveryLoading: false,
  }),
  [actions.PROPOSE_DELIVERY]: (state) => ({ ...state, deliveryLoading: true, propose: null }),
  [actions.PROPOSE_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.PROPOSE_DELIVERY_SUCCESS]: (state, propose: Propose[]) => ({
    ...state,
    propose,
    deliveryLoading: false,
  }),
  [actions.GET_MANUAL_MODE_DELIVERIES]: (state) => ({
    ...state,
    deliveriesList: [],
    deliveriesLoading: true,
  }),
  [actions.GET_MANUAL_MODE_DELIVERIES_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_MANUAL_MODE_DELIVERIES_SUCCESS]: (state, deliveriesList: Delivery[]) => ({
    ...state,
    deliveriesList,
    deliveriesLoading: false,
  }),
  [actions.SEND_MANUAL_MODE_DELIVERIES]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.SEND_MANUAL_MODE_DELIVERIES_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.SEND_MANUAL_MODE_DELIVERIES_SUCCESS]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.SET_MANUAL_MODE_DELIVERY]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.SET_MANUAL_MODE_DELIVERY_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.SET_MANUAL_MODE_DELIVERY_SUCCESS]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.DELIVERY_FORMED]: (state, deliveryForm: DeliveryFormedFilled) => ({
    ...state,
    deliveryForm,
  }),
  [actions.GET_DELIVERY_CONTACTS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.GET_DELIVERY_CONTACTS_SUCCESS]: (state, contacts: DeliveryContacts) => ({
    ...state,
    contacts,
    deliveryLoading: false,
  }),
  [actions.GET_DELIVERY_CONTACTS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.GET_DELIVERY_UNLOADING_METHODS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.GET_DELIVERY_UNLOADING_METHODS_SUCCESS]: (state, unloadingMethods: DeliveryUnloadingMethod[]) => ({
    ...state,
    unloadingMethods,
    deliveryLoading: false,
  }),
  [actions.GET_DELIVERY_UNLOADING_METHODS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.GET_DELIVERY_COMMENTS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.GET_DELIVERY_COMMENTS_SUCCESS]: (state, comments: DeliveryComment[]) => ({
    ...state,
    comments,
    deliveryLoading: false,
  }),
  [actions.GET_DELIVERY_COMMENTS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.ADD_DELIVERY_COMMENTS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.ADD_DELIVERY_COMMENTS_SUCCESS]: (state, comments: DeliveryComment[]) => ({
    ...state,
    comments,
    deliveryLoading: false,
  }),
  [actions.ADD_DELIVERY_COMMENTS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.GET_DISTRIBUTORS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.GET_DISTRIBUTORS_SUCCESS]: (state, distributors: Distributor[]) => ({
    ...state,
    distributors,
    deliveryLoading: false,
  }),
  [actions.GET_DISTRIBUTORS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.START_LOADING]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.STOP_LOADING]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.CREATE_MATERIAL_INSPECTION]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.CREATE_MATERIAL_INSPECTION_SUCCESS]: (state, payload: CreateInspectionSuccessPayload) => {
    const updatedDeliveryMaterials = state.delivery.deliveryMaterials.map((delMaterial) =>
      delMaterial.id === payload.deliveryMaterial.id ? payload.deliveryMaterial : delMaterial,
    );

    return {
      ...state,
      deliveryLoading: false,
      delivery: {
        ...state.delivery,
        deliveryInspectionStatus: payload.deliveryInspectionStatus,
        deliveryMaterials: updatedDeliveryMaterials,
      },
    };
  },
  [actions.CREATE_MATERIAL_INSPECTION_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.UPDATE_DELIVERY_MATERIAL]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.UPDATE_DELIVERY_MATERIAL_SUCCESS]: (state, deliveryMaterials: DeliveryMaterial[]) => {
    const updatedDeliveryMaterials = deliveryMaterials.map((deliveryMaterial) => {
      const deliveryMaterialsFromState: DeliveryMaterial[] = get(state, 'delivery.deliveryMaterials', []);

      const deliveryMaterialFromState = deliveryMaterialsFromState.find(({ id }) => id === deliveryMaterial.id);

      return deliveryMaterialFromState ? { ...deliveryMaterialFromState, ...deliveryMaterial } : deliveryMaterial;
    });

    const deliveriesList = state.deliveriesList.map((delivery) =>
      delivery.id === deliveryMaterials[0].deliveryId ? { ...delivery, deliveryMaterials } : delivery,
    );

    return {
      ...state,
      deliveryLoading: false,
      deliveriesList,
      delivery: {
        ...state.delivery,
        deliveryMaterials: updatedDeliveryMaterials,
      },
    };
  },
  [actions.UPDATE_DELIVERY_MATERIAL_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.GET_DELIVERY_LOGS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.GET_DELIVERY_LOGS_SUCCESS]: (state, payload: GetDeliveryLogsSuccessPayload) => ({
    ...state,
    deliveryLoading: false,
    delivery: {
      ...state.delivery,
      logs: payload.logs,
    },
  }),
  [actions.GET_DELIVERY_LOGS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.SEND_ORDERS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.SEND_ORDERS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.SEND_ORDERS_SUCCESS]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.GET_UNITS]: (state) => ({ ...state, deliveryLoading: true }),
  [actions.GET_UNITS_SUCCESS]: (state, units: Unit[]) => ({
    ...state,
    deliveryLoading: false,
    units,
  }),
  [actions.GET_UNITS_FAIL]: (state) => ({ ...state, deliveryLoading: false }),
  [actions.ASSIGN_STAGE_TO_RUN]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.ASSIGN_STAGE_TO_RUN_SUCCESS]: (state: DeliveriesState, deliveryRun: DeliveryRun) => {
    const longtermDeliveries = state.longtermDeliveries.map((delivery) => {
      if (deliveryRun.deliveryId !== delivery.id) {
        return delivery;
      }

      const unassignedDeliveryRuns = delivery.unassignedDeliveryRuns.filter(
        (unassignedRun: DeliveryRun) => unassignedRun.id !== deliveryRun.id,
      );

      const deliveryStages = delivery.deliveryStages.map((stage) => {
        if (stage.id !== deliveryRun.deliveryStageId) {
          return stage;
        }

        return {
          ...stage,
          deliveryRuns: [...stage.deliveryRuns, deliveryRun],
        };
      });

      return {
        ...delivery,
        unassignedDeliveryRuns,
        deliveryStages,
      };
    });

    return {
      ...state,
      longtermDeliveries,
      deliveriesLoading: false,
    };
  },
  [actions.ASSIGN_STAGE_TO_RUN_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.SET_DELIVERY_TIMES]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.SET_DELIVERY_TIMES_SUCCESS]: (state, payload: { deliveryId: string; stageId: string; run: DeliveryRun }) => {
    const longtermDeliveries = state.longtermDeliveries.map((oldDelivery) => {
      if (oldDelivery.id !== payload.deliveryId) {
        return oldDelivery;
      }

      const deliveryStages = oldDelivery.deliveryStages.map((oldStage) => {
        if (oldStage.id !== payload.stageId) {
          return oldStage;
        }

        const deliveryRuns = oldStage.deliveryRuns.map((oldRun) =>
          oldRun.id !== payload.run.id ? oldRun : { ...oldRun, ...payload.run },
        );

        return {
          ...oldStage,
          deliveryRuns,
        };
      });

      return {
        ...oldDelivery,
        deliveryStages,
      };
    });

    return {
      ...state,
      deliveriesLoading: false,
      longtermDeliveries,
    };
  },
  [actions.SET_DELIVERY_TIMES_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.SEND_TICKET_IMAGE]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.SEND_TICKET_IMAGE_SUCCESS]: (state, updatedRun: DeliveryRun) => {
    const longtermDeliveries = state.longtermDeliveries.map((delivery) => {
      const deliveryStages = delivery.deliveryStages.map((stage) => {
        const deliveryRuns = stage.deliveryRuns.map((run) => (run.id === updatedRun.id ? updatedRun : run));

        return { ...stage, deliveryRuns };
      });

      return { ...delivery, deliveryStages };
    });

    return { ...state, longtermDeliveries, deliveriesLoading: false };
  },
  [actions.SEND_TICKET_IMAGE_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.UPDATE_DELIVERY_RUN]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.UPDATE_DELIVERY_RUN_SUCCESS]: (state, payload) => {
    const longtermDeliveries = state.longtermDeliveries.map((delivery) => {
      const deliveryStages = delivery.deliveryStages.map((stage) => {
        const deliveryRuns = stage.deliveryRuns.map((run) => (run.id === payload.run.id ? payload.run : run));

        return { ...stage, deliveryRuns };
      });

      return { ...delivery, deliveryStages };
    });

    return { ...state, longtermDeliveries, deliveriesLoading: false };
  },
  [actions.UPDATE_DELIVERY_RUN_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.SEND_ONETIME_DELIVERY_TICKET_IMAGES]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.SEND_ONETIME_DELIVERY_TICKET_IMAGES_SUCCESS]: (
    state,
    payload: { data: DeliveryFile[]; deliveryId: string },
  ) => {
    const deliveriesList = state.deliveriesList.map((delivery) => {
      const updatedDeliveryFiles = delivery.deliveryFiles ? [...delivery.deliveryFiles, ...payload.data] : payload.data;

      return delivery.id === payload.deliveryId ? { ...delivery, deliveryFiles: updatedDeliveryFiles } : delivery;
    });

    const oldDeliveryFiles = state.delivery.deliveryFiles;

    const deliveryFiles = oldDeliveryFiles ? [...oldDeliveryFiles, ...payload.data] : payload.data;

    const updatedDelivery = {
      ...state.delivery,
      deliveryFiles,
    };

    return {
      ...state,
      deliveriesList,
      delivery: updatedDelivery,
      deliveriesLoading: false,
    };
  },
  [actions.SEND_ONETIME_DELIVERY_TICKET_IMAGES_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_CURRENT_TICKET_IMAGE_URL]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.GET_CURRENT_TICKET_IMAGE_URL_SUCCESS]: (state, payload: { deliveryId: string; file: DeliveryFile }) => {
    const deliveriesList = state.deliveriesList.map((delivery) => {
      if (!delivery.deliveryFiles || delivery.deliveryFiles.length === 0) {
        return delivery;
      }

      const deliveryFiles = delivery.deliveryFiles.map((deliveryFile) =>
        deliveryFile.id === payload.file.id ? payload.file : deliveryFile,
      );

      return { ...delivery, deliveryFiles };
    });

    const deliveryFiles = state.delivery.deliveryFiles.map((deliveryFile) =>
      deliveryFile.id === payload.file.id ? payload.file : deliveryFile,
    );

    const updatedDelivery = {
      ...state.delivery,
      deliveryFiles,
    };

    return {
      ...state,
      deliveriesList,
      delivery: updatedDelivery,
      deliveriesLoading: false,
    };
  },
  [actions.GET_CURRENT_TICKET_IMAGE_URL_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_POUR_CARD]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.GET_POUR_CARD_SUCCESS]: (state, pourCard: DeliveryPourCard) => ({
    ...state,
    pourCard,
    deliveriesLoading: false,
  }),
  [actions.DOWNLOAD_DELIVERY_CARD]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.DOWNLOAD_DELIVERY_CARD_SUCCESS]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.DOWNLOAD_DELIVERY_CARD_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_POUR_CARD_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_CUBE_TESTS_PURPOSES]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.GET_CUBE_TESTS_PURPOSES_SUCCESS]: (state, cubeTestsPurposes) => ({
    ...state,
    cubeTestsPurposes,
    deliveriesLoading: false,
  }),
  [actions.GET_CUBE_TESTS_PURPOSES_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.SEND_POUR_CARD]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.SEND_POUR_CARD_SUCCESS]: (state, pourCard) => ({
    ...state,
    pourCard,
    deliveriesLoading: false,
  }),
  [actions.SEND_POUR_CARD_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.UPDATE_POUR_CARD]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.UPDATE_POUR_CARD_SUCCESS]: (state, pourCard) => ({
    ...state,
    pourCard,
    deliveriesLoading: false,
  }),
  [actions.UPDATE_POUR_CARD_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.CLEAR_POUR_CARD_STATE]: (state) => ({ ...state, pourCard: null }),
  [actions.REMOVE_DELIVERY_FILE]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.REMOVE_DELIVERY_FILE_SUCCESS]: (state, payload: DeliveryFile[]) => {
    const delivery = {
      ...state.delivery,
      deliveryFiles: payload,
    };

    return {
      ...state,
      delivery,
      deliveriesList: state.deliveriesList.map((item) => (item.id === delivery.id ? delivery : item)),
      deliveriesLoading: false,
    };
  },
  [actions.REMOVE_DELIVERY_FILE_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),
  [actions.GET_LONGTERM_DELIVERY_TERMS]: (state) => ({ ...state, deliveriesLoading: true }),
  [actions.GET_LONGTERM_DELIVERY_TERMS_SUCCESS]: (state, longtermDeliveryTerms: LongtermDeliveryTerm[]) => ({
    ...state,
    longtermDeliveryTerms,
    deliveriesLoading: false,
  }),
  [actions.GET_LONGTERM_DELIVERY_TERMS_FAIL]: (state) => ({ ...state, deliveriesLoading: false }),

  // Delivery Stages Reducer
  ...stagesReducerMap,
};

export function deliveriesReducer(state = initialState, action: Action) {
  return isFunction(reducerMap[action.type]) ? reducerMap[action.type](state, action.payload) : state;
}

export const getDeliveries = (state: DeliveriesState) => state.deliveriesList;
export const getDelivery = (state: DeliveriesState) => state.delivery;
export const getCheck = (state: DeliveriesState) => state.check;
export const getPropose = (state: DeliveriesState) => state.propose;
export const getDeliveryCount = (state: DeliveriesState) => state.totalObjectsCount;
export const getDeliveryLoading = (state: DeliveriesState) => state.deliveryLoading;
export const getDeliveriesLoading = (state: DeliveriesState) => state.deliveriesLoading;
export const getDeliveryForm = (state: DeliveriesState) => state.deliveryForm;
export const getContacts = (state: DeliveriesState) => state.contacts;
export const getUnloadingMethods = (state: DeliveriesState) => state.unloadingMethods;
export const getComments = (state: DeliveriesState) => state.comments;
export const getDistributors = (state: DeliveriesState) => state.distributors;
export const getUnits = (state: DeliveriesState) => state.units;
export const getLongtermDeliveries = (state: DeliveriesState) => state.longtermDeliveries;
export const getPourCard = (state: DeliveriesState) => state.pourCard;
export const getCubeTestsPurposes = (state: DeliveriesState) => state.cubeTestsPurposes;
export const getLongtermDeliveryTerms = (state: DeliveriesState) => state.longtermDeliveryTerms;
