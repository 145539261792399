import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, filter, take, withLatestFrom, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromAppState from '@app/state';
import { I18nService } from '@app/core/i18n.service';
import { DateTimeService } from '@app/core';
import { GetTasksImportSource } from '@app/state';

@Injectable({
  providedIn: 'root',
})
export class UserDataGuard implements CanActivate {

  constructor(
    private store: Store<fromAppState.AppState>,
    private i18nService: I18nService,
    private dateTimeService: DateTimeService
  ) { }

  canActivate(): Observable<boolean> {
    this.store
      .select(fromAppState.getUserData)
      .pipe(
        filter(Boolean),
        take(1)
      )
      .subscribe(userData => {
        this.i18nService.setLanguage(userData.language);
        this.dateTimeService.updateLocale(userData.language);
      });
    
    this.store.dispatch(new GetTasksImportSource())
    
    return this.store.select(fromAppState.getUserDataLoaded).pipe(
      withLatestFrom(this.store.select(fromAppState.isLoggedIn)),
      tap(([loaded, isLoggedIn]) => (isLoggedIn && !loaded && this.store.dispatch(new fromAppState.GetUserData()))),
      map(([loaded]) => loaded),
      filter(Boolean),
      take(1)
    );
  }
}
