import { isFunction } from 'lodash-es';

import { Action, ReducerMap } from '@app/state/interfaces'; // add interfaces

import * as actions from './tasks.action';

export interface TasksImportSourceState {
  source: string | null;
  error: any;
  loaded: boolean;
}

const initialState: TasksImportSourceState = {
  source: null,
  error: null,
  loaded: false,
};

const reducerMap: ReducerMap<TasksImportSourceState> = {
  [actions.GET_TASKS_IMPORT_SOURCE]: state => ({ ...state, loading: true }),
  [actions.GET_TASKS_IMPORT_SOURCE_FAIL]: state => ({ ...state, loading: false }),
  [actions.GET_TASKS_IMPORT_SOURCE_SUCCESS]: (state, source: string | null) => ({
    ...state,
    source,
    loading: false,
  }),
  [actions.SET_TASKS_IMPORT_SOURCE]: state => ({ ...state, loading: true }),
  [actions.SET_TASKS_IMPORT_SOURCE_FAIL]: state => ({ ...state, loading: false }),
  [actions.SET_TASKS_IMPORT_SOURCE_SUCCESS]: (state, source: string | null) => ({
    ...state,
    source,
    loading: false,
  }),
};

export function tasksReducer(state: TasksImportSourceState = initialState, action: Action) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}
