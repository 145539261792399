import { Pipe, PipeTransform } from '@angular/core';
import { MenuTab } from '@app/state/interfaces';
import { PermissionsEnum } from '@ppgt/web/shared/domain';

@Pipe({ name: 'filterTabs' })
export class FilterTabsPipe implements PipeTransform {
  transform(tabs: MenuTab[], grantedPermissions: PermissionsEnum[]): MenuTab[] {
    return tabs.filter((tab) => !tab.withPermission || grantedPermissions.includes(tab.withPermission));
  }
}
