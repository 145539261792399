import { isFunction } from 'lodash-es';
import * as actions from './reservations.actions';
import { ReservationsState } from '.';
import { Action, EquipmentReservation } from '@app/state/interfaces';

const initialState: ReservationsState = {
  equipmentReservation: null,
  isLoading: false,
};

const reducerMap = {
  [actions.CREATE_EQUIPMENT_RESERVATION]: (state: ReservationsState) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_EQUIPMENT_RESERVATION_SUCCESS]: (
    state: ReservationsState,
    payload: { reservation: EquipmentReservation },
  ) => {
    return {
      ...state,
      loading: false,
      equipmentReservation: payload.reservation
    };
  },
  [actions.CREATE_EQUIPMENT_RESERVATION_FAIL]: (state: ReservationsState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_EQUIPMENT_RESERVATION]: (state: ReservationsState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_EQUIPMENT_RESERVATION_SUCCESS]: (
    state: ReservationsState,
    payload: { reservation: EquipmentReservation },
  ) => {
    return {
      ...state,
      loading: false,
      equipmentReservation: payload.reservation
    };
  },
  [actions.UPDATE_EQUIPMENT_RESERVATION_FAIL]: (state: ReservationsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_RESERVATION]: (state: ReservationsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_EQUIPMENT_RESERVATION_SUCCESS]: (
    state: ReservationsState,
    payload: { reservation: EquipmentReservation },
  ) => ({
    ...state,
    loading: false,
    equipmentReservation: payload.reservation,
  }),
  [actions.GET_EQUIPMENT_RESERVATION_FAIL]: (state: ReservationsState) => ({
    ...state,
    loading: false,
  }),
  [actions.DELETE_EQUIPMENT_RESERVATION]: (state: ReservationsState) => ({
    ...state,
    loading: true,
  }),
  [actions.DELETE_EQUIPMENT_RESERVATION_SUCCESS]: (state: ReservationsState, payload: { reservationId: string }) => {
    return {
      ...state,
      loading: false,
    };
  },
  [actions.DELETE_EQUIPMENT_RESERVATION_FAIL]: (state: ReservationsState) => ({
    ...state,
    loading: false,
  }),
};

export const reservationsReducer = (state: ReservationsState = initialState, action: Action) =>
  isFunction(reducerMap[action.type]) ? reducerMap[action.type](state, action.payload) : state;
