import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromState from '@app/state';
import { get } from 'lodash-es';

import { User } from '@app/state/interfaces';
import { PermissionsEnum, UserRoles } from '@ppgt/web/shared/domain';

interface RoleGuardData {
  requiredPermission: PermissionsEnum;
  // Deprecated in favor of requiredPermission
  withRoles?: UserRoles[];
  redirectionsMap?: { [role in keyof UserRoles]: string };
  defaultRedirect?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private store: Store<fromState.AppState>) {}

  mapLink(link: string, mapper: { [key: string]: string }) {
    return link
      .split('/')
      .map((item) => mapper[item] || item)
      .join('/');
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { requiredPermission, redirectionsMap, defaultRedirect } = route.data as RoleGuardData;

    /*
      `withRoles`-string[] changed in favor of `requiredPermission`-string
      Previously if `withRoles` didn't exist we didn't allow to enter anyone
      Right now if route doesn't contain 'data.requiredPermission' property we're allowing everyone to enter
      (no specific permission is required)
    */
    if (!requiredPermission) {
      return of(true);
    }

    return this.store.pipe(
      select(fromState.getUserData),
      map((user: User) => {
        const roleType = get(user, 'role.type');
        const grantedPermissions: PermissionsEnum[] = get(user, 'role.permits');
        const hasAccess = grantedPermissions && grantedPermissions.includes(requiredPermission);
        const subcontractorID = user.subcontractor && user.subcontractor.id;
        const supplierId = user.supplier && user.supplier.id;
        const mapper = {
          SUBCONTRACTOR_ID: subcontractorID,
          SUPPLIER_ID: supplierId,
        };

        if (!hasAccess) {
          const redirectRoute =
            redirectionsMap && roleType && redirectionsMap[roleType]
              ? this.mapLink(redirectionsMap[roleType], mapper)
              : defaultRedirect || '/dashboard';

          this.router.navigate([redirectRoute]);
        }

        return hasAccess;
      }),
      take(1)
    );
  }
}
