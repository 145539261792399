import { ActionReducer } from '@ngrx/store';

import * as effects from './dashboard.effect';
import * as reducers from './dashboard.reducer';

import {
  Dashboard,
  DashboardCanteen,
  DashboardTV,
  DashboardPreview,
  EODashboardDelivery,
} from '@app/state/interfaces';

export const dashboardsEffects: any[] = [effects.DashboardsEffects];

export interface DashboardState {
  dashboard?: Dashboard;
  dashboardTV: DashboardTV;
  canteenDashboard?: DashboardCanteen[];
  preview?: DashboardPreview;
  loading: boolean;
  loaded: boolean;
}

export const dashboardReducer: ActionReducer<DashboardState> = reducers.dashboardsReducer;

export * from './dashboard.action';
export * from './dashboard.effect';
export * from './dashboard.reducer';
export * from './dashboard.selector';
