import { Pipe, PipeTransform } from '@angular/core';
import { Unit, MaterialCategory } from '@app/state/interfaces';

@Pipe({ name: 'filterUnitsByMaterial' })
export class FilterUnitsByMaterialPipe implements PipeTransform {
  transform(units: Unit[], materialCategory?: MaterialCategory): Unit[] {
    if (!units) {
      return;
    }

    return materialCategory && materialCategory.definedUnit
      ? [units.find(unit => unit.name === materialCategory.definedUnit)]
      : units;
  }
}
