import { isFunction } from 'lodash-es';

import * as actions from './equipment.action';
import { EquipmentListState } from './';
import {
  Equipment,
  Action,
  EquipmentTimes,
  EquipmentType,
  UnloadingUtility,
  EquipmentModel,
  EquipmentBrand,
  EquipmentDeliveriesDetails,
} from '@app/state/interfaces';

const initialState: EquipmentListState = {
  equipments: [],
  selectedEquipment: null,
  equipmentTypes: [],
  unloadingUtilities: [],
  equipmentDeliveriesDetails: null,
  brands: [],
  models: [],
  totalObjectsCount: null,
  loaded: false,
  loading: false,
};

const reducerMap = {
  [actions.GET_EQUIPMENT]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_EQUIPMENT_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_SUCCESS]: (state: EquipmentListState, res: any) => ({
    ...state,
    equipments: res.equipment,
    totalObjectsCount: res.meta.count,
    loaded: true,
    loading: false,
  }),
  [actions.GET_SELECTED_EQUIPMENT]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SELECTED_EQUIPMENT_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SELECTED_EQUIPMENT_SUCCESS]: (state: EquipmentListState, selectedEquipment: any) => ({
    ...state,
    selectedEquipment,
    loaded: true,
    loading: false,
  }),
  [actions.CREATE_EQUIPMENT]: (state: EquipmentListState) => ({
    ...state,
    selectedEquipment: null,
    loading: true,
  }),
  [actions.CREATE_EQUIPMENT_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_EQUIPMENT_SUCCESS]: (state: EquipmentListState, selectedEquipment: Equipment) => ({
    ...state,
    equipments: [selectedEquipment, ...state.equipments],
    selectedEquipment,
    loading: false,
  }),
  [actions.UPDATE_EQUIPMENT]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_EQUIPMENT_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_EQUIPMENT_SUCCESS]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.REMOVE_EQUIPMENT]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.REMOVE_EQUIPMENT_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.REMOVE_EQUIPMENT_SUCCESS]: (state: EquipmentListState, removed: Equipment) => ({
    ...state,
    equipments: state.equipments.filter((item) => item.id !== removed.id),
    loading: false,
  }),
  [actions.CREATE_EQUIPMENT_TIME]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_EQUIPMENT_TIME_SUCCESS]: (state: EquipmentListState, newEquipmentTimes: EquipmentTimes[]) => {
    const updatedEquipmentTimes = [...state.selectedEquipment.equipmentTimes, ...newEquipmentTimes];

    const updatedEquipments = state.equipments.map((equipment) => {
      if (equipment.id === newEquipmentTimes[0].equipmentId) {
        return { ...equipment, equipmentTimes: updatedEquipmentTimes };
      }
      return equipment;
    });

    const updatedSelectedEquipment = { ...state.selectedEquipment, equipmentTimes: updatedEquipmentTimes };

    return {
      ...state,
      equipments: updatedEquipments,
      selectedEquipment: updatedSelectedEquipment,
      loading: false,
    };
  },
  [actions.CREATE_EQUIPMENT_TIME_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_EQUIPMENT_TIME]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_EQUIPMENT_TIME_SUCCESS]: (state: EquipmentListState, updatedEquipmentTime: EquipmentTimes) => {
    const updatedEquipmentTimes = state.selectedEquipment.equipmentTimes.map((eqTime) =>
      eqTime.id === updatedEquipmentTime.id ? updatedEquipmentTime : eqTime,
    );

    const updatedEquipments = state.equipments.map((equipment) => {
      if (equipment.id === updatedEquipmentTime.equipmentId) {
        return { ...equipment, equipmentTimes: updatedEquipmentTimes };
      }
      return equipment;
    });

    const updatedSelectedEquipment = { ...state.selectedEquipment, equipmentTimes: updatedEquipmentTimes };

    return {
      ...state,
      equipments: updatedEquipments,
      selectedEquipment: updatedSelectedEquipment,
      loading: false,
    };
  },
  [actions.UPDATE_EQUIPMENT_TIME_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.DELETE_EQUIPMENT_TIME]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.DELETE_EQUIPMENT_TIME_SUCCESS]: (state: EquipmentListState, removedEquipmentTime: EquipmentTimes) => {
    const updatedEquipmentTimes = state.selectedEquipment.equipmentTimes.filter(
      (eqTime) => eqTime.id !== removedEquipmentTime.id,
    );

    const updatedEquipments = state.equipments.map((equipment) => {
      if (equipment.id === removedEquipmentTime.equipmentId) {
        return { ...equipment, equipmentTimes: updatedEquipmentTimes };
      }
      return equipment;
    });

    const updatedSelectedEquipment = { ...state.selectedEquipment, equipmentTimes: updatedEquipmentTimes };

    return {
      ...state,
      equipments: updatedEquipments,
      selectedEquipment: updatedSelectedEquipment,
      loading: false,
    };
  },
  [actions.DELETE_EQUIPMENT_TIME_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_TYPES]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_EQUIPMENT_TYPES_SUCCESS]: (state: EquipmentListState, payload: { types: EquipmentType[] }) => ({
    ...state,
    loading: false,
    equipmentTypes: payload.types,
  }),
  [actions.GET_EQUIPMENT_TYPES_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_UNLOADING_UTILITIES]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_UNLOADING_UTILITIES_SUCCESS]: (
    state: EquipmentListState,
    payload: { utilities: UnloadingUtility[] },
  ) => ({
    ...state,
    unloadingUtilities: payload.utilities,
    loading: false,
  }),
  [actions.GET_UNLOADING_UTILITIES_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_BRANDS]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_EQUIPMENT_BRANDS_SUCCESS]: (state: EquipmentListState, payload: { brands: EquipmentBrand[] }) => ({
    ...state,
    brands: payload.brands,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_BRANDS_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_MODELS]: (state: EquipmentListState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_EQUIPMENT_MODELS_SUCCESS]: (state: EquipmentListState, payload: { models: EquipmentModel[] }) => ({
    ...state,
    models: payload.models,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_MODELS_FAIL]: (state: EquipmentListState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_EQUIPMENT_DELIVERIES_DETAILS]: (state: EquipmentListState) => ({
    ...state,
  }),
  [actions.GET_EQUIPMENT_DELIVERIES_DETAILS_FAIL]: (state: EquipmentListState) => ({
    ...state,
  }),
  [actions.GET_EQUIPMENT_DELIVERIES_DETAILS_SUCCESS]: (
    state: EquipmentListState,
    equipmentDeliveriesDetails: EquipmentDeliveriesDetails,
  ) => ({
    ...state,
    equipmentDeliveriesDetails,
  }),
};

export function equipmentReducer(state: EquipmentListState = initialState, action: Action) {
  return isFunction(reducerMap[action.type]) ? reducerMap[action.type](state, action.payload) : state;
}
