import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { tap, filter, take, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromAppState from '@app/state';
import { EcologySettings } from '@app/state/interfaces';

@Injectable({
  providedIn: 'root',
})
export class EcologyDataGuard implements CanActivate {
  public destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromAppState.AppState>,
  ) { }

  canActivate(): Observable<boolean> {
    return this.store.select(fromAppState.getAllEcologySettings).pipe(
      tap(
        (ecologyData: EcologySettings) => (!ecologyData && this.store.dispatch(new fromAppState.GetEcologySettings()))
      ),
      map(ecologyData => !!ecologyData),
      filter(ecologyData => ecologyData),
      take(1),
    );
  }
}
