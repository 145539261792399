import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './tasks.action';

@Injectable()
export class TasksImportSourceEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.GetTasksImportSource>
  ) {}

  
  getTasksImportSource$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_TASKS_IMPORT_SOURCE),
      map((action: actions.GetTasksImportSource) => action),
      switchMap(objects =>
        this.http
          .getTasksImportSource()
          .pipe(
            map(res => new actions.GetTasksImportSourceSuccess(res)),
            catchError(error => [new actions.GetTasksImportSourceFail(error)])
          )
      )
    ));

  setTasksImportSource$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.SET_TASKS_IMPORT_SOURCE),
      map((action: actions.SetTasksImportSource) => action),
      switchMap(source => 
        this.http
          .setTasksImportSource(source.payload)
          .pipe(
            map(res => new actions.SetTasksImportSourceSuccess(res)),
            catchError(error => [new actions.SetTasksImportSourceFail(error)])
          )
      )
    ));
}
