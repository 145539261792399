import { Action } from '@ngrx/store';

import {
  GetUsersRes,
  CreateUserReq,
  UpdateUserReq,
  NewPasswordReq,
  QueryParamObject,
  UpdateAvatarFile,
} from '@app/core/http/interfaces';
import { User } from '@app/state/interfaces';

// user data

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';

export class GetUserData implements Action {
  readonly type = GET_USER_DATA;
}

export class GetUserDataFail implements Action {
  readonly type = GET_USER_DATA_FAIL;
}

export class GetUserDataSuccess implements Action {
  readonly type = GET_USER_DATA_SUCCESS;

  constructor(public payload: User) { }
}

// users

export const GET_USERS = 'GET_USERS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export class GetUsers implements Action {
  readonly type = GET_USERS;

  constructor(public payload: QueryParamObject[] = null) { }
}

export class GetUsersFail implements Action {
  readonly type = GET_USERS_FAIL;
}

export class GetUsersSuccess implements Action {
  readonly type = GET_USERS_SUCCESS;

  constructor(public payload: GetUsersRes) { }
}

export const GET_USER = 'GET_USER';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export class GetUser implements Action {
  readonly type = GET_USER;

  constructor(public payload: { id: string }) { }
}

export class GetUserFail implements Action {
  readonly type = GET_USER_FAIL;

  constructor(public payload: Error) { }
}

export class GetUserSuccess implements Action {
  readonly type = GET_USER_SUCCESS;

  constructor(public payload: User) { }
}

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export class CreateUser implements Action {
  readonly type = CREATE_USER;

  constructor(public payload: CreateUserReq) { }
}

export class CreateUserFail implements Action {
  readonly type = CREATE_USER_FAIL;

  constructor(public payload: Error) { }
}

export class CreateUserSuccess implements Action {
  readonly type = CREATE_USER_SUCCESS;

  constructor(public payload: User) { }
}

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(public user: UpdateUserReq) { }
}

export class UpdateUserFail implements Action {
  readonly type = UPDATE_USER_FAIL;

  constructor(public payload: Error) { }
}

export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESS;

  constructor(public payload: User) { }
}

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export class UpdatePassword implements Action {
  readonly type = UPDATE_PASSWORD;
  constructor(public payload: { userId: string; email: string }) { }
}

export class UpdatePasswordFail implements Action {
  readonly type = UPDATE_PASSWORD_FAIL;
  constructor(public payload: Error) { }
}

export class UpdatePasswordSuccess implements Action {
  readonly type = UPDATE_PASSWORD_SUCCESS;
  constructor(public payload: any) { }
}

export const NEW_PASSWORD = 'NEW_PASSWORD';
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';

export class NewPassword implements Action {
  readonly type = NEW_PASSWORD;
  constructor(public payload: NewPasswordReq) { }
}

export class NewPasswordFail implements Action {
  readonly type = NEW_PASSWORD_FAIL;

  constructor(public payload: Error) { }
}

export class NewPasswordSuccess implements Action {
  readonly type = NEW_PASSWORD_SUCCESS;

  constructor(public payload: any) { }
}

export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_FAIL = 'GET_LOGS_FAIL';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';

export class GetLogs implements Action {
  readonly type = GET_LOGS;
  constructor() { }
}

export class GetLogsFail implements Action {
  readonly type = GET_LOGS_FAIL;
  constructor(public payload?: Error) { }
}

export class GetLogsSuccess implements Action {
  readonly type = GET_LOGS_SUCCESS;
  constructor() { }
}

export const GET_USER_PIN = 'GET_USER_PIN';
export const GET_USER_PIN_FAIL = 'GET_USER_PIN_FAIL';
export const GET_USER_PIN_SUCCESS = 'GET_USER_PIN_SUCCESS';

export class GetUserPin implements Action {
  readonly type = GET_USER_PIN;
  constructor(public payload: string) { }
}

export class GetUserPinFail implements Action {
  readonly type = GET_USER_PIN_FAIL;
  constructor(public payload?: Error) { }
}

export class GetUserPinSuccess implements Action {
  readonly type = GET_USER_PIN_SUCCESS;
  constructor(public payload: string) { }
}

export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_AVATAR_FAIL = 'UPDATE_AVATAR_FAIL';
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const STORE_AVATAR_BASE64 = 'STORE_AVATAR_BASE64';
export const STORE_AVATAR_BASE64_SUCCESS = 'STORE_AVATAR_BASE64_SUCCESS';

export class UpdateAvatar implements Action {
  readonly type = UPDATE_AVATAR;

  constructor(public payload: UpdateAvatarFile) {}
}

export class UpdateAvatarFail implements Action {
  readonly type = UPDATE_AVATAR_FAIL;
}

export class UpdateAvatarSuccess implements Action {
  readonly type = UPDATE_AVATAR_SUCCESS;

  constructor(public payload: string) {}
}

export class StoreAvatarBase64 implements Action {
  readonly type = STORE_AVATAR_BASE64;

  constructor(public payload: string) {}
}

export class StoreAvatarBase64Success implements Action {
  readonly type = STORE_AVATAR_BASE64_SUCCESS;

  constructor(public payload: string) {}
}


export type UsersAction =
  | GetUserData
  | GetUserDataFail
  | GetUserDataSuccess
  | GetUsers
  | GetUsersFail
  | GetUsersSuccess
  | GetUser
  | GetUserFail
  | GetUserSuccess
  | CreateUser
  | CreateUserFail
  | CreateUserSuccess
  | UpdateUser
  | UpdateUserFail
  | UpdateUserSuccess
  | UpdatePassword
  | UpdatePasswordFail
  | UpdatePasswordSuccess
  | NewPassword
  | NewPasswordFail
  | NewPasswordSuccess
  | GetLogs
  | GetLogsFail
  | GetLogsSuccess
  | GetUserPin
  | GetUserPinFail
  | GetUserPinSuccess
  | UpdateAvatar
  | UpdateAvatarFail
  | UpdateAvatarSuccess
  | StoreAvatarBase64
  | StoreAvatarBase64Success
  ;
