import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/http/http.service';

import * as actions from './dashboard.action';
import { AlertService } from '@ppgt/web/shared/core';

@Injectable()
export class DashboardsEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.DashboardAction>,
    private alertService: AlertService
  ) {}

  
  getDashboard$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_DASHBOARD),
      switchMap(() =>
        this.http.getDashboard().pipe(
          map(res => new actions.GetDashboardSuccess(res)),
          catchError(error => [new actions.GetDashboardFail()])
        )
      )
    ));

  
  getDashboardPreview$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_DASHBOARD_PREVIEW),
      switchMap(() =>
        this.http.getDashboardPreview().pipe(
          map(res => new actions.GetDashboardPreviewSuccess(res)),
          catchError(error => [new actions.GetDashboardPreviewFail()])
        )
      )
    ));

  
  getCanteenDashboard$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_CANTEEN_DASHBOARD),
      switchMap(() =>
        this.http.getCanteenDashboard().pipe(
          map(res => new actions.GetCanteenDashboardSuccess(res)),
          catchError(error => [new actions.GetCanteenDashboardFail()])
        )
      )
    ));

  
  getTVDashboard$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_TV_DASHBOARD),
      switchMap(() =>
        this.http.getTVDashboard().pipe(
          map(res => new actions.GetTVDashboardSuccess(res)),
          catchError(error => [new actions.GetTVDashboardFail()])
        )
      )
    ));

  
  updateTVDashboard$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_TV_DASHBOARD),
      map((action: actions.UpdateTVDashboard) => action.payload),
      switchMap(data =>
        this.http.updateTVDashboard(data).pipe(
          map(res => {
            this.alertService.showInfo('alert.updated_l');
            return new actions.UpdateTVDashboardSuccess(res);
          }),
          catchError(error => [new actions.UpdateTVDashboardFail()])
        )
      )
    ));
}
