import { Action } from '@ngrx/store';
import { QueryParamObject, SendReportReq } from '@app/core/http/interfaces';
import { ReportQueries, Report } from '@app/state/interfaces';

// get report
export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';

export class GetReport implements Action {
  readonly type = GET_REPORT;

  constructor(
    public payload: {
      reportQueries?: ReportQueries;
      queries: QueryParamObject[];
    },
  ) {}
}

export class GetReportFail implements Action {
  readonly type = GET_REPORT_FAIL;
}

export class GetReportSuccess implements Action {
  readonly type = GET_REPORT_SUCCESS;

  constructor(public payload: Report) {}
}

// send report
export const SEND_REPORT = 'SEND_REPORT';
export const SEND_REPORT_FAIL = 'SEND_REPORT_FAIL';
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS';

export class SendReport implements Action {
  readonly type = SEND_REPORT;

  constructor(public payload: SendReportReq) {}
}

export class SendReportFail implements Action {
  readonly type = SEND_REPORT_FAIL;
}

export class SendReportSuccess implements Action {
  readonly type = SEND_REPORT_SUCCESS;

  constructor(public payload: any) {}
}

export type ReportsAction =
  | GetReport
  | GetReportFail
  | GetReportSuccess
  | SendReport
  | SendReportFail
  | SendReportSuccess;
