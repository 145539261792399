import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetSortingComponent } from '@app/shared/reset-sorting/reset-sorting.component';
import { SvgModule } from '@app/shared/svg-icon/svg.module';

@NgModule({
  declarations: [ResetSortingComponent],
  imports: [
    CommonModule,
    SvgModule
  ],
  exports: [
    ResetSortingComponent,
  ]
})
export class ResetSortingModule { }
