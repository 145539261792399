import { Injectable } from '@angular/core';

class StorageWrapper {
  private storage: Storage;

  private constructor(storage: Storage) {
    this.storage = storage;
  }

  static of(storage: Storage) {
    return new StorageWrapper(storage);
  }

  public get(key: string) {
    const value = this.storage.getItem(key) as string;
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  public set(key: string, value: any) {
    try {
      const val = typeof value === 'object' ? JSON.stringify(value) : value;
      this.storage.setItem(key, val);
      return true;
    } catch (e) {
      return false;
    }
  }

  public remove(key: string) {
    try {
      this.storage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  public clear() {
    this.storage.clear();
    return true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WindowStorage {
  getStorage(type: string) {
    if (type === 'local') {
      return StorageWrapper.of(window.localStorage);
    }

    return StorageWrapper.of(window.sessionStorage);
  }
}
