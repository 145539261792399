import { Action } from '@ngrx/store';

import {
  QueryParamObject,
  GetAllSuppliersRes,
  GetSupplierRes,
  UpdateSupplierReq,
  GetSupplierVehiclesRes,
  CreateSupplierVehiclesReq,
  GetSuppliersProductsRes,
} from '@app/core/http/interfaces';
import {
  User,
  LocationType,
  SubcontractorsEmployee,
  VehicleObj,
  Subcontractor,
} from '../interfaces';
import {
  SuppliersEmployeeRole,
  SuppliersEmployee,
  Supplier,
  SupplierLocation,
  AssignSupplierEmployeeBody,
  WorkingDriver,
  NoDriverAppResponse,
} from '@interfaces';

// load all suppliers
export const GET_ALL_SUPPLIERS = 'GET_ALL_SUPPLIERS';
export const GET_ALL_SUPPLIERS_FAIL = 'GET_ALL_SUPPLIERS_FAIL';
export const GET_ALL_SUPPLIERS_SUCCESS = 'GET_ALL_SUPPLIERS_SUCCESS';

export class GetAllSuppliers implements Action {
  readonly type = GET_ALL_SUPPLIERS;

  constructor(public payload: QueryParamObject[] = null) { }
}

export class GetAllSuppliersFail implements Action {
  readonly type = GET_ALL_SUPPLIERS_FAIL;

  constructor(payload: any) { }
}

export class GetAllSuppliersSuccess implements Action {
  readonly type = GET_ALL_SUPPLIERS_SUCCESS;

  constructor(public payload: GetAllSuppliersRes['data']) { }
}

// create supplier
export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
export const CREATE_SUPPLIER_FAIL = 'CREATE_SUPPLIER_FAIL';
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS';

export class CreateSupplier implements Action {
  readonly type = CREATE_SUPPLIER;

  constructor(public payload: {
    location: SupplierLocation;
    supplier: Supplier;
    supplierEmployee: SuppliersEmployee;
    user: User;
  }) { }
}

export class CreateSupplierFail implements Action {
  readonly type = CREATE_SUPPLIER_FAIL;
}

export class CreateSupplierSuccess implements Action {
  readonly type = CREATE_SUPPLIER_SUCCESS;
}

// load selected supplier
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const GET_SUPPLIER_FAIL = 'GET_SUPPLIER_FAIL';
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';

export class GetSupplier implements Action {
  readonly type = GET_SUPPLIER;

  constructor(public payload: { id: string }) { }
}

export class GetSupplierFail implements Action {
  readonly type = GET_SUPPLIER_FAIL;

  constructor(payload: any) { }
}

export class GetSupplierSuccess implements Action {
  readonly type = GET_SUPPLIER_SUCCESS;

  constructor(public payload: GetSupplierRes['data']) { }
}

// update supplier
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const UPDATE_SUPPLIER_FAIL = 'UPDATE_SUPPLIER_FAIL';
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';

export class UpdateSupplier implements Action {
  readonly type = UPDATE_SUPPLIER;

  constructor(public payload: { updatedSupplier: UpdateSupplierReq; supplierId: string }) { }
}

export class UpdateSupplierFail implements Action {
  readonly type = UPDATE_SUPPLIER_FAIL;

  constructor(public error: any) { }
}

export class UpdateSupplierSuccess implements Action {
  readonly type = UPDATE_SUPPLIER_SUCCESS;

  constructor(public payload: Supplier) { }
}

// get supplier employees
export const GET_SUPPLIERS_EMPLOYEES = 'GET_SUPPLIER_EMPLOYEES';
export const GET_SUPPLIERS_EMPLOYEES_SUCCESS = 'GET_SUPPLIER_EMPLOYEES_SUCCESS';
export const GET_SUPPLIERS_EMPLOYEES_FAIL = 'GET_SUPPLIER_EMPLOYEES_FAIL';

export class GetSuppliersEmployees implements Action {
  readonly type = GET_SUPPLIERS_EMPLOYEES;

  constructor(public payload: { supplierId: string }) { }
}

export class GetSuppliersEmployeesSuccess implements Action {
  readonly type = GET_SUPPLIERS_EMPLOYEES_SUCCESS;

  constructor(public payload: SuppliersEmployee[]) { }
}

export class GetSuppliersEmployeesFail implements Action {
  readonly type = GET_SUPPLIERS_EMPLOYEES_FAIL;
}

// create supplier employee
export const CREATE_SUPPLIERS_EMPLOYEE = 'CREATE_SUPPLIERS_EMPLOYEE';
export const CREATE_SUPPLIERS_EMPLOYEE_SUCCESS = 'CREATE_SUPPLIERS_EMPLOYEE_SUCCESS';
export const CREATE_SUPPLIERS_EMPLOYEE_FAIL = 'CREATE_SUPPLIERS_EMPLOYEE_FAIL';

export class CreateSuppliersEmployee implements Action {
  readonly type = CREATE_SUPPLIERS_EMPLOYEE;

  constructor(public payload: {
    employee: SuppliersEmployee;
    supplierId: string;
    redirectAfter?: boolean;
  }) { }
}

export class CreateSuppliersEmployeeSuccess implements Action {
  readonly type = CREATE_SUPPLIERS_EMPLOYEE_SUCCESS;
}

export class CreateSuppliersEmployeeFail implements Action {
  readonly type = CREATE_SUPPLIERS_EMPLOYEE_FAIL;
}

// update supplier employee
export const UPDATE_SUPPLIER_EMPLOYEE = 'UPDATE_SUPPLIER_EMPLOYEE';
export const UPDATE_SUPPLIER_EMPLOYEE_SUCCESS = 'UPDATE_SUPPLIER_EMPLOYEE_SUCCESS';
export const UPDATE_SUPPLIER_EMPLOYEE_FAIL = 'UPDATE_SUPPLIER_EMPLOYEE_FAIL';

export class UpdateSuppliersEmployee implements Action {
  readonly type = UPDATE_SUPPLIER_EMPLOYEE;

  constructor(public payload: {
    employee: SuppliersEmployee;
    supplierId: string;
    employeeId: string;
  }) { }
}

export class UpdateSuppliersEmployeeSuccess implements Action {
  readonly type = UPDATE_SUPPLIER_EMPLOYEE_SUCCESS;

  constructor(public payload: User) { }
}

export class UpdateSuppliersEmployeeFail implements Action {
  readonly type = UPDATE_SUPPLIER_EMPLOYEE_FAIL;
}

// assign employee to supplier
export const ASSIGN_SUPPLIERS_EMPLOYEE = 'ASSIGN_SUPPLIERS_EMPLOYEE';
export const ASSIGN_SUPPLIERS_EMPLOYEE_SUCCESS = 'ASSIGN_SUPPLIERS_EMPLOYEE_SUCCESS';
export const ASSIGN_SUPPLIERS_EMPLOYEE_FAIL = 'ASSIGN_SUPPLIERS_EMPLOYEE_FAIL';

export class AssignSuppliersEmployee implements Action {
  readonly type = ASSIGN_SUPPLIERS_EMPLOYEE;

  constructor(public payload: {
    supplierId: string;
    employeeId: string;
    body: AssignSupplierEmployeeBody;
    redirectAfter?: boolean;
  }) { }
}

export class AssignSuppliersEmployeeSuccess implements Action {
  readonly type = ASSIGN_SUPPLIERS_EMPLOYEE_SUCCESS;

  constructor(public payload: SuppliersEmployee) { }
}

export class AssignSuppliersEmployeeFail implements Action {
  readonly type = ASSIGN_SUPPLIERS_EMPLOYEE_FAIL;
}

// get supplier employee roles
export const GET_SUPPLIERS_EMPLOYEE_ROLES = 'GET_SUPPLIERS_EMPLOYEE_ROLES';
export const GET_SUPPLIERS_EMPLOYEE_ROLES_SUCCESS = 'GET_SUPPLIERS_EMPLOYEE_ROLES_SUCCESS';
export const GET_SUPPLIERS_EMPLOYEE_ROLES_FAIL = 'GET_SUPPLIERS_EMPLOYEE_ROLES_FAIL';

export class GetSuppliersEmployeeRoles implements Action {
  readonly type = GET_SUPPLIERS_EMPLOYEE_ROLES;
}

export class GetSuppliersEmployeeRolesSuccess implements Action {
  readonly type = GET_SUPPLIERS_EMPLOYEE_ROLES_SUCCESS;

  constructor(public payload: SuppliersEmployeeRole[]) { }
}

export class GetSuppliersEmployeeRolesFail implements Action {
  readonly type = GET_SUPPLIERS_EMPLOYEE_ROLES_FAIL;
}

// get supplier locations
export const GET_SUPPLIER_LOCATIONS = 'GET_SUPPLIER_LOCATIONS';
export const GET_SUPPLIER_LOCATIONS_FAIL = 'GET_SUPPLIER_LOCATIONS_FAIL';
export const GET_SUPPLIER_LOCATIONS_SUCCESS = 'GET_SUPPLIER_LOCATIONS_SUCCESS';

export class GetSupplierLocations implements Action {
  readonly type = GET_SUPPLIER_LOCATIONS;

  constructor(public payload: { supplierId: string }) { }
}

export class GetSupplierLocationsFail implements Action {
  readonly type = GET_SUPPLIER_LOCATIONS_FAIL;

  constructor(payload: any) { }
}

export class GetSupplierLocationsSuccess implements Action {
  readonly type = GET_SUPPLIER_LOCATIONS_SUCCESS;

  constructor(public payload: SupplierLocation[]) { }
}

// create supplier locations
export const CREATE_SUPPLIER_LOCATION = 'CREATE_SUPPLIER_LOCATION';
export const CREATE_SUPPLIER_LOCATION_FAIL = 'CREATE_SUPPLIER_LOCATION_FAIL';
export const CREATE_SUPPLIER_LOCATION_SUCCESS = 'CREATE_SUPPLIER_LOCATION_SUCCESS';

export class CreateSupplierLocation implements Action {
  readonly type = CREATE_SUPPLIER_LOCATION;
  constructor(public payload: {
    supplierId: string;
    location: SupplierLocation;
    employee?: SubcontractorsEmployee;
  }) { }
}

export class CreateSupplierLocationFail implements Action {
  readonly type = CREATE_SUPPLIER_LOCATION_FAIL;

  constructor(payload: any) { }
}

export class CreateSupplierLocationSuccess implements Action {
  readonly type = CREATE_SUPPLIER_LOCATION_SUCCESS;

  constructor(public payload: SupplierLocation) { }
}

// update supplier locations
export const UPDATE_SUPPLIER_LOCATION = 'UPDATE_SUPPLIER_LOCATION';
export const UPDATE_SUPPLIER_LOCATION_FAIL = 'UPDATE_SUPPLIER_LOCATION_FAIL';
export const UPDATE_SUPPLIER_LOCATION_SUCCESS = 'UPDATE_SUPPLIER_LOCATION_SUCCESS';

export class UpdateSupplierLocation implements Action {
  readonly type = UPDATE_SUPPLIER_LOCATION;
  constructor(public payload: { supplierId: string; locationId: string; location: SupplierLocation }) { }
}

export class UpdateSupplierLocationFail implements Action {
  readonly type = UPDATE_SUPPLIER_LOCATION_FAIL;

  constructor(payload: any) { }
}

export class UpdateSupplierLocationSuccess implements Action {
  readonly type = UPDATE_SUPPLIER_LOCATION_SUCCESS;
  constructor(public payload: SupplierLocation) { }
}

// get supplier location types
export const GET_LOCATION_TYPES = 'GET_LOCATION_TYPES';
export const GET_LOCATION_TYPES_SUCCESS = 'GET_LOCATION_TYPES_SUCCESS';
export const GET_LOCATION_TYPES_FAIL = 'GET_LOCATION_TYPES_FAIL';

export class GetLocationTypes implements Action {
  readonly type = GET_LOCATION_TYPES;
}

export class GetLocationTypesSuccess implements Action {
  readonly type = GET_LOCATION_TYPES_SUCCESS;

  constructor(public payload: LocationType[]) { }
}

export class GetLocationTypesFail implements Action {
  readonly type = GET_LOCATION_TYPES_FAIL;

  constructor(payload: any) { }
}

// set default supplier location
export const SET_DEFAULT_LOCATION = 'SET_DEFAULT_LOCATION';
export const SET_DEFAULT_LOCATION_SUCCESS = 'SET_DEFAULT_LOCATION_SUCCESS';
export const SET_DEFAULT_LOCATION_FAIL = 'SET_DEFAULT_LOCATION_FAIL';

export class SetDefaultLocation implements Action {
  readonly type = SET_DEFAULT_LOCATION;

  constructor(public payload: { supplierId: string; locationId: string }) { }
}

export class SetDefaultLocationSuccess implements Action {
  readonly type = SET_DEFAULT_LOCATION_SUCCESS;

  constructor(public payload: SupplierLocation) { }
}

export class SetDefaultLocationFail implements Action {
  readonly type = SET_DEFAULT_LOCATION_FAIL;

  constructor(payload: any) { }
}

export const GET_SUPPLIERS_VEHICLES_COUNT = 'GET_SUPPLIERS_VEHICLES_COUNT';
export const GET_SUPPLIERS_VEHICLES_COUNT_SUCCESS = 'GET_SUPPLIERS_VEHICLES_COUNT_SUCCESS';
export const GET_SUPPLIERS_VEHICLES_COUNT_FAIL = 'GET_SUPPLIERS_VEHICLES_COUNT_FAIL';

export class GetSuppliersVehiclesCount implements Action {
  readonly type = GET_SUPPLIERS_VEHICLES_COUNT;

  constructor(public payload: { supplierId: string; materialCategoryId: string }) { }
}

export class GetSuppliersVehiclesCountSuccess implements Action {
  readonly type = GET_SUPPLIERS_VEHICLES_COUNT_SUCCESS;

  constructor(public payload: number) { }
}

export class GetSuppliersVehiclesCountFail implements Action {
  readonly type = GET_SUPPLIERS_VEHICLES_COUNT_FAIL;
}

// get supplier's vehicles
export const GET_SUPPLIER_VEHICLES = 'GET_SUPPLIER_VEHICLES';
export const GET_SUPPLIER_VEHICLES_FAIL = 'GET_SUPPLIER_VEHICLES_FAIL';
export const GET_SUPPLIER_VEHICLES_SUCCESS = 'GET_SUPPLIER_VEHICLES_SUCCESS';

export class GetSupplierVehicles implements Action {
  readonly type = GET_SUPPLIER_VEHICLES;

  constructor(public payload: { id: string; queries?: QueryParamObject[] }) { }
}

export class GetSupplierVehiclesFail implements Action {
  readonly type = GET_SUPPLIER_VEHICLES_FAIL;

  constructor(payload: any) { }
}

export class GetSupplierVehiclesSuccess implements Action {
  readonly type = GET_SUPPLIER_VEHICLES_SUCCESS;

  constructor(public payload: GetSupplierVehiclesRes) { }
}

// create supplier's vehicle
export const CREATE_SUPPLIER_VEHICLES = 'CREATE_SUPPLIER_VEHICLES';
export const CREATE_SUPPLIER_VEHICLES_FAIL = 'CREATE_SUPPLIER_VEHICLES_FAIL';
export const CREATE_SUPPLIER_VEHICLES_SUCCESS = 'CREATE_SUPPLIER_VEHICLES_SUCCESS';

export class CreateSupplierVehicles implements Action {
  readonly type = CREATE_SUPPLIER_VEHICLES;

  constructor(public payload: CreateSupplierVehiclesReq) { }
}

export class CreateSupplierVehiclesFail implements Action {
  readonly type = CREATE_SUPPLIER_VEHICLES_FAIL;

  constructor(payload: any) { }
}

export class CreateSupplierVehiclesSuccess implements Action {
  readonly type = CREATE_SUPPLIER_VEHICLES_SUCCESS;

  constructor(public payload: VehicleObj) { }
}

export const GET_RUN_AVAILABLE_DRIVERS = 'GET_RUN_AVAILABLE_DRIVERS';
export const GET_RUN_AVAILABLE_DRIVERS_FAIL = 'GET_RUN_AVAILABLE_DRIVERS_FAIL';
export const GET_RUN_AVAILABLE_DRIVERS_SUCCESS = 'GET_RUN_AVAILABLE_DRIVERS_SUCCESS';

export class GetRunAvailableDrivers implements Action {
  readonly type = GET_RUN_AVAILABLE_DRIVERS;

  constructor(public payload: { materialCategoryId: string }) {}
}

export class GetRunAvailableDriversSuccess implements Action {
  readonly type = GET_RUN_AVAILABLE_DRIVERS_SUCCESS;
  constructor(public payload: { availableDrivers: WorkingDriver[] | NoDriverAppResponse }) { }
}

export class GetRunAvailableDriversFail implements Action {
  readonly type = GET_RUN_AVAILABLE_DRIVERS_FAIL;
  constructor(public payload: { error: any }) { }
}

export const GET_SUPPLIERS_CLIENTS = 'GET_SUPPLIERS_CLIENTS';
export const GET_SUPPLIERS_CLIENTS_SUCCESS = 'GET_SUPPLIERS_CLIENTS_SUCCESS';
export const GET_SUPPLIERS_CLIENTS_FAIL = 'GET_SUPPLIERS_CLIENTS_FAIL';

export class GetSuppliersClients implements Action {
  readonly type = GET_SUPPLIERS_CLIENTS;

  constructor(public payload: { supplierId: string; params: QueryParamObject[] }) {}
}

export class GetSuppliersClientsSuccess implements Action {
  readonly type = GET_SUPPLIERS_CLIENTS_SUCCESS;

  constructor(public payload: { suppliersClients: Subcontractor[]; clientsCount: number } ) {}
}

export class GetSuppliersClientsFail implements Action {
  readonly type = GET_SUPPLIERS_CLIENTS_FAIL;
}

export const GET_SUPPLIERS_PRODUCTS = 'GET_SUPPLIERS_PRODUCTS';
export const GET_SUPPLIERS_PRODUCTS_SUCCESS = 'GET_SUPPLIERS_PRODUCTS_SUCCESS';
export const GET_SUPPLIERS_PRODUCTS_FAIL = 'GET_SUPPLIERS_PRODUCTS_FAIL';

export class GetSuppliersProducts implements Action {
  readonly type = GET_SUPPLIERS_PRODUCTS;

  constructor(public payload: { supplierId: string; params: QueryParamObject[] }) {}
}

export class GetSuppliersProductsSuccess implements Action {
  readonly type = GET_SUPPLIERS_PRODUCTS_SUCCESS;

  constructor(public payload: GetSuppliersProductsRes ) {}
}

export class GetSuppliersProductsFail implements Action {
  readonly type = GET_SUPPLIERS_PRODUCTS_FAIL;
}

export type SuppliersAction =
  | GetAllSuppliers
  | GetAllSuppliersFail
  | GetAllSuppliersSuccess
  | CreateSupplier
  | CreateSupplierFail
  | CreateSupplierSuccess
  | GetSupplier
  | GetSupplierFail
  | GetSupplierSuccess
  | UpdateSupplier
  | UpdateSupplierFail
  | UpdateSupplierSuccess
  | GetSuppliersEmployees
  | GetSuppliersEmployeesSuccess
  | GetSuppliersEmployeesFail
  | GetSuppliersEmployeeRoles
  | GetSuppliersEmployeeRolesSuccess
  | GetSuppliersEmployeeRolesFail
  | CreateSuppliersEmployee
  | CreateSuppliersEmployeeSuccess
  | CreateSuppliersEmployeeFail
  | UpdateSuppliersEmployee
  | UpdateSuppliersEmployeeSuccess
  | UpdateSuppliersEmployeeFail
  | AssignSuppliersEmployee
  | AssignSuppliersEmployeeSuccess
  | AssignSuppliersEmployeeFail
  | CreateSupplierLocation
  | CreateSupplierLocationFail
  | CreateSupplierLocationSuccess
  | UpdateSupplierLocation
  | UpdateSupplierLocationFail
  | UpdateSupplierLocationSuccess
  | GetSupplierLocations
  | GetSupplierLocationsFail
  | GetSupplierLocationsSuccess
  | GetLocationTypes
  | GetLocationTypesSuccess
  | GetLocationTypesFail
  | SetDefaultLocation
  | SetDefaultLocationSuccess
  | SetDefaultLocationFail
  | GetSuppliersVehiclesCount
  | GetSuppliersVehiclesCountSuccess
  | GetSuppliersVehiclesCountFail
  | CreateSupplierVehicles
  | CreateSupplierVehiclesFail
  | CreateSupplierVehiclesSuccess
  | GetSupplierVehicles
  | GetSupplierVehiclesFail
  | GetSupplierVehiclesSuccess
  | GetRunAvailableDrivers
  | GetRunAvailableDriversFail
  | GetRunAvailableDriversSuccess
  | GetSuppliersClients
  | GetSuppliersClientsSuccess
  | GetSuppliersClientsFail
  | GetSuppliersProducts
  | GetSuppliersProductsSuccess
  | GetSuppliersProductsFail;
