import { Directive, HostListener, Input } from '@angular/core';

import { Delivery } from '@app/state/interfaces';
import { DeliveryType } from '@ppgt/web/shared/domain';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({ selector: '[openDeliveryPanel]' })
export class OpenDeliveryPanelDirective {
  private delivery: Delivery;

  @Input() set openDeliveryPanel(delivery: Delivery) {
    this.delivery = delivery;
  }
  @Input() restoreMode = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  @HostListener('click')
  public togglePanel() {
    const { typeDelivery, deliveryId } = this.delivery;
    const id = typeDelivery === DeliveryType.Part ? deliveryId : this.delivery.id;
    const queryParams = { id, ...(this.restoreMode && { restoreMode: this.restoreMode }) };
    this.router.navigate([], { queryParams, relativeTo: this.route });

    return false;
  }
}
