import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PermissionsHttpService } from '@app/core/http/permissions.http.service';
import * as actions from './permissions.action';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';

@Injectable()
export class PermissionsEffects {
  constructor(
    private http: PermissionsHttpService,
    private actions$: Actions<actions.PermissionsAction>,
    private translationService: TranslateItemsService,
  ) { }

  
  updatePermission: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_PERMISSION),
      switchMap((action: actions.UpdatePermission) => {
        const { permissionToUpdate } = action.payload;

        // Only allowed fields in update
        const allowedUpdatedData = { translation: permissionToUpdate.translation };

        return this.http.updatePermission(permissionToUpdate.id, allowedUpdatedData).pipe(
          map(res => new actions.UpdatePermissionSuccess({
            updatedPermission: this.translationService.translatePermission(res.data)
          })),
          catchError(error => [new actions.UpdatePermissionFail({ error })])
        );
      })
    ));

  
  getPermissions: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_PERMISSIONS),
      switchMap(() => this.http.getPermissions().pipe(
          map(res => new actions.GetPermissionsSuccess({
            fetchedPermissions: this.translationService.translatePermissions(res.data)
          })),
          catchError(error => [new actions.GetPermissionsFail({ error })])
        ))
    ));


  
  getSections: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.GET_SECTIONS),
      switchMap(() => this.http.getSections().pipe(
          map(res => new actions.GetSectionsSuccess({
            fetchedSections: this.translationService.translateSections(res.data)
          })),
          catchError(error => [new actions.GetSectionsFail({ error })])
        ))
    ));

  
  updateSection: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.UPDATE_SECTION),
      switchMap((action: actions.UpdateSection) => {
        const { sectionToUpdate } = action.payload;

        const formattedSection = Object.keys(sectionToUpdate).reduce((newObj, langKey) => {
          const { id, nameTranslation, descriptionTranslation, permissions } = sectionToUpdate[langKey];
          newObj.id = id;
          newObj.nameTranslation[langKey] = nameTranslation;
          newObj.descriptionTranslation[langKey] = descriptionTranslation;

          newObj.permissions = permissions.map((originalPermission, index) => {
            newObj.permissions[index] = {
              id: originalPermission.id,
              translation: {},
              ...newObj.permissions[index]
            };

            newObj.permissions[index].translation[langKey] = originalPermission.translation;
            return { ...newObj.permissions[index] };
          });
          return newObj;
        }, {
          id: '',
          nameTranslation: {},
          descriptionTranslation: {},
          permissions: []
        });

        // Only allowed fields in update
        const allowedUpdatedData = {
          nameTranslation: formattedSection.nameTranslation,
          descriptionTranslation: formattedSection.descriptionTranslation,
          permissions: formattedSection.permissions
        };

        return this.http.updateSection(formattedSection.id, allowedUpdatedData).pipe(
          map(res => new actions.UpdateSectionSuccess({
            updatedSection: this.translationService.translateSection(res.data)
          })),
          catchError(error => [new actions.UpdateSectionFail({ error })])
        );
      })
    ));
}
