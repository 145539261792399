import { ActionReducer } from '@ngrx/store';

import * as effects from './construction-crews.effect';
import * as reducers from './construction-crews.reducer';

import { ConstructionCrew } from '@app/state/interfaces';

export const constructionCrewsEffects: any[] = [effects.ConstructionCrewsEffects];

export interface ConstructionCrewsState {
  constructionCrews: ConstructionCrew[];
  constructionCrew: ConstructionCrew;
  loading: boolean;
}

export const constructionCrewsReducer: ActionReducer<ConstructionCrewsState> = reducers.constructionCrewsReducer;

export * from './construction-crews.action';
export * from './construction-crews.effect';
export * from './construction-crews.reducer';
export * from './construction-crews.selector';
