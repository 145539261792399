import { Injectable } from '@angular/core'
import * as fromMaterialsActions from './materials.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { MaterialsHttpService } from '../../materials/materials-http.service'
import {catchError, map, mergeMap, Observable, of, tap} from 'rxjs'
import { Material } from '@ppgt/web/tasks/domain'
import * as fromTasksActions from '../../tasks/+state/tasks.actions'
import { AlertService } from "@ppgt/web/shared/core";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class MaterialsEffects {
  constructor(
    private actions$: Actions,
    private materialsHttpService: MaterialsHttpService,
    private alertService: AlertService,
    private translocoService: TranslocoService
  ) {}

  getMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterialsActions.Types.GetMaterials),
      mergeMap(() => this.materialsHttpService.getMaterials()),
      map((materials: Material[]) => fromMaterialsActions.getMaterialsSuccess({ materials })),
      catchError((error) => of(fromMaterialsActions.getMaterialsFail(error)))
    )
  );

  assignMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterialsActions.Types.AssignMaterials),
      mergeMap(({ taskChildId, taskParentId, materials, subtaskId }) =>
        this.materialsHttpService.assignMaterials(taskParentId, taskChildId, materials, subtaskId)
      ),
      map(({ materials, taskChildId, taskParentId, subtaskId } ) => fromMaterialsActions.assignMaterialsSuccess({ materials, taskChildId, taskParentId, subtaskId })),
      catchError((error) => of(
        fromMaterialsActions.assignMaterialsFail(error),
      ))
    )
  );

  assignMaterialsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterialsActions.Types.AssignMaterialsSuccess),
      map(({ materials, taskChildId, taskParentId, subtaskId }) =>
        fromTasksActions.updateTaskMaterials({ taskId: taskParentId, taskChildId, materials, subtaskId })
      ),
      tap(() => this.alertService.showInfo(this.translocoService.translate('tasks.materials-category.success')))
    )
  );

  assignMaterialsFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromMaterialsActions.Types.AssignMaterialsFail),
        tap(() => this.alertService.showError(this.translocoService.translate('tasks.materials-category.error')))
      );
    },
    { dispatch: false }
  );
}
