import { Injectable } from '@angular/core';
import { GeneralSettings } from '@app/state/interfaces';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private projectSettings: GeneralSettings = null;
  public settingsLoaded = false;

  init(settings: GeneralSettings) {
    this.projectSettings = settings;
    this.settingsLoaded = true;
  }

  getUserLanguage() {
    return this.projectSettings ? this.projectSettings.language : environment.defaultLanguage;
  }

  getClockFormat() {
    return this.projectSettings ? this.projectSettings.clockFormat : '24h';
  }

  getMetricSystem() {
    return this.projectSettings ? this.projectSettings.unitSystem : 'metric';
  }

  getPhonePrefix() {
    return this.projectSettings ? this.projectSettings.phonePrefix : null;
  }

  getProjectName() {
    return this.projectSettings ? this.projectSettings.projectName : null;
  }

  getFirstDayOfWeek() {
    return this.projectSettings ? this.projectSettings.firstDayOfWeek : null;
  }

  getAvailableLanguages() {
    return this.projectSettings ? this.projectSettings.availableLanguages : null;
  }

  getInstanceLanguageKey() {
    return this.projectSettings ? this.projectSettings.language : null;
  }

  getIntlCollator(options: Intl.CollatorOptions = { numeric: true, ignorePunctuation: true }) {
    return new Intl.Collator(this.getUserLanguage(), options);
  }
}
