import { Action } from '@ngrx/store';

import { ObjectToDeactivate } from '@interfaces';

// load all objects
export const GET_CONSTRUCTION_OBJECTS = 'GET_CONSTRUCTION_OBJECTS';
export const GET_CONSTRUCTION_OBJECTS_FAIL = 'GET_CONSTRUCTION_OBJECTS_FAIL';
export const GET_CONSTRUCTION_OBJECTS_SUCCESS =
  'GET_CONSTRUCTION_OBJECTS_SUCCESS';

export class GetConstructionObjects implements Action {
  readonly type = GET_CONSTRUCTION_OBJECTS;

  constructor(public payload: { id: string }) {}
}

export class GetConstructionObjectsFail implements Action {
  readonly type = GET_CONSTRUCTION_OBJECTS_FAIL;
}

export class GetConstructionObjectsSuccess implements Action {
  readonly type = GET_CONSTRUCTION_OBJECTS_SUCCESS;

  constructor(public payload: any) {}
}

// create new object
export const CREATE_CONSTRUCTION_OBJECT = 'CREATE_CONSTRUCTION_OBJECT';
export const CREATE_CONSTRUCTION_OBJECT_FAIL =
  'CREATE_CONSTRUCTION_OBJECT_FAIL';
export const CREATE_CONSTRUCTION_OBJECT_SUCCESS =
  'CREATE_CONSTRUCTION_OBJECT_SUCCESS';

export class CreateConstructionObject implements Action {
  readonly type = CREATE_CONSTRUCTION_OBJECT;

  constructor(public payload: any) {}
}

export class CreateConstructionObjectFail implements Action {
  readonly type = CREATE_CONSTRUCTION_OBJECT_FAIL;
}

export class CreateConstructionObjectSuccess implements Action {
  readonly type = CREATE_CONSTRUCTION_OBJECT_SUCCESS;

  constructor(public payload: any) {}
}

// load selected object
export const GET_CONSTRUCTION_OBJECT = 'GET_CONSTRUCTION_OBJECT';
export const GET_CONSTRUCTION_OBJECT_FAIL = 'GET_CONSTRUCTION_OBJECT_FAIL';
export const GET_CONSTRUCTION_OBJECT_SUCCESS =
  'GET_CONSTRUCTION_OBJECT_SUCCESS';

export class GetConstructionObject implements Action {
  readonly type = GET_CONSTRUCTION_OBJECT;

  constructor(public payload: { constrId: string; objectId: string }) {}
}

export class GetConstructionObjectFail implements Action {
  readonly type = GET_CONSTRUCTION_OBJECT_FAIL;
}

export class GetConstructionObjectSuccess implements Action {
  readonly type = GET_CONSTRUCTION_OBJECT_SUCCESS;

  constructor(public payload: any) {}
}

// update selected object
export const UPDATE_CONSTRUCTION_OBJECT = 'UPDATE_CONSTRUCTION_OBJECT';
export const UPDATE_CONSTRUCTION_OBJECT_FAIL =
  'UPDATE_CONSTRUCTION_OBJECT_FAIL';
export const UPDATE_CONSTRUCTION_OBJECT_SUCCESS =
  'UPDATE_CONSTRUCTION_OBJECT_SUCCESS';

export class UpdateConstructionObject implements Action {
  readonly type = UPDATE_CONSTRUCTION_OBJECT;

  constructor(public payload: any) {}
}

export class UpdateConstructionObjectFail implements Action {
  readonly type = UPDATE_CONSTRUCTION_OBJECT_FAIL;
}

export class UpdateConstructionObjectSuccess implements Action {
  readonly type = UPDATE_CONSTRUCTION_OBJECT_SUCCESS;

  constructor(public payload: any) {}
}

// remove selected object
export const REMOVE_CONSTRUCTION_OBJECT = 'REMOVE_CONSTRUCTION_OBJECT';
export const REMOVE_CONSTRUCTION_OBJECT_FAIL =
  'REMOVE_CONSTRUCTION_OBJECT_FAIL';
export const REMOVE_CONSTRUCTION_OBJECT_SUCCESS =
  'REMOVE_CONSTRUCTION_OBJECT_SUCCESS';

export class RemoveConstructionObject implements Action {
  readonly type = REMOVE_CONSTRUCTION_OBJECT;

  constructor(public payload: { constrId: string; objectId: string }) {}
}

export class RemoveConstructionObjectFail implements Action {
  readonly type = REMOVE_CONSTRUCTION_OBJECT_FAIL;
}

export class RemoveConstructionObjectSuccess implements Action {
  readonly type = REMOVE_CONSTRUCTION_OBJECT_SUCCESS;

  constructor(public payload: any) {}
}

export const DEACTIVATE_OBJECTS = 'DEACTIVATE OBJECTS';
export const DEACTIVATE_OBJECTS_SUCCESS = 'DEACTIVATE OBJECTS SUCCESS';
export const DEACTIVATE_OBJECTS_FAIL = 'DEACTIVATE OBJECTS FAIL';

export class DeactivateObjects implements Action {
  readonly type = DEACTIVATE_OBJECTS;

  constructor(public payload: ObjectToDeactivate[]) {}
}

export class DeactivateObjectsSuccess implements Action {
  readonly type = DEACTIVATE_OBJECTS_SUCCESS;
}

export class DeactivateObjectsFail implements Action {
  readonly type = DEACTIVATE_OBJECTS_FAIL;
}

export type ConstructionObjectsAction =
  | GetConstructionObjects
  | GetConstructionObjectsFail
  | GetConstructionObjectsSuccess
  | GetConstructionObject
  | GetConstructionObjectFail
  | GetConstructionObjectSuccess
  | CreateConstructionObject
  | CreateConstructionObjectFail
  | CreateConstructionObjectSuccess
  | UpdateConstructionObject
  | UpdateConstructionObjectFail
  | UpdateConstructionObjectSuccess
  | RemoveConstructionObject
  | RemoveConstructionObjectFail
  | RemoveConstructionObjectSuccess
  | DeactivateObjects
  | DeactivateObjectsSuccess
  | DeactivateObjectsFail;
