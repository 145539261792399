import { ActionReducer } from '@ngrx/store';

import * as effects from './contracts.effect';
import * as reducers from './contracts.reducer';

import { Contract } from '@app/state/interfaces';

export const contractsEffects: any[] = [effects.ContractsEffects];

export interface ContractsListState {
  contracts: Contract[];
  contract: Contract;
  totalObjectsCount: number;
  loading: boolean;
}

export const contractsReducer: ActionReducer<ContractsListState> = reducers.contractsReducer;

export * from './contracts.action';
export * from './contracts.effect';
export * from './contracts.reducer';
export * from './contracts.selector';
