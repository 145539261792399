import { Directive, Input, TemplateRef, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { DeviceService } from '@app/core/device.service';

@Directive({ selector: '[ifMobile]' })
export class IfMobileDirective {
  private embeddedView: EmbeddedViewRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private deviceService: DeviceService
  ) {}

  @Input()
  set ifMobile(condition: boolean) {
    const isMobile = this.deviceService.isMobile;
    const mobileWithAccess = isMobile && condition;
    const hasAccess = !isMobile || mobileWithAccess;

    if (!this.embeddedView && hasAccess) {
      this.embeddedView = this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (this.embeddedView && mobileWithAccess) {
      this.viewContainer.insert(this.embeddedView);
      return;
    }

    if (isMobile && !condition) {
      this.viewContainer.detach();
    }
  }
}
