import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { MyDatePickerModule } from 'mydatepicker';
import { InlineSVGModule } from 'ng-inline-svg';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { LoaderComponent } from './loader/loader.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { StepperComponent } from './stepper/stepper.component';
import { AddDeliveryModule } from './deliveries/add-delivery/add-delivery.module';
import { SvgModule } from './svg-icon/svg.module';
import { DirectivesModule } from './@directives/directives.module';
import { PipesModule } from './@pipes/pipes.module';
import { ExternalUrlRedirectResolver } from './@resolvers';
import { HeaderModule } from './header/header.module';
import { ExtendedClipboardModule } from './clipboard/extended-clipboard.module';
import { SearchModule } from './search/search.module';
import { ResetSortingModule } from './reset-sorting/reset-sorting.module';
import { ListPaginationModule } from './list-pagination/list-pagination.module';
import { NoDataModule } from './no-data/no-data.module';
import { TableLegendModule } from './table-legend/table-legend.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    NgSelectModule,
    TranslateModule,
    ClipboardModule,
    MyDatePickerModule,
    SvgModule,
    PipesModule,
    AddDeliveryModule,
    DirectivesModule,
    NgxMaskModule.forRoot({}),
    HeaderModule,
    ExtendedClipboardModule,
    SearchModule,
    ResetSortingModule,
    ListPaginationModule,
    NoDataModule,
    TableLegendModule,
  ],
  declarations: [LoadingIndicatorComponent, LoaderComponent, StepperComponent],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
    SvgModule,
    DirectivesModule,
    PipesModule,
    AddDeliveryModule,
    LoadingIndicatorComponent,
    InlineSVGModule,
    ClipboardModule,
    LoaderComponent,
    StepperComponent,
    MyDatePickerModule,
    RouterModule,
    NgxMaskModule,
    HeaderModule,
    ExtendedClipboardModule,
    SearchModule,
    ResetSortingModule,
    ListPaginationModule,
    NoDataModule,
    TableLegendModule,
  ],
  providers: [ExternalUrlRedirectResolver],
})
export class SharedModule {}
