import { Theme } from './interfaces';

export const planObjects: { [key in Theme]?: PlanObjectSettings[] } = {
  [Theme.Dark]: [
    {
      name: 'general.construction_plan_l',
      type: 'construction',
      svgType: 'polygon',
      style: {
        fill: 'transparent',
        stroke: '#D8D8D8',
        'stroke-width': 2,
      },
      legendIcon: 'assets/img/icon-construction.svg',
      legendIconActive: 'assets/img/icon-construction-active.svg',
      resize: true,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.building_l',
      type: 'building',
      svgType: 'polygon',
      style: {
        fill: '#C3DFFF',
        stroke: '#C2DEFF',
        'fill-opacity': 0.4,
        'stroke-width': 2,
      },
      styleText: {
        family: 'Roboto',
        size: 24,
        weight: 700,
        fill: '#6C95C5',
      },
      legendIcon: 'assets/img/icon-building.svg',
      legendIconActive: 'assets/img/icon-building-active.svg',
      resize: true,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.gate_l',
      type: 'gate',
      svgType: 'image',
      orientation: 'horizontal',
      size: {
        width: 34,
        height: 6,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      iconUrl: 'assets/img/icon-map-gate-horizontal.svg',
      iconUrlSelected: 'assets/img/icon-map-gate-horizontal-selected.svg',
      legendIcon: 'assets/img/icon-gate-horizontal.svg',
      legendIconActive: 'assets/img/icon-gate-horizontal-active.svg',
      resize: false,
      rotate: true,
      moveText: true,
      snapToGrid: 5,
    },
    {
      name: 'general.place_l',
      type: 'unloadingPlace',
      orientation: 'horizontal',
      svgType: 'image',
      size: {
        width: 52,
        height: 22,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      iconUrl: 'assets/img/icon-map-unloading-place-horizontal.svg',
      iconUrlSelected: 'assets/img/icon-map-unloading-place-horizontal-selected.svg',
      hotspotIconUrl: 'assets/img/icon-map-unloading-place-horizontal-hotspot.svg',
      hotspotIconUrlSelected: 'assets/img/icon-map-unloading-place-horizontal-hotspot-selected.svg',
      legendIcon: 'assets/img/icon-unloading-place.svg',
      legendIconActive: 'assets/img/icon-unloading-place-active.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.place_l',
      type: 'unloadingPlace',
      orientation: 'vertical',
      svgType: 'image',
      size: {
        width: 22,
        height: 52,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      iconUrl: 'assets/img/icon-map-unloading-place-vertical.svg',
      iconUrlSelected: 'assets/img/icon-map-unloading-place-vertical-selected.svg',
      legendIcon: 'assets/img/icon-unloading-place-vertical.svg',
      legendIconActive: 'assets/img/icon-unloading-place-vertical-active.svg',
      hotspotIconUrl: 'assets/img/icon-map-unloading-place-vertical-hotspot.svg',
      hotspotIconUrlSelected: 'assets/img/icon-map-unloading-place-vertical-hotspot-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.route_l',
      type: 'route',
      svgType: 'line',
      style: {
        fill: 'transparent',
        stroke: '#e6e6e6',
        'stroke-width': 6,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 400,
        fill: '#e6e6e6',
      },
      directionIcon: {
        twoWay: 'assets/img/icon-direction-two-way-dark.svg',
        oneWay: 'assets/img/icon-direction-one-way-dark.svg',
      },
      legendIcon: 'assets/img/icon-route.svg',
      legendIconActive: 'assets/img/icon-route-active.svg',
      resize: true,
      rotate: false,
      moveText: true,
    },
    {
      name: 'general.crane_l',
      type: 'crane',
      svgType: 'image',
      size: {
        width: 33,
        height: 30,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      legendIcon: 'assets/img/icon-plan-crane-dark.svg',
      iconUrl: 'assets/img/icon-map-crane-dark.svg',
      iconUrlSelected: 'assets/img/icon-map-crane-selected.svg',
      legendIconActive: 'assets/img/icon-plan-crane-active.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.elevator_l',
      type: 'elevator',
      svgType: 'image',
      size: {
        width: 20,
        height: 20,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      legendIcon: 'assets/img/icon-map-elevator-dark.svg',
      iconUrl: 'assets/img/icon-map-elevator-dark.svg',
      iconUrlSelected: 'assets/img/icon-map-elevator-selected.svg',
      legendIconActive: 'assets/img/icon-map-elevator-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.depot_l',
      type: 'depot',
      svgType: 'polygon',
      style: {
        fill: '#DCF7FD',
        'fill-opacity': 0.6,
        stroke: '#64C9E0',
        'stroke-width': 4,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#D8D8D8',
      },
      legendIcon: 'assets/img/icon-depot.svg',
      legendIconActive: 'assets/img/icon-depot-active.svg',
      resize: true,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.excavator_l',
      type: 'excavator',
      svgType: 'image',
      iconUrl: 'assets/img/icon-excavator-dark.svg',
      iconUrlSelected: 'assets/img/icon-excavator-selected.svg',
      size: {
        width: 35,
        height: 29,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      legendIcon: 'assets/img/icon-excavator-dark.svg',
      legendIconActive: 'assets/img/icon-excavator-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.mobile_crane_l',
      type: 'mobileCrane',
      svgType: 'image',
      iconUrl: 'assets/img/icon-mobile-crane-dark.svg',
      iconUrlSelected: 'assets/img/icon-mobile-crane-selected.svg',
      size: {
        width: 35,
        height: 29,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      legendIcon: 'assets/img/icon-mobile-crane-dark.svg',
      legendIconActive: 'assets/img/icon-mobile-crane-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
    },
    // {
    //   name: 'general.mini_excavator_l',
    //   type: 'miniExcavator',
    //   svgType: 'image',
    //   iconUrl: 'assets/img/icon-mini-excavator-dark.svg',
    //   iconUrlSelected: 'assets/img/icon-mini-excavator-selected-dark.svg',
    //   size: {
    //     width: 35,
    //     height: 29,
    //   },
    //   styleText: {
    //     family: 'Roboto',
    //     size: 14,
    //     weight: 700,
    //     fill: '#FFFFFF',
    //   },
    //   legendIcon: 'assets/img/icon-mini-excavator-dark.svg',
    //   legendIconActive: 'assets/img/icon-mini-excavator-selected-dark.svg',
    //   resize: false,
    //   rotate: false,
    //   moveText: false,
    // },
    {
      name: 'general.forklift_l',
      type: 'forklift',
      svgType: 'image',
      iconUrl: 'assets/img/icon-forklift-dark.svg',
      iconUrlSelected: 'assets/img/icon-forklift-selected.svg',
      size: {
        width: 35,
        height: 29,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      legendIcon: 'assets/img/icon-forklift-dark.svg',
      legendIconActive: 'assets/img/icon-forklift-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.other_equipment_l',
      type: 'other',
      svgType: 'image',
      size: {
        width: 33,
        height: 30,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      legendIcon: 'assets/img/icon-plan-crane-dark.svg',
      iconUrl: 'assets/img/icon-map-crane-dark.svg',
      iconUrlSelected: 'assets/img/icon-map-crane-selected.svg',
      legendIconActive: 'assets/img/icon-plan-crane-active.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },

  ],
  //
  [Theme.White]: [
    {
      name: 'general.construction_plan_l',
      type: 'construction',
      svgType: 'polygon',
      style: {
        fill: 'transparent',
        stroke: '#D8D8D8',
        'stroke-width': 2,
      },
      legendIcon: 'assets/img/icon-construction.svg',
      legendIconActive: 'assets/img/icon-construction-active.svg',
      resize: true,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.building_l',
      type: 'building',
      svgType: 'polygon',
      style: {
        fill: '#C3DFFF',
        'fill-opacity': 0.4,
        stroke: '#C2DEFF',
        'stroke-width': 2,
      },
      styleText: {
        family: 'Roboto',
        size: 24,
        weight: 700,
        fill: '#6C95C5',
      },
      legendIcon: 'assets/img/icon-building.svg',
      legendIconActive: 'assets/img/icon-building-active.svg',
      resize: true,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.gate_l',
      type: 'gate',
      svgType: 'image',
      orientation: 'horizontal',
      size: {
        width: 34,
        height: 6,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      iconUrl: 'assets/img/icon-map-gate-horizontal.svg',
      iconUrlSelected: 'assets/img/icon-map-gate-horizontal-selected.svg',
      legendIcon: 'assets/img/icon-gate-horizontal.svg',
      legendIconActive: 'assets/img/icon-gate-horizontal-active.svg',
      resize: false,
      rotate: true,
      moveText: true,
      snapToGrid: 5,
    },
    {
      name: 'general.place_l',
      type: 'unloadingPlace',
      orientation: 'horizontal',
      svgType: 'image',
      size: {
        width: 52,
        height: 22,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      iconUrl: 'assets/img/icon-map-unloading-place-horizontal.svg',
      iconUrlSelected: 'assets/img/icon-map-unloading-place-horizontal-selected.svg',
      hotspotIconUrl: 'assets/img/icon-map-unloading-place-horizontal-hotspot.svg',
      hotspotIconUrlSelected: 'assets/img/icon-map-unloading-place-horizontal-hotspot-selected.svg',
      legendIcon: 'assets/img/icon-unloading-place.svg',
      legendIconActive: 'assets/img/icon-unloading-place-active.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.place_l',
      type: 'unloadingPlace',
      orientation: 'vertical',
      svgType: 'image',
      size: {
        width: 22,
        height: 52,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#FFFFFF',
      },
      iconUrl: 'assets/img/icon-map-unloading-place-vertical.svg',
      iconUrlSelected: 'assets/img/icon-map-unloading-place-vertical-selected.svg',
      hotspotIconUrl: 'assets/img/icon-map-unloading-place-vertical-hotspot.svg',
      hotspotIconUrlSelected: 'assets/img/icon-map-unloading-place-vertical-hotspot-selected.svg',
      legendIcon: 'assets/img/icon-unloading-place-vertical.svg',
      legendIconActive: 'assets/img/icon-unloading-place-vertical-active.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.route_l',
      type: 'route',
      svgType: 'line',
      style: {
        fill: 'transparent',
        stroke: '#e6e6e6',
        'stroke-width': 6,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 'bold',
        fill: '#b9b9b9',
      },
      directionIcon: {
        twoWay: 'assets/img/icon-direction-two-way.svg',
        oneWay: 'assets/img/icon-direction-one-way.svg',
      },
      legendIcon: 'assets/img/icon-route.svg',
      legendIconActive: 'assets/img/icon-route-active.svg',
      resize: true,
      rotate: false,
      moveText: true,
    },
    {
      name: 'general.crane_l',
      type: 'crane',
      svgType: 'image',
      size: {
        width: 30,
        height: 30,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      legendIcon: 'assets/img/icon-map-crane.svg',
      iconUrl: 'assets/img/icon-map-crane.svg',
      iconUrlSelected: 'assets/img/icon-map-crane-selected.svg',
      legendIconActive: 'assets/img/icon-map-crane-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.elevator_l',
      type: 'elevator',
      svgType: 'image',
      size: {
        width: 20,
        height: 20,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      legendIcon: 'assets/img/icon-map-elevator.svg',
      iconUrl: 'assets/img/icon-map-elevator.svg',
      iconUrlSelected: 'assets/img/icon-map-elevator-selected.svg',
      legendIconActive: 'assets/img/icon-map-elevator-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
    {
      name: 'general.depot_l',
      type: 'depot',
      svgType: 'polygon',
      style: {
        fill: '#DCF7FD',
        'fill-opacity': 0.6,
        stroke: '#97ECFF',
        'stroke-width': 4,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#64C9E0',
      },
      legendIcon: 'assets/img/icon-depot.svg',
      legendIconActive: 'assets/img/icon-depot-active.svg',
      resize: true,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.excavator_l',
      type: 'excavator',
      svgType: 'image',
      iconUrl: 'assets/img/icon-excavator.svg',
      iconUrlSelected: 'assets/img/icon-excavator-selected.svg',
      size: {
        width: 35,
        height: 29,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      legendIcon: 'assets/img/icon-excavator.svg',
      legendIconActive: 'assets/img/icon-excavator-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.mobile_crane_l',
      type: 'mobileCrane',
      svgType: 'image',
      iconUrl: 'assets/img/icon-mobile-crane.svg',
      iconUrlSelected: 'assets/img/icon-mobile-crane-selected.svg',
      size: {
        width: 35,
        height: 29,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      legendIcon: 'assets/img/icon-mobile-crane.svg',
      legendIconActive: 'assets/img/icon-mobile-crane-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
    },
    // {
    //   name: 'general.mini_excavator_l',
    //   type: 'miniExcavator',
    //   svgType: 'image',
    //   iconUrl: 'assets/img/icon-mini-excavator.svg',
    //   iconUrlSelected: 'assets/img/icon-mini-excavator-selected.svg',
    //   size: {
    //     width: 35,
    //     height: 29,
    //   },
    //   styleText: {
    //     family: 'Roboto',
    //     size: 14,
    //     weight: 700,
    //     fill: '#B9B9B9',
    //   },
    //   legendIcon: 'assets/img/icon-mini-excavator.svg',
    //   legendIconActive: 'assets/img/icon-mini-excavator-selected.svg',
    //   resize: false,
    //   rotate: false,
    //   moveText: false,
    // },
    {
      name: 'general.forklift_l',
      type: 'forklift',
      svgType: 'image',
      iconUrl: 'assets/img/icon-forklift.svg',
      iconUrlSelected: 'assets/img/icon-forklift-selected.svg',
      size: {
        width: 35,
        height: 29,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      legendIcon: 'assets/img/icon-forklift.svg',
      legendIconActive: 'assets/img/icon-forklift-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
    },
    {
      name: 'general.other_equipment_l',
      type: 'other',
      svgType: 'image',
      size: {
        width: 30,
        height: 30,
      },
      styleText: {
        family: 'Roboto',
        size: 14,
        weight: 700,
        fill: '#B9B9B9',
      },
      legendIcon: 'assets/img/icon-map-crane.svg',
      iconUrl: 'assets/img/icon-map-crane.svg',
      iconUrlSelected: 'assets/img/icon-map-crane-selected.svg',
      legendIconActive: 'assets/img/icon-map-crane-selected.svg',
      resize: false,
      rotate: false,
      moveText: false,
      snapToGrid: 5,
    },
  ],
};

export interface PlanObjectSettings {
  name: string;
  type:
    | 'construction'
    | 'building'
    | 'gate'
    | 'unloadingPlace'
    | 'route'
    | 'crane'
    | 'elevator'
    | 'forklift'
    | 'depot'
    | 'excavator'
    | 'miniExcavator'
    | 'mobileCrane'
    | 'other';
  svgType: 'polygon' | 'image' | 'line';
  orientation?: 'horizontal' | 'vertical';
  directionIcon?: { twoWay: string; oneWay: string };
  size?: { width: number; height: number };
  style?: { fill: string; stroke: string; 'stroke-width': number; 'fill-opacity'?: number };
  legendIcon: string;
  legendIconActive: string;
  resize: boolean;
  rotate: boolean;
  moveText: boolean;
  styleText?: { family: string; size: number; weight: number | string; fill: string };
  iconUrl?: string;
  iconUrlSelected?: string;
  hotspotIconUrl?: string;
  hotspotIconUrlSelected?: string;
  snapToGrid?: number;
}
