import { isFunction } from 'lodash-es';

import {
  GetSubcontractorRes,
  GetSubcontractorsRes,
} from '@app/core/http/interfaces';

import * as actions from './subcontractors.action';
import { SubcontractorsState } from './';
import {
  VehicleObj,
  SubcontractorsEmployee, SubcontractorsEmployeeRole, MaterialSupplier
} from '../interfaces';
import { UsersState } from '@app/state';
import { SupplierLocation } from '@interfaces';

const initialState: SubcontractorsState = {
  subcontractors: [],
  selectedSubcontractor: null,
  vehicles: [],
  subcontractorVehicles: [],
  subcontractorPlaces: [],
  subcontractorEmployees: [],
  subcontractorEmployeesRoles: [],
  subcontractorsSuppliers: [],
  subcontractorsLocations: [],
  totalObjectsCount: null,
  loading: false,
};

const reducerMap: { [key: string]: Function } = {
  [actions.GET_SUBCONTRACTORS]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTORS_FAIL]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTORS_SUCCESS]: (
    state: SubcontractorsState,
    { subcontractors, meta }: GetSubcontractorsRes['data'],
  ) => ({
    ...state,
    subcontractors,
    totalObjectsCount: meta.count,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTOR]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTOR_FAIL]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTOR_SUCCESS]: (
    state: SubcontractorsState,
    selectedSubcontractor: GetSubcontractorRes,
  ) => ({
    ...state,
    selectedSubcontractor,
    loading: false,
  }),
  [actions.GET_VEHICLES]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_VEHICLES_FAIL]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_VEHICLES_SUCCESS]: (state: SubcontractorsState, vehicles: VehicleObj[]) => ({
    ...state,
    vehicles,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTOR_VEHICLES]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTOR_VEHICLES_FAIL]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTOR_VEHICLES_SUCCESS]: (
    state: SubcontractorsState,
    vehicles: VehicleObj[],
  ) => ({
    ...state,
    subcontractorVehicles: vehicles,
    // totalObjectsCount: data.meta.count,
    loading: false,
  }),
  [actions.CREATE_VEHICLES]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_VEHICLES_FAIL]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_VEHICLES_SUCCESS]: (state: SubcontractorsState, vehicles: VehicleObj[]) => ({
    ...state,
    subcontractorVehicles: [...vehicles, ...state.subcontractorVehicles],
    loading: false,
  }),
  [actions.CREATE_SUBCONTRACTOR]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_SUBCONTRACTOR_FAIL]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_SUBCONTRACTOR_SUCCESS]: (
    state: SubcontractorsState,
  ) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_SUBCONTRACTOR]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_SUBCONTRACTOR_FAIL]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_SUBCONTRACTOR_SUCCESS]: (state: SubcontractorsState, payload: any) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTORS_EMPLOYEES]: (state: UsersState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTORS_EMPLOYEES_SUCCESS]: (
    state: SubcontractorsState,
    employees: SubcontractorsEmployee[],
  ) => ({
    ...state,
    loading: false,
    subcontractorEmployees: employees,
  }),
  [actions.GET_SUBCONTRACTORS_EMPLOYEES_FAIL]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTORS_EMPLOYEE_ROLES]: (state: UsersState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTORS_EMPLOYEE_ROLES_SUCCESS]: (
    state: SubcontractorsState,
    roles: SubcontractorsEmployeeRole[],
  ) => ({
    ...state,
    loading: false,
    subcontractorEmployeesRoles: roles,
  }),
  [actions.GET_SUBCONTRACTORS_EMPLOYEE_ROLES_FAIL]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_SUBCONTRACTORS_EMPLOYEE]: (state: SubcontractorsState) => ({
    ...state,
    loading: true,
  }),
  [actions.CREATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.CREATE_SUBCONTRACTORS_EMPLOYEE_FAIL]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.ASSIGN_SUBCONTRACTORS_EMPLOYEE]: (state: SubcontractorsState) => ({
    ...state,
    loading: true,
  }),
  [actions.ASSIGN_SUBCONTRACTORS_EMPLOYEE_SUCCESS]: (
    state: SubcontractorsState,
    employee: SubcontractorsEmployee,
  ) => ({
    ...state,
    loading: false,
    subcontractorEmployees: [
    ...state.subcontractorEmployees,
      employee,
    ],
  }),
  [actions.ASSIGN_SUBCONTRACTORS_EMPLOYEE_FAIL]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_SUBCONTRACTORS_EMPLOYEE]: (state: SubcontractorsState) => ({
    ...state,
    loading: true,
  }),
  [actions.UPDATE_SUBCONTRACTORS_EMPLOYEE_SUCCESS]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.UPDATE_SUBCONTRACTORS_EMPLOYEE_FAIL]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTORS_SUPPLIERS]: (state: SubcontractorsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTORS_SUPPLIERS_SUCCESS]: (
    state: SubcontractorsState,
    subcontractorsSuppliers: MaterialSupplier[],
  ) => ({
    ...state,
    loading: false,
    subcontractorsSuppliers,
  }),
  [actions.GET_SUBCONTRACTORS_FAIL]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
  [actions.GET_SUBCONTRACTOR_LOCATIONS]: (state: SubcontractorsState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_SUBCONTRACTOR_LOCATIONS_SUCCESS]: (
    state: SubcontractorsState,
    locations: SupplierLocation[],
  ) => ({
    ...state,
    loading: false,
    subcontractorsLocations: locations,
  }),
  [actions.GET_SUBCONTRACTOR_LOCATIONS_FAIL]: (state: SubcontractorsState) => ({
    ...state,
    loading: false,
  }),
};

export function subcontractorsReducer(state: SubcontractorsState = initialState, action: any) {
  return isFunction(reducerMap[action.type])
    ? reducerMap[action.type](state, action.payload)
    : state;
}

export const getSubcontractors = (state: SubcontractorsState) => state.subcontractors;
export const getSelectedSubcontractor = (state: SubcontractorsState) => state.selectedSubcontractor;
export const getAllVehicles = (state: SubcontractorsState) => state.vehicles;
export const getAllSubcontractorVehicles = (state: SubcontractorsState) =>
  state.subcontractorVehicles;
export const getSubcontractorPlaces = (state: SubcontractorsState) => state.subcontractorPlaces;
export const getSubcontractorLocations = (state: SubcontractorsState) => state.subcontractorsLocations;
export const getSubcontractorCount = (state: SubcontractorsState) => state.totalObjectsCount;
export const getSubcontractorLoading = (state: SubcontractorsState) => state.loading;
export const getSubcontractorEmployees = (state: SubcontractorsState) => state.subcontractorEmployees;
export const getSubcontractorEmployeesRoles = (state: SubcontractorsState) => state.subcontractorEmployeesRoles;
export const getSubcontractorSuppliers = (state: SubcontractorsState) => state.subcontractorsSuppliers;
