import { createSelector, createFeatureSelector } from '@ngrx/store';
import { get } from 'lodash-es';
import { UsersState } from '.';
import { Theme } from '@app/config/interfaces';
import { Role } from '@ppgt/web/shared/domain';

const stateSelector = createFeatureSelector<UsersState>('users');

export const getUserData = createSelector(stateSelector, state => state.userData);

export const getUserTheme = createSelector(
  stateSelector,
  state => get(state, 'userData.theme', Theme.White) as Theme,
);

export const getUserDataLoaded = createSelector(stateSelector, state => !!state.userData);

export const getUserRole = createSelector(
  stateSelector,
  state => get(state, 'userData.role', null) as Role,
);

export const getUserPermissions = createSelector(stateSelector, state =>
  get(state, 'userData.role.permits'),
);

export const getUserAvatar = createSelector(stateSelector, state =>
  state.userData ? state.userData.avatar : '',
);

export const getAllUsers = createSelector(stateSelector, state => state.users);
export const getUsersLoaded = createSelector(stateSelector, state => state.loaded);
export const getUsersCount = createSelector(stateSelector, state => state.totalCount);
export const getSelectedUser = createSelector(stateSelector, state => state.selectedUser);
export const getUserLoading = createSelector(stateSelector, state => state.loading);
export const selectUserPin = createSelector(stateSelector, state => state.userPin);

export const getUserLanguage = createSelector(
  stateSelector,
  state => state.userData && state.userData.language,
);

export const userQuery = {
  getUserTheme,
};
